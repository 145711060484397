import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum MessageResourceType {
  ArtifactComment = 'ArtifactComment',
  AuditProofFolder = 'AuditProofFolder',
  BusinessInvitation = 'BUSINESS_INVITATION',
  AcceptedMemberInvitation = 'ACCEPTED_MEMBER_INVITATION',
  DeclinedMemberInvitation = 'DECLINED_MEMBER_INVITATION',
  AcceptedBusinessPartnerInvitation = 'ACCEPTED_BUSINESS_PARTNER_INVITATION',
  DeclinedBusinessPartnerInvitation = 'DECLINED_BUSINESS_PARTNER_INVITATION',
  CollectorPublished = 'CollectorPublished',
  CollectorGroupCreated = 'CollectorGroupCreated',
  CollectorGroupDeleted = 'CollectorGroupDeleted',
  CollectorItemCreated = 'CollectorItemCreated',
  CollectorItemDeleted = 'CollectorItemDeleted',
  CollectorUpdatedAttributes = 'CollectorUpdatedAttributes',
  CollectorGroupAssigned = 'CollectorGroupAssigned',
  CollectorItemAssigned = 'CollectorItemAssigned',
  ContactVerified = 'ContactVerified',
  CacStatusUpdate = 'CacStatusUpdate',
  Comment = 'Comment',
  Description = 'Description',
  DocumentUploaded = 'DocumentUploaded',
  DueDateChanged = 'DueDateChanged',
  ExternalMessage = 'ExternalMessage',
  ExternalAccessReceived = 'ExternalAccessReceived',
  InstantMessage = 'InstantMessage',
  MemberInvitation = 'MEMBER_INVITATION',
  Message = 'MESSAGE',
  MessageDraft = 'MESSAGE_DRAFT',
  ParticipantAdded = 'ParticipantAdded',
  ParticipantRemoved = 'ParticipantRemoved',
  Process = 'PROCESS',
  ProcessStarted = 'ProcessStarted',
  ProcessClosed = 'ProcessClosed',
  ProcessOwnerChanged = 'ProcessOwnerChanged',
  Reminded = 'Reminded',
  SentMessage = 'SentMessage',
  Task = 'TASK',
  TaskUpdated = 'TASK_UPDATED',
  TaskAssignment = 'TASK_ASSIGNMENT',
  TaskAssignmentAccepted = 'TASK_ASSIGNMENT_ACCEPTED',
  TaskAssignmentRejected = 'TASK_ASSIGNMENT_REJECTED',
  ThirdPartyDetails = 'ThirdPartyDetails',
  RequestReceived = 'RequestReceived',
  TaskClosed = 'TaskClosed',
  TaskReopened = 'TaskReopened',
  TaskApproved = 'TaskApproved',
  TaskDeclined = 'TaskDeclined',
  TaskDocumentAdded = 'TaskDocumentAdded',
  TaskDocumentRemoved = 'TaskDocumentRemoved',
  TaskCommented = 'TaskCommented',
  TaskResponsibleAssignment = 'TaskResponsibleAssignment'
}

export enum MessageType {
  Message = 'message',
  Update = 'update'
}

/**
 * Page size for paginated requests.
 */
export const MESSAGE_PAGE_SIZE = 15;

export interface IMessageParams {
  page: number;
  per_page?: number;

  /**
   * The returned timestamp is the start marker for the pagination.
   * Newer elements will not be considered during one paginated run starting from page 1.
   * It must be delivered on each subsequent page request.
   * It must only be reseted on page call 1.
   */
  timestamp?: number;

  /**
   * Total count of elements of the query scope (all candidates).
   */
  records?: number;

  archived?: boolean;
  favorite?: boolean;
  unread?: boolean;
  search?: string;
  resource?: string;
  t?: string,
  pid?: string,
  cid?: string,
  oid?: string,
  rec?: boolean
}

export interface IMessageStat extends IResource {
  unread: number;
  unreadUpdates: number;
}

export interface IMessageDraft {
  id?: string;
  recipients: string[];
  subject: string;
  body: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IMessage extends IResource {
  resourceType: MessageResourceType;

  performer: string;
  recipient: string;
  recipients: string[];

  subject: string;
  message: string;

  followUpId: string;
  resourceId: string;

  blankedOut: boolean;
  favorite: boolean;
  color: string;

  archivedAt: Date;
  readAt: Date;
  sentAt: Date;
  createdAt: Date;
  updatedAt?: Date;
  dueDate?: Date;

  payload?: any;

  apiType: string;

  icon?: string;
  unreadIcon?: string;

  count?: number;
  processes?: { ['id']: string; ['title']: string; ['type']: string; ['created_at']: Date; ['updated_at']: Date;  }[];
}

export interface Update {
  id: string,
  title: string;
  icon: string;
  author: string,
  performer$,
  type: MessageResourceType,
  createdAt: Date
}

export interface IMessageResourceStat extends IResource {
  all: number;
  unread: number;
}

export interface IUnreadResource extends IResource {
  unread: number;

  title?: string;
  subtitle?: string;
  url?: string;
  processType?: string;
}
