<div class="d-flex align-items-center justify-content-{{alignmentPosition}}">
  <div>
    <button mat-icon-button [matTooltip]="tooltip | translate" *ngIf="position === 'before'">
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  </div>
  <div class="dvtx-inline-valign" [class.h4]="h4">
    {{ title | translate }}
  </div>
  <div>
    <button mat-icon-button [matTooltip]="tooltip | translate" *ngIf="position === 'after'">
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  </div>
</div>
