import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {Role} from './role';

export interface State extends EntityState<Role> {
  loading: boolean
}

export const adapter = createEntityAdapter<Role>()

export const initialState: State = adapter.getInitialState({
  loading: false,
})

