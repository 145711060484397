<ng-template [ngIf]="enableFolderActionToolbar" [ngIfElse]="searchBarOnlyTpl">
  <div class="fivef-toolbar fivef-toolbar--space-between" style="flex-grow: 0">
    <fivef-search [disabled]="loading" (onChange)="applySearch($event)"></fivef-search>

    <div>
      <button mat-icon-button (click)="refresh()"
              matTooltip="{{ 'GENERAL.REFRESH_ACTION' | translate }}"
              [disabled]="loading">
        <mat-icon>refresh</mat-icon>
      </button>

      <button mat-icon-button (click)="createNode()" class="ml-2"
              matTooltip="{{ 'DMS.CREATE_FOLDER_ACTION' | translate }}"
              [disabled]="loading || !_selectedNode">
        <mat-icon>create_new_folder</mat-icon>
      </button>

      <button mat-icon-button (click)="renameNode()" matTooltip="{{ 'GENERAL.RENAME_ACTION' | translate }}"
              [disabled]="loading || _selectedNode?.renamable !== true">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button (click)="deleteNode()"
              [disabled]="loading || _selectedNode?.deletable !== true"
              matTooltip="{{ 'DMS.DELETE_FOLDER_ACTION' | translate }}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #searchBarOnlyTpl>
  <div class="fivef-toolbar fivef-toolbar--start fivef-toolbar--content-spacing fivef-toolbar--content-spacing--end">
    <fivef-search [disabled]="loading" (onChange)="applySearch($event)"></fivef-search>
  </div>
</ng-template>

<fivef-tree [nodes]="folderNodes$ | async"
            [expandRoot]="expandRoot"
            [search]="searchTerm"
            [select]="selectedFolder"
            (onSelect)="selectHandler($event)">
  <ng-container *fivefTreeNodeDef="let node">
    <fivef-folder-tree-node [node]="node"
                            (onCreate)="createNode($event)"
                            (onRename)="renameNode($event)"
                            (onDelete)="deleteNode($event)"></fivef-folder-tree-node>
  </ng-container>
</fivef-tree>
