<fivef-dialog [isCallToAction]="true" [title]="'TENANT.USER_MGMT.CREATE_USER_ACCOUNT'">
  <div class="five-f-mat-tab">
    <mat-tab-group [selectedIndex]="selectedIndexTab" class="fivef-create-employee-tab"
                   (selectedIndexChange)="resetForms($event)">
      <mat-tab [disabled]="employeeCreationOngoing">
        <ng-template mat-tab-label>
          {{ 'CONTACTS.CREATE_EMPLOYEE' | translate }}
        </ng-template>

        <div class="pt-2">
          <ng-template [ngIf]="employeeCreationOngoing" [ngIfElse]="employeeFormTpl">
            <fivef-loading-indicator [appearance]="'progressbar'"
                                     [message]="'CONTACTS.ADDING_EMPLOYEE_ONGOING'"
                                     [spacing]="'m'"></fivef-loading-indicator>
          </ng-template>

          <ng-template #employeeFormTpl>
            <div [formGroup]="form" autocomplete="off">
              <div class="ff-icon-content ff-icon-content--floating-labels">
                <mat-icon>person</mat-icon>
                <div class="ff-icon-content--content">
                  <mat-form-field>
                    <mat-label>{{ 'CONTACTS.FIRST_NAME' | translate }}</mat-label>
                    <input matInput required formControlName="firstName"/>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'CONTACTS.LAST_NAME' | translate }}</mat-label>
                    <input matInput required formControlName="lastName"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="ff-icon-content ff-icon-content--floating-labels">
                <mat-icon>vpn_key</mat-icon>
                <div class="ff-icon-content--content">
                  <mat-form-field>
                    <mat-label>{{ 'AUTH.PASSWORD' | translate }}</mat-label>
                    <input #passwordInput
                           autocomplete="new-password"
                           formControlName="password"
                           required
                           matInput [type]="showFirstPass" id="password-member">
                    <mat-icon matSuffix class="dvtx-fg-color dvtx-fg-cool-grey-400"
                              *ngIf="showFirstPass === fieldType.Password"
                              (click)="showFirstPassword();">visibility
                    </mat-icon>
                    <mat-icon matSuffix class="dvtx-fg-color dvtx-fg-cool-grey-400"
                              *ngIf="showFirstPass === fieldType.Text"
                              (click)="hideFirstPassword();">visibility_off
                    </mat-icon>

                    <mat-hint *ngIf="isPasswordInvalid(passwordInput.value)">
                      {{ verifyPasswordStrength(passwordInput.value)?.message | translate }}
                    </mat-hint>
                  </mat-form-field>

                  <button mat-icon-button
                          style="position: absolute; top: 0rem; right: -2.5rem;"
                          [matTooltip]="'PASSWORD.STRONG_PASSWORD_POLICY' | translate">
                    <mat-icon>info</mat-icon>
                  </button>
                </div>
              </div>

              <div class="ff-icon-content ff-icon-content--floating-labels">
                <mat-icon>email</mat-icon>
                <div class="ff-icon-content--content">
                  <mat-form-field>
                    <mat-label>{{ 'CONTACTS.EMAIL' | translate }}</mat-label>
                    <input email
                           formControlName="email"
                           required
                           matInput>

                    <mat-error
                      *ngIf="form.controls.email.hasError('invalidEmail') || form.controls.email.hasError('required')">
                      {{ 'CONTACTS.ENTER_VALID_EMAIL' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <mat-checkbox [labelPosition]="'after'" color="primary" formControlName="notifyUser">
                <dvtx-table-text-and-info [alignmentPosition]="'start'" [h4]="true"
                                          [title]="'CONTACTS.INFORM_EMPLOYEE_BY_EMAIL'"
                                          [tooltip]="'CONTACTS.INFORM_EMPLOYEE_BY_EMAIL_INFO'">
                </dvtx-table-text-and-info>
              </mat-checkbox>

            </div>
          </ng-template>
        </div>
      </mat-tab>

      <mat-tab [disabled]="employeeCreationOngoing">
        <ng-template mat-tab-label>
          {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}
        </ng-template>

        <div class="pt-2" style="min-height: 300px;">
          <dvtx-membership-invitation #membershipInvitation></dvtx-membership-invitation>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div fivef-dialog-footer>
    <ng-template [ngIf]="selectedIndexTab === '0'">
      <button mat-button
              [disabled]="employeeCreationOngoing"
              (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="createEmployee()"
              [disabled]="employeeCreationOngoing || !form.valid || passwordChangeOngoing || isPasswordInvalid(form.controls['password'].value)">
        <mat-icon matPrefix>save</mat-icon>
        {{ 'GENERAL.SAVE_ACTION' | translate }}
      </button>
    </ng-template>

    <ng-template [ngIf]="selectedIndexTab === '1'">
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button [disabled]="!isInviteeEmailValid()" mat-raised-button color="primary" class="ml-3"
              (click)="openMemberInvitationDialog()">
        <mat-icon matPrefix>send</mat-icon>
        {{ 'INVITATIONS.SEND_INVITATION' | translate }}
      </button>
    </ng-template>
  </div>

</fivef-dialog>
