import { ContactOrganization } from '../models/contact';
import { IContactAddress, IContactEmailAddress, IContactPhoneNumber } from '../models/contact.interface';
import { ContactPerson } from '../models/contact';
import { RelationBuilder } from '../../../../+store/_legacy/api/builders/relation.builder';

export namespace ContactOrganizationBuilder {
  export function fromResponse(response, catalog = null): ContactOrganization {
    const attrs = response.attributes;
    const org = new ContactOrganization(response.id, attrs.visibility, attrs.note,
      attrs.name, attrs.abbreviation, attrs.legal_form_id);

    if (response.relationships && catalog) {
      const relationships = response.relationships;
      org.address = <IContactAddress>RelationBuilder.build(relationships.address, catalog);
      org.addresses = <IContactAddress[]>RelationBuilder.build(relationships.addresses, catalog);
      org.emailAddress = <IContactEmailAddress>RelationBuilder.build(relationships.email_address, catalog);
      org.emailAddresses = <IContactEmailAddress[]>RelationBuilder.build(relationships.email_addresses, catalog);
      org.phoneNumber = <IContactPhoneNumber>RelationBuilder.build(relationships.phone_number, catalog);
      org.phoneNumbers = <IContactPhoneNumber[]>RelationBuilder.build(relationships.phone_numbers, catalog);
      org.members = <ContactPerson[]>RelationBuilder.build(relationships.members, catalog);
    }
    return org;
  }

  export function toRequest(record: ContactOrganization) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          visibility: record.visibility,
          note: record.note,
          name: record.name,
          abbreviation: record.abbreviation,
          legal_form_id: record.legalFormId
        }
      }
    };
  }
}
