import {RequestConfirmation} from './request-confirmation';
import {OrganizationBuilder} from '../../../+store/_legacy/api/builders/organization.builder';
import {ResourceBuilder} from '../../../+store/_legacy/api/builders/resource.builder';
import {IThirdPartyConfirmation} from './thirdPartyConfirmation';

export namespace RequestConfirmationBuilder {
  export function fromResponse(response): RequestConfirmation {
    const request = new RequestConfirmation(
      response.id,
      response.type,
      response.attributes.status,
      response.attributes.third_party_name,
      response.attributes.due_date,
      response.attributes.received_at,
      OrganizationBuilder.fromResponse(response.relationships.contractee.data),
      ResourceBuilder._build(response.attributes.creation_object) as any as IThirdPartyConfirmation
    );

    request.state = response.attributes.state;
    return request;
  }

  export function toRequest(resource: RequestConfirmation) {
    return null;
  }
}
