import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {CollectorConfirmation} from './collector-confirmation';
import {CollectorConfirmationBuilder} from './collector-confirmation.builder';

@Injectable()
export class CollectorConfirmationService {
  readonly BASE_PATH = 'api/v1/clr/checklists';

  constructor(private _http: FivefApiResourceService) {}

  getAll(collectorId: string): Observable<CollectorConfirmation[]> {
    const builder = new CollectorConfirmationBuilder(collectorId);
    return <Observable<CollectorConfirmation[]>>this._http.get<CollectorConfirmationBuilder, CollectorConfirmation>(builder, `${this.BASE_PATH}/${collectorId}/recipient_confirmations`);
  }

  confirm(collectorId: string): Observable<CollectorConfirmation> {
    const builder = new CollectorConfirmationBuilder(collectorId);
    return <Observable<CollectorConfirmation>>this._http.post<CollectorConfirmationBuilder, CollectorConfirmation>(builder, `${this.BASE_PATH}/${collectorId}/recipient_confirmations`, {});
  }
}
