import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FibuBookmanAccount} from './fibu-bookman-account';
import {
  FibuBookmanAccountActionTypes,
  GetFibuBookmanAccount,
  GetFibuBookmanAccountFail,
  GetFibuBookmanAccountSuccess,
  SignInToFibuBookmanAccount,
  SignInToFibuBookmanAccountFail,
  SignInToFibuBookmanAccountSuccess,
  SignOutFromFibuBookmanAccount,
  SignOutFromFibuBookmanAccountFail,
  SignOutFromFibuBookmanAccountSuccess
} from './fibu-bookman-account.actions';
import {FibuBookmanAccountService} from './fibu-bookman-account.service';

@Injectable()
export class FibuBookmanAccountEffects {
  SignInToFibuBookmanAccount$ = createEffect(() => this.actions.pipe(
    ofType(FibuBookmanAccountActionTypes.SignInToFibuBookmanAccount),
    switchMap((action: SignInToFibuBookmanAccount) => {
      return this._svc.signInFibuBookmanAccount(action.payload).pipe(
        first(),
        concatMap((fibuBookmanAccount: FibuBookmanAccount) => {
          return [new SignInToFibuBookmanAccountSuccess(fibuBookmanAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SignInToFibuBookmanAccountFail(err));
        }));
    })
  ));

  SignOutFromFibuBookmanAccount$ = createEffect(() => this.actions.pipe(
    ofType(FibuBookmanAccountActionTypes.SignOutFromFibuBookmanAccount),
    switchMap((action: SignOutFromFibuBookmanAccount) => {
      return this._svc.signOutFibuBookmanAccount().pipe(
        first(),
        concatMap((fibuBookmanAccount: FibuBookmanAccount) => {
          return [new SignOutFromFibuBookmanAccountSuccess(fibuBookmanAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SignOutFromFibuBookmanAccountFail(err));
        }));
    })
  ));

  GetFibuBookmanAccount$ = createEffect(() => this.actions.pipe(
    ofType(FibuBookmanAccountActionTypes.GetFibuBookmanAccount),
    switchMap((action: GetFibuBookmanAccount) => {
      return this._svc.getFibuBookmanAccount().pipe(
        first(),
        concatMap((fibuBookmanAccount: FibuBookmanAccount) => {
          return [new GetFibuBookmanAccountSuccess(fibuBookmanAccount)];
        }),
        catchError(err => {
          console.error(err);
          return of(new GetFibuBookmanAccountFail(err));
        }));
    })
  ));

  constructor(
    private actions: Actions,
    private _svc: FibuBookmanAccountService
  ) {
  }
}
