import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {CollectAndDelegateWorkflowComponent} from './containers/collect-and-delegate-workflow/collect-and-delegate-workflow.component';
import {EditableItemComponent} from './components/editable-item/editable-item.component';
import {ItemEditorComponent} from './components/item-editor/item-editor.component';
import {TaxonomyBuilderComponent} from './components/taxonomy-builder/taxonomy-builder.component';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatTreeModule
  ],
  declarations: [
    CollectAndDelegateWorkflowComponent,
    EditableItemComponent,
    ItemEditorComponent,
    TaxonomyBuilderComponent
  ],
  exports: [
    CollectAndDelegateWorkflowComponent,
    SharedModule
  ]
})
export class CollectAndDelegateWorkflowModule {
}
