<div class="fivef-page-sidebar--container">
  <!-- Regular sidebar with process context -->
  <ng-container *ngIf="_process && !(sidebar$ | async)?.portal">
    <div class="fivef-page-sidebar--process-view">
      <!-- Top sidebar links -->
      <dvtx-sidebar-navigation [tasksRouteCount]="tasksRouteCount"
                               [hasAccessibleParent]="hasAccessibleParent$ | async"
                               [process]="_process"
                               [collapsed]="collapsed"
                               [routes]="sidebarWorkflowRoutes">
      </dvtx-sidebar-navigation>

      <hr>

      <div class="fivef-page-sidebar--tree-widget">
        <!-- Project structure -->
        <fivef-process-tree-sidebar-widget *ngIf="!documentListingPage"
                                           [processId]="_process?.id"></fivef-process-tree-sidebar-widget>

        <!-- Document structure for the document view -->
        <fivef-process-artifact-tree *ngIf="documentListingPage"
                                     [process]="_process"
                                     (onSelect)="select($event)">
        </fivef-process-artifact-tree>
      </div>
    </div>
  </ng-container>

  <!-- Portal panel for dynamic content -->
  <ng-container *ngIf="(sidebar$ | async)?.portal">
    <ng-template [cdkPortalOutlet]="(sidebar$ | async)?.portal"></ng-template>
  </ng-container>

  <!-- Organizational administration -->
  <ng-container *ngIf="showOrganizationSettings">
    <dvtx-sidebar-navigation class="fivef-page-sidebar--scrollable-container"
                             [navigationWidth]="true"
                             [title]="'BANNER.LINK.ORGANIZATION_SETTINGS'"
                             [collapsed]="collapsed"
                             [routes]="sidebarOrganizationRoutes">
    </dvtx-sidebar-navigation>

    <hr>
  </ng-container>

  <!-- User Profile / User Settings -->
  <ng-container *ngIf="showUserSettings">
    <dvtx-sidebar-navigation class="fivef-page-sidebar--scrollable-container"
                             [navigationWidth]="true"
                             [title]="'BANNER.LINK.PERSONAL_SETTINGS'"
                             [collapsed]="collapsed"
                             [routes]="sidebarUserRoutes">
    </dvtx-sidebar-navigation>

    <hr>
  </ng-container>
</div>
