import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Label} from 'app/+store/label/label';
import {LabelBuilder} from 'app/+store/label/label.builder';
import {Item, LabelItemType} from "./item";

export class ItemBuilder implements IApiResourceBuilder<Item> {
  labels: {
    [id: string]: Label
  } = {};

  total = 1;
  perPage = 1;
  records = 1;

  typeMap = {
    'collecto_item': LabelItemType.CollectoItem,
    'document': LabelItemType.Document,
    'standard_task': LabelItemType.Task,
    'approval_task': LabelItemType.TaskApproval,
  }

  fromResponse(data): Item {
    const item = new Item(
      data.id,
      data.attributes.reference_id,
      this.typeMap[data.attributes.reference_type],
      data.attributes.updated_at
    );

    item.title = data.attributes.title;
    item.processId = data.attributes.process_id;
    item.processTitle = data.attributes.process_title;
    item.processType = data.attributes.process_type;
    item.client = data.attributes.client_name;
    item.total = this.total;
    item.perPage = this.perPage;
    item.records = this.records;
    item.color = data.attributes.reference_color;
    if (data.relationships && data.relationships.labels && data.relationships.labels.data) {
      item.labelIds = data.relationships.labels.data.map(l => l.id);
    }
    return item;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    // const labelBuilder = new LabelBuilder();

    // includes.map((include) => {
    //   if (include.type === 'label') {
    //     this.labels[include.id] = labelBuilder.fromResponse(include);
    //   }
    // });
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }

  toRequest() {
    return null;
  }
}
