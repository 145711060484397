import {Injectable} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  AuditTrailActionTypes,
  Download,
  DownloadFail,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  SaveDownload
} from './audit-trail.actions';
import {AuditTrailService} from './audit-trail.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {FileUtils} from 'app/lib/file_utils/functions';
import {ProcessEvent} from '../process-event/process-event';
import {AuditTrail} from './audit-trail';

@Injectable()
export class AuditTrailEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(AuditTrailActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.processId).pipe(
        first(),
        switchMap((res: ProcessEvent[]) => {
          // const folders: Update<AuditTrail>[] = [];
          // for (const item of res) {
          //   folders.push( item);
          // }
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  download$ = createEffect(() => this.actions.pipe(
    ofType(AuditTrailActionTypes.Download),
    switchMap((action: Download) => {
      return this._svc.download(action.processId, action.format).pipe(
        first(),
        switchMap((auditTrail: AuditTrail) => {
          return [new SaveDownload(auditTrail.filename, auditTrail.content, auditTrail.mimeType)];
        }),
        catchError(err => {
          console.error(err);
          // this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE')
          return of(new DownloadFail(err));
        }));
    })
  ));

  saveTemplate$ = createEffect(() => this.actions.pipe(
    ofType(AuditTrailActionTypes.SaveDownload),
    switchMap((action: SaveDownload) => {
      FileUtils.saveAsFile(action.filename, action.content, action.mimeType);
      return of(null);
    })
  ), {dispatch: false});

  constructor(private actions: Actions,
              private _svc: AuditTrailService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




