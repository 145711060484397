import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from '../../../../+store/user/user';
import { AppState } from '../../../../app.state';
import { Store } from '@ngrx/store';

import { TwoFactorAuthService } from 'app/+store/two-factor-auth/two-factor-auth.service';
@Component({
  selector: 'dvtx-two-factor-auth-dialog',
  templateUrl: './two-factor-auth-dialog.component.html',
  styleUrls: ['./two-factor-auth-dialog.component.scss']
})
export class TwoFactorAuthDialogComponent implements OnInit, OnDestroy {
  public twoFactorAuthStatus;
  private onDestroy: Subject<void> = new Subject<void>();
  public Activated2FA: boolean = null;
  public enableTwoFactorAuth: boolean = null;
  public StepActivation;
  public twoFactorAuthEnabled: Observable<Boolean>;

  constructor(private _store: Store<AppState>, private _tfaService: TwoFactorAuthService) {
    this.twoFactorAuthEnabled = this._store.pipe(map(state => state['currentUser']), map((currentUser: User) => {
      return currentUser ? currentUser.otpRequiredForLogin : false;
    }));
    this.loadStatus();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private loadStatus() {
    this._tfaService.status().pipe(
      takeUntil(this.onDestroy)).subscribe((response) => {
        this.twoFactorAuthStatus = response;
      });
  }

  public submittedActivate(value) {
    this.Activated2FA = value;
    if (value === null) {
      this.enableTwoFactorAuth = null;
    }
    this.StepActivation = 0;
    this.loadStatus();
  }

  public enable2faFunction(value) {
    this.StepActivation = value;
  }
}
