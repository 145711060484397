import {Component, OnInit} from '@angular/core';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {Settings} from 'app/lib/fivef-ui/file/fivef-csv-import/fivef-csv-import.component';
import {getSelected} from 'app/+store/organization/organization.selectors';
import {first} from 'rxjs/operators';
import {Organization} from 'app/+store/organization/organization';
import * as vCard from 'vcf';
import {ImportContacts} from 'app/+store/contact/contact.actions';
import {ImportContactRow, visibilityTypes} from 'app/+store/contact/contact';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'dvtx-import-contacts',
  templateUrl: './import-contacts.html',
  styleUrls: ['./import-contacts.scss']
})
export class ImportContactsComponent implements OnInit {
  public csvSettings: Settings;
  public data: ImportContactRow[];

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.csvSettings = {
      columns: [
        {
          title: 'Anrede',
          key: 'title'
        },
        {
          title: 'Vorname',
          key: 'first_name'
        },
        {
          title: 'Nachname',
          key: 'last_name'
        },
        {
          title: 'Straße geschäftlich',
          key: 'street_business'
        },
        {
          title: 'Ort geschäftlich',
          key: 'city_business'
        },
        {
          title: 'Postleitzahl geschäftlich',
          key: 'zip_business'
        },
        {
          title: 'Land geschäftlich',
          key: 'country_business'
        },
        {
          title: 'Straße privat',
          key: 'street_private'
        },
        {
          title: 'Ort privat',
          key: 'city_private'
        },
        {
          title: 'Postleitzahl privat',
          key: 'zip_private'
        },
        {
          title: 'Land privat',
          key: 'country_private'
        },
        {
          title: 'Faxnummer geschäftlich',
          key: 'fax_business'
        },
        {
          title: 'Telefonnummer geschäftlich',
          key: 'phone_business'
        },
        {
          title: 'Telefonnummer privat',
          key: 'phone_private'
        },
        {
          title: 'Telefonnummer mobil',
          key: 'phone_mobile'
        },
        {
          title: 'Haupttelefon',
          key: 'phone_main'
        },
        {
          title: 'E-Mail-Adresse',
          key: 'email'
        }
      ],
      text: {
        displayTableTitle: 'Kontakte',
        sampleTitle: 'Beispiel für die Tabelle zum Import von Kontakten'
      },
      hideHint: true
    }
  }

  newVcf(file) {
    new Promise<string>(((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (readFile: any) => {
        resolve(readFile.target.result);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.onabort = (e) => {
        reject(e);
      };

      reader.readAsBinaryString(file);
    })).then((contents) => {
      const vcards = vCard.parse(contents);

      function propToValue(prop: any) {
        return prop ? prop.toJSON()[3] : prop;
      }

      function typeIs(prop, type) {
        type = (type + '').toLowerCase();
        return Array.isArray(prop.type) ?
          prop.type.indexOf(type) >= 0 :
          prop.type === type
      }

      function getFromList(list, type): any[] {
        if (!list) {
          return [];
        } else if (Array.isArray(list)) {
          return list.filter((entry) => typeIs(entry, type));
        } else if (typeIs(list, type)) {
          return [list];
        } else {
          return [];
        }
      }

      const rows = vcards.map((vcard) => {
        const name = propToValue(vcard.data.n);

        const workAddress = propToValue(getFromList(vcard.data.adr, 'work')[0]);
        const homeAddress = propToValue(getFromList(vcard.data.adr, 'home')[0]);

        const workPhone = propToValue(getFromList(vcard.data.tel, 'work')[0]);
        const homePhone = propToValue(getFromList(vcard.data.tel, 'home')[0]);
        const cellPhone = propToValue(getFromList(vcard.data.tel, 'cell')[0]);
        const mainPhone = propToValue(getFromList(vcard.data.tel, 'pref')[0]);
        const email = propToValue(vcard.data.email);

        return {
          title: name[3],
          first_name: name[1],
          last_name: name[0],
          street_business: workAddress ? workAddress[2] || '' : '',
          city_business: workAddress ? workAddress[3] || '' : '',
          zip_business: workAddress ? workAddress[5] || '' : '',
          country_business: workAddress ? workAddress[6] || '' : '',
          street_private: homeAddress ? homeAddress[2] || '' : '',
          city_private: homeAddress ? homeAddress[3] || '' : '',
          zip_private: homeAddress ? homeAddress[5] || '' : '',
          country_private: homeAddress ? homeAddress[6] || '' : '',
          fax_business: '',
          phone_business: workPhone || '',
          phone_private: homePhone || '',
          phone_mobile: cellPhone || '',
          phone_main: mainPhone || '',
          email: email
        }
      });

      this.data = rows;
    });
  }

  public cancel() {
    this.dialog.closeAll();
  }

  createContacts() {
    this.store.select(getSelected).pipe(
      first()
    ).subscribe((orga: Organization) => {
      this.store.dispatch(new ImportContacts(orga, this.data, {type: visibilityTypes.visiblePublic, visibleFor: ''}))
    });
    console.log('Create', this.data);
    this.dialog.closeAll();
  }
}
