<div [formGroup]="createPersonFrom"
     class="fivef-contact-person--container">
  <fivef-input-label>{{ 'CONTACTS.NAME' | translate }}</fivef-input-label>
  <div class="ff-icon-input">
    <mat-icon>account_box</mat-icon>
    <div class="ff-icon-input--content fivef-contact-person--name">
      <!-- gender is currently disabled by business decision. It is not evaluated anywhere anymore. -->
      <!--      <div class="col-12 col-sm-6 col-md-5">-->
      <!--        <dvtx-api-select-cva-->
      <!--          formControlName="title"-->
      <!--          class="type-or-location"-->
      <!--          listingPath="name"-->
      <!--          listingType="gender"-->
      <!--          [elementName]="'CONTACTS.GENDER' | translate"-->
      <!--        ></dvtx-api-select-cva>-->
      <!--      </div>-->
      <dvtx-text-input-cva
        class="fivef-contact-person--title"
        [label]="'CONTACTS.TITLE'"
        formControlName="honorific">
      </dvtx-text-input-cva>

      <dvtx-text-input-cva
        [label]="'CONTACTS.FIRST_NAME'"
        formControlName="firstName"
        [requiredField]="true">
      </dvtx-text-input-cva>

      <dvtx-text-input-cva
        [label]="'CONTACTS.LAST_NAME'"
        formControlName="lastName"
        [requiredField]="true">
      </dvtx-text-input-cva>
    </div>
  </div>

  <hr>

  <!--  email section-->
  <fivef-input-label>{{ 'GENERAL.EMAIL' | translate }}</fivef-input-label>
  <div class="ff-icon-input">
    <mat-icon>email</mat-icon>

    <dvtx-email-address-input class="ff-icon-input--content"
                              formControlName="mainEmailAddress"
                              [requiredField]="true"
                              [disabled]="disabled"
                              [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]">
    </dvtx-email-address-input>
  </div>

  <div class="ff-icon-input--indent-paragraph">
    <dvtx-email-address-group-cva
      *ngIf="displayFurtherDataFields === true && contactPerson"
      [emailAddresses]="$any(contactPerson).emailAddresses"
      (addEmail)="addFurtherEmail($event)"
      (removeEmail)="removeFurtherEmail($event)"
      [showAddEmail]="!disabled"
      [disabled]="disabled"></dvtx-email-address-group-cva>
  </div>

  <div class="ff-icon-input"
       *ngIf="newContactMode && (administrationRights | async)?.hasAdministrationRights && (featureSet$ | async)?.hasPartnerLinks">
    <mat-icon>link</mat-icon>
    <div class="ff-icon-input--content">
      <mat-checkbox
        [labelPosition]="'before'"
        formControlName="activatePartnerLinks">
        {{ 'PARTNER_LINK.ACTIVATE_PARTNERLINKS' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <hr>

  <!--telephone section-->
  <fivef-input-label>{{ 'CONTACTS.PHONE' | translate }}</fivef-input-label>
  <div class="ff-icon-input">
    <mat-icon>phone</mat-icon>
    <dvtx-telephone-input class="ff-icon-input--content"
                          [formControlName]="'mainPhoneNumber'" [isType]="true"></dvtx-telephone-input>
  </div>

  <div class="ff-icon-input--indent-paragraph">
    <dvtx-telephone-group-cva *ngIf="displayFurtherDataFields && contactPerson"
                              [telephonenumbers]="$any(contactPerson).phoneNumbers"
                              (addPhone)="addFurtherPhoneNumber($event)"
                              (removePhone)="removeFurtherPhoneNumber($event)"
                              [showAddPhone]="!disabled"
                              [disabled]="disabled">
    </dvtx-telephone-group-cva>
  </div>

  <hr>

  <!--  Address section -->
  <fivef-input-label>{{ 'CONTACTS.ADDRESS' | translate }}</fivef-input-label>
  <div class="ff-icon-input">
    <mat-icon class="dvtx-create-contact-person-dialog--mat-icon">place</mat-icon>

    <dvtx-address-input-cva class="ff-icon-input--content"
                            formControlName="mainAddress" [elementName]="'CONTACTS.ADDRESS' | translate"
                            [disabled]="disabled"></dvtx-address-input-cva>
  </div>

  <div class="ff-icon-input--indent-paragraph">
    <dvtx-address-input-group-cva
      *ngIf="displayFurtherDataFields && contactPerson"
      [enableLocationOrType]="true"
      [addresses]="$any(contactPerson).addresses"
      (addAddress)="addFurtherAddress($event)"
      [showAddAddress]="!disabled"
      (removeAddress)="removeFurtherAddress($event)"
      [disabled]="disabled"
    ></dvtx-address-input-group-cva>
  </div>
</div>
