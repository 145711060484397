import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';

/**
 * Route helper for a component doing nothing for testing loading scenarios.
 */
@Component({
  selector: 'fivef-blank',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fivef-blank.component.html',
  styleUrls: ['./fivef-blank.component.scss']
})
export class FivefBlankComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
