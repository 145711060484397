import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fivef-info',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule, MatButtonModule, MatIconModule],
  templateUrl: './five-info.component.html',
  styleUrls: ['./five-info.component.scss']
})
export class FiveInfoComponent implements OnInit {
  @Input() tooltip = '';

  constructor() { }

  ngOnInit(): void {
  }

}
