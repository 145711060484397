import {Pipe, PipeTransform} from '@angular/core';
import {ListingService} from '../../../+store/_legacy/api/services/listing.service';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

@Pipe({
  name: 'fivefLegalFormNameById',
  standalone: true
})
export class FivefLegalFormNameByIdPipe implements PipeTransform {
  private static legalForms: Observable<{ name: string, value: number }[]>;

  constructor(private listingService: ListingService) {
  }

  transform(value) {
    if (!FivefLegalFormNameByIdPipe.legalForms) {
      FivefLegalFormNameByIdPipe.legalForms = this.listingService.fetchListing('legal-form').pipe(shareReplay(1));
    }

    return FivefLegalFormNameByIdPipe.legalForms.pipe(
      map((legalForms) => {
        const numberValue = parseInt(value, 10);
        const matchingLegalForm = legalForms.find((legalForm) => legalForm.value === numberValue);

        return matchingLegalForm ? matchingLegalForm.name : null;
      }),
    );
  }
}
