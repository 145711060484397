<div class="ff-drag-n-drop--icon fivef-csv-import-dropzone--drag-n-drop-icon pt-1">
  <mat-icon aria-label="upload" class="mat-icon material-icons" role="img" aria-hidden="true">file_upload</mat-icon>
</div>
<div class="fivef-csv-import-dropzone--drag-n-drop-btn">
  <button class="mat-button mat-gray" (click)="openFileChooser()">{{ 'UPLOAD.DROP_ZONE_BUTTON' | translate }}</button>
  <p>{{ fileTypes.join(' / ') }} max 20MB</p>
  <p class="fivef-csv-import-dropzone--side-note" *ngIf="sideNote?.length > 0">{{ sideNote }}</p>
</div>
<input #fileChooser type="file" class="mat-button mat-gray" value=""
       (change)="onFileChange();$event.target.value = null;" style="display: none;"/>
