import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 * Simple e-mail validator. Expects form as string value.
 *
 * @constructor
 */
export function FivefEmailAddressValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input: string = control.value;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(String(input).toLowerCase());
    if (!valid) {
      return {'invalidEmail': 'invalidEmail'}
    } else {
      return null;
    }
  };
}
