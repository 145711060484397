import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {PartnerLink} from './partner-link';
import {PartnerLinkBuilder} from './partner-link.builder';

@Injectable()
export class PartnerLinkService {
  readonly BASE_PATH = 'api/v1/cms/partner_links';

  constructor(private _http: FivefApiResourceService) {}

  getAll(): Observable<PartnerLink[]> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink[]>>this._http.get<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}`);
  }

  getOne(id: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.get<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${id}`);
  }

  update(partnerLink: PartnerLink): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    const payload = builder.toRequest(partnerLink);
    return <Observable<PartnerLink>>this._http.put<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLink.id}`, payload);
  }

  markVisible(partnerLinkId: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.post<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/visible`, {});
  }

  markHidden(partnerLinkId: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.del<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/visible`);
  }
}
