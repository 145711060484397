import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FivefApiResourceService } from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import { HttpParams } from '@angular/common/http';
import { TasksStatsBuilder } from './tasks-stats.builder';
import { TasksStats } from './tasks-stats';

@Injectable()
export class TasksStatsService {

  readonly basePath = 'api/v1/tasks/stats';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll() {
    const builder = new TasksStatsBuilder();
    return <Observable<TasksStats>>this._http.get<TasksStatsBuilder, TasksStats>(builder, `${this.basePath}`);
  }
}
