import {IAuditOrder} from '../models/audit-order.interface';
import {AnnualAudit, GroupAudit} from '../models/audit-order';
import {RelationBuilder} from './relation.builder';
import {Organization} from '../models/organization';
import {Process} from '../models/process';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';

export namespace AuditOrderBuilder {
  export function fromResponse(response, catalog = null): IAuditOrder {
    const attrs = response.attributes;
    switch (response.type) {
      case 'audit_annual_auditings':
        const annualAudit =  new AnnualAudit(response.id, attrs.process_id,
          attrs.identifier, attrs.type_of_auditing,
          attrs.business_year_start, attrs.business_year_end, null, null);

        if (response.relationships && catalog) {
          // console.log('%c Relationsships: ', 'color: var(--fivef-color-warn)', response.relationships);
          // console.log('%c Catalog: ', 'color: var(--fivef-color-warn)', catalog);
          const relationships = response.relationships;
          annualAudit.contractee = <Organization>RelationBuilder.build(relationships.contractee, catalog);
          annualAudit.contractor = <Organization>RelationBuilder.build(relationships.contractor, catalog);
          annualAudit.process = <Process>RelationBuilder.build(relationships.process, catalog);
        }
        return annualAudit;
      case 'audit_group_auditings':
        const groupAudit =  new GroupAudit(response.id, attrs.process_id,
          attrs.identifier, attrs.type_of_auditing,
          attrs.business_year_start, attrs.business_year_end, null, null);

        if (response.relationships && catalog) {
          // console.log('%c Relationsships: ', 'color: var(--fivef-color-warn)', response.relationships);
          // console.log('%c Catalog: ', 'color: var(--fivef-color-warn)', catalog);
          const relationships = response.relationships;
          groupAudit.contractee = <Organization>RelationBuilder.build(relationships.contractee, catalog);
          groupAudit.contractor = <Organization>RelationBuilder.build(relationships.contractor, catalog);
          groupAudit.process = <Process>RelationBuilder.build(relationships.process, catalog);
        }
        return groupAudit;
      default:
        return null;
    }
  }

  export function toRequest(record: IAuditOrder) {
    return {
      data: {
        id: record.id,
        type: record.type,
        attributes: {
          identifier: record.identifier,
          type_of_auditing: record.typeOfAuditing,
          business_year_start: record.businessYearStart,
          business_year_end: record.businessYearEnd,
          business_partner_uuid: record.contractee.id,
          business_partner_name: record.contractee.name,
          dms_folder_id: record.dmsFolderId,
          dms_account_type: record.dmsAccountType === DmsAccountType.Organization ? 'organization' : 'private',
          dms_path: record.dmsPath
        }
      }
    };
  }
}
