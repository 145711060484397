import {Injectable} from '@angular/core';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Observable} from 'rxjs/internal/Observable';
import * as model from './cms-slideshow';
import * as int from './cms-slideshow.interface';
import {CmsSlideBuilder, CmsSlideshowBuilder} from './cms-slideshow.builder';

@Injectable()
export class CmsSlideshowService {
  readonly BASE_PATH = 'api/v3/cms/';

  constructor(private _http: FivefApiResourceService) {
  }

  getSlideshows(profile: int.Cms.SlideProfile): Observable<model.Cms.Slideshow[]> {
    const builder = new CmsSlideshowBuilder();
    return <Observable<model.Cms.Slideshow[]>>this._http.get<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows?profile=${profile}`);
  }

  getSlideshow(id: string): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    return <Observable<model.Cms.Slideshow>>this._http.get<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${id}`);
  }

  createSlideshow(slideshow: model.Cms.Slideshow): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    const payload = builder.toRequest(slideshow);
    return <Observable<model.Cms.Slideshow>>this._http.post<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows`, payload);
  }

  updateSlideshow(slideshow: model.Cms.Slideshow): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    const payload = builder.toRequest(slideshow);
    return <Observable<model.Cms.Slideshow>>this._http.put<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${slideshow.id}`, payload);
  }

  deleteSlideshow(id: string): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    return <Observable<model.Cms.Slideshow>>this._http.del<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${id}`);
  }

  addSlideToSlideshow(id: string, slideId: string, language: int.Cms.SlideLanguage): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    const payload = {
      data: {
        attributes: {
          language: language,
          slide_id: slideId
        }
      }
    }
    return <Observable<model.Cms.Slideshow>>this._http.post<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${id}/add_slide`, payload);
  }

  removeSlideFromSlideshow(id: string, slideId: string, slideshowSlideId: string): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    const payload = {
      data: {
        attributes: {
          slide_id: slideId,
          slideshow_slide_id: slideshowSlideId
        }
      }
    }
    return <Observable<model.Cms.Slideshow>>this._http.post<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${id}/remove_slide`, payload);
  }

  reorder(id: string, reorderedSlideIds: string[]): Observable<model.Cms.Slideshow> {
    const builder = new CmsSlideshowBuilder();
    const payload = {
      data: {
        attributes: {
          reordered_ids: reorderedSlideIds
        }
      }
    }
    return <Observable<model.Cms.Slideshow>>this._http.post<CmsSlideshowBuilder, model.Cms.Slideshow>(builder, `${this.BASE_PATH}/slideshows/${id}/reorder`, payload);
  }

  getSlides(profile: int.Cms.SlideProfile): Observable<model.Cms.Slide[]> {
    const builder = new CmsSlideBuilder();
    return <Observable<model.Cms.Slide[]>>this._http.get<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides?profile=${profile}`);
  }

  getSlide(id: string, profile: int.Cms.SlideProfile): Observable<model.Cms.Slide> {
    const builder = new CmsSlideBuilder();
    return <Observable<model.Cms.Slide>>this._http.get<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides/${id}?profile=${profile}`);
  }

  deleteSlide(id: string): Observable<model.Cms.Slide> {
    const builder = new CmsSlideBuilder();
    return <Observable<model.Cms.Slide>>this._http.del<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides/${id}`);
  }

  createSlide(slide: model.Cms.Slide): Observable<model.Cms.Slide> {
    const builder = new CmsSlideBuilder();
    const payload = builder.toRequest(slide);
    return <Observable<model.Cms.Slide>>this._http.post<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides`, payload);
  }

  updateSlide(slide: model.Cms.Slide): Observable<model.Cms.Slide> {
    const builder = new CmsSlideBuilder();
    const payload = builder.toRequest(slide);
    return <Observable<model.Cms.Slide>>this._http.put<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides/${slide.id}`, payload);
  }

  removeContentImageFromSlide(slide: model.Cms.Slide): Observable<model.Cms.Slide> {
    const builder = new CmsSlideBuilder();
    return <Observable<model.Cms.Slide>>this._http.post<CmsSlideBuilder, model.Cms.Slide>(builder, `${this.BASE_PATH}/slides/${slide.id}/remove_content_image`, {});
  }
}
