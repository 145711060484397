import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {FiltersContainerModule} from 'app/lib/fivef-legacy/filters/filters-container.module';
import {TasksFiltersComponent} from './container/tasks-filters.component';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module'
import {FivefSearchComponent} from '../../../../lib/fivef-ui/input/fivef-search/fivef-search.component';

@NgModule({
  imports: [
    SharedModule,
    FiltersContainerModule,
    TooltipModule,
    FivefSearchComponent
  ],
  declarations: [
    TasksFiltersComponent
  ],
  exports: [
    TasksFiltersComponent
  ]
})
export class TasksFiltersModule {
}
