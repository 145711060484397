import {NgModule} from '@angular/core';
import {PersonsFilterComponent} from './components/persons-filter/persons-filter.component';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {FivefAvatarModule} from '../../fivef-ui/profile/fivef-avatar/fivef-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    TranslateModule,
    MatIconModule,
    MatSelectModule,
    FivefAvatarModule
  ],
  declarations: [
    PersonsFilterComponent
  ],
  exports: [
    PersonsFilterComponent
  ]
})
export class PersonsFilterModule {
}
