<div *ngIf="organization"
     class="ff-content-container-width fivef-organization-master-data--container">
  <h3 class="ff-section-title">{{ 'ORGANIZATION.MASTER_DATA_TITLE' | translate}}</h3>

  <div class="ff-icon-input">
    <mat-icon>domain</mat-icon>

    <div class="ff-icon-input--content ff-max500">
      <mat-form-field fivefDisableInputHints>
        <input matInput
               [(ngModel)]="organization.name"
               [placeholder]="'CONTACTS.ORG_NAME' | translate"
               [disabled]="readonly || !editmode" required/>
      </mat-form-field>

      <dvtx-api-select-cva class="fivef-organization-master-data--legal-id"
                           [elementName]="'CONTACTS.LEGAL_FORM' | translate"
                           listingType="legal-form"
                           listingPath="name"
                           [disabled]="readonly || !editmode"
                           [(ngModel)]="organization.legalFormId">
      </dvtx-api-select-cva>
    </div>
  </div>

  <div class="ff-icon-input">
    <mat-icon>email</mat-icon>
    <dvtx-email-address-input class="ff-icon-input--content ff-max500"
                              [showEmailType]="false"
                              [requiredField]="true"
                              [(ngModel)]="organization.email"
                              [disabled]="readonly || !editmode">
    </dvtx-email-address-input>
  </div>

  <div class="ff-icon-input">
    <mat-icon>phone</mat-icon>
    <dvtx-telephone-input class="ff-icon-input--content ff-max500"
                          [(ngModel)]="organization.telephone"
                          [disabled]="readonly || !editmode"
                          [isType]="false">
    </dvtx-telephone-input>
  </div>

  <div class="ff-icon-input">
    <mat-icon>place</mat-icon>
    <dvtx-address-input-form class="ff-icon-input--content"
                             [address]="organization.address" [disabled]="readonly || !editmode"></dvtx-address-input-form>
  </div>

  <hr>

  <h3 class="ff-section-title">{{ 'CLIENT.DATEV_CONSULTANT_TITLE' | translate }}</h3>

  <div class="ff-icon-input">
    <mat-icon svgIcon="datev"></mat-icon>

    <mat-form-field fivefDisableInputHints>
      <input matInput
             [(ngModel)]="consultantId"
             [placeholder]="'CLIENT.DATEV_CONSULTANT_ID' | translate"
             [disabled]="readonly || !editmode" />
    </mat-form-field>
  </div>
</div>
