import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {TaskStatus} from './task-status';

export class TaskStatusBuilder implements IApiResourceBuilder<TaskStatus> {
  fromResponse(data): TaskStatus {
    const status = new TaskStatus(
      data.id,
      data.attributes.title,
      data.attributes.color,
      data.attributes.order,
      data.attributes.status_scheme,
      data.attributes.created_at,
      data.attributes.updated_at);
    return status;
  }

  toRequest(status: TaskStatus) {
    return {
      data: {
        id: status.id,
        type: 'task_status',
        attributes: {
          title: status.title,
          color: status.color,
          status_scheme: status.statusScheme,
        }
      }
    }
  }
}
