import {IDueDatable} from 'app/lib/date/date-util';
import {CollectorCategory} from '../collector-category/collector-category';
import {CollectorItem} from '../collector-item/collector-item';
import {IBomDataNode} from '../bom/bom-data-node.interface';
import {DocumentType} from '../process-artifact/process-artifact';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {ProcessProfile} from '../process/process.interface';

export enum CollectorElementType {
  Category = 'Category',
  Item = 'Item',
  DataNode = 'DataNode'
}

export interface ICollectorElement extends IBomDataNode {
  type: CollectorElementType;

  clone(): ICollectorElement;
}

export interface ICollectorCategory extends ICollectorElement {
  processId: string;
  documentType?: DocumentType
  items: CollectorItem[];
  responsibleId?: string;
  lockedAt?: Date;
}

export interface ICollectorItem extends ICollectorElement {
  categoryId: string;
  documentType?: DocumentType
  responsibleId?: string;
  lockedAt?: Date;
}

export interface ICollector {
  title: string;
  description: string;
  categories: CollectorCategory[];
  processType: string;
  profile: ProcessProfile;
  state: string;
  color?: string;
  creatorName: string;
  creatorEmail: string;
  ownerName: string;
  ownerEmail: string;
  recipients: string[];
  workers: string[];
  identifier: string;
  createdAt: Date;
  updatedAt: Date;
  dueDate?: Date;
  startsAt?: Date;
  endsAt?: Date;
  startedAt?: Date;
  dmsFolderId?: string;
}

export interface IStatusNode extends IDueDatable {
  id: string;
  status: ItemStatus;
  children: IStatusNode[];
}

export interface IStatusNodeVisitor {
  accept(element: IStatusNode);
}

export enum ItemStatus {
  Draft = 'STATUS.DRAFT',
  Sent = 'STATUS.SENT',
  Received = 'STATUS.RECEIVED',
  Overdue = 'STATUS.OVERDUE',
  Info = 'STATUS.INFO',
  Completed = 'STATUS.COMPLETED',
  Review = 'STATUS.REVIEW',
  Accepted = 'STATUS.ACCEPTED',
  Warning = 'STATUS.WARNING',
  Error = 'STATUS.ERROR',
  // NEW SET
  Open = 'STATUS.OPEN',
  InProgress = 'STATUS.IN_PROGRESS',
  Closed = 'STATUS.CLOSED'
}

// Found icons
// received send warning check_circle check filter_list done_all error_outline error poll
export const ItemStatusIcon = {
  'STATUS.DRAFT': 'drafts',
  'STATUS.SENT': 'send',
  'STATUS.RECEIVED': 'call_received',
  'STATUS.OVERDUE': 'alarm',
  'STATUS.INFO': 'info',
  'STATUS.COMPLETED': 'check',
  'STATUS.REVIEW': 'remove_red_eye',
  'STATUS.ACCEPTED': 'done_all',
  'STATUS.WARNING': 'warning',
  'STATUS.ERROR': 'error',
  // NEW SET
  'STATUS.OPEN': 'radio_button_unchecked',
  'STATUS.IN_PROGRESS': 'timelapse',
  'STATUS.CLOSED': 'check_circle'
};

export const StatusColorMapping = {
  'STATUS.DRAFT': '#5bc4f1',
  'STATUS.SENT': '#8a8a8a',
  'STATUS.RECEIVED': '#5bc4f1',
  'STATUS.OVERDUE': '#db0000',
  'STATUS.INFO': '#5bc4f1',
  'STATUS.COMPLETED': '#2CAAAF',
  'STATUS.REVIEW': '#5bc4f1',
  'STATUS.ACCEPTED': '#00ca5c',
  'STATUS.WARNING': '#ff8800',
  'STATUS.ERROR': '#db0000',
  // NEW SET
  'STATUS.OPEN': 'ff8800',
  'STATUS.IN_PROGRESS': '5bc4f1',
  'STATUS.CLOSED': '00ca5c'
};

export const ItemStatusToApiStatusMapping = {
  'STATUS.DRAFT': 'draft',
  'STATUS.SENT': 'published',
  'STATUS.RECEIVED': 'received',
  'STATUS.COMPLETED': 'completed',
  'STATUS.REVIEW': 'review',
  'STATUS.ACCEPTED': 'accepted',
  // NEW SET
  'STATUS.OPEN': 'published',
  'STATUS.IN_PROGRESS': 'in_progress',
  'STATUS.CLOSED': 'closed'
};

export const ApiStatusToItemStatusMapping = {
  'draft': ItemStatus.Open,
  'received': ItemStatus.Open,
  'completed': ItemStatus.InProgress,
  'review': ItemStatus.InProgress,
  'accepted': ItemStatus.InProgress,
  // NEW SET
  'published': ItemStatus.Open,
  'in_progress': ItemStatus.InProgress,
  'closed': ItemStatus.Closed
};

export const CollectoElementStatus = [
  {
    title: ItemStatus.Open,
    id: ItemStatus.Open,
    icon: ItemStatusIcon[ItemStatus.Open],
    color: StatusColorMapping[ItemStatus.Open]
  },
  {
    title: ItemStatus.InProgress,
    id: ItemStatus.InProgress,
    icon: ItemStatusIcon[ItemStatus.InProgress],
    color: StatusColorMapping[ItemStatus.InProgress]
  },
  {
    title: ItemStatus.Closed,
    id: ItemStatus.Closed,
    icon: ItemStatusIcon[ItemStatus.Closed],
    color: StatusColorMapping[ItemStatus.Closed]
  }
];

export interface ICollectorParams {
  title: string;
  parentId?: string;
  dmsFolderId?: string;
  dmsAccountType?: DmsAccountType;
  description?: string;
  dueDate?: Date;
  profile?: string;
  identifier?: string;
  clientId?: string;
}
