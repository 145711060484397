import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {MessageSettingsV2, NotificationProfile, TimeZone} from './message-settings';

export class MessageSettingsV2Builder implements IApiResourceBuilder<MessageSettingsV2> {
  fromResponse(data): MessageSettingsV2 {
    return new MessageSettingsV2(
      data.id,
      data.attributes.type === 'global_settings',
      data.attributes.by_inbox,
      data.attributes.by_email,
      data.attributes.by_mobile,
      data.attributes.activities,
      !!data.attributes.override_global_off,
      data.attributes.notification_profile,
      data.attributes.timezone);
  }

  toRequest(settings: MessageSettingsV2) {
    return {
      data: {
        id: settings.id,
        type: 'settings_domain_notifications',
        attributes: {
          by_inbox : settings.byInbox,
          by_email: settings.byEmail,
          by_mobile: settings.byMobile,
          activities: settings.activities,
          override_global_off: settings.globalOverride
        }
      }
    }
  }
}

export class TimeZoneBuilder implements IApiResourceBuilder<TimeZone> {
  fromResponse(data): TimeZone {
    const zone = new TimeZone(
      data.id,
      data.attributes.mapping,
      data.attributes.offset);
    zone.offsetMultiHour = Math.abs(data.attributes.offset) > 1;
    return zone;
  }

  toRequest(zone: TimeZone) {
    return {
      'data': {
        'id': zone.id,
        'type': zone.type,
        'attributes': {
          'time_zone': zone.id
        }
      }
    };
  }
}

export class NotificationProfileBuilder implements IApiResourceBuilder<NotificationProfile> {
  fromResponse(data): NotificationProfile {
    return new NotificationProfile(data.id);
  }

  toRequest(profile: NotificationProfile) {
    return {
      'data': {
        'id': profile.id,
        'type': profile.type,
        'attributes': {
          'notification_profile': profile.id
        }
      }
    };
  }
}
