import {Component, Input} from '@angular/core';
import {ParsedCsv} from '../fivef-csv-import.service';

@Component({
  selector: 'dvtx-csv-import-error-box',
  templateUrl: './fivef-csv-import-error-box.component.html',
  styleUrls: ['./fivef-csv-import-error-box.component.scss']
})
export class FivefCsvImportErrorBoxComponent {
  @Input() public fileName: string;

  @Input() public numberOfErrors: number;
}
