
import {takeUntil} from 'rxjs/operators';
import { ChangeDetectorRef, Directive, EventEmitter, ElementRef, HostBinding, Input, NgZone,
  OnChanges, OnInit, OnDestroy, Optional, Output, SkipSelf
} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

const DISABLED_OPACITY = 0.3;

export function resolve(optDisabled?: FivefDisabledToggleDirective): FivefDisabledToggleDirective {
  return optDisabled || defaultDisabled;
};

@Directive({
  selector: '[fivefDisabled]',
  standalone: true
})
export class FivefDisabledToggleDirective implements OnInit, OnDestroy, OnChanges {
  @Output() fivefDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() fivefDisabled: boolean = false;
  @Input() fivefDisabledStopPropagation: boolean = false;
  private onDestroy = new Subject();

  public disabled: boolean = false;
  private element: HTMLElement;

  constructor(private elementRef: ElementRef,
              @SkipSelf() @Optional() private optParent: FivefDisabledToggleDirective,
              @Optional() private changeDetector: ChangeDetectorRef,
              @Optional() private zone: NgZone) {
    this.fivefDisabledChange = new EventEmitter<boolean>(false);

    if (optParent) {
      optParent.onChange(this, () => this.checkForChanges());
    }
  }

  ngOnChanges() {
    this.checkForChanges();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    this.element = this.elementRef.nativeElement;
  }

  private checkForChanges() {
    setTimeout(() => {
      let newValue = false;

      if (this.fivefDisabledStopPropagation || !this.optParent) {
        newValue = !!this.fivefDisabled;
      } else {
        newValue = !!this.fivefDisabled || this.optParent.disabled;
      }

      if (this.zone && newValue !== this.disabled) {
        this.zone.run(() => {
          if (this.changeDetector) {
            this.changeDetector.markForCheck();
          }
          this.disabled = newValue;
          this.fivefDisabledChange.emit(newValue);
        });
      }
    }, 0);
  }

  /**
   * Alerts the callback when the disabled state changes
   */
  onChange(directive: FivefDisabledToggleDirective, callback: (opt?: boolean) => void) {
    this.fivefDisabledChange.pipe(takeUntil(this.onDestroy)).subscribe(callback);
  }
}

const defaultDisabled = new FivefDisabledToggleDirective(null, null, null, null);

@Directive({
  selector: '[fivefDefaultDisabled]',
  standalone: true
})
export class DefaultDisabledStateDirective {
  disabledDirective: FivefDisabledToggleDirective;

  @HostBinding('style.opacity') opacity: string;
  @HostBinding('style.pointerEvents') pointer: string;

  get disabled(): boolean {
    this.opacity = this.disabledDirective.disabled ? '"' + DISABLED_OPACITY + '"' : undefined;
    this.pointer = this.disabledDirective.disabled ? 'none' : undefined;
    return this.disabledDirective.disabled;
  }

  constructor(@Optional() optDisabled: FivefDisabledToggleDirective) {
    this.disabledDirective = resolve(optDisabled);
  }
}
