import {ProcessParticipant} from "../process-participant/process-participant";
import {ProcessStatus} from "../process/process";
import {IResource} from "../../lib/fivef-net/fivef-api-resource/models/resource.interface";
import {ProcessProfile} from "../process/process.interface";

export default class FavoriteProcess implements IResource {
  type: string = 'favorite-process';
  participants: ProcessParticipant[];
  clientName: string;
  status: ProcessStatus;
  color: string = '#fff';
  creatorEmail: string;
  creatorName: string;
  organizationId: string;
  canCreateTask: boolean;
  lastUpdatedByEmail: string;
  lastUpdatedByName: string;
  profile: ProcessProfile;
  parentId: string;

  total: number;
  perPage: number;
  records: number;
  timestamp: number;

  constructor(public id: string,
              public title: string,
              public subtitle: string,
              public processType: string,
              public dueDate: Date,
              public createdAt: Date,
              public updatedAt: Date,
              public favoriteId: string,
              public favoriteConfirmedAt: Date) {
  }

}
