import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {ClientActions, ClientSelectors} from 'app/+store/client';
import {Client} from 'app/+store/client/client';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ClientContactType} from 'app/+store/client/client.interface';
import {ContactListDto, contactListDtoType} from 'app/+store/contact/contact';
import {OrganizationSelectors} from 'app/+store/organization';
import {distinctUntilKeyChanged, filter, first, takeUntil} from 'rxjs/operators';
import {ClientService} from 'app/+store/client/client.service';
import {SimplePhoneNumber} from '../../../../+store/contact/legacy/models/contact.interface';
import {Router} from '@angular/router';
import {StringValidator} from 'app/lib/validator';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {BookmanClient} from 'app/+store/bookman-client/bookman-client';
import {BookmanClientSelectors} from 'app/+store/bookman-client';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FivefConfirm} from '../../util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefInputLabelComponent} from '../../input/fivef-input-label/fivef-input-label.component';

/**
 * Embedment types e.g. button types for the dialog version or a direct form.
 */
export enum ClientCreateActionAppearance {
  EditButton = 'EditButton',
  CreateButton = 'CreateButton',
  CreateRaisedButton = 'CreateRaisedButton',
  CreateBasicButton = 'CreateBasicButton',
  DeleteButton = 'DeleteButton',
  Listing = 'Listing',
  Form = 'Form',
  None = 'None'
}

/**
 * Create client component with multiple appearances depending on how the dialog should be embedded.
 *
 * See ClientCreateActionAppearance for options to embed the form/dialog.
 */
@Component({
  selector: 'fivef-create-client',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule, MatInputModule, ReactiveFormsModule, MatTooltipModule, FivefDialogComponent, FivefInputLabelComponent],
  templateUrl: './fivef-create-client.component.html',
  styleUrls: ['./fivef-create-client.component.scss']
})
export class FivefCreateClientComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  readonly ClientCreateActionAppearance = ClientCreateActionAppearance;
  public AddressbookContactType = contactListDtoType;

  contact?: ContactListDto;
  client: Client;
  deleteDisabled = true;

  bookmanClients$: Observable<BookmanClient[]>;
  bookmanClientsFiltered$: Observable<BookmanClient[]>;
  bookmanClientsLoadingState$: Observable<boolean>;
  bookmanClientsLoaded = false;
  selectedBookmanClient: BookmanClient;

  @Input()
  readonly = false;

  @Input()
  position = 'center';

  @Input()
  enableSaveButton = false;

  submitOngoing = false;

  form: UntypedFormGroup;
  clients$: Observable<Client[]>;
  organizationId: string;

  @ViewChild('clientCreationDialogTpl', {static: true})
  clientCreationDialogTpl: TemplateRef<any>;

  @ViewChild('clientCreationForm', {static: true})
  clientCreationForm: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  organization;

  enableCreation = false;
  dialogTitle = '';
  @Input()
  saveButtonTitle = 'GENERAL.SAVE_ACTION';

  showForm = true;
  _appearance: ClientCreateActionAppearance;
  disabled = false;

  @Output()
  onClientChange = new EventEmitter();

  @Output()
  onEditSuccess = new EventEmitter();

  @Output()
  onCreateSuccess = new EventEmitter();

  @Output()
  onFormUpdate = new EventEmitter();

  featureSet$: Observable<Feature>;

  @Input()
  set selectedClient(c: Client) {
    this.client = c;
    if (c) {
      this._initForm();
      this.setClient();
      this.form.markAsPristine();
    }
  }

  @Input()
  set appearance(a: ClientCreateActionAppearance) {
    this._appearance = a;
    switch (a) {
      case ClientCreateActionAppearance.Listing:
        this.showForm = false;
        break;
      case ClientCreateActionAppearance.CreateButton:
      case ClientCreateActionAppearance.CreateRaisedButton:
      case ClientCreateActionAppearance.CreateBasicButton:
        this.dialogTitle = 'CLIENT.CREATE_CLIENT';
        this.saveButtonTitle = 'CLIENT.CREATE_CLIENT';
        this.enableCreation = true;
        break;
      case ClientCreateActionAppearance.EditButton:
        this.dialogTitle = 'CLIENT.EDIT_CLIENT';
        this.saveButtonTitle = 'GENERAL.APPLY_CHANGE_ACTION';
        break;
      case ClientCreateActionAppearance.Form:
        this.showForm = true;
        break;
      case ClientCreateActionAppearance.None:
        this.disabled = true;
        break;
    }
  }

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _router: Router,
              private _fb: UntypedFormBuilder,
              private _clientSvc: ClientService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.bookmanClients$ = this._store.select(BookmanClientSelectors.getAll);
    this.bookmanClientsLoadingState$ = this._store.select(BookmanClientSelectors.loadingState);
    this.clients$ = this._store.select(ClientSelectors.getAllClients);
    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(x => !!x), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe(organization => {
        this.organization = organization;
        // this._store.dispatch(new ContactActions.LoadAll(organization))
        this.organizationId = organization.id;
      });
    this._initForm();

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setClient() {
    if (!this.client) return;

    this.deleteDisabled = this.client.processCount > 0;
    this.selectedBookmanClient = new BookmanClient(Number(this.client.bookmanClientId), this.client.bookmanClientName);
    this.form.reset();
    const phone = new SimplePhoneNumber();
    phone.phoneNumber = this.client.phone;
    this.form.patchValue({
      id: this.client.id,
      contactId: this.client.contactId,
      clientId: this.client.clientId,
      bookmanClientId: this.selectedBookmanClient ? this.selectedBookmanClient.id : null,
      name: this.client.name,
      title: this.client.title,
      gender: this.client.gender,
      firstName: this.client.firstName,
      lastName: this.client.lastName,
      contactType: this.client.contactType,
      email: this.client.email,
      street: this.client.street,
      streetNo: this.client.streetNo,
      city: this.client.city,
      zip: this.client.zip,
      phone: phone
    });
    this.form.markAsDirty();
  }

  submit() {
    if (this.form.invalid) return;

    const values = this.form.value;
    const client = new Client(values.id, values.contactType, values.name, values.email, values.gender,
      values.title, values.firstName, values.lastName, values.clientId, this.organizationId, values.contactId);
    if (this.selectedBookmanClient) {
      client.bookmanClientId = this.selectedBookmanClient.id;
      client.bookmanClientName = this.selectedBookmanClient.name;
    }
    client.street = values.street;
    client.streetNo = values.streetNo;
    client.city = values.city;
    client.zip = values.zip;
    client.legalForm = values.legalForm;
    client.phone = values.phone.phoneNumber;

    this.submitOngoing = true;
    if (values.id) {
      this._clientSvc.update(client).pipe(first()).subscribe((_client: Client) => {
        this.form.patchValue({id: _client.id});
        this.form.markAsPristine();
        this._cdr.detectChanges();
        this._store.dispatch(new ClientActions.SaveSuccess(_client));
        this.submitOngoing = false;
        this.onEditSuccess.emit(_client);
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }, (err) => {
        this.submitOngoing = false;
      });
    } else {
      this._clientSvc.create(client)
        .pipe(first())
        .subscribe((_client: Client) => {
          this.form.patchValue({id: _client.id});
          this.form.markAsPristine();
          this._cdr.detectChanges();
          this._store.dispatch(new ClientActions.CreateSuccess(_client));
          this.submitOngoing = false;
          this.onClientChange.emit(_client);
          this.onCreateSuccess.emit(_client);
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this._reset();
        }, (err) => {
          this.submitOngoing = false;
        });
    }
  }

  closeDialog() {
    this._reset();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  @FivefConfirm({
    message: 'GENERAL.CONFIRM_PROCEED',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_CONFIRM_ACTION'
  })
  public deleteClient(clientId, navigateToOverview = false) {
    this._clientSvc.destroy(clientId).pipe(first()).subscribe(_client => {
      this._reset();
      this._store.dispatch(new ClientActions.RemoveSuccess(_client));
      if (navigateToOverview) {
        this._router.navigate([`/organization/${this.organizationId}/addressbook/clients`]);
      }
    }, error => console.error(error));
  }

  public openDialog() {
    this.dialogRef = this._dialog.open(this.clientCreationDialogTpl)
    this.dialogRef.afterClosed().subscribe(_ => {
      this._reset();
    });
  }

  private _initForm() {
    if (this.form) return;

    this.form = this._fb.group({
      id: null,
      name: ['', [Validators.required, StringValidator.noWhitespaceValidator]],
      contactType: [ClientContactType.Organization, Validators.required],
      clientId: '',
      contactId: '',
      bookmanClientId: null,
      gender: '',
      title: '',
      firstName: '',
      lastName: '',
      legalForm: '',
      street: '',
      streetNo: '',
      city: '',
      zip: '',
      phone: new SimplePhoneNumber(),
      email: [null]
    });

    this.onFormUpdate.emit(this.form);
  }

  private _reset() {
    this.submitOngoing = false;
    if (this._appearance === ClientCreateActionAppearance.EditButton) return;

    this.form.reset();
    this.form.patchValue({contactType: ClientContactType.Organization, phone: new SimplePhoneNumber()});
    this.form.markAsPristine();
    this.contact = null;
    this.client = null;
  }
}
