import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {EnvService} from './env.service';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IApiResponse, IApiResourceBuilder} from '../models/api.interface'
import {ApiResourceBuilder} from '../builders/api-resource.builder'
import {first, map} from 'rxjs/operators';
import {saveAs} from 'file-saver';

export class SimpleMessage {
  readonly id = 'simple_messsage';
  readonly type = 'simple_messsage';

  constructor(public message: string) {
  }
}

export class SimpleMessageBuilder implements IApiResourceBuilder<SimpleMessage> {
  fromResponse(data): SimpleMessage {
    if (data) {
      return new SimpleMessage(data.attributes.message);
    }
  }

  toRequest(tenant: SimpleMessage) {
    return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FivefApiResourceService {
  apiUrl;

  constructor(private http: HttpClient, private env: EnvService) {
    this.apiUrl = env.apiBase();
  }

  getAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getOne<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    return this.http
      .get(`${this.apiUrl}/${path}/${resource.id}`, { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  get<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  update<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .put(`${this.apiUrl}/${path}/${resource.id}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  put<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  putAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  create<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  post<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  postAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M[]> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  delete<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}/${resource.id}`).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getBlob(url: string, fileName: string, currentAuthData = null) {
    if (currentAuthData) {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
      delete headersConfig['tokenType'];
      delete headersConfig['accessToken'];
      const headers = new HttpHeaders(headersConfig)

      this.http.get(url, {
        responseType: 'blob',
        headers: headers,
      }).pipe(first())
        .subscribe((blob) => {
          saveAs(blob, fileName);
        }, err => {
          console.error(err);
        });
      return;
    }
    this.http.get(url, {
      responseType: 'blob'
    }).pipe(first())
      .subscribe((blob) => {
        saveAs(blob, fileName);
      }, err => {
        console.error(err);
      });
  }

  postBlob(url: string, fileName: string, payload = {}, currentAuthData = null) {
    if (currentAuthData) {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
      delete headersConfig['tokenType'];
      delete headersConfig['accessToken'];
      const headers = new HttpHeaders(headersConfig)

      this.http.post(url, payload,{
        responseType: 'blob',
        headers: headers,
      }).pipe(first())
        .subscribe((blob) => {
          saveAs(blob, fileName);
        }, err => {
          console.error(err);
        });
      return;
    }
    this.http.post(url, payload, {
      responseType: 'blob'
    }).pipe(first())
      .subscribe((blob) => {
        saveAs(blob, fileName);
      }, err => {
        console.error(err);
      });
  }

  del<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}`).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  delAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string): Observable<M[]> {
    return this.http
      .delete(`${this.apiUrl}/${path}`).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }
}

