import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './item-labels.state';
import { ItemType } from './item-labels.interface';
import {LabelSelectors} from '../label';
import {ItemLabels} from './item-labels';
import {Label} from '../label/label';
import {GLOBAL_LABELS} from '../label/label.interface';

export const stateKey = 'item-labels';
const getItemLabelsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getItemLabelsEntities,
  selectAll: getAllItemLabels,
} = adapter.getSelectors(getItemLabelsState);

export const getSelected = createSelector(
  getItemLabelsState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getItemLabelsEntities,
  (entities) => entities[id]
);

export const getAll = () => createSelector(
  getAllItemLabels,
  (entities) => entities
);

export const getAllByLabels = (ids: string[]) => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.labelIds.some(labelId => ids.indexOf(labelId) > -1))
);

export const getAllTasks = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.TASK_ITEM)
);

export const getAllTasksByLabelIds = (labelIds: string[]) => createSelector(
  getAllTasks(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);

export const getAllCollectorItems = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.COLLECTOR_ITEM)
);

export const getAllCollectorItemsByLabelIds = (labelIds: string[]) => createSelector(
  getAllCollectorItems(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);

export const getAllDocuments = () => createSelector(
  getAllItemLabels,
  (entities) => entities.filter(item => item.itemType === ItemType.DOCUMENT_ITEM)
);

export const getAllDocumentsByLabelIds = (labelIds: string[]) => createSelector(
  getAllDocuments(),
  (entities) => entities.filter(item => item.labelIds.some(id => !!labelIds.find(labelId => labelId === id)))
);

/**
 * Memoized label item cache by item ID.
 */
export const itemIdItemLabelsCache = createSelector(
  getAll(),
  (entities) => {
    const cache = {};
    entities.forEach(item => cache[item.itemId] = item)
    return cache;
  }
);

/**
 * Memoized label ID -> label cache.
 * Uses all selectable labels, as such global and organizational labels.
 */
const labelCache = createSelector(
  LabelSelectors.getSelectableLabels,
  (labels: Label[]) => {
    const cache = {}
    labels.forEach(l => {
      if (!cache[l.id]) {
        cache[l.id] = [];
      }
      cache[l.id].push(l)
    });
    return cache;
  }
);

/**
 * Memoized title label cache of global labels.
 */
const globalLabelCache = createSelector(
  () => {
    const cache = {}
    GLOBAL_LABELS.forEach(l => cache[l.title] = l);
    return cache;
  }
);

/**
 * Returns item label by item ID.
 * Uses a cache to prevent recalculation by iterating all elements.
 */
export const getByItemId = (itemId) => createSelector(
  itemIdItemLabelsCache,
  (itemLabelCache) => {
    return !!itemId ? itemLabelCache[itemId] : null;
  }
);

/**
 * ItemLabel of item with itemId.
 * Returns an object with all labels and label IDs attached.
 * Uses memoized caches to reduce calculation.
 *
 * @param itemId
 */
export const getItemLabels = (itemId) => createSelector(
  globalLabelCache,
  labelCache,
  getByItemId(itemId),
  (globalLabelMap, labelMap: { [id: string]: Label[] }, itemLabel) => {
    const itemLabels = Object.assign({}, itemLabel) as ItemLabels;
    if (itemLabels) {
      if (itemLabels.labelIds) {
        itemLabels.labels = [];

        itemLabels.labelIds.forEach(labelId => {
          const labelFiltered = labelMap[labelId]
          if (labelFiltered && labelFiltered.length > 0) {
            itemLabels.labels.push(labelFiltered[0]);
          }
        });

        itemLabels.labels = itemLabels.labels.map(label => {
          if (!label) return;
          const globalLabel = globalLabelMap[label.title];
          if (globalLabel) {
            label.isSVGIcon = globalLabel.isSVGIcon;
          }
          return label;
        });
        itemLabels.labelIds = itemLabels.labels.map(label => label.id);
      }
    }
    return itemLabels;
  }
);



export const loadingState = createSelector(
  getItemLabelsState,
  (state) => state.loading
);
