import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './organization.interface';
import * as memberInterface from './../member/member.interface';
import * as model from './organization';
import * as build from './organization.builder';

@Injectable()
export class TenantOrganizationService {
  readonly BASE_PATH = 'api/v3/tenants/entities';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(tenantId = null): Observable<model.Tenant.Organization[]> {
    const builder = new build.Tenant.OrganizationBuilder();
    return <Observable<model.Tenant.Organization[]>>this._http.get<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations`);
  }

  getAllOrganizationsOfUser(userId: number): Observable<model.Tenant.Organization[]> {
    const builder = new build.Tenant.OrganizationBuilder();
    return <Observable<model.Tenant.Organization[]>>this._http.get<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `operator/api/v1/user/accounts/${userId}/entities`);
  }

  updateStatus(id: string, status: modelInterface.Tenant.OrganizationStatus): Observable<model.Tenant.Organization> {
    const builder = new build.Tenant.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          status: status
        }
      }
    };
    return <Observable<model.Tenant.Organization>>this._http.post<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${id}/status`, payload);
  }

  getOne(tenantId: string, id: string): Observable<model.Tenant.Organization> {
    const builder = new build.Tenant.OrganizationBuilder();
    return <Observable<model.Tenant.Organization>>this._http.get<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${id}`);
  }

  removeLicence(id: string): Observable<model.Tenant.Organization> {
    const builder = new build.Tenant.OrganizationBuilder();
    return <Observable<model.Tenant.Organization>>this._http.post<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${id}/decrement`, {});
  }

  update(tenantId: string, organization: model.Tenant.Organization): Observable<model.Tenant.Organization> {
    const builder = new build.Tenant.OrganizationBuilder();
    const payload = builder.toRequest(organization);
    return <Observable<model.Tenant.Organization>>this._http.put<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organization.id}`, payload);
  }

  getStats(startAt: Date = null): Observable<model.Tenant.OrganizationStats> {
    const builder = new build.Tenant.OrganizationStatsBuilder();
    const payload = { data: { attributes: { start_at: startAt } } };
    return <Observable<model.Tenant.OrganizationStats>>this._http.post<build.Tenant.OrganizationStatsBuilder, model.Tenant.OrganizationStats>(builder, 'operator/api/v1/organization/stats', payload);
  }

  toggleBigFileForMember(organization: model.Tenant.Organization, member: modelInterface.Tenant.IOrganizationMembership | memberInterface.Tenant.IMember): Observable<model.Tenant.Organization> {
    const builder = new build.Tenant.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          fastdocs_enabled: !!member.fastdocsEnabledAt,
          big_files_enabled: !!member.bigFilesEnabledAt
        }
      }
    }
    return <Observable<model.Tenant.Organization>>this._http.put<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}`, payload);
  }

  getAllEmailFooters(organization: model.Tenant.Organization): Observable<model.Tenant.EmailFooter[]> {
    const builder = new build.Tenant.EmailFooterBuilder();
    return <Observable<model.Tenant.EmailFooter[]>>this._http.get<build.Tenant.EmailFooterBuilder, model.Tenant.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers`);
  }

  createEmailFooter(organization: model.Tenant.Organization, emailFooter: model.Tenant.EmailFooter): Observable<model.Tenant.EmailFooter> {
    const builder = new build.Tenant.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.post<build.Tenant.EmailFooterBuilder, model.Tenant.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers` , payload);
  }

  sendTestEmail(organization: model.Tenant.Organization, emailFooter: model.Tenant.EmailFooter): Observable<model.Tenant.EmailFooter> {
    const builder = new build.Tenant.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.post<build.Tenant.EmailFooterBuilder, model.Tenant.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/test_email` , payload);
  }

  updateEmailFooter(organization: model.Tenant.Organization, emailFooter: model.Tenant.EmailFooter): Observable<model.Tenant.EmailFooter> {
    const builder = new build.Tenant.EmailFooterBuilder();
    const payload = builder.toRequest(emailFooter);
    return this._http.put<build.Tenant.EmailFooterBuilder, model.Tenant.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/${emailFooter.id}`, payload);
  }

  removeEmailFooter(organization: model.Tenant.Organization, id: string): Observable<model.Tenant.EmailFooter> {
    const builder = new build.Tenant.EmailFooterBuilder();
    return this._http.del<build.Tenant.EmailFooterBuilder, model.Tenant.EmailFooter>(builder, `${this.BASE_PATH}/${organization.id}/email_footers/${id}`);
  }

  getAllLogos(organization: model.Tenant.Organization): Observable<model.Tenant.OrganizationLogo[]> {
    const builder = new build.Tenant.OrganizationLogoBuilder();
    return this._http.getAll<build.Tenant.OrganizationLogoBuilder, model.Tenant.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos`);
  }

  updateLogo(organization: model.Tenant.Organization, logo: model.Tenant.OrganizationLogo, publish): Observable<model.Tenant.OrganizationLogo> {
    const builder = new build.Tenant.OrganizationLogoBuilder();
    const payload = {
      data: {
        attributes: {
          publish: publish
        }
      }
    };
    return this._http.put<build.Tenant.OrganizationLogoBuilder, model.Tenant.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos/${logo.id}`, payload);
  }

  removeLogo(organization: model.Tenant.Organization, logo: model.Tenant.OrganizationLogo): Observable<model.Tenant.OrganizationLogo> {
    const builder = new build.Tenant.OrganizationLogoBuilder();
    return this._http.del<build.Tenant.OrganizationLogoBuilder, model.Tenant.OrganizationLogo>(builder, `${this.BASE_PATH}/${organization.id}/logos/${logo.id}`);
  }

  /**
   * Updates the AD Department field of a given organization of the tenant.
   * The field is a comma separated list for all departments where the user should
   * be onboarded into the given organization as employee.
   * @param id
   * @param departments: string - comma separated departments.
   */
  public updateAdDepartment(tenantId: string, id: string, departments: string) {
    const builder = new build.Tenant.OrganizationBuilder();
    const payload = {
      data: {
        attributes: {
          ad_departments: departments
        }
      }
    }
    return <Observable<model.Tenant.Organization>>this._http.put<build.Tenant.OrganizationBuilder, model.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${id}/ad_departments`, payload);
  }
}
