import { ITask } from '../models/task.interface';
import { Task } from '../models/task';

export namespace TaskBuilder {
  export function fromResponse(response, _catalog = null): ITask {
    throw Error('Unsupported builder: TaskBuilder. Migrate to store');

    // const attrs = response.attributes;
    // return new Task(response.id, attrs.process_id, attrs.title, attrs.description, attrs.due_date,
    //   attrs.completed_at, attrs.status, attrs.priority, attrs.created_at, attrs.updated_at);
  }

  export function toRequest(record: ITask) {
    throw Error('Unsupported builder: TaskBuilder. Migrate to store');

    // return {
    //   data: {
    //     id: record.id,
    //     type: record.type,
    //     attributes: {
    //       title: record.title,
    //       description: record.description,
    //       due_date: record.dueDate,
    //       priority: record.priority,
    //       status: record.status,
    //       completed_at: record.completedAt
    //     }
    //   }
    // };
  }
}
