import * as foxdoxStatusActions from '../actions/foxdox-status.actions';
import { IFoxdoxStatus } from '../../../+store/_legacy/api/models/foxdoxStatus.interface';

export type FoxdoxStatus = IFoxdoxStatus;
export const initialState: FoxdoxStatus = null;

export function foxdoxStatus(state: FoxdoxStatus = initialState, action: foxdoxStatusActions.Actions): FoxdoxStatus {
  switch (action.type) {

    case foxdoxStatusActions.GET_STATUS_SUCCESS:
      return action.payload;
    case foxdoxStatusActions.GET_STATUS_FAILURE:
      return action.payload;
    default: {
      return state;
    }
  }
};

