import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';

export class TwoFactorAuthBuilder implements IApiResourceBuilder<any> {
  eventCommentMap = {};

  fromResponse(data) {  // status builder
    const values = data.data;
    const event = {
        id: data.id,
        totpSmsEnabled: values.attributes.totp_sms_enabled,
        totpQrEnabled: values.attributes.totp_qr_enabled,
        phone: values.attributes.phone,
        confirmedAt: values.attributes.confirmed_at,
        activatedAt: values.attributes.activated_at,
        updatedAt: values.attributes.updated_at,
        createdAt: values.attributes.created_at,
      };
    return event;
  }

  toRequest(_) {
    return null;
  }

}
