import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {DmsFolderActions} from '../../../../+store';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';

/**
 * Dialog to delete folders.
 */
@Component({
  selector: 'fivef-fivef-delete-folder-dialog',
  standalone: true,
  imports: [CommonModule, FivefDialogComponent, MatButtonModule, TranslateModule, MatIconModule, MatTooltipModule],
  templateUrl: './fivef-delete-folder-dialog.component.html',
  styleUrls: ['./fivef-delete-folder-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefDeleteFolderDialogComponent {
  public folder: DmsFolder;

  public submitOngoing = false;

  constructor(private store: Store<AppState>,
              private dialogRef: MatDialogRef<FivefDeleteFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { folder: DmsFolder }) {
    this.folder = data.folder;
  }

  /**
   * Deletes the folder async by +store.
   */
  public deleteFolder() {
    const folder = this.folder;
    if (!folder) return;

    this.store.dispatch(new DmsFolderActions.Delete(folder.id, folder.dmsAccountType));
    this.closeDialog();
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
