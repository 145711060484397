import {IResource, IResourceCatalog} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {ContactAddressBuilder} from 'app/+store/contact/legacy/builders/contact-address.builder';
import {ContactEmailAddressBuilder} from 'app/+store/contact/legacy/builders/contact-email-address.builder';
import {ContactPhoneNumberBuilder} from 'app/+store/contact/legacy/builders/contact-phone-number.builder';
import {ContactOrganizationBuilder} from 'app/+store/contact/legacy/builders/contact-organization.builder';
import {ContactPersonBuilder} from 'app/+store/contact/legacy/builders/contact-person.builder';
import {OrganizationMemberBuilder} from 'app/+store/contact/legacy/builders/organization-member.builder';
import {ProcessBuilder} from './process.builder';
import {BusinessPartnerBuilder} from 'app/+store/contact/legacy/builders/business-partner.builder';
import {TaskBuilder} from './task.builder';
import {AuditOrderBuilder} from './audit-order.builder';
import {OrganizationBuilder} from './organization.builder';
import {ThirdPartyConfirmationLeasingBuilder} from 'app/modules/third-party-confirmation/models/leasing.builder';
import {ThirdPartyConfirmationClaimInvoiceBuilder} from 'app/modules/third-party-confirmation/models/claim-invoice.builder';
import {ThirdPartyConfirmationClaimBalanceBuilder} from 'app/modules/third-party-confirmation/models/claim-balance.builder';
import {RequestConfirmationBuilder} from 'app/modules/third-party-confirmation/models/request-confirmation.builder';
import {ThirdPartyConfirmationContributionBuilder} from 'app/modules/third-party-confirmation/models/contribution.builder';
import {ThirdPartyConfirmationLiabilityInvoiceBuilder} from 'app/modules/third-party-confirmation/models/liability-invoice.builder';
import {ThirdPartyConfirmationLiabilityBalanceBuilder} from 'app/modules/third-party-confirmation/models/liability-balance.builder';
import {ThirdPartyConfirmationBankBuilder} from 'app/modules/third-party-confirmation/models/bank.builder';
import {ThirdPartyConfirmationSafekeepingBuilder} from 'app/modules/third-party-confirmation/models/safekeeping.builder';
import {ThirdPartyConfirmationExpertBuilder} from 'app/modules/third-party-confirmation/models/expert.builder';
import {ThirdPartyConfirmationInsuranceBuilder} from 'app/modules/third-party-confirmation/models/insurance.builder';
import {AuditContactImportsBuilder} from './audit-contact-imports.builder';
import {AuditContactVerificationProcessBuilder} from './audit-contact-verification-process.builder';
import {AuditContactVerificationBuilder} from './audit-contact-verification.builder';
import {LocaleBuilder} from 'app/+store/_legacy/api/builders/locale.builder';
import {InvoiceAddressBuilder} from 'app/+store/_legacy/api/builders/invoice-address.builder';
import {LineItemBuilder} from 'app/+store/_legacy/api/builders/line-item.builder';
import {PaymentOptionBuilder} from 'app/+store/_legacy/api/builders/payment-option.builder';
import {ShoppingCartBuilder} from 'app/+store/_legacy/api/builders/shopping-cart.builder';
import {SalesOrderBuilder} from 'app/+store/_legacy/api/builders/sales-order.builder';
import {LicenceBuilder} from 'app/+store/_legacy/api/builders/licence.builder';
import {IamActivityBuilder} from 'app/+store/_legacy/api/builders/iam-activity.builder';
import {LicenceAssignmentBuilder} from 'app/+store/_legacy/api/builders/licence-assignment.builder';

export namespace ResourceBuilder {
  export function fromResponse(response): IResource | IResource[] {
    // parse included resources and build a catalog for each
    // type once ahead of time.
    const catalog: IResourceCatalog = {};
    if (response.included) {
      // console.log('%c Includes: ', 'color: #ff8800', response.relationships);
      for (const resource of response.included) {
        if (!catalog[resource.type]) {
          catalog[resource.type] = [];
        }
        catalog[resource.type].push(ResourceBuilder._build(resource));
      }
    }
    if (response.data instanceof Array) {
      return response.data.map((resource) => ResourceBuilder._build(resource, catalog));
    } else {
      return ResourceBuilder._build(response.data, catalog);
    }
  }

  export function toRequest(resource: IResource) {
    const builder = ResourceBuilder._selectBuilder(resource);
    if (builder) {
      return builder.toRequest(resource);
    } else {
      return { id: null, type: null, toForm: () => null };
    }
  }

  export function _build(response, catalog = null): IResource {
    const builder = ResourceBuilder._selectBuilder(response);
    if (builder) {
      const model = builder.fromResponse(response, catalog);
      // console.log('%c Created model: ', 'color: #0090cf', model);
      return model;
    } else {
      return { id: null, type: null, toForm: () => null };
    }
  }

  export function _selectBuilder(response):  { fromResponse(response: any, catalog: any);
                                               toRequest(resource: IResource): any; } {
    try {
      // console.log('%c Trying to build: ', 'background: #5bc4f1; color: #fff', response.type);
      switch (response.type) {
        case 'contact_people':
          return ContactPersonBuilder;
        case 'organization_members':
          return OrganizationMemberBuilder;
        case 'contact_organizations':
          return ContactOrganizationBuilder;
        case 'contact_addresses':
          return ContactAddressBuilder;
        case 'contact_email_addresses':
          return ContactEmailAddressBuilder;
        case 'contact_phone_numbers':
          return ContactPhoneNumberBuilder;
        case 'business_partners':
          return BusinessPartnerBuilder;
        case 'organization_records':
          return OrganizationBuilder;
        case 'iam_activities':
          return IamActivityBuilder;
        case 'quickstart':
        case 'workflow_engine_processes':
          return ProcessBuilder;
        case 'settings_locales':
          return LocaleBuilder;
        case 'task_records':
          return TaskBuilder;
        case 'audit_annual_auditings':
        case 'audit_group_auditings':
          return AuditOrderBuilder;
        case 'audit_contact_imports':
          return AuditContactImportsBuilder;
        case 'audit_contact_verification_process':
          return AuditContactVerificationProcessBuilder;
        case 'audit_contact_verification':
          return AuditContactVerificationBuilder;
        case 'leasing':
          return ThirdPartyConfirmationLeasingBuilder;
        case 'claim_invoice':
          return ThirdPartyConfirmationClaimInvoiceBuilder;
        case 'claim_balance':
          return ThirdPartyConfirmationClaimBalanceBuilder;
        case 'liability_invoice':
          return ThirdPartyConfirmationLiabilityInvoiceBuilder;
        case 'liability_balance':
          return ThirdPartyConfirmationLiabilityBalanceBuilder;
        case 'audit_confirmation_request':
          return RequestConfirmationBuilder;
        case 'audit_third_party_contribution':
          return ThirdPartyConfirmationContributionBuilder;
        case 'bank':
          return ThirdPartyConfirmationBankBuilder;
        case 'safekeeping':
          return ThirdPartyConfirmationSafekeepingBuilder;
        case 'expert':
          return ThirdPartyConfirmationExpertBuilder;
        case 'insurance':
          return ThirdPartyConfirmationInsuranceBuilder;
        case 'sales_invoice_addresses':
          return InvoiceAddressBuilder;
        case 'sales_line_items':
          return LineItemBuilder;
        case 'sales_payment_sepa':
          return PaymentOptionBuilder;
        case 'sales_carts':
          return ShoppingCartBuilder;
        case 'sales_orders':
          return SalesOrderBuilder;
        case 'sales_licence_teams':
        case 'sales_licence_professionals':
        case 'sales_licence_annual_auditings':
        case 'sales_licence_group_auditings':
          return LicenceBuilder;
        case 'sales_licence_assignments':
        case 'sales_licence_standard':
        case 'sales_licence_professional':
        case 'sales_licence_enterprise':
          return LicenceAssignmentBuilder;
        default:
          return null;
      }
    } catch (error) {
      console.error('*** Unknown resource: ', response);
      return null;
    }
  }
}
