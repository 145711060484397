import {Action} from '@ngrx/store';
import {CacheFroala} from "./cache-froala";

export enum CacheFroalaActionTypes {
  Create = '[CacheFroala] Create',
  Remove = '[CacheFroala] Remove',
  Reset = '[CacheFroala] Reset',
}

export class Reset implements Action {
  readonly type = CacheFroalaActionTypes.Reset;

  constructor() {
  }
}

export class Create implements Action {
  readonly type = CacheFroalaActionTypes.Create;

  constructor(public cacheFroala: CacheFroala) {
  }
}


export class Remove implements Action {
  readonly type = CacheFroalaActionTypes.Remove;

  constructor(public cacheFroalaId: string) {
  }
}

export type CacheFroalaActions =
  | Create
  | Remove
  | Reset;
