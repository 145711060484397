<div *ngIf="alertMsg && isUnsupportedBrowser" class="mb-3">
  <fivef-alert [color]="'warn'" [text]="alertMsg" [icon]="'warning'"></fivef-alert>
</div>

<div *ngIf="isUnsupportedBrowser" class="unsupported-browser">
  <h3>{{ info | translate }}</h3>
  <div class="recommendations">
    <span>{{ 'SYSTEM.BROWSER.RECOMMENDATION_INFO' | translate }}</span>
    <div class="browsers">
      <a href="https://www.google.com/chrome/" target="_blank">
        <img src="../../../assets/img/chrome-logo.svg" alt="chrome browser logo">
        <span>Chrome</span>
      </a>
      <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
        <img src="../../../assets/img/firefox-logo.svg" alt="firefox browser logo">
        <span>Firefox</span>
      </a>
      <a href="https://www.microsoft.com/edge" target="_blank">
        <img src="../../../assets/img/edge-logo.svg" alt="edge browser logo">
        <span>Edge</span>
      </a>
    </div>
  </div>
</div>
