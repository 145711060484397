import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './tenant';

export namespace Tenant {
  export class TenantBuilder implements IApiResourceBuilder<model.Tenant.Tenant> {
    fromResponse(data): model.Tenant.Tenant {
      if (data) {
        return new model.Tenant.Tenant(
          data.id,
          data.attributes.name,
          data.attributes.sender_name,
          data.attributes.domain,
          data.attributes.email_domain,
          data.attributes.favicon_url,
          data.attributes.login_header,
          data.attributes.login_header_en,
          data.attributes.login_footer,
          data.attributes.login_footer_en,
          data.attributes.imprint_url,
          data.attributes.ad_department_onboarding_activated,
          data.attributes.ad_offboarding_activated,
          data.attributes.ad_offboarding_admin_id,
          data.attributes.auto_license_upgrade,
          data.attributes.whitelisted_user_domains,
          data.attributes.created_at,
          data.attributes.updated_at);
      }
    }

    toRequest(tenant: model.Tenant.Tenant) {
      return {
        data: {
          id: tenant.id,
          type: tenant.type,
          attributes: {
            name: tenant.name,
            sender_name: tenant.sender_name,
            domain: tenant.domain,
            email_domain: tenant.email_domain || tenant.domain,
            favicon_url: tenant.favicon_url,
            login_header: tenant.login_header,
            login_header_en: tenant.login_header_en,
            login_footer: tenant.login_footer,
            login_footer_en: tenant.login_footer_en,
            imprint_url: tenant.imprint_url
          }
        }
      };
    }
  }

  export class TenantDepartmentBuilder implements IApiResourceBuilder<model.Tenant.TenantDepartment> {
    fromResponse(data): model.Tenant.TenantDepartment {
      if (data) {
        return new model.Tenant.TenantDepartment(
          data.id,
          data.attributes.name,
          data.attributes.description,
          data.attributes.created_at);
      }
    }

    toRequest(department: model.Tenant.TenantDepartment) {
      return {
        data: {
          id: department.id,
          type: department.type,
          attributes: {
            name: department.name,
            description: department.description
          }
        }
      };
    }
  }

  export class TenantLicenceStatsBuilder implements IApiResourceBuilder<model.Tenant.LicenceStats> {
    fromResponse(data): model.Tenant.LicenceStats {
      return new model.Tenant.LicenceStats(
        data.attributes.all,
        data.attributes.available,
        data.attributes.assigned,
        data.attributes.memberships,
        data.attributes.uploads
      );
    }

    toRequest(tenant: model.Tenant.LicenceStats) {
      return null;
    }
  }

  export class TenantAuthorizedDomainsBuilder implements IApiResourceBuilder<model.Tenant.TenantAuthorizedDomain> {
    fromResponse(data): model.Tenant.TenantAuthorizedDomain {
      return new model.Tenant.TenantAuthorizedDomain(data.id, data.attributes.domain, data.attributes.default_domain);
    }

    toRequest(tenant: model.Tenant.TenantAuthorizedDomain) {
      return null;
    }
  }

  export class AdminLogItemBuilder implements IApiResourceBuilder<model.Tenant.AdminLogItem> {
    total = 1;
    perPage = 1;
    records = 1;

    fromResponse(data): model.Tenant.AdminLogItem {
      const log = new model.Tenant.AdminLogItem(
        data.id,
        data.attributes.performer_email,
        data.attributes.performer_name,
        data.attributes.category,
        data.attributes.action,
        data.attributes.info_level,
        data.attributes.message,
        data.attributes.created_at,
      );

      log.total = this.total;
      log.perPage = this.perPage;
      log.records = this.records;

      return log;
    }

    toRequest(user: model.Tenant.AdminLogItem) {
      return null;
    }

    addMetaSection(meta: any) {
      if (!meta) return;
      this.total = meta.total;
      this.perPage = meta.per_page;
      this.records = meta.records;
    }
  }

  export class OffbordingDryRunBuilder implements IApiResourceBuilder<model.Tenant.OffbordingDryRun> {
    constructor(private id: string) {
    }

    fromResponse(data): model.Tenant.OffbordingDryRun {
      return new model.Tenant.OffbordingDryRun(
        this.id,
        data.attributes.total,
        data.attributes.considered,
        data.attributes.removed,
        data.attributes.inactive);
    }

    toRequest(_: model.Tenant.OffbordingDryRun) {
      return null;
    }
  }

  /**
   * {
   *   "data": {
   *     "id": "829dc847-537d-4c31-8532-d40a68715f02",
   *     "type": "tenant_accounts",
   *     "attributes": {
   *       "title": "My login config",
   *       "description": null,
   *       "login_theme": "fivef_pure",
   *       "background_image_url": null,
   *       "background_image_filename": null,
   *       "slideshow_id": "2a16027e-eb70-4afe-a4a5-3244129007d7",
   *       "slideshow_title": "My slideshow",
   *       "created_at": "2024-08-14T12:05:40.018+02:00",
   *       "updated_at": "2024-08-14T12:05:40.032+02:00"
   *     }
   *   }
   * }
   */
  export class LoginConfigBuilder implements IApiResourceBuilder<model.Tenant.LoginConfig> {
    fromResponse(data): model.Tenant.LoginConfig {
      return new model.Tenant.LoginConfig(
        data.id,
        data.attributes.title,
        data.attributes.description,
        data.attributes.bg_color,
        data.attributes.fg_color,
        data.attributes.login_theme,
        data.attributes.background_image_url,
        data.attributes.background_image_filename,
        data.attributes.secondary_logo_url,
        data.attributes.secondary_logo_filename,
        data.attributes.slideshow_id,
        data.attributes.slideshow_title,
        data.attributes.published,
        data.attributes.created_at,
        data.attributes.updated_at);
    }

    toRequest(config: model.Tenant.LoginConfig) {
      return {
        data: {
          id: config.id,
          type: config.type,
          attributes: {
            title: config.title,
            description: config.description,
            login_theme: config.loginTheme,
            bg_color: config.bgColor,
            fg_color: config.fgColor
          }
        }
      };
    }
  }
}
