<div class="dialog-container">
  <ng-container *ngIf="(selectedNatPerson | async) as person">
    <h2 mat-dialog-title>{{ 'ROLE.EDIT_DIALOG_TITLE' | translate }} {{ person.firstName }} {{ person.lastName}}</h2>
  </ng-container>

  <mat-dialog-content>

    <mat-spinner *ngIf="(loading | async); else Content"
                 [diameter]="70" [strokeWidth]="4"
                 aria-label="loading...">
    </mat-spinner>

    <ng-template #Content>
      <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
        <mat-tab [label]="'ROLE.EDIT_MEMBER_ROLES_TABTEXT' | translate">
          <form [formGroup]="form">
            <mat-form-field class="chip-list">
              <mat-chip-grid #chipList>
                <mat-chip-row
                  *ngFor="let role of selectedRoles"
                  [class.new-role]="!role.id"
                  [selectable]="true"
                  [removable]="role.removeable"
                  (removed)="remove(role)">
                  {{role.roleName}}
                  <mat-icon matChipRemove *ngIf="role.removeable">cancel</mat-icon>
                </mat-chip-row>
                <input
                  #roleInput
                  formControlName="roles"
                  [placeholder]="'ROLE.AUTOCOMPLETE_HEADING' | translate"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-grid>
              <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
                <mat-option *ngFor="let role of filteredRoles | async" [value]="role">
                  {{role.roleName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab [label]="'ROLE.EDIT_ROLES_TABTEXT' | translate">
          <div class="role-creation--container">
            <div>
              {{ 'ROLE.CREATE_ROLE' | translate }}:
            </div>
            <dvtx-text-input-cva
              [(ngModel)]="roleName"
              [elementName]="'ROLE.ROLENAME' | translate"
            ></dvtx-text-input-cva>
            <button mat-raised-button (click)="createRole()">{{ 'ROLE.CREATE' | translate}}</button>
          </div>
          <div>
            <mat-card appearance="outlined" *ngFor="let role of allRoles" class="role-list--cell">
              <div class="role-cell--content">
                <span>{{ role.roleName }}</span>
                <div class="action--wrapper" *ngIf="role.deleteable">
                  <div *ngIf="role.toggleDeleteAction" class="delete-action--wrapper">
                    {{ 'ROLE.DELETE_ROLE_CONFIRMATION' | translate }}?
                    <button mat-raised-button color="warn"
                            (click)="deleteRole(role.id)">{{ 'GENERAL.DELETE_ACTION' | translate }}</button>
                    <button mat-raised-button
                            (click)="cancelDelete(role)">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
                  </div>
                  <button (click)="handleDeleteClick(role)" mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-template>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
  </mat-dialog-actions>
</div>
