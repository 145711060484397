import {ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ContactPersonActions, ContactPersonSelectors, FeatureSelectors, InvitationSelectors, MembershipSelectors, OrganizationSelectors, PartnerLinkParticipationActions, PartnerLinkParticipationSelectors} from 'app/+store';
import {first, map, takeUntil} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {Membership} from 'app/+store/membership/membership';
import {ContactListDto, contactListDtoType} from 'app/+store/contact/contact';
import {Invitation, InvitationStatus, InvitationType} from '../../../../../+store/invitation/invitation.model';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {LoadOfOrganization} from '../../../../../+store/invitation/invitation.actions';
import {InviteService} from '../../../../../modules/organization/components/organization-view/invite.service';
import {ContactPersonBaseForm, contactPersonBaseFormFromContact, newContactBaseForm} from '../../../../../modules/organization/models/person-contact-base-form';
import {ContactPerson} from '../../../../../+store/contact-person/contact-person';
import {AngularTokenService} from 'angular-token';

/**
 * Dialog options.
 */
export interface IFivefEditContactDialogData {
  contact: ContactListDto,
  selectedTab?: number;
  onCloseAction?: any;
}

/**
 * Sidebar dialog to edit contacts.
 */
@Component({
  selector: 'fivef-edit-contact-dialog',
  templateUrl: './fivef-edit-contact-dialog.component.html',
  styleUrls: ['./fivef-edit-contact-dialog.component.scss']
})
export class FivefEditContactDialogComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  /**
   * Invitation setup.
   */
  readonly InvitationStatus = InvitationStatus;
  public invitationStatus = null;
  public invitationStatusPresent = false;
  public invitationSent = false;
  public invitationDate: Date;

  /**
   * Current user UID to check for creator of contact.
   */
  public currentUserUid: string;

  readonly contactListDtoType = contactListDtoType;

  public featureSet$: Observable<Feature>;

  public myMembership$: Observable<Membership>;

  public partnerLinkId: string = null;

  /**
   * Base contact from address book with minimal data.
   */
  contact: any;

  /**
   * ContactPerson entity with extended meta data.
   */
  personContact: ContactPerson;

  form: ContactPersonBaseForm = newContactBaseForm();

  selectedTab: number = 0;

  /**
   * Edit mode of form.
   *
   * TODO: Recheck why this functionality is disabled?
   *       Is disabling the form not working?
   */
  editmode = true;

  constructor(public dialogRef: MatDialogRef<FivefEditContactDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IFivefEditContactDialogData,
              private route: ActivatedRoute,
              private router: Router,
              private store: Store<AppState>,
              private inviteService: InviteService,
              private cdr: ChangeDetectorRef,
              private ngZone: NgZone,
              tokenSvc: AngularTokenService) {
    this.currentUserUid = tokenSvc.currentAuthData.uid;

    if (data) {
      this.selectedTab = data.selectedTab ? data.selectedTab : 0;
      this.contact = data.contact;
      this.fetchContactPerson(data.contact.id);
    }
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(first()).subscribe((params) => {
      const tab = Number(params.get('tab'));
      if (tab) {
        this.selectedTab = tab;
      }
    });

    this.myMembership$ = this.store.select(MembershipSelectors.getMyMembership);
    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);

    const contact$ = this.store.select(ContactPersonSelectors.getPersonContactById(this.contact.id));
    const partnerLinkMap$ = this.store.select(PartnerLinkParticipationSelectors.getPartnerLinkMapOfSelectedOrg);
    combineLatest(contact$, partnerLinkMap$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([contact, partnerLinkMap]) => {
        if (contact && contact.mainEmailAddress && contact.mainEmailAddress.emailAddress) {
          this.partnerLinkId = partnerLinkMap[contact.mainEmailAddress.emailAddress.toLowerCase()];
        } else {
          this.partnerLinkId = null
        }
        this.cdr.detectChanges();
      });

    contact$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((contact: ContactPerson) => {
        this.personContact = contact;
        this.form = contactPersonBaseFormFromContact(contact);
        this.cdr.detectChanges();
      });

    combineLatest(contact$, this.store.select(InvitationSelectors.selectOrgasInvitations))
      .pipe(
        map(([contact, invitations]: [ContactPerson, Invitation[]]) => {
          if (this.personContact && this.personContact.mainEmailAddress.emailAddress) {
            return invitations.filter((invitation) => invitation.type === InvitationType.ContactPerson && this.personContact.mainEmailAddress.emailAddress === invitation.invitee_email);
          }
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(invis => {
        if (invis && invis.length > 0) {
          const invitationStatus = invis[0].current_status;
          this.invitationStatus = invitationStatus;
          this.invitationStatusPresent = invitationStatus === InvitationStatus.Pending || invitationStatus === InvitationStatus.Declined || invitationStatus === InvitationStatus.Accepted;
          this.invitationDate = invis[0].created_at;
          this.cdr.detectChanges();
        }
      });
  }

  updateMapping(data) {
    if (!this.data.onCloseAction) {
      return;
    }
    this.data.onCloseAction(data);
  }

  ngOnDestroy() {
    this.router.navigate([], {
      queryParams: {},
    });
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  closeSidebar(_$event) {
    this.dialogRef.close();
  }

  selectedTabChanged(tab) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab,
      },
      queryParamsHandling: 'merge',
    });
  }

  public authorizePartner($event) {
    if (!this.personContact) return;

    if ($event.checked) {
      this.store.dispatch(new PartnerLinkParticipationActions.Create(this.personContact.mainEmailAddress.emailAddress));
    } else if (this.partnerLinkId) {
      this.store.dispatch(new PartnerLinkParticipationActions.Remove(this.partnerLinkId));
    }
  }

  public saveData() {
    this.personContact = {
      ...this.personContact,
      ...this.form.contactPerson
    };
    this.store.dispatch(new ContactPersonActions.EditContact(this.personContact));
    // this.editmode = false;
    // this.onSave.emit(this.contact);
  }

  public sendMemberInvitation() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.personContact.mainEmailAddress.emailAddress !== '') {
        this.inviteService.inviteBusinessPartner(this.personContact.mainEmailAddress.emailAddress).subscribe(() => {
          this.store.dispatch(new LoadOfOrganization(orgaId));
          this.invitationSent = true;
          this.closeSidebar(this.personContact)
        });
      }
    });
  }

  private fetchContactPerson(id: string) {
    this.ngZone.runOutsideAngular(_ => {
      this.store.dispatch(new ContactPersonActions.LoadOne(id));
      this.store.dispatch(new ContactPersonActions.LoadAssignedOrganizations(id));
    });
  }
}
