<mat-form-field class="fivef-datepicker--form-field"
                [class.fivef-datepicker--theme-table-cell]="appearance === 'tableCell'"
                fivefDisableInputHints>
  <input matInput
         [min]="minDate"
         [max]="maxDate"
         [matDatepicker]="datepickerRef"
         [placeholder]="placeholder"
         [(ngModel)]="_value"
         (dateChange)="valueChanges($event)"
         [disabled]="disabled"
         [class.fivef-color-status-overdue]="alertOnPast || overdue"
         (click)="datepickerRef.open()"
         autocomplete="new-datepicker"
         #inputElementRef>

  <button *ngIf="!!inputElementRef.value && !disabled && !required"
          mat-icon-button
          matSuffix
          [disableRipple]="true"
          (click)="clearDueDate()">
    <fivef-icon [icon]="'close'" [size]="'xs'"></fivef-icon>
  </button>

  <mat-datepicker-toggle *ngIf="!inputElementRef.value && !disabled"
                         matSuffix
                         [for]="datepickerRef"></mat-datepicker-toggle>
  <mat-datepicker #datepickerRef [startAt]="minDate"></mat-datepicker>
</mat-form-field>
