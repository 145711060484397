import {Injectable} from '@angular/core';
import {
  AuditContactImports,
  SimpleContactImportItem,
  SimpleContactImportItemBuilder
} from 'app/+store/_legacy/api/models/audit-contact-imports';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {AuditContactVerificationProcess} from 'app/+store/_legacy/api/models/audit-contact-verification-process';
import {AuditContactVerification} from 'app/+store/_legacy/api/models/audit-contact-verification';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ProcessProfile} from '../process/process.interface';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';

@Injectable()
export class AuditContactImportsService {
  readonly BASE_PATH = 'api/v1/audit';
  basePath: string;

  constructor(private tokenService: HttpClient,
              private _apiSvc: FivefApiResourceService) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  public acceptMyVerifiicationRequests(id: string): Observable<SimpleContactImportItem> {
    const builder = new SimpleContactImportItemBuilder();
    return <Observable<SimpleContactImportItem>>this._apiSvc.post<SimpleContactImportItemBuilder, SimpleContactImportItem>(builder, `${this.BASE_PATH}/contact_verifications/my/${id}`, {});
  }

  public declineMyVerifiicationRequests(id: string): Observable<SimpleContactImportItem> {
    const builder = new SimpleContactImportItemBuilder();
    return <Observable<SimpleContactImportItem>>this._apiSvc.del<SimpleContactImportItemBuilder, SimpleContactImportItem>(builder, `${this.BASE_PATH}/contact_verifications/my/${id}`);
  }

  public getMyVerifiicationRequests(): Observable<SimpleContactImportItem[]> {
    const builder = new SimpleContactImportItemBuilder();
    return <Observable<SimpleContactImportItem[]>>this._apiSvc.get<SimpleContactImportItemBuilder, SimpleContactImportItem>(builder, `${this.BASE_PATH}/contact_verifications/my`);
  }

  public get(auditingId: number, workflowId: number): Observable<AuditContactImports> {
    return this.tokenService.get(
        `${this.basePath}/auditings/${auditingId}/contact_imports/${workflowId}`
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactImports;
    }));
  }

  public create(auditingId: number, auditContactImports: AuditContactImports, processProfile: ProcessProfile): Observable<AuditContactImports> {
    const payload = ResourceBuilder.toRequest(auditContactImports);
    payload['data']['attributes']['profile'] = processProfile;
    return this.tokenService.post(
      `${this.basePath}/auditings/${auditingId}/contact_imports`,
      payload
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactImports;
    }));
  }

  public update(auditingId: number, workflowId: number, auditContactImports: AuditContactImports): Observable<AuditContactImports> {
    return this.tokenService.put(
      `${this.basePath}/auditings/${auditingId}/contact_imports/${workflowId}`,
      ResourceBuilder.toRequest(auditContactImports)
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactImports;
    }));
  }

  public send(workflowId: number, auditContactImports: AuditContactImports): Observable<AuditContactImports> {
    return this.tokenService.post(
      `${this.basePath}/contact_imports/${workflowId}/send`,
      ResourceBuilder.toRequest(auditContactImports)
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactImports;
    }));
  }

  public getOverview(workflowId: string, excludeDeleted = false): Observable<AuditContactVerificationProcess[]> {
    const params = new HttpParams({fromObject: {exclude_deleted: JSON.stringify(excludeDeleted)}});

    return this.tokenService.get(
      `${this.basePath}/contact_imports/${workflowId}/overview`, { params: params }
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactVerificationProcess[];
    }));
  }

  public getVerification(token: string): Observable<AuditContactVerification> {
    return this.tokenService.get(
        `${this.basePath}/contact_verification/contribute/${token}`,
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactVerification;
    }));
  }

  public postVerification(token: string, contactVerification: AuditContactVerification, addedComponentAuditors = []): Observable<AuditContactVerification> {
    const payload = ResourceBuilder.toRequest(contactVerification);
    if (addedComponentAuditors && addedComponentAuditors.length > 0) {
      payload['data']['attributes']['added_component_auditors'] = addedComponentAuditors;
    }
    return this.tokenService.post(
      `${this.basePath}/contact_verification/contribute/${token}`,
      payload
    ).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as AuditContactVerification;
    }));
  }
}
