import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FivefCsvImportComponent} from './fivef-csv-import.component';
import {FivefCsvImportDropzoneComponent} from '../../upload/fivef-csv-import-dropzone/fivef-csv-import-dropzone.component';
import {SharedModule} from '../../../fivef-legacy/shared/shared.module';
import {FivefCsvImportDisplayTableComponent} from './fivef-csv-import-display-table/fivef-csv-import-display-table.component';
import {FivefCsvImportService} from './fivef-csv-import.service';
import {FivefCsvImportErrorBoxComponent} from './fivef-csv-import-error-box/fivef-csv-import-error-box.component';
import {FivefCsvImportDisplayComponent} from './fivef-csv-import-display/fivef-csv-import-display.component';
import {InViewportModule} from 'ng-in-viewport';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {
  FivefDisableInputHintsWrapperDirective
} from '../../input/fivef-disable-input-hints-wrapper.directive';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefAlertComponent} from '../../util/fivef-alert/fivef-alert.component';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    InViewportModule,
    ScrollingModule,
    FivefDisableInputHintsWrapperDirective,
    FivefCsvImportDropzoneComponent,
    FivefLoadingIndicatorComponent,
    FivefAlertComponent,
    FivefIconMessageBlockComponent
  ],
  declarations: [
    FivefCsvImportComponent,
    FivefCsvImportDisplayComponent,
    FivefCsvImportDisplayTableComponent,
    FivefCsvImportErrorBoxComponent
  ],
  providers: [
    FivefCsvImportService
  ],
  exports: [
    FivefCsvImportComponent,
    FivefCsvImportDropzoneComponent
  ]
})
export class FivefCsvImportModule {
}
