<!--<mat-card-content class="col-12 col-sm-12" *ngIf="!_settings.hideHint">-->
<!--{{'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_HINT' | translate}}-->
<!--</mat-card-content>-->

<ng-container *ngIf="!idid">
  <mat-card-content>
    <a *ngIf="excelTemplateUrl"
       mat-button color="primary"
       class="my-1 mr-2"
       [href]="excelTemplateUrl" target="_blank" download>
      <mat-icon matPrefix aria-label="download">file_download</mat-icon>
      {{ 'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_SAMPLE_DOWNLOAD' | translate }} (Excel)
    </a>
    <!--<a mat-raised-button  (click)="browserCheck($event)" [href]="templateDataUri" target="_blank" download="sample.csv">-->
    <!--<mat-icon matprefix aria-label="download">file_download</mat-icon>-->
    <!--{{ 'WORKFLOWS.GA_CA_COMMUNICATION.CA_SELECTION_SAMPLE_DOWNLOAD' | translate }} (CSV)-->
    <!--</a>-->
  </mat-card-content>

  <mat-card-content>
    <em class="fivef-color-status-info">{{ _settings.text.sampleTitle | translate }}</em>
    <div class="fivef-csv-import--expected-structure fivef-color-status-info table-responsive">
      <table class="fivef-csv-import--expected-structure__table">
        <tbody>
        <tr>
          <ng-container *ngFor="let column of _settings.columns">
            <td *ngIf="!column.isHidden">{{ column.title }}</td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
    <em class="fivef-color-status-info sample-info">{{ _settings.text.sampleInfo | translate }}</em>
  </mat-card-content>

  <mat-card-content>
    <fivef-csv-import-dropzone class="ff-drag-n-drop my-2"
                               (newFile)="onNewFile($event)"
                               [fileTypes]="['XLSX', 'XLS', 'CSV']"></fivef-csv-import-dropzone>
  </mat-card-content>
</ng-container>

<ng-template [ngIf]="ididLoading" [ngIfElse]="dataContentContainer">
  <div class="w-100 mt-2 mb-3 d-flex justify-content-center">
    <fivef-loading-indicator [message]="'THIRD_PARTY.WIZARD.IMPORT_FILE_LOADING'"></fivef-loading-indicator>
  </div>
</ng-template>

<ng-template #dataContentContainer>
  <ng-content select=".after-drop-zone"></ng-content>
  <ng-container *ngIf="parsedCsv">
    <mat-card-content class="col-12 col-sm-12 mb-2" *ngIf="parsedCsv?.totalNumberOfErrors">
      <dvtx-csv-import-error-box [fileName]="parsedCsv.filename"
                                 [numberOfErrors]="parsedCsv?.totalNumberOfErrors"></dvtx-csv-import-error-box>
    </mat-card-content>

    <fivef-csv-import-display [children]="parsedCsv?.children" [columns]="_settings.columns"
                              [title]="_settings.text.displayTableTitle | translate"></fivef-csv-import-display>

    <ng-container *ngIf="idid && parsedCsv?.children?.length === 0">
      <fivef-icon-message-block [icon]="'info'"
                                [message]="'IMPORT.ERRORS.IMPORT_EMPTY'">
        <button class="ff-mt-m"
                mat-stroked-button (click)="idid = null">{{ 'IMPORT.REPEAT_IMPORT_Q' | translate }}</button>
      </fivef-icon-message-block>
    </ng-container>
  </ng-container>
</ng-template>
