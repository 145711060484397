import {NgModule} from '@angular/core';
import {DocumentSignatureUserAccountFormComponent} from './components/document-signature-user-account-form/document-signature-user-account-form.component';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {DocumentSignatureUserAccountDialogComponent} from './containers/document-signature-user-account-dialog/document-signature-user-account-dialog.component';
import {SignatureBoxComponent} from './components/signature-box/signature-box.component';
import {CreateSignatureDialogComponent} from './components/create-signature-dialog/create-signature-dialog.component';
import {FivefAlertComponent} from '../../../../lib/fivef-ui/util/fivef-alert/fivef-alert.component';

@NgModule({
  imports: [
    SharedModule,
    FivefAlertComponent,
  ],
  declarations: [
    DocumentSignatureUserAccountFormComponent,
    DocumentSignatureUserAccountDialogComponent,
    SignatureBoxComponent,
    CreateSignatureDialogComponent
  ],
  exports: [
    DocumentSignatureUserAccountDialogComponent,
    CreateSignatureDialogComponent
  ]
})
export class DocumentSignatureUserAccountModule {
}
