import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './invitation.state';
import {OrganizationSelectors} from 'app/+store/organization';
import {NaturalPersonSelectors} from 'app/+store/natural-person';
import {Invitation, InvitationStatus, InvitationType} from './invitation.model'
import {NaturalPerson} from 'app/+store/natural-person/natural-person';
import {Message} from 'app/+store/message/message';
import {InvitationBuilder} from './invitation.builder';

export const stateKey = 'invitation';
const getInvitationsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getInvitationEntities,
  selectAll: getAllInvitations,
} = adapter.getSelectors(getInvitationsState);

export const selectUsersInvitations = createSelector(
  getAllInvitations,
  NaturalPersonSelectors.getMyNaturalPersonEntity,
  (invitations: Invitation[], userNatPers: NaturalPerson) => {
    if (userNatPers) {
      return invitations.filter((inv: Invitation) => inv.invitee_email === userNatPers.mainEmailAddress.emailAddress).sort(invitationSort);
    } else {
      return [];
    }
  }
);

export const selectOrgasInvitations = createSelector(
  getAllInvitations,
  OrganizationSelectors.getSelectedId,
  (invitations: Invitation[], orgaId: string | undefined) => {
    if (orgaId) {
      return invitations.filter((inv: Invitation) => inv.belongsToOrganizationId === orgaId).sort(invitationSort);
    } else {
      return [];
    }
  }
);

export const getOpenInvitationsCountByEmail = (email) => createSelector(
  getAllInvitations,
  (invitations: Invitation[]) => {
    return invitations.filter((inv: Invitation) => inv.current_status === InvitationStatus.Pending && inv.invitee_email === email).length;
  }
);

export const getInvitation = (id: string) => createSelector(
  getInvitationEntities,
  entities => entities[id]
);

export const getNotifications = createSelector(
  selectUsersInvitations,
  (invitations: Invitation[]): Message[] => {
    return invitations.map((inv: Invitation) => InvitationBuilder.invitationToNotification(inv));
  }
);

export const getLoadingState = createSelector(
  getInvitationsState,
  state => state.loading
);

export function invitationSort(a: Invitation, b: Invitation) {
  if (a.created_at > b.created_at) {
    return -1;
  }
  if (a.created_at < b.created_at) {
    return 1;
  }
  // a muss gleich b sein
  return 0;
}
