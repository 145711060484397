import {ChangeDetectionStrategy, Component, HostBinding, HostListener, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefIconComponent} from '../../common/fivef-icon/fivef-icon.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'fivef-menu-item',
  standalone: true,
  host: {'class': 'fivef-menu-item'},
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    FivefIconComponent,
    MatFormFieldModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './fivef-menu-item.component.html',
  styleUrls: ['./fivef-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefMenuItemComponent {
  @HostBinding('class')
  private hostClass = '';

  @Input()
  public icon: string;

  @Input()
  color: string;

  @Input()
  public inProgress: boolean = false;

  @Input()
  public title: string;

  @Input()
  public subtitle: string;

  @Input()
  public hasSubItems = false;

  @Input()
  public set disabled(disable) {
    this.hostClass = disable ? 'fivef-menu-item fivef-menu-item--disabled' : 'fivef-menu-item';
  }
}
