import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Apply,
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Update,
  UpdateFail,
  UpdateSuccess,
  WorkflowTemplateActionTypes
} from './workflow-template.actions';
import {WorkflowTemplate} from './workflow-template';
import {WorkflowTemplateService} from './workflow-template.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class WorkflowTemplateEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.scope, action.templateType).pipe(
        first(),
        concatMap((res: WorkflowTemplate[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((res: WorkflowTemplate) => {
          return [new LoadOneSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  delete$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.delete(action.id).pipe(
        first(),
        concatMap((template: WorkflowTemplate) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.TEMPLATE_DELETED_SUCCESSFULLY')
          return [
            new DeleteSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.Update),
    switchMap((action: Update) => {

      return this._svc.update(action.id, action.payload).pipe(
        first(),
        concatMap((template: WorkflowTemplate) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.TEMPLATE_UPDATED_SUCCESSFULLY')
          return [
            new UpdateSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.payload).pipe(
        first(),
        concatMap((template: WorkflowTemplate) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.TEMPLATE_CREATED_SUCCESSFULLY')
          return [
            new CreateSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  apply$ = createEffect(() => this.actions.pipe(
    ofType(WorkflowTemplateActionTypes.Apply),
    switchMap((action: Apply) => {
      return this._svc.applyTemplate(action.id).pipe(
        first(),
        concatMap((template: WorkflowTemplate) => {
          this._notifyService.success('WORKFLOW_TEMPLATE.TEMPLATE_APPLIED_SUCCESSFULLY')
          return [
            new UpdateSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: WorkflowTemplateService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




