import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';
import {TranslateModule} from '@ngx-translate/core';
import {FivefPageSidebarComponent} from './fivef-page-sidebar/fivef-page-sidebar.component';
import {MatBadgeModule} from '@angular/material/badge';
import {FivefPageSidebarProcessHeaderComponent} from './fivef-page-sidebar-process-header/fivef-page-sidebar-process-header.component';
import {WorkflowEngineModule} from 'app/modules/workflow-engine/workflow-engine.module';
import {TasksModule} from 'app/modules/tasks/tasks.module';
import {FivefPageSidebarNavigationComponent} from './fivef-page-sidebar-navigation/fivef-page-sidebar-navigation.component';
import {FivefNavLinkComponent} from '../../navigation/fivef-nav-link/fivef-nav-link.component';
import {TooltipModule} from '../../../fivef-legacy/tooltip/tooltip.module';
import {BaseFormElementsModule} from '../../../fivef-legacy/base-form-elements/base-form-elements.module';
import {FivefProcessTreeSidebarWidgetComponent} from '../../process/fivef-process-tree-sidebar-widget/fivef-process-tree-sidebar-widget.component';
import {FivefProcessArtifactTreeComponent} from '../../artifact/fivef-process-artifact-tree/fivef-process-artifact-tree.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BaseFormElementsModule,
    MatBadgeModule,
    TranslateModule,
    PortalModule,
    TooltipModule,
    WorkflowEngineModule,
    TasksModule,
    FivefNavLinkComponent,
    FivefProcessTreeSidebarWidgetComponent,
    FivefProcessArtifactTreeComponent
  ],
  declarations: [
    FivefPageSidebarComponent,
    FivefPageSidebarNavigationComponent,
    FivefPageSidebarProcessHeaderComponent
  ],
  exports: [
    FivefPageSidebarComponent,
    FivefPageSidebarNavigationComponent,
    PortalModule,
    FivefPageSidebarProcessHeaderComponent
  ]
})
export class FivefPageSidebarModule {
}
