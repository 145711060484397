import {UserTwoFactorWhitelist} from './user-two-factor-whitelist';
import {IApiResourceBuilder} from '../../lib/fivef-net/fivef-api-resource/models/api.interface';


export class UserTwoFactorWhitelistBuilder implements IApiResourceBuilder<UserTwoFactorWhitelist> {
  fromResponse(data): UserTwoFactorWhitelist {
    return new UserTwoFactorWhitelist(
      data.id,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.created_at,
      data.attributes.email
      );
  }

  toRequest(user: UserTwoFactorWhitelist) {
    return null;
  }
}
