import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefAvatarComponent} from './fivef-avatar/fivef-avatar.component';
import {FivefAvatarService} from './fivef-avatar.service';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefAvatarShapeComponent} from './fivef-avatar-shape/fivef-avatar-shape.component';
import {InViewportModule} from 'ng-in-viewport';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefSafeHtmlPipe} from '../../util/fivef-safe-html-pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    InViewportModule,
    MatMenuModule,
    MatCardModule,
    FivefDialogComponent,
    FivefSafeHtmlPipe
  ],
  declarations: [
    FivefAvatarComponent,
    FivefAvatarShapeComponent
  ],
  providers: [
    FivefAvatarService
  ],
  exports: [
    FivefAvatarComponent,
    FivefAvatarShapeComponent
  ]
})
export class FivefAvatarModule {
}
