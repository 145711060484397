import {Action} from '@ngrx/store';
import {CacEntity} from './cac-entity';

export enum CacEntityActionTypes {
  LoadAll = '[CacEntity] LoadAll One',
  LoadAllSuccess = '[CacEntity] LoadAll One Success',
  LoadAllFail = '[CacEntity] LoadAll One Fail',
  Load = '[CacEntity] Load One',
  LoadSuccess = '[CacEntity] Load One Success',
  LoadFail = '[CacEntity] Load One Fail',
}

export class LoadAll implements Action {
  readonly type = CacEntityActionTypes.LoadAll;

  constructor(public processId: string) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CacEntityActionTypes.LoadAllSuccess;

  constructor(public societies: CacEntity[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CacEntityActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Load implements Action {
  readonly type = CacEntityActionTypes.Load;

  constructor(public id: string) {
  }
}

export class LoadSuccess implements Action {
  readonly type = CacEntityActionTypes.LoadSuccess;

  constructor(public society: CacEntity) {
  }
}

export class LoadFail implements Action {
  readonly type = CacEntityActionTypes.LoadFail;

  constructor(public payload: any) {
  }
}

export type CacEntityActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Load
  | LoadSuccess
  | LoadFail;
