import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {LicenceAssignment} from 'app/+store/_legacy/api/models/licence';

export interface State extends EntityState<LicenceAssignment> {
  my?: string
  loading: boolean,
  licencedOrganizations: any
}

export const adapter = createEntityAdapter<LicenceAssignment>();

export const initialState: State = adapter.getInitialState({
  my: undefined,
  loading: false,
  licencedOrganizations: []
});
