import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {EmailFooter} from './email-footer';

export class EmailFooterBuilder implements IApiResourceBuilder<EmailFooter> {
  fromResponse(data): EmailFooter {
    return new EmailFooter(
      data.id,
      data.attributes.title,
      data.attributes.content,
      data.attributes.creator_email,
      data.attributes.published_at,
      data.attributes.updated_at,
      data.attributes.created_at,
      !!data.attributes.published_at
    );
  }

  toRequest (footer: EmailFooter) {
    return {
      data: {
        id: footer.id,
        type: 'email_footers',
        attributes: {
          title: footer.title,
          content: footer.content,
          published: footer.published,
        }
      }
    };
  }
}
