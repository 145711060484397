import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {TemplateVisibility, WorkflowTemplate} from './workflow-template';

export class WorkflowTemplateBuilder implements IApiResourceBuilder<WorkflowTemplate> {
  total = 1;
  perPage = 1;
  records = 1;

  fromResponse(data): WorkflowTemplate {
    const visibilities = {
      'hidden': TemplateVisibility.Hidden,
      'personal': TemplateVisibility.Personal,
      'global': TemplateVisibility.Global,
    }
    const {
      template_type,
      client_id,
      template_data,
      updated_at,
      visibility,
      title,
      description,
      creator_id,
      color,
      created_at,
      category_id,
      usage_count,
      locked_at,
      blocked_at,
      performer_id,
    } = data.attributes;
    const template = new WorkflowTemplate(
      data.id,
      template_type,
      title,
      description,
      visibilities[visibility],
      client_id,
      creator_id,
      color,
      created_at,
      template_data?.data,
      updated_at,
      category_id,
      locked_at,
      blocked_at,
      {usageCount: usage_count},
      {performerId: performer_id},
    );
    template.total = this.total;
    template.perPage = this.perPage;
    template.records = this.records;
    return template;
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }

  toRequest(_: WorkflowTemplate) {
    return null;
  }
}

