import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import {Router} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fivef-back',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatFormFieldModule, MatIconModule, TranslateModule],
  templateUrl: './fivef-back.component.html',
  styleUrls: ['./fivef-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefBackComponent {
  @Input()
  public title: string;

  @Input()
  public route: string = null;

  constructor(public location: Location, private _router: Router) {}

  goBack() {
    if (this.route) {
      this._router.navigate([this.route]);
    } else {
      this.location.back();
    }
  }
}
