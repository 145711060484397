import {NgModule} from '@angular/core';
import {ProcessAttributeModule} from './modules/process-attribute/process-attribute.module';
import {ProcessDialogModule} from './modules/process-dialog/process-dialog.module';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';

@NgModule({
  imports: [
    ProcessDialogModule,
    ProcessAttributeModule,
    ScrollingModule
  ],
  declarations: [],
  exports: [
    ProcessDialogModule,
    ProcessAttributeModule
  ]
})
export class WorkflowEngineModule {
}
