
import {of as observableOf, Observable} from 'rxjs';

import {catchError, first, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {RegistrationUser} from '../../../+store/registration/registration.interface';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class FoxdoxService {
  basePath;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
  }

  createFoxdoxAccount(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          password: user.password,
          confirmation_token: user.confirmationToken,
          natural_person_attributes : {
            first_name: user.firstName,
            last_name: user.lastName
          }
        }
      }
    };
    const apiResponse = { success: true, message: '' };
    return this._http.post(`${this.basePath}/foxdox/registrations`, payload).pipe(
      map((_res: any) => apiResponse),
      catchError((_error: HttpErrorResponse) => {
        const error = _error.error;
        return observableOf({
          success: false,
          message: error.errors[0].details
        });
      }),);
  }

  create(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          password: user.password,
          confirmation_token: user.confirmationToken,
          natural_person_attributes : {
            first_name: user.firstName,
            last_name: user.lastName
          }
        }
      }
    };
    const apiResponse = { success: true, message: '' };
    return this._http.post(`${this.basePath}/foxdox/registrations`, payload)
      .pipe(first(), map((_res: any) => apiResponse))
  }

  loginExistingFoxdoxAccount(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          password: user.password,
          confirmation_token: user.confirmationToken,
          foxdox_username: user.foxdoxUsername,
          foxdox_password: user.foxdoxPassword,
          natural_person_attributes : {
            first_name: user.firstName,
            last_name: user.lastName
          }
        }
      }
    };
    const apiResponse = { success: true, message: '' };
    return this._http.post(`${this.basePath}/foxdox/connect`, payload).pipe(
      map((_res: any) => apiResponse),
      catchError((_error: HttpErrorResponse) => {
        const error = _error.error;
        return observableOf({
          success: false,
          message: error.errors[0].details
        });
      }),);
  }

  registerAltenativeFoxdoxAccount(user: RegistrationUser, email: string) {
    const payload = {
      data: {
        attributes: {
          email: email,
          password: user.password,
          confirmation_token: user.confirmationToken,
          natural_person_attributes : {
            first_name: user.firstName,
            last_name: user.lastName
          }
        }
      }
    };
    const apiResponse = { success: true, message: '' };
    return this._http.post(`${this.basePath}/foxdox/registrations`, payload).pipe(
      map((_res: any) => apiResponse),
      catchError((_error: HttpErrorResponse) => {
        const error = _error.error;
        return observableOf({
          success: false,
          message: error.errors[0].details
        });
      }),);
  }

  verifyFoxdoxAccount() {
    return this._http.get(`${this.basePath}/foxdox/status`).pipe(
      map((res: any) => res.data));
  }

  signInFoxdoxAccount(credentials) {
    console.log('credentials', credentials);
    const payload = {
      data: {
        attributes: {
          foxdox_username: credentials.foxdoxUsername,
          foxdox_password: credentials.foxdoxPassword
        }
      }
    };
    return this._http.post(`${this.basePath}/foxdox/sign_in`, payload).pipe(
      map((res: any) => res.data));
  }


}
