import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {FivefUnsupportedBrowserComponent} from './fivef-unsupported-browser/fivef-unsupported-browser.component';
import {FivefBrowserSupportContainerComponent} from './fivef-browser-support-container/fivef-browser-support-container.component';
import {FivefAlertComponent} from '../../util/fivef-alert/fivef-alert.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FivefAlertComponent
  ],
  declarations: [
    FivefUnsupportedBrowserComponent,
    FivefBrowserSupportContainerComponent
  ],
  exports: [
    FivefUnsupportedBrowserComponent,
    FivefBrowserSupportContainerComponent,
    SharedModule
  ]
})
export class FivefBrowserSupportModule {
}
