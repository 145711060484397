import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollectAndDelegateWorkflowModule} from '../../../../modules/customization/modules/collect-and-delegate-workflow/collect-and-delegate-workflow.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FivefDisableInputHintsWrapperDirective} from '../fivef-disable-input-hints-wrapper.directive';

@Component({
  selector: 'fivef-search',
  host: {'class': 'fivef-search'},
  standalone: true,
  imports: [
    CommonModule,
    CollectAndDelegateWorkflowModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    FivefDisableInputHintsWrapperDirective
  ],
  templateUrl: './fivef-search.component.html',
  styleUrls: ['./fivef-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefSearchComponent {
  @Input()
  public disabled = false;

  @Output()
  onChange = new EventEmitter<string>();

  @Input()
  public searchTerm: string = '';

  @HostBinding('style.justify-content')
  contentAlignment = 'flex-start'

  @Input()
  set align(align: 'start' | 'end') {
    this.contentAlignment = align === 'end' ? 'flex-end' : 'flex-start';
  }

  public search($event: string) {
    this.onChange.emit($event);
  }
}
