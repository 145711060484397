import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './project-statistics.state';
import { ProcessesStatsObj, TasksStatsObj, CollectorsStatsObj, DocumentsStatsObj } from './project-statistics.interface';
import * as ProcessSelectors from 'app/+store/process/process.selectors';
import * as CollectorSelectors from 'app/+store/collector/collector.selectors';

export const stateKey = 'process_stat_views';
const getProjectStatsState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProjectStatsEntities,
  selectAll: getAllProjectStatsModels,
} = adapter.getSelectors(getProjectStatsState);

export const getStatistics = createSelector(
  getAllProjectStatsModels,
  ProcessSelectors.getSelectedProcess,
  CollectorSelectors.getSelected,
  (entities, process, collector) => {
    if (process || collector) {
      return entities.filter(entity => (process && process.id && entity.processId === process.id) || (collector && collector.id && entity.processId === collector.id))
    }
    return entities.filter(entity => !entity.processId);
  }
);

export const loadingState = createSelector(
  getProjectStatsState,
  (state) => state.loading
);

export const getProcessesStatistics = () => createSelector(
  getStatistics,
  (entities) => entities && entities[0] ? entities[0].processes : <ProcessesStatsObj>{}
);

export const getTasksStatistics = () => createSelector(
  getStatistics,
  (entities) => {
    return entities && entities[0] ? entities[0].tasks : <TasksStatsObj>{}
  }
);

export const getTasksStatisticsById = (id: string) => createSelector(
  getAllProjectStatsModels,
  (stats) => {
    if (!id) return <TasksStatsObj>{};
    const found = stats.find(stat => stat.processId === id);
    return found ? found.tasks : <TasksStatsObj>{}
  });

export const getCollectorsStatistics = () => createSelector(
  getStatistics,
  (entities) => entities && entities[0] ? entities[0].collectors : <CollectorsStatsObj>{}
);

export const getDocumentsStatistics = () => createSelector(
  getStatistics,
  (entities) => entities && entities[0] ? entities[0].documents : <DocumentsStatsObj>{}
);
