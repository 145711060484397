import {filter, takeUntil, distinctUntilChanged, first} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {DmsFolderActions, FeatureSelectors, OrganizationActions, OrganizationSelectors} from 'app/+store';
import {Organization} from 'app/+store/organization/organization';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {OrganizationProxy} from 'app/+store/organization/organization-proxy';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Feature} from 'app/+store/feature/feature';

@Component({
  selector: 'dvtx-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  orga: Organization;

  public organizationProxy: OrganizationProxy;
  public cleanupStarted = false;

  public featureSet$: Observable<Feature>;

  constructor(private _store: Store<AppState>,
              private _organizationProxySvc: OrganizationProxyService,
              private _notifyService: FivefNotificationService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    this._store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(orga => !!orga),
        distinctUntilChanged((x, y) => x.id === y.id),
        takeUntil(this.onDestroy)
      )
      .subscribe(orga => {
        this._store.dispatch(new OrganizationActions.LoadDetailed(orga.id));
        this._fetchApiOrganizationProxy(orga.id);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Fetches the organization proxy model from the Core API.
   */
  _fetchApiOrganizationProxy(id: string) {
    if (!id) return;

    this._organizationProxySvc.getOne(id)
      .pipe(first())
      .subscribe((org: OrganizationProxy) => {
        this.organizationProxy = org;
      });
  }

  toggleGrantClientsFolderToMembers($event: MatCheckboxChange) {
    this._organizationProxySvc.toggleGrantClientsFolderToMembers(this.organizationProxy.id, $event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
      }, err => {
        this._notifyError(err);
      });
  }

  toggleForceTwoFactorAuthentication($event: MatCheckboxChange) {
    this._organizationProxySvc.toggleForceTwoFactorAuthentication(this.organizationProxy.id, $event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
      }, err => {
        this._notifyError(err);
      });
  }

  toggleDisableBusinessPartnerDmsContacts($event: MatCheckboxChange) {
    this._organizationProxySvc.toggleDisableBusinessPartnerDmsContacts(this.organizationProxy.id, !$event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
      }, err => {
        this._notifyError(err);
      });
  }

  startManualContactCleanup() {
    this.cleanupStarted = true;
    this._cdr.detectChanges();

    this._organizationProxySvc.manualContactCleanup(this.organizationProxy.id)
      .pipe(first())
      .subscribe(_org => {
        this._notifyService.info('ORGANIZATION.MANUAL_DMS_CONTACT_CLEANUP_ACTION_STARTED');
      }, err => {
        this._notifyError(err);
      });
  }

  togglePrivateDms($event: MatCheckboxChange) {
    this._organizationProxySvc.togglePrivateDms(this.organizationProxy.id, $event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
        this._store.dispatch(new DmsFolderActions.Refresh)
      }, err => {
        this._notifyError(err);
      });
  }

  toggleShowClientToBusinessPartner($event: MatCheckboxChange) {
    this._organizationProxySvc.toggleShowClientToBusinessPartner(this.organizationProxy.id, $event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
      }, err => {
        this._notifyError(err);
      });
  }

  toggleShowClientInEmail($event: MatCheckboxChange) {
    this._organizationProxySvc.toggleShowClientInEmail(this.organizationProxy.id, $event.checked)
      .pipe(first())
      .subscribe(org => {
        this._settingsApplied(org);
      }, err => {
        this._notifyError(err);
      });
  }

  private _notifyError(err) {
    this._notifyService.error('HTTP_ERROR.DEFAULT')
    console.error(err);
  }

  private _settingsApplied(org) {
    this.organizationProxy = org;
    this._notifyService.info('ORGANIZATION.ORGANIZATION_SETTING_APPLIED');
  }
}
