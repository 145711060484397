<dvtx-dropdown-content [xPosition]="'before'"
                       [icon]="containerContentTogglerIconPortal"
                       [content]="containerContentPortal"
                       [tooltip]="'GENERAL.FILTERS' | translate"
                       [buttonType]="'iconTextButton'"
                       [iconButtonColor]="'gray'"
                       [backdropClassesList]="'fivef-filters-dropdown-menu'"
                       [borderColor]="'no-border'"
                       #containerDropdownMenu>
</dvtx-dropdown-content>

<ng-template cdkPortal #containerContentTogglerIcon>
  <span class="fivef-filters-container--count-indicator"
        [class.fivef-filters-container--count-indicator--badge-left]="labelPosition === 'right'"
        [class.fivef-filters-container--count-indicator--zero]="selectedCount === 0"
        [matBadge]="selectedCount"
        matBadgeOverlap="false"
        [matTooltip]="title | translate">
    <ng-container *ngIf="labelPosition === 'left'">{{title | translate}}</ng-container>
    <mat-icon svgIcon="filter_alt"></mat-icon>
    <ng-container *ngIf="labelPosition === 'right'">{{title | translate}}</ng-container>
  </span>
</ng-template>

<ng-template cdkPortal #containerContent>
  <ng-content></ng-content>
</ng-template>
