import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiErrorBuilder} from 'app/+store/_legacy/api/builders/api-error.builder';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {IShoppingCart} from 'app/+store/_legacy/api/models/shopping-cart.interface';
import {ShoppingCart} from 'app/+store/_legacy/api/models/shopping-cart';
import {LineItem} from 'app/+store/_legacy/api/models/line-item';

import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

export enum CartActionTypes {
  BUY_LICENCE = 'buy_licence',
  BUY_USER_LICENCE = 'buy_user_licence',
  UPGRADE = 'upgrade'
}

/**
 * Service to manage a shopping cart.
 */
@Injectable()
export class ShoppingCartService {
  basePath;
  readonly BASE_PATH = 'sales/carts';

  constructor(private _tokenSvc: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  /**
   * Get all Products inside the cart.
   * @returns {Observable<LineItem[]>}
   */
  getAll(): Observable<LineItem[]> {
    return this._tokenSvc.get(this.basePath).pipe(
      map(res => <LineItem[]>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  /**
   * Detailed configuration of the user's shopping cart.
   * Returns invoice address, SEPA data and line items as includes.
   *
   * @returns {Observable<ShoppingCart>}
   */
  getDetails(): Observable<IShoppingCart> {
    return this._tokenSvc.get(`${this.basePath}/details`).pipe(
      map(res => <IShoppingCart>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  /**
   * Adds a product and its quantity to the cart.
   */
  addProductToCart(productId, quantity) {
    const payload = { data: { attributes: { product_id: productId, quantity: quantity } } };
    return this._tokenSvc.post(this.basePath, payload).pipe(
      map(res => <LineItem>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }


  /**
   * Adds a product and its quantity to the cart.
   */
  addLicenceProductToCart(licenceId, userLicencesCount, paymentInterval, actionType = CartActionTypes.BUY_LICENCE) {
    const payload = { data: { attributes: { action: actionType, licence_id: licenceId, user_licences_count: userLicencesCount, payment_interval: paymentInterval} } };
    return this._tokenSvc.post(this.basePath, payload).pipe(
      map(res => <LineItem>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  /**
   * clear products from cart.
   */
  cleanUpCart() {
    const payload = { data: '' };
    return this._tokenSvc.post(`${this.basePath}/cleanup`, payload).pipe(
      map(res => <LineItem>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  /**
   * Select the invoice address of the cart.
   *
   * @param invoiceAddressId
   * @returns {Observable<any>}
   */
  selectInvoiceAddress(invoiceAddressId) {
    const payload = { data: { attributes: { invoice_address_id: invoiceAddressId } } };
    return this._tokenSvc.post(`${this.basePath}/invoice_address`, payload).pipe(
      map(res => res),
      catchError(this.handleError),);
  }

  selectPaymentOption(id) {
    const payload = { data: { attributes: { payment_option_id: id } } };
    return this._tokenSvc.post(`${this.basePath}/payment_option`, payload).pipe(
      map(res => res),
      catchError(this.handleError),);
  }

  private handleError(error) {
    if (error.json) {
      const errors = ApiErrorBuilder.fromResponse(error);
      return observableThrowError(errors);
    }
    return observableThrowError(error);
  }
}
