import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {BatchContactDeletionDialogComponent} from './containers/batch-contact-deletion-dialog/batch-contact-deletion-dialog.component';
import {BatchPartnerLinkActivationDialogComponent} from './containers/batch-partner-link-activation-dialog/batch-partner-link-activation-dialog.component';
import {FivefAlertComponent} from '../../../../lib/fivef-ui/util/fivef-alert/fivef-alert.component';

@NgModule({
  imports: [
    SharedModule,
    FivefAlertComponent
  ],
  declarations: [
    BatchContactDeletionDialogComponent,
    BatchPartnerLinkActivationDialogComponent
  ],
  exports: [
    SharedModule,
    BatchContactDeletionDialogComponent,
    BatchPartnerLinkActivationDialogComponent
  ]
})
export class BatchDialogModule {
}
