<ng-template [ngIf]="accessDenied">
  <h2 class="mb-3">{{ 'AUTH.NOT_FOUND_OR_NOT_PERMITTED' | translate }}</h2>
  <h3>{{ 'GENERAL.THANK_YOU_FOR_CHOSING_US' | translate }}</h3>
</ng-template>

<ng-template [ngIf]="!success">
  <dvtx-request-confirmation-display *ngIf="thirdPartyContribution$ | async as thirdPartyContribution"
                                     [allFiles]="allFiles"
                                     [thirdPartyContribution]="thirdPartyContribution"
                                     [token]="submitToken | async"
                                     (submit)="onSubmit($event)"
                                     (uploadManualReload)="uploadManualReload.next(undefined)"
  ></dvtx-request-confirmation-display>
</ng-template>

<ng-template [ngIf]="success">
  <div class="w-100 d-flex justify-content-center dvtx-third-party__contact-verification-form">
    <div class="w-100" style="max-width: 600px">
      <mat-card appearance="outlined" class="five-f-card five-f-elevation-z-2">
        <mat-card-content>
          <div class="p-2">
            <div class="w-100 d-flex justify-content-center py-5">
              <div class="d-flex flex-column">
                <div class="w-100 mb-2 d-flex justify-content-center">
                  <div class="h2">{{ 'THIRD_PARTY.CONTRIBUTION.THANK_YOU_FOR_CONTRIBUTION' | translate }}</div>
                </div>
                <div class="w-100 d-flex justify-content-center" style="height: 70px;">
                  <mat-icon class="fivef-color-status-info" style="font-size: 48px">send</mat-icon>
                </div>
                <div class="w-100 d-flex justify-content-center">
                  <div class="h2">{{ 'THIRD_PARTY.EXTERNAL_ACCESS.CONFIRMATION_EMAIL_SENT' | translate }}</div>
                </div>
                <div class="w-100 d-flex justify-content-center">
                  <div
                    class="h4 text-center">{{ 'THIRD_PARTY.EXTERNAL_ACCESS.CONFIRMATION_EMAIL_FOLLOW_LINK' | translate }}</div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
