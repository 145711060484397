
import {throwError as observableThrowError, Subject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {UntypedFormGroup} from '@angular/forms';
import {ApiErrorBuilder} from '../../../+store/_legacy/api/builders/api-error.builder';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class OrganizationUploadService {

  uploader: FileUploader;
  form: UntypedFormGroup;

  // TODO change BASE_PATH for the logo
  readonly BASE_PATH = 'workflow_engine/processes';
  private subject = new Subject();
  uploaderChanged = new Subject<FileUploader>();
  formChanged = new Subject<UntypedFormGroup>();

  constructor() {}

  subjectChange(data) {
    this.subject.next(data);
  }

  subjectAsObservable() {
    return this.subject.asObservable();
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }

  getUploader() {
    return this.uploader;
  }

  updateUploader(newUploader: FileUploader) {
    this.uploader = newUploader;
    this.uploaderChanged.next(this.uploader);
  }

  getForm() {
    return this.form;
  }

  updateForm(newForm: UntypedFormGroup) {
    this.form = newForm;
    this.formChanged.next(this.form);
  }
}
