import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {first, map} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {IApiResourceBuilder, IApiResponse} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/builders/api-resource.builder';
import {AngularTokenService} from 'angular-token';

@Injectable()
export class GrantThorntonApiResourceService {
  apiUrl;

  constructor(private http: HttpClient, private env: EnvService, private _tokenSvc: AngularTokenService) {
    this.apiUrl = env.customerApiBase();
  }

  getAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getOne<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    return this.http
      .get(`${this.apiUrl}/${path}/${resource.id}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  get<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  update<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .put(`${this.apiUrl}/${path}/${resource.id}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  put<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  putAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  create<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  post<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  postAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M[]> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  delete<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}/${resource.id}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getBlob(url: string, fileName: string, currentAuthData = null) {
    if (currentAuthData) {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
      delete headersConfig['tokenType'];
      delete headersConfig['accessToken'];
      const headers = new HttpHeaders(headersConfig)

      this.http.get(url, {
        responseType: 'blob',
        headers: headers,
      }).pipe(first())
        .subscribe((blob) => {
          saveAs(blob, fileName);
        }, err => {
          console.error(err);
        });
      return;
    }
    this.http.get(url, {
      responseType: 'blob'
    }).pipe(first())
      .subscribe((blob) => {
        saveAs(blob, fileName);
      }, err => {
        console.error(err);
      });
  }

  del<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  private _setHeaders(): HttpHeaders {
    const currentAuthData: any = this._tokenSvc.currentAuthData;
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (this._tokenSvc.currentAuthData) {
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
    }
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    return new HttpHeaders(headersConfig)
  }
}

