<dvtx-user-profile-settings-header [routes]="routes"
                                   [activeLink]="activeLink"
                                   [title]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'"
                                   [subtitle]="'SIGNATURE.ACCOUNT.ACCOUNT_DATA'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <div class="mt-2 d-flex">
    <img src="./assets/img/fp_sign.png" style="width: 180px" />
  </div>
  <dvtx-document-signature-user-account-dialog></dvtx-document-signature-user-account-dialog>
</div>
