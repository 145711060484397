import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ITabNavRoute} from '../../models/tab-nav-route.interface';
import {Location} from '@angular/common';
import {OrganizationSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Organization} from 'app/+store/organization/organization';
import {Process} from 'app/+store/process/process';
import {takeUntil} from 'rxjs/operators';
import {Portal} from '@angular/cdk/portal';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import { Router } from '@angular/router';

@Component({
  selector: 'dvtx-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss']
})
export class SettingsHeaderComponent implements OnInit , OnDestroy {
  private onDestroy = new Subject<void>();

  @Input() title = '';
  @Input() subtitle: string;
  @Input() toOverview = false;
  backLink = null;
  browserBackEnabled = true;
  @Output() onBack = new EventEmitter();
  @Input() routes: ITabNavRoute[] = null;
  @Input() activeLink: string;
  @Input() callToActionPortal: Portal<any>;
  @Input() globalActionPortal: Portal<any>;
  @Input () Administration = false;
  @Input () showProjectName = false;
  @Input () workflowBackNavigation = false;
  _process: Process;
  _iconConfig: { icon: string, isSvgIcon: boolean };
  @Input() set process(p: Process) {
    this._process = p;
    if (p && p.processType) {
      this._iconConfig = Process.iconForType(p.processType);
    }
  }

  @Input() enableSearch = false;

  public organization$: Observable<Organization>;

  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchTerm: string;

  @Output() onSearch = new EventEmitter<string>();

  @ViewChild('searchFieldRef') searchFieldRef;

  constructor(private store: Store<AppState>,
              private _location: Location,
              private _router: Router,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);

    this.searchTerm$.pipe(takeUntil(this.onDestroy)).subscribe(term => {
      this.onSearch.emit(term);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.searchTerm$.complete();
  }

  back() {
    this._location.back();
  }

  public applySearch($event) {
    this.searchTerm$.next($event);
  }

  public clearSearch() {
    this.searchTerm = '';
    this.searchTerm$.next('');
    this._cdr.detectChanges();
  }

  public navigateToOverview() {
    switch (this._process.processType) {
      case 'quickcollector':
        this._router.navigate([`/collecto/run/${this._process.id}/dashboard`]);
        break;
      case 'project':
        this._router.navigate([`projects/run/${this._process.id}/timeline`]);
        break;
      case 'quickstart':
      case 'quickshare':
      case 'quickshare_v2':
        this._router.navigate([`/workflows/timelines/${this._process.id}`]);
        break;
      case 'cac':
        this._router.navigate([`/workflows/audit/kap/${this._process.id}/appendices`]);
        break;
      case 'cac_request':
        this._router.navigate([`/workflows/audit/kap/communicate-respond/${this._process.id}`]);
        break;
      case 'audit_contact_imports':
        this._router.navigate([`/workflows/audit/kap/${this._process.parentId}/contacts-timeline/${this._process.id}`]);
        break;
        case 'claim_balance':
        case 'liability_balance':
          this._router.navigate([`/workflows/audit/jap-bestaetigung-dritte/overview/${this._process.id}`]);
        break;
      default :
      this._router.navigate([`/workflows/timelines/${this._process.id}`]);
    }
  }
}
