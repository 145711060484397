import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefFileSizePipe} from '../../util/fivef-file-size.pipe';
import {MatButtonModule} from '@angular/material/button';

/**
 * Button a like download component used at Quickshare external view, third party external view,
 * the third party event, and the CAC instruction downloads inside the CAC entity dashboard.
 */
@Component({
  selector: 'fivef-file-button',
  standalone: true,
  host: { class: 'fivef-file-button'},
  imports: [CommonModule, MatIconModule, MatTooltipModule, FivefFileSizePipe, MatButtonModule],
  templateUrl: './fivef-file-button.component.html',
  styleUrls: ['./fivef-file-button.component.scss']
})
export class FivefFileButtonComponent implements OnInit {
  @Input()
  title: string = ''

  @Input()
  uploaderName: string = ''

  @Input()
  size: number = 0

  @Input()
  artifact: any;

  @Input()
  color: string = null;

  @Input()
  public enableDelete = false;

  @Input()
  colorClass: 'default' | 'audit-instruction' = 'default';

  @Output()
  onDownload = new EventEmitter();

  @Output()
  onDelete = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public download(artifact): void {
    this.onDownload.emit(artifact);
  }

  public delete(artifact): void {
    this.onDelete.emit(artifact);
  }
}
