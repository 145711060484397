import {Component, ComponentFactoryResolver, Injectable, Injector, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'dvtx-mat-icon-renderer',
  template: '<mat-icon *ngIf="!isSVGIcon">{{icon}}</mat-icon><mat-icon *ngIf="isSVGIcon" [svgIcon]="icon"></mat-icon>'
})

export class MatIconRendererComponent {
  @Input() icon;
  @Input() isSVGIcon;
}

/**
 * Loader service registering new SVG icons to the Material Icon theme.
 */
@Injectable()
export class IconLoaderService {
  constructor(private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private injector: Injector) {
  }

  init() {
    // When loaded locally from assets (OTC OBS preferred to reduce traffic on platform)
    // this.registerMatSvgIcon('process', '../assets/icons/process.svg');
    // Icons must be uploaded first to be publicly accessible at https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/
    this.registerMatSvgIcon('process', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/process.svg');
    this.registerMatSvgIcon('person_verif', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/cav.svg');
    this.registerMatSvgIcon('exchange_circle', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/cac.svg');
    this.registerMatSvgIcon('cac', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/cac.svg');
    this.registerMatSvgIcon('person_verif2', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/person_verif_24px.svg');
    this.registerMatSvgIcon('exchange_circle2', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/exchange_circle_24px.svg');
    this.registerMatSvgIcon('tasks', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/Icon-Aufgabe.svg');
    this.registerMatSvgIcon('projectroom', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/Icon-Projektraum.svg');
    this.registerMatSvgIcon('notification_important', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/notification_important.svg');
    this.registerMatSvgIcon('timeline', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/timeline.svg');
    this.registerMatSvgIcon('file_copy', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/file_copy.svg');
    this.registerMatSvgIcon('calendar_today', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/date-add.svg');
    this.registerMatSvgIcon('supervised_user_circle', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/baseline-supervised_user_circle-24px.svg');
    this.registerMatSvgIcon('person_add_disabled', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/baseline-person_add_disabled-24px.svg');
    this.registerMatSvgIcon('call_received', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/baseline-call_received-24px.svg');
    this.registerMatSvgIcon('signature', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/file-signature-solid.svg');
    this.registerMatSvgIcon('invoice_approval', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/invoice_approval.svg');
    this.registerMatSvgIcon('resources', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/resources.svg');
    this.registerMatSvgIcon('insertChartOutlined', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/insert_chart_outlined.svg');
    this.registerMatSvgIcon('cavLink', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/cav-link.svg');
    this.registerMatSvgIcon('openInBrowser', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/open-in-browser.svg');
    this.registerMatSvgIcon('datev', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/datev.svg');
    this.registerMatSvgIcon('clients', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/mandant_icon.svg');
    this.registerMatSvgIcon('change_reference', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/change_reference.svg');
    this.registerMatSvgIcon('file_preview', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/file_preview.svg');
    this.registerMatSvgIcon('add_comment', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/add_comment.svg');
    this.registerMatSvgIcon('excel_download', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/excel_download.svg');
    this.registerMatSvgIcon('account_tree', '../assets/icons/account_tree.svg');
    this.registerMatSvgIcon('labels', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/labels.svg');
    this.registerMatSvgIcon('not_applicable', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/not_applicable.svg');
    this.registerMatSvgIcon('templates', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/templates.svg');
    this.registerMatSvgIcon('history_edu', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/history_edu.svg');
    this.registerMatSvgIcon('reset_filters', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/reset_filters.svg');
    this.registerMatSvgIcon('sign', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/sign.svg');
    this.registerMatSvgIcon('rubber-stamp', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/rubber-stamp.svg');
    this.registerMatSvgIcon('rubber-stamp-outline', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/rubber-stamp-outline.svg');
    this.registerMatSvgIcon('mark_read', 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/icons/mark_read.svg');
    this.registerMatSvgIcon('fastdocs', '../assets/icons/fastdocs-signet-anthracite.svg');
    this.registerMatSvgIcon('sign_frame', '../assets/icons/sign_frame.svg');
    this.registerMatSvgIcon('third_party', '../assets/icons/third-party.svg');
    this.registerMatSvgIcon('bookman_cockpit', '../assets/icons/bookman_cockpit.svg');
    this.registerMatSvgIcon('fibu_workflow', '../assets/icons/fibu-workflow.svg');
    this.registerMatSvgIcon('lohn', '../assets/icons/lohn.svg');
    this.registerMatSvgIcon('lohn2', '../assets/icons/lohn_2.svg');
    this.registerMatSvgIcon('enter', '../assets/icons/enter.svg');
    this.registerMatSvgIcon('bookman_folder', '../assets/icons/bookman_folder.svg');
    this.registerMatSvgIcon('bookman_folder_colored', '../assets/icons/bookman_folder_colored.svg');
    this.registerMatSvgIcon('kanban', '../assets/icons/kanban.svg');
    this.registerMatSvgIcon('filter_alt', '../assets/icons/filter_alt-24px.svg');
    this.registerMatSvgIcon('filter_disable', '../assets/icons/filter_disable.svg');
    this.registerMatSvgIcon('versioning_preview', '../assets/icons/versioning_preview.svg');
    this.registerMatSvgIcon('add_label', '../assets/icons/add_label.svg');
    this.registerMatSvgIcon('remove_label', '../assets/icons/remove_label.svg');
    this.registerMatSvgIcon('post_add', '../assets/icons/post_add.svg');
    this.registerMatSvgIcon('file_version', '../assets/icons/file_version.svg');
    this.registerMatSvgIcon('arrow_circle_right', '../assets/icons/arrow_circle_right.svg');
    this.registerMatSvgIcon('new_template', '../assets/icons/new_template.svg');
    this.registerMatSvgIcon('new_template_from', '../assets/icons/new_template_from.svg');
    this.registerMatSvgIcon('published', '../assets/icons/published.svg');
    this.registerMatSvgIcon('unpublished', '../assets/icons/unpublished.svg');
    this.registerMatSvgIcon('logout', '../assets/icons/logout.svg');
    this.registerMatSvgIcon('smart_display', '../assets/icons/smart_display.svg');
    this.registerMatSvgIcon('ondemand_video', '../assets/icons/ondemand_video.svg');
    this.registerMatSvgIcon('manage_accounts', '../assets/icons/manage_accounts.svg');
    this.registerMatSvgIcon('fact_check', '../assets/icons/fact_check.svg');
    this.registerMatSvgIcon('checklist_rtl', '../assets/icons/checklist_rtl.svg');
    this.registerMatSvgIcon('datev_logo', '../assets/icons/datev_icon.svg');
    this.registerMatSvgIcon('datev_data_service_logo', '../assets/icons/datev_data_service_logo.svg');
    this.registerMatSvgIcon('datev_data_service_logo_color', '../assets/icons/datev_data_service_logo_color.svg');
    this.registerMatSvgIcon('drive_file_move', '../assets/icons/drive_file_move.svg');
    this.registerMatSvgIcon('drive_file_move_outline', '../assets/icons/drive_file_move_outline.svg');
    this.registerMatSvgIcon('add_comment_alt', '../assets/icons/add_comment_alt.svg');
    this.registerMatSvgIcon('newspaper', '../assets/icons/newspaper.svg');
    this.registerMatSvgIcon('article', '../assets/icons/article.svg');
    this.registerMatSvgIcon('engineering', '../assets/icons/engineering.svg');
    this.registerMatSvgIcon('hide_image', '../assets/icons/hide_image.svg');
    this.registerMatSvgIcon('monitor_heart', '../assets/icons/monitor_heart.svg');
    this.registerMatSvgIcon('policy', '../assets/icons/policy.svg');
    this.registerMatSvgIcon('fivef', '../assets/icons/fivef.svg');
    this.registerMatSvgIcon('fivef_desktop', '../assets/icons/fivef_desktop.svg');
    this.registerMatSvgIcon('wifi_off', '../assets/icons/wifi_off.svg');
    this.registerMatSvgIcon('recycling', '../assets/icons/recycling.svg');
    this.registerMatSvgIcon('low_hanging_fruit', '../assets/icons/low-hanging-fruit.svg');
    this.registerMatSvgIcon('drag_indicator', '../assets/icons/drag_indicator.svg');
    this.registerMatSvgIcon('elo_dms', '../assets/icons/elo_dms.svg');
    this.registerMatSvgIcon('elo_dms_color', '../assets/icons/elo_dms_color.svg');
    this.registerMatSvgIcon('update', '../assets/icons/update.svg');
    this.registerMatSvgIcon('rocket_launch', '../assets/icons/rocket_launch.svg');
    this.registerMatSvgIcon('gt', '../assets/icons/gt.svg');
    this.registerMatSvgIcon('gt_color', '../assets/icons/gt_color.svg');
    this.registerMatSvgIcon('arrow_outward', '../assets/icons/arrow_outward.svg');
    this.registerMatSvgIcon('login', '../assets/icons/login.svg');
    this.registerMatSvgIcon('category', '../assets/icons/category.svg');
  }

  registerMatSvgIcon(name, path) {
    this._matIconRegistry.addSvgIcon(
      name,
      this._domSanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }


  get(icon, isSVGIcon = false) {
    const factory = this.resolver.resolveComponentFactory(MatIconRendererComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.icon = icon;
    componentRef.instance.isSVGIcon = isSVGIcon;
    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    return componentRef.location.nativeElement;
  }
}
