import {Action} from '@ngrx/store';
import {CollectorItem} from '../collector-item/collector-item';
import {CollectorCategory} from '../collector-category/collector-category';
import {IBomDataNode, IBomDataNodeParams} from '../bom/bom-data-node.interface';
import {Collector} from './collector';
import {ICollectorParams} from './collector.interface';

export enum CollectorActionTypes {
  Create = '[Collector] Create',
  CreateSuccess = '[Collector] Create Success',
  CreateFail = '[Collector] Create Fail',
  Publish = '[Collector] Publish',
  PublishSuccess = '[Collector] Publish Success',
  PublishFail = '[Collector] Publish Fail',
  NavigateToDashboard = '[Collector] Navigate To Dashboard',
  SaveDraft = '[Collector] Save Draft',
  SaveDraftSuccess = '[Collector] Save Draft Success',
  SaveDraftFail = '[Collector] Save Draft Fail',
  UpdateRecipients = '[Collector] Update Recipients',
  UpdateRecipientsSuccess = '[Collector] Update Recipients Success',
  UpdateRecipientsFail = '[Collector] Update Recipients Fail',
  UpdateWorkers = '[Collector] Update Workers',
  UpdateWorkersSuccess = '[Collector] Update Workers Success',
  UpdateWorkersFail = '[Collector] Update Workers Fail',
  LoadAll = '[Collector] Load All',
  LoadAllSuccess = '[Collector] Load All Success',
  LoadAllFail = '[Collector] Load All Fail',
  LoadOne = '[Collector] Load One',
  LoadOneSuccess = '[Collector] Load One Success',
  LoadOneFail = '[Collector] Load One Fail',
  OrderItems = '[Collector] Order Items',
  OrderItemsSuccess = '[Collector] Order Items Success',
  OrderItemsFail = '[Collector] Order Items Fail',
  RemoveItemResponsible = '[Collector] Remove Item Responsible',
  AddItemResponsible = '[Collector] Add Item Responsible',
  LockItemUpload = '[Collector] LockItemUpload',
  LockCategoryUpload = '[Collector] LockCategoryUpload',
  UnlockItemUpload = '[Collector] UnlockItemUpload',
  UnlockCategoryUpload = '[Collector] UnlockCategoryUpload',
  Duplicate = '[Collector] Duplicate',
  DuplicateSuccess = '[Collector] Duplicate Success',
  DuplicateFail = '[Collector] Duplicate Fail',
  PrefetchAttachments = '[Collector] PrefetchAttachments',
  PrefetchAttachmentsSuccess = '[Collector] PrefetchAttachments Success',
  PrefetchAttachmentsFail = '[Collector] PrefetchAttachments Fail',
  LoadDataNodes = '[Collector] LoadDataNodes',
  LoadDataNodesSuccess = '[Collector] LoadDataNodes Success',
  LoadDataNodesFail = '[Collector] LoadDataNodes Fail',
  UpsertDataNodes = '[Collector] UpsertDataNodes',
  UpsertDataNodesSuccess = '[Collector] UpsertDataNodes Success',
  UpsertDataNodesFail = '[Collector] UpsertDataNodes Fail',
}

export class Create implements Action {
  readonly type = CollectorActionTypes.Create;

  constructor(public params: ICollectorParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CollectorActionTypes.CreateSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class CreateFail implements Action {
  readonly type = CollectorActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = CollectorActionTypes.Publish;

  constructor(public id: string) {
  }
}

export class PublishSuccess implements Action {
  readonly type = CollectorActionTypes.PublishSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class PublishFail implements Action {
  readonly type = CollectorActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class NavigateToDashboard implements Action {
  readonly type = CollectorActionTypes.NavigateToDashboard;

  constructor(public id: string) {
  }
}

export class SaveDraft implements Action {
  readonly type = CollectorActionTypes.SaveDraft;

  constructor(public id: string, public params) {
  }
}

export class SaveDraftSuccess implements Action {
  readonly type = CollectorActionTypes.SaveDraftSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class SaveDraftFail implements Action {
  readonly type = CollectorActionTypes.SaveDraftFail;

  constructor(public payload: any) {
  }
}

export class UpdateRecipients implements Action {
  readonly type = CollectorActionTypes.UpdateRecipients;

  constructor(public id: string, public recipients: string[]) {
  }
}
export class AddItemResponsible implements Action {
  readonly type = CollectorActionTypes.AddItemResponsible;

  constructor(public collectorId: string, public nodeId: string, public participantId: string) {
  }
}
export class RemoveItemResponsible implements Action {
  readonly type = CollectorActionTypes.RemoveItemResponsible;

  constructor(public collectorId: string, public nodeId: string) {
  }
}

export class LockItemUpload implements Action {
  readonly type = CollectorActionTypes.LockItemUpload;

  constructor(public collectorId: string, public categoryId: string, public itemId: string) {
  }
}
export class LockCategoryUpload implements Action {
  readonly type = CollectorActionTypes.LockCategoryUpload;

  constructor(public collectorId: string, public categoryId: string) {
  }
}
export class UnlockItemUpload implements Action {
  readonly type = CollectorActionTypes.UnlockItemUpload;

  constructor(public collectorId: string, public categoryId: string, public itemId: string) {
  }
}
export class UnlockCategoryUpload implements Action {
  readonly type = CollectorActionTypes.UnlockCategoryUpload;

  constructor(public collectorId: string, public categoryId: string) {
  }
}

export class UpdateRecipientsSuccess implements Action {
  readonly type = CollectorActionTypes.UpdateRecipientsSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class UpdateRecipientsFail implements Action {
  readonly type = CollectorActionTypes.UpdateRecipientsFail;

  constructor(public payload: any) {
  }
}

export class UpdateWorkers implements Action {
  readonly type = CollectorActionTypes.UpdateWorkers;

  constructor(public id: string, public workers: string[]) {
  }
}

export class UpdateWorkersSuccess implements Action {
  readonly type = CollectorActionTypes.UpdateWorkersSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class UpdateWorkersFail implements Action {
  readonly type = CollectorActionTypes.UpdateWorkersFail;

  constructor(public payload: any) {
  }
}

export class OrderItems implements Action {
  readonly type = CollectorActionTypes.OrderItems;

  constructor(public id: string, public items: CollectorItem[] | CollectorCategory[]) {
  }
}

export class OrderItemsSuccess implements Action {
  readonly type = CollectorActionTypes.OrderItemsSuccess;

  constructor(public payload: any) {
  }
}

export class OrderItemsFail implements Action {
  readonly type = CollectorActionTypes.OrderItemsFail;

  constructor(public payload: any) {
  }
}

export class LoadAll implements Action {
  readonly type = CollectorActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorActionTypes.LoadAllSuccess;

  constructor(public quickcollectors: Collector[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CollectorActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = CollectorActionTypes.LoadOne;

  constructor(public id: string, public loading = true) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CollectorActionTypes.LoadOneSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CollectorActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Duplicate implements Action {
  readonly type = CollectorActionTypes.Duplicate;

  constructor(public id: string, public includeTemplates: boolean, public includeDueDates: boolean) {
  }
}

export class DuplicateSuccess implements Action {
  readonly type = CollectorActionTypes.DuplicateSuccess;

  constructor(public quickcollector: Collector) {
  }
}

export class DuplicateFail implements Action {
  readonly type = CollectorActionTypes.DuplicateFail;

  constructor(public payload: any) {
  }
}
export class PrefetchAttachments implements Action {
  readonly type = CollectorActionTypes.PrefetchAttachments;

  constructor(public id: string) {
  }
}

export class PrefetchAttachmentsSuccess implements Action {
  readonly type = CollectorActionTypes.PrefetchAttachmentsSuccess;

  constructor() {
  }
}

export class PrefetchAttachmentsFail implements Action {
  readonly type = CollectorActionTypes.PrefetchAttachmentsFail;

  constructor() {
  }
}

export class LoadDataNodes implements Action {
  readonly type = CollectorActionTypes.LoadDataNodes;

  constructor(public processId: string, public parentNodeId: string = null) {
  }
}

export class LoadDataNodesSuccess implements Action {
  readonly type = CollectorActionTypes.LoadDataNodesSuccess;

  constructor(public nodes: IBomDataNode[]) {
  }
}

export class LoadDataNodesFail implements Action {
  readonly type = CollectorActionTypes.LoadDataNodesFail;

  constructor() {
  }
}

export class UpsertDataNodes implements Action {
  readonly type = CollectorActionTypes.UpsertDataNodes;

  constructor(public processId: string, public parentNodeId: string = null) {
  }
}

export class UpsertDataNodesSuccess implements Action {
  readonly type = CollectorActionTypes.UpsertDataNodesSuccess;

  constructor(public nodes: IBomDataNode[]) {
  }
}

export class UpsertDataNodesFail implements Action {
  readonly type = CollectorActionTypes.UpsertDataNodesFail;

  constructor() {
  }
}

export type CollectorActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Publish
  | PublishSuccess
  | PublishFail
  | NavigateToDashboard
  | SaveDraft
  | SaveDraftSuccess
  | SaveDraftFail
  | UpdateRecipients
  | UpdateRecipientsSuccess
  | UpdateRecipientsFail
  | UpdateWorkers
  | UpdateWorkersSuccess
  | UpdateWorkersFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | OrderItems
  | OrderItemsSuccess
  | OrderItemsFail
  | Duplicate
  | DuplicateSuccess
  | DuplicateFail
  | PrefetchAttachments
  | PrefetchAttachmentsSuccess
  | PrefetchAttachmentsFail
  | LoadDataNodes
  | LoadDataNodesSuccess
  | LoadDataNodesFail
  | UpsertDataNodes
  | UpsertDataNodesSuccess
  | UpsertDataNodesFail;
