<div *ngIf="avatarProfile" class="fivef-avatar fivef-avatar-{{avatarSizeString}}">
  <div class="fivef-avatar-icon-placeholder mx-1">
    <ng-template [ngIf]="this.avatarButtonCallback" [ngIfElse]="noActionAvatar">
      <button class="fivef-avatar-icon"
              mat-fab
              (click)="avatarButtonCallback()"
              [style.color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.fgColor"
              [style.background-color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.bgColor"
              [style.border-color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.bgColor"
              [style.width.px]="avatarSizeNumber" [style.height.px]="avatarSizeNumber">
        <img [src]="userAvatar?.avatar"/>
      </button>
    </ng-template>

    <ng-template #noActionAvatar>
      <span class="fivef-avatar-icon fivef-avatar-size-{{avatarSizeString}} mat-fab mat-elevation-z"
            [style.color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.fgColor"
            [style.background-color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.bgColor"
            [style.border-color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.bgColor">
        <img [src]="userAvatar?.avatar"/>
      </span>

      <ng-template
        [ngIf]="(userStatusIsVerified || (avatarProfile?.hasAccount || isVerified ) && !(isMember || avatarProfile?.isMember || userStatusIsMember )) && showTick">
        <mat-icon class="fivef-avatar-verification--icon fivef-avatar-check">check_circle</mat-icon>
      </ng-template>

      <ng-template [ngIf]="(userStatusIsMember  || (isMember || avatarProfile?.isMember))  && showTick">
        <mat-icon class="fivef-avatar-verification--icon fivef-avatar-member-check">check_circle</mat-icon>
      </ng-template>
    </ng-template>
  </div>

  <div class="fivef-avatar-user-info" *ngIf="avatarShowTitle || avatarShowEmail">
    <h4 class="mb-0" *ngIf="avatarShowTitle" mat-line>
      <strong *ngIf="avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name">
        {{ avatarProfile?.firstName && avatarProfile?.lastName ? (avatarProfile?.firstName + ' ' + avatarProfile?.lastName) : avatarProfile?.name }}
      </strong>
      <strong *ngIf="!(avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name) && !avatarShowEmail">
        {{ avatarProfile?.email }}
      </strong>
    </h4>
    <p class="mb-0 email" *ngIf="avatarShowEmail" mat-line>{{ avatarProfile?.email }}</p>
  </div>
</div>
