<mat-card-title class="flex flex-row justify-content-between align-items-center">
  <h3 (click)="toggleExpanded()" [class.allow-hiding-children]="allowHidingChildren">
    <mat-icon *ngIf="isExpanded && allowHidingChildren">expand_more</mat-icon>
    <mat-icon *ngIf="!isExpanded && allowHidingChildren">expand_less</mat-icon>
    <ng-container [ngSwitch]="!!title">
      <ng-container *ngSwitchCase="true">{{ title | translate }}</ng-container>
      <em *ngSwitchCase="false">(leer)</em>
    </ng-container>
  </h3>
</mat-card-title>

<ng-container *ngIf="children.length>0 && isExpanded">
  <ng-container *ngIf="children[0].hasOwnProperty('title')">
    <ng-container *ngFor="let child of children">
      <fivef-csv-import-display [title]="child.title" [children]="child.children" [columns]="columns"
                               [allowHidingChildren]="true"></fivef-csv-import-display>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="children[0].hasOwnProperty('data')">
    <fivef-csv-import-display-table [parsedCsvRows]="children" [columns]="columns"></fivef-csv-import-display-table>
  </ng-container>
</ng-container>
