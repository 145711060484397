<span class="fivef-tree-node--content fivef-folder-tree-node--theme-{{theme}}"
      [cdkContextMenuTriggerFor]="folderContextMenuRef"
      [class.fivef-tree-node--content--active]="_node?.active">
  <mat-icon class="fivef-tree-node--icon">
    {{ _node?.icon }}</mat-icon>

  <span class="fivef-tree-node--title"
        [matTooltip]="_node?.title">
    {{ _node?.title }} {{ _node?.active }}
  </span>
</span>

<ng-template #folderContextMenuRef>
  <div class="fivef-context-menu" cdkMenu>
    <button class="fivef-context-menu-item"
            cdkMenuItem (click)="createNode(_node)">{{ 'DMS.CREATE_FOLDER_ACTION' | translate }}
    </button>
    <button class="fivef-context-menu-item"
            cdkMenuItem (click)="renameNode(_node)">{{ 'GENERAL.RENAME_ACTION' | translate }}
    </button>
    <button class="fivef-context-menu-item"
            [disabled]="_node.hasChildren"
            cdkMenuItem (click)="deleteNode(_node)">{{ 'GENERAL.DELETE_ACTION' | translate }}
    </button>
  </div>
</ng-template>
