import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorConfirmationActionTypes,
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess
} from './collector-confirmation.actions';
import {CollectorConfirmation} from './collector-confirmation';
import {CollectorConfirmationService} from './collector-confirmation.service';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {ProjectRoomActions} from '../project-room';
import {CollectorActions} from '../collector';

@Injectable()
export class CollectorConfirmationEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(CollectorConfirmationActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.collectorId).pipe(
        first(),
        concatMap((res: CollectorConfirmation[]) => {
          return [
            new LoadAllSuccess(res)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(CollectorConfirmationActionTypes.Confirm),
    switchMap((action: Confirm) => {
      return this._svc.confirm(action.collectorId).pipe(
        first(),
        concatMap((confirmation: CollectorConfirmation) => {
          return [
            new ConfirmSuccess(confirmation),
            new CollectorActions.LoadOne(action.collectorId),
            new CollectorActions.LoadDataNodes(action.collectorId),
            new ProjectRoomActions.LoadOne(action.collectorId)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new ConfirmFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: CollectorConfirmationService,
              private _notifyService: FivefNotificationService) {
  }
}




