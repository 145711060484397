<ng-template [ngIf]="!logo" [ngIfElse]="brandedLogo">
  <a [routerLink]="homeLink">
    <img [src]="defaultLogoUrl" alt="5F Logo">
  </a>
</ng-template>

<ng-template #brandedLogo>
  <a [routerLink]="homeLink">
    <img [attr.src]="logo" alt="5F">
  </a>
</ng-template>
