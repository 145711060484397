<div class="fivef-email-address-input--email-container">
  <mat-form-field fivefDisableInputHints
                  class="fivef-email-address-input--email">
    <mat-label *ngIf="label"> {{ label | translate }}</mat-label>
    <input
      matInput
      [placeholder]="(!label ? ('CONTACTS.EMAIL-TABLE-HEADER' | translate ): '')"
      [(ngModel)]="innerValue.emailAddress"
      (ngModelChange)="notifyChange()"
      [errorStateMatcher]="errorStateMatcher"
      [disabled]="disabled"
      [required]="requiredField"
      email>
  </mat-form-field>

  <div style="max-width: 300px;">
    <ng-container *ngFor="let err of errorMapper">
      <mat-error *ngIf="dirty && errors && errors[err.error]">
        {{ err.message }}
      </mat-error>
    </ng-container>
  </div>
</div>

<dvtx-api-select-cva
  *ngIf="showEmailType"
  class="fivef-email-address-input--type"
  [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
  listingPath="name"
  listingType="typed_email-type"
  [(ngModel)]="innerValue.locationOrType"
  (ngModelChange)="notifyChange()"
  [disabled]="disabled">
</dvtx-api-select-cva>
