import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {Colors} from '../../../color/hsl';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';

/**
 * 5F color picker with different toggle button modes to be embeddable in listings,
 * dialogs and setting pages (see variable appearance).
 *
 * Refactors the category and button alike color picker.
 * Unifies 3 different button types.
 */
@Component({
  selector: 'fivef-color-picker',
  templateUrl: './fivef-color-picker.component.html',
  host: {'class': 'fivef-color-picker'},
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatRippleModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefColorPickerComponent {

  /**
   * Toggle button appearance. iconButton implements the material icon button.
   * icon implements a simple button without material button and button implements
   * a squared stroken button.
   * The icon or button is implementing the current color as background color.
   */
  @Input()
  public appearance: 'icon' | 'iconButton' | 'button' = 'button';

  /**
   * Emits on dialog open event.
   * @private
   */
  @Output()
  private onColorPickerOpen: EventEmitter<any> = new EventEmitter<any>(null);

  @Input()
  public loading = false;

  /**
   * Toggle reference.
   * @private
   */
  @ViewChild('buttonToggler')
  private buttonToggler: MatButton;

  public colorsLib = Colors;
  public showColorPicker = false;

  public readonly colors = [
    '#e2445c',
    '#bb3354',
    '#ff158a',
    '#ff5ac4',
    '#784bd1',
    '#a25ddc',
    '#00c875',
    '#037f4c',
    '#579bfc',
    '#0086c0',
    '#66ccff',
    '#c4c4c4',
    '#808080',
    '#333333',
    '#fdab3d',
    '#ffcb00',
    '#cab641',
    '#9cd326',
    '#ff642e',
    '#7f5347'
  ];

  @Input()
  public disabled = false;

  @Input()
  public selected = '#000000';

  @Input()
  public xPosition = 'after';

  @Input()
  public yPosition = 'below';

  @Input()
  public tooltipPosition = 'top right';

  @Input()
  public tooltip = 'GENERAL.CHOOSE_COLOR';

  @Output()
  protected onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  @Input()
  public showCustomColor: boolean = true;

  public openColorPicker() {
    this.buttonToggler._elementRef.nativeElement.click()
  }

  public selectColor($event) {
    this.onSelection.emit($event);
    this.showColorPicker = false;
  }

  public selectCustomColor($event) {
    this.onSelection.emit($event.target.value);
    this.showColorPicker = false;
  }

  showColorsDropdown() {
    this.showColorPicker = true
    this.onColorPickerOpen.emit(true)
  }

  hideColorsDropdown() {
    this.showColorPicker = false
    this.onColorPickerOpen.emit(false)
  }
}
