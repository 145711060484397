<div class="fivef-filter-listing--header">
  {{ title }}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</div>

<div class="fivef-filter-listing--container">
  <ng-container *ngFor="let option of filterdOptions$ | async">
    <button [matTooltip]="(option.title | translate)"
            type="button"
            class="fivef-filter-listing--item"
            [class.active]="isSelected(option)"
            (click)="changeSelection(option)"
            [disabled]="loading">
      <mat-icon *ngIf="option?.icon && (!option?.isSVGIcon && !option?.isSvgIcon)"
                class="mr-1">{{ option?.icon }}
      </mat-icon>
      <mat-icon *ngIf="option?.icon && (option?.isSVGIcon || option?.isSvgIcon)" [svgIcon]="option?.icon"
                class="mr-1"></mat-icon>
      <span class="text-truncate">
          {{ option.title | translate }}
        </span>
    </button>
  </ng-container>
</div>
