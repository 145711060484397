import { Routes, RouterModule } from '@angular/router';
import { OrganizationNavigatorComponent } from './components/navigator/organization-navigator.component';
import { AddressbookFrameComponent } from './components/frames/addressbook-frame/addressbook-frame.component';
import { CustomizationFrameComponent } from './components/frames/customization-frame/customization-frame.component';
import { EmployeesFrameComponent } from './components/frames/employees-frame/employees-frame.component';
import { FivefOrganizationMasterDataComponent } from './components/frames/fivef-organization-master-data/fivef-organization-master-data.component';
import { LicensesFrameComponent } from './components/frames/licenses-frame/licenses-frame.component';
import { AuthGuard } from 'app/+store/_legacy/api/services/auth-guard.service';
import { DavContactImportFrameComponent } from './components/frames/dav-contact-import-frame/dav-contact-import-frame.component';
import { TasksSettingsStandardFrameComponent } from './components/frames/tasks-settings-standard-frame/tasks-settings-standard-frame.component';
import { LabelsSettingsFrameComponent } from './components/frames/labels-settings-frame/labels-settings-frame.component';
import { BookmanCockpitSettingsFrameComponent } from './components/frames/bookman-cockpit-settings-frame/bookman-cockpit-settings-frame.component';
import { SettingsFrameComponent } from './components/frames/settings-frame/settings-frame.component';
import { ClientsFrameComponent } from './components/frames/clients-frame/clients-frame.component';
import { MemberInvitationsFrameComponent } from './components/frames/member-invitations-frame/member-invitations-frame.component';
import { CustomizationEmailFrameComponent } from './components/frames/customization-email-frame/customization-email-frame.component';
import { CustomizationLogoFrameComponent } from './components/frames/customization-logo-frame/customization-logo-frame.component';
import { LabelsGlobalSettingsFrameComponent } from './components/frames/labels-global-settings-frame/labels-global-settings-frame.component';
import { TasksSettingsApprovalFrameComponent } from './components/frames/tasks-settings-approval-frame/tasks-settings-approval-frame.component';

const routes: Routes = [{
  path: ':id/navigation',
  component: OrganizationNavigatorComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/addressbook/contacts',
  component: AddressbookFrameComponent,
  canActivate: [AuthGuard]
}, {
  path: ':id/addressbook/clients',
  component: ClientsFrameComponent,
  canActivate: [AuthGuard]
}, {
  path: ':id/addressbook',
  redirectTo: ':id/addressbook/contacts',
  pathMatch: 'full'
}, {
  path: ':id/contact_imports/dav',
  component: DavContactImportFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/customization',
  redirectTo: ':id/customization/logo',
  pathMatch: 'full'
}, {
  path: ':id/customization/logo',
  component: CustomizationLogoFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/customization/email',
  component: CustomizationEmailFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/customization/:tab',
  component: CustomizationFrameComponent,
  canActivate: [AuthGuard],
},
{
  path: ':orgIdStorage/storage',
  redirectTo: ':orgIdStorage/storage/main',
  pathMatch: 'full'
},
{
  path: ':orgIdStorage/storage',
  loadChildren: () => import('app/modules/dms-management/dms-management.module').then(m => m.DmsManagementModule),
},
{
  path: ':id/employees',
  redirectTo: ':id/employees/index',
  pathMatch: 'full'
}, {
  path: ':id/employees/index',
  component: EmployeesFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/employees/member-invitations',
  component: MemberInvitationsFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/licenses',
  component: LicensesFrameComponent,
  canActivate: [AuthGuard]
},
{
  path: ':id/orgSettings',
  redirectTo: ':id/orgSettings/profile',
  pathMatch: 'full'
}, {
  path: ':id/orgSettings/profile',
  component: FivefOrganizationMasterDataComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/orgSettings/settings',
  component: SettingsFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/tasks-settings',
  redirectTo: ':id/tasks-settings/standard',
  pathMatch: 'full'
}, {
  path: ':id/tasks-settings/standard',
  component: TasksSettingsStandardFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/tasks-settings/approval',
  component: TasksSettingsApprovalFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/labels-settings',
  redirectTo: ':id/labels-settings/organization',
  pathMatch: 'full'
}, {
  path: ':id/labels-settings/organization',
  component: LabelsSettingsFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/labels-settings/global',
  component: LabelsGlobalSettingsFrameComponent,
  canActivate: [AuthGuard],
}, {
  path: ':id/bookman-cockpit-settings',
  component: BookmanCockpitSettingsFrameComponent,
  canActivate: [AuthGuard],
}];

export const organizationRoutes = RouterModule.forChild(routes);
