import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BomDataNode} from '../../../../+store/bom/bom-data-node';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {FivefIconComponent} from '../fivef-icon/fivef-icon.component';

/**
 * 5F context sidebar is intended to show resource details.
 * It contains a title and an optional subtitle.
 *
 * The left border can receive a color given by the current
 * activated element. It defaults to the main border color.
 *
 * === Collections
 *
 * When a collection is given, the bar allows to navigate between items.
 * If the first item is given, then the previous button is disabled.
 * If the last item of the collection is selected then the next button
 * is disabled.
 *
 * The content part is lazy loaded. It contains for each content section
 * a tab title.
 * Each tab is routable by a given query parameter.
 * Switching items in the collection changes the according query parameter
 * to current selected ID.
 */
@Component({
  selector: 'fivef-context-sidebar',
  host: {class: 'fivef-context-sidebar'},
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FivefIconComponent
  ],
  templateUrl: './fivef-context-sidebar.component.html',
  styleUrls: ['./fivef-context-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefContextSidebarComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @HostBinding('style.border-left-color')
  borderColor = 'var(--fivef-color-text-primary)';

  private nodeId$ = new BehaviorSubject<string>(null);
  private collection$ = new BehaviorSubject<{ id: string }[]>([]);

  public hasPrevious = false;
  public hasNext = false;
  private currentIdx = -1;

  /**
   * Main title of sidebar.
   */
  @Input()
  title: string;

  /**
   * Optional fivef-icon compatible string.
   */
  @Input()
  icon: string;

  /**
   * Subtitle of sidebar.
   */
  @Input()
  subtitle: string;

  public _collection: { id: string }[];

  @Output()
  onSelect = new EventEmitter<{ id: string }>();

  @Input()
  set nodeId(nid: string) {
    this.nodeId$.next(nid);
  }

  @Input()
  set collection(nodes: { id: string }[]) {
    this._collection = nodes;
    this.collection$.next(nodes);
  }

  @Input()
  set color(c: string) {
    this.borderColor = !!c ? c : this.borderColor;
  }

  /**
   * Sets the color of the dialog.
   * Convenience color input for action or warning color.
   *
   * @param c
   * @private
   */
  @Input()
  public set infoColor(c: 'primary' | 'warn') {
    switch (c) {
      case 'primary':
        this.borderColor = 'var(--fivef-color-action)';
        break;
      case 'warn':
        this.borderColor = 'var(--fivef-color-warn)';
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    combineLatest(this.nodeId$, this.collection$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([id, nodes]) => {
        if (id && nodes && nodes.length) {
          this.currentIdx = nodes.findIndex((node) => node.id === id);
          this.hasPrevious = this.currentIdx > 0;
          this.hasNext = this.currentIdx >= 0 && this.currentIdx < nodes.length - 1;
          this.cdr.detectChanges();
        }
      })
  }

  ngOnDestroy() {
    this.nodeId$.complete();
    this.collection$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public previousNode() {
    if (this.currentIdx > 0 && this._collection?.length && this.currentIdx <= this._collection?.length) {
      const previousNode = this._collection[--this.currentIdx];
      this.onSelect.emit(previousNode);
    }
  }

  public nextNode() {
    if (this.currentIdx >= 0 && this._collection?.length && this.currentIdx < this._collection?.length - 1) {
      const nextNode = this._collection[++this.currentIdx];
      this.onSelect.emit(nextNode);
    }
  }

}
