import {ChangeDetectionStrategy, Component, HostListener, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {DmsFolderActions} from '../../../../+store';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {FormsModule} from '@angular/forms';

/**
 * Dialog to rename a folder.
 * Prefills the existing folder name and uses the store to update the folder globally.
 */
@Component({
  selector: 'fivef-fivef-rename-folder-dialog',
  standalone: true,
  imports: [CommonModule, FivefDialogComponent, MatButtonModule, TranslateModule, MatFormFieldModule, MatInputModule, FormsModule],
  templateUrl: './fivef-rename-folder-dialog.component.html',
  styleUrls: ['./fivef-rename-folder-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefRenameFolderDialogComponent {
  public folder: DmsFolder;
  public newFolderName: string;
  private oldFolderName: string;

  /**
   * Listen on enter to close the dialog.
   * @param _event
   */
  @HostListener('window:keyup.Enter', ['$event'])
  public onDialogClick(_event: KeyboardEvent): void {
    // Do nothing if name has not changed.
    if (this.newFolderName === this.oldFolderName) {
      this.closeDialog();
      return;
    }

    if (this.newFolderName && this.newFolderName.length) {
      this.renameFolder();
    }
  }

  constructor(private store: Store<AppState>,
              private dialogRef: MatDialogRef<FivefRenameFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { folder: DmsFolder }) {
    this.folder = data.folder;
    this.newFolderName = this.folder.name;
    this.oldFolderName = this.newFolderName;
  }

  /**
   * Renames the folder async by +store.
   */
  public renameFolder() {
    const folder = this.folder;
    const name = this.newFolderName;
    if (!name || !folder) return;

    this.store.dispatch(new DmsFolderActions.Rename(folder.id, name, folder.dmsAccountType));
    this.closeDialog();
  }

  /**
   * Closes the dialog.
   */
  public closeDialog() {
    this.dialogRef.close();
  }
}
