<fivef-dialog [isCallToAction]="true"
              [isUtilityDialog]="true"
              [title]="'DMS.RENAME_FOLDER'">
  <div class="d-flex">
    <mat-form-field>
      <input matInput [(ngModel)]="newFolderName"
             [value]="folder.name"
             #editField
             autofocus />
    </mat-form-field>
  </div>

  <div fivef-dialog-footer>
    <button mat-button
            (click)="closeDialog()">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="!(editField?.value?.length >= 1)"
            (click)="renameFolder()">
      {{ 'DMS.RENAME_FOLDER_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
