<div class="button-row mb-2">
  <a mat-button [routerLink]="backLink" *ngIf="backLink && !browserBackEnabled">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>

  <a mat-button [routerLink]="backLink" *ngIf="enableOnBack && !browserBackEnabled">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>

  <a mat-button (click)="back()" *ngIf="browserBackEnabled">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div>

<mat-card appearance="outlined" class="p-0 five-f-organization-card"> <!--  five-f-card five-f-elevation-z-3 -->
  <mat-card-title [style.background-color]="profile === FiveFCardProfile.User ? '#4b9bb4' : '#233246'">
    <span class="small-title" *ngIf="topHeadline">{{ topHeadline | translate }}</span>
    <ng-template [ngIf]="headlinePortal" [ngIfElse]="simpleHeadline">
      <ng-template [cdkPortalOutlet]="headlinePortal"></ng-template>
    </ng-template>

    <ng-template #simpleHeadline>
      <h3 class="m-0 p-0">
        {{ headline | translate }}
      </h3>
    </ng-template>

    <span class="small-title" *ngIf="subtitle">{{ subtitle | translate }}</span>
  </mat-card-title>

  <mat-card-content>
    <dvtx-organization-card-nav-bar [profile]="profile" [routes]="routes"
                                    [activeLink]="activeLink"></dvtx-organization-card-nav-bar>

    <div *ngIf="header" class="w-100 m-0 p-0 five-f-organization-card-header">
      <ng-template [cdkPortalOutlet]="header"></ng-template>
    </div>

    <div class="w-100 m-0 p-0 five-f-organization-card-content">
      <ng-content></ng-content>
    </div>

  </mat-card-content>
</mat-card>
