import {NgModule} from '@angular/core';
import {AddressBookTableComponent} from './containers/address-book-table/address-book-table.component';
import {ImportContactsComponent} from './components/import-contacts/import-contacts.component';
import {FivefCsvImportModule} from 'app/lib/fivef-ui/file/fivef-csv-import/fivef-csv-import.module';
import {CustomAttributesComponent} from './components/custom-attributes/custom-attributes.component';
import {BatchDialogModule} from '../batch-dialog/batch-dialog.module';
import {InViewportModule} from 'ng-in-viewport';
import {MemberDeletionDialogComponent} from './components/member-deletion-dialog/member-deletion-dialog.component';
import {ContactDeletionDialogComponent} from './components/contact-deletion-dialog/contact-deletion-dialog.component';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';
import {FivefDialogComponent} from '../../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefItemSelectorComponent} from '../../../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.component';
import {FivefContactModule} from '../../../../lib/fivef-ui/contact/fivef-contact/fivef-contact.module';
import {FiltersContainerModule} from '../../../../lib/fivef-legacy/filters/filters-container.module';
import {TooltipModule} from '../../../../lib/fivef-legacy/tooltip/tooltip.module';
import {FivefLoadingIndicatorComponent} from '../../../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';

@NgModule({
  imports: [
    BatchDialogModule,
    FivefCsvImportModule,
    InViewportModule,
    FiltersContainerModule,
    TooltipModule,
    ScrollingModule,
    FivefDialogComponent,
    FivefItemSelectorComponent,
    FivefContactModule,
    FivefLoadingIndicatorComponent
  ],
  declarations: [
    CustomAttributesComponent,
    ImportContactsComponent,
    AddressBookTableComponent,
    MemberDeletionDialogComponent,
    ContactDeletionDialogComponent
  ],
  exports: [
    BatchDialogModule,
    AddressBookTableComponent
  ]
})
export class AddressBookTableModule {
}
