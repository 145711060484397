import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Fibu} from './fibu';
import {FibuBuilder} from './fibu.builder';
import {IFibuParams} from './fibu.interface';
import {ProcessEventBuilder} from '../process-event/process-event.builder';
import {ProcessEvent} from '../process-event/process-event';

@Injectable()
export class FibuService {
  readonly BASE_PATH = 'api/v1/clients/clients';

  constructor(private _http: FivefApiResourceService) {
  }

  loadByClientId(clientId: string): Observable<Fibu> {
    const builder = new FibuBuilder();
    return <Observable<Fibu>>this._http.get<FibuBuilder, Fibu>(builder, `${this.BASE_PATH}/${clientId}/fibu_settings`);
  }

  update(clientId: string, params: IFibuParams): Observable<Fibu> {
    const builder = new FibuBuilder();
    const payload = {
      data: {
        attributes: {
          dms_folder_id: params.dmsFolderId,
          dms_account_type: 'organization',
          dms_path: params.dmsPath,
          bookman_cockpit_enabled: params.bookmanCockpitEnabled,
          five_f_sync_enabled: params.fiveFSyncEnabled,
          document_expiry_time: params.documentExpiryTime,
        }
      }
    };
    return <Observable<Fibu>>this._http.put<FibuBuilder, Fibu>(builder, `${this.BASE_PATH}/${clientId}/fibu_settings`, payload);
  }

  getAuditTrailFor(clientId, page) {
    const builder = new ProcessEventBuilder(clientId);
    return <Observable<ProcessEvent[]>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${clientId}/fibu_settings/audit_trail?page=${page}`);
  }
}
