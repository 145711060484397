import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GLOBAL_LABELS_RESERVED_TITLES, ILabelParams, LabelOrigin, LabelScope} from 'app/+store/label/label.interface';
import {LabelActions, LabelSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Label} from 'app/+store/label/label';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StringValidator} from '../../../validator';
import {distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {of} from 'rxjs/internal/observable/of';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {FivefColorPickerComponent} from '../../util/fivef-color-picker/fivef-color-picker.component';
import {FivefIconPickerComponent} from '../../util/fivef-icon-picker/fivef-icon-picker.component';
import {MatButtonModule} from '@angular/material/button';
import {IFivefCreateLabelDialogData} from './fivef-create-label-dialog.interface';
import {CommonModule} from '@angular/common';

/**
 * Dialog to create labels.
 *
 * Note: Refactored, fixed and simplified from the organizational module.
 */
@Component({
  selector: 'fivef-create-label-dialog',
  templateUrl: './fivef-create-label-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FivefDialogComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    FivefColorPickerComponent,
    FivefIconPickerComponent,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCreateLabelDialogComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  private readonly gloablLabelsReservedTitles = GLOBAL_LABELS_RESERVED_TITLES;
  public readonly LabelScopeRef = LabelScope;

  /**
   * Created or updated label.
   * @private
   */
  private label: Label;

  /**
   * State variable to differentiate edit mode or creation mode.
   * @private
   */
  private editingMode = false;

  // Label scope setup.
  public scope: LabelScope = LabelScope.PERSONAL;
  private origin: LabelOrigin = LabelOrigin.NO_ORIGIN;

  /**
   * Title input form with label existence validation.
   */
  public form: UntypedFormGroup;

  /**
   * Dialog title depending on dialog mode edit or create.
   */
  public dialogTitle: string = '';

  constructor(private store: Store<AppState>,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<FivefCreateLabelDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IFivefCreateLabelDialogData) {
    console.error(data);
    if (data.label) {
      this.label = data.label;
      this.editingMode = true;
    }

    if (data.scope) {
      this.scope = data.scope;
    }
    this.dialogTitle = data?.label ? 'LABELS.EDIT_LABEL' : 'LABELS.CREATE_LABEL';

    this.createForm();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Creates or updates the label depending on dialog mode.
   */
  public submit() {
    const value = this.form.value;
    const labelParams: ILabelParams = {
      color: value.color,
      title: value.title,
      icon: value.icon,
      description: value.description,
      scope: this.scope,
      origin: this.origin
    };

    if (this.editingMode) {
      this.store.dispatch(new LabelActions.Edit(this.data.label.id, labelParams));
    } else {
      this.store.dispatch(new LabelActions.Create(labelParams));
    }
    this.dialogRef.close();
  }

  /**
   * Closes the dialog.
   */
  public closeDialog() {
    this.dialogRef.close()
  }

  /**
   * Changes the label color.
   *
   * @param color
   */
  public editLabelChangeColor(color) {
    this.form.patchValue({
      color: color
    });
  }

  /**
   * Changes the label icon.
   *
   * @param icon
   */
  public editLabelChangeIcon(icon) {
    this.form.patchValue({
      icon: icon
    });
  }

  /**
   * Creates the initial label form.
   * Initializes the label existence validator.
   *
   * @private
   */
  private createForm() {
    this.form = this.fb.group({
      'title': ['', [Validators.required]],
      'color': ['#000000', [Validators.required]],
      'icon': [],
      'description': []
    });

    this.form.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, this.gloablLabelsReservedTitles));
    this.form.get('title').updateValueAndValidity();

    combineLatest(
      this.store.select(LabelSelectors.getAll).pipe(first(),
        filter(labels => !!labels),
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      ), this.label ?
        of(this.label).pipe(
          filter(label => !!label),
          distinctUntilChanged(),
          takeUntil(this.onDestroy)
        ) : of(null)
    )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([labels, label]) => {
        const blacklistedLabels = [...this.gloablLabelsReservedTitles, ...labels.filter(l => l.id !== (label ? label.id : '')).map(l => l.title)];
        this.form.get('title').setValidators((control) => StringValidator.notExistedIn(control.value, blacklistedLabels));
        this.form.get('title').updateValueAndValidity();

        if (label) {
          this.form.patchValue({
            title: label.title,
            color: label.color,
            icon: label.icon,
            description: label.description
          })
        }

      });

  }
}
