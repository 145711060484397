import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

/**
 * Fivef Alert box.
 * Supports text input or content by ng-content.
 */
@Component({
  selector: 'fivef-alert',
  host: {class: 'fivef-alert'},
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './fivef-alert.component.html',
  styleUrls: ['./fivef-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FivefAlertComponent {
  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  dismissible: boolean = false;

  /**
   * Highlights the border, icon and text by the theme's status colors.
   */
  @Input()
  color: 'success' | 'warn' | 'info' | 'default';

  /**
   * Highlights the border by the theme's status colors.
   */
  @Input()
  borderColor: 'success' | 'warn' | 'info' | 'default';

  @Input()
  icon: string = 'info';
}
