import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsSlideshowService} from './cms-slideshow.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    CmsSlideshowService
  ]
})
export class CmsSlideshowStoreModule {
}
