import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IRole} from '../user/role.interface';

export interface IProcessParticipantRole extends IRole {
  symbol: string;
  name: string;
}

export enum ProcessParticipantType {
  Member = 'MEMBER',
  External = 'EXTERNAL'
}

export interface IProcessParticipant extends IResource {
  id: string;
  processId: string;
  name: string;
  email: string;
  gender: string;
  title: string;
  firstName: string;
  lastName: string;
  contactId;
  roles: IProcessParticipantRole[];
  isMember?: boolean;
  isAdmin: boolean;
  isCreator: boolean;
  isOwner: boolean;
  recursive: boolean;
  createdAt?: Date;

  getName(): string;
}

/**
 * Participation creation attributes.
 */
export interface IProcessParticipantParams {
  processId: string;

  email: string;
  firstName?: string;
  lastName?: string;

  recursive?: boolean;
  admin?: boolean;

  canDelegate?: boolean;
  expiresAt?: Date;

  successMessage?: string;
  failMessage?: string;

  cableUpdateRootId?: string;
}

/**
 * Participation udpate attributes.
 * ID is requried in contrast to the creation attributes.
 */
export interface IProcessParticipantUpdateParams extends IProcessParticipantParams {
  id: string;
}

/**
 * Participation udpate attributes.
 * email, firstName, lastName are required in contrast to the creation attributes.
 */
export interface IProcessParticipantInviteParams extends IProcessParticipantParams {
  firstName: string;
  lastName: string;
}
