import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Favorite, FavoriteV2} from './favorite';

export class FavoriteBuilder implements IApiResourceBuilder<Favorite> {
  fromResponse(data): Favorite {
    return new Favorite(
      data.id,
      data.attributes.title,
      data.attributes.resource_id,
      data.attributes.resource_type,
      data.attributes.confirmed_at
    );
  }

  toRequest(favorite: Favorite) {
    return {
      data: {
        attributes: {
          title: favorite.title,
          resource_id: favorite.resourceId,
          resource_type: favorite.resourceType,
        },
        type: 'favorites'
      }
    };
  }
}

/**
 * v3 fast favorite API builder with reduced data set.
 */
export class FavoriteV2Builder implements IApiResourceBuilder<FavoriteV2> {
  fromResponse(data): FavoriteV2 {
    return new FavoriteV2(
      data.id,
      data.attributes.title,
      data.attributes.color,
      data.attributes.type,
      data.attributes.status,
      data.attributes.client_id,
      data.attributes.client_name,
      data.attributes.updated_at,
      data.attributes.created_at,
    );
  }

  toRequest(favorite: FavoriteV2) {
    return null;
  }
}
