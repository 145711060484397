import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

/**
 * 5F dialog content wrapper.
 *
 * Color and title are inputs, content and footer can be delivered as ng-content with selector (footer).
 * Input `disablePadding` allows to reset the standard container paddings.
 * Color is setting the left border color.
 *
 * The components styles are located at the 5F UI global styles to allow component wise overriding at themes.
 */
@Component({
  selector: 'fivef-dialog',
  standalone: true,
  host: {'class': 'fivef-dialog'},
  imports: [CommonModule, TranslateModule],
  templateUrl: './fivef-dialog.component.html',
  styleUrls: ['./fivef-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefDialogComponent {
  @HostBinding('style.border-left-color')
  borderColor = 'var(--fivef-color-text-primary)';

  @HostBinding('class')
  cssClasses = 'fivef-dialog';

  /**
   * Title of dialog.
   */
  @Input()
  public title: string;

  /**
   * Optional subtitle of dialog.
   */
  @Input()
  public subtitle: string;

  /**
   * Disables the footer area.
   */
  @Input()
  public disableFooter = false;

  /**
   * Info icon beside header.
   */
  @Input()
  public info: string = null;

  @Input()
  public set fullscreen(enable) {
    this.cssClasses = `${this.cssClasses} ${enable ? 'fivef-dialog--fullscreen' : ''}`;
  }

  /**
   * Disables the dialog padding.
   *
   * @param disable
   * @private
   */
  @Input()
  public set disablePadding(disable) {
    this.cssClasses = `${this.cssClasses} ${disable ? 'fivef-dialog-no-padding' : ''}`;
  }

  /**
   * Adds the utility size class for small dialogs, e.g. confirmation dialogs.
   *
   * @param enable
   * @private
   */
  @Input()
  public set isUtilityDialog(enable) {
    this.cssClasses = `${this.cssClasses} ${enable ? 'fivef-dialog--utility-dialog' : ''}`;
  }

  /**
   * Sets the color of the dialog.
   * CSS compatible input expected.
   *
   * @param c
   * @private
   */
  @Input()
  public set color(c: string) {
    this.borderColor = c;
  }

  /**
   * Sets the color of the dialog.
   * Convenience color input for action or warning color.
   *
   * @param c
   * @private
   */
  @Input()
  public set infoColor(c: 'primary' | 'warn') {
    switch (c) {
      case 'primary':
        this.borderColor = 'var(--fivef-color-action)';
        break;
      case 'warn':
        this.borderColor = 'var(--fivef-color-warn)';
    }
  }

  /**
   * Sets action color on dialog (call to action).
   * Convenience method for the color.
   *
   * @param isCallToAction
   * @private
   */
  @Input()
  public set isCallToAction(isCallToAction) {
    if (isCallToAction) {
      this.borderColor = 'var(--fivef-color-action)';
    }
  }
}
