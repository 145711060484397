import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fivef-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fivef-box.component.html',
  styleUrls: ['./fivef-box.component.scss'],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class FivefBoxComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
