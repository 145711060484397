import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefFolderTreeComponent} from '../fivef-folder-tree/fivef-folder-tree.component';
import {Observable} from 'rxjs/internal/Observable';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {Subject} from 'rxjs/internal/Subject';
import {FivefFolderTreeDialogRepository} from './fivef-folder-tree-dialog.repository';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FivefCreateFolderDialogComponent} from '../fivef-create-folder-dialog/fivef-create-folder-dialog.component';
import {FivefRenameFolderDialogComponent} from '../fivef-rename-folder-dialog/fivef-rename-folder-dialog.component';
import {FivefDeleteFolderDialogComponent} from '../fivef-delete-folder-dialog/fivef-delete-folder-dialog.component';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {IFivefFolderTreeDialogData} from './fivef-folder-tree-dialog.interface';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

/**
 * Folder tree dialog with private and organizational folders.
 * Must be invoked in organization context otherwise empty.
 */
@Component({
  selector: 'fivef-folder-tree-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FivefFolderTreeComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    FivefDialogComponent,
    FivefCreateFolderDialogComponent,
    FivefRenameFolderDialogComponent,
    FivefDeleteFolderDialogComponent,
    FivefSearchComponent
  ],
  providers: [FivefFolderTreeDialogRepository],
  templateUrl: './fivef-folder-tree-dialog.component.html',
  styleUrls: ['./fivef-folder-tree-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class FivefFolderTreeDialogComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public folders$: Observable<DmsFolder[]>;
  public loading$: Observable<boolean>;

  public selectedNode: DmsFolder;
  public selectedFolder$ = new BehaviorSubject<{id: string}>(null);

  public dialogTitle: string = 'DMS.SELECT_FOLDER';
  public dialogInfo: string;
  public confirmButtonTitle: string = 'DMS.SELECT_FOLDER';

  /**
   * Callback being invoked on close.
   * @private
   */
  private callback: (DmsFolder) => void;

  constructor(private repo: FivefFolderTreeDialogRepository,
              private dialogRef: MatDialogRef<FivefCreateFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IFivefFolderTreeDialogData) {
    this.dialogTitle = data.dialogTitle || this.dialogTitle;
    this.dialogInfo = data.dialogInfo || this.dialogInfo;
    this.confirmButtonTitle = data.confirmButtonTitle || this.confirmButtonTitle;

    if (data.folder) {
      this.selectedFolder$.next(data.folder);
    }

    this.callback = data.callback;
  }

  ngOnInit() {
    this.loading$ = this.repo.loading;
    this.folders$ = this.repo.folders;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.selectedFolder$.complete();
  }

  public selectNode(folder: DmsFolder) {
    this.selectedNode = folder as DmsFolder;
    if (this.callback) {
      this.callback(this.selectedNode);
    }
  }

  public refresh() {
    this.repo.refresh()
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public selectNodeAndClose() {
    if (this.callback) {
      this.callback(this.selectedNode);
    }

    this.dialogRef.close(this.selectedNode);
  }
}
