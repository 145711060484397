import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { OrganizationSelectors } from 'app/+store/organization';
import { Observable, Subject } from 'rxjs';
import { Organization } from 'app/+store/organization/organization';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { InvitationType } from 'app/+store/invitation/invitation.model';
import {LicenceAssignmentSelectors, NaturalPersonSelectors} from 'app/+store';
import { takeUntil } from 'rxjs/operators';
import { ITabNavRoute } from 'app/lib/fivef-legacy/organization-card/models/tab-nav-route.interface';
import { ActivatedRoute } from '@angular/router';
import { Portal, TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'dvtx-organization-member-invitations-frame',
  templateUrl: './member-invitations-frame.component.html',
  styleUrls: ['./member-invitations-frame.component.scss']
})
export class MemberInvitationsFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  public organization$: Observable<Organization>;
  public orgId;
  public InvitationType = InvitationType;
  private employeesCount: number = 0;
  private onDestroy: Subject<void> = new Subject<void>();

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  public availableUserLicences: number = 0;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.organization$ = this._store.select(OrganizationSelectors.getSelected);

    const myLicenceAssignment = this._store.select(LicenceAssignmentSelectors.getAvailableLicenceCount);
    myLicenceAssignment.pipe(takeUntil(this.onDestroy)).subscribe((licenceCount) => {
      this.availableUserLicences = licenceCount;
    });

    this.orgId = this._route.snapshot.params.id;
    this._store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(persons => {
        if (persons) {
          this.routes = [{
            title: 'ORGANIZATION.EMPLOYEES',
            route: `/organization/${this.orgId}/employees/index`,
            count: persons.length
          }, {
            title: 'BANNER.LINK.INVITATIONS_TITLE_MEMBER',
            route: `/organization/${this.orgId}/employees/member-invitations`,
          }];
          this.activeLink = this.routes[1].title;
        }
      });
  }
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }
}
