<div class="fivef-address-input-cva--wrapper">
  <div class="fivef-address-input-cva--header">
    <!--
    <ng-container [formGroup]="form"
      <mat-form-field fivefDisableInputHints>
        <mat-label *ngIf="label">{{ label | translate }}</mat-label>
        <input matInput
               #autocomplete
               formControlName="full"
               [placeholder]="elementName"
               (focus)="onFocus($event)"/>
      </mat-form-field>
    </ng-container>
    -->

    <dvtx-api-select-cva *ngIf="enableLocationOrType"
                         elementName="Ort oder Typ"
                         listingType="typed_email-type"
                         listingPath="name"
                         [(ngModel)]="address.locationOrType"
                         [ngModelOptions]="{standalone: true}"
                         [disabled]="disabled"></dvtx-api-select-cva>
  </div>

  <div class="fivef-address-input-cva--container">
    <!-- Removed condition, because the autocomplete was using Google Maps -->
    <!-- *ngIf="address && (autocomplete.value.length > 0 || (address.street && address.street !== '') || (address.zip && address.zip !== '') || (address.city && address.city !== '') || (address.streetNo && address.streetNo !== ''))">-->
    <div class="ff-row">
      <mat-form-field class="fivef-address-input-cva--street" fivefDisableInputHints>
        <input matInput [disabled]="disabled" [(ngModel)]="address.street"
               placeholder="{{ 'CONTACTS.STREET' | translate }}">
      </mat-form-field>

      <mat-form-field class="fivef-address-input-cva--street-no" fivefDisableInputHints>
        <input matInput [disabled]="disabled" [(ngModel)]="address.streetNo"
               placeholder="{{ 'CONTACTS.STREET_NO' | translate }}">
      </mat-form-field>
    </div>

    <div class="ff-row">
      <mat-form-field class="fivef-address-input-cva--city" fivefDisableInputHints>
        <input matInput [disabled]="disabled" [(ngModel)]="address.city"
               placeholder="{{ 'CONTACTS.CITY' | translate }}">
      </mat-form-field>

      <mat-form-field class="fivef-address-input-cva--zip" fivefDisableInputHints>
        <input matInput [disabled]="disabled" [(ngModel)]="address.zip" placeholder="{{ 'CONTACTS.ZIP' | translate }}">
      </mat-form-field>
    </div>
  </div>
</div>
