<fivef-dialog class="fivef-dialog--utility-dialog"
              [title]="dialogTitle | translate">
  <form [formGroup]="form"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.space)="$event.stopPropagation()">
    <mat-form-field>
      <input matInput [placeholder]="'GENERAL.TITLE' | translate"
             [type]="'text'"
             formControlName="title">
      <mat-error *ngIf="form.get('title').errors?.existed">{{ 'LABELS.LABEL_ALREADY_EXISTS_ERROR' | translate }}
      </mat-error>
      <input type="color" class="d-none" formControlName="color" #colorInput/>
      <div matSuffix style="display: flex">
        <fivef-color-picker (onSelection)="editLabelChangeColor($event)"
                            [appearance]="'iconButton'"
                            [selected]="form.value.color"
                            [xPosition]="'before'"></fivef-color-picker>
        <fivef-icon-picker *ngIf="scope === LabelScopeRef.ORGANIZATIONAL"
                           (onSelection)="editLabelChangeIcon($event)"
                           [selected]="form.value.icon"
                           [xPosition]="'before'"></fivef-icon-picker>
      </div>

    </mat-form-field>

    <mat-form-field>
      <textarea matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                [placeholder]="'GENERAL.DESCRIPTION' | translate"
                formControlName="description"></textarea>
    </mat-form-field>
  </form>


  <div fivef-dialog-footer>
    <button mat-button
            (click)="closeDialog()">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>

    <button mat-raised-button
            color="primary"
            (click)='submit()'
            [disabled]="form.invalid || form.get('title').value.trim().length === 0">
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
