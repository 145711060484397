import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ProcessTaskStat, Task} from './task';
import {TaskStatus} from '../task-status/task-status';
import {TaskAssignee} from 'app/+store/task-assignee/task-assignee';
import {TaskResource} from 'app/+store/task-resource/task-resource';
import {TaskAssignmentStatus} from 'app/+store/task-assignee/task-assignee.interface';
import {IIsTask, ITaskDates, ITaskIndicators, ITaskPerson, ITaskProcess, ITaskStatusMap, TaskPermissions} from './task.interface';
import {TaskResourceType} from 'app/+store/task-resource/task-resource.interface';
import {Process} from '../process/process';
import {DateUtil} from 'app/lib/date/date-util';
import * as dayjs from 'dayjs';
import {IFivefIconColorStatus, PRIORITIES_MAP} from '../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.interface';
import {Comment} from '../comment/comment';
import {CommentType} from '../comment/comment.interface';


export class TaskBuilder implements IApiResourceBuilder<Task> {
  assigneesCommentMap = {};
  statusMap = {};
  resourceMap = {};
  commentsMap = {};
  attachmentsMap = {};

  addIncludedSection(includes) {
    if (includes && includes.length) {
      includes.forEach(include => {
        this.createIncludeModel(include.type, include);
      });
    }
  }

  createIncludeModel(type: string, include) {
    const attrs = include.attributes;
    switch (type) {
      case 'task_statuses':
        const status = new TaskStatus(
          include.id,
          attrs.title,
          attrs.color,
          attrs.order,
          attrs.status_scheme,
          attrs.created_at,
          attrs.updated_at
        );
        if (!this.statusMap[attrs.status_scheme]) {
          this.statusMap[attrs.status_scheme] = [];
        }
        this.statusMap[attrs.status_scheme].push(status);
        break;
      case 'task_assignees':
        const assignee = new TaskAssignee(
          include.id,
          attrs.email,
          attrs.first_name,
          attrs.last_name,
          attrs.task_id,
          attrs.created_at,
          attrs.updated_at,
          attrs.approved,
          attrs.approved_at,
          attrs.comment,
          attrs.accepted_at,
          attrs.creator_email
        );
        if (attrs.approved === false) {
          assignee.status = TaskAssignmentStatus.Denied;
        } else if (attrs.approved === true) {
          assignee.status = TaskAssignmentStatus.Approved;
        } else {
          switch (attrs.status) {
            case 'accepted':
              assignee.status = TaskAssignmentStatus.Accepted;
              break;
            case 'rejected':
              assignee.status = TaskAssignmentStatus.Rejected;
              break;
            case 'pending':
              assignee.status = TaskAssignmentStatus.Pending;
              break;
          }
        }
        if (!this.assigneesCommentMap[attrs.task_id]) {
          this.assigneesCommentMap[attrs.task_id] = [];
        }
        this.assigneesCommentMap[attrs.task_id].push(assignee);
        break;
      case 'task_resources':
        const resource = new TaskResource(
          include.id,
          attrs.title,
          attrs.description,
          attrs.creator_email,
          attrs.resource_id,
          attrs.resource_type,
          attrs.created_at,
          attrs.updated_at,
          attrs.role,
          attrs.revision
        );
        this.resourceMap[include.id] = resource;
        break;
      case 'task_attachments':
        this.attachmentsMap[include.id] = {
          id: include.id,
          documentId: attrs.dms_document_id,
          title: attrs.title,
          fileName: attrs.file_name,
          role: attrs.role,
          revision: attrs.revision,
          type: attrs.content_type,
          size: attrs.file_size,
          owner: attrs.attached_by_email,
          createdAt: attrs.created_at,
          uploadedAt: attrs.updated_at,
          canDeleteAttachment: attrs.can_remove_attachment
        };
        break;
      case 'comment_records':
        const comment = new Comment(
          include.id,
          CommentType.Comment,
          null,
          attrs.authorEmail,
          attrs.content,
          attrs.created_at,
          attrs.updated_at
        );
        this.commentsMap[include.id] = comment;
        break;
    }
  }

  fromResponse(data): Task {
    const processIcon = Process.iconForType(data.attributes.process_type);

    const task = new Task(
      data.id,
      data.attributes.updated_at,
      data.attributes.order,
      data.attributes.title,
      data.attributes.color,
      <ITaskProcess>{
        id: data.attributes.process_id,
        title: data.attributes.process_title,
        color: data.attributes.color || data.attributes.process_color || '#000000',
        isClosed: data.attributes.process_closed,
        dueDate: data.attributes.process_due_date,
        createdAt: data.attributes.process_created_at,
        canCreateTask: data.attributes.can_create || false,
        processType: data.attributes.process_type,
        client: data.attributes.client_id,
        icon: processIcon.icon,
        isSvgIcon: processIcon.isSvgIcon,
        canAccessProcess: data.attributes.can_access_process || false
      },
      data.attributes.task_type, // task type
      <IFivefIconColorStatus>PRIORITIES_MAP[data.attributes.priority], // priority
      <TaskStatus>null, // status
      data.attributes.status_scheme, // status scheme
      <ITaskStatusMap>{}, // statusMap
      <TaskStatus[]>[], // statuses
      <ITaskPerson>{ // creator
        email: data.attributes.creator_email,
        name: data.attributes.creator_name
      },
      <ITaskPerson>{ // responsible
        email: data.attributes.responsible_email,
        name: data.attributes.responsible_name
      },
      <TaskAssignee[]>this.assigneesCommentMap[data.id], // assignees,
      <ITaskDates>{ // dates
        dueDate: data.attributes.due_date,
        completedAt: data.attributes.completed_at,
        createdAt: data.attributes.created_at,
        updatedAt: data.attributes.updated_at,
        approvedAt: data.attributes.approved_at
      },
      <ITaskIndicators>{ // indicators
        documentsCount: data.attributes.documents_count,
        attachmentsCount: data.attributes.attachments_count,
        referencesCount: data.attributes.references_count,
        commentsCount: data.attributes.comments_count,
        description: data.attributes.has_description || data.attributes.description
      },
      this.isOverdue(data.attributes.due_date) && this.overdueDuration(data.attributes.due_date), // is overdue
      <TaskPermissions>{ // permissions
        canRead: data.attributes.can_read || false,
        canEdit: data.attributes.can_edit || false,
        canDelete: data.attributes.can_delete || false,
        canClose: data.attributes.can_close || false,
        canReopen: data.attributes.can_reopen || false,
        canAssignParticipant: data.attributes.can_assign_participant || false,
        canRemoveParticipant: data.attributes.can_remove_participant || false,
        canChangeResponsible: data.attributes.can_change_responsible || false,
        canCreate: data.attributes.can_create || false,
        canUploadAttachment: data.attributes.can_edit || false,
        canDeleteAttachment: data.attributes.can_remove_attachments || false,
        canDownloadAttachment: data.attributes.can_read || false
      },
      <IIsTask>{ //is
        approved: data.attributes.approved,
        overdue: data.attributes.due_date && this.isOverdue(data.attributes.due_date),
        closed: data.attributes.completed_at || false,
        favorite: data.attributes.favorite
      },
      data.attributes.description, // description
      <TaskResource[]>[], // documents
      <TaskResource[]>[], // collectors
      <Comment[]>[], // comments
      <any[]>[] // attachments
    );

    // Setting the id
    task.organizationId = data.attributes.organization_id;
    if (data.relationships) {
      const rel = data.relationships;

      // All documents
      if (rel.documents) {
        rel.documents.data.forEach(document => {
          task.documents.push(this.resourceMap[document.id]);
        });
      }

      // All comments
      if (rel.comments) {
        rel.comments.data.forEach(comment => {
          task.comments.push(this.commentsMap[comment.id])
        });
      }

      // All attachments
      if (rel.attachments) {
        rel.attachments.data.forEach(attachment => {
          task.attachments.push(this.attachmentsMap[attachment.id]);
        });
      }

      // All collectors and Appendices
      if (rel.tree_nodes) {
        rel.tree_nodes.data.forEach(item => {
          if (this.resourceMap[item.id]) {
            switch (this.resourceMap[item.id].resourceType) {
              case TaskResourceType.CollectorNode:
                task.collectors.push(this.resourceMap[item.id]);
                break;
              case TaskResourceType.Appendix:
                task.appendices.push(this.resourceMap[item.id]);
                break;
            }
          }
        });
      }
    }

    // All valid statuses
    task.statuses = this.sortedStatuses(data);

    // Status ID to Status Mapping
    task.statuses.forEach(status => (task.statusMap[status.id] = status));

    // The current status of the task
    if (data.attributes.status && task.statusMap[data.attributes.status]) {
      task.status = <TaskStatus>task.statusMap[data.attributes.status];
    }

    return task;
  }

  private sortedStatuses(data) {
    if (!this.statusMap || !this.statusMap[data.attributes.status_scheme]) {
      return [];
    }
    const statusMap = this.statusMap[data.attributes.status_scheme];
    try {
      return statusMap.sort((l, r) => dayjs(l.createdAt).diff(dayjs(r.createdAt)));
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  toRequest(task: Task) {
    return {
      data: {
        id: task.id,
        type: 'topic_tasks',
        attributes: {
          description: task.description,
          due_date: task.dates && task.dates.dueDate,
          priority: task.priority && task.priority.id,
          process_id: task.process.id,
          status: task.status && task.status.id,
          status_scheme: task.statusScheme,
          title: task.title,
          color: task.color,
          favorite: task.is && task.is.favorite,
          responsible_email: task.responsible && task.responsible.email,
          task_type: task.taskType,
          approved: task.is && task.is.approved,
          order: task.order
        }
      }
    };
  }

  isOverdue(date) {
    const today = new Date().toString();
    return DateUtil.daysDiff(date, today) <= 0;
  }

  overdueDuration(date) {
    const today = new Date().toString();
    return DateUtil.daysDiff(date, today) * -1;
  }
}

export class ProcessTaskCountBuilder implements IApiResourceBuilder<ProcessTaskStat> {
  fromResponse(response, catalog?: any): ProcessTaskStat {
    return new ProcessTaskStat(response.id, response.attributes.all);
  }

  toRequest(model: ProcessTaskStat) {
    return null;
  }
}
