<div class="fivef-file-button--container fivef-file-button--download-button"
     [style.border-color]="color"
     [ngClass]="'fivef-file-button--color-' + colorClass">
  <div (click)="download(artifact)"
       class="fivef-file-button--content-wrapper">
    <mat-icon>insert_drive_file</mat-icon>
    <div class="fivef-file-button--content" [matTooltip]="title">
      <span class="h4 mb-0">{{ artifact?.title || title }}</span>
      <small *ngIf="uploaderName">{{ uploaderName }}</small>
      <small *ngIf="size">{{ size | fivefFileSize }}</small>
    </div>
  </div>

  <div class="fivef-file-button--action-button">
    <button mat-icon-button (click)="download(artifact)">
      <mat-icon>file_download</mat-icon>
    </button>

    <button *ngIf="enableDelete"
            mat-icon-button (click)="delete(artifact)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
