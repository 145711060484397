import {ProcessParticipant} from '../../../../+store/process-participant/process-participant';
import {StringUtils} from '../../../string_utils';

/**
 * Item type to distinguish correct filtering of already selected elements.
 */
export type IFivefSelectorSelectionKey = 'id' | 'clientId' | 'contactId';

/**
 * Row types.
 * Selected items are expected with item type 'selected',
 * selection candidates with type 'candidate' and text rows e.g. groupings or leading
 * headlines are expected to have type 'message'.
 */
export type IFivefSelectorItemType = 'candidate' | 'selected' | 'message' | 'action';


export type FivefSelectableParticipant = ProcessParticipant | {id: string};

export interface IParticipantCandidate {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * Transforms contacts and participations to a selectable entries with
 * e-mail as primary key.
 *
 * @param contact
 */
export function transformContactObject(contact: { id?: string; email?: string; name?: string; firstName?: string; lastName?: string }): IParticipantCandidate {
  const uid = StringUtils.normalizeEmail(contact?.email);
  return {
    id: uid,
    email: uid,
    name: contact?.name ? contact?.name : `${contact?.firstName} ${contact?.lastName}`,
    firstName: contact?.firstName,
    lastName: contact?.lastName
  }
}

/**
 * Row item of the virtual scrolling.
 * The original data set is expected as data property.
 */
export interface IFivefItemSelectorItem {
  id: string;
  icon?: string;
  type: IFivefSelectorItemType
  data?: any;
}

export const SELECTED_TITLE_ROW = {
  id: 'GENERAL.SELECTION',
  type: 'message'
};

export const SELECTED_CONTACT_TITLE_ROW = {
  id: 'CONTACTS.SELECTED_CONTACTS',
  type: 'message'
};

export const CANDIDATES_CONTACT_TITLE_ROW = {
  id: 'CONTACTS.CONTACTS',
  type: 'message'
};

export const CANDIDATES_TITLE_ROW = {
  id: 'GENERAL.ALL',
  type: 'message'
};

export const SELECTED_CLIENT_TITLE_ROW = {
  id: 'CONTACTS.SELECTED_CLIENTS',
  type: 'message'
};

export const CANDIDATES_CLIENT_TITLE_ROW = {
  id: 'CLIENT.CLIENTS',
  type: 'message'
};
