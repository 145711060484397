import {Injectable} from '@angular/core';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {environment} from 'environments/environment';
import {CacEntityBuilder} from './cac-entity.builder';
import {CacEntity} from './cac-entity';

@Injectable()
export class CacEntityService {
  readonly BASE_PATH = 'api/v1/audit/cac/processes';
  basePath: string;

  constructor(private _http: FivefApiResourceService) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  updateSociety(cacId: string, id: string, params) {
    const builder = new CacEntityBuilder(cacId);
    const payload = {
      data: {
        attributes: params
      }
    };
    return this._http.put<CacEntityBuilder, CacEntity>(builder, `${this.BASE_PATH}/${cacId}/societies/${id}?render_society_only=true`, payload);
  }
}
