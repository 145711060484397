import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CmsFaqCategory, CmsFaqSection, CmsFaqItem} from './cms-faq';

export class CmsFaqCategoryBuilder implements IApiResourceBuilder<CmsFaqCategory> {
  fromResponse(data): CmsFaqCategory {
    const category = new CmsFaqCategory(
      data.id,
      data.attributes.title_de,
      data.attributes.title_en,
      data.attributes.order,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);

    if (data.attributes.sections) {
      const builder = new CmsFaqSectionBuilder(category.id);
      category.sections = data.attributes.sections.map(section => {
        return builder.fromResponse(section);
      });
    }
    return category;
  }

  toRequest(cmsFaqCategory: CmsFaqCategory) {
    return {
      data: {
        id: cmsFaqCategory.id,
        type: cmsFaqCategory.type,
        attributes: {
          title_de: cmsFaqCategory.title_de,
          title_en: cmsFaqCategory.title_en,
          order: cmsFaqCategory.order,
          published: !!cmsFaqCategory.published_at,
        }
      }
    };
  }
}

export class CmsFaqSectionBuilder implements IApiResourceBuilder<CmsFaqSection> {
  constructor(private categoryId) {
  }

  fromResponse(data): CmsFaqSection {
    const section = new CmsFaqSection(
      data.id,
      data.attributes.title_de,
      data.attributes.title_en,
      data.attributes.order,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);

    section.categoryId = this.categoryId;

    if (data.attributes.items) {
      const builder = new CmsFaqItemBuilder(this.categoryId, section.id);
      section.items = data.attributes.items.map(item => {
        return builder.fromResponse(item);
      });
    }

    return section;
  }

  toRequest(cmsFaqSection: CmsFaqSection) {
    return {
      data: {
        id: cmsFaqSection.id,
        type: cmsFaqSection.type,
        attributes: {
          title_de: cmsFaqSection.title_de,
          title_en: cmsFaqSection.title_en,
          order: cmsFaqSection.order,
          published: !!cmsFaqSection.published_at,
        }
      }
    };
  }
}

export class CmsFaqItemBuilder implements IApiResourceBuilder<CmsFaqItem> {
  constructor(private categoryId, private sectionId) {
  }

  fromResponse(data): CmsFaqItem {
    const item = new CmsFaqItem(
      data.id,
      data.attributes.title_de,
      data.attributes.title_en,
      data.attributes.document_title_de,
      data.attributes.document_title_en,
      data.attributes.url_de,
      data.attributes.url_en,
      data.attributes.video_url_de,
      data.attributes.video_url_en,
      data.attributes.order,
      data.attributes.published_at,
      data.attributes.licenced_only,
      data.attributes.created_at,
      data.attributes.updated_at);
    item.categoryId = this.categoryId;
    item.sectionId = this.sectionId;
    return item;
  }

  toRequest(cmsFaqItem: CmsFaqItem) {
    return {
      data: {
        id: cmsFaqItem.id,
        type: cmsFaqItem.type,
        attributes: {
          title_de: cmsFaqItem.title_de,
          title_en: cmsFaqItem.title_en,
          document_title_de: cmsFaqItem.document_title_de,
          document_title_en: cmsFaqItem.document_title_en,
          licenced_only: cmsFaqItem.licenced_only,
          order: cmsFaqItem.order,
          published: !!cmsFaqItem.published_at,
        }
      }
    };
  }
}
