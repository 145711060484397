import {distinctUntilKeyChanged, filter, first, switchMap} from 'rxjs/operators';
import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {OrganizationSelectors} from 'app/+store/organization';
import {ProcessManagementActions, ProcessManagementSelectors} from 'app/+store/process-management';
import {EventFilterClass, ProcessEventType} from 'app/+store/process-event/process-event.interface';
import {ProcessEvent} from 'app/+store/process-event/process-event';
import {InViewportMetadata} from 'ng-in-viewport';
import {MatSelectChange} from '@angular/material/select';
import {FibuService} from 'app/+store/fibu/fibu.service';
import {ProcessService} from 'app/+store/process/process.service';

@Component({
  selector: 'fivef-bookman-audit-trail',
  templateUrl: './fivef-bookman-audit-trail.component.html',
  styleUrls: ['./fivef-bookman-audit-trail.component.scss']
})
export class FivefBookmanAuditTrailComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  readonly ProcessEventType = ProcessEventType;

  org$;
  activeLink: string;
  private fetched: { [id: string]: boolean } = {};

  private _auditTrailEvents: ProcessEvent[] = [];
  auditTrailEvents$ = new BehaviorSubject<ProcessEvent[]>([]);
  filteredAuditTrailEvents$: Observable<ProcessEvent[]>;
  isInViewPort: string[] = [];

  query$: BehaviorSubject<string> = new BehaviorSubject(null);
  filters$: BehaviorSubject<EventFilterClass[]> = new BehaviorSubject([]);

  filterTranslationMap = {};
  filterCandidates;

  // Pagination setup
  public loading$ = new BehaviorSubject<boolean>(false);
  public page: number = 1;
  public pages: number = 1;

  @Input()
  processId: string;

  @Input()
  displayedColumns: string[] = ['icon', 'created-at', 'actions'];

  @Input()
  set filter(f: EventFilterClass[]) {
    this.filters$.next(f);
  }

  constructor(private _store: Store<AppState>,
              private _fibuSvc: FibuService,
              private _processSvc: ProcessService,
              private _ngZone: NgZone) {
    this.setAllFilter();
  }

  ngOnInit() {
    this.org$ = this._store.select(OrganizationSelectors.getSelected).pipe(filter(org => !!org), distinctUntilKeyChanged('id'));
    this._loadPage(this.processId, 1);

    this.filteredAuditTrailEvents$ = combineLatest(this.auditTrailEvents$, this.filters$, this.query$)
      .pipe(
        switchMap(([events, _filter, query]) => {
          if (!_filter && !query) return of(events);
          return of(events.filter(event => this._logMatch(event, query, _filter)));
        }));
  }

  private _logMatch(event: ProcessEvent, query: string, filters) {
    if (!query && this._matchFilterClass(event, filters)) return true;

    const _query = query && query.toLowerCase() || '';
    return this._matchFilterClass(event, filters)
      && ((event.log && event.log.toLowerCase().indexOf(_query) > -1)
        || (event.performer && event.performer.toLowerCase().indexOf(_query) > -1)
        || (event.createdAt && event.createdAt.toLowerCase().indexOf(_query) > -1)
        || (event.logLevel && event.logLevel.toLowerCase().indexOf(_query) > -1));
  }

  private _matchFilterClass(event: ProcessEvent, filters) {
    return filters.includes(event.filterClass);
  }


  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.auditTrailEvents$.complete();
    this.filters$.complete();
    this.loading$.complete();
  }

  getProcessTitleById(processId) {
    if (!processId) return of(null);
    if (!this.fetched[processId]) {
      this.fetched[processId] = true;
      const id = processId;
      this._ngZone.runOutsideAngular(_ => {
        setTimeout(_ => {
          this._processSvc.getOne(id)
            .pipe(first())
            .subscribe(process => this._store.dispatch(new ProcessManagementActions.LoadOneSuccess(process)))
        });
      });
    }
    return this._store.select(ProcessManagementSelectors.getById(processId));
  }

  public showItem(event, element) {
    const {[InViewportMetadata]: {entry}, target, visible} = event;
    if (!visible) {
      const index = this.isInViewPort.indexOf(element, 0);
      if (index > -1) {
        this.isInViewPort.splice(index, 1);
      }

      return;
    }
    this.isInViewPort.push(element);
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }
    this.page += 1;
    this._loadPage(this.processId, this.page)
  }

  private _loadPage(processId, page) {
    if (page === 1) {
      this.page = 1;
      this._auditTrailEvents = [];
    }

    this.loading$.next(true);

    this._fibuSvc.getAuditTrailFor(processId, page)
      .pipe(first())
      .subscribe(auditTrailEvents => {
        this._auditTrailEvents = [...this._auditTrailEvents, ...auditTrailEvents];
        this.auditTrailEvents$.next(this._auditTrailEvents);

        // Pagination counter
        if (auditTrailEvents && auditTrailEvents[0] && auditTrailEvents[0].total) {
          this.pages = auditTrailEvents[0].total;
        }
        // Disable auto load loading circle and enable viewport detection
        this.loading$.next(false);
      });
  }

  applyFilter(filterValue: string) {
    try {
      const val = filterValue.trim().toLowerCase();
      this.query$.next(val);
    } catch (e) {
      this.query$.next(null);
      console.error(e);
    }
  }

  setAllFilter() {
    this.filterCandidates = [];
    this.filterCandidates.push(EventFilterClass.Access);
    this.filterCandidates.push(EventFilterClass.Attributes);
    this.filterCandidates.push(EventFilterClass.Document);
    this.filterCandidates.push(EventFilterClass.GeneralEvent);
    this.filterCandidates.push(EventFilterClass.Message);
    this.filterCandidates.push(EventFilterClass.Participation);
    this.filterCandidates.push(EventFilterClass.Task);

    this.filterTranslationMap[EventFilterClass.GeneralEvent] = 'AUDIT_TRAIL.EVENT_FILTER_GENERAL';
    this.filterTranslationMap[EventFilterClass.Task] = 'AUDIT_TRAIL.EVENT_FILTER_TASKS';
    this.filterTranslationMap[EventFilterClass.Document] = 'AUDIT_TRAIL.EVENT_FILTER_DOCUMENTS';
    this.filterTranslationMap[EventFilterClass.Message] = 'AUDIT_TRAIL.EVENT_FILTER_MESSAGES';
    this.filterTranslationMap[EventFilterClass.Access] = 'AUDIT_TRAIL.EVENT_FILTER_ACCESS';
    this.filterTranslationMap[EventFilterClass.Participation] = 'AUDIT_TRAIL.EVENT_FILTER_MEMBERSHIPS';
    this.filterTranslationMap[EventFilterClass.Attributes] = 'AUDIT_TRAIL.EVENT_FILTER_ATTRIBUTES';

    this.filters$.next(this.filterCandidates);
  }

  public selectWorkflow(event: MatSelectChange) {
    this.filters$.next(event.value);
  }
}
