import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RegistrationUser} from '../fivef-session-registration/fivef-session-registration.component';

@Component({
  selector: 'fivef-session-registration-complete',
  host: {'class': 'fivef-session-registration-complete'},
  templateUrl: './fivef-session-registration-complete.component.html',
  styleUrls: ['./fivef-session-registration-complete.component.scss']
})
export class FivefSessionRegistrationCompleteComponent {
  @Input()
  public user: RegistrationUser;

  @Input()
  public dmsAccountPresent = false;

  @Input()
  public accountCompleted = false;

  @Output()
  public onNext = new EventEmitter();

  @Output()
  public onBack = new EventEmitter();

  public toDashboard() {
    this.onNext.emit();
  }
}
