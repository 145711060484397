import {Component, Input, Output, EventEmitter, OnChanges, OnDestroy} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {EmailFooter} from 'app/+store/email-footer/email-footer';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {first, takeUntil} from 'rxjs/operators';
import {EmailFooterActions, EmailFooterSelectors} from 'app/+store/email-footer';
import {FooterType} from 'app/+store/email-footer/email-footer.interface';
import {OrganizationSelectors} from 'app/+store';
import {EmailFooterService} from '../../../../+store/email-footer/email-footer.service';
import {FivefNotificationService} from '../../../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-email-footer',
  styleUrls: ['./email-footer.component.scss'],
  templateUrl: './email-footer.component.html',
})
export class EmailFooterComponent implements OnChanges, OnDestroy {
  private onDestroy = new Subject<void>();

  form: UntypedFormGroup;
  date: Date = new Date();

  firstName: string;
  lastName: string;

  _type: string;


  orgnizationalSignture: EmailFooter;
  FooterType = FooterType;

  profile: { firstName: string, lastName: string, color: string } = {
    firstName: '',
    lastName: '',
    color: '#03a9f4'
  };

  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Output() formReset: EventEmitter<any> = new EventEmitter();

  @Input() creatorName: string;
  @Input() title: string;
  @Input() subtitle: string;

  @Input() set type(value: string) {
    this._type = value;
    if (this._type === this.FooterType.User) {
      this._store.select(OrganizationSelectors.getSelected).pipe(
        takeUntil(this.onDestroy))
        .subscribe((x) => {
          if (x) {
            this._store.dispatch(new EmailFooterActions.LoadAll);
            this._store.select(EmailFooterSelectors.getAll)
              .pipe(takeUntil(this.onDestroy))
              .subscribe((emailFooter: EmailFooter[]) => {
                this.orgnizationalSignture = emailFooter[0];
              });
          }
        });
    }
  }

  get type(): string {
    return this._type;
  }

  @Input() set formValues(values: EmailFooter[]) {
    if (values && values.length > 0) {
      this.form.patchValue(values[0]);
      this.form.updateValueAndValidity();
      this.form.markAsPristine();
    } else {
      this.form.reset();
    }
  }

  @Input() emailFooterInfo;


  constructor(private _store: Store<AppState>,
              private svc: EmailFooterService,
              private _notifyService: FivefNotificationService) {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(),
      title: new UntypedFormControl(),
      content: new UntypedFormControl(),
      published: new UntypedFormControl(),
      publishedAt: new UntypedFormControl(),
    });

  }

  ngOnChanges(changes) {
    if (changes.formValues && changes.formValues.currentValue && changes.formValues.currentValue.length > 0) {
      this.formValues = changes.formValues.currentValue;
      this.form.patchValue(changes.formValues.currentValue[0]);
      this.form.markAsPristine();
    }

    if (this.creatorName) {
      this.firstName = this.creatorName.slice(0, this.creatorName.indexOf(' '));
      this.lastName = this.creatorName.slice(this.creatorName.indexOf(' ') - 1, this.creatorName.length);
      this.profile.firstName = this.creatorName.slice(0, this.creatorName.indexOf(' '));
      this.profile.lastName = this.creatorName.slice(this.creatorName.indexOf(' ') - 1, this.creatorName.length);
    }

    if (this.emailFooterInfo && changes.emailFooterInfo && changes.emailFooterInfo.currentValue) {
      this.emailFooterInfo = changes.emailFooterInfo.currentValue;
      this.form.markAsPristine();
    }
  }

  sendTestMail() {
    this.svc.sendTestMail(this.form.value).pipe(first()).subscribe(_ => {
      this._notifyService.info('FOOTER.TEST_MAIL_SENT')
    });
  }

  resetForm() {
    this.formReset.emit(this.form.value);
  }

  submit() {
    this.formSubmit.emit(this.form.value);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
