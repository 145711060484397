import {IResource} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IRedirectUrl, ProcessProfile} from 'app/+store/process/process.interface';

export class AuditContactVerification implements IResource {
  readonly type = 'audit_contact_verification';
  signature: string;
  auditors: string[];
  profile: ProcessProfile;
  redirectUrl: IRedirectUrl;

  constructor(
    public id,
    public rows: AuditContactVerificationRow[],
    public business_year_end: string,
    public business_year_start: string,
    public contractee: string[],
    public contractor: string[],
    public due_date: string,
    public recipient_name: string,
    public sender_name: string,
    public title: string,
    public token: string,
    public comment: string,
    public global_comment: string,
    public reviewer_first_name: string,
    public reviewer_last_name: string,
    public reviewer_job_title: string,
    public reviewer_organization_name: string,
    public reviewer_city: string,
    public reviewer_country: string,
    public reviewed_at: string,
    public reviewer_confirmed: boolean,
  ) {

  }

  toForm() {
    return undefined;
  }
}

export class AuditContactVerificationRow {
  signature: string;

  constructor(
    public id: number,
    public identifier: string,
    public abbreviation: string,
    public organizationName: string,
    public countryCode: string,
    public firstName: string,
    public lastName: string,
    public role: string,
    public email: string,
    public phone: string,
    public mobile: string,
    public verifiedAt: string,
    public verifiedById: number,
    public due_date: string,
    public notificationLanguage: string,
    public modified_by_tbp_at: string,
    public modified_by_tbp_name: string,
    public comment: string,
    public global_comment: string,
    public reviewer_first_name: string,
    public reviewer_last_name: string,
    public reviewer_job_title: string,
    public reviewer_organization_name: string,
    public reviewer_city: string,
    public reviewer_country: string,
    public reviewed_at: string,
    public reviewer_confirmed: boolean,
  ) {

  }
}
