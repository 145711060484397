<ng-container [ngSwitch]="action">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Button">
    <button mat-icon-button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate">
      <mat-icon svgIcon="sign"></mat-icon>
      <!--<mat-icon>import_export</mat-icon>-->
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.IconButton">
    <button (click)="openWorkflowDialog()"
            [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
            mat-icon-button
            [disabled]="menuItemButtonDisabled">
      <mat-icon svgIcon="sign"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.ListItem">
    <li class="d-flex align-items-center p-2 ff-pointer"
        [fivefDisabled]="!supported"
        [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
        (click)="openWorkflowDialog()">
      <mat-icon svgIcon="sign" class="mr-2"></mat-icon>
      {{ 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE' | translate }}
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.MenuItem">
    <fivef-menu-item [icon]="'sign'"
                     [title]="menuItemButtonText"
                     [matTooltip]="'SIGNATURE.START_WORKFLOW_DIALOG.OPEN_SIGNATURE_DIALOG' | translate"
                     (click)="openWorkflowDialog()"></fivef-menu-item>
  </ng-container>

  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Quickstart">
    <ng-container *ngIf="(featureSet$ | async)?.canCreateSignature">
      <fivef-menu-item [icon]="'sign'"
                       [title]="'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE'"
                       [subtitle]="'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE_QUICKSTART_SUBTTITLE'"
                       (click)="openWorkflowDialog()"></fivef-menu-item>
    </ng-container>
  </ng-container>

  <!-- Custom view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="DocumentSignatureSelectionMenuViewType.Custom">
    <ng-content></ng-content>
  </ng-container>
</ng-container>

<ng-template #dialogTpl>
  <fivef-dialog [isCallToAction]="true" [title]="dialogTitle">
    <ng-template [ngIf]="workflowFlowIsLoading" [ngIfElse]="workflowMenu">
      <fivef-status-list>
        <fivef-status-list-item *ngIf="!createStandaloneSignatureProcess"
                                [successPrefix]="'PROJECT_ROOM.TITLE'"
                                [inProgress]="processLoading"
                                [progressTitle]="'PROJECT_ROOM.LOADING_PROJECT_ROOM_2'"
                                [successTitle]="(process$ | async)?.title"></fivef-status-list-item>

        <fivef-status-list-item *ngIf="!provideUpload"
                                [inProgress]="documentLoading"
                                [progressTitle]="'SIGNATURE.WIZARD.CHECK_DOCUMENT_ACCESS'"
                                [successPrefix]="'MILESTONE.DOCUMENT'"
                                [successTitle]="(document$ | async)?.title"></fivef-status-list-item>

        <fivef-status-list-item *ngIf="!provideUpload"
                                [inProgress]="pdfLoading"
                                [progressTitle]="'SIGNATURE.WIZARD.CHECK_PDF_AVAILABLE'"
                                [successPrefix]="'SIGNATURE.WIZARD.PDF_AVAILABILITY'"
                                [successTitle]="'SIGNATURE.WIZARD.AVAILABLE'"
                                [isErrorState]="!pdfIsAvailable"
                                [errorPrefix]="'SIGNATURE.WIZARD.PDF_AVAILABILITY'"
                                [errorTitle]="'SIGNATURE.WIZARD.NOT_AVAILABLE'">
        </fivef-status-list-item>


        <fivef-status-list-item [inProgress]="accountLoading"
                                [progressTitle]="'SIGNATURE.WIZARD.CHECK_ACCOUNT'"
                                [successPrefix]="'SIGNATURE.WIZARD.ACCOUNT_USERNAME'"
                                [successTitle]="'' + (account$ | async)?.id"
                                [isErrorState]="accountLoadingError"
                                [errorTitle]="'SIGNATURE.WIZARD.ACCOUNT_LOADING_ERROR'">
          <a *ngIf="accountLoadingError"
             (click)="$event.preventDefault(); navigateToSettings()"
             href="#">{{ 'SIGNATURE.WIZARD.SIGNATURE_SETTINGS' | translate }}</a>
        </fivef-status-list-item>

        <fivef-status-list-item [inProgress]="settingsLoading"
                                [progressTitle]="'SIGNATURE.WIZARD.LOAD_SIGNATURE_SETTINGS'"
                                [successPrefix]="'SIGNATURE.WIZARD.SIGNATURE_SETTINGS'"
                                [successTitle]="'SIGNATURE.WIZARD.LOADED'"
                                [isErrorState]="settingsLoadingError"
                                [errorTitle]="'SIGNATURE.WIZARD.ACCOUNT_SETTINGS_LOADING_ERROR'">
          <a (click)="$event.preventDefault(); navigateToSettings()"
             *ngIf="settingsLoadingError"
             href="#">{{ 'SIGNATURE.WIZARD.SIGNATURE_SETTINGS' | translate }}</a>
        </fivef-status-list-item>
      </fivef-status-list>

      <fivef-loading-indicator [message]="'SIGNATURE.LOADING_SIGNATURE_WORKFLOW'"
                               [appearance]="'progressbar'"
                               [spacing]="'s'"></fivef-loading-indicator>
    </ng-template>

    <ng-template #workflowMenu>
      <fivef-menu-item [icon]="'sign'"
                       [title]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_MENU_ITEM'"
                       [subtitle]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_MENU_ITEM_SUBTITLE'"
                       (click)="startSignature(DocumentSignatureRequestProfileType.JustMe)"></fivef-menu-item>

      <fivef-menu-item [icon]="'sign'"
                       [title]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_MENU_ITEM'"
                       [subtitle]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_MENU_ITEM_SUBTITLE'"
                       (click)="startSignature(DocumentSignatureRequestProfileType.Circular)"></fivef-menu-item>

      <fivef-menu-item [icon]="'sign'"
                       [title]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_OTHERS_MENU_ITEM'"
                       [subtitle]="'SIGNATURE.START_WORKFLOW_DIALOG.SIGN_DOCUMENT_RUN_OTHERS_MENU_ITEM_SUBTITLE'"
                       (click)="startSignature(DocumentSignatureRequestProfileType.Delegated)"></fivef-menu-item>
    </ng-template>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
      <button mat-raised-button color="primary"
              [disabled]="creatingWorkflowRequest || accountLoading || settingsLoading || !createStandaloneSignatureProcess && processLoading || !provideUpload && (documentLoading || pdfLoading || !pdfIsAvailable)"
              (click)="createRequestWorkflow()">{{ 'GENERAL.CREATE_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
