import {ContactListDto} from 'app/+store/contact/contact';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {
  ClientSelectors,
  ContactActions,
  ContactSelectors,
  NaturalPersonSelectors,
  OrganizationSelectors,
  PartnerLinkParticipationActions,
  PartnerLinkParticipationSelectors
} from 'app/+store';
import {catchError, filter, first, map, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {ClientService} from 'app/+store/client/client.service';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {Client} from 'app/+store/client/client';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {IPartnerLinkParticipationMap} from 'app/+store/partner-link-participation/partner-link-participation.interface';
import {ProcessParticipation} from 'app/+store/process-participant/process-participant';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ContactTypes} from './address-book-table.model';
import {StringUtils} from "../../../../../../lib/string_utils";

export class AddressBookTableRepository {
  protected onDestroy = new Subject();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private clients$: Observable<Client[]>;
  private selectedClients$ = new BehaviorSubject<Client[]>(null);
  public data = new BehaviorSubject<ContactListDto[]>([]);
  public filter$ = new BehaviorSubject<string>(null);
  private query$ = new BehaviorSubject<string>('');
  private contactType$ = new BehaviorSubject<ContactTypes>(ContactTypes.All);

  private processParticipations = [];
  public processParticipationMap: { [id: string]: ProcessParticipation } = {};
  private _processParticipations$ = new BehaviorSubject<ProcessParticipation[]>([]);
  public processParticipationLoading$ = new BehaviorSubject<boolean>(true);
  public clientMapping$ = new BehaviorSubject({});
  private clientContactMapping = {};
  public clientMappingLoadingState$ = new BehaviorSubject<boolean>(true);
  private showParticipationsOnly$ = new BehaviorSubject<boolean>(false);

  constructor(protected store: Store<AppState>,
              private clientSvc: ClientService,
              private participationtSvc: ProcessParticipantService,
              private _notifyService: FivefNotificationService,
              private _translateSvc: TranslateService) {

    const contacts$ = this.store.select(ContactSelectors.getContactsOfSelectedOrg);
    const processParticipations$ = this._processParticipations$
      .pipe(
        map((participations) => {
          const participationEmailMap = {};
          if (participations && participations.length) {
            participations.forEach(p => participationEmailMap[p.id] = p);
          }
          return participationEmailMap;
        }));
    const partnerLinkParticipations$ = this.store.select(PartnerLinkParticipationSelectors.getPartnerLinkMapOfSelectedOrg);
    const membershipMap$: Observable<{ [email: string]: any }> = this.createMembershipEmailMap();

    const combined$ = combineLatest(partnerLinkParticipations$, contacts$, processParticipations$, membershipMap$, this.clientMapping$, this.showParticipationsOnly$)
      .pipe(map(([partnerLinkMap, _contacts, participationEmailMap, memberMap, clientMapping, showParticipationsOnly]: [IPartnerLinkParticipationMap, ContactListDto[], ProcessParticipation[], { [email: string]: any }, any, boolean]) => {
        let contacts = _contacts;
        const orphanedParticipations = [];
        if (showParticipationsOnly) {
          // LOOP O(p)
          // Improvement: Refactor map outside. Run it once and handover map in this block.
          // const participationEmailMap = {};
          // _participations.forEach(p => participationEmailMap[p.id] = p);
          // LOOP O(p + c)
          // P: Contacts are out of the contact selector, having property normalizedEmail and being sorted.
          contacts = _contacts.filter(c => {
            const found = !!participationEmailMap[c.normalizedEmail];
            if (!found) {
              orphanedParticipations.push(c);
            }
            return found;
          });

          if (this._processParticipations$.value && this._processParticipations$.value.length > 0) {
            const contactsEmailMap = {}
            const contactsEmailsPresent = !!contacts.length;
            contacts.forEach(s => contactsEmailMap[s.email] = true);

            if (contactsEmailsPresent) {
              const notOnContacts = this._processParticipations$.value.filter(a => !contactsEmailMap[a.email]); // include is find inside a filter, better -> map

              if (notOnContacts && notOnContacts.length > 0) {
                notOnContacts.forEach((value) => {
                  const newContact: ContactListDto = {
                    email: value.email,
                    name: value.email,
                    normalizedEmail: value.normalizedEmail,
                    firstName : value.firstName,
                    lastName : value.lastName,
                    key : 'noContact'
                  };
                  contacts.push(newContact);
                })
              }
            }
          }
        }

        // P: Contacts are out of the contact selector, having property normalizedEmail and being sorted.
        contacts.forEach(contact => {
          const email = contact.email;
          if (this.processParticipationMap[email]) {
            contact.processCount = this.processParticipationMap[email].processCount
          }

          if (partnerLinkMap && partnerLinkMap[contact.normalizedEmail]) { // <- here lower case is uncessarily run twice
            contact.partnerLinkId = partnerLinkMap[contact.normalizedEmail];
          }

          if (memberMap[email]) {
            contact.naturalProfileId = memberMap[email].id;
            contact.membershipId = memberMap[email].membershipId;
            contact.isMember = true;
          }

          const clients = clientMapping[contact.id] ? clientMapping[contact.id] : [];
          contact.clients = clients;
        });
        return contacts;
      }));

    combineLatest(combined$, this.query$, this.contactType$, this.selectedClients$)
      .pipe(
        takeUntil(this.onDestroy)
      )
      .subscribe(([contacts, term, contactType, selectedClientIds]) => {
        if (!selectedClientIds) {
          selectedClientIds = [];
        }
        let _contacts = contacts;
        if (!!term || contactType !== ContactTypes.All || selectedClientIds.length) {
          const q = !!term && typeof term === 'string' ? term.toLowerCase() : '';
          _contacts = contacts.filter(c => {
            let found = true;
            if (q) {
              found = c.name.toLowerCase().indexOf(q) >= 0 || c.normalizedEmail.indexOf(q) >= 0;
            }

            if (contactType !== ContactTypes.All) {
              found = found && c.type.toString() === contactType;
            }

            if (selectedClientIds.length) {
              found = found && !!selectedClientIds.find(clientId => this.clientContactMapping[`${clientId}|${c.id}`]);
            }
            return found;
          });
        }
        this.data.next(_contacts);
      });
    this.clients$ = this.store.select(ClientSelectors.getClientsOfSelectedOrg);
  }

  disconnect(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.data.complete();
    this.loadingSubject.complete();
    this.query$.complete();
    this.contactType$.complete();
    this._processParticipations$.complete();
    this.processParticipationLoading$.complete();
  }

  public toggleParticipationsOnly(value: boolean) {
    this.showParticipationsOnly$.next(value);
  }

  public search(term) {
    this.query$.next(term);
  }

  public contactType(ctype) {
    this.contactType$.next(ctype);
  }

  public selectAssociatedClients(clients) {
    this.selectedClients$.next(clients);
  }

  public loadData(): void {
    this.loadingSubject.next(true);
    // this.coursesService.findLessons(courseId, filter, sortDirection,
    //   pageIndex, pageSize).pipe(
    //   catchError(() => of([])),
    //   finalize(() => this.loadingSubject.next(false))
    // )
    //   .subscribe(contacts => this.contactData.next(contacts));

    this.store.select(OrganizationSelectors.getSelected).pipe(
      filter(org => !!org),
      first(),
      takeUntil(this.onDestroy),
    ).subscribe((org) => {
      this.store.dispatch(new ContactActions.LoadAll(org))
      this.store.dispatch(new LoadOfOrganization(org.id));
      this.store.dispatch(new PartnerLinkParticipationActions.LoadAll);

      setTimeout(_ => this.loadClientMappings(), 100);
      setTimeout(_ => this.loadParticipations(), 100);
    });
  }

  private loadParticipations() {
    this.participationtSvc.getProcessParticipations().pipe(first()).subscribe(participations => {
      this.processParticipationMap = {};
      participations.forEach(p => {
        this.processParticipationMap[p.id] = p;
      });
      this.processParticipations = participations;
      this.processParticipationLoading$.next(false);
      this._processParticipations$.next(participations);
    });
  }

  /**
   * Creates a map for all organization members.
   * @private
   */
  private createMembershipEmailMap(): Observable<{ [email: string]: any }> {
    return this.store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(map(members => {
        const membershipMap = {}
        members.forEach(member => {
          membershipMap[member.mainEmailAddress.emailAddress] = member;
        })
        return membershipMap;
      }));
  }

  public createClientContact(contact, client, roleName = '') {
    this.clientSvc.createContactClient(client.id, contact.id, roleName).pipe(first()).subscribe(data => {
      const newClientMapping = Object.assign({}, this.clientMapping$.value);
      const val = { contactId : data.contactId , relationId: data.id, role : roleName, clientId: client.id };
      if (newClientMapping[contact.id]) {
        newClientMapping[contact.id] = [val, ...newClientMapping[contact.id]];
      } else {
        newClientMapping[contact.id] = [val];
      }
      this.clientMapping$.next(newClientMapping);
      this.clientContactMapping[`${client.id}|${contact.id}`] = true;
      this._notifyService.success('CONTACTS.CLIENT_CONTACT_ASSIGN')
    }, err => {
      this._notifyService.error('CONTACTS.CLIENT_CONTACT_ASSIGN_FAIL')
      console.error(err);
    });
  }

  public removeClientContact(contact, client) {
    const clientMapping = Object.assign({}, this.clientMapping$.value);
    const mapping = clientMapping[contact.id];
    let contactRelation = null;

    if (mapping && mapping.length) {
      contactRelation = mapping.find(c => c.clientId === client.id);
    } else {
      return;
    }

    if (!contactRelation) {
      return;
    }

    this.clientSvc.removeContactClient(client.id, contactRelation.relationId).pipe(first()).subscribe(data => {
      const _clientMapping = Object.assign({}, this.clientMapping$.value);
      const _mapping = _clientMapping[contact.id];
      if (_mapping) {
        _clientMapping[contact.id] = _mapping.filter(m => m.clientId !== client.id);
      }
      this.clientMapping$.next(_clientMapping);
      this.clientContactMapping[`${mapping.clientId}|${mapping.contactId}`] = false;

      this._notifyService.success('CONTACTS.CLIENT_CONTACT_REMOVE')
    }, err => {
      console.error(err);
      this._notifyService.error('CONTACTS.CLIENT_CONTACT_REMOVE_FAIL')
    });
  }

  private loadClientMappings() {
    this.clientSvc.getContactClientMappings()
      .pipe(first(), catchError(() => of([])))
      .subscribe(data => {
        const clientMapping = this.generateMapping(data);
        this.clientMapping$.next(clientMapping);
        this.clientMappingLoadingState$.next(false);
      }, err =>  console.error(err));
  }

  public generateMapping (data) {
    const clientMapping = {};
    this.clientContactMapping = {};
    data.forEach(mapping => {
      if (!clientMapping[mapping.contactId]) {
        clientMapping[mapping.contactId] = [];
      }
      clientMapping[mapping.contactId].push(mapping);
      this.clientContactMapping[`${mapping.clientId}|${mapping.contactId}`] = true;
    });
    return clientMapping;
  }
}
