import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CollectorConfirmation} from './collector-confirmation';

export class CollectorConfirmationBuilder implements IApiResourceBuilder<CollectorConfirmation> {
  constructor(public collectorId: string) {}

  fromResponse(data): CollectorConfirmation {
    return new CollectorConfirmation(
      data.id,
      this.collectorId,
      data.attributes.email,
      data.attributes.created_at);
  }

  toRequest(_: CollectorConfirmation) {
    return null;
  }
}
