
import {map, first} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {IThirdPartyConfirmation} from 'app/modules/third-party-confirmation/models/thirdPartyConfirmation';
import {RequestConfirmation} from 'app/modules/third-party-confirmation/models/request-confirmation';
import {ThirdPartyConfirmationContribution} from 'app/modules/third-party-confirmation/models/contribution';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {HttpClient} from '@angular/common/http';
import {ProcessArtifactBuilder} from 'app/+store/process-artifact/process-artifact.builder';
import {environment} from 'environments/environment';
import {FileUtils} from 'app/lib/file_utils/functions';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from '../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {FivefApiResourceService} from '../../lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {
  ThirdPartyAuditActionBuilder,
  ThirdPartyConfirmationV2Builder,
  ThirdPartyDetailsBuilder
} from './audit-third-party.builder';
import {ThirdPartyAuditAction, ThirdPartyConfirmationV2, ThirdPartyDetails} from './audit-third-party';
import {Observable} from 'rxjs/internal/Observable';
import {FileApiResourceService} from '../process-artifact/file-api-resource.service';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {AngularTokenService} from 'angular-token';

@Injectable()
export class AuditThirdPartyConfirmationService {
  readonly BASE_PATH = 'api/v1/audit';
  readonly BASE_PATH_v3 = 'api/v3/audit';
  basePath: string;

  constructor(private _http: HttpClient,
              private _httpClient: HttpClient,
              private env: EnvService,
              private _fhttp: FileApiResourceService,
              private _tokenSvc: AngularTokenService,
              private _translateSvc: TranslateService,
              private _apiSvc: FivefApiResourceService,
              private _notifyService: FivefNotificationService) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  public createThirdPartyConfirmation(auditId: number, type: string, attributes: {}) {
    return this._http.post(`${this.basePath}/auditings/${auditId}/third_party_confirmations`, {
      data: {
        type,
        attributes
      }
    }).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as IThirdPartyConfirmation;
    }));
  }

  public getThirdPartyConfirmation(thirdPartyConfirmationId: number): Observable<IThirdPartyConfirmation> {
    return this._http.get(`${this.basePath}/third_party_confirmations/${thirdPartyConfirmationId}`).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as IThirdPartyConfirmation;
    }));
  }

  public saveThirdPartyConfirmation(confirmation: IThirdPartyConfirmation) {
    return this._http.put(`${this.basePath}/third_party_confirmations/${confirmation.id}`, {
      data: ResourceBuilder.toRequest(confirmation)
    });
  }

  public deleteAttachment(confirmation: IThirdPartyConfirmation) {
    return this._http.delete(`${this.basePath}/third_party_confirmations/${confirmation.id}/email_attachment`);
  }

  public sendThirdPartyConfirmation(confirmation: IThirdPartyConfirmation) {
    return this._http.post(`${this.basePath}/third_party_confirmations/${confirmation.id}/send`, {
      data: ResourceBuilder.toRequest(confirmation)
    });
  }

  public remindThirdPartyConfirmation(confirmationId: string, workflowId: string) {
    return this._http.post(`${this.basePath}/third_party_confirmations_request/${workflowId}/remind`, {});
  }

  public remindThirdPartyOptIn(confirmationId: string, workflowId: string) {
    return this._http.post(`${this.basePath}/third_party_confirmations_request/${workflowId}/remind_opt_in`, {});
  }

  public getThirdPartyConfirmationOverview(thirdPartyConfirmationId: number): Observable<RequestConfirmation[]> {
    return this._http.get(`${this.basePath}/third_party_confirmations/${thirdPartyConfirmationId}/overview`).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as RequestConfirmation[];
    }));
  }

  public getThirdPartyConfirmationContribution(token: string): Observable<ThirdPartyConfirmationContribution> {
    return this._http.get(`${this.basePath}/annual_auditing/contribute/${token}`).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as ThirdPartyConfirmationContribution;
    }));
  }

  public updateThirdPartyConfirmationContribution(token: string, thirdPartyContribution: ThirdPartyConfirmationContribution) {
    return this._http.post(`${this.basePath}/annual_auditing/contribute/${token}`, {
      data: ResourceBuilder.toRequest(thirdPartyContribution)
    }).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as ThirdPartyConfirmationContribution;
    }));
  }

  public getDraftUploads(processId): Observable<ProcessArtifact[]> {
    const basePath = `${environment.token_service_config.apiBase}/api/v1/audit/third_party_confirmations/`;
    return this._http.get(`${basePath}${processId}/uploads`).pipe(map((response: any) => {
      return response.data.map((row) => {
        return new ProcessArtifactBuilder(processId).fromResponse(row);
      });
    }));
  }

  public destroyUpload(processId, uploadId): Observable<ProcessArtifact> {
    const basePath = `${environment.token_service_config.apiBase}/api/v1/audit/third_party_confirmations/`;
    return this._http.delete(`${basePath}${processId}/uploads/${uploadId}`).pipe(map((response: any) => {
      return new ProcessArtifactBuilder(processId).fromResponse(response.data);
    }));
  }

  public downloadAttachment(processId, uploadId) {
    const basePath = `${environment.token_service_config.apiBase}/api/v1/audit/third_party_confirmations/`;
    return this._http.get(`${basePath}${processId}/uploads/${uploadId}`)
      .pipe(first())
      .subscribe(response => {
        FileUtils.saveAsFile(response['data']['attributes']['file_name'], response['data']['attributes']['content'], response['data']['attributes']['content_type'])
      });
  }

  public getProcessUploads(processId): Observable<ProcessArtifact[]> {
    const basePath = `${environment.token_service_config.apiBase}/api/v1/workflow_engine/processes/`;
    return this._http.get(`${basePath}${processId}/artifacts`).pipe(map((response: any) => {
      return response.data.map((row) => {
        return new ProcessArtifactBuilder(processId).fromResponse(row);
      });
    }));
  }

  public getExternalDownloads(accessToken: string): Observable<ProcessArtifact[]> {
    return this._http.get(`${this.basePath}/annual_auditing/contribute/${accessToken}/artifacts`).pipe(
      map((response: any) => {
        return response.data.map((row) => {
          return new ProcessArtifactBuilder(accessToken).fromResponse(row);
        });
      }));
  }

  public verifyEmail(token: string) {
    return this._http.post(`${this.basePath}/annual_auditing/confirm_email/${token}`, {}).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as ThirdPartyConfirmationContribution;
    }));
  }

  public getClientLetter(accessToken: string) {
    return this._http.get(`${this.basePath}/annual_auditing/contribute/${accessToken}/client_letter`).pipe(
      map(download => {
        console.error(download);
        return download['data']['attributes'];
      }))
      .pipe(first())
      .subscribe(document => {
          const mimeType = document.content_type;
          FileUtils.saveAsFile(document.title, document.content, mimeType);
        }, error => {
          console.error(error);
            this._notifyService.error('SIGNATURE.ERRORS.FILE_COULD_NOT_BE_LOADED');
        }
      );
  }

  public getThirdPartyConfirmationPreview(parentId: number, id: number): Observable<ThirdPartyConfirmationContribution> {
    return this._http.get(`${this.basePath}/third_party_confirmations/${parentId}/preview/${id}`).pipe(map((response) => {
      return ResourceBuilder.fromResponse(response) as any as ThirdPartyConfirmationContribution;
    }));
  }

  exportAsZip(id: string) {
    const payload = {
      'data': {
        'attributes': {
          'dms_document_ids': null,
          'export_type': 'third_party',
          'include_index_html': false,
          'include_xml': false,
          'exclude_collector_templates': false,
          'mark_file_exported': false
        }
      }
    };
    return this._httpClient.post(`${environment.token_service_config.apiBase}/api/v1/workflow_engine/processes/${id}/file_exports`, payload)
      .pipe(first())
      .subscribe(res => {
          this._notifyService.info('DMS.EXPORT_CREATION_ONGOING_INFO');
      });
  }

  exportAsExcel(id: string, filename: string) {
    return this._fhttp.getBlob( `${this.env.tusServer()}/${this.BASE_PATH_v3}/third_party/${id}/export_excel`, filename, this._tokenSvc.currentAuthData);
  }

  public getExternalLink(requestId) {
    return this._http.post(`${this.basePath}/third_party_confirmations_request/${requestId}/link`, {});
  }

  public getThirdPartyDetails(processId): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    return <Observable<ThirdPartyDetails>>this._apiSvc.get<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${processId}`);
  }

  public updateThirdPartyDetails(thirdPartyDetails: ThirdPartyDetails): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    const payload = builder.toRequest(thirdPartyDetails)
    return <Observable<ThirdPartyDetails>>this._apiSvc.put<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyDetails.id}`, payload);
  }

  public sendThirdPartyTestEmail(thirdPartyId: string): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    return <Observable<ThirdPartyDetails>>this._apiSvc.get<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyId}/test_email`);
  }

  public resetThirdPartyEmail(thirdPartyId: string): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    return <Observable<ThirdPartyDetails>>this._apiSvc.del<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyId}/email`);
  }

  public updateThirdPartyEmail(thirdPartyDetails: ThirdPartyDetails): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    const payload = builder.toRequest(thirdPartyDetails)
    return <Observable<ThirdPartyDetails>>this._apiSvc.put<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyDetails.id}/email`, payload);
  }

  public updateThirdPartyPreamble(thirdPartyDetails: ThirdPartyDetails): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    const payload = builder.toRequest(thirdPartyDetails)
    return <Observable<ThirdPartyDetails>>this._apiSvc.put<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyDetails.id}/preamble`, payload);
  }

  public resetThirdPreamble(thirdPartyId: string): Observable<ThirdPartyDetails> {
    const builder = new ThirdPartyDetailsBuilder();
    return <Observable<ThirdPartyDetails>>this._apiSvc.del<ThirdPartyDetailsBuilder, ThirdPartyDetails>(builder, `${this.BASE_PATH}/third_party_details/${thirdPartyId}/preamble`);
  }

  public getThirdPartyConfirmationV3(id: string): Observable<ThirdPartyConfirmationV2> {
    const builder = new ThirdPartyConfirmationV2Builder();
    return <Observable<ThirdPartyConfirmationV2>>this._apiSvc.get<ThirdPartyConfirmationV2Builder, ThirdPartyConfirmationV2>(builder, `${this.BASE_PATH_v3}/third_party/${id}/overview`);
  }

  public getAuditActions(processId: string, lineItemId: number): Observable<ThirdPartyAuditAction[]> {
    const builder = new ThirdPartyAuditActionBuilder();
    return <Observable<ThirdPartyAuditAction[]>>this._apiSvc.get<ThirdPartyAuditActionBuilder, ThirdPartyAuditAction>(builder, `${this.BASE_PATH_v3}/third_party/${processId}/line_items/${lineItemId}/audit_actions`);
  }

  public createAuditAction(processId: string, lineItemId: number, auditAction: ThirdPartyAuditAction, auditedAttribute: 'audited_value' | 'confirmed_value'): Observable<ThirdPartyAuditAction> {
    const builder = new ThirdPartyAuditActionBuilder();
    const payload = builder.toRequest(auditAction);
    payload['data']['attributes']['audited_attribute'] = auditedAttribute;
    return <Observable<ThirdPartyAuditAction>>this._apiSvc.post<ThirdPartyAuditActionBuilder, ThirdPartyAuditAction>(builder, `${this.BASE_PATH_v3}/third_party/${processId}/line_items/${lineItemId}/audit_actions`, payload);
  }
}
