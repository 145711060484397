<ng-container style="display: block; height: 100%"
     *ngIf="nodes$ && (nodes$ | async)?.length && (nodes$ | async)?.length > 0">
  <div class="ff-w100 ff-align-center">
    <h3>
      <ng-container [ngSwitch]="(process$ | async)?.processType">

        <ng-container *ngSwitchCase="'project'">
          {{ 'DOCUMENTS.DOCUMENT_STRUCTURE_PROJECT_ROOM' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'quickcollector'">
          {{ 'DOCUMENTS.DOCUMENT_STRUCTURE' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'quickstart'">
          {{ 'DOCUMENTS.DOCUMENT_STRUCTURE_PROJECT_ROOM' | translate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ 'DOCUMENTS.DOCUMENT_STRUCTURE' | translate }}
        </ng-container>

      </ng-container>
    </h3>
  </div>

  <div class="fivef-toolbar fivef-toolbar--start fivef-toolbar--content-spacing fivef-toolbar--content-spacing--end">
    <fivef-search [disabled]="!(nodes$ | async)?.length" (onChange)="applySearch($event)"></fivef-search>
  </div>

  <!-- Tree -->
  <fivef-tree [nodes]="nodes$ | async"
              [search]="searchTerm"
              [expandRoot]="false"
              [showNoResult]="true"
              [noElementsIcon]="'category'"
              [noElementsText]="'FASTDOCS.NO_ITEMS_FOUND'"
              [select]="{ id: _id }"
              (onSelect)="selectNode($event)">
    <ng-container *fivefTreeNodeDef="let node">
      <fivef-process-tree-node [node]="node"></fivef-process-tree-node>
    </ng-container>
  </fivef-tree>
</ng-container>
