<ng-template #callToActionRef>
  <fivef-create-client [appearance]="ClientCreateActionAppearance.CreateRaisedButton"
                       [readonly]="false"
                       (onCreateSuccess)="clientTableComponent?.createSuccess($event)"></fivef-create-client>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [callToActionPortal]="callToActionPortal"
                      (onSearch)="search($event)"
                      [enableSearch]="true"
                      [title]="'ORGANIZATION.ADDRESS_BOOK'"></dvtx-settings-header>

<dvtx-client-overview class="five-f-settings-container five-f-block"
                      #clientTableComponent
                      [searchTerm]="searchTerm"
                      [enableToolbar]="false"></dvtx-client-overview>
