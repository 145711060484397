import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {RoleActions, RoleSelectors} from '../../../+store/role';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Role} from '../../../+store/role/role';
import {NaturalPersonSelectors} from '../../../+store'
import {MembershipSelectors} from '../../../+store'
import {Membership} from '../../../+store/membership/membership';
import {TranslateService} from '@ngx-translate/core';
import {NaturalPerson} from '../../../+store/natural-person/natural-person';

@Component({
  selector: 'dvtx-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.scss']
})
export class EditRolesComponent implements OnInit, OnDestroy {

  @ViewChild('roleInput') roleInput: ElementRef;
  onDestroy = new Subject();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  form: UntypedFormGroup;
  selectedRoles: RoleVM[]
  allRoles: RoleVM[];
  filteredRoles: Observable<RoleVM[]>;
  availableRoles: Observable<RoleVM[]>;
  selectedNatPerson: Observable<NaturalPerson>;
  loading: Observable<boolean>;
  membership: Membership;
  roleName: string;

  constructor(
    private store: Store<any>,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new RoleActions.LoadAll());
    this.selectedNatPerson = this.store.select(NaturalPersonSelectors.getById(this.data.natPersonId));
    this.store.select(MembershipSelectors.getMembershipOfSelectedOrgByNatPersonId(this.data.natPersonId)).subscribe(membership => {
      this.membership = membership;
    });
    this.loading = this.store.select(RoleSelectors.getLoading)

    this.form = this.fb.group({
      roles: '',
    })

    // Currently selected Roles in the membership
    this.store.select(RoleSelectors.getRolesByNatPersonIdForSelectedOrg(this.data.natPersonId)).pipe(
      map(roles => roles.map(r => this.mapRoleToRoleVM(r))),
      takeUntil(this.onDestroy)
    ).subscribe((roles) => {
      this.selectedRoles = roles
    });

    // All roles
    this.store.select(RoleSelectors.getAllRoles).pipe(
      map(roles => roles.map(r => this.mapRoleToRoleVM(r))),
      takeUntil(this.onDestroy)
    ).subscribe(roles => this.allRoles = roles)

    // Available Roles
    this.availableRoles = this.store.select(RoleSelectors.getAllRoles).pipe(
      map(roles => roles
        .map(r => this.mapRoleToRoleVM(r))
        .filter(role => {
          return !this.selectedRoles.find(selRole => selRole.roleName === role.roleName);
        }))
    )

    // Filtered Roles, derived from available roles
    this.filteredRoles = this.rolesControl.valueChanges.pipe(
      startWith(undefined),
      switchMap((role?: string | RoleVM) => {
        if (role) {
          let filterValue: string
          if (typeof role === 'string') {
            filterValue = role.toLowerCase()
          } else {
            filterValue = role.roleName.toLowerCase();
          }
          return this.availableRoles.pipe(
            map(roles => roles.filter(r => r.roleName.toLowerCase().indexOf(filterValue) === 0))
          )
        } else {
          return this.availableRoles
        }
      })
    )
  }

  private get rolesControl(): AbstractControl {
    return this.form.get('roles')!
  }

  add(event: MatChipInputEvent): void {
    // Reset the input value
    event.input.value = '';
    this.rolesControl.setValue(null);
  }

  // Removes the selected Role
  remove(role: RoleVM): void {
    const index = this.selectedRoles.indexOf(role);
    if (index >= 0) {
      this.selectedRoles.splice(index, 1);
    }
    this.store.dispatch(new RoleActions.Remove({membershipId: this.membership.id, roleId: role.id}))
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedRoles.push(event.option.value);
    this.store.dispatch(new RoleActions.Add({membershipId: this.membership.id, roleId: event.option.value.id}))
    this.roleInput.nativeElement.value = '';
    this.rolesControl.setValue(null);
  }

  mapRoleToRoleVM(role: Role): RoleVM {
    // Assumption that we don't change the language while the dialog is opened
    let roleName = role.role_name
    if (roleName.startsWith('ROLE.')) {
      roleName = this.translate.instant(roleName)
    }
    const roleVM = {
      id: role.id,
      roleName,
      removeable: role.current_roletype !== 'owner',
      deleteable: !(role.current_roletype === 'owner' || role.current_roletype === 'administrator' || role.current_roletype === 'sysadmin')
    };
    return roleVM
  }

  createRole() {
    if (!this.roleName) return;
    this.roleName = this.roleName.trim();
    if (!this.roleName.length) return;
    this.store.dispatch(new RoleActions.Create(this.roleName));
    this.roleName = '';
  }

  handleDeleteClick(role: RoleVM) {
    role.toggleDeleteAction = true;
  }

  deleteRole(roleId: string) {
    this.store.dispatch(new RoleActions.Delete(roleId));
  }

  cancelDelete(role: RoleVM) {
    role.toggleDeleteAction = false;
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
  }
}

export interface RoleVM {
  id?: string;
  removeable?: boolean;
  deleteable?: boolean;
  toggleDeleteAction?: boolean;
  roleName: string;
}

export interface DialogData {
  natPersonId: string
}

