import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {FivefControlValueAccessor} from '../../../input/fivef-control-value-accessor.directive';
import {
  SimpleAddress,
  SimpleEmailAddress,
  SimpleEmailAddressRequiredValidator,
  SimplePhoneNumber
} from '../../../../../+store/contact/legacy/models/contact.interface';
import {take, takeUntil} from 'rxjs/operators';
import {ContactPersonBaseForm, newContactBaseForm} from '../../../../../modules/organization/models/person-contact-base-form';
import {ContactPerson} from '../../../../../+store/contact-person/contact-person';
import {ContactActions} from '../../../../../+store/contact';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.state';
import {
  ContactListDto,
  contactListDtoType,
  ContactVisibility,
  visibilityTypes
} from '../../../../../+store/contact/contact';
import {
  getAssignedOrganizationsByPersonContactId,
  getContactRelationship
} from '../../../../../+store/contact-person-organization-association/contact-person-organization-association.selectors';
import {
  AssignContactPerson,
  UnassignContactPerson
} from '../../../../../+store/contact-person-organization-association/contact-person-organization-association.actions';
import {getOrgOrPersonContactByEMail} from '../../../../../+store/contact/contact.selectors';
import {
  ContactOrganizationBaseForm,
  newOrganizationBaseForm
} from '../../../../../modules/organization/models/contact-organization-base-form';
import {FivefCreateContactDialogComponent} from 'app/lib/fivef-ui/contact/fivef-contact/fivef-create-contact-dialog/fivef-create-contact-dialog.component';
import {Membership} from '../../../../../+store/membership/membership';
import {Feature} from '../../../../../+store/feature/feature';
import {MembershipSelectors} from '../../../../../+store/membership';
import {FeatureSelectors} from '../../../../../+store/feature';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs/internal/Subject';
import {Subscription} from 'rxjs/internal/Subscription';
import {Observable} from 'rxjs/internal/Observable';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

/**
 * This component is used for creating new contacts as well as editing existing contacts.
 * The component name is misleading.
 */
@Component({
  selector: 'fivef-contact-person',
  templateUrl: './fivef-contact-person.component.html',
  styleUrls: ['./fivef-contact-person.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefContactPersonComponent),
      multi: true,
    }
  ]
})
export class FivefContactPersonComponent extends FivefControlValueAccessor implements OnInit, OnDestroy, OnChanges {
  private onDestroy = new Subject<void>();

  @Input()
  title: string = 'CONTACTS.NEW_TITLE';

  @Input()
  userProfileEdit: boolean = false;

  @Input()
  displayFurtherDataFields: boolean = false;

  @Input()
  newContactMode: boolean = false;

  contactPerson: ContactPerson;

  assignedOrgSubscription: Subscription;

  assignedOrganizations: ContactListDto[] = [];
  assignedOrganizationsIds: string[] = [];
  selectedOrg: ContactListDto;

  contactListDtoType = contactListDtoType;

  createPersonFrom: UntypedFormGroup;

  createOrgaForm: ContactOrganizationBaseForm = newOrganizationBaseForm();

  orgaTypeId: string = '';

  contactVisibility: ContactVisibility = {visibleFor: '', type: visibilityTypes.visiblePublic};

  administrationRights: Observable<Membership>;
  featureSet$: Observable<Feature>;

  constructor(fb: UntypedFormBuilder,
              protected injector: Injector,
              public store: Store<AppState>,
              public dialog: MatDialog,
              private _cdr: ChangeDetectorRef) {
    super();
    this.createPersonFrom = fb.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      title: [undefined],
      honorific: [undefined],
      activatePartnerLinks: [false],
      mainAddress: [new SimpleAddress()],
      mainPhoneNumber: [new SimplePhoneNumber()],
      mainEmailAddress: [new SimpleEmailAddress(), SimpleEmailAddressRequiredValidator()],
    });

    this.createPersonFrom.valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(((value: ContactPerson) => {
      const ret = newContactBaseForm();
      this.notifyOnChange({
        ...ret,
        contactPerson: {
          firstName: value.firstName,
          lastName: value.lastName,
          title: value.title,
          honorific: value.honorific,
          activatePartnerLinks: value.activatePartnerLinks,
          mainAddress: value.mainAddress,
          mainPhoneNumber: value.mainPhoneNumber,
          mainEmailAddress: value.mainEmailAddress,
          valid: this.createPersonFrom.valid
        },
        isValid: this.createPersonFrom.valid
      });
    }))
  }

  ngOnInit() {
    this.administrationRights = this.store.select(MembershipSelectors.getMyMembership);
    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);

    combineLatest(this.administrationRights, this.featureSet$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([perms, featureSet]) => {
        // Only enable the Partner Links in form Checkbox option if the dialog is 'New Contact Mode'
        // The edit mode has the partner links checkbox directly embedded in the dialog's toolbar.
        if (this.newContactMode && perms && featureSet && perms.hasAdministrationRights && featureSet['hasPartnerLinks']) {
          this.createPersonFrom.patchValue({
            activatePartnerLinks: true
          });
          this._cdr.detectChanges();
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.createPersonFrom.disable();
      } else {
        this.createPersonFrom.enable();
        if (this.userProfileEdit) {
          this.createPersonFrom.get('mainEmailAddress').disable();
        }
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(obj: ContactPersonBaseForm): void {
    this.contactPerson = obj ? obj.contactPerson : null;
    if (obj) {
      // Write value is called multiple times
      if (!this.assignedOrgSubscription) {
        this.assignedOrgSubscription = this.store.select(getAssignedOrganizationsByPersonContactId(this.contactPerson.id)).pipe(
          takeUntil(this.onDestroy)
        ).subscribe(data => {
          this.assignedOrganizations = data || [];
          this.assignedOrganizationsIds = this.assignedOrganizations.map(x => x.id);
        });
      }

      this.createPersonFrom.patchValue({
        firstName: obj.contactPerson.firstName,
        lastName: obj.contactPerson.lastName,
        title: obj.contactPerson.title,
        honorific: obj.contactPerson.honorific,
        mainAddress: obj.contactPerson.mainAddress || new SimpleAddress(),
        mainPhoneNumber: obj.contactPerson.mainPhoneNumber || new SimplePhoneNumber(),
        mainEmailAddress: obj.contactPerson.mainEmailAddress || new SimpleEmailAddress(),
        valid: this.createPersonFrom.valid
      })
    }
  }

  addFurtherPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new ContactActions.AddFurtherPhoneNumber({
      contactId: this.contactPerson.id,
      phoneNumber,
    }))
  }

  removeFurtherPhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherPhoneNumber({
      contactId: this.contactPerson.id,
      phoneNumberId
    }))
  }

  addFurtherEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new ContactActions.AddFurtherEmail({
      contactId: this.contactPerson.id,
      emailAddress,
    }))
  }

  removeFurtherEmail(emailAddressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherEmail({
      contactId: this.contactPerson.id,
      emailAddressId
    }))
  }

  addFurtherAddress(address: SimpleAddress) {
    this.store.dispatch(new ContactActions.AddFurtherAddress({
      contactId: this.contactPerson.id,
      address,
    }))
  }

  removeFurtherAddress(addressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherAddress({
      contactId: this.contactPerson.id,
      addressId
    }))
  }

  public async assignOrganization() {
    this.store.select(getOrgOrPersonContactByEMail(this.selectedOrg.email)).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(contact => {
      if (contact) {
        this.store.dispatch(new AssignContactPerson(contact.id, this.contactPerson.id));
        this.selectedOrg = null;

      }
    })
  }

  public unassignOrganization(id: string) {
    this.store.select(getContactRelationship(id, this.contactPerson.id)).pipe(
      take(1),
    ).subscribe(relation => {
      this.store.dispatch(new UnassignContactPerson(relation));
    });
  }

  private createOrganization() {

    const dialogRef = this.dialog.open(FivefCreateContactDialogComponent,
      {
        data: {
          options: {
            contactType: 'contact_organizations'
          }
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedOrg = {
          email: result.contactToCreate.contactOrganization.mainEmailAddress.emailAddress,
          name: result.contactToCreate.contactOrganization.name
        }
        this.assignOrganization();
        return result;
      }
    });

  }
}
