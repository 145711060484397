<dvtx-user-profile-settings-header [routes]="routes" [activeLink]="activeLink" [title]="'MOBILE_LOGIN.TITLE'"
                                   [subtitle]="'MOBILE_LOGIN.TITLE'">
</dvtx-user-profile-settings-header>
<div class="five-f-settings-container dvtx-mobile-login-container">
  <div class="dvtx-mobile-login-container__content-container">

    <div class="mt-1 mb-2 d-flex">
      <img src="./assets/img/five_desktop.png"/>
    </div>

    <h3 class="mb-2">{{'FIVE_F.FIVEF_DESKTOP.PIN_CODE_TITLE' | translate}}</h3>

    <ng-container *ngIf="authorizationCode$ | async">
      <div class="w-100 d-flex">
        <span>{{ 'CONTACTS.EMAIL' | translate }}:</span>&nbsp; <strong>{{ email }}</strong>
      </div>

      <div class="mt-2 w-100 d-flex flex-column">
        <span class="mb-1">{{ 'FIVEF_AGENT.ENTER_PIN_CODE' | translate }}:</span>
        <div class="dvtx-mobile-login-container--pin-code">
          <span>{{ (authorizationCode$ | async)?.code }}</span>
        </div>
      </div>

      <div class="mt-2 mb-2 w-100 d-flex flex-column">
        <span class="mb-1">{{ 'FIVEF_AGENT.SCAN_QR_CODE' | translate }}:</span>
        <div class="dvtx-mobile-login-container--qr-code">
          <qrcode
            [qrdata]="getQrCodeValue()"
            [width]="200"
            errorCorrectionLevel="H">
          </qrcode>
        </div>
      </div>

      <div class="w-100 mb-1 d-flex">
        <small>
          {{ 'FIVEF_AGENT.AUTH_CODE_VALID_UNTIL' | translate }} {{(authorizationCode$ | async)?.expiresAt | date: 'medium'}}
          )</small>
      </div>
    </ng-container>

    <div class="w-100 mb-2 d-flex">
      <div class="d-flex align-self-end">
        <button mat-button color="primary" (click)="refreshCode()">
          <mat-icon matPrefix>refresh</mat-icon>
          {{ 'FIVEF_AGENT.REFRESH_CODE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
