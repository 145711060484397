import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CmsSlideBuilder} from 'app/+store/cms-slideshow/cms-slideshow.builder';
import {FivefLoginSettings} from './fivef-session';

export class FivefLoginSettingsBuilder implements IApiResourceBuilder<FivefLoginSettings> {
  fromResponse(data): FivefLoginSettings {
    const settings = new FivefLoginSettings(
      data.id,
      data.attributes.impressum_url,
      data.attributes.color_theme,
      data.attributes.color_theme_config,
      data.attributes.login_theme,
      data.attributes.login_bg_color,
      data.attributes.login_fg_color,
      data.attributes.login_header,
      data.attributes.login_footer,
      data.attributes.login_header_en,
      data.attributes.login_footer_en,
      data.attributes.login_background_image_url,
      data.attributes.login_secondary_logo_url
    );

    const loginSlideshowResponse = data.attributes?.login_slideshow?.data;
    if (loginSlideshowResponse?.length) {
      const slideBuilder = new CmsSlideBuilder();
      settings.loginSlideshow = loginSlideshowResponse.map(slide => {
        return slideBuilder.fromResponse(slide)
      });
    }


    return settings;
  }

  toRequest(_: FivefLoginSettings) {
    return null
  }
}
