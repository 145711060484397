import {Component, Input, OnInit} from '@angular/core';
import {Browser} from 'browser-support';

@Component({
  selector: 'fivef-unsupported-browser',
  templateUrl: './fivef-unsupported-browser.component.html',
  styleUrls: ['./fivef-unsupported-browser.component.scss']
})
export class FivefUnsupportedBrowserComponent implements OnInit {
  isUnsupportedBrowser: boolean;
  isUnsupportedVersion: boolean;

  @Input() info = 'SYSTEM.BROWSER.UNSUPPORTED_BROWSER_INFO';
  @Input() alertMsg = null;

  /**
   * Test and styleguide setter.
   */
  @Input()
  unsupportedBrowserTest = false;

  ngOnInit() {
    const validator = new Browser.SupportValidator();
    this.isUnsupportedBrowser = this.unsupportedBrowserTest || validator.isUnsupportedBrowser;
  }
}
