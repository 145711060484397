import { IResourceCatalog, IResource } from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import { environment } from 'environments/environment';


export namespace RelationBuilder {
  export function build(relation, catalog: IResourceCatalog): IResource | IResource[] {
    const data = relation ? relation.data : null;
    if (data) {
      if (data instanceof Array) {
        return data.map((resource) => catalog[resource.type].find(item => item.id == resource.id)); // tslint:disable-line
      } else {
        if (!catalog[data.type]) return null;
        return catalog[data.type].find(item => item.id == data.id); // tslint:disable-line
      }
    } else {
      if (!environment.production) {
        console.log('RelationBuilder#build: response.data is undefined.');
      }
      return null;
    }
  }
}
