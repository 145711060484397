import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefTreeComponent} from '../../tree/fivef-tree/fivef-tree.component';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {Subject} from 'rxjs/internal/Subject';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FivefFolderTreeRepository} from './fivef-folder-tree.repository';
import {Observable} from 'rxjs/internal/Observable';
import {FivefTreeNodeDevDirective} from '../../tree/fivef-tree/fivef-tree-node-dev.directive';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {FivefFolderTreeNodeComponent} from '../fivef-folder-tree-node/fivef-folder-tree-node.component';
import {IFivefFlatNode} from '../../tree/fivef-tree/fivef-tree.interface';
import {MatDialog} from '@angular/material/dialog';
import {FivefCreateFolderDialogComponent} from '../fivef-create-folder-dialog/fivef-create-folder-dialog.component';
import {FivefRenameFolderDialogComponent} from '../fivef-rename-folder-dialog/fivef-rename-folder-dialog.component';
import {FivefDeleteFolderDialogComponent} from '../fivef-delete-folder-dialog/fivef-delete-folder-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IFivefFolderNode} from './fivef-folder-tree.interface';
import {DmsFolderActions} from '../../../../+store';

/**
 * Folder tree implementation using the organization DMS only.
 *
 * Requires a selected organization and allows to filter the folders
 * by a search bar.
 */
@Component({
  selector: 'fivef-folder-tree',
  host: {'class': 'fivef-folder-tree'},
  standalone: true,
  imports: [
    CommonModule,
    FivefTreeComponent,
    FivefFolderTreeNodeComponent,
    FivefCreateFolderDialogComponent,
    FivefRenameFolderDialogComponent,
    FivefDeleteFolderDialogComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    FivefTreeNodeDevDirective,
    FivefSearchComponent,
    MatTooltipModule
  ],
  templateUrl: './fivef-folder-tree.component.html',
  styleUrls: ['./fivef-folder-tree.component.scss'],
  providers: [FivefFolderTreeRepository],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefFolderTreeComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  /**
   * Internal nodes as tree with children relation.
   */
  public folderNodes$: Observable<IFivefFolderNode[]>;

  /**
   * Current selected node by tree click.
   * For internal use. No public access.
   */
  public _selectedNode: DmsFolder = null;

  /**
   * Query string to serch and filter the tree.
   */
  @Input()
  public searchTerm: string = null;

  /**
   * Enables the action buttons right next beside the toolbar.
   * Off by default.
   */
  @Input()
  public enableFolderActionToolbar = false;

  /**
   * Indicator if the component is in loading state.
   * Disables the search if true.
   */
  @Input()
  public loading: boolean = false;

  /**
   * Initially expands the root node of the tree.
   */
  @Input()
  public expandRoot = true;

  /**
   * Emits a node item on click inside the tree.
   * Only emits if the item itself and not the expander is clicked.
   * @private
   */
  @Output()
  private onSelect = new EventEmitter<DmsFolder>();

  /**
   * Event called on refresh folder tree request.
   * @private
   */
  @Output()
  private onRefresh = new EventEmitter<any>();

  /**
   * Current selected folder by outside world.
   */
  public selectedFolder: { id: string };

  /**
   * Selects a node inside the tree.
   * @param dmsFolder
   * @private
   */
  @Input()
  public set select(dmsFolder: { id: string }) {
    this.selectedFolder = dmsFolder;
  }

  /**
   * Sets the DMS folders for the tree.
   * The internal representation is tree structure IFivefFolderNode.
   *
   * @param dmsFolders
   * @private
   */
  @Input()
  public set folders(dmsFolders: DmsFolder[]) {
    this.repo.folders = dmsFolders;
  }

  constructor(private repo: FivefFolderTreeRepository,
              private dialog: MatDialog,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.folderNodes$ = this.repo.folderNodes;
    this.repo.init();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Sets the searchTerm to be used as input of the search.
   *
   * @param $event
   */
  public applySearch($event: string) {
    this.searchTerm = $event;
    this.cdr.detectChanges();
  }

  /**
   * Emits the selected node given by the tree.
   * @param node
   */
  public selectHandler(node) {
    const folder: DmsFolder = this.findFolderByNode(node);
    this._selectedNode = folder;
    this.cdr.detectChanges();
    this.onSelect.emit(folder);
  }

  /**
   * Creates a new folder node by creation dialog.
   * @param node
   */
  public createNode(node: IFivefFlatNode = null) {
    const folder: DmsFolder = node ? this.findFolderByNode(node) : this._selectedNode;
    if (folder) {
      this.dialog.open(FivefCreateFolderDialogComponent, {data: {folder: folder}});
    }
  }

  /**
   * Renames a folder by rename dialog.
   *
   * @param node
   */
  public renameNode(node: IFivefFlatNode = null) {
    const folder: DmsFolder = node ? this.findFolderByNode(node) : this._selectedNode;
    if (folder) {
      this.dialog.open(FivefRenameFolderDialogComponent, {data: {folder: folder}});
    }
  }


  /**
   * Deletes a folder by deletion dialog.
   *
   * @param node
   */
  public deleteNode(node: IFivefFlatNode = null) {
    const folder: DmsFolder = node ? this.findFolderByNode(node) : this._selectedNode;
    if (folder) {
      this.dialog.open(FivefDeleteFolderDialogComponent, {data: {folder: folder}});
    }
  }

  /**
   * Triggers a folder tree refresh.
   */
  public refresh() {
    this.onRefresh.emit();
  }

  /**
   * Finds the according DmsFolder entity of the given tree node.
   * @param node
   * @private
   */
  private findFolderByNode(node: {id: string}): DmsFolder {
    return node ? this.repo.find(node.id) : null;
  }
}
