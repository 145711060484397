<div class="dvtx-instance-messenger column w-100">
  <div *ngIf="dasError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{ 'GENERAL.BIG_IMAGE' | translate }} </p>
  </div>
  <div *ngIf="fileError" class="error-container red flex">
    <mat-icon>warning</mat-icon>
    <p> {{ 'GENERAL.FILE_ERROR' | translate }} </p>
  </div>
  <div class="editor-text-reply row m-0">

    <textarea #editor
              (froalaInit)="initializeLink($event)"
              [froalaEditor]="options"
              [(froalaModel)]="message"
              [placeholder]="placeholder"
              (click)="getCaretPos(editor)"
              (keyup)="getCaretPos(editor)"
              class="col-12"></textarea>
  </div>
  <div class="action-buttons row m-0 message-panel-footer d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center mr-2">
      <fivef-emoji-menu (onSelection)="insertEmoji($event)"></fivef-emoji-menu>
    </div>

    <div class="d-flex">
      <div *ngIf="!editMode" class="d-flex align-items-center">
        <button [disabled]="message?.length === 0" mat-icon-button color="primary"
                (click)="onSend.emit($event)">
          <mat-icon>send</mat-icon>
        </button>
      </div>
      <div class="d-flex align-items-center ml-1" *ngIf="enableCancelButton">
        <button mat-icon-button
                (click)="onCancel.emit($event)" matTooltip="{{'GENERAL.CANCEL_ACTION' | translate}}">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div *ngIf="editMode" class="d-flex align-items-center">
        <span class="input-group-btn ml-2">
          <button [disabled]="message?.length === 0 || disableEditButton"
                  [matTooltip]="'GENERAL.SAVE_ACTION' | translate"
                  mat-icon-button color="primary"
                  (click)="onSave.emit($event)">
            <mat-icon>check</mat-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
