import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorItemActionTypes,
  Copy,
  CopyFail,
  CopySuccess,
  Create,
  CreateFail,
  CreateSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess,
  Status,
  StatusFail,
  StatusSuccess
} from './collector-item.actions';
import {CollectorItem} from './collector-item';
import {CollectorItemService} from './collector-item.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import * as itemLookupActions from '../collector-item-lookup/collector-item-lookup.actions';
import {BomDataNodeService} from '../bom/bom-data-node.service';

@Injectable()
export class CollectorItemEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.Create),
    concatMap((action: Create) => {
      return this._dataNodeSvc.create(action.collectorId, action.params, action.categoryId).pipe(
        first(),
        concatMap((quickcollector: CollectorItem) => {
          return [new CreateSuccess(quickcollector)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  saveOne$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.Save),
    switchMap((action: Save) => {
      return this._dataNodeSvc.update(action.collectorId, action.id, action.params).pipe(
        first(),
        concatMap((item: CollectorItem) => {
          this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED')
          return [new SaveSuccess(item), new itemLookupActions.ResetOne(item.id)];
        }),
        catchError(err => {
          this._notifyService.error('GENERAL.ERRORS_OCCURRED')
          console.error(err);
          this._notifyService.error('PROJECT_ROOM_SETTINGS.CHANGES_FAILED')
          return of(new SaveFail(err));
        }));
    })
  ));

  status$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.Status),
    switchMap((action: Status) => {
      return this._dataNodeSvc.updateStatus(action.collectorId, action.id, action.status).pipe(
        first(),
        concatMap((node: CollectorItem) => {
          console.error('Status', node)
          return [new StatusSuccess(node)];
        }),
        catchError(err => {
          console.error(err);
          return of(new StatusFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._dataNodeSvc.getOne(action.collectorId, action.itemId).pipe(
        first(),
        concatMap((item: CollectorItem) => {
          return [new LoadOneSuccess(<CollectorItem>item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._dataNodeSvc.remove(action.collectorId, action.id).pipe(
        first(),
        concatMap((item: CollectorItem) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  copy$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemActionTypes.Copy),
    switchMap((action: Copy) => {
      return this._dataNodeSvc.copy(action.processId, action.id).pipe(
        first(),
        switchMap((item: CollectorItem) => {
            this._notifyService.success('COLLECTOR.ITEM_COPIED');
          return [new CopySuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CopyFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: CollectorItemService,
              private _dataNodeSvc: BomDataNodeService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
