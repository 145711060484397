
import {throwError as observableThrowError, combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {ApiErrorBuilder} from 'app/+store/_legacy/api/builders/api-error.builder';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';


@Injectable()
export class TaskAssigneeService {
  readonly BASE_PATH = 'api/v1/workflow_engine';
  basePath;
  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getAll(taskId): Observable<IResource[]> {
    return this._tokenService.get(`${this.basePath}/tasks/${taskId}/assignees`).pipe(
      map((res: any) => <IResource[]>ResourceBuilder.fromResponse(res)),
      catchError(this.handleError),);
  }

  add(taskId, assigneeId, assigneeType) {
    return this._tokenService.post(`${this.basePath}/tasks/${taskId}/assignees`, {
      data: {
        id: assigneeId,
        type: assigneeType
      }
    });
  }
  remove(taskId, assigneeId, assigneeType) {
    return this._tokenService.delete(`${this.basePath}/tasks/${taskId}/assignees/${assigneeId}`);
  }

  diffOperations(taskId: number, original: ProcessParticipant[], now): Observable<void> {
    const originalByOriginalIdAndType: {[key: string]: ProcessParticipant} = {};

    for (const participant of original) {
      originalByOriginalIdAndType[participant.id + '|' + participant.type] = participant;
    }

    const operations = [];

    for (const newParticipant of now) {
      if (!newParticipant || !newParticipant.email) {
        continue
      }
      const key = newParticipant.id + '|' + newParticipant.type;

      if (originalByOriginalIdAndType.hasOwnProperty(key)) {
        delete originalByOriginalIdAndType[key];
      } else {
        operations.push(this.add(taskId, newParticipant.id, newParticipant.type));
      }
    }
    for (const originalId in originalByOriginalIdAndType) {
      if (originalByOriginalIdAndType.hasOwnProperty(originalId)) {
        operations.push(this.remove(taskId, originalByOriginalIdAndType[originalId].id, originalByOriginalIdAndType[originalId].type));
      }
    }
    return observableCombineLatest(operations).pipe(map(() => undefined));
  }

  private handleError(error) {
    console.error('TaskAssigneeHandler#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}

