import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CacProcess} from './cac';

export interface State extends EntityState<CacProcess> {
  selected?: CacProcess;
  loading: boolean;
}

export const adapter = createEntityAdapter<CacProcess>();

export const initialState: State = adapter.getInitialState({
  selected: null,
  loading: false,
});
