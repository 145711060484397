import {ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SignatureFrameFormat, VisualSignature} from 'app/+store/document-signature/document-signature';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {first} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {CreateSignatureDialogComponent} from '../../../../../document-signature/modules/document-signature-user-account/components/create-signature-dialog/create-signature-dialog.component';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'dvtx-document-signature-profile-preview',
  templateUrl: './document-signature-profile-preview.component.html',
  styleUrls: ['./document-signature-profile-preview.component.scss']
})
export class DocumentSignatureProfilePreviewComponent implements OnInit {
  @Input() currentSignature: VisualSignature;
  @Input() skipMetaInfoSupported = false;
  @Input() enableSkipMetaInfoSupport = true;
  @Input() enableEdit = true;
  @Input() enableHandWriting = true;

  private signatureType: SignatureFrameFormat;

  cssPreviewClass: 'format-signature' | 'format-signet' | 'format-stamp' = 'format-signature';
  aspectRatio = 3;

  uploadSignatureButton: HTMLElement;
  uploadError;
  imageBase64: string;
  skipMetaInfo = false;
  public edit = false;
  signatureImageChangedEvent: any = '';
  croppedProfileImage: string = '';

  @ViewChild('dialogTpl', {static: true})
  private dialogTpl: TemplateRef<any>;
  private dialogRef: MatDialogRef<any>;

  @Input() set format(f: SignatureFrameFormat) {
    switch (f) {
      case 'signet':
        this.aspectRatio = 1;
        this.signatureType = 'signet';
        this.cssPreviewClass = 'format-signet';
        break;
      case 'stamp':
        this.aspectRatio = 1.46;
        this.signatureType = 'stamp';
        this.cssPreviewClass = 'format-stamp';
        break;
      case 'signature':
        this.aspectRatio = 3;
        this.cssPreviewClass = 'format-signature';
        break;
    }
  }

  constructor(private _signSvc: DocumentSignatureService,
              private _dialog: MatDialog,
              private _notifyService: FivefNotificationService,
              private _cdr: ChangeDetectorRef) {
    this.currentSignature = new VisualSignature(null);
  }

  ngOnInit(): void {
  }

  public save() {
    this.edit = false;
    this._cdr.detectChanges();
  }

  onSubmitCroppingClick(): void {
    const content = this.croppedProfileImage;
    this.currentSignature.content = content;
    this._signSvc.updateDefaultSignature(content, this.signatureType).pipe(first()).subscribe(visualSignature => {
      this.currentSignature = visualSignature;
      this.skipMetaInfoSupported = VisualSignature.isSkipMetaInfoSupported(visualSignature);
      this._notifyService.success('SIGNATURE.EVENTS.DEFAULT_SIGNATURE_SAVED')
      this._cdr.detectChanges();
    }, err => {
      console.error(err);
      this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
    });
    this.dialogRef.close();
  }

  signatureImageCropEvent(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.base64;
  }

  signatureImageLoaded() {
    console.log('Image loaded');
  }

  signatureImageLoadedFailed() {
    console.error('Image loading failed');
  }

  signatureRemove(): void {
    this._signSvc.deleteDefaultSignature(this.signatureType)
      .pipe(first()).subscribe(_visualSignature => {
      this.currentSignature.content = null;
      this._notifyService.success('SIGNATURE.EVENTS.DEFAULT_SIGNATURE_DELETED')
      this._cdr.detectChanges();
    }, err => {
      console.error(err);
      if (err.status === 404) return;
      this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
    });
  }

  signatureImageChangeRequest() {
    this.uploadSignatureButton = document.getElementById('uploadSignatureButton-' + this.cssPreviewClass) as HTMLElement;
    this.uploadSignatureButton.click();
  }

  signatureImageChangeEvent(event, base64 = false) {
    this.uploadError = false;
    if (base64) {
      this.imageBase64 = event;
    } else {
      this.signatureImageChangedEvent = event;
      if (event && event.srcElement && event.srcElement.files[0] && event.srcElement.files[0].size > (1048576 / 2)) {
        this.uploadError = true;
        this._notifyService.error('SIGNATURE.ERRORS.SIZE_ERROR');
        return;
      }
    }

    this.dialogRef = this._dialog.open(this.dialogTpl);
  }

  openHandWriteDialog() {
    const dialog = this._dialog.open(CreateSignatureDialogComponent, {
      panelClass: 'signature-popup',
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        const content = result;
        this.currentSignature.content = content;
        this._signSvc.updateDefaultSignature(content).pipe(first()).subscribe(visualSignature => {
          this.currentSignature = visualSignature;
          this._notifyService.success('SIGNATURE.EVENTS.DEFAULT_SIGNATURE_SAVED')
          this._cdr.detectChanges();
        }, err => {
          console.error(err);
          this._notifyService.error('SIGNATURE.ERRORS.GENERAL_ERROR_RETRY')
        });
      }
    });
  }

  toggleSkipMetaInfo($event: MatCheckboxChange) {
    this._signSvc.toggleSkipMetaInfo($event.checked).pipe(first()).subscribe(visualSignature => {
      this.currentSignature = visualSignature;
      this.skipMetaInfoSupported = VisualSignature.isSkipMetaInfoSupported(visualSignature);
      this._notifyService.success('SIGNATURE.EVENTS.DEFAULT_SIGNATURE_SAVED')
      this._cdr.detectChanges();
    }, err => {
      console.error(err);
    })
  }
}
