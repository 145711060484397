import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {TaskStatusBuilder} from './task-status.builder';
import {TaskStatus} from './task-status';

@Injectable()
export class TaskStatusService {
  readonly base_path = 'api/v1/tasks/status_schemes';

  builder: TaskStatusBuilder;

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(schemeId: string): Observable<TaskStatus[]> {
    const builder = new TaskStatusBuilder();
    return <Observable<TaskStatus[]>>this._http.get<TaskStatusBuilder, TaskStatus>(builder, `${this.base_path}/${schemeId}/statuses`);
  }

  create(status: TaskStatus): Observable<TaskStatus> {
    const builder = new TaskStatusBuilder();
    const payload = builder.toRequest(status);
    return <Observable<TaskStatus>>this._http.post<TaskStatusBuilder, TaskStatus>(builder, `${this.base_path}/${status.statusScheme}/statuses`, payload);
  }

  update(status: TaskStatus): Observable<TaskStatus> {
    const builder = new TaskStatusBuilder();
    const payload = builder.toRequest(status);
    return <Observable<TaskStatus>>this._http.put<TaskStatusBuilder, TaskStatus>(builder, `${this.base_path}/${status.statusScheme}/statuses/${status.id}`, payload);
  }

  remove(status: TaskStatus): Observable<TaskStatus> {
    const builder = new TaskStatusBuilder();
    return <Observable<TaskStatus>>this._http.del<TaskStatusBuilder, TaskStatus>(builder, `${this.base_path}/${status.statusScheme}/statuses/${status.id}`);
  }
}
