import {IResource} from '../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IOrganization} from '../../../+store/_legacy/api/models/organization.interface';
import {IThirdPartyConfirmation} from './thirdPartyConfirmation';

export class RequestConfirmation implements IResource {
  state: string;

  constructor(public id: number,
              public type: string,
              public status: string,
              public third_party_name: string,
              public due_date: string,
              public received_at: string,
              public contractee: IOrganization,
              public creationObject: IThirdPartyConfirmation) {
  }

  thirdPartyDifference() {
    try {
      const request = this.creationObject.rows[0].toRequestCreation();
      if (request.balanceItem) {
        const balanceItem = request.balanceItem;
        if (balanceItem.isBalanceConfirmed) {
          return 0;

        } else {
          try {
            return parseFloat(balanceItem.balance) - parseFloat(balanceItem.balanceManualInput);
          } catch (error) {
            console.error(error, balanceItem.balance, balanceItem.balanceManualInput);
            return null;
          }
        }
      }

      let deviations = 0;
      let confirmations = 0;
      let undecided = 0;
      for (const creationObjectRow of this.creationObject.rows) {
        for (const invoiceRow of creationObjectRow.toRequestCreation().invoiceItems) {
          if (invoiceRow.isInvoiceConfirmed) {
            ++confirmations;
          } else {
            try {
              const thirdPartyDifference = parseFloat(invoiceRow.amount) - parseFloat(invoiceRow.invoiceManualInput);
              thirdPartyDifference === 0 ? ++confirmations : ++deviations;
            } catch (error) {
              ++undecided;
            }
          }
        }
      }
      return deviations > 0 ? deviations : 0;
    } catch (err) {
      return null;
    }
  }

  toForm() {
    return undefined;
  }
}
