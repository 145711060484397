import {Action} from '@ngrx/store';
import {CacProcess} from './cac';

export enum CacActionTypes {
  LoadOne = '[cac] Load One',
  LoadOneSuccess = '[cac] Load One Success',
  LoadOneFail = '[cac] Load One Fail'
}


export class LoadOne implements Action {
  readonly type = CacActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CacActionTypes.LoadOneSuccess;

  constructor(public cac: CacProcess) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CacActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export type CacActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
