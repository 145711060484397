import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ProjectStatistics} from './project-statistics';
import {ProcessesStatsObj, TasksStatsObj, DocumentsStatsObj, CollectorsStatsObj} from 'app/+store/project-statistics/project-statistics.interface';

export class ProjectStatisticsBuilder implements IApiResourceBuilder<ProjectStatistics> {

  public processes: ProcessesStatsObj = <ProcessesStatsObj>{};
  public tasks: TasksStatsObj = <TasksStatsObj>{};
  public collectors: CollectorsStatsObj = <CollectorsStatsObj>{};
  public documents: DocumentsStatsObj = <DocumentsStatsObj>{};

  fromStatsObject(statsObject) {
    const type = statsObject.type;

    switch (type) {
      case 'stat_process_simple_process_counts':
        this.processes = {
          all: statsObject.all_count || 0,
          closed: statsObject.closed_count || 0,
          open: statsObject.open_count || 0
        }
        break;

      case 'stat_tasks_status_overview_seven_days':
        this.tasks = {
          all: statsObject.all_count || 0,
          closed: statsObject.closed_count || 0,
          open: statsObject.open_count || 0,
          overdue: statsObject.overdue_count || 0,
          daily: {}
        };
        statsObject.daily_counts.forEach(dailyStats => {
          this.tasks.daily[dailyStats.date] = {
            day: dailyStats.day,
            date: dailyStats.date,
            all: dailyStats.all_count | 0,
            closed: dailyStats.closed_count | 0,
            open: dailyStats.open_count | 0,
            overdue: dailyStats.overdue_count | 0,
            creation: dailyStats.creation_count | 0
          }
        });
        break;

      case 'stat_collecto_item_status':
        this.collectors.collectors = statsObject.collecto_count | 0;
        this.collectors.statuses = {};
        statsObject.statuses.forEach(collectorStatus => {
          this.collectors.statuses[collectorStatus.status] = collectorStatus.count;
        });
        break;

      case 'stat_folder_documents_counts':
        this.documents = {
          folders: statsObject.folder_count | 0,
          attachedDocuments: statsObject.attached_document_count | 0,
          availableDocuments: statsObject.available_document_count | 0
        }
        break;
    }
  }

  fromResponse(data): ProjectStatistics {
    const project = new ProjectStatistics(
      data.id,
      data.attributes.process_id || null
    );

    data.attributes.stat_objects.forEach(object => {
      this.fromStatsObject(object);
    });

    project.processes = this.processes;
    project.tasks = this.tasks;
    project.collectors = this.collectors;
    project.documents = this.documents;

    return project;
  }

  toRequest(_: ProjectStatistics) {
    return null;
  }
}
