import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {OrganizationLogo} from './organization-logo';

export class OrganizationLogoBuilder implements IApiResourceBuilder<OrganizationLogo> {
  fromResponse(data): OrganizationLogo {
    return new OrganizationLogo(
      data.id,
      data.attributes.link,
      data.attributes['default'],
      data.attributes.title,
      data.attributes.organization_uuid,
      data.attributes.inviter_logo
    );
  }

  toRequest(_: OrganizationLogo) {
    return null
  }
}
