<div class="fivef-icon--container"
     [class.five-f-menu-opened]="showIconPicker">
  <button [disabled]="disabled"
          [matTooltip]="'LABELS.SELECT_ICON' | translate"
          mat-icon-button
          [disableRipple]="true"
          [matMenuTriggerFor]="menu"
          (click)="showIconPicker = !showIconPicker"
          #buttonToggler>
    <mat-icon>{{ selected ? selected : 'insert_emoticon' }}</mat-icon>
  </button>

  <mat-menu #menu="matMenu"
            class="fivef-icon-picker--menu"
            [xPosition]="xPosition"
            [yPosition]="yPosition"
            (closed)="showIconPicker = false">
    <ul class="fivef-icon-picker--listing">
      <ng-container *ngFor="let icon of icons">
        <li class="list-inline-item" (click)="selectIcon(icon)">
          <button mat-icon-button
                  [color]="selected === icon ? 'primary' : 'grey'">
            <mat-icon>{{ icon }}</mat-icon>
          </button>
        </li>
      </ng-container>
    </ul>
  </mat-menu>
</div>
