<div class="five-f-dropdown-content-container {{borderColor}}"
     [class.five-f-menu-opened]="showDropdownContent"
     [style.border-color]="borderColor">

  <dvtx-tooltip [tooltipText]="tooltip" [position]="'top left'" *ngIf="buttonType === 'iconButton'">
    <!-- Dropdown icon button -->
    <button mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menu"
            (click)="showDropdownContent = !showDropdownContent"
            #buttonToggler
            [color]="iconButtonColor">
      <ng-template [cdkPortalOutlet]="icon"></ng-template>
    </button>
  </dvtx-tooltip>

  <dvtx-tooltip [tooltipText]="tooltip" [position]="'top left'" *ngIf="buttonType === 'fabButton'">
    <!-- Dropdown mini-fab-button button -->
    <button mat-mini-fab
            [matMenuTriggerFor]="menu"
            (menuOpened)="menuOpened($event)"
            (click)="showDropdownContent = !showDropdownContent"
            #buttonToggler
            [color]="buttonColor">
      <ng-template [cdkPortalOutlet]="icon"></ng-template>
    </button>
  </dvtx-tooltip>

  <ng-container *ngIf="buttonType === 'iconTextButton'">
    <!-- Dropdown raised button -->
    <button mat-button
            [matMenuTriggerFor]="menu"
            [color]="iconButtonColor"
            (menuOpened)="menuOpened($event)"
            [disableRipple]="true"
            (click)="showDropdownContent = !showDropdownContent"
            class="mat-icon-button-with-text" #buttonToggler>
      <ng-template [cdkPortalOutlet]="icon"></ng-template>
    </button>
  </ng-container>


  <mat-menu #menu="matMenu"
            class="fivef-dropdown-content--menu-wrapper {{borderColor}} {{classesList}}"
            [backdropClass]="backdropClassesList"
            [xPosition]="xPosition"
            [yPosition]="yPosition"
            (click)="$event.stopPropagation();"
            (keydown.tab)="$event.stopPropagation()"
            (keydown.space)="$event.stopPropagation()"
            (closed)="afterClose()">
    <div class="five-f-dropdown-content-inner-content"
         (click)="$event.stopPropagation();"
         (keydown.tab)="$event.stopPropagation()"
         (keydown.space)="$event.stopPropagation()"
         [style.max-width]="maxWidth"
         #menuContainer>
      <ng-template [cdkPortalOutlet]="content"></ng-template>
    </div>
  </mat-menu>
</div>
