// import {IApiResourceBuilder} from 'app/+store/_legacy/api-resource/models/api.interface';
import {IContactVerificationEntry} from './contact-verification.interface';

export class ContactVerificationBuilder {
  toRequest (params: IContactVerificationEntry) {
    return {
      data: {
        type: 'audit_contact_imports',
        attributes: {
          identifier: params.identifier,
          abbreviation: params.abbreviation,
          organization_name: params.organization_name,
          country_code: params.country_code,
          first_name: params.first_name,
          last_name: params.last_name,
          role: params.role,
          email: params.email,
          phone: params.phone,
          mobile: params.mobile,
          verified_at: params.verified_at,
          notification_language: params.notification_language
        }
      }
    };
  }
}


