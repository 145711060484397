import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorPickFileFromDms,
  CollectorPickFileFromDmsFail,
  CollectorPickFileFromDmsSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllExternal,
  LoadAllExternalFail,
  LoadAllExternalSuccess,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneById,
  LoadOneByIdFail,
  LoadOneByIdSuccess,
  LoadOneFail,
  LoadOneSuccess, MarkSeen, MarkSeenFail, MarkSeenSuccess,
  PickFileFromDms,
  PickFileFromDmsFail,
  ProcessArtifactActionTypes,
  Rename,
} from './process-artifact.actions';
import * as dmsActions from '../dms-document/dms-document.actions';
import {ProcessArtifactService} from './process-artifact.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {ProcessArtifact} from './process-artifact';
import {RefreshUploads} from '../process-event/process-event.actions';

@Injectable()
export class ProcessArtifactEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id, action.recursive).pipe(
        first(),
        concatMap((res: ProcessArtifact[]) => {
          return [new LoadAllSuccess(res, action.upsertEntities)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.processId, action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new LoadOneSuccess(artifact)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  markSeen$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.MarkSeen),
    switchMap((action: MarkSeen) => {
      return this._svc.markSeen(action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new MarkSeenSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new MarkSeenFail(err));
        }));
    })
  ));

  loadOneById$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadOneById),
    switchMap((action: LoadOneById) => {
      return this._svc.getOneById(action.id).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          return [new LoadOneByIdSuccess(artifact)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneByIdFail(err));
        }));
    })
  ));

  rename$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.Rename),
    switchMap((action: Rename) => {
      return this._svc.rename(action.processId, action.id, action.fileName).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
            this._notifyService.success('GENERAL.DOCUMENT_RENAME_SUCCESSFULLY')
          return [new LoadAllExternalSuccess([artifact]), new RefreshUploads(action.processId, artifact.id)];
        }),
        catchError(err => {
          this._notifyService.error('GENERAL.DOCUMENT_RENAME_FAIL')
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.processId, action.id, action.deleteFile).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => {
          if (action.deleteFile) {
            return [new DeleteSuccess(action.id), new dmsActions.RemoveSuccess(String(artifact.dmsDocumentId))];
          }
          return [new DeleteSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteFail(err));
        }));
    })
  ));

  loadAllExternal$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.LoadAllExternal),
    switchMap((action: LoadAllExternal) => {
      return this._svc.getAllExternal(action.token).pipe(
        first(),
        concatMap((res: ProcessArtifact[]) => {
          return [new LoadAllExternalSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllExternalFail(err));
        }));
    })
  ));

  pickFileFromDms$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.PickFileFromDms),
    concatMap((action: PickFileFromDms) => {
      return this._svc.pickFileFromDms(action.processId, action.referenceId, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => { // Returns an upload, not an process artifact
          return [new CollectorPickFileFromDmsSuccess(artifact), new LoadAll(action.processId)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PickFileFromDmsFail(err));
        }));
    })
  ));

  collectorPickFileFromDms$ = createEffect(() => this.actions.pipe(
    ofType(ProcessArtifactActionTypes.CollectorPickFileFromDms),
    concatMap((action: CollectorPickFileFromDms) => {
      return this._svc.collectorPickFileFromDms(action.collectorId, action.nodeId, action.role, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((artifact: ProcessArtifact) => { // Returns an upload, not an process artifact
          return [new CollectorPickFileFromDmsSuccess(artifact), new LoadAll(action.collectorId)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CollectorPickFileFromDmsFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessArtifactService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




