import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IFivefFlatNode} from '../../tree/fivef-tree/fivef-tree.interface';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CdkContextMenuTrigger, CdkMenu, CdkMenuItem} from '@angular/cdk/menu';
import {TranslateModule} from '@ngx-translate/core';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {FivefIconComponent} from '../../common/fivef-icon/fivef-icon.component';

/**
 * Specialized folder node with folder color support and
 * context menu.
 */
@Component({
  selector: 'fivef-process-tree-node',
  host: {'class': 'fivef-tree-node fivef-process-tree-node'},
  standalone: true,
  imports: [CommonModule, MatTooltipModule, CdkMenu, CdkMenuItem, CdkContextMenuTrigger, TranslateModule, FivefIconComponent],
  templateUrl: './fivef-process-tree-node.component.html',
  styleUrls: ['./fivef-process-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefProcessTreeNodeComponent {
  public readonly DmsAccountType = DmsAccountType;

  /**
   * Internal node for template usage.
   * Not part of interface, should not be used.
   */
  public _node: IFivefFlatNode;

  /**
   * Emits target folder on folder creation action click.
   * @private
   */
  @Output()
  private onCreate = new EventEmitter();

  /**
   * Emits target folder on folder update action click.
   * @private
   */
  @Output()
  private onRename = new EventEmitter();

  /**
   * Emits target folder on folder deletion action click.
   * @private
   */
  @Output()
  private onDelete = new EventEmitter();

  /**
   * Default folder theme class suffix.
   */
  public theme = 'default';

  /**
   * Displayed node as transformed flattened 5F/CDK node.
   */
  @Input()
  public set node(n: IFivefFlatNode) {
    this._node = n;
    this.setFolderColorTheme(n);
  }

  /**
   * Creates a create event on click.
   * Emits the target folder.
   *
   * @param node
   */
  public createNode(node: IFivefFlatNode) {
    this.onCreate.emit(node);
  }

  /**
   * Creates an update event on click.
   * Emits the target folder.
   *
   * @param node
   */
  public renameNode(node: IFivefFlatNode) {
    this.onRename.emit(node);
  }

  /**
   * Creates a delete event on click.
   * Emits the target folder.
   *
   * @param node
   */
  public deleteNode(node: IFivefFlatNode) {
    this.onDelete.emit(node);
  }

  /**
   * Sets a folder color theme.
   *
   * Current themes concepts are
   * - private theme: Theme for private folders.
   * - DATEV theme: Theme for folders connected to bookman synchronization.
   *
   * @param node
   * @private
   */
  private setFolderColorTheme(node: IFivefFlatNode) {
    const folder: DmsFolder = node && node.data ? <DmsFolder>node.data : null;

    if (!folder) {
      return;
    }

    if (folder && folder.dmsAccountType === DmsAccountType.Private) {
      this.theme = 'private-folder';
    }

    if (folder && folder.dmsAccountType === DmsAccountType.Organization && folder.bookmanCockpitEnabled) {
      this.theme = 'datev-folder';
    }
  }
}
