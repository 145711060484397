import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Client} from '../client/client';
import {IProcessTreeSettings, ProcessTreeNode, ProcessTreeSettings} from './process-tree';
import {ProcessTreeNodeType} from './process-tree.interface';

/**
 * Builder for Project Structure settings.
 * Settings are persisted in the user account's profile.
 *
 * Current options:
 * - Sort project structure by different attributes.
 * - Define sort direction.
 * - Hide closed workflows.
 */
export class ProcessTreeSettingsBuilder implements IApiResourceBuilder<IProcessTreeSettings> {
  fromResponse(data): IProcessTreeSettings {
    return new ProcessTreeSettings(data.attributes.sort_by,
      data.attributes.sort_direction,
      data.attributes.hide_closed
    );
  }

  toRequest(settings: IProcessTreeSettings) {
    return {
      data: {
        type: 'workflow_engine_process_tree_settings',
        attributes: {
          sort_by: settings.sortBy,
          sort_direction: settings.sortDirection,
          hide_closed: settings.hideClosed,
        }
      }
    };
  }
}

export class ProcessTreeBuilder implements IApiResourceBuilder<ProcessTreeNode> {
  clients: {
    [id: string]: Client
  } = {};

  fromResponse(data): ProcessTreeNode {
    const node = new ProcessTreeNode(
      data.id,
      ProcessTreeNodeType.Process,
      data.attributes.parent_id,
      data.attributes.title,
      data.attributes.updated_at);
    node.createdAt = data.attributes.created_at;
    node.active = data.attributes.active;
    node.closed = data.attributes.closed;

    this._setIcon(node, data.attributes);
    return node;
  }

  toRequest(_: ProcessTreeNode) {
    return null;
  }

  private _setIcon(node, attrs) {
    switch (attrs.type) {
      case 'project':
        node.icon = 'process';
        node.isSvgIcon = true;
        node.isProject = true;
        break;
      case 'quickshare':
        node.icon = 'share';
        node.isQuickshare = true;
        break;
      case 'cac':
      case 'cac_request':
        node.icon = 'exchange_circle';
        node.isSvgIcon = true;
        node.isCac = true;
        break;
      case 'signature':
        node.icon = 'sign';
        node.isSvgIcon = true;
        break;
      case 'quickcollector':
        node.icon = 'playlist_add_check';
        node.isCollecto = true;
        break;
      case 'audit_contact_imports':
      case 'contact_verification':
        node.icon = 'person_verif';
        node.isSvgIcon = true;
        node.isCollecto = true;
        break;
      case 'third_party':
      case 'leasing':
      case 'bank':
      case 'safekeeping':
      case 'expert':
      case 'insurance':
      case 'claim_invoice':
      case 'claim_balance':
      case 'liability_balance':
      case 'liability_invoice':
      case 'third_party_request':
        node.icon = 'third_party';
        node.isSvgIcon = true;
        break
      case 'fibu':
        node.icon = 'fibu_workflow';
        node.isSvgIcon = true;
        node.isFibu = true;
        break;
      default:
        node.icon = 'projectroom';
        node.isSvgIcon = true;
        node.isProjectRoom = true;
    }
  }
}
