import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CreateResource,
  CreateResourceFail,
  Delete,
  DeleteFail,
  DeleteSuccess,
  TaskResourceActionTypes,
  UpdateResource,
  UpdateResourceFail,
  UpdateResourceSuccess
} from './task-resource.actions';
import * as taskActions from './../task/task.actions';
import {TaskResourceService} from './task-resource.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {TaskResource} from './task-resource';
import * as TaskActions from 'app/+store/task/task.actions';
import {Task} from '../task/task';

@Injectable()
export class TaskResourceEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(TaskResourceActionTypes.CreateResource),
    switchMap((action: CreateResource) => {
      return this._svc.create(action.taskId, action.resouce, action.referenceId, action.referenceType).pipe(
        first(),
        concatMap((res: Task) => {
          return [new taskActions.EditSuccess(res)];
        }),
        catchError(err => {
          console.log(err);
          return of(new CreateResourceFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(TaskResourceActionTypes.UpdateResource),
    switchMap((action: UpdateResource) => {
      return this._svc.update(action.taskId, action.resouce).pipe(
        first(),
        concatMap((res: TaskResource) => {
          return [new UpdateResourceSuccess(res)];
        }),
        catchError(err => {
          console.log(err);
          return of(new UpdateResourceFail(err));
        }));
    })
  ));

  delete$ = createEffect(() => this.actions.pipe(
    ofType(TaskResourceActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.taskId, action.taskId).pipe(
        first(),
        switchMap((res: TaskResource) => {
          this._notifyService.success('TASK.DELETED_RESOURCE_SUCCESSFULLY')
          return [new DeleteSuccess(res), new TaskActions.LoadOne(action.taskId)];
        }),
        catchError(err => {
          console.log(err);
          return of(new DeleteFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TaskResourceService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
