import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Quickshare, QuickshareSettings} from './quickshare';
import { Client } from 'app/+store/client/client';
import { ClientBuilder } from 'app/+store/client/client.builder';

export class QuickshareBuilder implements IApiResourceBuilder<Quickshare> {
  clients: {
    [id: string]: Client
  } = {};

  fromResponse(data): Quickshare {
    const quickshare = new Quickshare(
      data.id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.state,
      data.attributes.creator_name,
      data.attributes.creator_email,
      data.attributes.owner_name,
      data.attributes.owner_email,
      data.attributes.dms_folder_id,
      data.attributes.notify_by_sms,
      data.attributes.password_enabled,
      data.attributes.password_expires_at_enabled,
      data.attributes.password_expires_at,
      data.attributes.due_date_enabled,
      data.attributes.due_date,
      data.attributes.created_at,
      data.attributes.updated_at);
    quickshare.passphrase = data.attributes.passphrase;
    quickshare.parentId = data.attributes.parent_id;
    quickshare.color = data.attributes.color;
    let clientId = null;
    try {
      clientId = data.relationships.client && data.relationships.client.data.id ? data.relationships.client.data.id : null;
    } catch (e) {}
    if (clientId) {
      quickshare.client = this.clients[clientId];
    }

    return quickshare;
  }

  toRequest(_: Quickshare) {
    return null;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const cbuilder = new ClientBuilder();

    includes.map( (include) => {
      if (include.type === 'client_people' || include.type === 'client_organizations') {
        const _client = cbuilder.fromResponse(include);
        this.clients[include.id] = _client;
      }
    });
  }
}

export class QuickshareSettingsBuilder implements IApiResourceBuilder<QuickshareSettings> {
  fromResponse(data): QuickshareSettings {
    return new QuickshareSettings(
      data.id,
      data.attributes.authorized,
      data.attributes.tokens,
      data.attributes.passphrase,
      data.attributes.password_expires_at);
  }

  toRequest(settings: QuickshareSettings) {
    return {
      data: {
        id: settings.id,
        type: settings.type,
        attributes: {
          passphrase: settings.passphrase,
          password_expires_at: settings.passwordExpiresAt,
        }
      }
    };
  }
}
