<!-- Alternative border bar e.g. with more left padding, example
     position absolute, width 10px, radius 5px, bottom, top 10px; -->
<div class="fivef-dialog--color-bar" [style.background-color]="borderColor"></div>

<header class="fivef-dialog--header" *ngIf="title">
  <div class="fivef-dialog--header-title">
    <h2>{{ title | translate }}</h2>
    <span *ngIf="!!subtitle"
          class="fivef-dialog--header--subtitle">{{ subtitle | translate }}</span>
  </div>

  <ng-content select="[fivef-dialog-header-actions]"></ng-content>
</header>

<section class="fivef-dialog--content">
  <ng-content></ng-content>
</section>

<footer class="fivef-dialog--footer" *ngIf="!disableFooter">
  <ng-content select="[fivef-dialog-footer]"></ng-content>
</footer>
