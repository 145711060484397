import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';

/**
 * Simple tree control with single selection model.
 */
export class FivefTreeTreeControl<T extends {id: string}, K> extends FlatTreeControl<T, K> {
  /**
   * Selection model of the tree.
   * Uses a track by function to track selected nodes on immutable data set changes.
   *
   * @private
   */
  private selection: SelectionModel<T>;

  constructor(getNodeLevelFn, isNodeExpandableFn, options) {
    super(getNodeLevelFn, isNodeExpandableFn, options);

    // Selection model with track functionality.
    this.selection = new SelectionModel<T>(false, [], true, (value, other) => other.id === value.id)
  }

  /**
   * Returns true if node is selected.
   *
   * @param node
   */
  public isSelected(node: T): boolean {
    return !!node && !!this.selection.isSelected(node);
  }

  /**
   * Selects a given node.
   *
   * @param node
   */
  public select(node: T): boolean {
    this.selection.select(node);
    return this.isSelected(node);
  }

  /**
   * Removes selection from a given node.
   *
   * @param node
   */
  public deselect(node: T): boolean {
    return !!node && !!this.selection.deselect(node);
  }
}
