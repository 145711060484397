import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Label} from 'app/+store/label/label';
import {ILabelParams, LabelOrigin, LabelScope} from 'app/+store/label/label.interface';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FivefLabelComponent} from '../fivef-label/fivef-label.component';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'fivef-labels-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatCheckboxModule,
    FivefLabelComponent,
    FivefSearchComponent,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule
  ],
  host: {class: 'fivef-labels-selector'},
  templateUrl: './fivef-labels-selector.component.html',
  styleUrls: ['./fivef-labels-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefLabelsSelectorComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  private labels$ = new BehaviorSubject<Label[]>([]);
  public filteredLabels$: Observable<Label[]>;
  private searchTerm$ = new BehaviorSubject<string>(null);

  public scope = LabelScope;
  public origin = LabelOrigin;
  private _labels: Label[] = [];

  @Input()
  selected: string[] = [];

  @Input()
  appearance: 'button' | 'iconButton' = 'iconButton';

  @Input()
  labelIcon = true;

  @Input()
  hiddenCount: number = 0;

  @Output()
  onSelection: EventEmitter<any> = new EventEmitter();

  @Input()
  set labels(labels: Label[]) {
    this._labels = !!labels && labels.length ? labels : [];
    this.labels$.next(this._labels);
  }

  constructor(private translateSvc: TranslateService) {
  }

  ngOnInit(): void {
    this.filteredLabels$ = combineLatest(this.searchTerm$, this.labels$)
      .pipe(
        takeUntil(this.onDestroy),
        map(([searchTerm, labels]) => {
          if (!!searchTerm && typeof searchTerm === 'string' && searchTerm.length) {
            const query = searchTerm.trim().toLowerCase();
            return labels.filter(label => {
              const title = this.translateSvc.instant(label.title)?.trim()?.toLowerCase() || '';
              return title.indexOf(query) !== -1;
            });
          } else {
            return labels;
          }
        }));
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.searchTerm$.complete();
    this.labels$.complete();
  }

  public select(label: Label, $event) {
    if (!!this._labels && !!this._labels.find(_label => _label.id === label.id)) {
      this.onSelection.emit({event: $event, label: label});
    } else {
      this.submitCreateGlobalLabel(label);
    }
  }

  private submitCreateGlobalLabel(label: Label) {
    const labelParams: ILabelParams = {
      icon: label.icon,
      color: label.color,
      title: label.title,
      description: label.description,
      scope: this.scope.GLOBAL,
      isGlobal: true,
      origin: this.origin.NO_ORIGIN
    };
    this.onSelection.emit({$event: null, label: labelParams})
  }

  public isSelected(labelId: string) {
    if (!labelId) return false;
    return this.selected && this.selected.indexOf(labelId) > -1;
  }

  public applySearch(term: string) {
    this.searchTerm$.next(term);
  }
}
