import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {BookmanClientActions, ClientActions, ClientSelectors, FeatureSelectors, FibuActions, MembershipSelectors} from 'app/+store';
import {first, map, takeUntil} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {Membership} from 'app/+store/membership/membership';
import {ClientCreateActionAppearance, FivefCreateClientComponent} from 'app/lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Client} from 'app/+store/client/client';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'fivef-edit-client-dialog',
  templateUrl: './fivef-edit-client-dialog.component.html',
  styleUrls: ['./fivef-edit-client-dialog.component.scss']
})
export class FivefEditClientDialogComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  readonly ClientCreateActionAppearance = ClientCreateActionAppearance;

  @ViewChild('dialogContent', {static: true})
  dialogContent: ElementRef;

  @ViewChild('clientSelectionComponent')
  clientSelectionComponent: FivefCreateClientComponent;

  selectedTab = 0;

  public client$ = new BehaviorSubject<Client>(null);
  clientId: string;

  featureSet$: Observable<Feature>;

  myMembership$: Observable<Membership>;

  showFibu: boolean = false;

  showDatevLogin: boolean = false;

  form: UntypedFormGroup;

  openedTab: number;

  constructor(public dialogRef: MatDialogRef<FivefEditClientDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _route: ActivatedRoute,
              private _router: Router,
              private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef) {
    if (data) {
      this.selectedTab = data.selectedTab ? data.selectedTab : 0;
      this.client$.next(data.client);
      this.clientId = data.client && data.client.id ? data.client.id : data.clientId;
      this.openedTab = data.openedTab ?? 0;
    }

  }

  ngOnInit() {
    this._route.queryParamMap.pipe(first()).subscribe((params) => {
      const tab = Number(params.get('tab'));
      if (tab) {
        this.selectedTab = tab;
      }
    })
    if (!this.client$.value && this.clientId) {
      this._store.select(ClientSelectors.getClientById(this.clientId))
        .pipe(takeUntil(this.onDestroy))
        .subscribe(client => {
          this.client$.next(client);
        });
    }

    this.myMembership$ = this._store.select(MembershipSelectors.getMyMembership);
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    combineLatest([this.featureSet$, this.myMembership$])
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([featureSet, myMembership]) => {
        if (myMembership && featureSet?.hasBookman && featureSet?.canUpdateBookmanSettings) {
          this.showFibu = true;

          if (this.clientId) {
            this._store.dispatch(new ClientActions.LoadOne(this.clientId));
            this._store.dispatch(new FibuActions.LoadByClient(this.clientId));
            this._store.dispatch(new BookmanClientActions.Refresh());
          }
        }
        this.showDatevLogin = !!featureSet?.['hasDatev'];
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this._router.navigate([], {
      queryParams: {},
    });
    this.onDestroy.next();
    this.onDestroy.complete();
    this.client$.complete();
  }

  updateBookmanSettings(client) {
    this.client$.next(client);
    this.data.onBookmanSave?.(client);
  }

  updateMapping(data) {
    this.data.onCloseAction(data);
  }

  saveClient() {
    if (this.clientSelectionComponent) {
      this.clientSelectionComponent.submit();
    }
  }

  editSuccess(client) {
    if (this.data.onSubmitAction) {
      this.data.onSubmitAction(client);
    }
  }

  updateActionButtons(form) {
    this.form = form;
    this._cdr.detectChanges();
  }

  updateUrl() {

  }

  selectedTabChanged(tab) {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        tab,
      },
      queryParamsHandling: 'merge',
    });
  }
}
