import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {MembershipActions} from 'app/+store/membership';
import {OperatorMemberService} from 'app/+store/operator/member/member.service';
import {getSelected} from 'app/+store/organization/organization.selectors';
import {EmailValidator} from 'app/lib/validator/email_valid';
import {RegistrationService} from 'app/+store/registration/registration.service';
import {AppState} from 'app/app.state';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {first} from 'rxjs/internal/operators/first';
import {MembershipInvitationComponent} from '../membership-invitation/membership-invitation.component';
import {FivefInputFieldType} from '../../../../../lib/fivef-ui/input/fivef-input.interface';
import {FivefEmailAddressValidator} from '../../../../../lib/fivef-ui/input/fivef-email.validator';

@Component({
  selector: 'dvtx-create-employee-dialog',
  templateUrl: './create-employee-dialog.component.html',
  styleUrls: ['./create-employee-dialog.component.scss']
})
export class CreateEmployeeDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  public color = 'var(--fivef-color-action)';

  public fieldType = FivefInputFieldType;

  showFirstPass: string = FivefInputFieldType.Password;
  passwordChangeOngoing = false;

  selectedIndexTab: any = '1'

  // true when member is being created, false else.
  // During member creation a DMS is created. This can take some time and
  // a progress bar is shown.
  // In this case we hiding the form by CSS not disabling it (ngIf), otherwise this creates issues with
  // the viewchild below.
  public employeeCreationOngoing = false;

  @ViewChild('membershipInvitation') membershipInvitationComponent: MembershipInvitationComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdr: MatDialogRef<CreateEmployeeDialogComponent>,
    private store: Store<AppState>,
    private memberService: OperatorMemberService,
    private notifySvc: FivefNotificationService,
    private cdr: ChangeDetectorRef,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      note: [''],
      notifyUser: [false],
      password: ['', Validators.required],
      email: ['', [Validators.required, FivefEmailAddressValidator()]]
    });
    if (data && data.color) {
      this.color = data.color;
    }
    if (data && data.selectedIndexTab) {
      this.selectedIndexTab = data.selectedIndexTab;
    }
  }

  ngOnInit() {
  }

  showFirstPassword() {
    this.showFirstPass = FivefInputFieldType.Text;
  }

  hideFirstPassword() {
    this.showFirstPass = FivefInputFieldType.Password;
  }

  verifyPasswordStrength(password) {
    return RegistrationService.verifyPasswordStrength(password)
  }

  isPasswordInvalid(password) {
    const result = RegistrationService.verifyPasswordStrength(password);
    return !result || result && result.level !== 'success';
  }

  createEmployee() {
    this.employeeCreationOngoing = true;
    const employee: any = {}
    employee.email = this.form.controls['email'].value;
    employee.password = this.form.controls['password'].value;
    employee.firstName = this.form.controls['firstName'].value;
    employee.lastName = this.form.controls['lastName'].value;
    employee.note = this.form.controls['note'].value;
    employee.notifyUser = this.form.controls['notifyUser'].value;

    // Switch view to progress mode before creation is ongoing.
    this.cdr.detectChanges();

    // Give the action another run inside the event queue, so the progress bar can be shown.
    setTimeout(_ => {
      this.store.select(getSelected).pipe(
        first()
      ).subscribe((org) => {
        this.memberService.addEmployee(employee, org.id).pipe(first()).subscribe((res) => {
          this.employeeCreationOngoing = false;
          this.store.dispatch(new MembershipActions.LoadAll(org.id));
          this.notifySvc.success('CONTACTS.EMPLOYEE_ADDED_SUCCESSFULLY')
          this.mdr.close(employee);
          this.employeeCreationOngoing = false;
          return;
        }, err => {
          console.error(err);
          this.employeeCreationOngoing = false;
        });
      });
    }, 10);
  }

  closeDialog(result?) {
    this.mdr.close(result);
  }

  public isInviteeEmailValid() {
    if (this.membershipInvitationComponent) {
      if (!this.membershipInvitationComponent.inviteeEmail
        || !this.membershipInvitationComponent.inviteeEmail.length)
        return false;
      return EmailValidator.emailValid(this.membershipInvitationComponent.inviteeEmail);
    } else {
      return false;
    }
  }

  openMemberInvitationDialog() {
    if (this.membershipInvitationComponent) {
      this.membershipInvitationComponent.openMemberInvitationDialog();
    }
  }

  resetForms(tabIdx = '0') {
    this.selectedIndexTab = '' + tabIdx;

    // didnt use form reset since email control cannot reset because its a new SimpleEmailAddress object
    if (this.form) {
      this.form.controls.password.reset();
      this.form.controls.firstName.reset();
      this.form.controls.lastName.reset();
      this.form.controls.note.reset();
      this.form.controls.notifyUser.reset();
      this.form.controls.email.patchValue('');
    }

    if (this.membershipInvitationComponent) {
      this.membershipInvitationComponent.inviteeEmail = '';
    }
    this.cdr.detectChanges();
  }
}
