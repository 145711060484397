import {
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors
} from '@angular/forms';
import {Directive, Injector, Input} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {ErrorMapper} from '../../fivef-legacy/base-form-elements/components/1_control-value-accessor-components/DvtxErrorMapper';


@Directive()
export abstract class FivefControlValueAccessor implements ControlValueAccessor {
  private onChangeListeners: Function[] = [];
  private onTouchListeners: Function[] = [];
  errorStateMatcher: ErrorStateMatcher;

  protected injector: Injector;

  _disabled = false;

  @Input()
  public elementName: string;

  @Input()
  errorMapper: ErrorMapper[];

  /**
   * Disables the CVA interface.
   *
   * Getter/setter to allow custom complex overrides by subcomponents.
   * @param disable
   */
  @Input()
  public set disabled(disable: boolean) {
    this._disabled = disable;
  }

  get disabled() {
    return this._disabled;
  }

  constructor() {
    this.errorStateMatcher = new FivefCvaErrorStateMatcher(this);
  }

  abstract writeValue(obj: any): void;

  public registerOnChange(fn: Function): void {
    this.onChangeListeners.push(fn);
  }

  public notifyOnChange(obj: any) {
    for (const changeListener of this.onChangeListeners) {
      changeListener(obj);
    }
  }

  public registerOnTouched(fn: Function): void {
    this.onTouchListeners.push(fn);
  }

  public setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
  }

  public notifyOnTouch() {
    for (const touchListener of this.onTouchListeners) {
      touchListener();
    }
  }

  public get errors(): ValidationErrors | null {
    return this.injector.get(NgControl).errors;
  }

  public get dirty(): boolean {
    try {
      const control = this.injector.get(NgControl);
      return control.dirty || (control.control && control.control.dirty);
    } catch {
      return false;
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class FivefCvaErrorStateMatcher implements ErrorStateMatcher {
  constructor(private cva: FivefControlValueAccessor) {
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.cva.dirty && !!this.cva.errors
  }
}
