import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {
  MembershipActions, MembershipSelectors,
  NaturalPersonActions,
  NaturalPersonSelectors,
  OrganizationActions,
  RoleActions
} from 'app/+store';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {AngularTokenService} from 'angular-token';
import {catchError, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {AddressBookTableHelper} from '../../../../address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';
import {combineLatest} from 'rxjs';
import {NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {TwoFactorSessionService} from 'app/+store/two-factor-session/two-factor-session.service';
import {TwoFactorMemberStatus} from 'app/+store/two-factor-session/two-factor-session';
import {ProjectRoomAdmin} from 'app/+store/process-role/process-role';
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {Membership} from 'app/+store/membership/membership';
import {OperatorMemberService} from 'app/+store/operator/member/member.service';
import * as permissionModel from '../../../../../+store/operator/member/member';
import {NaturalPerson} from 'app/+store/natural-person/natural-person';
import {Organization} from 'app/+store/organization/organization';
import {Observable} from "rxjs/internal/Observable";
import * as model from "../../../../../+store/operator/member/member";
import {of} from "rxjs/internal/observable/of";

export interface IMember {
  // NaturalPerson id, because it is first available.
  id: string;
  email: string;
  name: string;
  membershipId: string;
  isMe: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isProjectRoomAdmin: boolean;
  projectRoomAdminRoleId: string;
  isSelected: boolean;
  has2Fa: boolean;
  isWorkflowCreationEnabled: boolean;
  isSubWorkflowCreationEnabled: boolean;
  isFastdocsEnabled: boolean;
  isDigitalbarEnabled: boolean;
  isFivefAgentEnabled: boolean;
  isFivefDesktopEnabled: boolean;
  isDatevEnabled: boolean;
  isBookmanEnabled: boolean;
  isBigFilesEnabled: boolean;
  person: NaturalPerson
}

export class Member implements IMember {
  constructor(public id: string,
              public email: string,
              public name: string,
              public membershipId: string,
              public person: NaturalPerson,
              public isMe = false,
              public isOwner = false,
              public isAdmin = false,
              public isProjectRoomAdmin = false,
              public projectRoomAdminRoleId = null,
              public isSelected = false,
              public has2Fa = false,
              public isWorkflowCreationEnabled = false,
              public isSubWorkflowCreationEnabled = false,
              public isFastdocsEnabled = false,
              public isBigFilesEnabled = false,
              public isDigitalbarEnabled = false,
              public isFivefAgentEnabled = false,
              public isFivefDesktopEnabled = false,
              public isDatevEnabled = false,
              public isBookmanEnabled = false) {
  }
}

export class OrganizationEmployeeRepository {
  private onDestroy = new Subject();
  public data = new BehaviorSubject<Member[]>([]);
  private uid: string;
  private amIProjectRoomAdmin$ = new BehaviorSubject<boolean>(false);
  private membershipPerms$ = new BehaviorSubject<any>({});
  private member2FAStatusMap$ = new BehaviorSubject<any>({});
  private twoFAStatusLoading$ = new BehaviorSubject<boolean>(true);
  private projectRoomAdminMap$ = new BehaviorSubject<any>({});
  private searchTerm$ = new BehaviorSubject<string>('');
  private adminFilter$ = new BehaviorSubject<any>([]);
  private selectedMember: Member = null;
  private selectedMember$ = new BehaviorSubject<Member>(null);
  private organization: Organization;

  constructor(private _store: Store<AppState>,
              private _tokenSvc: AngularTokenService,
              private _memberSvc: OperatorMemberService,
              private _twoFactorSessionSvc: TwoFactorSessionService,
              private _processRoleSvc: ProcessRoleService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService,
              private _ngZone: NgZone) {
    this.uid = this._tokenSvc.currentAuthData.uid;
    this._init();
  }

  public destroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.member2FAStatusMap$.complete();
    this.twoFAStatusLoading$.complete();
    this.projectRoomAdminMap$.complete();
    this.membershipPerms$.complete();
    this.searchTerm$.complete();
    this.adminFilter$.complete();
    this.selectedMember$.complete();
  }

  public amIProjectRoomAdmin() {
    return this.amIProjectRoomAdmin$;
  }

  public selected$() {
    return this.selectedMember$.asObservable();
  }

  public getSelectedMember(_selectedMember): Observable<Member> {
    const selectedMember = this.data.value.find((_member) => _selectedMember && _selectedMember.id === _member.id);
    return this._memberSvc.getMembershipPermissions(this.organization.id, selectedMember.membershipId)
      .pipe(
        first(),
        switchMap((permissionsObject: model.Operator.MemberPermissions) => {
          if (!permissionsObject) {
            return of(null);
          }
          const mP = Object.assign({}, this.membershipPerms$.value);
          mP[permissionsObject.id] = permissionsObject;
          this.membershipPerms$.next(mP);
          const currentSelectedMember = selectedMember;
          const isAdmin = selectedMember.isAdmin || selectedMember.isOwner;
          currentSelectedMember.isProjectRoomAdmin = isAdmin && !!permissionsObject.projectRoomAdminRoleId;
          currentSelectedMember.projectRoomAdminRoleId = permissionsObject.projectRoomAdminRoleId;
          currentSelectedMember.isFastdocsEnabled = permissionsObject.fastdocsEnabled;
          currentSelectedMember.isDigitalbarEnabled = permissionsObject.digitalbarEnabled;
          currentSelectedMember.isFivefAgentEnabled = permissionsObject.fivefAgentEnabled;
          currentSelectedMember.isFivefDesktopEnabled = permissionsObject.fivefDesktopEnabled;
          currentSelectedMember.isDatevEnabled = permissionsObject.datevEnabled;
          currentSelectedMember.isBookmanEnabled = permissionsObject.bookmanEnabled;
          currentSelectedMember.isWorkflowCreationEnabled = permissionsObject.workflowCreationEnabled;
          currentSelectedMember.isSubWorkflowCreationEnabled = permissionsObject.subWorkflowCreationEnabled;

          const updatedMember = Object.assign({}, currentSelectedMember)
          this.selectedMember = updatedMember;
          this.selectedMember$.next(updatedMember);
          return of(updatedMember);
        }),
        catchError(err => {
          console.error(err);
          return of(null);
        }));
  }

  private _init() {
    const naturals$ = this._store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(map(persons => persons.sort(AddressBookTableHelper.sortByString)));
    const membershipMap$ = this._store.select(MembershipSelectors.getAllMemberships)
      .pipe(map((memberships) => {
        const membershipMap = {};
        memberships.forEach((membership) => {
          membershipMap[membership.id] = membership;
        });
        return membershipMap;
      }));

    let combined$ = combineLatest(naturals$, membershipMap$, this.member2FAStatusMap$, this.membershipPerms$)
      .pipe(
        map(([persons, membershipMap, twoFaStatusMap, membershipPermissionsMap]) => {
          return persons.map(person => {
            const member = new Member(person.id, person.mainEmailAddress.emailAddress, person.name, person.membershipId, person);
            const membership: Membership = membershipMap[member.membershipId];
            member.isMe = this.uid === member.email;

            let isAdmin = false;
            let isOwner = false;

            if (membership) {
              isAdmin = membership.isOrganizationalOwner || membership.hasAdministrationRights;
              isOwner = membership.isOrganizationalOwner;
            }
            member.isOwner = isOwner;
            member.isAdmin = isAdmin;

            member.has2Fa = !!twoFaStatusMap[member.email];

            const coreApiPerms: permissionModel.Operator.MemberPermissions = membershipPermissionsMap[member.membershipId]
            if (coreApiPerms) {
              const projectRoomAdminRoleId = coreApiPerms.projectRoomAdminRoleId;
              member.isProjectRoomAdmin = isAdmin && !!projectRoomAdminRoleId;
              member.projectRoomAdminRoleId = projectRoomAdminRoleId;

              member.isWorkflowCreationEnabled = coreApiPerms.workflowCreationEnabled;
              member.isSubWorkflowCreationEnabled = coreApiPerms.subWorkflowCreationEnabled;
              member.isFastdocsEnabled = coreApiPerms.fastdocsEnabled;
              member.isDigitalbarEnabled = coreApiPerms.digitalbarEnabled;
              member.isFivefAgentEnabled = coreApiPerms.fivefAgentEnabled;
              member.isFivefDesktopEnabled = coreApiPerms.fivefDesktopEnabled;
              member.isDatevEnabled = coreApiPerms.datevEnabled;
              member.isBookmanEnabled = coreApiPerms.bookmanEnabled;
              member.isBigFilesEnabled = coreApiPerms.bigFilesEnabled;
            }

            if (member.isMe && member.isProjectRoomAdmin) {
              this.amIProjectRoomAdmin$.next(true);
            }

            if (this.selectedMember && this.selectedMember.id === member.id) {
              this.selectedMember$.next(member);
            }

            return member;
          });
        }));

    combined$ = combineLatest(combined$, this.searchTerm$, this.adminFilter$)
      .pipe(
        map(([members, searchTerm, filterAdmin]) => {
          if (!searchTerm && (!filterAdmin || filterAdmin.length === 0)) {
            return members;
          }

          return members.filter((person) => {
            let found = true;
            if (filterAdmin && filterAdmin.length > 0) {
              found = false;

              filterAdmin.forEach(admFilter => {
                if (admFilter.id === '1' && (person.isAdmin || person.isOwner)) {
                  found = true;
                }

                if (admFilter.id === '2' && person.isProjectRoomAdmin) {
                  found = true;
                }
              })
            }

            if (searchTerm) {
              found = found && person.name.toLowerCase().indexOf(searchTerm) >= 0 || person.email.toLowerCase().indexOf(searchTerm) >= 0;
            }
            return found;
          });
        }));
    combined$.pipe(takeUntil(this.onDestroy)).subscribe((members) => this.data.next(members));
  }

  public search(searchTerm) {
    if (searchTerm && typeof searchTerm === 'string') {
      let q = searchTerm.trim();
      q = q.toLowerCase();
      this.searchTerm$.next(q);
    } else {
      this.searchTerm$.next('')
    }
  }

  public filterAdmin(filterValue) {
    this.adminFilter$.next(filterValue);
  }

  public fetch(organization): void {
    if (!organization) {
      console.error('OrganizationEmployeeRepository: Organization unset');
      return;
    }

    this.organization = organization;

    this._ngZone.runOutsideAngular(__ => {
      this._store.dispatch(new RoleActions.LoadAll());
      this._store.dispatch(new OrganizationActions.LoadDetailed(organization.id));
      this._store.dispatch(new LoadOfOrganization(organization.id));
      this._store.dispatch(new MembershipActions.LoadAll(organization.id));
      this._store.dispatch(new NaturalPersonActions.LoadMy);

      setTimeout(_ => this._loadTwoFaStatus());
      // setTimeout(_ => this._loadProjectRoomAdmins());
      setTimeout(_ => this._loadAllMembershipPermissions(organization));
    });
  }

  public updatePermissions(membershipId = null): void {
    if (!this.organization) {
      console.error('OrganizationEmployeeRepository: Organization unset');
      return;
    }
    if (membershipId) {
      setTimeout(_ => this._loadMembershipPermissions(this.organization, membershipId));
      return;
    }
    setTimeout(_ => this._loadAllMembershipPermissions(this.organization));
  }

  public get2FaLoadingState() {
    return this.twoFAStatusLoading$.asObservable();
  }

  private _loadTwoFaStatus() {
    this._ngZone.runOutsideAngular(___ => {
      this.twoFAStatusLoading$.next(true);
      this._twoFactorSessionSvc.memberStatus()
        .pipe(first())
        .subscribe((memberStatuses: TwoFactorMemberStatus[]) => {
          const member2FAStatusMap = {};
          memberStatuses.forEach(status => {
            // id of this return endpoint is the email
            member2FAStatusMap[status.id] = status.otpEnabled;
          });
          this.member2FAStatusMap$.next(member2FAStatusMap);
          this.twoFAStatusLoading$.next(false);
        }, err => {
          console.error(err);
          this.twoFAStatusLoading$.next(false);
        });
    });
  }

  private _loadAllMembershipPermissions(organization): void {
    setTimeout(_ => {
      this._ngZone.runOutsideAngular(__ => {
        this._memberSvc.getAllMembershipPermissions(organization.id)
          .pipe(first())
          .subscribe((permissions: any) => {
            const mP = {}
            permissions.forEach(element => {
              mP[element.id] = element;
            });
            this.membershipPerms$.next(mP);
          }, err => {
            console.error(err);
          });
      });
    });
  }

  private _loadMembershipPermissions(organization, membershipId): void {
    setTimeout(_ => {
      this._ngZone.runOutsideAngular(__ => {
        this._memberSvc.getMembershipPermissions(organization.id, membershipId)
          .pipe(first())
          .subscribe((permissionsObject: any) => {
            const mP = Object.assign({}, this.membershipPerms$.value);
            mP[permissionsObject.id] = permissionsObject;
            this.membershipPerms$.next(mP);
          }, err => {
            console.error(err);
            this._loadAllMembershipPermissions(organization);
          });
      });
    });
  }
}
