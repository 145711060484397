import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmitSuccessComponent} from './components/submit-success/submit-success.component';
import {SharedModule} from '../../lib/fivef-legacy/shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ExternalAccessService} from '../../+store/_legacy/api/services/external-access.service';
import {InfoTextComponent} from './components/info-text/info-text.component';
import {InfoComponent} from './containers/info/info.component';
import {IsAuthenticatedDialogComponent} from './components/is-authenticated-dialog/is-authenticated-dialog.component';
import {authenticatedccessRoutes} from './authenticated-access.routes';
import {AuthenticatedAccessDispatcherComponent} from './containers/authenticated-access-dispatcher/authenticated-access-dispatcher.component';
import {FivefAccessTokenNotFoundComponent} from '../../lib/fivef-ui/page/fivef-access-token-not-found/fivef-access-token-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    authenticatedccessRoutes,
    ReactiveFormsModule,
    SharedModule,
    FivefAccessTokenNotFoundComponent
  ],
  declarations: [
    AuthenticatedAccessDispatcherComponent,
    SubmitSuccessComponent,
    InfoTextComponent,
    InfoComponent,
    IsAuthenticatedDialogComponent
  ],
  providers: [
    ExternalAccessService
  ]
})
export class AuthenticatedAccessModule {
}
