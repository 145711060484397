<fivef-icon-message-block *ngIf="(!dataSource || !dataSource.data || dataSource.data?.length === 0) && showNoResult"
                          [icon]="noElementsIcon"
                          [message]="noElementsText"></fivef-icon-message-block>

<cdk-virtual-scroll-viewport class="fivef-tree--container fivef-tree--theme-{{theme}}"
                             [itemSize]="itemSize"
                             minBufferPx="400"
                             maxBufferPx="800"
                             #virtualScrollViewportRef>
  <ng-container *cdkVirtualFor="let node of dataSource; trackBy: trackBy">
    <div class="fivef-tree--node" [style.height]="itemSize + 'px'"
         [style.padding-left]="node.level * 18 + 'px'">

      <!-- Left tree expander button -->
      <button class="fivef-tree--toggle"
              [disabled]="!node.hasChildren"
              (click)="node.hasChildren && toggleExpand(node)"
              matRipple [matRippleDisabled]="!node.hasChildren">
        <mat-icon class="mat-icon-rtl-mirror"
                  [style.visibility]="node.hasChildren ? 'visible' : 'hidden'">
          {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>

      <!-- node template as projection. The wrapper allows to implement the click behavior
           by the underlying tree control. -->
      <div class="fivef-tree--node-wrapper"
           [class.fivef-tree--node--active]="isSelected(node)"
           (click)="selectNode(node, false)">
        <!-- Node template ref as structural directive -->
        <ng-container [ngTemplateOutlet]="treeNodeTpl.templateRef"
                      [ngTemplateOutletContext]="{$implicit: node}"></ng-container>
      </div>
    </div>
  </ng-container>
</cdk-virtual-scroll-viewport>
