<ng-template #iconTpl>
  <span class="fivef-nav-link--icon"
        [ngSwitch]="icon">
    <mat-icon *ngSwitchCase="'fivef_desktop'" svgIcon="fivef_desktop"></mat-icon>
    <mat-icon *ngSwitchCase="'datev_data_service_logo'" svgIcon="datev_data_service_logo"></mat-icon>
    <mat-icon *ngSwitchCase="'elo_dms'" svgIcon="elo_dms"></mat-icon>
    <mat-icon *ngSwitchCase="'account_tree'" svgIcon="account_tree"></mat-icon>
    <mat-icon *ngSwitchCase="'labels'" svgIcon="labels"></mat-icon>
    <mat-icon *ngSwitchCase="'fibu_workflow'" svgIcon="fibu_workflow"></mat-icon>
    <mat-icon *ngSwitchCase="'templates'" svgIcon="templates"></mat-icon>
    <mat-icon *ngSwitchCase="'lohn'" svgIcon="lohn"></mat-icon>
    <mat-icon *ngSwitchCase="'process'" svgIcon="projectroom"></mat-icon>
    <mat-icon *ngSwitchCase="'projectroom'" svgIcon="process"></mat-icon>
    <mat-icon *ngSwitchCase="'milestone'" svgIcon="timeline"></mat-icon>
    <mat-icon *ngSwitchCase="'tasks'" svgIcon="tasks"></mat-icon>
    <mat-icon *ngSwitchCase="'sign'" svgIcon="sign"></mat-icon>
    <mat-icon *ngSwitchCase="'third_party'" svgIcon="third_party"></mat-icon>
    <mat-icon *ngSwitchCase="'kanban'" svgIcon="kanban"></mat-icon>
    <mat-icon *ngSwitchCase="'manage_accounts'" svgIcon="manage_accounts"></mat-icon>
    <mat-icon *ngSwitchCase="'insertChartOutlined'" svgIcon="insertChartOutlined"></mat-icon>
    <mat-icon *ngSwitchCase="'newspaper'" svgIcon="newspaper"></mat-icon>
    <mat-icon *ngSwitchCase="'article'" svgIcon="article"></mat-icon>
    <mat-icon *ngSwitchCase="'monitor_heart'" svgIcon="monitor_heart"></mat-icon>
    <mat-icon *ngSwitchCase="'policy'" svgIcon="policy"></mat-icon>
    <mat-icon *ngSwitchCase="'recycling'" svgIcon="recycling"></mat-icon>
    <mat-icon *ngSwitchCase="'login'" svgIcon="login"></mat-icon>
    <mat-icon *ngSwitchDefault>{{icon}}</mat-icon>
  </span>
</ng-template>

<ng-template [ngIf]="callback" [ngIfElse]="routeByLink">
  <a class="fivef-nav-link fivef-nav-link--{{orientation}}"
     [matTooltip]="showOnlyIcons? (title | translate) : ''"
     href="#"
     (click)="$event.preventDefault(); callback()">

    <ng-template [ngTemplateOutlet]="iconTpl"></ng-template>

    <span *ngIf="!showOnlyIcons"
          class="fivef-nav-link--content">
      <span [matBadge]="count" [matBadgeColor]="infoLevel" matBadgeOverlap="false">
        <span class="mat-badge-title">{{ title | translate }}</span>
      </span>
    </span>
  </a>
</ng-template>

<ng-template #routeByLink>
  <a class="fivef-nav-link fivef-nav-link--{{orientation}}"
     (click)="checkLicense()"
     [matTooltip]="showOnlyIcons? (title | translate) : ''"
     [routerLinkActiveOptions]="{ exact: false }" [routerLink]="path"
     routerLinkActive="fivef-nav-link--active">

    <ng-template [ngTemplateOutlet]="iconTpl"></ng-template>

    <span *ngIf="!showOnlyIcons"
          class="fivef-nav-link--content">
      <span [matBadge]="count"
            [matBadgeColor]="infoLevel"
            matBadgeOverlap="false">
        <span class="mat-badge-title">{{ title | translate }}</span>
      </span>
    </span>
  </a>
</ng-template>
