import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Organization} from './organization';

export interface State extends EntityState<Organization> {
  selected?: string
  loading: boolean
}

export const adapter = createEntityAdapter<Organization>();

export const initialState: State = adapter.getInitialState({
  selected: undefined,
  loading: false,
});
