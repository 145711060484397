import {NgModule} from '@angular/core';
import {H1EditableComponent} from './containers/h1-editable/h1-editable.component';
import {TranslateModule} from '@ngx-translate/core';
import {TitleEditableComponent} from './containers/title-editable/title-editable.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

const EXPORTED_COMPONENTS = [
  H1EditableComponent,
  TitleEditableComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    ...EXPORTED_COMPONENTS
  ],
  exports: [
    ...EXPORTED_COMPONENTS
  ]
})
export class ContentEditableModule {
}
