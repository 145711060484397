import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {DmsContact} from './dms-contact';

export class DmsContactBuilder implements IApiResourceBuilder<DmsContact> {
  constructor() {}

  fromResponse(data): DmsContact {
    return new DmsContact(
      data.id,
      data.attributes.user_name,
      data.attributes.first_name,
      data.attributes.last_name,
      data.attributes.email);
  }

  toRequest(_: DmsContact) {
    return null;
  }
}
