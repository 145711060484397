import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './cms-slideshow';

export class CmsSlideBuilder implements IApiResourceBuilder<model.Cms.Slide> {
  fromResponse(data): model.Cms.Slide {
    return new model.Cms.Slide(
      data.id,
      data.attributes.slideshow_slide_id,
      data.attributes.profile,
      data.attributes.language,
      data.attributes.title,
      data.attributes.alt,
      data.attributes.order,
      data.attributes.background_image_url,
      data.attributes.background_image_filename,
      data.attributes.content,
      data.attributes.content_image_url,
      data.attributes.content_image_filename,
      data.attributes.content_image_width,
      data.attributes.content_image_height,
      data.attributes.content_image_map_areas,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(slide: model.Cms.Slide) {
    return {
      data: {
        id: slide.id,
        type: slide.type,
        attributes: {
          title: slide.title,
          alt: slide.alt,
          profile: slide.profile,
          language: slide.language,
          content: slide.content,
          content_image_width: slide.contentImageWidth,
          content_image_height: slide.contentImageHeight,
          content_image_map_areas: slide.contentImageMapAreas
        }
      }
    };
  }
}

export class CmsSlideshowBuilder implements IApiResourceBuilder<model.Cms.Slideshow> {
  fromResponse(data): model.Cms.Slideshow {
    const slideshow = new model.Cms.Slideshow(
      data.id,
      data.attributes.profile,
      data.attributes.title,
      data.attributes.description,
      data.attributes.created_at,
      data.attributes.updated_at);

    const slidesRes = data.attributes?.slides?.data;
    if (slidesRes?.length) {
      const slideBuilder = new CmsSlideBuilder();
      slideshow.slides = slidesRes.map(slide => {
        return slideBuilder.fromResponse(slide)
      });
    }

    return slideshow;
  }

  toRequest(slideshow: model.Cms.Slideshow) {
    return {
      data: {
        id: slideshow.id,
        type: slideshow.type,
        attributes: {
          title: slideshow.title,
          description: slideshow.description,
          profile: slideshow.profile
        }
      }
    };
  }
}
