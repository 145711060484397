import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {FavoriteBuilder, FavoriteV2Builder} from './favorite.builder';
import {Favorite, FavoriteV2} from './favorite';

@Injectable()
export class FavoriteService {
  readonly BASE_PATH = 'api/v1/favorites';
  readonly BASE_PATH_V3 = 'api/v3/favorites';
  builder: FavoriteBuilder;

  constructor(private _http: FivefApiResourceService) {
    this.builder = new FavoriteBuilder();

  }

  getOne(processId: string): Observable<Favorite> {
    return <Observable<Favorite>>this._http.get<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${processId}`);
  }

  getAll(): Observable<Favorite[]> {
    return <Observable<Favorite[]>>this._http.get<FavoriteBuilder, Favorite>(this.builder, this.BASE_PATH);
  }

  /**
   * Uses the new minimal Favorite v3 API developed for the desktop client.
   * Returns all favorites without unconfirmed ones.
   * Includes state and client.
   */
  getAllv3(): Observable<FavoriteV2[]> {
    const builder = new FavoriteV2Builder();
    return <Observable<FavoriteV2[]>>this._http.get<FavoriteV2Builder, FavoriteV2>(builder, this.BASE_PATH_V3);
  }

  confirm(favoriteId: string): Observable<Favorite> {
    return <Observable<Favorite>>this._http.post<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${favoriteId}/confirm`, {});
  }

  update(favorite: Favorite): Observable<Favorite> {
    const payload = this.builder.toRequest(favorite);
    return <Observable<Favorite>>this._http.put<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${favorite.id}`, payload);
  }

  remove(id: string): Observable<Favorite> {
    return this._http.del<FavoriteBuilder, Favorite>(this.builder, `${this.BASE_PATH}/${id}`);
  }

}
