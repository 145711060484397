<span class="fivef-status-list-item--icon">
  <ng-template [ngIf]="!isErrorState" [ngIfElse]="errorIconTpl">
    <mat-icon [class.fivef-color-status-success]="!inProgress"
              [class.fivef-color-status-default]="inProgress">
      {{ !inProgress ? 'check_circle' : 'radio_button_unchecked' }}
    </mat-icon>
  </ng-template>

  <ng-template #errorIconTpl>
    <mat-icon class="fivef-color-status-danger">cancel</mat-icon>
  </ng-template>
</span>

<div class="fivef-status-list-item--title">
  <ng-template [ngIf]="inProgress" [ngIfElse]="completedTpl">
    <span class="dvtx-loading-indicator">
        {{ progressTitle | translate }}<span>.</span><span>.</span><span>.</span>
    </span>
  </ng-template>

  <ng-template #completedTpl>
    <ng-template [ngIf]="!isErrorState" [ngIfElse]="errorTitleTpl">
      <span *ngIf="successPrefix">{{ successPrefix | translate }}:&nbsp;</span>
        {{ successTitle | translate }}
    </ng-template>

    <ng-template #errorTitleTpl>
      <span *ngIf="errorPrefix">{{ errorPrefix | translate }}:&nbsp;</span>
      {{ errorTitle | translate }}
    </ng-template>
  </ng-template>

  <ng-content></ng-content>
</div>
