import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {startWith, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {CommonModule} from '@angular/common';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'fivef-pdf-viewer',
  standalone: true,
  imports: [CommonModule, NgxExtendedPdfViewerModule],
  styleUrls: ['./fivef-pdf-viewer.component.scss'],
  templateUrl: './fivef-pdf-viewer.component.html',
})
export class FivefPdfViewerComponent implements OnInit {
  private onDestroy = new Subject<void>();

  @ViewChild('pdfViewer') public pdfViewer: NgxExtendedPdfViewerComponent;

  private base64Content$ = new BehaviorSubject<string>(null);
  public src: Uint8Array;
  public lang: string = 'de';

  @Input()
  public enableDownload = false;

  @Input()
  public showEditingMode = false;

  @Input() set content(content) {
    this.base64Content$.next(content);
  }

  constructor(private _cdr: ChangeDetectorRef, private _translateSvc: TranslateService) {
  }

  public ngOnInit(): void {
    this.base64Content$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((content) => {
        setTimeout(_ => {
          if (content) {
            const pdfAsArray = this.convertDataURIToBinary(content);
            this.src = pdfAsArray;
            this._cdr.detectChanges();
          }
        });
      });
  }

  private convertDataURIToBinary(base64): Uint8Array {
    // const base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    // const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  onPdfLoad() {
    this._translateSvc.onLangChange.pipe(startWith({lang: this._translateSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => {
      this.lang = lang.lang;
      this.pdfViewer.language = this.lang;
    });
  }

  saveNewPdf($event: any, src: any) {
    const value: any = this.pdfViewer.src;
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onloadend = function () {
      const base64data = reader.result;
      console.log(base64data);
    }
  }
}
