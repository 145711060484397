import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './user.interface';
import * as model from './user';
import * as build from './user.builder';
import {SignatureFrameFormat, VisualSignature} from '../../document-signature/document-signature';
import {VisualSignatureBuilder} from '../../document-signature/document-signature.builder';

@Injectable()
export class OperatorUserService {
  readonly BASE_PATH = 'operator/api/v1/user/accounts';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(params: modelInterface.Operator.IUserGetAllParams): Observable<model.Operator.User[]> {
    const page = params.page;
    const builder = new build.Operator.UserBuilder();
    let query = `?all=${!!params.all}`;
    if (page && page > 0) {
      query = `${query}&page=${params.page}`
    }

    if (params.lockedOnly) {
      query = `${query}&locked_only=${!!params.lockedOnly}`
    }

    if (params.filters) {
      query = `${query}&f=${params.filters}`
    }

    if (params.query && params.query.length > 0) {
      query = `${query}&q=${params.query}`
    }

    if (params.fiveFOperators) {
      query = `${query}&ff=true`
    }
    return <Observable<model.Operator.User[]>>this._http.get<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}${query}`);
  }

  getOne(id: string): Observable<model.Operator.User> {
    const builder = new build.Operator.UserBuilder();
    return <Observable<model.Operator.User>>this._http.get<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${id}`);
  }

  create(params: modelInterface.Operator.IUserParams): Observable<model.Operator.User> {
    const builder = new build.Operator.UserBuilder();
    const payload = {
      data: {
        attributes: params
      }
    };
    return <Observable<model.Operator.User>>this._http.post<build.Operator.UserBuilder, model.Operator.User>(builder, this.BASE_PATH, payload);
  }

  update(user: model.Operator.User): Observable<model.Operator.User> {
    const builder = new build.Operator.UserBuilder();
    const payload = builder.toRequest(user);
    return <Observable<model.Operator.User>>this._http.put<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${user.id}`, payload);
  }

  destroy(userId: string): Observable<model.Operator.User> {
    const builder = new build.Operator.UserBuilder();
    return <Observable<model.Operator.User>>this._http.del<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${userId}`);
  }

  changePassword(user: model.Operator.User, password): Observable<model.Operator.User> {
    const builder = new build.Operator.UserBuilder();
    const payload = {
      data: {
        attributes: {
          password: password
        }
      }
    }
    return <Observable<model.Operator.User>>this._http.post<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${user.id}/change_password`, payload);
  }

  setTrial(userId, trialDate) {
    const builder = new build.Operator.UserBuilder();
    const payload = { data: { attributes: { trial_date: trialDate } } };
    return <Observable<model.Operator.User>>this._http.post<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${userId}/trial`, payload);
  }

  resetTrial(userId) {
    const builder = new build.Operator.UserBuilder();
    return <Observable<model.Operator.User>>this._http.del<build.Operator.UserBuilder, model.Operator.User>(builder, `${this.BASE_PATH}/${userId}/trial`);
  }

  getStats(startAt: Date = null) {
    const builder = new build.Operator.UserStatsBuilder();
    const payload = { data: { attributes: { start_at: startAt } } };
    return <Observable<model.Operator.UserStats>>this._http.post<build.Operator.UserStatsBuilder, model.Operator.UserStats>(builder, 'operator/api/v1/user/stats', payload);
  }

  getDefaultSignature(id, apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    return <Observable<VisualSignature>>this._http.get<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/${id}/default_signature?type=${apiMode}`);
  }

  deleteDefaultSignature(id, apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    return <Observable<VisualSignature>>this._http.del<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/${id}/default_signature?type=${apiMode}`);
  }

  updateDefaultSignature(id, base64Content: string, apiMode: SignatureFrameFormat = 'signature'): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    const payload = {
      data: {
        attributes: {
          content: base64Content
        }
      }
    };
    return <Observable<VisualSignature>>this._http.put<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/${id}/default_signature?type=${apiMode}`, payload);
  }

  toggleSkipMetaInfo(id, skipMetaInfo: boolean): Observable<VisualSignature> {
    const builder = new VisualSignatureBuilder();
    const payload = {
      data: {
        attributes: {
          skip_meta_info: skipMetaInfo
        }
      }
    };
    return <Observable<VisualSignature>>this._http.put<VisualSignatureBuilder, VisualSignature>(builder, `${this.BASE_PATH}/${id}/default_signature/skip_meta_info`, payload);
  }
}
