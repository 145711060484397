import {IResource} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {SpecializedAppendix} from './appendix';


export class ContactImportItem implements IResource {
  readonly type = 'contact_import_item';

  constructor(
    public id: number,
    public identifier: string,
    public abbreviation: string,
    public organizationName: string,
    public countryCode: string,
    public firstName: string,
    public lastName: string,
    public role: string,
    public email: string,
    public phone: string,
    public mobile: string,
    public verifiedAt: Date,
    public deletedAt: Date,
    public verifiedById: number,
    public dueDate: Date,
    public notificationLanguage: string,
    public modifiedByTbpAt: string,
    public modifiedByTbpName: string,
    public reviewerFirstName,
    public reviewerLastName,
    public reviewerConfirmed: string,
    public appendices: SpecializedAppendix[],
    public appendicesIds: string[],
    public createdByEmail: string,
    public createdByFirstName: string,
    public createdByLastName: string,
    public createdByExternalAt: Date,
    public lastUpdatedByEmail: string,
    public lastUpdatedByFirstName: string,
    public lastUpdatedByLastName: string,
    public comment: string,
    public createdAt: Date,
    public updatedAt: Date) {

  }

  toForm() {
    return undefined;
  }
}

