<fivef-context-sidebar *ngIf="(client$ | async) as client"
                       [nodeId]="client?.id"
                       [title]="client?.name"
                       [subtitle]="client?.clientId">
  <mat-tab-group mat-stretch-tabs
                 fivefContextSidebarContent
                 disablePagination
                 [selectedIndex]="selectedTab"
                 (selectedIndexChange)="selectedTabChanged($event)">
    <mat-tab [label]="'SETTINGS.SETTINGS' | translate">
      <ng-template matTabContent>
        <fivef-create-client [position]="'start'"
                             [readonly]="!client?.canEditClient"
                             (onEditSuccess)="editSuccess($event)"
                             [appearance]="ClientCreateActionAppearance.Form"
                             [selectedClient]="client"
                             (onFormUpdate)="updateActionButtons($event)"
                             #clientSelectionComponent></fivef-create-client>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ 'CONTACTS.ASSOCIATED_CONTACTS' | translate }}</span>
      </ng-template>
      <ng-template matTabContent>
        <fivef-client-contact-listing [selectedClient]="client"
                                      [isClient]="true"
                                      (updateMapping)="updateMapping($event)"></fivef-client-contact-listing>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="showFibu"
             [label]="'FIBU.CLIENT_SETTINGS' | translate">
      <ng-template matTabContent>
        <fivef-bookman-client-settings (onSave)="updateBookmanSettings($event)" [id]="client?.id"></fivef-bookman-client-settings>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="(featureSet$ | async)?.hasDatev"
             [label]="'DATEV.AUTHORIZE_CLIENT_TITLE' | translate">
      <ng-template matTabContent>
        <fivef-datev-client-authorization *ngIf="client$ | async" [client]="client$ | async"></fivef-datev-client-authorization>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div fivef-context-sidebar-footer>
    <button *ngIf="selectedTab === 0" mat-raised-button
            color="primary"
            [disabled]="form?.pristine || form?.invalid"
            (click)="saveClient()">
      <mat-icon matPrefix>save</mat-icon>
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>

    <button *ngIf="!selectedTab === 0" type="button" mat-button mat-dialog-close>
      {{ 'GENERAL.CLOSE_ACTION' | translate }}
    </button>
  </div>
</fivef-context-sidebar>
