<fivef-dialog [isCallToAction]="true" [title]="'GENERAL.CREATE_SIGNATURE'">
  <dvtx-signature-box (window:resize)="onResize()" [boxWidth]="signatureBoxWidth" #signatureBox></dvtx-signature-box>

  <div fivef-dialog-footer>
    <button type="reset" mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button mat-raised-button color="primary"
            (click)="saveSignature()">{{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
