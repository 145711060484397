/**
 * Notification module for local and global message handling.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 */
import {NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FivefSnackbarMessageContentComponent} from './fivef-snackbar-message-content/fivef-snackbar-message-content.component';
import {FivefSnackbarMessageComponent} from './fivef-snackbar-message/fivef-snackbar-message.component';
import {FivefNotificationService} from './fivef-notification.service';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    TranslateModule
  ],
  declarations: [
    FivefSnackbarMessageComponent,
    FivefSnackbarMessageContentComponent
  ],
  providers: [
    FivefNotificationService
  ]
})
export class FivefNotificationModule {
}
