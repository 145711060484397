import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from './app.state';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {SystemService} from 'app/services/system.service';
import {TranslateService} from '@ngx-translate/core';
import {VersionCheckService} from './services/version-check.service';
import {IconLoaderService} from './services/icon-loader.service';
import {FeatureActions, OrganizationActions, OrganizationSelectors} from './+store';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

/**
 * Root component loaded after bootstrap.
 *
 * Dev notes.
 * Sceen resolution is currently unused.
 * Code:
 *    import {Component, HostListener, OnDestroy} from '@angular/core';
 *    import {ScreenResize} from './actions/screen.actions';
 *    @HostListener('window:resize')
 *    onresize() {
 *      this._store.dispatch(new ScreenResize(window.innerWidth));
 *    }
 */
@Component({
  selector: 'dvtx-app',
  styleUrls: [
    './app.component.scss'
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  error: any;

  /**
   * User session state initialization.
   * @private
   */
  private userSessionInitialized: boolean = false;
  private refreshFeatureSet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private previousSelectedOrganizationId: string = null;

  constructor(private _store: Store<AppState>,
              private _translate: TranslateService,
              private _cdr: ChangeDetectorRef,
              private _systemSvc: SystemService,
              private _iconSvc: IconLoaderService,
              private _versionCheckSvc: VersionCheckService,
              private _ngZone: NgZone) {
    // Configures tenant dependent visual properties (theme, browser title, favicon).
    _systemSvc.configureTenant();

    this._store.select('title')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(title => {
        document.title = title['title'];
      });

    this._translate.setDefaultLang('de');
    this._translate.use('de');

    // Store debugging.
    // setInterval(_ => {
    //   this._store.pipe(take(1)).subscribe(s => console.error('Current store snapshot', s));
    // }, 3000);
  }

  ngOnInit() {
    // Rechecks the app's version every 30 minutes. On a new version the user can reload the app by browser refresh
    // from the user profile's menu.
    this._initUpdateAvailabilityCheck();

    // Load custom SVG icons for Material Icon theme.
    this._iconSvc.init();

    // TODO: refactor to the main app component
    this._systemSvc.getStatus().pipe(takeUntil(this.onDestroy)).subscribe((status) => {
    }, (error) => {
      this.error = error.status;
      // error is view public but invoked async.
      // This can create ExpressionChangedAfterItHasBeenCheckedError without detectChanges.
      this._cdr.detectChanges();
    });
    this._init()
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.refreshFeatureSet$.complete();
  }

  /**
   * Initializes the user session.
   *
   * init() has two responsibilities:
   * 1. Ensure that the organization are loaded to have the selection state of getSelected set
   *    to one organization.
   * 2. Depending on the organization the feature set is loaded and initialized for further authorization.
   * @private
   */
  private _init() {
    const currentUser$ = this._store.select('currentUser');
    const currentOrg$ = this._store.select(OrganizationSelectors.getSelected);

    currentUser$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((user) => {
        // Requirement 1: Sign out state
        // Correct logged out state with removing session data.
        // 1. There is no previous organization
        // 2. The featureSet is restted.
        // 3. The user session is invalid.
        // If no user, then reset the session and the feature set.
        if (!user) {
          this.userSessionInitialized = false;
          this.previousSelectedOrganizationId = null;
          this.refreshFeatureSet$.next(false);
          return;
        }

        // Requirement 2: Signed in state: Correct user session initialization.
        // Conditions:
        // 1. If user is present the first time after login then load all organizations.
        // 2. Ensure that all organizations are not loaded multiple times during an existing session.
        // 3. Ensure the call is not executed if the user has signed out (user === null).
        if (!this.userSessionInitialized && user) {
          this._store.dispatch(new OrganizationActions.LoadAll());
          this.userSessionInitialized = true;
        }
      });

    // Requirement 3: Ensure feature set is renewed.
    // Condition 1: Logged in.
    // Condition 2: getSelected changes.
    // Case 1: Sign in.
    //         previousSelectedOrganizationId must be null => organization.id != previousSelectedOrganizationId
    //         => Set RefreshFeatureSet to true.
    // Case 2: GetSelected returns same organization but with different object reference.
    //         => Don't touch refreshFeatureSet
    // Case 3: GetSelected returns another organization.
    //         => Set RefreshFeatureSet to true.
    currentOrg$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((currentOrganization) => {
        if (currentOrganization && currentOrganization.id !== this.previousSelectedOrganizationId) {
          this.previousSelectedOrganizationId = currentOrganization.id;
          this.refreshFeatureSet$.next(true);
        }
      });

    // Requirement 4: Correct feature set initialization.
    // Ensure that the combineLatest is always run after
    // - refreshFeatureSet changes
    // - currentUser has changed
    // - currentOrganization has changed
    combineLatest(this.refreshFeatureSet$, currentUser$, currentOrg$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([refreshFeatureSet, currentUser, currentOrganization]) => {
        // Requirements:
        // 1. The user must be initialized aka present.
        // 2. the selected organization must be present.
        // 3. feature set was not already loaded for the currentOrg.
        if (refreshFeatureSet && currentUser && currentOrganization) {
          this._ngZone.runOutsideAngular(() => {
            this._store.dispatch(new FeatureActions.LoadAll());

            this.refreshFeatureSet$.next(false);
          });
        }
      });
  }

  /**
   * Rechecks the app's version every 30 minutes.
   *
   * @private
   */
  private _initUpdateAvailabilityCheck() {
    try {
      this._versionCheckSvc.initVersionCheck(window.location.protocol + '//' + window.location.host, 30 * 6000);
    } catch (e) {
      console.error(e);
    }
  }
}
