import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Sorters } from "app/lib/sorter/sorters";
import { Fastdocs } from "app/+store/fastdocs/fastdocs";

@Injectable()
export class FastdocsActionsService {
  sortItems(items: Fastdocs[], sort: Sort, isAsc) {
    switch (sort.active) {
      case 'fastdocsClientCol':
        items = items.sort((a, b) => {
          return Sorters.sort(a.process.client ? a.process.client.name : '', b.process.client ? b.process.client.name : '', isAsc)
        });
        break;
      case 'fastdocsStatusCol':
        items = items.sort((a, b) => {
          return Sorters.sort(a.fiveFStatus ? a.fiveFStatus.title : '', b.fiveFStatus ? b.fiveFStatus.title : '', isAsc)
        });
        break;
      case 'fastdocsStartAtCol':
        items = items.sort((a, b) => Sorters.sortByDates(new Date(a.dates.startsAt), new Date(b.dates.startsAt), isAsc));
        break;
      case 'fastdocsDaysCol':
        items = items.sort((a, b) => Sorters.sort(a.duration, b.duration, isAsc));
        break;
      case 'fastdocsItemStatusCol':
        // TODO 
        // items = items.sort((a, b) => {
        //   return Sorters.sort(a.fastdocsStatus ? a.fastdocsStatus.title : '', b.fastdocsStatus ? b.fastdocsStatus.title : '', isAsc)
        // });
        break;

      case 'fastdocsParticipantsCol':
        items = items.sort((a, b) => Sorters.sort(a.participants ? a.participants.length : 0, b.participants ? b.participants.length : 0, isAsc));
        break;
    }

    return items;
  }
}
