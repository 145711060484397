import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {LicenceAssignment} from 'app/+store/_legacy/api/models/licence';

export enum LicenceAssignmentActionTypes {
  Add = '[LicenceAssignment] Add',
  AddSuccess = '[LicenceAssignment] Add Success',
  LoadAll = '[LicenceAssignment] Load All',
  LoadAllSuccess = '[LicenceAssignment] Load All Success',
  LoadAllFail = '[LicenceAssignment] Load All Fail',
  LoadAttachedOrganizations = '[LicenceAssignment] Load Attached Organizations',
  LoadAttachedOrganizationsSuccess = 'Load Attached Organizations Success',
  LoadAttachedOrganizationsFail = '[LicenceAssignment] Load Attached Organizations Fail',
  Remove = '[LicenceAssignment] Remove',
  RemoveSuccess = '[LicenceAssignment] Remove Success',
}

export class Add implements Action {
  readonly type = LicenceAssignmentActionTypes.Add;

  constructor(public payload: any) {
  }
}

export class AddSuccess implements Action {
  readonly type = LicenceAssignmentActionTypes.AddSuccess;

  constructor(public payload: LicenceAssignment) {
  }
}

export class LoadAll implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAllSuccess;

  constructor(public payload: LicenceAssignment[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAllFail;

  constructor(public payload: any = null) {
  }
}

export class LoadAttachedOrganizations implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAttachedOrganizations;
}

export class LoadAttachedOrganizationsSuccess implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAttachedOrganizationsSuccess;

  constructor(public organizations: any) {
  }
}

export class LoadAttachedOrganizationsFail implements Action {
  readonly type = LicenceAssignmentActionTypes.LoadAttachedOrganizationsFail;

  constructor(public organizations: any = null) {
  }
}

export class Remove implements Action {
  readonly type = LicenceAssignmentActionTypes.Remove;

  constructor(public organizationId: string, public membershipId: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = LicenceAssignmentActionTypes.RemoveSuccess;

  constructor(public payload: any) {
  }
}

export type LicenceAssignmentActions =
  | Add
  | AddSuccess
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAttachedOrganizations
  | LoadAttachedOrganizationsSuccess
  | LoadAttachedOrganizationsFail
  | Remove
  | RemoveSuccess;
