import {ApplicableState} from '../../../../+store/cac/cac.interface';

/**
 * Simple status interface providing a text message icon and color.
 */
export interface IFivefIconColorStatus {
  /**
   * Status identifier.
   */
  id: string;

  /**
   * Status message.
   */
  title: string;

  /**
   * Status color.
   */
  color: string;

  /**
   * The icon is optional. Task status do not support a status icon because these
   * status types are dynamic.
   */
  icon?: string;
}

export const PRIORITIES_COLORS = {
  highest: 'var(--fivef-color-priority-highest)',
  high: 'var(--fivef-color-priority-high)',
  medium: 'var(--fivef-color-priority-medium)',
  low: 'var(--fivef-color-priority-low)',
  lowest: 'var(--fivef-color-priority-lowest)',
}

export const PRIORITIES_MAP: { [id: string]: IFivefIconColorStatus } = {
  highest: {
    id: 'highest',
    title: 'TASK.PRIORITY.HIGHEST',
    color: PRIORITIES_COLORS.highest,
    icon: 'arrow_upward'
  },
  high: {
    id: 'high',
    title: 'TASK.PRIORITY.HIGH',
    color: PRIORITIES_COLORS.high,
    icon: 'call_made'
  },
  medium: {
    id: 'medium',
    title: 'TASK.PRIORITY.MEDIUM',
    color: PRIORITIES_COLORS.medium,
    icon: 'arrow_forward'
  },
  low: {
    id: 'low',
    title: 'TASK.PRIORITY.LOW',
    color: PRIORITIES_COLORS.low,
    icon: 'call_received'
  },
  lowest: {
    id: 'lowest',
    title: 'TASK.PRIORITY.LOWEST',
    color: PRIORITIES_COLORS.lowest,
    icon: 'arrow_downward'
  }
}

export const PRIORITIES: IFivefIconColorStatus[] = [
  PRIORITIES_MAP.highest,
  PRIORITIES_MAP.high,
  PRIORITIES_MAP.medium,
  PRIORITIES_MAP.low,
  PRIORITIES_MAP.lowest
];

export const APPLICABLE_COLORS = {
  yes: 'var(--fivef-color-applicable)',
  no: 'var(--fivef-color-not-applicable)',
}

export const APPLICABLE_MAP: { [id: string]: IFivefIconColorStatus } = {
  'WORKFLOWS.STATUS.APPLICABLE': {
    id: ApplicableState.Applicable,
    title: 'GENERAL.YES',
    color: APPLICABLE_COLORS.yes,
    icon: ''
  },
  'WORKFLOWS.STATUS.NOT_APPLICABLE': {
    id: ApplicableState.NotApplicable,
    title: 'GENERAL.NO',
    color: APPLICABLE_COLORS.no,
    icon: ''
  }
}

export const APPLICABLE_STATES: IFivefIconColorStatus[] = [
  APPLICABLE_MAP[ApplicableState.Applicable],
  APPLICABLE_MAP[ApplicableState.NotApplicable],
];
