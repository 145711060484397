import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './workflow-template.state';
import {AppState} from "../../app.state";
import {TemplateType, TemplateVisibility} from "./workflow-template";

export const stateKey = 'workflow-template';
const getWorkflowTemplateState = createFeatureSelector<State>(stateKey);

export const currentUser = (state: AppState) => state.currentUser;

export const {
  selectEntities: getWorkflowTemplateEntities,
  selectAll: getAllWorkflowTemplates,
} = adapter.getSelectors(getWorkflowTemplateState);

export const getSelected = createSelector(
  getWorkflowTemplateState,
  (state) => state.selected
);

export const getAll = createSelector(
  getAllWorkflowTemplates,
  (templates) => templates
);

export const getAllProjectTemplates = createSelector(
  getAllWorkflowTemplates,
  (templates) => templates.filter(t => t.templateType === TemplateType.Project)
);

export const getUserTemplates = createSelector(
  currentUser,
  getAllWorkflowTemplates,
  (currentUser, templates) => templates.filter(template =>
    template.visibility === TemplateVisibility.Personal && template.creator === currentUser.uid)
);

export const getGlobalOrgTemplates = createSelector(
  getAllWorkflowTemplates,
  (templates) =>
    templates.filter(template => template.visibility === TemplateVisibility.Global)
);

export const getTemplatesForUserView = createSelector(
  getGlobalOrgTemplates,
  getUserTemplates,
  (orgTemplates, userTemplates) => orgTemplates.concat(userTemplates)
);

export const getCollectoTemplatesForUserView = createSelector(
  getTemplatesForUserView,
  (templates) => templates.filter(t => t.templateType === TemplateType.Collecto)
);

export const getProjectTemplatesForUserView = createSelector(
  getTemplatesForUserView,
  (templates) => templates.filter(t => t.templateType === TemplateType.Project)
);

export const getOne = (id: string) => createSelector(
  getAllWorkflowTemplates,
  (templates) => templates.find(t => t.id === id)
);


export const loadingState = createSelector(
  getWorkflowTemplateState,
  (state) => state.loading
);


