import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {RegistrationUser} from '../fivef-session-registration/fivef-session-registration.component';
import {FivefSessionRegistrationService} from '../fivef-session-registration/fivef-session-registration.service';
import {FivefInputFieldType} from '../../../../lib/fivef-ui/input/fivef-input.interface';

@Component({
  selector: 'fivef-session-registration-form',
  host: {class: 'fivef-session-registration-form'},
  templateUrl: './fivef-session-registration-form.component.html',
  styleUrls: ['./fivef-session-registration-form.component.scss']
})

export class FivefSessionRegistrationFormComponent implements OnInit, OnChanges {
  @Input()
  public user: RegistrationUser;

  @Output()
  private onNext = new EventEmitter();

  public PASSWORD_MIN_LENGTH = 8;
  public form: UntypedFormGroup;
  public verifyPasswordResponse: any;
  public showPassword: string = FivefInputFieldType.Password;
  public showConfirmPassword: string = FivefInputFieldType.Password;

  readonly FivefInputFieldType = FivefInputFieldType;

  constructor(private formBuilder: UntypedFormBuilder,
              private registrationSvc: FivefSessionRegistrationService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes.user.currentValue) {
      this.createForm();
    }
  }

  private createForm() {
    const noWhiteSpace = new RegExp(/^\S*$/);
    const group = {
      first_name: [this.user.firstName, Validators.required],
      last_name: [this.user.lastName, Validators.required],
      password: [this.user.password, [
        Validators.required,
        Validators.minLength(this.PASSWORD_MIN_LENGTH),
        Validators.pattern(this.registrationSvc.passwordPatternExpr),
        Validators.pattern(noWhiteSpace),
      ]],
      passwordConfirm: [this.user.passwordConfirm, [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      terms: [this.user.terms, Validators.required]
    };

    this.form = this.formBuilder.group(
      group,
      {
        validator: this.matchPassword
      });

  }

  private matchPassword(ctrl: AbstractControl) {
    const password = ctrl.get('password').value; // to get value in input tag
    const passwordConfirm = ctrl.get('passwordConfirm').value; // to get value in input tag
    if (password !== passwordConfirm || !password) {
      ctrl.get('passwordConfirm').setErrors({MatchPassword: true});
    } else {
      ctrl.get('passwordConfirm').setErrors(null);
      return null;
    }
  }

  public get passwordform() {
    return this.form.get('password');
  }

  public get passwordConfirmform() {
    return this.form.get('passwordConfirm');
  }

  public verifyPassword(password: string) {
    this.registrationSvc.verifyPassword(password).subscribe(response => {
      this.verifyPasswordResponse = response;
    });
  }

  public submit(): void {
    // if (this.form.invalid) return;
    const values = this.form.value;
    const updatedUser = <RegistrationUser>values;
    console.error(values);
    console.error(updatedUser);

    updatedUser.email = this.user.email;
    updatedUser.firstName = values.first_name;
    updatedUser.lastName = values.last_name;
    updatedUser.confirmationToken = this.user.confirmationToken;
    this.onNext.emit(updatedUser);
  }

  public readTerms(event: MatCheckboxChange): void {
    this.form.controls.terms.setValue(event.checked);
    this.form.controls.terms.updateValueAndValidity();
  }

  public togglePasswordVisibility() {
    this.showPassword = this.showPassword === FivefInputFieldType.Text ? FivefInputFieldType.Password : FivefInputFieldType.Text;
    this.cdr.detectChanges();
  }

  public toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = this.showConfirmPassword === FivefInputFieldType.Text ? FivefInputFieldType.Password : FivefInputFieldType.Text;
    this.cdr.detectChanges();
  }
}

export enum fieldType {
  Text = 'text',
  Password = 'password',
}
