import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DocumentPreviewPreviewActionTypes,
  RequestPreview,
  RequestPreviewFail,
  RequestPreviewSuccess
} from './document-preview-preview.actions';
import {DocumentPreviewPreview} from './document-preview-preview';
import {DocumentPreviewPreviewService} from './document-preview-preview.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class DocumentPreviewPreviewEffects {
  requestPreview$ = createEffect(() => this.actions.pipe(
    ofType(DocumentPreviewPreviewActionTypes.RequestPreview),
    switchMap((action: RequestPreview) => {
      return this._svc.getOne(action.document_id, action.preview_id)
        .pipe(
          first(),
          concatMap((res: DocumentPreviewPreview) => {
            return [new RequestPreviewSuccess(res)];
          }),
          catchError(err => {
            console.error(err);
            return of(new RequestPreviewFail(err));
          }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: DocumentPreviewPreviewService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
