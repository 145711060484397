import {Component, OnDestroy, OnInit} from '@angular/core';
import {startWith, takeUntil} from 'rxjs/operators';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fivef-page-not-found',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './fivef-page-not-found.component.html',
  styleUrls: ['./fivef-page-not-found.component.scss']
})
export class FivefPageNotFoundComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();
  public lang = 'de';

  constructor(private _i18nSvc: TranslateService) {
    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang)
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
