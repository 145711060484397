<div class="row">
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [routerLink]="'/user-profile/user'"
    [title]="'USER_NAVIGATON.PROFILE' | translate"
    [subtext]="'USER_NAVIGATON.PROFILE_SUBTITLE' | translate"
    [icon]="'account_box'"
    [colorProfile]="'user'"></fivef-settings-panel>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [routerLink]="'/user-profile/security'"
    [title]="'USER_NAVIGATON.SECURITY' | translate"
    [subtext]="'USER_NAVIGATON.SECURITY_SUBTITLE' | translate"
    [icon]="'lock'"
    [colorProfile]="'user'"></fivef-settings-panel>
  <ng-container *ngIf="(featureSet$ | async)?.hasMyStorage">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [routerLink]="'/user-profile/dms'"
      [title]="'USER_NAVIGATON.DMS' | translate"
      [subtext]="'USER_NAVIGATON.DMS_SUBTITLE' | translate"
      [icon]="'folder_open'"
      [colorProfile]="'user'"></fivef-settings-panel>
  </ng-container>

  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [routerLink]="'/user-profile/signature/account'"
    [title]="'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS' | translate"
    [subtext]="'SIGNATURE.ACCOUNT.ACCOUNT_SETTINGS_SUBTITLE' | translate"
    [icon]="'sign'"
    [colorProfile]="'user'"></fivef-settings-panel>

  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [routerLink]="'/messages/notification'"
    [title]="'USER_NAVIGATON.NOTIFICATIONS' | translate"
    [subtext]="'USER_NAVIGATON.NOTIFICATIONS_SUBTITLE' | translate"
    [icon]="'notifications'"
    [colorProfile]="'user'"></fivef-settings-panel>

  <ng-container *ngIf="hasMobileAppAccess">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [routerLink]="'/user-profile/mobile'"
      [title]="'MOBILE_LOGIN.TITLE' | translate"
      [subtext]="'MOBILE_LOGIN.SUBTITLE' | translate"
      [icon]="'smartphone'"
      [colorProfile]="'user'"></fivef-settings-panel>
  </ng-container>

  <ng-container>
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [routerLink]="'/user-profile/privacy'"
      [title]="'REGISTRATION.DATA_PRIVACY' | translate"
      [subtext]="'REGISTRATION.DATA_PRIVACY_SUBTITLE' | translate"
      [icon]="'policy'"
      [colorProfile]="'user'"></fivef-settings-panel>
  </ng-container>
</div>
