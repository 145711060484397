import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {IFivefFlatNode, IFivefTreeNodeDef} from './fivef-tree.interface';

/**
 * Structural tree node template directive.
 *
 * References
 *
 * - https://angular.io/guide/structural-directives
 * - https://gist.github.com/JanMalch/800cc0e1e448961fa5d93289e24e26fb
 *
 * Usage
 *
 * <fivef-tree [nodes]="myNodes">
 *   <ng-container *fivefTreeNodeDef=" let node">
 *     <!-- Example. Custom node implementations are feasible. -->
 *     <fivef-tree-node [node]="node"></fivef-tree-node>
 *   </ng-template>
 * </fivef-tree>
 *
 */
@Directive({
  selector: '[fivefTreeNodeDef]',
  standalone: true,
})
export class FivefTreeNodeDevDirective implements OnInit, OnDestroy {
  @Input()
  fivefTreeNodeDef: IFivefFlatNode;

  /**
   * Creates the structural template directive.
   *
   * @param viewContainerRef
   * @param templateRef Public. Accessed by the ngTemplateOutlet.
   */
  constructor(private viewContainerRef: ViewContainerRef,
              public templateRef: TemplateRef<IFivefTreeNodeDef>) {
  }

  ngOnInit() {
    this.createView();
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }

  /**
   * Creates the visual and assigns the core argument as implicit value.
   * @private
   */
  private createView() {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: this.fivefTreeNodeDef
    });
  }
}
