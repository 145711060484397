import {NgModule} from '@angular/core';
import {ProcessDialogModule} from '../workflow-engine/modules/process-dialog/process-dialog.module';
import {AddressBookComponent} from './containers/address-book/address-book.component';
import {AddressBookTableModule} from './modules/address-book-table/address-book-table.module';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';

@NgModule({
  imports: [
    AddressBookTableModule,
    ProcessDialogModule,
    ScrollingModule
  ],
  declarations: [
    AddressBookComponent
  ],
  exports: [
    AddressBookTableModule,
    AddressBookComponent
  ]
})
export class AddressBookModule {
}
