<dvtx-page-header #mainHeader [error]="null" [userSessionEnabled]="false"></dvtx-page-header>

<div class="dvtx-app" style="display: flex;">
  <div class="container-fluid fivef-app-content"
       id="fivef-app-content">
    <div class="fivef-app-content__router-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
