<fivef-dialog [title]="'TASK.CREATE_TASK'" [isCallToAction]="true" [isUtilityDialog]="true">
  <dvtx-task-settings [task]="task"
                      (onChange)="updateTask($event)"></dvtx-task-settings>

  <div fivef-dialog-footer>
    <button type="button" mat-button mat-dialog-close>
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button
            color="primary"
            (click)='createTask()'
            [disabled]="!task?.title || !task?.taskType">
      {{'GENERAL.SAVE_ACTION' | translate}}
    </button>
  </div>
</fivef-dialog>
