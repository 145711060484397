import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, forwardRef, Injector,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {FivefMessageEditorComponent} from '../fivef-message-editor/fivef-message-editor.component';
import {FivefControlValueAccessor} from '../fivef-control-value-accessor.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'fivef-rich-text',
  host: {class: 'fivef-rich-text'},
  standalone: true,
  templateUrl: './fivef-rich-text.component.html',
  styleUrls: ['./fivef-rich-text.component.scss'],
  imports: [
    CommonModule,
    FivefMessageEditorComponent,
    FroalaViewModule,
    TranslateModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefRichTextComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefRichTextComponent extends FivefControlValueAccessor implements OnDestroy {
  private onDestroy = new Subject<void>();

  @Input()
  saveIcon: 'save' | 'send' = 'send';

  @Input()
  editable = false;

  @Input()
  showCancelAction: boolean = false;

  @Input()
  optimizeToolbar = false;

  /**
   * Resets the message after sending/saving.
   * Default case for all comment sections.
   */
  @Input()
  resetOnSave = true;

  @Input()
  public placeholder = 'PROJECT_ROOM.ADD_DESCRIPTION_2';

  @Output()
  public onSave: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onBlur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Backup to be restored on cancel.
   * @private
   */
  private _backup: string;
  public _text: string;

  @Input()
  textPrefix: string;

  @Input()
  set text(txt: string) {
    this._text = txt;
    this._backup = txt;
  }

  constructor(protected injector: Injector,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public cancel($event) {
    this._text = this._backup;
    this.onCancel.emit($event);
    this.editable = false;
    this.cdr.detectChanges();
  }

  public save($event) {
    this._text = $event;
    this._backup = $event;
    this.editable = false;
    this.onSave.emit($event);
    this.cdr.detectChanges();
  }

  /**
   * Changes the view of the editor into the editing mode with toolbar.
   * @param $event
   */
  public activateEditMode($event) {
    // Don't change into edit mode if the event is of type link.
    // Reason: The triggered event was made by a click on a link, that should just open it (Usability).

    // NOTE (ab): I used target, here. Needs to be discussed if srcElement could be a better option.
    if ($event && $event.target && $event.target.nodeName === 'A') {
      $event.stopPropagation();
      return;
    }

    if (this._disabled) {
      $event.stopPropagation();
      return;

    } else {
      this.editable = true;
      this.cdr.detectChanges();
    }
  }

  writeValue(value): void {
    if (value !== undefined) {
      this._text = value;
      this._backup = value;
      this.notifyOnChange(this._text);
    }
  }
}
