import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Project} from './project';
import {Client} from '../client/client';
import {ClientBuilder} from '../client/client.builder';

export class ProjectBuilder implements IApiResourceBuilder<Project> {
  clients: {
    [id: string]: Client
  } = {};

  fromResponse(data): Project {
    const project = new Project(
      data.id,
      data.attributes.parent_id,
      data.attributes.title,
      data.attributes.identifier,
      data.attributes.reference_id,
      data.attributes.description,
      data.attributes.state,
      data.attributes.creator_name,
      data.attributes.creator_email,
      data.attributes.owner_name,
      data.attributes.owner_email,
      data.attributes.dms_folder_id,
      data.attributes.due_date,
      data.attributes.created_at,
      data.attributes.updated_at);

    project.dmsAccountType = data.attributes.dms_account_type;
    project.color = data.attributes.color;
    project.profile = data.attributes.profile;
    project.organizationId = data.attributes.organization_uuid;

    let clientId = null;
    try {
      clientId = data.relationships.client && data.relationships.client.data.id ? data.relationships.client.data.id : null;
    } catch (e) {}
    if (clientId) {
      project.client = this.clients[clientId];
    }

    return project;
  }

  toRequest(_: Project) {
    return null;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const cbuilder = new ClientBuilder();

    includes.map( (include) => {
      if (include.type === 'client_people' || include.type === 'client_organizations') {
        const _client = cbuilder.fromResponse(include);
        this.clients[include.id] = _client;
      }
    });
  }
}
