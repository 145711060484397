import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AuditOrderService} from './audit-order.service';
import {EffectsModule} from '@ngrx/effects';
import {AuditOrderEffects} from './audit-order.effects';
import {SubareaVerifierRequestOverviewService} from './subarea-verifier-request-overview.service';
import {AppendicesRequestCreateService} from './appendices-request-create.service';
import {AppendicesRequestRespondService} from './appendices-request-respond.service';
import {AppendicesRequestOverviewService} from './appendices-request-overview.service';
import {AuditContactImportsService} from './audit-contact-imports.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuditOrderEffects])
  ],
  providers: [
    AuditOrderService,
    SubareaVerifierRequestOverviewService,
    AppendicesRequestCreateService,
    AppendicesRequestRespondService,
    AppendicesRequestOverviewService,
    AuditContactImportsService
  ]
})
export class AuditOrderStoreModule {
}
