import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {DocumentSignatureService} from '../../../../+store/document-signature/document-signature.service';

@Injectable()
export class DmsPdfExportService {
  readonly apiPath = '/api/v1/foxdox/pdf';
  basePath;

  constructor(private signSvc: DocumentSignatureService) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.apiPath}`;
  }

  /**
   * Returns a PDF version of document with id.
   *
   * @param id
   */
  getPdf(id: string) {
    this.signSvc.downloadPdf(id);
  }

  /**
   * @deprecated former code, kept for documentation.
   *
   * getPdf(dmsDocumentId) {
   *   const url = `${this.basePath}/${dmsDocumentId}?${this._authQueryParams()}`;
   *   console.log(url);
   *   window.open(url);
   * }
   *
   * private _authQueryParams() {
   *   const auth = this._tokenService.currentAuthData;
   *   const uid = User.userUidToParam(auth.uid);
   *   return `access_token=${auth.accessToken}&uid=${uid}&client=${auth.client}`;
   * }
   */
}
