import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './member.interface';
import * as memberInterface from './member.interface';
import * as model from './member';
import * as orgModel from './../organization/organization';
import * as build from './member.builder';

@Injectable()
export class TenantMemberService {
  readonly BASE_PATH = 'api/v3/tenants/entities';
  readonly BASE_PATH2 = 'api/v3/tenants';
  readonly BASE_PATH3 = 'api/v1/tenants'

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(organizationId = null): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member[]>>this._http.get<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organizationId}/memberships`);
  }

  destroy(organization: orgModel.Tenant.Organization, member: memberInterface.Tenant.IMember): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organization.id);
    return <Observable<model.Tenant.Member[]>>this._http.delAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}`);
  }

  create(organizationId = null, params: modelInterface.Tenant.IMemberParams, createMemberIfNotExists = false): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    const payload = {
      data: {
        attributes: params
      }
    }
    return <Observable<model.Tenant.Member[]>>this._http.postAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organizationId}/memberships?create_if_not_exists=${createMemberIfNotExists}`, payload);
  }

  toggleAdminRoleForMember(organization: orgModel.Tenant.Organization, member: memberInterface.Tenant.IMember): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organization.id);

    if (!!member.admin) {
      return <Observable<model.Tenant.Member[]>>this._http.postAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}/admin`, {});
    } else {
      return <Observable<model.Tenant.Member[]>>this._http.delAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}/admin`);
    }
  }

  changeOwner(organization: orgModel.Tenant.Organization, member: memberInterface.Tenant.IMember): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organization.id);
    const payload = {
      data: {
        attributes: {
          user_account_id: member.id
        }
      }
    }
    return <Observable<model.Tenant.Member[]>>this._http.postAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}/change_owner`, payload);
  }

  addMember(organization: orgModel.Tenant.Organization, email: string): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organization.id);
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    }
    return <Observable<model.Tenant.Member[]>>this._http.postAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `operator/api/v1/organization/entities/${organization.id}/memberships`, payload);
  }

  toggleProjectRoomAdminForMember(organization: orgModel.Tenant.Organization, member: memberInterface.Tenant.IMember): Observable<model.Tenant.Member[]> {
    const builder = new build.Tenant.MemberBuilder(organization.id);

    if (!!member.projectRoomAdmin) {
      return <Observable<model.Tenant.Member[]>>this._http.postAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}/project_room_admin`, {});
    } else {
      return <Observable<model.Tenant.Member[]>>this._http.delAll<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH}/${organization.id}/memberships/${member.membershipId}/project_room_admin`);
    }
  }

  enableSalaryAccess(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/enable_fastdocs`, {});
  }

  disableSalaryAccess(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/disable_fastdocs`, {});
  }

  enableDigitalbar(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/enable_digitalbar`, {});
  }

  disableDigitalbar(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/disable_digitalbar`, {});
  }

  enableFivefAgent(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/enable_fivef_agent`, {});
  }

  disableFivefAgent(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/disable_fivef_agent`, {});
  }

  enableDatev(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/enable_datev`, {});
  }

  disableDatev(organizationId: any, membershipId: string): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/disable_datev`, {});
  }

  updateWorkflowCreation(organizationId, membershipId: any, payload: any): Observable<model.Tenant.Member> {
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/workflow_creation`,payload);
  }

  passwordReset(organizationId, membershipId: any, passwordResetValue: any): Observable<model.Tenant.Member> {
    const payload = {
      data: {
        attributes: {
          'password': passwordResetValue
        }
      }
    }
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.put<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships/${membershipId}/password_reset`, payload);
  }

  getAllMembershipPermissions(organizationId): Observable<model.Tenant.MemberPermissions[]> {
    const builder = new build.Tenant.MemberPermissionsBuilder(organizationId);
    return <Observable<model.Tenant.MemberPermissions[]>>this._http.get<build.Tenant.MemberPermissionsBuilder, model.Tenant.MemberPermissions>(builder, `${this.BASE_PATH3}/membership_permissions`);
  }

  getMembershipPermissions(organizationId, membershipId: string): Observable<model.Tenant.MemberPermissions> {
    const builder = new build.Tenant.MemberPermissionsBuilder(organizationId);
    return <Observable<model.Tenant.MemberPermissions>>this._http.get<build.Tenant.MemberPermissionsBuilder, model.Tenant.MemberPermissions>(builder, `${this.BASE_PATH3}/membership_permissions/${membershipId}`);
  }
  addEmployee(emp: any, organizationId): Observable<model.Tenant.Member> {
    const payload = {
      data: {
        attributes: {
          first_name: emp.firstName,
          last_name: emp.lastName,
         // note: emp.note,
          email: emp.email.emailAddress,
          password: emp.password,
          notify_user: emp.notifyUser
        }
      }
    };
    const builder = new build.Tenant.MemberBuilder(organizationId);
    return <Observable<model.Tenant.Member>>this._http.post<build.Tenant.MemberBuilder, model.Tenant.Member>(builder, `${this.BASE_PATH2}/memberships`, payload);
  }
}
