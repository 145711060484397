import {catchError, concatMap, first, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppState} from 'app/app.state';
import {Action, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {User} from '../../user/user';
import * as userActions from '../actions/user-actions';
import * as currentUserActions from 'app/+store/_legacy/actions/current-user-actions';
import {ResetArtifacts} from 'app/+store/_legacy/actions/artifacts.actions';
import {PassIsWrong, ResetTfa, TfaIsWrong, UserNeedsTfa} from 'app/+store/_legacy/actions/tfa.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {NaturalPersonActions} from 'app/+store/natural-person';
import {OrganizationActions} from 'app/+store/organization';
import {AngularTokenService, ApiResponse, SignInData} from 'angular-token';
import {UserLogoutRequest, UserLogoutSuccess} from 'app/+store/_legacy/actions/user-actions';
import {environment} from 'environments/environment'
import {AuthGuard} from 'app/+store/_legacy/api/services/auth-guard.service';
import {AutoLogoutService} from 'app/services/auto-logout.service';
import {ProcessTreeService} from '../../process-tree/process-tree.service';
import {TwoFactorAuthService} from '../../two-factor-auth/two-factor-auth.service';
import {WebsocketService} from '../../../services/websocket.service';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class UserEffects {
  userSignIn$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(userActions.LOGIN_USER_REQUEST),
      switchMap((action: userActions.LoginUserRequest) => {
        const userCredi: SignInData = {
          login: action.payload.email,
          password: action.payload.password
        };
        return this.tokenSvc.signIn(userCredi).pipe(
          map((res: any) => {
            this._store.dispatch(new ResetTfa());

            // Test for TFA requirement and jump into the wizard if this is the case.
            const tfaSetupAccessToken = this.maybeTfaSetupToken(res.data);
            if (tfaSetupAccessToken) {
              // NOTE: These credentials must be resetted in TFA component as soon as being loaded.
              this.tfaSvc.setCredentials(userCredi.login, userCredi.password, tfaSetupAccessToken.organizationName);

              this.router.navigate([`/session/tfa-setup/${tfaSetupAccessToken.accessToken}`]);
              return new userActions.UserLoginPending(null);
            }

            if (res.data) {
              const authData = this.tokenSvc.currentAuthData;
              const response = res.data;
              const user = User.buildFrom(response);
              user.accessToken = authData.accessToken;
              user.client = authData.client;

              this._store.dispatch(new NaturalPersonActions.LoadMy());
              this._store.dispatch(new OrganizationActions.LoadAll());
              this._store.dispatch(new currentUserActions.SetCurrentUser(user));
              this._store.dispatch(new ResetTfa());
              this.processTreeService.refresh();

              localStorage.setItem('route', '/dashboard');

              if (user && user.autoLogout) {
                this.autoLogoutSvc.minutesToLogout = user.autoLogout;
              } else {
                this.autoLogoutSvc.minutesToLogout = this.autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;
              }
              // Kept for debugging.
              // console.error('GetCurrentUser: Auto-Logout timout to: ', this.autoLogoutSvc.minutesToLogout);

              return new userActions.LoginUserSuccess(user);

            } else if (res && res[0] && res[0].status === 'Code is empty') {
              return new UserNeedsTfa(true);

            } else {
              this._store.dispatch(new UserNeedsTfa(true))
              return new TfaIsWrong(true);
            }
          }),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            // this._resetToInitialState();
            this._store.dispatch(new PassIsWrong(true))
            return of(new userActions.LoginUserError(err.error));
          }));
      }),
      catchError((err) => {
        console.error(err);
        this._store.dispatch(new PassIsWrong(true))
        return of(new userActions.LoginUserError(err.error));
      })));

  userSignUp$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(userActions.USER_REGISTER_REQUEST),
      map((action: userActions.UserRegisterRequest) => action.payload),
      switchMap(user => {
        const regData: any = user;
        return this.tokenSvc.registerAccount(regData);
      }),
      switchMap((res: ApiResponse) => {
        // this.router.navigate(['dashboard']);
        const authData = this.tokenSvc.currentAuthData;
        const response = res.json().data;
        const user = User.buildFrom(response);
        user.accessToken = authData.accessToken;
        user.client = authData.client;
        this._store.dispatch(new currentUserActions.SetCurrentUser(user));
        return of(new userActions.UserRegisterSuccess(user));
      }),
      catchError(err => {
        this._resetToInitialState();
        return of(new userActions.UserRegisterError(err.json().errors));
      })));

  userSignOut$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.USER_LOGOUT_REQUEST),
    concatMap((action: UserLogoutRequest) => {
      return this.tokenSvc.signOut().pipe(
        first(),
        switchMap((res) => {
          if (!environment.production) {
            console.log('UserLogoutRequest: Logging out');
          }
          return [new UserLogoutSuccess()];
        }),
        catchError(error => {
          if (error.status && error.status !== 404) {
            console.error('UserLogoutRequest: Failed logging out or already logged out: Clearing store, navigating to session/sign-in');
          }
          return of(new UserLogoutSuccess(error));
        })
      );
    })
  ));

  /**
   * Logout success reducer cleanup up the local cache.
   */
  userSignOutComplete$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.USER_LOGOUT_SUCCESS),
    map((action: UserLogoutSuccess) => {
      if (!environment.production) {
        console.log('UserLogoutRequest: Logged out successfully. Cleaning up...');
      }

      // Route to session sign in except current view is an external view.
      this.routeToSessionView();

      // Reset the logout timer to default.
      this.autoLogoutSvc.minutesToLogout = this.autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;

      // Clear local storage.
      this.clearStorage();
    })
  ), {dispatch: false});

  constructor(
    private router: Router,
    private actions$: Actions,
    private _store: Store<AppState>,
    private tfaSvc: TwoFactorAuthService,
    private tokenSvc: AngularTokenService,
    private autoLogoutSvc: AutoLogoutService,
    private processTreeService: ProcessTreeService,
    private ws: WebsocketService) {
  }

  private _resetToInitialState() {
    this._store.dispatch(new ResetArtifacts());
    this._store.dispatch(new currentUserActions.ResetCurrentUser());
  }

  /**
   * Routes to sign-in except an external content view is used.
   * @private
   */
  private routeToSessionView() {
    if (AuthGuard.isTfaWizardRoute(this.router.url)
      || (!AuthGuard.isSessionRoute(this.router.url)
        && !AuthGuard.isThirdPartyContribution(this.router.url)
        && !AuthGuard.isFileInbox(this.router.url)
        && !AuthGuard.isExternalAccess(this.router.url)
        && !AuthGuard.isCavContribution(this.router.url))) {
      this.router.navigate(['/session/sign-in'],
        {queryParams: {returnUrl: this.router.url}});

    } else {
      console.error(`Skipping sign-in navigation for route: ${this.router.url}`)
    }
  }

  /**
   * Clears the storage but keeps the organization ID so it can be set on next sign-in.
   * Also disconnects open WS connections.
   */
  private clearStorage() {
    this.autoLogoutSvc.clearLocalStorage();

    this._store.dispatch({type: null});
    try {
      this.ws.disconnect();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Returns a TFA token setup if TFA is required.
   * This setup is for the logged out state and allows to jump into the TFA setup.
   *
   * @param data
   * @private
   */
  private maybeTfaSetupToken(data: { attributes: { access_token: string, organization_name: string } }): {
    accessToken: string,
    organizationName: string
  } {
    if (!!data && data.attributes && data.attributes.access_token) {
      return {
        accessToken: data.attributes.access_token,
        organizationName: data.attributes.organization_name
      };
    }
    return null;
  }
}
