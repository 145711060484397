import {throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ProcessContextBuilder} from 'app/+store/_legacy/api/builders/process-context.builder';
import {IProcess} from 'app/+store/_legacy/api/models/process.interface';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {ApiErrorBuilder} from 'app/+store/_legacy/api/builders/api-error.builder';

@Injectable()
export class QuickstartService {
  BASE_PATH;
  // readonly BASE_PATH = 'workflow_engine/quickstart';

  constructor(private _tokenSvc: HttpClient, private _contextBuilder: ProcessContextBuilder) {
    this.BASE_PATH = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/workflow_engine/quickstart`;
  }

  create(process: IProcess, workflowType: string = null) {
    const path = process.parent ? `${this.BASE_PATH}?parent=${process.parent}` : this.BASE_PATH;
    const andOrAmpersand = process.parent ? '&' : '?';
    const queryParam = workflowType ? `${andOrAmpersand}type=${workflowType}` : '';
    return this._tokenSvc
      .post(`${path}${queryParam}`, JSON.stringify(ResourceBuilder.toRequest(process))).pipe(
      map((res: any) => this._contextBuilder.fromResponse(res.data)),
      catchError(this.handleError),);
  }

  private handleError(error) {
    if (error.json) {
      const errors = ApiErrorBuilder.fromResponse(error);
      return observableThrowError(errors);
    }
    return observableThrowError(error);
  }
}
