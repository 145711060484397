
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuditAuditorCommunications} from 'app/+store/_legacy/api/models/auditAuditorCommunications';
import {Observable} from 'rxjs';
import {AuditAuditorCommunicationsBuilder} from 'app/+store/_legacy/api/builders/auditAuditorCommunications.builder';
import {AuditAuditorContributionsBuilder} from 'app/+store/_legacy/api/builders/auditAuditorContributions.builder';
import {AuditAuditorContributions} from 'app/+store/_legacy/api/models/auditAuditorContributions';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable()
export class AppendicesRequestOverviewService {
  readonly BASE_PATH = 'api/v1/audit';
  basePath: string;

  constructor(private tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  public getAppendicesRequestList(id: string): Observable<AuditAuditorCommunications> {
    return this.tokenService.get(
        `${this.basePath}/component_auditor_communications/${id}`
    ).pipe(
      map((response) => {
        return AuditAuditorCommunicationsBuilder.fromResponse(response['data']);
      }));
  }

  public getAuditorContributions(id: string): Observable<AuditAuditorContributions> {
    return this.tokenService.get(
        `${this.basePath}/component_auditor_contributions/${id}`
    ).pipe(
      map((response) => {
        return AuditAuditorContributionsBuilder.fromResponse(response['data']);
      }));
  }

  updateAuditorContributions(id: string, contributions: AuditAuditorContributions) {
    return this.tokenService.put(
      `${this.basePath}/component_auditor_contributions/${id}`,
      AuditAuditorContributionsBuilder.toRequest(contributions)
    );
  }
}
