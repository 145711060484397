import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {Process} from '../../../../+store/process/process';

@Component({
  selector: 'fivef-process-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './fivef-process-icon.component.html',
  styleUrls: ['./fivef-process-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefProcessIconComponent {
  @Input()
  public size: 'xs' | 'sm' | 'md' = 'md';

  public _iconConfig: { icon: string, isSvgIcon: boolean };

  @Input()
  set workflowType(workflowType) {
    this._iconConfig = Process.iconForType(workflowType)
  }
}
