import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  TenantUserActionTypes,
  ToggleLicense,
  ToggleLicenseFail,
  ToggleLicenseSuccess,
  LockUserAccount,
  LockUserAccountFail,
  LockUserAccountSuccess,
  Save,
  SaveFail,
  SaveSuccess
} from './user.actions';
import * as model from './user';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {TenantUserService} from './user.service';

@Injectable()
export class TenantUserEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.createUserAccount(action.tenantId, action.user).pipe(
        first(),
        concatMap((user: model.Tenant.User) => {
          return [new CreateSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.user).pipe(
        first(),
        switchMap((user: model.Tenant.User) => {
          return [new SaveSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.tenantId, action.id).pipe(
        first(),
        concatMap((user: model.Tenant.User) => {
          return [new LoadOneSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      const reset = action.params && action.params.page && action.params.page === 1;
      return this._svc.getAll(action.tenantId, action.params).pipe(
        first(),
        concatMap((res: model.Tenant.User[]) => {
          return [new LoadAllSuccess(res, res, reset)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  toggleLicense$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.ToggleLicense),
    concatMap((action: ToggleLicense) => {
      return this._svc.toggleLicense(action.tenantId, action.userId, action.enable).pipe(
        first(),
        concatMap((item: model.Tenant.User) => {
          return [new ToggleLicenseSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ToggleLicenseFail(err));
        }));
    })
  ));

  toggleUserAccountLock$ = createEffect(() => this.actions.pipe(
    ofType(TenantUserActionTypes.LockUserAccount),
    concatMap((action: LockUserAccount) => {
      return this._svc.toggleUserAccountLock(action.tenantId, action.userId, action.enable).pipe(
        first(),
        concatMap((item: model.Tenant.User) => {
          return [new LockUserAccountSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LockUserAccountFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TenantUserService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
