import {Process} from '../process/process';
import {BasicContact} from '../cac-entity/basic-contact';
import {CacEntity} from '../cac-entity/cac-entity';
import {CacAppendix} from './cac-appendix';
import {FileAttachment} from './cac-attachment';

export {BasicContact} from '../cac-entity/basic-contact';
export {CacEntity} from '../cac-entity/cac-entity';
export {CacAppendix} from './cac-appendix';
export {FileAttachment} from './cac-attachment';

export class CacProcess extends Process {
  static readonly INSTRUCTION_COLOR = '#00CA5C';
  static readonly DEFAULT_APPENDIX_COLOR = '#4e5a6a';
  static readonly SOCIETY_APPENDIX_COLOR = '#93c3d2';

  instructionNodeId: string;
  appendices: CacAppendix[] = [];
  societies: CacEntity[] = [];
  attachments: FileAttachment[];
  isCommunication = false;
  isAuditing = false;
  contactImportId: string;

  total: number;
  perPage: number;
  records: number;

  static fromProcess(process: Process) {
    const cac = new CacProcess(process.id, process.title, process.subtitle, process.description,
                               process.dueDate, process.createdAt, process.processType, process.state, process.updatedAt);
    cac.client = process.client;
    cac.startsAt = process.startsAt;
    cac.endsAt = process.endsAt;
    return cac;
  }
}
