import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Organization} from 'app/+store/organization/organization';
import {ClientService} from 'app/+store/client/client.service';
import {catchError, first} from 'rxjs/operators';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {ClientSelectors, ContactSelectors} from 'app/+store';
import {ContactListDto} from 'app/+store/contact/contact';
import {FivefClientSelectComponent} from 'app/lib/fivef-ui/client/fivef-client-select/fivef-client-select.component';
import {FivefConfirm} from 'app/lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';
import {of} from 'rxjs/internal/observable/of';
import {CommonModule} from '@angular/common';
import {FivefContactAutocompleteComponent} from 'app/lib/fivef-ui/input/fivef-contact-autocomplete/fivef-contact-autocomplete.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FivefAvatarModule} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.module';
import {ContentEditableModule} from 'app/lib/fivef-legacy/content-editable/content-editable.module';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'fivef-client-contact-listing',
  standalone: true,
  imports: [CommonModule, FivefContactAutocompleteComponent, MatIconModule, TranslateModule, FivefAvatarModule, ContentEditableModule, FivefClientSelectComponent, FormsModule, MatTooltipModule, MatButtonModule],
  templateUrl: './fivef-client-contact-listing.component.html',
  styleUrls: ['./fivef-client-contact-listing.component.scss']
})
export class FivefClientContactListingComponent implements AfterViewInit, OnDestroy, OnInit {

  private onDestroy = new Subject<void>();
  organization: Organization;

  @Input()
  isContact = false;

  @Input()
  isClient = false;

  @Input()
  selectedClient: any;

  @Input()
  selectedContact: any;

  clientSelection: any = null;
  public allContacts: ContactListDto[] = [];
  public allContactsMap = {};

  excludedIds: any = [];

  public allClientsMap = {};

  @ViewChild('clientSelectionCvaComponent')
  clientSelectionCvaComponent: FivefClientSelectComponent;

  latestClientMapping: any[];

  @Output()
  updateMapping = new EventEmitter<any>();

  constructor(private _clientSvc: ClientService,
              private _store: Store<AppState>,
              private _cdr: ChangeDetectorRef,
              private _notifyService: FivefNotificationService) {
  }

  ngOnInit() {
    this._store.select(ContactSelectors.getContactsOfSelectedOrg).subscribe((contacts) => {
      this.allContacts = contacts;
      if (contacts && contacts.length > 0) {
        contacts.forEach(element => {
          this.allContactsMap[element.id] = element;
        });
      }
    });
    this._store.select(ClientSelectors.getAllClients).subscribe((clients) => {
      if (clients && clients.length > 0) {
        clients.forEach(element => {
          this.allClientsMap[element.id] = element;
        });
      }
    });
    this.loadExcludedIds();
    this._cdr.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.updateMapping.emit(this.latestClientMapping);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getContactClientMappings(action, clientId, contactId) {
    this._clientSvc.getContactClientMappings().pipe(first()).subscribe(data => {
      this.latestClientMapping = data;
      if (data) {
        let toReturn = [];
        if (this.isClient) {
          toReturn = this.selectedClient.contacts;
        } else {
          toReturn = this.selectedContact.clients;
        }
        if (action === 'create') {
          const newRecord = data.find(a => a.clientId === clientId && a.contactId === contactId);
          if (!toReturn) {
            toReturn = [];
          }
          if (newRecord) {
            toReturn.push(newRecord);
          }
        } else if (action === 'remove') {
          toReturn = toReturn.filter(a => !(a.clientId === clientId && a.contactId === contactId));
        } else if (action === 'update') {
          const updatedRecord = data.find(a => a.clientId === clientId && a.contactId === contactId);
          toReturn.forEach(element => {
            if (updatedRecord && element.clientId === clientId && element.contactId === contactId) {
              element.role = updatedRecord.role;
            }
          });
        }
        if (this.isClient) {
          this.selectedClient.contacts = toReturn;
        } else {
          this.selectedContact.clients = toReturn;
        }
        this.loadExcludedIds();
      }
      this._cdr.detectChanges();
    }), catchError(err => {
      console.error(err);
      return of();
    });
  }

  loadExcludedIds() {
    if (this.isClient) {
      if (this.selectedClient.contacts) {
        const ids = this.selectedClient.contacts.map(a => a.contactId);
        if (ids && ids.length > 0) {
          this.excludedIds = this.allContacts.filter(a => ids.includes(a.id)).map(a => a.email);
        }
      }
    } else {
      if (this.selectedContact.clients)
        this.excludedIds = this.selectedContact.clients.map(a => a.clientId);
    }
  }

  createContactClient(contact) {
    const selected = this.allContacts.find(a => a.email === contact.email);
    if (selected) {
      this._clientSvc.createContactClient(this.selectedClient.id, selected.id, '').pipe(first()).subscribe(data => {
        this._notifyService.success('CONTACTS.CONTACT_CLIENT_ASSIGN')
        this.getContactClientMappings('create', this.selectedClient.id, selected.id);
      }), catchError(err => {
        console.error(err);
        this._notifyService.error('CONTACTS.CONTACT_CLIENT_ASSIGN_FAIL')
        return of();
      });
    }
  }

  createClientContact(client) {
    if (!client || !client.id) return;
    if (this.clientSelectionCvaComponent) {
      this.clientSelectionCvaComponent.reset();
    }
    this._clientSvc.createContactClient(client.id, this.selectedContact.id, '').pipe(first()).subscribe(data => {
      this.getContactClientMappings('create', client.id, this.selectedContact.id);
      this._notifyService.success('CONTACTS.CLIENT_CONTACT_ASSIGN')
    }), catchError(err => {
      console.error(err);
      this._notifyService.error('CONTACTS.CLIENT_CONTACT_ASSIGN_FAIL')
      return of();
    });
  }

  updateContactClient(contact, roleName) {
    this._clientSvc.updateContactClient(contact.clientId, contact.contactId, roleName, contact.relationId).pipe(first()).subscribe(data => {
      if (roleName === '') {
        this._notifyService.success('CONTACTS.REMOVE_ROLE')
      } else {
        this._notifyService.success('CONTACTS.EDIT_ROLE')
      }
      this.getContactClientMappings('update', contact.clientId, contact.contactId);
    }), catchError(err => {
      console.error(err);
      if (roleName === '') {
        this._notifyService.error('CONTACTS.REMOVE_ROLE_FAIL')
      } else {
        this._notifyService.error('CONTACTS.EDIT_ROLE_FAIL')
      }
      return of();
    });
  }

  @FivefConfirm({
    message: 'GENERAL.CONFIRM_PROCEED',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_CONFIRM_ACTION'
  })
  public removeContactClient(contact) {
    this._clientSvc.removeContactClient(contact.clientId, contact.relationId).pipe(first()).subscribe(data => {
      if (this.isClient) {
        this._notifyService.success('CONTACTS.CONTACT_CLIENT_REMOVE')
      } else {
        this._notifyService.success('CONTACTS.CLIENT_CONTACT_REMOVE')
      }
      this.getContactClientMappings('remove', contact.clientId, contact.contactId);
    }), catchError(err => {
      console.error(err);
      if (this.isClient) {
        this._notifyService.error('CONTACTS.CONTACT_CLIENT_REMOVE_FAIL')
      } else {
        this._notifyService.error('CONTACTS.CLIENT_CONTACT_REMOVE_FAIL')
      }
      return of();
    });
  }
}
