import {Component} from '@angular/core';

@Component({
  selector: 'dvtx-project-room-quickstarter',
  templateUrl: './project-room-quickstarter.component.html',
  styleUrls: ['./project-room-quickstarter.component.scss']
})
export class ProjectRoomQuickstarterComponent {
}

