import {INotificationProfile, ITimeZone} from './message-settings.interface';
import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';

export class MessageSettingsV2 implements IResource {
  readonly type = 'message_settings_v2';

  constructor(public id: string,
              public global: boolean,
              public byInbox: boolean,
              public byEmail: boolean,
              public byMobile: boolean,
              public activities: string[],
              public globalOverride: boolean,
              public notificationProfile: INotificationProfileType,
              public timezone: string) {
  }
}

export class TimeZone implements ITimeZone {
  readonly type = 'settings_time_zones';
  offsetMultiHour: boolean;

  constructor(public id: string,
              public mapping: string,
              public offset: number) {
  }
}

export enum INotificationProfileType {
  notify_by_mail_standard = 'notify_by_mail_standard',
  notify_by_mail_hourly = 'notify_by_mail_hourly',
  notify_by_mail_daily_digest = 'notify_by_mail_daily_digest',
  notify_by_mail_weekly_digest = 'notify_by_mail_weekly_digest',
  notify_by_mail_morning_afternoon = 'notify_by_mail_morning_afternoon',
  notify_by_mail_morning_midday_afternoon = 'notify_by_mail_morning_midday_afternoon',
  notify_by_mail_5_minute_digest = 'notify_by_mail_5_minute_digest'
}

export class NotificationProfile implements INotificationProfile {
  readonly type = 'settings_notification_profiles';
  title: string;

  constructor(public id: string) {
  }
}

export const NOTIFICATION_PROFILES = [{
  title: 'USER.notify_by_mail_standard',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_standard
}, {
  title: 'USER.notify_by_mail_5_minute_digest',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_5_minute_digest
}, {
  title: 'USER.notify_by_mail_hourly',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_hourly
}, {
  title: 'USER.notify_by_mail_daily_digest',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_daily_digest
}, {
  title: 'USER.notify_by_mail_morning_afternoon',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_morning_afternoon
}, {
  title: 'USER.notify_by_mail_morning_midday_afternoon',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_morning_midday_afternoon
}, {
  title: 'USER.notify_by_mail_weekly_digest',
  type: 'settings_notification_profiles',
  id: INotificationProfileType.notify_by_mail_weekly_digest
}];
