<div class="fivef-alert--wrapper fivef-alert--border-color-{{borderColor}} fivef-alert--color-{{color}}"
     [class.alert-dismissible]="dismissible"
     [class.fade]="dismissible"
     [class.show]="dismissible"
     role="alert">
  <div *ngIf="icon" class="fivef-alert--icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>

  <ng-container *ngIf="text || title">
    <div *ngIf="title || dismissible">
      <div class="fivef-alert--content">
        <span *ngIf="title" [innerHTML]="title | translate"></span>
      </div>

      <button *ngIf="dismissible"
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="text" [innerHTML]="text | translate"></div>
  </ng-container>
  <ng-content></ng-content>
</div>
