import {ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTreeFlatDataSource} from '@angular/material/tree';
import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';
import {MatRippleModule} from '@angular/material/core';
import {IFivefFlatNode, IFivefTreeNode} from './fivef-tree.interface';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {takeUntil} from 'rxjs/operators';
import {FivefTreeNodeComponent} from '../fivef-tree-node/fivef-tree-node.component';
import {FivefTreeNodeDevDirective} from './fivef-tree-node-dev.directive';
import {FivefTreeService} from './fivef-tree.service';
import {FastdocsModule} from '../../../../modules/fastdocs/fastdocs.module';
import {TranslateModule} from '@ngx-translate/core';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';

/**
 * Scroll-padding: Show given number of nodes above intended node
 * for better orientation, where we are inside the tree.
 */
const SCROLL_ITEM_PADDING = 5;

/**
 * Basic tree component based on CDK tree and CDK virtual scrolling.
 *
 * References:
 * - https://material.angular.io/cdk/scrolling/overview
 * - https://material.angular.io/cdk/tree/overview
 * - https://octoperf.com/blog/2020/08/13/angular10-material-tree-virtual-scroll/#updating-the-tree-html
 * - https://stackoverflow.com/questions/52606511/angular-material-cdk-tree-component-with-virtual-scroll
 * - https://stackoverflow.com/questions/52460914/angular-mat-tree-with-ng-template
 * - https://material.angular.io/components/ripple/api
 */
@Component({
  selector: 'fivef-tree',
  host: {'class': 'fivef-tree'},
  standalone: true,
  imports: [CommonModule, MatIconModule, ScrollingModule, MatRippleModule, FivefTreeNodeComponent, FastdocsModule, TranslateModule, FivefIconMessageBlockComponent],
  providers: [FivefTreeService],
  templateUrl: './fivef-tree.component.html',
  styleUrls: ['./fivef-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefTreeComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  /**
   * The node visual, mostly a template to show the title.
   */
  @ContentChild(FivefTreeNodeDevDirective)
  public treeNodeTpl!: FivefTreeNodeDevDirective;

  /**
   * Virtual scrolling viewport. Required to scroll programmatically
   * to the activated element.
   *
   * @private
   */
  @ViewChild('virtualScrollViewportRef', {static: true})
  private virtualScrollViewport: CdkVirtualScrollViewport;

  // Node being selected.
  private selectedNodeId$ = new BehaviorSubject<string>(null);

  /**
   * CDK tree setup.
   * All changes are delegated to the tree service.
   */
  public dataSource: MatTreeFlatDataSource<IFivefTreeNode, IFivefFlatNode>;

  /**
   * Icon shown on empty element set.
   */
  @Input()
  noElementsIcon: string;

  /**
   * Message key used on empty element set.
   */
  @Input()
  noElementsText: string;

  /**
   * Expand the root node on initialization.
   */
  @Input()
  public expandRoot = true;

  /**
   * show no result found if search is empty.
   */
  @Input() showNoResult: boolean = false;

  /**
   * Size of the inner items height required to let the virtual scrolling
   * calculate the views visibility inside the element's frame.
   * Default to 24.
   */
  @Input()
  public itemSize = 24;

  /**
   * Theme of selected items. Choice: Primary font color with bold weight and
   * inverted background and foreground color with rounded box.
   */
  @Input()
  public theme: 'primary' | 'background' = 'primary';

  /**
   * Emits the node if selected by click inside the tree.
   * Nodes are not emitted at expansion of subtrees.
   *
   * @private
   */
  @Output()
  private onSelect = new EventEmitter();

  /**
   * Preselects the given folder by ID.
   *
   * @param folder
   */
  @Input()
  public set select(folder: { id: string }) {
    if (!folder) {
      return;
    }
    this.selectedNodeId$.next(folder.id)
  }

  /**
   * Sets the input nodes.
   *
   * @param n
   * @private
   */
  @Input()
  private set nodes(n: IFivefTreeNode[]) {
    this.treeSvc.nodes = n;
  }

  /**
   * Searches the tree.
   *
   * @param term
   * @private
   */
  @Input()
  private set search(term: string) {
    this.treeSvc.search(term);
  }

  constructor(private treeSvc: FivefTreeService) {
  }

  ngOnInit() {
    this.treeSvc.expandRoot = this.expandRoot;
    this.dataSource = this.treeSvc.dataSource;

    this.selectedNodeId$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((id) => {
        const idx = this.treeSvc.selectNodeById(id);
        this.scrollToNode(idx);
      }, err => console.error(err));
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.selectedNodeId$.complete();
  }

  /**
   * Returns true if the node is expanded.
   *
   * @param node
   */
  public isExpanded(node: IFivefFlatNode): boolean {
    return this.treeSvc.isExpanded(node);
  }

  /**
   * Returns true if the node is currently selected.
   *
   * @param node
   */
  public isSelected(node: IFivefFlatNode): boolean {
    return this.treeSvc.isSelected(node);
  }

  /**
   * Toggles the expansion state of a node's subtree.
   *
   * @param node
   */
  public toggleExpand(node: IFivefFlatNode) {
    this.treeSvc.toggle(node);
  }

  /**
   * Selects the node.
   * NOTE: This is an internal method, not intended to be used as interface.
   *       Use the input for selection from the outside world.
   *
   * @param node
   * @param expand
   */
  public selectNode(node: IFivefFlatNode, expand: boolean = true): void {
    this.treeSvc.selectNode(node, expand);
    this.onSelect.emit(node);
  }

  /**
   * Selects ir deselects the node.
   * Internal method, not intended to be used as interface.
   *
   * @param node
   */
  public toggleSelect(node: IFivefFlatNode): void {
    const res = this.treeSvc.toggleSelect(node);
    this.onSelect.emit(res ? node : null);
  }

  /**
   * Scrolls to node in tree.
   * Keeps a padding of SCROLL_ITEM_PADDING items as orientation for the user on top of it.
   */
  private scrollToNode(idx: number): void {
    const scrollNodeIndex = idx - SCROLL_ITEM_PADDING;
    if (scrollNodeIndex > 0) {
      this.virtualScrollViewport.scrollToIndex(scrollNodeIndex, 'smooth');
    }
  }

  /**
   * Helper to track changes of a node for efficient rendering.
   *
   * @param _idx
   * @param node
   */
  public trackBy = (_idx, node: IFivefFlatNode) => {
    return `${node.id}|${node.active}|${node.expanded}`
  }
}
