<ng-template #callToActionRef>
  <button mat-raised-button color="primary"
          *ngIf="(myMembership | async)?.hasAdministrationRights"
          (click)="createLabel()">
    <mat-icon matPrefix>add_circle_outline</mat-icon>
    {{ 'LABELS.CREATE_LABEL' | translate }}
  </button>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [enableSearch]="true"
                      (onSearch)="search($event)"
                      [callToActionPortal]="callToActionPortal"
                      [title]="'ORGANIZATION_NAVIGATON.LABELS_SETTINGS'"></dvtx-settings-header>

<dvtx-labels-listing #labelListingRef
                     [onSearch]="searchTerm"
                     [labels]="organizationLabels$ | async"
                     [canCreate]="(myMembership | async)?.hasAdministrationRights"
                     [canDelete]="(myMembership | async)?.hasAdministrationRights"
                     [canEdit]="(myMembership | async)?.hasAdministrationRights"
                     [scope]="scope.ORGANIZATIONAL"></dvtx-labels-listing>
