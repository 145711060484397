<mat-form-field class="dvtx-date-picker-cva">
  <input matInput
         [min]="minDate"
         [max]="maxDate"
         [matDatepicker]="picker"
         [placeholder]="placeholder"
         [(ngModel)]="representedDate"
         (dateChange)="valueChanges($event)"
         [disabled]="disabled"
         [class.fivef-color-status-overdue]="alertOnPast"
         (click)="picker.open()"
         autocomplete="new-datepicker"
         #inputElementRef>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
</mat-form-field>
