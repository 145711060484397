import {Injectable} from '@angular/core';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {SlimFolder} from './slim-folder';
import {SlimFolderBuilder} from './slim-folder.builder';
import {DmsAccountType} from './slim-folder.interface';
import {Observable} from "rxjs";

@Injectable()
export class SlimFolderService {
  readonly BASE_PATH = 'api/v1/folders';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(accountType: DmsAccountType, loadAll = false): Observable<SlimFolder[]> {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const loadAllParam = accountType === DmsAccountType.Organization && loadAll ? `${param}&load_all=true` : param;
    return <Observable<SlimFolder[]>>this._http.get<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}?account_type=${loadAllParam}&slim=true`);
  }

  getShared(sid: string, accountType: DmsAccountType): Observable<SlimFolder[]> {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder[]>>this._http.get<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}?account_type=${param}&sid=${sid}`);
  }

  getOne(id: string, accountType: DmsAccountType = DmsAccountType.Private): Observable<SlimFolder> {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.get<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}?account_type=${param}&slim=true`);
  }

  details(id: string, accountType: DmsAccountType = DmsAccountType.Private): Observable<SlimFolder> {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.get<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}/details?account_type=${param}`);
  }

  chmod(id: string, sid: string, read: boolean, write: boolean, accountType: DmsAccountType = DmsAccountType.Private): Observable<SlimFolder> {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    const payload = {
      data: {
        attributes: {
          sid: sid,
          read: read,
          write: write
        }
      }
    };
    return <Observable<SlimFolder>>this._http.post<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}/chmod?account_type=${param}`, payload);
  }

  getProcessFolder(id: string, accountType): Observable<SlimFolder> {
    const builder = new SlimFolderBuilder();
    return <Observable<SlimFolder>>this._http.get<SlimFolderBuilder, SlimFolder>(builder, `api/v1/workflow_engine/processes/${id}/folder`);
  }

  create(name, parentId?, accountType = DmsAccountType.Private) {
    const body = {data: {attributes: {name: name}}};
    if (parentId) {
      body['data']['attributes']['parent_id'] = parentId;
    }
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.post<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}?account_type=${param}&slim=true`, body);
  }

  rename(id: string, name: string, accountType = DmsAccountType.Private) {
    const body = {data: {attributes: {name: name}}};
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.post<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}/rename?account_type=${param}&slim=true`, body);
  }

  lockFolder(id: string, lockUntil: string, accountType = DmsAccountType.Organization) {
    const body = {data: {attributes: {lock_until: lockUntil}}};
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.post<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}/lock?account_type=${param}`, body);
  }

  delete(id, accountType = DmsAccountType.Private) {
    const builder = new SlimFolderBuilder();
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<SlimFolder>>this._http.del<SlimFolderBuilder, SlimFolder>(builder, `${this.BASE_PATH}/${id}?account_type=${param}&slim=true`);
  }

  // TODO: Creation and Deletion of folders. Taken from old Folder service
  // create(name, parentId?) {
  //   const body = { data: { attributes: { name: name } } };
  //   if (parentId) {
  //     body['data']['attributes']['parent_id'] = parentId;
  //   }
  //   return this._http
  //     .post(`${this.basePath}`, body)
  //     .map((res: any) => createFolder(res.data))
  //     .catch(this.handleError);
  // }
  //
  // delete(id) {
  //   return this._http
  //     .delete(`${this.basePath}/${id}`)
  //     .map((res: any) => createFolder(res.data))
  //     .catch(this.handleError);
  // }
}
