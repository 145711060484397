import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MobileActivationService} from 'app/+store/mobile-activation/mobile-activation.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {takeUntil} from 'rxjs/operators';
import {MobileActivation} from 'app/+store/mobile-activation/mobile-activation';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'fivef-contact-mobile-settings',
  templateUrl: './fivef-contact-mobile-settings.component.html',
  styleUrls: ['./fivef-contact-mobile-settings.component.scss']
})
export class FivefContactMobileSettingsComponent implements OnDestroy, OnInit {
  private onDestroy = new Subject<void>();

  isLoading$ = new BehaviorSubject<boolean>(false);
  isUserVerified$ = new BehaviorSubject<boolean>(true);
  mobileActivation$ = new BehaviorSubject<MobileActivation>(null);


  @Input() userEmail: string;

  constructor(private _mobileActivationSvc: MobileActivationService) {
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this._mobileActivationSvc.status(this.userEmail)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((mobileActivation) => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(true);
        this.mobileActivation$.next(mobileActivation);
      }, () => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(false);
        this.mobileActivation$.next(null);
      });
  }

  ngOnDestroy(): void {
    this.mobileActivation$.complete();
    this.isUserVerified$.complete();
    this.isLoading$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  activateMobile() {
    this.isLoading$.next(true);

    this._mobileActivationSvc.activate(this.userEmail)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((mobileActivation) => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(true);
        this.mobileActivation$.next(mobileActivation);
      }, () => {
        this.isLoading$.next(false);
        this.isUserVerified$.next(false);
        this.mobileActivation$.next(null);
      });
  }
}
