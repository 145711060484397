import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {UserSignature} from './user-signature';

export class UserSignatureBuilder implements IApiResourceBuilder<UserSignature> {
  fromResponse(data): UserSignature {
    return new UserSignature(
      data.id,
      data.attributes.title,
      data.attributes.content,
      data.attributes.creator_email,
      data.attributes.published_at,
      data.attributes.updated_at,
      data.attributes.created_at,
      !!data.attributes.published_at
    );
  }

  toRequest (signature: UserSignature) {
    return {
      data: {
        id: signature.id,
        type: 'user-signature',
        attributes: {
          title: signature.title,
          content: signature.content,
          published: signature.published,
        }
      }
    };
  }
}
