<div class="fivef-address-input-group-cva--wrapper">
  <div *ngFor="let address of addresses"
       class="fivef-address-input-group-cva--address">

    <dvtx-address-input-cva
      [enableLocationOrType]="enableLocationOrType"
      [address]="address"
      [disabled]="disabled"
      elementName="Adresssuche"></dvtx-address-input-cva>

    <div class="d-flex align-items-end" *ngIf="!disabled">
      <button *ngIf="!address.id"
              mat-button
              [disabled]="disabled"
              (click)="saveAddress(address)">
        <mat-icon>save</mat-icon>
        {{'GENERAL.SAVE_ACTION' | translate}}
      </button>

      <button mat-button
              [disabled]="disabled"
              (click)="deleteAddress(address)">
        <mat-icon>remove_circle_outline</mat-icon>
        {{'GENERAL.REMOVE_ACTION' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="!disabled && showAddAddress">
    <button mat-button [disabled]="disabled" type="button" (click)="addNewField()"
            [disableRipple]="true">
      <mat-icon>add</mat-icon>
      {{ 'CONTACTS.ADD_ADDRESS' | translate }}
    </button>
  </div>
</div>
