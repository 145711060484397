import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {DmsStatusService} from 'app/+store/dms-status/dms-status.service';
import {OrganizationSelectors} from 'app/+store/organization';

@Component({
  selector: 'dvtx-dav-contact-import-frame',
  templateUrl: './dav-contact-import-frame.component.html',
  styleUrls: ['./dav-contact-import-frame.component.scss']
})
export class DavContactImportFrameComponent implements OnInit {
  organization$;
  loading;

  constructor(private store: Store<AppState>,
              private _dmsStatusSvc: DmsStatusService) {}

  ngOnInit(): void {
    this.loading = true;
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
  }
}
