
import {map,  filter, distinctUntilKeyChanged } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {User} from '../../../../+store/user/user';
import {AppState} from '../../../../app.state';
import {Store} from '@ngrx/store';
import {Iam} from 'app/+store/_legacy/api/models/iam/activity';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DirectiveService {
  readonly subpath: string = '/workflow_engine/processes';
  public currentRoles: Observable<string[]>;
  public currentUser: Observable<User>;
  basePath;
  constructor(private _http: HttpClient,
              private _store: Store<AppState>) {
    this.currentUser = this._store.select('currentUser').pipe(filter(u => !!u), distinctUntilKeyChanged('uid'));
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;

    this.currentRoles = this.currentUser.pipe(
      filter((user) => !!user),
      map((user) => user.id),
      // .switchMap(() => this._tokenService.get(this.subpath))
      // .map((roles) => {
      //   return roles.json().map((role) => {
      //     return role.toLowerCase();
      //   })
      // })
      map(() => ['user']),);
  }

  public getAuthForRoom(roomId: number, userId): Observable<Iam.Activity[]> {
    return this._http.get(`${this.basePath}/${this.subpath}/${roomId}/permissions`).pipe(
      map((activities) => <Iam.Activity[]>ResourceBuilder.fromResponse(activities)));
  }
}
