import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CsvColumn, FivefCsvImportService, ParsedCsv} from './fivef-csv-import.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {DmsDocumentService} from 'app/+store/dms-document/dms-document.service';
import {first} from 'rxjs/operators';
import {FivefNotificationService} from '../../notification/fivef-notification/fivef-notification.service';

export interface Settings {
  columns: CsvColumn[];
  text: {
    displayTableTitle?: string;
    sampleTitle?: string;
    sampleInfo?: string;
  };
  hideHint?: boolean;
}

@Component({
  selector: 'dvtx-csv-import',
  templateUrl: './fivef-csv-import.component.html',
  styleUrls: ['./fivef-csv-import.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefCsvImportComponent),
      multi: true,
    }
  ]
})
export class FivefCsvImportComponent implements ControlValueAccessor, OnInit {
  public _settings: Settings;
  public templateDataUri;

  @Input()
  public excelTemplateUrl?: string;

  // <editor-fold desc="parsedCsv">
  public _parsedCsv: ParsedCsv;
  private onChangeListeners: Function[] = [];
  private onTouchListeners: Function[] = [];

  idid: string;
  ididLoading = false;

  @Input()
  public set settings(settings: Settings) {
    this._settings = settings;

    const templateCsv = this.csvImportService.templateCsv(settings.columns);
    this.templateDataUri = this.domSanitizer.bypassSecurityTrustUrl('data:text/csv;charset=utf-8,' + templateCsv);
    this.parsedCsv = undefined;
  }

  constructor(private csvImportService: FivefCsvImportService,
              private domSanitizer: DomSanitizer,
              private _notifyService: FivefNotificationService,
              private activatedRoute: ActivatedRoute,
              private dmsDocumentSvc: DmsDocumentService) {

  }

  ngOnInit() {
    // Document by import document ID.
    this.idid = this.activatedRoute.snapshot.queryParams['idid'];

    if (this.idid) {
      this.ididLoading = true;
      this.dmsDocumentSvc.downloadById(this.idid)
        .pipe(first())
        .subscribe(blob => {
          const _blob: any = blob;
          // A Blob() is almost a File() - it's just missing the two properties below which we will add
          _blob.lastModifiedDate = new Date();
          _blob.name = 'import.xlsx';
          this.ididLoading = false;
          this.onNewFile(<File>_blob);
        }, err => {
          console.error(err);
          this.ididLoading = false;
          this._notifyService.error('THIRD_PARTY.ERRORS.LOADING_ERROR_ON_IMPORT')
        });
    }
  }

  public get parsedCsv() {
    return this._parsedCsv;
  }

  public set parsedCsv(parsedCsv: ParsedCsv) {
    this._parsedCsv = parsedCsv;

    this.notifyOnTouch();
    this.notifyOnChange(parsedCsv);
  }
  // </editor-fold>

  // <editor-fold desc="Control Value Accessor implementation">
  public writeValue(obj: any): void {
    if (!obj) {
      this.parsedCsv = obj;
      return;
    }

    this.parsedCsv = {
      errors: [],
      children: obj.map((row) => {
        return {
          data: this._settings.columns.map((column) => {
            return row[column.key];
          }),
          errors: []
        };
      }),
      filename: '',
      totalNumberOfErrors: 0,
      value: obj
    };
  }

  public registerOnChange(fn: Function): void {
    this.onChangeListeners.push(fn);
  }

  public notifyOnChange(parsedCsv?: ParsedCsv) {
    const newValue = parsedCsv ? parsedCsv.value : parsedCsv;

    for (const changeListener of this.onChangeListeners) {
      changeListener(newValue);
    }
  }

  public registerOnTouched(fn: Function): void {
    this.onTouchListeners.push(fn);
  }

  public notifyOnTouch() {
    for (const touchListener of this.onTouchListeners) {
      touchListener();
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      throw new Error('Disabled state cannot be set for CsvImportComponent');
    }
  }
  // </editor-fold>

  public onNewFile(file: File) {
    this.csvImportService.parse(file, this._settings.columns).then((parsedCsv) => {
      this.parsedCsv = parsedCsv;
    });
  }

  // public browserCheck(event: Event) {
  //   if (window.navigator.msSaveBlob) {
  //     event.preventDefault();

  //     let file;

  //     try {
  //       file = new Blob([this.templateDataUri], {type: 'text.csv'});

  //     } catch (e) {
  //       if (MSBlobBuilder) {
  //         const builder = new MSBlobBuilder();

  //         builder.append([this.templateDataUri]);

  //         file = builder.getBlob('text.csv');
  //       }
  //     }

  //     window.navigator.msSaveBlob(file, 'sample.csv');
  //   }
  // }
}
