import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

export enum StatusColor {
  Info = 'info'
}

@Component({
  selector: 'fivef-info-block',
  standalone: true,
  host: { class: 'fivef-info-block'},
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './fivef-info-block.component.html',
  styleUrls: ['./fivef-info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefInfoBlockComponent {
  StatusColor = StatusColor;

  @Input()
  icon = 'info';

  @Input()
  color: 'info' | 'warn' | 'success' | null = null;

  @Input()
  text: string;
}
