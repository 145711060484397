import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DeleteDraft,
  DeleteDraftFail,
  DeleteDraftSuccess,
  DeleteMessage,
  DeleteMessageFail,
  DeleteMessageSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadDrafts,
  LoadDraftsFail,
  LoadDraftsSuccess,
  LoadMessageStats,
  LoadMessageStatsFail,
  LoadMessageStatsSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  LoadSent,
  LoadSentFail,
  LoadSentSuccess,
  MarkReadForResource,
  MarkReadForResourceDocuments,
  MarkReadForResourceDocumentsFail,
  MarkReadForResourceDocumentsSuccess,
  MarkReadForResourceFail,
  MessageActionTypes,
  SelectMessage,
  ToggleArchive,
  ToggleArchiveFail,
  ToggleArchiveSuccess,
  ToggleFavorite,
  ToggleFavoriteFail,
  ToggleFavoriteSuccess,
  ToggleRead,
  ToggleReadFail,
  ToggleReadSuccess
} from './message.actions';
import {MessageService} from './message.service';
import {IMessageParams, IMessageStat} from 'app/+store/message/message.interface';
import {Message} from './message';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class MessageEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.params).pipe(first()).pipe(
        switchMap((res: Message[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          // this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR')
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(first()).pipe(
        concatMap((message: Message) => {
          return [new LoadOneSuccess(message), new SelectMessage(message)];
        }),
        catchError(err => {
          // this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR')
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadDrafts$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.LoadDrafts),
    concatMap((action: LoadDrafts) => {
      return this._svc.getDrafts(action.params).pipe(first()).pipe(
        concatMap((res: Message[]) => {
          return [new LoadDraftsSuccess(res), new LoadMessageStats()];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadDraftsFail(err));
        }));
    })
  ));

  loadSent$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.LoadSent),
    concatMap((action: LoadSent) => {
      return this._svc.getSent(action.params).pipe(first()).pipe(
        concatMap((res: Message[]) => {
          return [new LoadSentSuccess(res), new LoadMessageStats()];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadSentFail(err));
        }));
    })
  ));

  loadStats$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.LoadMessageStats),
    switchMap((action: LoadMessageStats) => {
      return this._svc.getStats().pipe(first()).pipe(
        switchMap((stats: IMessageStat) => {
          return [new LoadMessageStatsSuccess(stats)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadMessageStatsFail(err));
        }));
    })
  ));

  toggleFavorite$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.ToggleFavorite),
    switchMap((action: ToggleFavorite) => {
      return this._svc.toggleFavorite(action.message).pipe(first()).pipe(
        switchMap((message: Message) => {
          return [new ToggleFavoriteSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ToggleFavoriteFail(err));
        }));
    })
  ));

  toggleRead$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.ToggleRead),
    switchMap((action: ToggleRead) => {
      return this._svc.toggleRead(action.message).pipe(first()).pipe(
        switchMap((message: Message) => {
          return [new ToggleReadSuccess(message), new LoadMessageStats()];
        }),
        catchError(err => {
          console.error(err);
          return of(new ToggleReadFail(err));
        }));
    })
  ));

  markReadForResource$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.MarkReadForResource),
    switchMap((action: MarkReadForResource) => {
      return this._svc.readMessagesForResource(action.id, action.findResourceBy).pipe(first()).pipe(
        switchMap((unreadResource: any) => {
          const params: IMessageParams = {
            page: 1,
            unread: true
          };
          return [new LoadAll(params)];
        }),
        catchError(err => {
          console.error(err);
          return of(new MarkReadForResourceFail(err));
        }));
    })
  ));

  markReadForResourceDocuments$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.MarkReadForResourceDocuments),
    switchMap((action: MarkReadForResourceDocuments) => {
      return this._svc.readMessagesForResourceDocuments(action.id).pipe(first()).pipe(
        switchMap((res: any) => {
          return [new MarkReadForResourceDocumentsSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new MarkReadForResourceDocumentsFail(err));
        }));
    })
  ));

  toggleArchive$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.ToggleArchive),
    switchMap((action: ToggleArchive) => {
      return this._svc.toggleArchive(action.message).pipe(first()).pipe(
        switchMap((message: Message) => {
          return [new ToggleArchiveSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ToggleArchiveFail(err));
        }));
    })
  ));

  deleteMessage$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.DeleteMessage),
    switchMap((action: DeleteMessage) => {
      return this._svc.deleteMessage(action.message).pipe(first()).pipe(
        concatMap((message: Message) => {
          return [new DeleteMessageSuccess(message), new LoadMessageStats()];
        }),
        catchError(err => {
          if (err.status === 404) {
            return of(new DeleteMessageSuccess(action.message));
          } else {
            console.error(err);
          }
          return of(new DeleteMessageFail(err));
        }));
    })
  ));

  deleteDraft$ = createEffect(() => this.actions.pipe(
    ofType(MessageActionTypes.DeleteDraft),
    switchMap((action: DeleteDraft) => {
      return this._svc.deleteDraft(action.message).pipe(first()).pipe(
        concatMap((message: Message) => {
          return [new DeleteDraftSuccess(message)];
        }),
        catchError(err => {
          if (err.status === 404) {
            return of(new DeleteDraftSuccess(action.message));
          } else {
            console.error(err);
          }
          return of(new DeleteDraftFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: MessageService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
