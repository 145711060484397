<div [formGroup]="form" class="dvtx-api-select">
  <mat-form-field class="dvtx-input-full-width">
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <mat-icon *ngIf="checkedOnChoose && showCheckIcon" matPrefix class="fivef-color-status-success">check</mat-icon>
    <input matInput
           type="text"
           placeholder="{{placeholder}}"
           [matAutocomplete]="auto"
           [matAutocompleteDisabled]="disabled"
           [id]="controlName"
           [formControlName]="controlName"
           [disabled]="disabled">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of listing | async" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</div>
