import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefBookmanClientSettingsComponent} from './fivef-bookman-client-settings/fivef-bookman-client-settings.component';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {FivefBookmanAuditTrailDialogComponent} from './fivef-bookman-audit-trail-dialog/fivef-bookman-audit-trail-dialog.component';
import {FivefBookmanAuditTrailComponent} from './fivef-bookman-audit-trail/fivef-bookman-audit-trail.component';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {InViewportDirective} from 'ng-in-viewport';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefDatevClientAuthorizationComponent} from './fivef-datev-client-authorization/fivef-datev-client-authorization.component';
import {FivefEditClientDialogComponent} from './fivef-edit-client-dialog.component';
import {FivefContextSidebarComponent} from '../../common/fivef-context-sidebar/fivef-context-sidebar.component';
import {MatTabsModule} from '@angular/material/tabs';
import {FivefContextSidebarContentDirective} from '../../common/fivef-context-sidebar/fivef-context-sidebar-content.directive';
import {FivefCreateClientComponent} from '../fivef-create-client/fivef-create-client.component';
import {FivefClientContactListingComponent} from './fivef-client-contact-listing/fivef-client-contact-listing.component';
import {FivefDisableInputHintsWrapperDirective} from '../../input/fivef-disable-input-hints-wrapper.directive';


@NgModule({
  declarations: [
    FivefBookmanClientSettingsComponent,
    FivefBookmanAuditTrailComponent,
    FivefBookmanAuditTrailDialogComponent,
    FivefDatevClientAuthorizationComponent,
    FivefEditClientDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    InViewportDirective,
    FivefDialogComponent,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTabsModule,
    FivefSearchComponent,
    FivefLoadingIndicatorComponent,
    FivefIconMessageBlockComponent,
    FivefContextSidebarComponent,
    FivefContextSidebarContentDirective,
    FivefCreateClientComponent,
    FivefClientContactListingComponent,
    FivefDisableInputHintsWrapperDirective
  ],
  exports: [
    FivefEditClientDialogComponent
  ]
})
export class FivefEditClientDialogModule {
}
