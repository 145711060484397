import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './label.state';
import {GLOBAL_LABELS, LabelOrigin} from './label.interface';
import {Label} from './label';

export const stateKey = 'label';
const getLabelState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getLabelEntities,
  selectAll: getAllLabels,
} = adapter.getSelectors(getLabelState);

export const getSelected = createSelector(
  getLabelState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getLabelEntities,
  (entities) => entities[id]
);

export const getOneByTitle = (title: string) => createSelector(
  getAll,
  (entities) => entities.find(entity => entity.title === title)
);

export const getAll = createSelector(
  getAllLabels,
  (entities) => entities
);

/**
 * All labels without virtual contextual labels.
 * ATTENTION: Please use LabelService->sortedLabels instead!
 * Which contains all labels in a translated way with the help of the translation service.
 */
const getAllExceptContextual = createSelector(
  getAllLabels,
  (entities) => entities.filter(entity => entity.origin === LabelOrigin.NO_ORIGIN)
);

/**
 * Returns all selectable labels including global ones.
 */
export const getSelectableLabels = createSelector(
  getAllExceptContextual,
  (labels: Label[]) => {
    if (!labels?.length) {
      return [];
    }

    const labelMap = {};
    // KANBAN Stuff: Remove virtual KANBAN unsorted label, return global label on match otherwise the normal label.
    const _labels = labels.filter(label => label.origin === LabelOrigin.NO_ORIGIN && label.title !== 'KANBAN.UNSORTED_GROUP').map(label => {
      labelMap[label.title] = true;
      const globalLabel = GLOBAL_LABELS.find(_label => _label.title === label.title);
      if (globalLabel) {
        globalLabel.id = label.id;
        return globalLabel;
      }
      return label;
    });

    // Create new global label set.
    const missingGlobalLabels = GLOBAL_LABELS.filter(label => !labelMap[label.title]);
    // Concat both, sorting is managed by the label service and with respect to translations.
    return [...missingGlobalLabels, ..._labels];
  }
);

export const loadingState = createSelector(
  getLabelState,
  (state) => state.loading
);
