import {Injectable} from '@angular/core';
import {KanbanApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/kanban-api-resource.service';
import {ItemLabels} from './item-labels';
import {ItemLabelsBuilder} from './item-labels.builder';
import {ItemType, LabelItemScope} from './item-labels.interface';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class ItemLabelsService {
  readonly BASE_PATH = 'api/v1/labels/items';
  readonly BASE_WFE_SCOPED_LABELS_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: KanbanApiResourceService) {
  }

  create(itemId: string, labelId: string, itemType: ItemType, processId: string): Observable<ItemLabels> {
    const builder = new ItemLabelsBuilder();

    const payload = {
      data: {
        attributes: {
          label_id: labelId,
          item_type: itemType,
          process_id: processId
        }
      }
    }
    return <Observable<ItemLabels>>this._http.post<ItemLabelsBuilder, ItemLabels>(builder, `${this.BASE_PATH}/${itemId}/labels`, payload);
  }

  remove(itemId: string, labelId: string): Observable<ItemLabels> {
    const builder = new ItemLabelsBuilder();
    return <Observable<ItemLabels>>this._http.del<ItemLabelsBuilder, ItemLabels>(builder, `${this.BASE_PATH}/${itemId}/labels/${labelId}`);
  }

  getAll(referencesIds: string[]): Observable<ItemLabels[]> {
    const builder = new ItemLabelsBuilder();
    const payload = {
      data: {
        attributes: {
          references_ids: referencesIds
        }
      }
    }
    return <Observable<ItemLabels[]>>this._http.postAll<ItemLabelsBuilder, ItemLabels>(builder, `${this.BASE_PATH}/all`, payload);
  }

  getByProcessIdAndScope(processId: string, scope: LabelItemScope): Observable<ItemLabels[]> {
    const builder = new ItemLabelsBuilder();
    return <Observable<ItemLabels[]>>this._http.get<ItemLabelsBuilder, ItemLabels>(builder, `${this.BASE_WFE_SCOPED_LABELS_PATH}/${processId}/label_items?scope=${scope}`);
  }

  getAllByLabels(labelsIds: string[]): Observable<ItemLabels[]> {
    const builder = new ItemLabelsBuilder();
    const payload = {
      data: {
        attributes: {
          labels_ids: labelsIds
        }
      }
    }
    return <Observable<ItemLabels[]>>this._http.postAll<ItemLabelsBuilder, ItemLabels>(builder, `${this.BASE_PATH}/all`, payload);
  }
}
