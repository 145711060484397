import {of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  ItemLabelsActionTypes,
  LoadAll,
  LoadAllByLabels,
  LoadAllByLabelsSuccess,
  LoadAllFail,
  LoadAllSuccess,
  LoadByProcessIdAndScope,
  LoadByProcessIdAndScopeSuccess,
  LoadByProcessIdAndScopeFail,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './item-labels.actions';
import {catchError, concatMap, first} from 'rxjs/operators';
import {ItemLabelsService} from './item-labels.service';
import {ItemLabels} from './item-labels';

@Injectable()
export class ItemLabelsEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(ItemLabelsActionTypes.Create),
    concatMap((action: Create) => {
      return this._svc.create(action.referenceId, action.labelId, action.itemType, action.processId).pipe(
        first(),
        concatMap((labelItem: ItemLabels) => {
          return [new CreateSuccess(labelItem)];
        }),
        catchError(err => {
          console.error(err);
          return observableOf(new CreateFail(err));
        })
      );
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ItemLabelsActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.referencesId).pipe(
        first(),
        concatMap((res: ItemLabels[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return observableOf(new LoadAllFail(err));
        }));
    })
  ));

  loadByProcessAndScope$ = createEffect(() => this.actions.pipe(
    ofType(ItemLabelsActionTypes.LoadByProcessIdAndScope),
    concatMap((action: LoadByProcessIdAndScope) => {
      return this._svc.getByProcessIdAndScope(action.processId, action.scope).pipe(
        first(),
        concatMap((res: ItemLabels[]) => {
          return [new LoadByProcessIdAndScopeSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return observableOf(new LoadByProcessIdAndScopeFail(err));
        }));
    })
  ));

  loadAllByLabels$ = createEffect(() => this.actions.pipe(
    ofType(ItemLabelsActionTypes.LoadAllByLabels),
    concatMap((action: LoadAllByLabels) => {
      return this._svc.getAllByLabels(action.labelsIds).pipe(
        first(),
        concatMap((res: ItemLabels[]) => {
          return [new LoadAllByLabelsSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return observableOf(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(ItemLabelsActionTypes.Remove),
    concatMap((action: Remove) => {
      return this._svc.remove(action.referenceId, action.labelId).pipe(
        first(),
        concatMap((labelItem: ItemLabels) => {
          labelItem.labels = labelItem.labels.filter(a => a.id !== action.labelId);
          labelItem.labelIds = labelItem.labelIds.filter(a => a !== action.labelId);
          return [new RemoveSuccess(labelItem)];
        }),
        catchError(err => {
          console.error(err);
          return observableOf(new RemoveFail(err));
        }));
    })
  ));

  constructor(
    private actions: Actions,
    private _svc: ItemLabelsService
  ) {
  }
}
