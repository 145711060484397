<div
  class="d-flex justify-content-center mat-elevation-z2 five-f-signature-settings-default-image five-f-signature-settings-default-image__preview-container"
  [ngClass]="cssPreviewClass">
  <ng-template [ngIf]="currentSignature?.content" [ngIfElse]="selectiontext">
    <img [src]="currentSignature.content"/>
  </ng-template>

  <ng-template #selectiontext>
    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center flex-column">
        <div class="h5">{{ 'SIGNATURE.ACCOUNT.SELECT_DEFAULT_SIGNATURE' | translate }}</div>
        <div class="w-100 d-flex justify-content-center">
          <p>{{ 'SIGNATURE.ACCOUNT.DEFAULT_SIGNATURE_SUPPORTED_FILE_TYPES' | translate }}</p></div>
      </div>
    </div>
  </ng-template>

  <span class="five-f-signature-preview-action-buttons">
    <button mat-icon-button type="button"
            (click)="signatureImageChangeRequest()"
            class="add-button"
            [disableRipple]="true" [matTooltip]="'SIGNATURE.ACTIONS.UPLOAD_VISUAL_SIGNATURE' | translate">
      <mat-icon>photo_camera</mat-icon>
    </button>

    <button mat-icon-button type="button"
            *ngIf="enableEdit && currentSignature?.content"
            (click)="signatureImageChangeEvent(currentSignature?.content, true)"
            class="add-button"
            [disableRipple]="true" [matTooltip]="'GENERAL.EDIT_ACTION' | translate">
      <mat-icon>edit</mat-icon>
    </button>

    <button mat-icon-button type="button"
            *ngIf="enableHandWriting"
            (click)="openHandWriteDialog()"
            class="add-button"
            [disableRipple]="true" [matTooltip]="'GENERAL.CREATE_SIGNATURE' | translate">
      <mat-icon svgIcon="sign"></mat-icon>
    </button>
    <button *ngIf="currentSignature?.content"
            mat-icon-button
            type="button" (click)="signatureRemove()"
            class="add-button" [disableRipple]="true"
            [matTooltip]="'GENERAL.DELETE_ACTION' | translate">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
  <input type="file" (change)="signatureImageChangeEvent($event)" class="d-none"
         [id]="'uploadSignatureButton-' + cssPreviewClass"
         accept="image/*"/>
</div>

<div *ngIf="enableSkipMetaInfoSupport && currentSignature?.content && skipMetaInfoSupported"
     class="mt-2 d-flex align-items-center">
  <div>
    <mat-checkbox [checked]="currentSignature.skipMetaInfo"
                  (change)="toggleSkipMetaInfo($event)">{{ 'SIGNATURE.SETTINGS.SKIP_META_INFO' | translate }}
    </mat-checkbox>
  </div>
  <button [matTooltip]="'SIGNATURE.SETTINGS.SKIP_META_INFO_TOOLTIP' | translate" mat-icon-button>
    <mat-icon>info</mat-icon>
  </button>
</div>

<ng-template #dialogTpl>
  <fivef-dialog [isCallToAction]="true">
    <image-cropper
      [imageChangedEvent]="signatureImageChangedEvent"
      [imageBase64]="imageBase64"
      [aspectRatio]="aspectRatio"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="true"
      [alignImage]="'left'"
      [resizeToHeight]="400"
      [backgroundColor]="'transparent'"
      format="png"
      [output]="'base64'"
      (imageCropped)="signatureImageCropEvent($event)"
      (imageLoaded)="signatureImageLoaded()"
      (loadImageFailed)="signatureImageLoadedFailed()"
      class="p-0">
    </image-cropper>

    <div fivef-dialog-footer>
      <button mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="primary"
              (click)="onSubmitCroppingClick()">{{ 'GENERAL.SAVE_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
