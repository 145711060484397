<fivef-labels [labels]="(itemLabels$ | async)?.labels"
              [dynamicWidth]="true"
              [readonly]="false"
              [maxToShow]="1"
              [showLabelsSelector]="true"
              [allLabels]="labels$ | async"
              (onClick)="onClick.emit($event)"
              (onAdd)="addLabel($event)"
              [canShowMore]="false"
              (onRemove)="removeLabel($event)"></fivef-labels>
