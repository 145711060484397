import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './sms-record';
import {Process} from '../../process/process';

export namespace Operator {
  export class SmsRecordBuilder implements IApiResourceBuilder<model.Operator.SmsRecord> {
    total = 1;
    perPage = 1;
    records = 1;

    fromResponse(data): model.Operator.SmsRecord {
      const sms = new model.Operator.SmsRecord(
        data.id,
        data.attributes.phone_number,
        data.attributes.status,
        data.attributes.performer_name,
        data.attributes.performer_email,
        data.attributes.provider,
        Process.humanProcessTypeFromString(data.attributes.process_type),
        data.attributes.created_at,
        data.attributes.updated_at);
      sms.total = this.total;
      sms.perPage = this.perPage;
      sms.records = this.records;
      return sms;
    }

    toRequest(_sms: model.Operator.SmsRecord) {
      return null;
    }

    addMetaSection(meta: any) {
      if (!meta) return;
      this.total = meta.total;
      this.perPage = meta.per_page;
      this.records = meta.records;
    }
  }
}
