import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Colors} from 'app/lib/color/hsl';
import {TranslateModule} from '@ngx-translate/core';
import {ILabel, LabelOrigin} from '../../../../+store/label/label.interface';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fivef-label',
  standalone: true,
  host: {class: 'fivef-label'},
  templateUrl: './fivef-label.component.html',
  styleUrls: ['./fivef-label.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefLabelComponent {
  colors = Colors;
  LabelOrigin = LabelOrigin;

  @Input()
  label: ILabel;

  @Input()
  readonly: boolean = false;

  @Input()
  preventClicking: boolean = false;

  @Output()
  onClick: EventEmitter<ILabel> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<ILabel> = new EventEmitter();

  @Input()
  disableLabel: boolean = false;

  public click(label: ILabel) {
    this.onClick.emit(label);
  }

  public remove($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.onRemove.emit(this.label)
  }
}
