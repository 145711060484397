import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefApiResourceService} from './services/fivef-api-resource.service';
import {EnvService} from './services/env.service';
import {KanbanApiResourceService} from './services/kanban-api-resource.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    FivefApiResourceService,
    KanbanApiResourceService,
    EnvService
  ],
})
export class FivefApiResourceModule {
}
