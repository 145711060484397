import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';

@Component({
  selector: 'fivef-emoji-menu',
  standalone: true,
  host: {'class': 'fivef-emoji-menu'},
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    PickerModule,
    MatFormFieldModule,
    MatMenuModule
  ],
  templateUrl: './fivef-emoji-menu.component.html',
  styleUrls: ['./fivef-emoji-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefEmojiMenuComponent {

  @Output()
  onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  public insertEmoji($event) {
    this.onSelection.emit($event);
  }
}
