import {Injectable} from '@angular/core';
import {visibilityTypes} from '../contact/contact';
import {getSelected} from '../organization/organization.selectors';
import {Organization} from '../organization/organization';
import {NewContact} from '../contact/contact.actions';
import {LoadOfOrganization} from '../invitation/invitation.actions';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {first} from 'rxjs/operators';
import {InviteService} from '../../modules/organization/components/organization-view/invite.service';
import {ContactOrganizationBaseForm, newOrganizationBaseForm} from '../../modules/organization/models/contact-organization-base-form';
import {SimplePhoneNumber} from '../contact/legacy/models/contact.interface';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

export interface IBasicContactOrganization {
  name: string;
  legalFormId?: string;
  email: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactOrganizationFactoryService {
  createContactForm: ContactOrganizationBaseForm = newOrganizationBaseForm();

  constructor(private _store: Store<AppState>,
              private inviteService: InviteService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }

  create(contact: IBasicContactOrganization,
         contactVisibility = {
           visibleFor: '',
           type: visibilityTypes.visiblePublic
         }) {
    this.createContactForm = newOrganizationBaseForm();

    this.createContactForm.contactOrganization.name = contact.name || '';
    this.createContactForm.contactOrganization.legalFormId = contact.legalFormId || '0';
    this.createContactForm.contactOrganization.mainEmailAddress.emailAddress = contact.email || '';
    if (contact.phone) {
      const phoneNumber = new SimplePhoneNumber();
      phoneNumber.phoneNumber = contact.phone;
      phoneNumber.countryCode = '49';
      phoneNumber.locationOrType = 'undefined';
      this.createContactForm.contactOrganization.phoneNumbers.push(phoneNumber);
    }

    this._store.select(getSelected)
      .pipe(first())
      .subscribe((orga: Organization) => {
        this._store.dispatch(new NewContact(orga, this.createContactForm, contactVisibility));
          this._notifyService.success('CONTACTS.CONTACT_TRANSFERED_TO_ADDRESSBOOK')
      });
  }
}
