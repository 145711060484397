import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export class CollectorTemplate implements IResource {
  readonly type = 'collector_templates';

  constructor(public id: string,
              public title: string,
              public description: string,
              public creator: string,
              public owner: string,
              public createdAt: Date,
              public updatedAt?: Date) {}
}


interface GroupsResponseType {
    title: string,
    groups: [
      {
        title: string,
        children: [
          {
            title: string,
            start_date: string,
            due_date: string,
            description: string,
            status: string,
            priority: string
          }
        ]
      }
    ]
}

export class CollectorDownloadTemplate implements IResource {
  readonly type = 'collector_download_templates';

  constructor(public id: string,
              public title: string,
              public groups: GroupsResponseType) {}
}
