import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum DavContactType {
  Person = 'Person',
  Organization = 'Organization'
}

export interface IDavContact extends IResource {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  invite?: boolean;
  contactType?: DavContactType;
  private?: boolean;
  imported?: boolean;
}
