<button mat-icon-button
        *ngIf="!isClosed && (isAuthor || enableReplyButton)"
        [matMenuTriggerFor]="menu"
        matTooltip="{{ 'PROJECT_ROOM.ACTIONS' | translate }}">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu" class="fivef-menu">
  <fivef-menu-item *ngIf="isAuthor"
                   [icon]="'edit'"
                   [title]="'PROJECT_ROOM.EDIT_COMMENT'"
                   (click)="onEditMode.emit($event)"></fivef-menu-item>
  <fivef-menu-item *ngIf="isAuthor"
                   [icon]="'delete'"
                   [title]="'PROJECT_ROOM.DELETE_COMMENT'"
                   (click)="deleteComment($event)"></fivef-menu-item>
  <fivef-menu-item *ngIf="enableReplyButton"
                   [icon]="'reply'"
                   [title]="'PROJECT_ROOM.REPLY_ACTION'"
                   (click)="onReply.emit($event)"></fivef-menu-item>
</mat-menu>
