import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Label} from 'app/+store/label/label';
import {LabelOrigin} from '../../../../+store/label/label.interface';
import {CommonModule} from '@angular/common';
import {FivefLabelComponent} from '../fivef-label/fivef-label.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefLabelsSelectorComponent} from '../fivef-labels-selector/fivef-labels-selector.component';
import {LabelsModule} from '../../../fivef-legacy/labels/labels.module';

@Component({
  selector: 'fivef-labels',
  standalone: true,
  host: {class: 'fivef-labels'},
  imports: [
    CommonModule,
    FivefLabelComponent,
    TranslateModule,
    MatTooltipModule,
    LabelsModule,
    FivefLabelsSelectorComponent
  ],
  templateUrl: './fivef-labels.component.html',
  styleUrls: ['./fivef-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefLabelsComponent implements OnInit, OnDestroy, AfterViewInit {
  private sizeObserver: ResizeObserver;

  private MAX_WIDTH_LABEL = 130;
  private LABEL_INDICATOR_WIDTH = 30;

  @ViewChild('labelsListRef') labelsListRef: ElementRef<HTMLDivElement>;
  _labelsIds: string[];
  _labels: Label[];

  @Input()
  canShowMore: boolean = false;

  @Input()
  allLabels: Label[];

  @Input()
  maxToShow: number = 5;

  @Input()
  readonly: boolean = false;

  @Input()
  showLabelsSelector: boolean = false;

  @Input()
  classList: string = '';

  @Input()
  appearance: 'button' | 'iconButton' = 'iconButton';

  @Input()
  filterOutContextual: boolean = true;

  @Input()
  dynamicWidth: boolean = false;

  @Input()
  showAll: boolean = false;

  @Output()
  onClick: EventEmitter<Label> = new EventEmitter();

  @Output()
  onShowMoreClickFun: EventEmitter<any> = new EventEmitter();

  @Output()
  onAdd: EventEmitter<Label> = new EventEmitter();

  @Output()
  onRemove: EventEmitter<Label> = new EventEmitter();

  isShowMore = false;
  numberOfVisibleLabels = this.dynamicWidth ? 1 : this.maxToShow;

  @Input()
  set labels(labels: Label[]) {
    if (Array.isArray(labels)) {
      this._labels = labels.filter(label => {
        return label && this.filterOutContextual
          ? label.origin === LabelOrigin.NO_ORIGIN
          : label.origin !== LabelOrigin.DOCUMENTS_UNSORTED && label.title !== 'KANBAN.UNSORTED_GROUP';
      });
      this._labelsIds = this._labels.map(label => label.id);
    } else {
      this._labels = [];
      this._labelsIds = [];
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // @ts-ignore
    this.sizeObserver = new ResizeObserver(this.resized);
  }

  ngOnDestroy(): void {
    this.sizeObserver.unobserve(this.labelsListRef.nativeElement)
  }

  ngAfterViewInit() {
    if (this.labelsListRef && this.dynamicWidth) {
      this.sizeObserver.observe(this.labelsListRef.nativeElement);
    }
  }

  private resized = (entries, _observer) => {
    for (const entry of entries) {
      const availableWidth = entry.contentRect.width;
      this.numberOfVisibleLabels = Math.max(0, Math.floor((availableWidth - this.LABEL_INDICATOR_WIDTH) / this.MAX_WIDTH_LABEL));
    }
    this.cdr.detectChanges();
  }
}
