import {CacEntityActions, CacEntityActionTypes} from './cac-entity.actions';
import {adapter, initialState, State} from './cac-entity.state';

export function reducer(state = initialState, action: CacEntityActions): State {
  switch (action.type) {
    case CacEntityActionTypes.LoadAll:
      return {
        ...state,
        loading: true
      };
    case CacEntityActionTypes.LoadAllSuccess:
      return adapter.setAll(action.societies, {
          ...state,
          loading: false,
      });
    case CacEntityActionTypes.LoadAllFail:
      return {
        ...state,
        loading: false
      };
    case CacEntityActionTypes.Load:
      return {
        ...state,
        loading: true
      };
    case CacEntityActionTypes.LoadSuccess:
      return adapter.upsertOne(action.society, {
        ...state,
        loading: false,
      });
    case CacEntityActionTypes.LoadFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

