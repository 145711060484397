import {ILabel, LabelOrigin, LabelScope} from './label.interface';
import {KanbanGroup} from '../kanban-store/kanban-group/kanban-group';

export class Label implements ILabel {
  readonly type = 'label';
  public isSelected = false;
  public group?: KanbanGroup = null;
  public isGroup?: boolean = false;
  public groupId?: string = null;
  public referenceId?: string;

  constructor(public id: string,
              public icon: string,
              public color: string,
              public title: string,
              public description: string,
              public isGlobal: boolean = false,
              public scope: LabelScope,
              public origin: LabelOrigin,
              public usageCount: number = 0,
              public isSVGIcon: boolean = false,
              public createdBy: string) {
  }
}
