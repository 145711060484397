<div class="chooser-container">
  <mat-radio-group [(ngModel)]="contactVisibility" (ngModelChange)="notify()" [disabled]="disabled">
    <mat-radio-button *ngFor="let visibility of visibilities"
                      matTooltip="{{contactVisibility === visibility.value? (visibility.tooltip_active | translate) : (visibility.tooltip_inactive | translate)  }}"
                      [value]="visibility.value">
      <span class="d-flex align-items-center">
      <mat-icon class="mr-1">{{ visibility.icon }}</mat-icon>
        {{ visibility.label | translate }}
    </span>
    </mat-radio-button>
  </mat-radio-group>
</div>
