import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {ContactOrganization} from './contact-organization';

export interface State extends EntityState<ContactOrganization> {
  loading: boolean
}

export const adapter = createEntityAdapter<ContactOrganization>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});

