import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LicenceControlService} from 'app/services/licence-control.service';
import {Organization} from 'app/+store/organization/organization';
import {AppState} from 'app/app.state';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {OrganizationSelectors} from 'app/+store';
import {Router} from '@angular/router';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {Observable} from 'rxjs/internal/Observable';

@Directive({
  selector: '[dvtxQuickStart]'
})
export class QuickstartDirective implements OnInit {

  @Input() parent = null;
  @Input() profile: ProcessProfile = ProcessProfile.StandardProfile;
  @Input() modalClass: string;
  @Input() size: string;
  @Input() dmsPathChooser = true;
  @Input() processId = null;
  @Output() onModalCall = new EventEmitter<any>();

  currentModalComponent;
  modalSizeClass;

  organizations: Observable<Organization[]>;
  organizationsCount: number;

  constructor(private el: ElementRef,
              private _dialog: MatDialog,
              private licenceControl: LicenceControlService,
              private _store: Store<AppState>,
              private _router: Router) {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations.subscribe((organizations) => {
      this.organizationsCount = organizations.length;
    })
  }

  ngOnInit(): void {
    this.licenceControl.elementRef = this.el;
    // For development. TODO: Remove when finished.
    // this.currentModalComponent = QuickShareDialogContainerComponent;
    // setTimeout(_ => this.openDialog(), 100);
  }

  @HostListener('click')
  onClick() {
    this.chooseComponent(this.modalClass);
  }

  openDialog() {
    console.log(this);
    setTimeout(_ => {
      this._dialog.open(this.currentModalComponent,
        {
          panelClass: this.modalSizeClass,
          data: {
            options: {
              parent: this.parent,
              dmsPathChooser: this.dmsPathChooser,
              processId: this.processId
            }
          }
        });
    }, 0);
  }

  chooseComponent(type, size = 'md') {
    this.modalSizeClass = `dialog-${size}`;
    switch (type) {
      case 'SendMessageDialog':
        const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
        if (licenceChecker) {
          const queryParams = {parent: this.parent, profile: this.profile || ProcessProfile.StandardProfile}
          this._router.navigate([`project-room/new`], {queryParams: queryParams});
        }
        break;
      default:
        throw new Error('Unknown dialog component.');
    }
  }
}
