import {IResource} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';

export class InvoiceAddress implements IResource {
  readonly type = 'sales_invoice_addresses';

  get full(): string {
    return `${this.firstName} ${this.lastName}, ${this.street} ${this.streetNo}, ${this.city}`;
  }

  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public organizationName: string,
    public street: string,
    public streetNo: string,
    public zip: string,
    public city: string,
    public country: string,
    public ustId: string,
    public taxIdDe: string = null
  ) {}
}
