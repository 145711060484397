import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiErrorBuilder} from 'app/+store/_legacy/api/builders/api-error.builder';
import {LicenceProductBuilder} from 'app/+store/_legacy/api/builders/licence-product.builder';
import {LicenceProduct} from 'app/+store/_legacy/api/models/licence';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

/**
 * Customer API for the products sold in the checkout wizard.
 */
@Injectable()
export class ProductService {
  readonly apiPath = 'sales/products';
  basePath;
  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.apiPath}`;
  }

  getAll(): Observable<LicenceProduct[]> {
    return this._tokenService.get(this.basePath).pipe(
      map((res) => LicenceProductBuilder.fromResponseList(res)),
      catchError(ApiErrorBuilder.handleArrayError),);
  }
}
