import {distinctUntilChanged, filter, first, startWith, takeUntil} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {OrganizationActions, OrganizationSelectors} from 'app/+store';
import {Organization} from 'app/+store/organization/organization';
import {LoadMy} from 'app/+store/membership/membership.actions';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {OrganizationProxy} from 'app/+store/organization/organization-proxy';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'dvtx-organization-master-data',
  templateUrl: './organization-master-data.component.html',
  styleUrls: ['./organization-master-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationMasterDataComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  public organization: Organization;

  public consultantId: string;

  private refreshSubject: Subject<void> = new Subject();

  @Input()
  readonly = true;

  @Input()
  editmode = false;

  constructor(private store: Store<AppState>,
              private notifyService: FivefNotificationService,
              private organizationProxySvc: OrganizationProxyService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(orga => !!orga),
        distinctUntilChanged((x, y) => x.id === y.id),
        takeUntil(this.onDestroy)
      )
      .subscribe(orga => {
        this.store.dispatch(new OrganizationActions.LoadDetailed(orga.id));
        this._fetchApiOrganizationProxy(orga.id);
      });


    combineLatest(
      this.store.select(OrganizationSelectors.getSelected),
      this.refreshSubject.pipe(startWith(undefined)),
      (organ: Organization) => {
        return organ
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe((organ: Organization | undefined) => {
        if (organ) {
          this.organization = Object.assign({}, organ);
          this.organization.address = Object.assign({}, organ.address);
          this.organization.telephone = Object.assign({}, organ.telephone);
          this.organization.email = Object.assign({}, organ.email);
          this.store.dispatch(new LoadMy(this.organization.id));
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public refresh() {
    this.refreshSubject.next();
  }

  public save() {
    if (!this.organization || !this.organization.id) {
      this.editmode = false;
      return;
    }

    this.store.dispatch(new OrganizationActions.UpdateOrga(this.organization));
    this._updateApiOrganizationProxy();
    this.editmode = false;
    this.cdr.detectChanges();
  }

  /**
   * Fetches the organization model at the API to support DATEV document export in the Project Room
   * by the consultant number. This model is an addition to the CPP service organization.
   */
  private _fetchApiOrganizationProxy(id: string) {
    if (!id) return;

    this.organizationProxySvc.getOne(id)
      .pipe(first())
      .subscribe((org: OrganizationProxy) => {
        this.consultantId = org.consultantId;
        this.cdr.detectChanges();
      });
  }

  /**
   * Updates the organization model at the API to support DATEV document export in the Project Room
   * by the consultant number. This model is an addition to the CPP service organization.
   */
  private _updateApiOrganizationProxy() {
    if (!this.organization || !this.organization.id) return;

    const org = new OrganizationProxy(this.organization.id, this.organization.name, null, this.organization.legalFormId, null,
      null, this.consultantId, null, null);
    this.organizationProxySvc.update(this.organization.id, org)
      .pipe(first())
      .subscribe((_org: OrganizationProxy) => {
        this.editmode = false;
        this.cdr.detectChanges();
        this.notifyService.success('GENERAL.UPDATE_ACTION_DONE');
      }, err => {
        console.error(err);
        this.notifyService.error('HTTP_ERROR.DEFAULT');
      });
  }
}
