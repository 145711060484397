import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './cac.state';

export const stateKey = 'cac';
const getCacState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCacEntities,
  selectAll: getAllCacProcesses,
} = adapter.getSelectors(getCacState);

export const getSelected = createSelector(
  getCacState,
  (state) => state.selected
);

export const getOne = (id: string) => createSelector(
  getCacEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllCacProcesses,
  (entities) => entities
);

export const loadingState = createSelector(
  getCacState,
  (state) => state.loading
);

export const appendixMap = (cacId: string) => createSelector(
  getOne(cacId),
  (cac) => {
    const idx = {};
    cac?.appendices?.forEach(a => {
      idx[a.id] = a;
    });
    return idx;
  }
);

export const getAttachmentsofAppendix = (cacId: string, aid: string) => createSelector(
  getOne(cacId),
  appendixMap(cacId),
  (cac, idx) => {
    return idx[aid]?.attachments || [];
  }
);
