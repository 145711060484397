import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {NotificationActionTypes, ShowHttpError} from './notification.actions'
import {tap} from 'rxjs/operators'
import {FivefNotificationService} from '../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationEffects {
  showHttpErrorNotification = createEffect(() => this.actions.pipe(
    ofType(NotificationActionTypes.ShowHttpError),
    tap((action: ShowHttpError) => {
      const error = action.error;
      if (error && error.status && error.status !== 401 && error.status !== 403) {
        console.error(action.error);
        const code = action.error.error && action.error.error.code ? action.error.error.code : 'HTTP_ERROR.DEFAULT';
        const errorText = this.translate.instant(code);
        this.notificationService.error(errorText)
      }
    })
  ), { dispatch: false });

  constructor(private actions: Actions,
              private notificationService: FivefNotificationService,
              private translate: TranslateService
  ) {
  }
}
