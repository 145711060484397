import {adapter, initialState, State} from './cache-froalo.state';
import {CacheFroalaActions, CacheFroalaActionTypes} from './cache-froala.actions';

export function reducer(state = initialState, action: CacheFroalaActions): State {
  switch (action.type) {
    case CacheFroalaActionTypes.Create:
      return adapter.upsertOne(action.cacheFroala, {
        ...state
      });
    case CacheFroalaActionTypes.Remove:
      return adapter.removeOne(action.cacheFroalaId, state)
    case CacheFroalaActionTypes.Reset:
      return initialState
  }
  return state
}
