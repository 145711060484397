import { IApiResourceBuilder } from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import { BookmanClient } from './bookman-client';

export class BookmanClientBuilder implements IApiResourceBuilder<BookmanClient> {

  fromResponse(data): BookmanClient {
    return new BookmanClient(
      data.attributes.client_id,
      data.attributes.name,
    );
  }

  toRequest(_: BookmanClient) {
    return null;
  }
}
