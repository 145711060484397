import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorAttachmentActionTypes,
  Download,
  DownloadFail,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  PickFileFromDms,
  PickFileFromDmsFail,
  PickFileFromDmsSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  SaveDownload
} from './collector-attachment.actions';
import {CollectorAttachment} from './collector-attachment';
import {CollectorAttachmentService} from './collector-attachment.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {FileUtils} from 'app/lib/file_utils/functions';

@Injectable()
export class CollectorAttachmentEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(CollectorAttachmentActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.collectorId).pipe(
        first(),
        concatMap((res: CollectorAttachment[]) => {
          // const folders: Update<CollectorAttachment>[] = [];
          // for (const item of res) {
          //   folders.push( item);
          // }
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(CollectorAttachmentActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.collectorId, action.attachmentId).pipe(
        first(),
        concatMap((attachment: CollectorAttachment) => {
          return [new RemoveSuccess(attachment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  download$ = createEffect(() => this.actions.pipe(
    ofType(CollectorAttachmentActionTypes.Download),
    switchMap((action: Download) => {
      return this._svc.download(action.collectorId, action.id).pipe(
        first(),
        switchMap((attachment: CollectorAttachment) => {
          return [new SaveDownload(attachment.filename, attachment.content, attachment.mimeType)];
        }),
        catchError(err => {
            this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE');
          return of(new DownloadFail(err));
        }));
    })
  ));

  saveTemplate$ = createEffect(() => this.actions.pipe(
    ofType(CollectorAttachmentActionTypes.SaveDownload),
    switchMap((action: SaveDownload) => {
      FileUtils.saveAsFile(action.filename, action.content, action.mimeType);
      return of(null);
    })
  ), {dispatch: false});

  pickFileFromDms$ = createEffect(() => this.actions.pipe(
    ofType(CollectorAttachmentActionTypes.PickFileFromDms),
    concatMap((action: PickFileFromDms) => {
      return this._svc.pickFileFromDms(action.collectorId, action.nodeId, action.dmsDocumentId, action.dmsAccountType).pipe(
        first(),
        concatMap((attachment: CollectorAttachment) => {
          return [new PickFileFromDmsSuccess(attachment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PickFileFromDmsFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: CollectorAttachmentService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




