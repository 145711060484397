<div class="w-100 pt-4 d-flex justify-content-center dvtx-401-error-page">
  <div class="text-center d-flex d-flex flex-column">
    <h2 class="text-center mb-2">{{ 'AUTH.NOT_FOUND_OR_NOT_PERMITTED' | translate }}</h2>

    <div [ngSwitch]="lang">
      <ng-container *ngSwitchCase="'de'">
        <img src="../../../../../assets/img/401_de.png" height="691" width="643" />
      </ng-container>

      <ng-container *ngSwitchDefault>
        <img src="../../../../../assets/img/401_en.png" height="691" width="643" />
      </ng-container>
    </div>
  </div>
</div>
