import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Task} from 'app/+store/task/task';
import {TaskActions, TaskAssigneeActions} from 'app/+store';
import {TaskResource} from 'app/+store/task-resource/task-resource';
import {TaskResourceType} from 'app/+store/task-resource/task-resource.interface';
import {CreateTaskDialogComponent} from 'app/modules/tasks/component/create-task-dialog/create-task-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TaskAssignee} from 'app/+store/task-assignee/task-assignee';
import {
  PRIORITIES_MAP
} from '../../../../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.interface';
import {TaskType} from '../../../../../+store/task/task.interface';

@Injectable()
export class TaskActionsService {
  _titleEditing = false;

  public set titleEditing(process) {
    this._titleEditing = process;
  }

  public get titleEditing() {
    return this._titleEditing;
  }

  constructor(private _store: Store<AppState>, private _ngZone: NgZone, private _dialog: MatDialog) {
  }

  public onTitleChanges(task: Task = null, title, taskTypeControl, process) {
    this.titleEditing = false;
    if (!task) {
      this.createTask(title, taskTypeControl.value, process);
    } else {
      task.title = title;
      this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Edit(task)));
    }
    taskTypeControl.markAsUntouched();
  }

  private openNewTaskDialog(task, reference = null, referenceId: string = null, referenceType: TaskResourceType = null) {
    const dialogRef = this._dialog.open(CreateTaskDialogComponent, {
      panelClass: 'dialog-md',
      data: {
        task: task,
        referenceId: referenceId,
        onSaveChanges: async (newTask) => {
          newTask.assignees = newTask.assignees.map(assignee => {
            if (typeof assignee !== 'string') return assignee;
            return new TaskAssignee(null, assignee, null, null, null, null)
          });
          this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Create(newTask, reference, referenceId, referenceType)));
          dialogRef.close();
        }
      }
    });
  }

  public createTask(title, taskType, process) {
    const task = new Task(null, null);
    task.title = title;
    task.process = {
      id: process.id,
      color: process.color
    };
    task.taskType = taskType;
    task.color = process.color;
    task.priority = PRIORITIES_MAP.medium;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Create(task)));
  }

  public createTaskDialogRequest(process) {
    const task = new Task(null, null);
    task.priority = PRIORITIES_MAP.medium;
    task.taskType = TaskType.Standard;
    task.process = {
      id: process.id,
      title: process.title,
      color: process.color,
      dueDate: process.dueDate ? process.dueDate.toString() : null
    };
    task.color = process.color;
    task.dates.dueDate = process.dueDate ? process.dueDate.toString() : null;
    task.color = process.color;

    this.openNewTaskDialog(task);
  }

  public createDocumentTask(doc, process, color: string, referenceId: string = null, referenceType: TaskResourceType = null) {
    if (!process || process.status.isClosed()) return;

    const task = new Task(null, null);
    task.taskType = TaskType.Standard;
    task.priority = PRIORITIES_MAP.medium;
    task.process = {
      id: process.id,
      title: process.title,
      color: process.color,
      dueDate: process.dueDate ? process.dueDate.toString() : null
    };
    task.color = process.color;
    task.dates.dueDate = process.dueDate ? process.dueDate.toString() : null;
    task.title = doc.fileName;
    task.color = color || doc.color || process.color;

    const resource = new TaskResource();
    resource.title = doc.title;
    resource.description = doc.description;
    resource.resourceId = doc.id;
    resource.resourceType = TaskResourceType.Document;

    this.openNewTaskDialog(task, resource, referenceId, referenceType);
  }

  createTaskFromCollectorItem(element, collecto) {
    const task = new Task(null, null);
    task.priority = PRIORITIES_MAP.medium;
    task.taskType = TaskType.Standard;
    task.process = {
      id: collecto.id,
      title: collecto.title,
      color: collecto.color,
      dueDate: collecto.dueDate
    };
    task.title = "";
    task.description = element.description;
    task.color = element.color;

    const resource = new TaskResource();
    resource.resourceType = TaskResourceType.CollectorNode;
    resource.resourceId = element.id;
    resource.title = element.title;
    resource.description = element.description;

    this.openNewTaskDialog(task, resource);
  }

  createTaskForResource(process, resource, resourceType: TaskResourceType) {
    if (resourceType === TaskResourceType.CollectorNode) {
      this.createTaskFromCollectorItem(resource, process)
    } else if (resourceType === TaskResourceType.Appendix) {
      this.createTaskFromAppendix(resource);
    }
  }

  createTaskFromAppendix(appendix) {
    const task = new Task(null, null);
    task.taskType = TaskType.Standard;
    task.title = appendix.title;
    task.priority = PRIORITIES_MAP.medium;
    task.process = {id: appendix.processId};
    task.description = appendix.description;

    const resource = new TaskResource();
    resource.resourceType = TaskResourceType.Appendix;
    resource.resourceId = appendix.id;
    resource.title = appendix.title;
    resource.description = appendix.description;

    this.openNewTaskDialog(task, resource);
  }

  public async updateResponsible(task: Task, email: string) {
    const oldResponsible = await task.responsible.email;
    if (!task.assignees.find(assignee => assignee.email === oldResponsible)) {
      await this.addAssignee(task, oldResponsible);
    }
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.AddResponsible(task.id, email)));
  }

  public addAssignee(task: Task, email: string) {
    const assignee = task.assignees.find(a => a.email === email);
    if (assignee) return;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskAssigneeActions.AddAssignment(task.id, email)));
  }

  public removeAssignee(task: Task, email: string) {
    const assignee = task.assignees.find(a => a.email === email);
    console.error(email, task.assignees)
    if (!assignee) return;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskAssigneeActions.Delete(task.id, assignee.id)));
  }

  public updateDueDate(task: Task, dueDate) {
    task.dates.dueDate = dueDate;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Edit(task)));
  }

  public onStatusChanges(task: Task, status) {
    task.status = status;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Edit(task)));
  }

  public onPriorityChanges(task: Task, priority) {
    task.priority = priority;
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Edit(task)));
  }

  public closeTask(taskId) {
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Close(taskId)));
  }

  public reopenTask(taskId) {
    this._ngZone.runOutsideAngular(() => this._store.dispatch(new TaskActions.Open(taskId)));
  }
}
