import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './collector.state';
import {CollectorCategorySelectors} from '../collector-category';
import {CollectorItemSelectors} from '../collector-item';
import {StatusNode} from './collector';

export const stateKey = 'quickcollector';
const getCollectorState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCollectorEntities,
  selectAll: getAllCollectors,
} = adapter.getSelectors(getCollectorState);

export const getSelected = createSelector(
  getCollectorState,
  (state) => state.selected
);

export const getElementStatusTree = (id: string) => createSelector(
  CollectorCategorySelectors.getCategoriesByCollectorId(id),
  CollectorItemSelectors.getAll,
  (categories, items) => {
    const categoryMap = {};
    categories.forEach(category => categoryMap[category.id] = []);
    items.forEach(item => {
      if (categoryMap[item.categoryId]) {
        categoryMap[item.categoryId].push(item);
      }
    });
    return categories.map(category => {
      const node = new StatusNode(category.id, category.status, category.dueDate, category.completedAt);
      node.children = categoryMap[category.id].map(item => new StatusNode(item.id, item.status, item.dueDate, item.completedAt));
      return node;
    });
  });

export const getOne = (id: string) => createSelector(
  getCollectorEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllCollectors,
  (entities) => entities
);

export const loadingState = createSelector(
  getCollectorState,
  (state) => state.loading
)

export const creatingState = createSelector(
  getCollectorState,
  (state) => state.creating
)

export const updatingState = createSelector(
  getCollectorState,
  (state) => state.updating
)

export const removingState = createSelector(
  getCollectorState,
  (state) => state.removing
)

/**
 * Returns the closed status of a process.
 * There are two ways to find out if a process is closed depending on the existing data set:
 * A full available process has an internal status object.
 * A dense process object has a state property containing the 'closed' state as string.
 * @param id - Process ID.
 */
export const isClosed = (id: string) => createSelector(
  getCollectorEntities,
  entities => {
    const process = entities[id];
    if (process && process['state'] && process['state'] === 'closed') {
      return true;
    }

    return false;
  }
);
