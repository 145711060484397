import {CollectorActions, CollectorActionTypes, UpsertDataNodesSuccess} from './collector.actions';
import {adapter, initialState, State} from './collector.state';

export function reducer(state = initialState, action: CollectorActions): State {
  const current = state.selected;
  switch (action.type) {
    /*
      LOAD ONE REDUCERS
    */
    case CollectorActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: action.loading
      };
    case CollectorActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        loading: false,
      });
    case CollectorActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };

    /*
      LOAD ALL REDUCERS
    */
    case CollectorActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case CollectorActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.quickcollectors, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case CollectorActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };

    /*
      CREATE REDUCERS
    */
    case CollectorActionTypes.Create:
      return {
        ...state,
        selected: null,
        creating: true
      };
    case CollectorActionTypes.CreateSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        creating: false,
      });
    case CollectorActionTypes.CreateFail:
      return {
        ...state,
        selected: null,
        creating: false,
      };

    /*
      SAVE DRAFT REDUCERS
    */
    case CollectorActionTypes.SaveDraft:
      return {
        ...state,
        updating: true
      };
    case CollectorActionTypes.SaveDraftSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case CollectorActionTypes.SaveDraftFail:
      return {
        ...state,
        selected: null,
        updating: false,
      };

    /*
      PUBLISH REDUCERS
    */
    case CollectorActionTypes.Publish:
      return {
        ...state,
        creating: true,
        updating: true
      };
    case CollectorActionTypes.PublishSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
        creating: false
      });
    case CollectorActionTypes.PublishFail:
      return {
        ...state,
        updating: false,
        creating: false
      };

    /*
      UPDATE REDUCERS
    */
    case CollectorActionTypes.UpdateRecipients:
      return {
        ...state,
        updating: true
      };
    case CollectorActionTypes.UpdateRecipientsSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case CollectorActionTypes.UpdateRecipientsFail:
      return {
        ...state,
        selected: null,
        updating: false,
      };


    /*
      ORDER ITEMS
    */
    case CollectorActionTypes.OrderItems:
      return {
        ...state,
        updating: true
      };
    case CollectorActionTypes.OrderItemsSuccess:
      return {
        ...state,
        updating: false
      };
    case CollectorActionTypes.OrderItemsFail:
      return {
        ...state,
        updating: false
      };
    case CollectorActionTypes.Duplicate:
      return {
        ...state,
        updating: true
      };
    case CollectorActionTypes.DuplicateSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case CollectorActionTypes.DuplicateFail:
      return {
        ...state,
        updating: false,
      };
  }
  return state;
}
