import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {TaskAssigneeBuilder} from './task-assignee.builder';
import {TaskAssignee} from './task-assignee';

@Injectable()
export class TaskAssigneeService {
  readonly base_path = 'api/v1/tasks';

  builder: TaskAssigneeBuilder;

  constructor(private _http: FivefApiResourceService) {
    this.builder = new TaskAssigneeBuilder();
  }

  getAll(): Observable<TaskAssignee[]> {
    return <Observable<TaskAssignee[]>>this._http.get<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/assignments`);
  }

  getOne(taskId: string): Observable<TaskAssignee[]> {
    return <Observable<TaskAssignee[]>>this._http.get<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/tasks/${taskId}/assignees?archived=true`);
  }

  remove(taskId, assigneeId): Observable<TaskAssignee> {
    return <Observable<TaskAssignee>>this._http.del<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/tasks/${taskId}/assignees/${assigneeId}`);
  }

  add(taskId , assigneeEmail): Observable<TaskAssignee> {
   const payload = {
      data: {
        attributes: {
          email: assigneeEmail
        }
      }
    };
    return <Observable<TaskAssignee>>this._http.post<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/tasks/${taskId}/assignees`, payload);
  }

  accept(assignmentID: string): Observable<TaskAssignee> {
    return <Observable<TaskAssignee>>this._http.post<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/assignments/${assignmentID}/accept`, {});
  }

  reject(assignmentID: string): Observable<TaskAssignee> {
    return <Observable<TaskAssignee>>this._http.post<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/assignments/${assignmentID}/reject`, {});
  }

  update(assignment: TaskAssignee): Observable<TaskAssignee> {
    const builder = new TaskAssigneeBuilder();
    const payload = builder.toRequest(assignment);
    return <Observable<TaskAssignee>>this._http.put<TaskAssigneeBuilder, TaskAssignee>(this.builder, `${this.base_path}/tasks/${assignment.taskId}/assignees/${assignment.id}`, payload);
  }

}
