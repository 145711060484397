import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DmsFolderActions} from '../../../../+store';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';

/**
 * Folder creation dialog.
 * Takes the parent and creates the folder async by +store.
 */
@Component({
  templateUrl: './fivef-create-folder-dialog.component.html',
  styleUrls: ['./fivef-create-folder-dialog.component.scss'],
  standalone: true,
  imports: [
    FivefDialogComponent,
    MatInputModule,
    FormsModule,
    TranslateModule,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCreateFolderDialogComponent implements AfterViewInit {
  /**
   * Name input field reference.
   * @private
   */
  @ViewChild('creationField')
  private creationField: ElementRef<any>;

  /**
   * New folder name.
   */
  public folderName: string = null;

  /**
   * Target parent folder.
   */
  public parentFolder: DmsFolder;

  /**
   * Listen on enter to close the dialog.
   * @param _event
   */
  @HostListener('window:keyup.Enter', ['$event'])
  public onDialogClick(_event: KeyboardEvent): void {
    if (this.folderName && this.folderName.length) {
      this.createFolder();
    }
  }

  constructor(private store: Store<AppState>,
              private dialogRef: MatDialogRef<FivefCreateFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { folder: DmsFolder }) {
    this.parentFolder = data.folder;
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      if (this.creationField && this.creationField.nativeElement)
        this.creationField.nativeElement.focus();
    }, 1000);
  }

  /**
   * Creates the folder and closes the dialog.
   */
  public createFolder() {
    if (!this.folderName || !(this.folderName.length >= 1)) return;

    const name = this.folderName;
    this.folderName = null;

    this.store.dispatch(new DmsFolderActions.Create(name, this.parentFolder.id, this.parentFolder.dmsAccountType));
    this.closeDialog();
  }

  /**
   * Closes the dialog.
   */
  public closeDialog() {
    this.dialogRef.close();
  }
}
