import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './sms-record.interface';
import * as model from './sms-record';
import * as build from './sms-record.builder';

@Injectable()
export class OperatorSmsRecordService {
  readonly BASE_PATH = 'operator/api/v1/messages/sms';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(params: modelInterface.Operator.ISmsRecordParams): Observable<model.Operator.SmsRecord[]> {
    const page = params.page;
    const builder = new build.Operator.SmsRecordBuilder();
    return <Observable<model.Operator.SmsRecord[]>>this._http.get<build.Operator.SmsRecordBuilder, model.Operator.SmsRecord>(builder, `${this.BASE_PATH}?page=${page}`);
  }
}
