import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * Marks raw HTML as safe to bypass security checks.
 * ATTENTION: This pipe must only be used in scenarios where the piped
 *            content is given by us and not influencable by the user.
 *            Example: It never must be used on descriptions are any other
 *            user related elements.
 */
@Pipe({name: 'fivefSafeHtml', standalone: true})
export class FivefSafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
