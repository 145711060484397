<fivef-dialog [title]="'GENERAL.INVITATIONS'" [isCallToAction]="true">
  <p *ngIf="(invitations | async).length > 0">
    {{ 'INVITATIONS.YOU_HAVE_PENDING_INVITATIONS' | translate:{invitations: (invitations | async).length} }}
  </p>

  <div fivef-dialog-footer>
    <button (click)="onClose()" type="reset" mat-button>{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
    <button (click)="goToInvitations()" mat-raised-button color="primary">{{ 'INBOX.INVITATIONS' | translate }}</button>
  </div>
</fivef-dialog>
