<div class="w-100 five-f-process-management-audit-trail" style="overflow: auto">
  <div class="fivef-toolbar fivef-toolbar--space-between fivef-toolbar--content-spacing">
    <fivef-search (onChange)="applyFilter($event)"></fivef-search>

    <mat-form-field>
      <mat-icon matPrefix>filter_list</mat-icon>
      <mat-select placeholder="{{ 'PROCESS_MANAGEMENT.EVENT_FILTER' | translate }}"
                  [multiple]="true"
                  (selectionChange)="selectWorkflow($event)"
                  class="persons--filter"
                  [value]="filters$ | async">
        <mat-select-trigger *ngIf="(filters$ | async)?.length" class="persons--filter--selected">
          <ng-template [ngIf]="filters$.value?.length == filterCandidates.length" [ngIfElse]="selectedFilters">
            {{ 'GENERAL.ALL' | translate }}
          </ng-template>
          <ng-template #selectedFilters>
            {{filters$.value ? (filterTranslationMap[filters$.value[0]] | translate) : ''}}
            <span *ngIf="filters$.value?.length > 1">
                (+{{filters$.value.length - 1}} {{filters$.value?.length === 2 ? ('GENERAL.OTHER' | translate) : ('GENERAL.OTHERS' | translate) }}
              )
              </span>
          </ng-template>
        </mat-select-trigger>

        <mat-option *ngFor="let filter of filterCandidates" [value]="filter" class="persons--filter--option">
          <div class="d-flex align-items-center">
            <div>{{ filterTranslationMap[filter] | translate }}</div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="p-3 dvtx-audit-trail-console-frame">
    <ul>
      <li class="dvtx-mono">
        <table class="mw-100">
          <tr>
            <td>
              LOG FORMAT [DATE] INFO LEVEL &nbsp;
            </td>
            <td>PERFORMER (PERFORMER EMAIL): ACTIVITY: MESSAGE)</td>
          </tr>
        </table>
        <hr style="background-color: white">
      </li>
      <li *ngFor="let ev of filteredAuditTrailEvents$ | async"
          class="dvtx-mono dvtx-audit-log-item"
          [ngClass]="'dvtx-audit-log-item-color-' + ev.logLevel">
        <table class="mw-100">
          <tr>
            <ng-template [ngIf]="ev.type === ProcessEventType.ProcessUpdate" [ngIfElse]="otherwise">
              <td class="dvtx-audit-log-item-timestamp">
                <div class="dvtx-audit-log-item-timestamp">[{{ ev.createdAt | date:'\'yyyy-MM-dd HH:mm' }} CET] <span
                  [ngClass]="'dvtx-log-info-color-' + ev.logLevel">{{ ev.logLevel }}</span>&nbsp;
                </div>
              </td>
              <td>
                <ng-container *ngIf="ev?.parentId && ev?.parentId !== ev?.processId">
                  <div>Workflow: <strong><span
                    style="color: #f6daf5">{{ (getProcessTitleById(ev.processId) | async) }}</span></strong></div>
                </ng-container>
                <strong>{{ ev?.performer }}</strong>: <span
                [ngClass]="'dvtx-log-info-color-' + ev.logLevel">{{ ev.activity }}:</span>
              </td>
              <pre>
    {{ ev.log }}
    </pre>
            </ng-template>
            <ng-template #otherwise>
              <td class="dvtx-audit-log-item-timestamp">
                <div class="dvtx-audit-log-item-timestamp">[{{ ev.createdAt | date:'\'yyyy-MM-dd HH:mm' }} CET] <span
                  [ngClass]="'dvtx-log-info-color-' + ev.logLevel">{{ ev.logLevel }}</span>&nbsp;
                </div>
              </td>
              <td>
                <ng-container *ngIf="ev?.parentId && ev?.parentId !== ev?.processId">
                  <div>Workflow:
                    <strong inViewport [inViewportOptions]="{ partial: true, threshold: [0] }"
                            (inViewportAction)="showItem($event, ev.id)">
                        <span style="color: #f6daf5">
                          {{ (getProcessTitleById(ev.processId) | async)?.title }}
                        </span>
                    </strong>
                  </div>
                </ng-container>
                <strong>{{ ev?.performer }}</strong>: <span
                [ngClass]="'dvtx-log-info-color-' + ev.logLevel">{{ ev.activity }}:</span> {{ ev.log }}
              </td>
            </ng-template>
          </tr>
        </table>
      </li>
      <ng-container *ngIf="loading$ | async; else more">
        <li class="w-100" style="height: 2px">
          <fivef-loading-indicator [appearance]="'progressbar'" [message]="'GENERAL.LOAD_ITEMS' | translate"></fivef-loading-indicator>
        </li>
      </ng-container>

      <ng-template #more>
        <li class="w-100 d-flex justify-content-center">
          <ng-container *ngIf="page < pages">
            <button type="button" mat-button color="primary" (click)="loadMore($event)">
              <mat-icon matPrefix>refresh</mat-icon>
              {{ 'GENERAL.LOAD_MORE' | translate }}
            </button>

            <div class="infinite-scroll-zone"
                 inViewport [inViewportOptions]="{ threshold: 0 }"
                 (inViewportAction)="loadMore($event)">&#160;
            </div>
          </ng-container>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
