import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IProcessContext } from '../models/process-context.interface';
import { ProcessContext, ProcessStatus } from '../models/process-context';
import { NoopCommand } from '../models/commands/noop.command';
import { ClientNavigationCommand } from '../models/commands/client-navigation.command';
import { IResource } from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {Net} from 'app/lib/fivef-net/uuid';

@Injectable()
/**
 * Creates a process context from the workflow/process endpoints e.g.
 * ExternalAccessService and WorkflowEngineService.
 *
 * The context is the basis for dynamically configured workflows.
 */
export class ProcessContextBuilder {
  constructor(private _router: Router) {}

  /**
   * Creates the current process context based on the response.
   *
   * @param response
   * @returns {ProcessContext}
   */
  fromResponse(response, catalog = null): IResource {
    const context = new ProcessContext();

    this.buildCoreAttributes(context, response);

    this.buildCommandsFrom(context, response.attributes.commands);

    this.buildProcessEnvironment(context, response.attributes.environments.default,
      response.attributes.process_type, response.attributes.revision);

    // Create artifact configurations and attach them to the context.
    // Note: Artifacts like documents should be considered as first class citizens in our
    // platform. Most workflows are based on them.
    this.addArtifacts(context, response.attributes.artifacts);

    return context;
  }

  toRequest(model: IResource) {
    throw new Error('Not implemented, yet.');
  }


  buildCoreAttributes(context: IProcessContext, response): void {
    const attr = response.attributes;
    context.id = response.id;
    context.title = attr.title;
    context.subtitle = attr.subtitle;
    context.description = attr.description;
    context.dueDate = attr.due_date;
    context.createdAt = attr.created_at;
    context.updatedAt = attr.updated_at;

    if (attr.status && attr.status.message) {
      context.status = new ProcessStatus(attr.status.code, attr.status.message, attr.status.info_level, attr.status.icon);
    }
  }

  /**
   * Builds the process environment containing variable bindings and process
   * meta information.
   *
   * @param {IProcessContext} context
   * @param environment
   */
  buildProcessEnvironment(context: IProcessContext, environment, process_type, revision): void {
    if (environment) {
      if (environment.values) {
        context.values = environment.values;
        if (environment.values.assignee) {
          const val = Object.assign({}, {attributes: environment.values.assignee}, environment.values.assignee);

          context.assignee = val; // ResourceBuilder._build(val) as IContact;
        }
      }

      if (environment.workflow) {
        context.workflow = environment.workflow;
        context.revision = environment.workflow.revision;
      }

      if (environment.process) {
        context.parentId = environment.process.parent;
        if (!Net.validUUID(context.id)) {
          context.id = environment.process.id;
        }
      }

      if (environment.config) {
        context.config = environment.config;
      }
    }

    // environment is not sent
    if (!context.workflow) {
      context.workflow = process_type;
      context.revision = revision;
    }
  }

  /**
   * Adds process artifacts to the process context.
   *
   * @param {IProcessContext} context
   * @param artifacts
   */
  addArtifacts(context: IProcessContext, artifacts): void {
    if (artifacts && artifacts.length > 0) {
      context.artifacts = artifacts;
    }
  }

  /**
   * Builds API driven commands for the execution of certain predefined events
   * in the process control flow.
   *
   * @param {IProcessContext} context
   * @param commands
   */
  buildCommandsFrom(context: IProcessContext, commands): void {
    // Create commands for each queue based on the event queue's name.
    for (const command of commands) {
      console.log(`ProcessContextBuilder: Creating command: ${command}.`);
      context.addCommand(command.event_queue, this._createCommand(command));
    }

    // For testing purpose:
    // Should return one No op on the command line
    // context.addCommand('after_validation', new NoopCommand(false));
    // context.addCommand('after_validation', new NoopCommand(true));
  }

  /*
   * Helper method to create the concrete commands from command classes.
   */
  private _createCommand(command) {
    switch (command.context) {
      case 'API':
        return new NoopCommand(`ProcessContextBuilder: Not implemented, yet.`);

      case 'web_client':
        return this._createClientCommand(command);

      default:
        return new NoopCommand(`ProcessContextBuilder: Unknown command context: ${command.context}.`);
    }
  }

  /*
   * Helper method for the creation of client specific commands, e.g. navigation using the
   * Angular router.
   */
  private _createClientCommand(command) {
    switch (command.method) {
      case 'router.navigate':
        return new ClientNavigationCommand(command, this._router);

      default:
        return new NoopCommand(`ProcessContextBuilder: Unknown client command: ${command.method}`);
    }
  }
}
