import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {ContentEditableModule} from '../content-editable/content-editable.module';
import {FivefNotificationModule} from '../../fivef-ui/notification/fivef-notification/fivef-notification.module';
import {LocalizationHelperService} from './services/localization-helper.service';
import {LocaleSwitchDirective} from './directives/locale-switch.directive';
import {FeatureToggleDirective} from './directives/feature-toggle.directive';
import {CustomMatPaginatorIntl} from 'app/lib/fivef-legacy/shared/services/custom-mat-paginator-intl.service';
import {RoleToggleDirective} from './directives/roleToggle.directive';
import {AuthToggleDirective} from './directives/authToggle.directive';
import {FivefApiResourceModule} from 'app/lib/fivef-net/fivef-api-resource/fivef-api-resource.module';
import {RoutingModule} from '../routing/routing.module';
import {FivefLicenseControlDirective} from '../../fivef-ui/auth/fivef-license-control.directive';
import {TableUtilsModule} from '../table-utils/table-utils.module';
import {VarDirective} from './directives/ng-var.directive';
import {FivefStepper} from './components/fivef-stepper/fivef-stepper';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  CreateEmployeeDialogComponent
} from 'app/modules/organization/components/organization-view/create-employee-dialog/create-employee-dialog.component';
import {
  MembershipInvitationComponent
} from 'app/modules/organization/components/organization-view/membership-invitation/membership-invitation.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PortalModule} from '@angular/cdk/portal';
import {MatBadgeModule} from '@angular/material/badge';
import {RouterModule} from '@angular/router';
import {FivefDialogComponent} from '../../fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefEnumKeysPipe, FivefEnumToArrayPipe, FivefEnumValuesPipe} from '../../fivef-ui/util/fivef-enum.pipe';
import {
  FivefLoadingIndicatorComponent
} from '../../fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefAvatarGroupComponent} from '../../fivef-ui/profile/fivef-avatar-group/fivef-avatar-group.component';
import {FivefInputLabelComponent} from '../../fivef-ui/input/fivef-input-label/fivef-input-label.component';
import {FivefFeatureAuthorizationDirective} from '../../fivef-ui/auth/fivef-feature-authorization.directive';
import {ApiModule} from '../../../+store/_legacy/api/api.module';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';

const materialMods = [
  MatCardModule,
  MatStepperModule,
  MatTableModule,
  MatPaginatorModule
];

const components = [
  LocaleSwitchDirective,
  FeatureToggleDirective,
  CreateEmployeeDialogComponent,
  MembershipInvitationComponent,
  RoleToggleDirective,
  AuthToggleDirective,
  VarDirective,
  FivefStepper
];

const sharedModules = [
  FroalaViewModule,
  ApiModule,
  FivefApiResourceModule,
  BaseFormElementsModule,
  ContentEditableModule,
  FivefNotificationModule,
  RouterModule,
  MatBadgeModule,
  PortalModule,
  RoutingModule,
  TableUtilsModule,
  FivefDialogComponent,
  FivefLicenseControlDirective,
  FivefEnumToArrayPipe,
  FivefEnumKeysPipe,
  FivefEnumValuesPipe,
  FivefAvatarGroupComponent,
  FivefInputLabelComponent,
  FivefFeatureAuthorizationDirective
];

@NgModule({
  imports: [
    ...materialMods,
    ...sharedModules,
    MatCheckboxModule,
    MatProgressBarModule,
    FivefLoadingIndicatorComponent
  ],
  declarations: components,
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    LocalizationHelperService
  ],
  exports: [
    ...materialMods,
    ...sharedModules,
    ...components
  ]
})
export class SharedModule {
}
