import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {PartnerLink} from './partner-link';
import {PartnerLinkBuilder} from './partner-link.builder';

@Injectable()
export class PartnerLinkMgmtService {
  readonly BASE_PATH = 'api/v1/cms/partner_link_mgmt';

  constructor(private _http: FivefApiResourceService) {}

  getAll(): Observable<PartnerLink[]> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink[]>>this._http.get<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}`);
  }

  getOne(id: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.get<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${id}`);
  }

  create(partnerLink: PartnerLink): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    const payload = builder.toRequest(partnerLink);
    return <Observable<PartnerLink>>this._http.post<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}`, payload);
  }

  update(partnerLink: PartnerLink): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    const payload = builder.toRequest(partnerLink);
    return <Observable<PartnerLink>>this._http.put<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLink.id}`, payload);
  }

  destroy(partnerLinkId: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.del<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}`);
  }

  publish(partnerLinkId: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.post<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/publish`, {});
  }

  unpublish(partnerLinkId: string): Observable<PartnerLink> {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.del<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/publish`);
  }

  createAssignee(partnerLinkId, assigneeEmail) {
    const builder = new PartnerLinkBuilder();
    const payload = {
      data: {
        attributes: {
          email: assigneeEmail
        }
      }
    };
    return <Observable<PartnerLink>>this._http.post<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/assignees`, payload);
  }

  deleteAssignee(partnerLinkId, assigneeId) {
    const builder = new PartnerLinkBuilder();
    return <Observable<PartnerLink>>this._http.del<PartnerLinkBuilder, PartnerLink>(builder, `${this.BASE_PATH}/${partnerLinkId}/assignees/${assigneeId}`);
  }
}
