import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  MotdActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess,
} from './motd.actions';
import {Motd} from './motd';
import {MotdService} from './motd.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class MotdEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(MotdActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.motd).pipe(
        first(),
        concatMap((motd: Motd) => {
          return [new CreateSuccess(motd)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(MotdActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.motd).pipe(
        first(),
        switchMap((motd: Motd) => {
          return [new SaveSuccess(motd)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(MotdActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((motd: Motd) => {
          return [new LoadOneSuccess(motd)];
        }),
        catchError(err => {
          // 404 is Standard error if no motd is present, so no 'real' error
          if (err.status !== 404) {
            console.error(err);
          }
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(MotdActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.id).pipe(
        first(),
        concatMap((item: Motd) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: MotdService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
