import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IKanbanItemParams} from './kanban-item.interface';
import {KanbanItem} from './kanban-item';
import {KanbanItemBuilder} from './kanban-item.builder';
import {KanbanApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/kanban-api-resource.service';

@Injectable()
export class KanbanItemService {
  readonly BASE_PATH = 'api/v1/kanban/configurations';
  readonly ALT_PATH = 'api/v1/labels/items';

  constructor(private _http: KanbanApiResourceService) {
  }

  create(boardId: string, groupId: string, params: IKanbanItemParams): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    const payload = builder.toRequest(params);
    return <Observable<KanbanItem>>this._http.post<KanbanItemBuilder, KanbanItem>(builder, `${this.BASE_PATH}/${boardId}/groups/${groupId}/items`, payload);
  }

  getAll(boardId: string, groupId: string = null): Observable<KanbanItem[]> {
    const builder = new KanbanItemBuilder();
    if (groupId) {
      return <Observable<KanbanItem[]>>this._http.get<KanbanItemBuilder, KanbanItem>(builder, `${this.BASE_PATH}/${boardId}/groups/${groupId}/items`);
    }
    return <Observable<KanbanItem[]>>this._http.get<KanbanItemBuilder, KanbanItem>(builder, `${this.BASE_PATH}/${boardId}/items`);
  }

  getOne(id: string): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    return <Observable<KanbanItem>>this._http.get<KanbanItemBuilder, KanbanItem>(builder, `${this.ALT_PATH}/${id}`);
  }

  remove(id: string): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    return <Observable<KanbanItem>>this._http.del<KanbanItemBuilder, KanbanItem>(builder, `${this.ALT_PATH}/${id}`);
  }

  addLabel(referenceId: string, labelId: string): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    const payload = {
      data: {
        attributes: {
          label_id: labelId
        }
      }
    }
    return <Observable<KanbanItem>>this._http.post<KanbanItemBuilder, KanbanItem>(builder, `${this.ALT_PATH}/${referenceId}/labels`, payload);
  }

  removeLabel(referenceId: string, labelId: string): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    return <Observable<KanbanItem>>this._http.del<KanbanItemBuilder, KanbanItem>(builder, `${this.ALT_PATH}/${referenceId}/labels/${labelId}`);
  }

  updateGroup(boardId: string, groupId: string, itemId: string, newGroupLabelId: string): Observable<KanbanItem> {
    const builder = new KanbanItemBuilder();
    const payload = {
      data: {
        attributes: {
          label_id: newGroupLabelId
        }
      }
    }
    return <Observable<KanbanItem>>this._http.put<KanbanItemBuilder, KanbanItem>(builder, `${this.BASE_PATH}/${boardId}/groups/${groupId}/items/${itemId}`, payload);
  }


  getItemsLabels(referencesIds: string[]): Observable<KanbanItem[]> {
    const builder = new KanbanItemBuilder();
    const payload = {
      data: {
        attributes: {
          references_ids: referencesIds
        }
      }
    }
    return <Observable<KanbanItem[]>>this._http.postAll<KanbanItemBuilder, KanbanItem>(builder, `${this.ALT_PATH}/all`, payload);
  }
}
