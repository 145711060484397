import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-event.state';
import {ProcessSelectors} from '../process';
import {ProcessEventType} from './process-event.interface';
import * as dayjs from 'dayjs';
import {ProcessTreeSelectors} from '../process-tree';

export const stateKey = 'process-event';
const getProcessEventState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessEventEntities,
  selectAll: getAllProcessEvents,
} = adapter.getSelectors(getProcessEventState);

export const getLoadingState = createSelector(
  getProcessEventState,
  state => state.loading
);

export const getSelectedProcess = createSelector(
  getProcessEventState,
  state => state.selected
);

export const getProcessEventById = (id: string) => createSelector(
  getProcessEventEntities,
  (entites) => entites[id]
);

/**
 * Returns document events for altered state, e.g. renaming, deletion.
 * See also refreshUploads$ effect inside ProcessEventEffects.
 */
export const getUploadProcessEvents = createSelector(
  getAllProcessEvents,
  (events) => events.filter(ev => {
    return ev.type === ProcessEventType.DocumentUploaded
      || ev.type === ProcessEventType.DocumentDeleted
      || ev.type === ProcessEventType.DocumentRevisionUploaded
      || ev.type === ProcessEventType.DocumentRemoved;
  })
);

export const getProcessEventsOfProcess = (id: string) => createSelector(
  getAllProcessEvents,
  events => events.filter(event => event.processId === id)
);

export const sortByCreatedAt = (l, r) => -(dayjs(l.createdAt).diff(dayjs(r.createdAt)));

export const getEventTree = (level, events, tree, pid, rootNode = null, tMap = null, evMap = null) => {
  let selected = [];
  let root = null;
  let eventMap = evMap;
  let treeMap = tMap

  if (!treeMap) {
    treeMap = {}
    tree.forEach(node => {
      treeMap[node.id] = {id: node.id, children: []}
      if (node.id === pid) {
        root = treeMap[node.id];
      }
    });

    tree.forEach(node => {
      if (node.parentId) {
        if (!treeMap[node.parentId]) {
          treeMap[node.parentId] = {id: node.parentId, children: []}
        }
        treeMap[node.parentId].children.push(node);
      }
    });
  } else {
    root = treeMap[rootNode.id];
  }

  if (!eventMap) {
    eventMap = {}
    events.forEach(ev => {
      if (!eventMap[ev.processId]) {
        eventMap[ev.processId] = []
      }
      eventMap[ev.processId].push(ev);
    });
  }

  if (!root) {
    return events.filter(r => r.processId === pid || r.parentId === pid);
  }

  if (eventMap[root.id] && eventMap[root.id].length) {
    selected = selected.concat(eventMap[root.id])
  }

  root.children.forEach(child => {
    if (eventMap[child.id] && eventMap[child.id].length) {
      selected = selected.concat(eventMap[child.id])
    }

    const childNode = treeMap[child.id];
    if (childNode && childNode.children.length) {
      const childEvents = getEventTree(level + 1, events, tree, child.id, childNode, treeMap, eventMap);
      selected = selected.concat(childEvents);
    }
  });

  // Return distinct set without duplicates.
  // This algorithm is using a map as O(n) to prevent O(n^2) with the naive find-approach.
  const distinctSelection = [];
  const map = new Map();
  for (const item of selected) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      distinctSelection.push(item);
    }
  }

  const result = distinctSelection.sort(sortByCreatedAt);
  return result;
}

export const getProcessEventsOfSelectedProcess = createSelector(
  getAllProcessEvents,
  ProcessSelectors.getSelectedProcess,
  ProcessTreeSelectors.getAllProcesssTreeNodes,
  (events, process, tree) => {
    if (process) {
      return getEventTree(0, events, tree, process.id);
    }
    return [];
  });

export const getLastCurrentProcessEvent = createSelector(
  getProcessEventsOfSelectedProcess,
  (events) => {
    return events && events.length > 0 ? events[0] : null
  }
);

export const getAllThirdPartyRequestEvents = (id: string) => createSelector(
  getAllProcessEvents,
  events => events.filter((event) => event.processId === id)
);

export const getInstantMessageCountOfSelectedProcess = createSelector(
  getAllProcessEvents,
  ProcessSelectors.getSelectedProcess,
  (events, process) => {
    if (process) {
      return events.filter(r => r.type === ProcessEventType.InstantMessage && r.processId === process.id).length
    }
    return 0;
  });

export const getCollectorItemEvents = (id: string) => createSelector(
  getAllProcessEvents,
  events => events.filter((event) => event.itemId === id)
);

/**
 * Returns all referenced events by item ID.
 * Used at Collecto activity log.
 *
 * @param id
 */
export const getReferencedEvents = (id: string) => createSelector(
  getAllProcessEvents,
  events => events.filter((event) => event.itemId === id)
);

export const getCollectorItemComments = (id: string) => createSelector(
  getAllProcessEvents,
  events => events.filter((event) => event.type === ProcessEventType.InstantMessage && event.itemId === id)
);

export const getSeenBys = (id: string) => createSelector(
  getProcessEventEntities,
  events => events[id] && events[id].seenBy ? events[id].seenBy : []
);
