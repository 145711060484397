import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from 'app/app.module';
import {environment} from 'environments/environment';
import 'zone.js/dist/zone';


if (environment.production) {
  enableProdMode();
  const companyNameLog = `%c
  5F5F5F5F   5F5F5F5F
  5F         5F
  5F5F5F     5F5F5F
        5F   5F
  5F5F5F     5F
  `;
  const companyNameLogStyle = 'color:#4b9bb4;font-weight:bolder;font-size:8px;line-height:6px';
  console.log(companyNameLog, companyNameLogStyle);
}

platformBrowserDynamic().bootstrapModule(AppModule);
