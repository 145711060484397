import {ProcessParticipantActions, ProcessParticipantActionTypes} from './process-participant.actions';
import {adapter, initialState, State} from './process-participant.state';

export function reducer(state = initialState, action: ProcessParticipantActions): State {
  switch (action.type) {
    case ProcessParticipantActionTypes.Create:
    case ProcessParticipantActionTypes.Delete:
    case ProcessParticipantActionTypes.DeleteExternal:
    case ProcessParticipantActionTypes.LoadAll:
    case ProcessParticipantActionTypes.LoadAllRefresh:
      return {
        ...state,
        loading: true
      };
    case ProcessParticipantActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.participants, {
        ...state,
        loading: false,
      });
    case ProcessParticipantActionTypes.LoadAllRefreshSuccess:
      return adapter.setAll(action.participants, {
          ...state,
          loading: false,
      });
    case ProcessParticipantActionTypes.CreateFail:
    case ProcessParticipantActionTypes.DeleteFail:
    case ProcessParticipantActionTypes.DeleteExternalFail:
    case ProcessParticipantActionTypes.LoadAllFail:
    case ProcessParticipantActionTypes.LoadAllRefreshFail:
      return {
        ...state,
        loading: false
      };
    case ProcessParticipantActionTypes.UpdateSuccess:
    case ProcessParticipantActionTypes.CreateSuccess:
      return adapter.upsertOne(action.participant, {
        ...state,
        loading: false,
      });
    case ProcessParticipantActionTypes.DeleteSuccess:
    case ProcessParticipantActionTypes.DeleteExternalSuccess:
      return adapter.removeOne(action.participant.id, {
        ...state,
        loading: false,
      });
    case ProcessParticipantActionTypes.Reset:
      return Object.assign({}, initialState);
  }
  return state;
}

