import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess, MilestoneActionTypes,} from './milestone.actions';
import {MilestoneService} from './milestone.service';
import {Milestone} from './milestone';
import {MilestoneType} from './milestone.interface';
import {IProcessQuery} from '../process-event/process-event.interface';
import {ProcessActions} from '../process';
import {ProcessLookupActions} from "../process-lookup";

@Injectable()
export class MilestoneEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(MilestoneActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((milestones: Milestone[]) => {
          const ids = milestones.map(item => {
            if (item.type === MilestoneType.Process) return item.id;
            return item.parentId;
          }).filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });

          const params: IProcessQuery = {
            dense: true,
            processIds: ids,
            debug: 'MilestoneActionTypes.LoadAll'
          };
          return [new LoadAllSuccess(milestones), new ProcessLookupActions.LoadSelection(params)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: MilestoneService) {
  }
}




