<div class="fivef-labels--container" #labelsListRef>
  <ng-container *ngIf="_labels || (showLabelsSelector && _labelsIds)">
    <ng-template [ngIf]="showAll">
      <ng-container *ngFor="let label of _labels">
        <fivef-label *ngIf="label?.id && label?.title"
                     [label]="label" [readonly]="readonly || label?.group?.isContextual"
                     (onClick)="onClick.emit($event)"
                     (onRemove)="onRemove.emit($event)"></fivef-label>
      </ng-container>
    </ng-template>

    <ng-template [ngIf]="!showAll">
      <ng-container *ngFor="let label of (_labels | slice:0:numberOfVisibleLabels)">
        <fivef-label *ngIf="label?.id && label?.title"
                     [label]="label" [readonly]="readonly || label?.group?.isContextual"
                     (onClick)="onClick.emit($event)"
                     (onRemove)="onRemove.emit($event)"></fivef-label>
      </ng-container>
    </ng-template>

    <ng-template [ngIf]="isShowMore && !showAll">
      <ng-container *ngFor="let label of (_labels | slice:numberOfVisibleLabels:_labels.length)">
        <fivef-label *ngIf="label?.id && label?.title"
                     [label]="label" [readonly]="readonly || label?.group?.isContextual"
                     (onClick)="onClick.emit($event)"
                     (onRemove)="onRemove.emit($event)"></fivef-label>
      </ng-container>
    </ng-template>

    <span class="fivef-label"
          *ngIf="numberOfVisibleLabels < _labels.length && !isShowMore && canShowMore && !showAll">
    <span class="fivef-label--container fivef-labels--show-more"
          [matTooltip]="'GENERAL.SHOW_MORE' | translate"
          (click)="isShowMore = true">
      +{{ _labels.length - maxToShow }}
    </span>
  </span>

    <span class="fivef-label"
          *ngIf="maxToShow < _labels.length && isShowMore && canShowMore && !showAll">
    <span class="fivef-label--container fivef-labels--show-more"
          [matTooltip]="'GENERAL.SHOW_LESS' | translate"
          (click)="isShowMore = false">
      -{{ _labels.length - maxToShow }}
    </span>
  </span>

    <ng-container *ngIf="showLabelsSelector && allLabels?.length > 0 && _labelsIds && !showAll">
      <fivef-labels-selector [labels]="allLabels" [selected]="_labelsIds" (onSelection)="onAdd.emit($event)"
                             [appearance]="appearance"
                             [labelIcon]="true"
                             [hiddenCount]="(_labels?.length)"></fivef-labels-selector>
    </ng-container>
    <ng-container *ngIf="showLabelsSelector && allLabels?.length > 0 && _labelsIds && showAll">
      <fivef-labels-selector [labels]="allLabels" [selected]="_labelsIds" (onSelection)="onAdd.emit($event)"
                             [hiddenCount]="(_labels?.length)"
                             [appearance]="appearance"
                             [labelIcon]="true"></fivef-labels-selector>
    </ng-container>
  </ng-container>
</div>
