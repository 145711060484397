import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Organization} from 'app/+store/organization/organization';
import {MatDialog} from '@angular/material/dialog';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationSelectors} from 'app/+store';
import {Router} from '@angular/router';

@Component({
  selector: 'dvtx-quickshare-quickstarter',
  templateUrl: './quickshare-quickstarter.component.html',
  styleUrls: ['./quickshare-quickstarter.component.scss']
})
export class QuickshareQuickstarterComponent implements OnInit {
  organizations: Observable<Organization[]>;
  organizationsCount: number;

  constructor(public dialog: MatDialog, private _store: Store<AppState>, private _translateSvc: TranslateService, private router: Router) {
  }

  ngOnInit() {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations.subscribe((organizations) => {
      this.organizationsCount = organizations.length;
    })
  }

  redirectToLink(link) {
    this.router.navigate(link);
  }
}
