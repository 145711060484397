import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {Sorters} from 'app/lib/sorter/sorters';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {EmailToNameSelectors} from 'app/+store';
import {first} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {FivefAvatarSize} from '../fivef-avatar/fivef-avatar.model';
import {FivefAvatarService} from '../fivef-avatar/fivef-avatar.service';
import {IFivefAvatarProfile} from '../fivef-avatar/fivef-avatar.interface';
import {FivefAvatarModule} from '../fivef-avatar/fivef-avatar.module';

@Component({
  selector: 'fivef-avatar-group',
  standalone: true,
  imports: [
    CommonModule,
    FivefAvatarModule,
    MatTooltipModule,
    MatMenuModule
  ],
  host: {class: 'fivef-avatar-group'},
  templateUrl: './fivef-avatar-group.component.html',
  styleUrls: ['./fivef-avatar-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefAvatarGroupComponent implements OnChanges {
  /**
   * Avatar size.
   */
  public avatarSizeNumber: number = +FivefAvatarSize['xs'];
  public avatarSizeString: string = 'xs';

  public avatarShowTitle: boolean = false;
  public containerClass;
  public itemsClass;
  private _profiles: IFivefAvatarProfile[];

  @ViewChild('ShowTooltipButton')
  showTooltipButton: ElementRef;

  public contentTooltipAvatar;

  @Input()
  maxToShow: number = 5;

  @Input()
  removable: boolean = false;

  @Input()
  showMore = true;

  @Input()
  set size(size: any) {
    this.avatarSizeNumber = +FivefAvatarSize[size];
    this.avatarSizeString = size;
  };

  @Input()
  set showTitle(showTitle: boolean) {
    this.avatarShowTitle = showTitle;
  };

  @Input()
  set profiles(profiles: IFivefAvatarProfile[]) {
    if (profiles && profiles.length) {
      this._profiles = profiles.filter(p => !!p && p.email).sort((l, r) => Sorters.sort(l.email, r.email));
      this._profiles.forEach(profile => {
        if (profile.lastName && profile.firstName) {
          profile.name = profile.lastName + ', ' + profile.firstName;
        } else {
          this.store.select(EmailToNameSelectors.getByEmail(profile.email)).pipe(first()).subscribe((x) => {
            if (x) {
              profile.firstName = x.firstName;
              profile.lastName = x.lastName;
              if (x.firstName || x.lastName) {
                profile.name = x.lastName + ', ' + x.firstName;
              } else {
                profile.name = null;
              }
            }
          });
        }
      })
    } else {
      this._profiles = [];
    }
  }

  get profiles() {
    return this._profiles;
  }

  constructor(private store: Store<AppState>,
              private avatarService: FivefAvatarService,
              private cdr: ChangeDetectorRef,
              private sanitized: DomSanitizer) {
  }

  openTooltip() {
    let content = `<ul class="fivef-avatar-group--menu--listing">`;

    this.profiles.slice(this.maxToShow, this.profiles.length).map((profile) => {
      const avatar = this.avatarService.get(profile, 'xs', null, true);
      content += `<li class="fivef-avatar-group--menu--list-item">${avatar.innerHTML}</li>`;
    });

    content += `</ul>`;
    this.contentTooltipAvatar = this.sanitized.bypassSecurityTrustHtml(content);
  }

  ngOnChanges(changes) {
    // console.log(changes)
    if (changes.profiles && changes.profiles.currentValue) {
      this.profiles = changes.profiles.currentValue;
    }
  }

  getProfile(email) {
    const _profile = this.avatarService.getProfile(email);
    this.cdr.markForCheck();
    return _profile;
  }
}
