import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ITabNavRoute} from '../../models/tab-nav-route.interface';
import {Location} from '@angular/common';
import {NaturalPersonSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {takeUntil} from 'rxjs/operators';
import {Portal} from '@angular/cdk/portal';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import {NaturalPerson} from 'app/+store/natural-person/natural-person';
import {AngularTokenService} from 'angular-token';
import {User} from 'app/+store/user/user';

@Component({
  selector: 'dvtx-user-profile-settings-header',
  templateUrl: './user-profile-settings-header.component.html',
  styleUrls: ['./user-profile-settings-header.component.scss']
})
export class UserProfileSettingsHeaderComponent implements OnInit , OnDestroy {
  private onDestroy = new Subject<void>();

  public person$: Observable<NaturalPerson>;
  public user: User;

  /**
   * Main view title.
   */
  @Input() title = '';

  /**
   * Sup title (optional).
   */
  @Input() subtitle: string = null;

  /**
   * Shows the avatar and user profile (optional).
   */
  @Input() showUserProfile = false;

  /**
   * Tab routing.
   */
  @Input() routes: ITabNavRoute[] = null;
  @Input() activeLink: string;

  @Input() callToActionPortal: Portal<any>;
  @Input() globalActionPortal: Portal<any>;
  @Input() enableSearch = false;
  @Input() backLink = true;

  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchTerm: string;

  @Output() onSearch = new EventEmitter<string>();

  @ViewChild('searchFieldRef') searchFieldRef;

  constructor(private store: Store<AppState>,
              private location: Location,
              private router: Router,
              private tokenSvc: AngularTokenService,
              private cdr: ChangeDetectorRef) {
    this.store.select('currentUser')
      .pipe(takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  ngOnInit(): void {
    this.person$ = this.store.select(NaturalPersonSelectors.getMyNaturalPersonEntity);

    this.searchTerm$.pipe(takeUntil(this.onDestroy)).subscribe(term => {
      this.onSearch.emit(term);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public applySearch($event): void {
    this.searchTerm$.next($event);
  }

  public clearSearch(): void {
    this.searchTerm = '';
    this.searchTerm$.next('');
    this.cdr.detectChanges();
  }
}
