import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {first, map} from 'rxjs/operators';
import {IApiResponse, IJsonApiItem} from '../lib/fivef-net/fivef-api-resource/models/api.interface';
import {Observable} from 'rxjs/internal/Observable';
import * as tenantModel from '../+store/tenant/tenant/tenant';
import * as tenantInterface from '../+store/tenant/tenant/tenant.interface';
import {SetBrowserTitle} from '../+store/_legacy/actions/title.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';

/**
 * Customer API for the products sold in the checkout wizard.
 */
@Injectable()
export class SystemService {
  readonly apiPath = 'system';
  basePath;

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private env: EnvService) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.apiPath}`;
  }

  getStatus(): Observable<Object> {
    return this.httpClient.get(this.basePath + '/status');
  }

  getPublicTenantConfig(domain: string): Observable<tenantInterface.Tenant.IPublicTenantConfig> {
    const apiUrl = this.env.apiBase();
    const payload = {
      data: {
        attributes: {
          domain: domain
        }
      }
    };
    return this.httpClient.post(`${apiUrl}/api/v1/tenant/public_settings/domain_details`, payload)
      .pipe(
        map((response: IApiResponse) => {
          const attrs = (<IJsonApiItem>response.data)?.attributes;
          return {
            name: attrs?.name,
            faviconUrl: attrs?.favicon_url,
            colorTheme: attrs?.color_theme,
            colorThemeConfig: attrs?.color_theme_config
          }
        }))
  }

  /**
   * Tenant configuration of the app.
   *
   * Includes styling based on theme classes, browser tab title and favicon.
   */
  public configureTenant() {
    // Domain based default Heuristic to the set the default style.
    // This hardcoded domain base approach is required if the API is offline, so the following will fail.
    // Response of the call will override the default domain style.
    this.setStyleByHostname();

    try {
      const hostName = window.location.hostname;
      this.getPublicTenantConfig(hostName)
        .pipe(first())
        .subscribe((tenantConfig: tenantInterface.Tenant.IPublicTenantConfig) => {
          this.setTheme(tenantConfig.colorTheme);

          if (tenantConfig?.name) {
            const name: string = tenantConfig.name;
            this.store.dispatch(new SetBrowserTitle(name));

            const faviconUrl: string = tenantConfig.faviconUrl;
            if (faviconUrl) {
              // Change favicon by URL
              // const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
              // link.type = 'image/x-icon';
              // link.rel = 'shortcut icon';
              // link.href = faviconUrl;
              // document.getElementsByTagName('head')[0].appendChild(link);
              // Angular way
              const favicon = document.getElementById('favicon');
              favicon.setAttribute('href', faviconUrl);
            }
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Some theme defaults for big customers to be enabled even if the API is down.
   *
   * @private
   */
  private setStyleByHostname() {
    if (tenantModel.Tenant.Tenant.isGrantThornton()) {
      this.setTheme('grant_thornton_theme');
    }

    if (tenantModel.Tenant.Tenant.isPaperbirdOrg()) {
      this.setTheme('paperbird_theme');
    }

    if (tenantModel.Tenant.Tenant.isCuracon()) {
      this.setTheme('curacon_theme');
    }

    if (tenantModel.Tenant.Tenant.isColoredFivef()) {
      this.setTheme('colored_fivef_theme');
    }
  }

  /**
   * Sets the theme style class on the body element.
   *
   * @param theme
   * @private
   */
  private setTheme(theme: tenantInterface.Tenant.FivefAppColorTheme) {
    if (!theme) {
      return;
    }

    const body = document.getElementsByTagName('body')[0];
    const themeName = tenantInterface.Tenant.themeNameToCssStyleClass(theme);
    if (!themeName) {
      console.error(`[ERROR] Invalid app theme setup: ${theme}. Skipping class setup.`);
      return;
    }
    body.classList.add(themeName);
  }
}
