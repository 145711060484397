import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GroupContextType, IKanbanGroupParams} from './kanban-group.interface';
import {KanbanGroup} from './kanban-group';
import {KanbanGroupBuilder} from './kanban-group.builder';
import {KanbanApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/kanban-api-resource.service';

@Injectable()
export class KanbanGroupService {
  readonly BASE_PATH = 'api/v1/kanban/configurations';

  constructor(private _http: KanbanApiResourceService) {
  }

  create(labelId, boardId, isContextual: boolean = false, contextType: GroupContextType = GroupContextType.NO_CONTEXT, referenceId: string = null): Observable<KanbanGroup> {
    const builder = new KanbanGroupBuilder();
    const params = {
      data: {
        attributes: {
          label_id: labelId,
          is_contextual: isContextual,
          context_type: contextType,
          reference_id: referenceId
        }
      }
    }
    return <Observable<KanbanGroup>>this._http.post<KanbanGroupBuilder, KanbanGroup>(builder, `${this.BASE_PATH}/${boardId}/groups`, params);
  }

  update(boardId: string, id: string, params: IKanbanGroupParams): Observable<KanbanGroup> {
    const builder = new KanbanGroupBuilder();
    const payload = builder.toRequest(params);
    return <Observable<KanbanGroup>>this._http.put<KanbanGroupBuilder, KanbanGroup>(builder, `${this.BASE_PATH}/${boardId}/groups/${id}`, payload);
  }

  getAll(boardId: string): Observable<KanbanGroup[]> {
    const builder = new KanbanGroupBuilder();
    return <Observable<KanbanGroup[]>>this._http.get<KanbanGroupBuilder, KanbanGroup>(builder, `${this.BASE_PATH}/${boardId}/groups`);
  }

  getOne(boardId: string, id: string): Observable<KanbanGroup> {
    const builder = new KanbanGroupBuilder();
    return <Observable<KanbanGroup>>this._http.get<KanbanGroupBuilder, KanbanGroup>(builder, `${this.BASE_PATH}/${boardId}/groups/${id}`);
  }

  remove(boardId: string, id: string): Observable<KanbanGroup> {
    const builder = new KanbanGroupBuilder();
    return <Observable<KanbanGroup>>this._http.del<KanbanGroupBuilder, KanbanGroup>(builder, `${this.BASE_PATH}/${boardId}/groups/${id}`);
  }
}
