import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {OrganizationSelectors} from '../../../../../+store/organization';
import {Organization} from '../../../../../+store/organization/organization';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.state';
import {MembershipSelectors} from '../../../../../+store/membership';
import {Membership} from '../../../../../+store/membership/membership';
import {ITabNavRoute} from 'app/lib/fivef-legacy/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {OrganizationMasterDataComponent} from '../../organization-view/organization-master-data/organization-master-data.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import { FivefNotificationService } from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'fivef-organization-master-data',
  templateUrl: './fivef-organization-master-data.component.html',
  styleUrls: ['./fivef-organization-master-data.component.scss']
})
export class FivefOrganizationMasterDataComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public organization$: Observable<Organization>;
  private organization: Organization;
  public myMembership: Observable<Membership>;

  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  public readonly = true;
  public editmode = false;

  @ViewChild('callToActionRef', { static: true })
  private callToActionRef: TemplateRef<any>;
  public callToActionPortal: Portal<any>;

  @ViewChild('masterDataComponent', { static: true })
  private masterDataComponent: OrganizationMasterDataComponent;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _notifyService: FivefNotificationService,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.organization$ = this._store.select(OrganizationSelectors.getSelected);
    this.organization$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(org => this.organization = org);

    this.myMembership = this._store.select(MembershipSelectors.getMyMembership);

    const orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'ORGANIZATION.MASTER_DATA',
      route: `/organization/${orgId}/orgSettings/profile`
    }];

    this.myMembership.pipe(takeUntil(this.onDestroy)).subscribe(membership => {
      if (membership && membership.hasAdministrationRights) {
        this.readonly = false;

        this.routes = [{
          title: 'ORGANIZATION.MASTER_DATA',
          route: `/organization/${orgId}/orgSettings/profile`
        }, {
          title: 'ORGANIZATION.GLOBAL_SETTINGS',
          route: `/organization/${orgId}/orgSettings/settings`
        }];
        this._cdr.detectChanges();
      }
    });
    this.activeLink = this.routes[0].title;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  public save() {
    if (!this.masterDataComponent?.organization?.name
      || this.masterDataComponent.organization.name.length < 2) {
      this._notifyService.error('CONTACTS.ENTER_ORG_NAME')
      return;
    }

    if (!this.organization || !this.organization.id) {
      this.editmode = false;
      return;
    }

    this.masterDataComponent.save();
    this.editmode = false;
    this._cdr.detectChanges();
  }

  public cancel() {
    this.masterDataComponent.refresh();
    this.editmode = false;
  }
}
