<div class="w-100 d-flex justify-content-center py-2" *ngIf="!currentUser?.currentLicence">
  <div>
    <h2 class="mb-1">
      {{ 'LICENCES.HAVE_NO_LICENCE_TEXT' | translate }}
    </h2>
  </div>
</div>

<div class="row px-3" *ngIf="currentUser?.currentLicence">
  <div class="col-12 col-md-4 pt-3">
    <table class="licence--details--table w-100">
      <tr>
        <td>
          {{ 'LICENCES.CURRENT_SUBSCRIPTION' | translate }}
        </td>
        <td>
          <strong>{{ currentUser.currentLicence?.attributes?.title | translate }}</strong>
        </td>
      </tr>
      <tr>
        <td>
          {{ 'SHOPPING_CART.PAYMENT_INTERVAL' | translate }}
        </td>
        <td>
          <strong>{{ currentUser.currentLicence?.attributes?.paymentInterval }}</strong>
        </td>
      </tr>
      <tr>
        <td>
          {{ 'LICENCES.SUBSCRIBED_AT' | translate }}
        </td>
        <td>
          <strong>{{ currentUser.currentLicence?.attributes?.createdAt | date: 'd.MM.y' }}</strong>
        </td>
      </tr>
      <tr>
        <td>
          {{ 'SHOPPING_CART.NEW_LICENCE_VALID_UNTIL' | translate }}
        </td>
        <td>
          <strong>{{ currentUser.currentLicence?.attributes?.validUntil | date: 'd.MM.y' }}</strong>
        </td>
      </tr>
      <tr>
        <td class="d-flex align-items-center">
          {{ 'SHOPPING_CART.AVAILABLE_USER_LICENCES' | translate }}
          <button mat-icon-button class="ml-1">
            <mat-icon class="icon-info" [matTooltip]="'SHOPPING_CART.AVAILABLE_USER_LICENCES_HINT' | translate">
              info
            </mat-icon>
          </button>
        </td>
        <td>
          <strong
            [class.text-success]="currentUser.currentLicence?.attributes?.availableUserLicences > 0"
            [class.text-warning]="currentUser.currentLicence?.attributes?.availableUserLicences <= 0">
            {{ currentUser.currentLicence?.attributes?.availableUserLicences }}
            {{ 'SHOPPING_CART.OUT_OF' | translate }}
            {{ currentUser.currentLicence?.attributes?.userLicencesCount }}
          </strong>

        </td>
      </tr>
    </table>

    <h3 class="mb-1" *ngIf="!(currentUser?.currentLicence)">
      {{ 'LICENCES.HAVE_NO_LICENCE_TEXT' | translate }}
    </h3>
  </div>

  <div class="col-12 col-md-8">
    <div class="fivef-mat-table" *ngIf="currentUser?.currentLicence && (invitations?.length || members?.length)">
      <!--      <div class="d-flex align-items-center justify-content-end">-->
      <!--        &lt;!&ndash; User licence buy dialog: Removed: We only sell licences by the sales team. &ndash;&gt;-->
      <!--        &lt;!&ndash; <ng-container *ngIf="currentUser?.currentLicence?.attributes?.availableUserLicences < 1">&ndash;&gt;-->
      <!--        &lt;!&ndash;   <button class="mb-2" mat-raised-button color="primary" (click)="buyUserLicence()">{{ 'LICENCES.BUY_LICENCE' | translate }}</button>&ndash;&gt;-->
      <!--        &lt;!&ndash; </ng-container>&ndash;&gt;-->

      <!--        <ng-container *ngIf="currentUser?.currentLicence?.attributes?.availableUserLicences > 0">-->
      <!--          <div class="mr-3"> {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}:</div>-->
      <!--          <dvtx-contact-select-->
      <!--            elementName="E-Mail"-->
      <!--            [(ngModel)]="inviteeEmail"-->
      <!--            [filterType]="contactListDtoType.naturalPersonContact"-->
      <!--            [excludedIds]="[]"-->
      <!--            [valueIsEmail]="true">-->
      <!--          </dvtx-contact-select>-->
      <!--          <button [disabled]="!inviteeEmail || inviteeEmail.length === 0"-->
      <!--                  mat-raised-button color="primary"-->
      <!--                  class="ml-3"-->
      <!--                  (click)="openMemberInvitationDialog()">-->
      <!--            {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}-->
      <!--          </button>-->
      <!--        </ng-container>-->
      <!--      </div>-->

      <table cellpadding="0" cellspacing="0" border="0">
        <thead>
        <tr>
          <th class="five-f-column-avatar">
          </th>

          <th class="five-f-column-email">
            {{ 'INVITATIONS.INVITEES_EMAIL' | translate }}
          </th>

          <th class="five-f-column-created-at">
            {{ 'INVITATIONS.INVITE_DATE' | translate }}
          </th>

          <th class="five-f-column-status">
            Status
          </th>

          <th class="five-f-column-actions">
          </th>
        </tr>
        </thead>
      </table>

      <cdk-virtual-scroll-viewport *ngIf="dataSource" [itemSize]="55" autosize>
        <table cellpadding="0" cellspacing="0" border="0">
          <tbody>
          <tr *cdkVirtualFor="let member of dataSource.data; index as i; templateCacheSize: 0">
            <td class="five-f-column-avatar">
              <fivef-avatar [size]="'sm'" [email]="member?.email"></fivef-avatar>
            </td>

            <td class="five-f-column-email">
              {{ member.email }}
            </td>

            <td class="five-f-column-created-at">
              {{ member.created_at | date : 'medium' }}
            </td>

            <td class="five-f-column-status">
              <div>
                <ng-container [ngSwitch]="member.status">
                    <span *ngSwitchCase="null" class="text-success">
                      {{ currentUser.attributes.uid === member.email ? ('GENERAL.ME' | translate) : ('INVITATIONS.ACCEPTED' | translate) }}
                    </span>
                  <i *ngSwitchCase="InvitationStatus.Pending">{{ 'INVITATIONS.PENDING' | translate }}</i>
                </ng-container>
              </div>
            </td>

            <!-- Resend Invitation Column -->
            <td class="five-f-column-actions">
              <button mat-icon-button
                      *ngIf="member.status === InvitationStatus.Pending"
                      (click)="cancelInvitationDialog(member)"
                      [matTooltip]="'INVITATIONS.DELETE_INVITATION_TOOLTIP' | translate">
                <mat-icon class="icon">delete</mat-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
