import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {PartnerLinkParticipation} from './partner-link-participation';

export class PartnerLinkParticipationBuilder implements IApiResourceBuilder<PartnerLinkParticipation> {
  fromResponse(data): PartnerLinkParticipation {
    return new PartnerLinkParticipation(
      data.id,
      data.attributes.organization_id,
      data.attributes.email,
      data.attributes.created_at);
  }

  toRequest(partnerLink: PartnerLinkParticipation) {
    return null;
  }
}
