import {
  IDocumentPreview,
  IDocumentSignature,
  ISignatureAccount,
  ISignatureJob,
  ISignatureSettings,
  IVisualSignature
} from './document-signature.interface';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

/**
 * Enable/Disable QES globally.
 */
export const QES_FEATURE_TOGGLE = true;
export type SignatureFrameFormat = 'signature' | 'stamp' | 'signet';

/**
 * Setting IDs for FP Sign workflows.
 * The setting ID defines _how_ to sign
 * - Advanced Signature (Mentana PKI, default)
 * - Qualified using the Sign Me PKI of the Bundesdruckerei
 * - Without login: This is for external signing without logging in into the FP sign platform and only signing by token link.
 */
export const SETTINGS_ID__ADVANCED = '2';
export const SETTINGS_ID__QES_SIGN_ME = '23';
export const SETTINGS_ID__QES_SIGN_ME_DTRUST = '29';
export const SETTINGS_ID__ADVANCED_WITHOUT_LOGIN = '61';

export class DocumentSignature implements IDocumentSignature {
  readonly type = 'dms_sign_document_signatures';

  constructor(public id,
              public createdAt = null,
              public updatedAt = null) {
  }
}

export class VisualSignature implements IVisualSignature {
  public type = 'dms_sign_visual_signatures';
  public format: SignatureFrameFormat = 'signature';

  public name: string;
  public referenceId: string;

  public editable: boolean;
  public draggable: boolean;

  public page: number;
  public x: number;
  public y: number;
  public w: number;
  public h: number;
  public cw?: number;
  public ch?: number;

  public color: string;

  constructor(public id,
              public content: string = null,
              public skipMetaInfo: boolean = false,
              public createdAt: Date = null,
              public updatedAt: Date = null) {
  }

  /**
   * Returns true if option skip meta info is supported.
   * This is valid for all default signatures created by the cropper after the release of 2021-11-28
   * (The image size changed to 1200x400 and aspect ratio 3, formerly 5).
   *
   * @param visualSignature
   */
  static isSkipMetaInfoSupported(visualSignature) {
    if (!visualSignature || !visualSignature.updatedAt) {
      return false;
    }
    return new Date(visualSignature.updatedAt).getTime() >= new Date('2021-11-28T17:00:00.664+01:00').getTime();
  }
}

export class DocumentPreview implements IDocumentPreview {
  readonly type = 'dms_sign_document_pdf_previews';

  constructor(public id: string,
              public title: string,
              public content: string,
              public createdAt = null,
              public updatedAt = null) {
  }

  static signedDocumentByName(document: DocumentPreview): string {
    return `${document.title.replace(/\.[^/.]+$/, '')} (signed).pdf`
  }
}

export class SignatureSettings implements ISignatureSettings {
  readonly type = 'dms_workflow_sign_signature_settings';

  constructor(public id: number | string,
              public name: string,
              public accountPresent: boolean,
              public fpSignAccountAccountPresent: boolean,
              public description: string,
              public authentication: string,
              public exchangeType: string,
              public fillOutPdf: boolean,
              public signatureType: string) {
  }

  static qesSupported(settings) {
    return settings.find(s => s.id === SETTINGS_ID__QES_SIGN_ME || s.id === SETTINGS_ID__QES_SIGN_ME_DTRUST);
  }
}

export class SignatureJobPositionFrame implements IResource {
  public type = 'signature_position_frames';
  public skipMetaInfo: boolean = true;
  public format: SignatureFrameFormat = 'signature';

  constructor(public id: string, type: string, skipMetaInfo: boolean, format: SignatureFrameFormat, public page: number, public width, public height, public offsetX,
              public offsetY, public color,
              public reviewedAt: Date = null, public image: string = null) {
    this.type = type;
    this.skipMetaInfo = skipMetaInfo;
    this.format = format;
  }
}

export class SignatureJob implements ISignatureJob {
  readonly type = 'dms_workflow_sign_signature_jobs';
  mobile: string;
  signedAt: Date;
  projectRoom: boolean;
  signatureFrames: SignatureJobPositionFrame[] = [];
  signatureFrameId?: string;
  qes = false;

  constructor(public id: string | number,
              public identifier: string,
              public email: string,
              public firstName: string,
              public lastName: string,
              public settingsId: number | string,
              public comment: string,
              public status: string = null,
              public data: any = {}) {
    this.qes = +settingsId === 23 || +settingsId === 29;
  }
}

export class SignatureAccount implements ISignatureAccount {
  readonly type = 'dms_sign_accounts';
  mobile: string;

  constructor(public id: number,
              public createdAt: Date) {
  }
}

export enum DocumentSignatureRequestProfileType {
  JustMe = 'just_me',
  Circular = 'circular',
  Delegated = 'delegated',
  Watermark = 'watermark',
}

/**
 * View profile for wizard and overview
 * Wizard: The signature creation form.
 * Originator: Used to differentiate between recipient overview and the senders view.
 * Recipient: Used to differentiate between recipient overview and the senders view.
 * Contributor: Recipient that must sign. After signing the view switches to the Recipient view.
 * ProjectRoomParticipant: User that is not part of the signature circular but member in the project room.
 * Unauthorized: No FP Sign Account setup
 */
export enum DocumentSignaturePreviewViewType {
  Wizard = 'Wizard',
  Originator = 'Originator',
  MayBeRecipient = 'MayBeRecipient',
  Recipient = 'Recipient',
  Contributor = 'Contributor',
  ProjectRoomParticipant = 'ProjectRoomParticipant',
  Unauthorized = 'Unauthorized'
}

export class DocumentSignatureRequest implements IResource {
  readonly type = 'dms_workflow_sign_signature_request';
  public originatorSignature?: VisualSignature;
  public qes = false;
  public state;

  constructor(public id: string,
              public processId: string,
              public parentId: string,
              public organizationId: string,
              public originator: boolean,
              public documentId: string,
              public dmsFolderId: string,
              public dmsAccountType: DmsAccountType,
              public title: string,
              public description: string,
              public dueDate: Date,
              public clientId: string,
              public color: string,
              public profile: DocumentSignatureRequestProfileType,
              public signatureProcessingState: string,
              public signedDocumentId: string,
              public auditLogId: string,
              public verificationReportId: string,
              public sendDocumentsByEmail: boolean = false,
              public createdAt = null,
              public updatedAt = null) {
  }
}

export class OrganizationalVisual implements IResource {
  readonly type = 'organization_visual';

  constructor(public id,
              public content: string,
              public name: string,
              public fontSize: number,
              public enableProfession: boolean,
              public profession: string,
              public enableDate: boolean,
              public enableTime: boolean,
              public updatedAt: Date,
              public createdAt: Date) {}
}
