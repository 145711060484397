import {
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Fibu} from 'app/+store/fibu/fibu';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'fivef-bookman-audit-trail-dialog',
  templateUrl: './fivef-bookman-audit-trail-dialog.component.html',
  styleUrls: ['./fivef-bookman-audit-trail-dialog.component.scss']
})
export class FivefBookmanAuditTrailDialogComponent implements OnDestroy {
  private onDestroy = new Subject<void>();

  @ViewChild('dialogTpl', {static: true})
  dialogTpl: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  @Input()
  process: Fibu;

  constructor(private dialog: MatDialog) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  openDialog() {
    this.dialogRef = this.dialog.open(this.dialogTpl);
  }

  closeDialog(_event) {
    this.dialogRef.close();
  }
}
