import {ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DateUtil} from 'app/lib/date/date-util';
import {DateAdapter} from '@angular/material/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FivefDisableInputHintsWrapperDirective} from '../fivef-disable-input-hints-wrapper.directive';
import {FivefIconComponent} from '../../common/fivef-icon/fivef-icon.component';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'fivef-datepicker',
  host: {class: 'fivef-datepicker'},
  standalone: true,
  templateUrl: './fivef-datepicker.component.html',
  styleUrls: ['./fivef-datepicker.component.scss'],
  imports: [
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    FivefDisableInputHintsWrapperDirective,
    FivefIconComponent,
    MatButtonModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefDatepickerComponent),
      multi: true,
    }
  ]
})
export class FivefDatepickerComponent implements ControlValueAccessor, OnInit {
  private onDestroy = new Subject<void>();

  private onTouchListeners: Function[] = [];
  private onChangeListeners: Function[] = [];

  public _value;

  @Input()
  appearance: 'form' | 'tableCell' = 'form';

  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  overdue: boolean = false;

  @ViewChild('inputElementRef', {static: true})
  inputElementRef: ElementRef<any>;

  @Input()
  minDate?: Date;

  @Input()
  maxDate?: Date;

  @Input()
  placeholder?: string;

  @Output()
  onChange = new EventEmitter();

  private today = new Date();
  public alertOnPast = false;

  /**
   * Mark date red on past or today.
   */
  _enableAlertOnPast = false;

  @Input()
  set value(val) {
    this.writeValue(isNaN(Date.parse(val)) ? null : val);
  }

  /**
   * Compatibility input. Use min instead.
   *
   * @param date
   */
  @Input()
  set startAt(date) {
    this.minDate = date;
  }

  @Input()
  set enableAlertOnPast(alertOnPast: boolean) {
    this._enableAlertOnPast = alertOnPast;
    if (alertOnPast && this._value) {
      let date: Date = this._value;
      if (date && typeof (date) === 'string') {
        date = new Date(date);
      }
      this.alertOnPast = DateUtil.inPastOrToday(this.today, date);
    }
  }

  constructor(private i18n: TranslateService,
              private dateAdapter: DateAdapter<Date>,
              private cdr: ChangeDetectorRef) {
    const currentLang = this.i18n.currentLang;
    this.dateAdapter.setLocale(currentLang);
  }

  ngOnInit() {
    this.i18n.onLangChange.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe(lang => {
      this.dateAdapter.setLocale(lang.lang);
      this.cdr.detectChanges();
    })
  }

  public registerOnChange(fn: Function): void {
    this.onChangeListeners.push(fn);
  }

  public registerOnTouched(fn: Function): void {
    this.onTouchListeners.push(fn);
  }

  writeValue(obj: any): void {
    this._value = obj;

    // Mark date red on past or today
    if (this._enableAlertOnPast && obj) {
      let date = obj;
      if (typeof (date) === 'string') {
        date = new Date(obj);
      }
      this.alertOnPast = DateUtil.inPastOrToday(this.today, date);
    }
  }

  public notifyOnChange(obj: any) {
    for (const changeListener of this.onChangeListeners) {
      changeListener(obj);
    }
  }

  public notifyOnTouch() {
    for (const touchListener of this.onTouchListeners) {
      touchListener();
    }
  }

  valueChanges(event) {
    this.notifyOnChange(this._value);
    this.notifyOnTouch();

    // Mark date red on past or today
    if (this._enableAlertOnPast) {
      this.alertOnPast = DateUtil.inPastOrToday(this.today, event.value);
    }
    this.onChange.emit(event.value);
  }

  public focusOnInput() {
    this.inputElementRef.nativeElement.focus();
  }

  public clearDueDate() {
    if (this.disabled || this.required) {
      return;
    }

    this.writeValue(null);
    this.onChange.emit(null);
  }
}
