import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {FivefCreateContactDialogComponent} from './fivef-create-contact-dialog/fivef-create-contact-dialog.component';
import {LicenceControlService} from 'app/services/licence-control.service';
import {Organization} from 'app/+store/organization/organization';
import {OrganizationSelectors} from 'app/+store';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[fivefContact]'
})
export class FivefContactDirective implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @Input()
  contactType: 'contact_people' | 'contact_organizations' = 'contact_people';

  @Input()
  contactToolbar = true;

  @Input()
  lockContactType = true;

  @Input()
  parent = null;

  @Output()
  onNewContact = new EventEmitter<any>();

  organizations: Observable<Organization[]>;
  organizationsCount: number;

  constructor(private el: ElementRef,
              public dialog: MatDialog,
              private _store: Store<AppState>,
              private licenceControl: LicenceControlService) {
    this.organizations = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.organizations
      .pipe(takeUntil(this.onDestroy))
      .subscribe((organizations) => {
        this.organizationsCount = organizations.length;
      })
  }

  ngOnInit(): void {
    this.licenceControl.elementRef = this.el;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  @HostListener('click')
  onClick() {
    const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
    if (licenceChecker) {
      this.openDialog();
    }
  }

  /**
   * open dialog with files uploading on click element this directive attached to
   */
  openDialog() {
    const dialogRef = this.dialog.open(FivefCreateContactDialogComponent,
      {
        data: {
          options: {
            contactType: this.contactType,
            lockContactType: this.lockContactType,
            autosave: false,
            contactToolbar: this.contactToolbar,
            parent: this.parent
          }
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.onNewContact.emit(ContactRequestBuilder.generateDataForApi(result));
        console.log(result);
      }
    });
  }
}
