import {Inject, Injectable, InjectionToken} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpResponse
} from '@angular/common/http';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {map, scan} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {IApiResourceBuilder, IApiResponse} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/builders/api-resource.builder';
import {AngularTokenService} from 'angular-token';
import {Observable} from "rxjs";

export type Saver = (blob: Blob, filename?: string) => void

export const SAVER = new InjectionToken<Saver>('saver')

export function getSaver(): Saver {
  return saveAs;
}
function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpProgressEvent(event: HttpEvent<any>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress
    || event.type === HttpEventType.UploadProgress
}

export function download(
  saver?: (b: Blob) => void
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan((previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null
            }
          }
          if (isHttpResponse(event)) {
            if (saver && event.body) {
              saver(event.body)
            }
            return {
              progress: 100,
              state: 'DONE',
              content: event.body
            }
          }
          return previous
        },
        {state: 'PENDING', progress: 0, content: null}
      )
    )
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE' | string
  progress: number
  content: Blob | null
}

@Injectable()
export class FileApiResourceService {
  apiUrl;

  constructor(private http: HttpClient,
              private env: EnvService,
              private _tokenSvc: AngularTokenService,
              @Inject(SAVER) private save: Saver) {
    this.apiUrl = env.tusServer();
  }

  getAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getOne<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    return this.http
      .get(`${this.apiUrl}/${path}/${resource.id}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  get<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .get(`${this.apiUrl}/${path}`, { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  update<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .put(`${this.apiUrl}/${path}/${resource.id}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  put<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  putAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M | M[]> {
    return this.http
      .put(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  create<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M, params?: HttpParams): Observable<M> {
    const payload = ApiResourceBuilder.toRequest(builder, resource);
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  post<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  postAll<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, payload: any, params?: HttpParams): Observable<M[]> {
    return this.http
      .post(`${this.apiUrl}/${path}`, JSON.stringify(payload), { params: params, headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M[]>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  delete<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string, resource: M): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}/${resource.id}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  getBlob(url: string, filename: string, currentAuthData): Observable<Download> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    for (const key in currentAuthData) {
      if (currentAuthData.hasOwnProperty(key)) {
        headersConfig[key] = currentAuthData[key];
      }
    }
    headersConfig['access-token'] = currentAuthData['accessToken'];
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    const headers = new HttpHeaders(headersConfig)

    return this.http.get(url, {
      reportProgress: true,
      responseType: 'blob',
      observe: 'events',
      headers: headers,
    }).pipe(download(blob => this.save(blob, filename)))
  }

  postBlob(url: string, filename: string, payload, currentAuthData): Observable<Download> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    for (const key in currentAuthData) {
      if (currentAuthData.hasOwnProperty(key)) {
        headersConfig[key] = currentAuthData[key];
      }
    }
    headersConfig['access-token'] = currentAuthData['accessToken'];
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    const headers = new HttpHeaders(headersConfig)

    return this.http.post(url, payload, {
      reportProgress: true,
      responseType: 'blob',
      observe: 'events',
      headers: headers,
    }).pipe(download(blob => this.save(blob, filename)))
  }

  del<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, path: string): Observable<M> {
    return this.http
      .delete(`${this.apiUrl}/${path}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => <M>ApiResourceBuilder.fromResponse<B, M>(builder, res))
      );
  }

  private _setHeaders(): HttpHeaders {
    const currentAuthData: any = this._tokenSvc.currentAuthData;
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (this._tokenSvc.currentAuthData) {
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
    }
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    return new HttpHeaders(headersConfig)
  }
}

