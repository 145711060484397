<!-- Button to open the dialog -->
<button mat-raised-button (click)="openWhiteListTwoFactorAuthDialog()">{{
    'AUTH.EXCLUDE_USER_TWO_FACTOR'
      | translate
  }}
</button>

<ng-template #dialogTpl>
  <fivef-dialog [title]="'AUTH.EXCLUDE_USER_TWO_FACTOR'" [isCallToAction]="true">
    <div class="row mb-2">
      <ng-container>
        <div class="col-12 d-flex flex-row align-items-center">
          <div style="padding-right: 15px;">
            <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-300">person_add</mat-icon>
          </div>
          <fivef-contact-autocomplete elementName="E-Mail" [formControl]="newParticipant"
                                      [excludeTwoFactorWhitelist]="true" [showEmail]="true"
                                      [placeholder]="'CONTACTS.ADDRESS_BOOK_SEARCH'"
                                      (onSelect)="addUserToWhitelist($event)" [excludedIds]="excludedIds"
                                      [valueIsEmail]="true" [loadNew]="false">
          </fivef-contact-autocomplete>
        </div>
      </ng-container>
    </div>
    <fivef-loading-indicator class="fivef-user-whitelist-two-factor-dialog--loading-indicator"
                             *ngIf="(loading$ | async)" [appearance]="'spinnerOnly'"></fivef-loading-indicator>

    <fivef-icon-message-block *ngIf="(!usersWhiteListed || usersWhiteListed?.length == 0) && !(loading$ | async)"
                              [icon]="'person'"
                              [message]="'AUTH.NO_EXCLUDED_USERS'"></fivef-icon-message-block>

    <div class="dvtx-participant-role-dialog"
         *ngIf="usersWhiteListed && usersWhiteListed?.length > 0 && !(loading$ | async)">
      <ul>
        <li>
          <div class="row m-0">
            <div class="col-7">
              {{ 'WORKFLOW_ENGINE.MEMBER' | translate }}
            </div>
            <div class="col-3 text-center">
              <div class="d-flex justify-content-center">
                <div class="align-self-center">
                  {{ 'GENERAL.CREATED_AT' | translate }}
                </div>
              </div>
            </div>
            <div class="col-2 text-center">
              <div class="d-flex justify-content-center">
                <div class="align-self-center">
                  {{ 'GENERAL.REMOVE_ACTION' | translate }}
                </div>
                <div class="align-self-center">
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- user whitelist Listing -->
      <ul
        style="min-height: 300px; max-height: 400px; overflow-y: auto">
        <li class="m-0 p-1" *ngFor="let user of usersWhiteListed">
          <div class="row m-0">
            <div class="col-7 dvtx-contact-chip-cell text-truncate">
              <fivef-avatar [size]="'sm'" [email]="user?.email" [showEmail]="true" [showTitle]="true"></fivef-avatar>
            </div>
            <div class="col-3 text-center align-self-center">
              <span>{{ user?.createdAt | date }}</span>
            </div>
            <div class="col-2 text-center align-self-center">
              <button (click)="openRemoveUserTwoFactorDialog(user)" mat-icon-button>
                <mat-icon matPrefix>close</mat-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div fivef-dialog-footer>
      <button mat-raised-button color="primary" (click)="closeDialog()">{{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
