
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserTwoFactorWhitelist } from 'app/+store/user/user-two-factor-whitelist';
import { UserTwoFactorWhitelistBuilder } from 'app/+store/user/user-two-factor-whitelist.builder';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IApiResponse } from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import { map } from 'rxjs/operators';
import { ApiResourceBuilder } from 'app/lib/fivef-net/fivef-api-resource/builders/api-resource.builder';
import { EnvService } from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import { AngularTokenService } from 'angular-token';


@Injectable()
export class TwoFactorUserWhitelistService {
  basePath;
  apiUrl;
  constructor(private http: HttpClient, private env: EnvService, private _tokenSvc: AngularTokenService) {
    this.apiUrl = env.apiBase();
    this.basePath = `api/v3/organizations/tfa_whitelisted_accounts`;
  }

  getAll(): Observable<UserTwoFactorWhitelist[]> {
    const builder = new UserTwoFactorWhitelistBuilder();
    return <Observable<UserTwoFactorWhitelist[]>>this.http
      .get(`${this.apiUrl}/${this.basePath}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<UserTwoFactorWhitelistBuilder, UserTwoFactorWhitelist>(builder, res))
      );
  }

  create(email): Observable<UserTwoFactorWhitelist> {
    const builder = new UserTwoFactorWhitelistBuilder();
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    }
    return <Observable<UserTwoFactorWhitelist>>this.http
      .post(`${this.apiUrl}/${this.basePath}`, JSON.stringify(payload), { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<UserTwoFactorWhitelistBuilder, UserTwoFactorWhitelist>(builder, res))
      );
  }

  remove(email): Observable<UserTwoFactorWhitelist> {
    const builder = new UserTwoFactorWhitelistBuilder();
    const userBase64 = btoa(email);
    return <Observable<UserTwoFactorWhitelist>>this.http
      .delete(`${this.apiUrl}/${this.basePath}/${userBase64}`, { headers: this._setHeaders() }).pipe(
        map((res: IApiResponse) => ApiResourceBuilder.fromResponse<UserTwoFactorWhitelistBuilder, UserTwoFactorWhitelist>(builder, res))
      );
  }

  private _setHeaders(): HttpHeaders {
    const currentAuthData: any = this._tokenSvc.currentAuthData;
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (this._tokenSvc.currentAuthData) {
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
    }
    delete headersConfig['tokenType'];
    delete headersConfig['accessToken'];
    return new HttpHeaders(headersConfig)
  }
}
