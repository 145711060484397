import {NgModule} from '@angular/core';
import {SharedModule} from '../../lib/fivef-legacy/shared/shared.module';
import {OrganizationComponent} from './organization.component';
import {OfficeSettingsComponent} from 'app/modules/organization/components/office-settings/office-settings.component';
import {MemberAddressService} from './services/member-address.service';
import {MemberService} from './services/member.service';
import {MemberEmailAddressService} from './services/member-email-address.service';
import {MemberPhoneNumberService} from './services/member-phone-number.service';
import {MemberFormComponent} from './components/member-form/member-form.component';
import {
  OrganizationMasterDataComponent
} from './components/organization-view/organization-master-data/organization-master-data.component';
import {
  OrganizationEmployeeComponent
} from './components/organization-view/organization-employee/organization-employee.component';
import {OrganizationUploadService} from './services/upload-file.service';
import {DashboardModule} from '../two-factor-auth/dashboard.module';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {organizationRoutes} from './organization.routes';
import {InvitationsViewComponent} from './components/organization-view/invitations-view/invitations-view.component';
import {EditRolesComponent} from './edit-roles/edit-roles.component';
import {EditRoleToggleComponent} from './edit-role-toggle/edit-role-toggle.component';
import {CustomizationModule} from 'app/modules/customization/customization.module';
import {OrganizationNavigatorComponent} from './components/navigator/organization-navigator.component';
import {AddressbookFrameComponent} from './components/frames/addressbook-frame/addressbook-frame.component';
import {CustomizationFrameComponent} from './components/frames/customization-frame/customization-frame.component';
import {EmployeesFrameComponent} from './components/frames/employees-frame/employees-frame.component';
import {FivefOrganizationMasterDataComponent} from './components/frames/fivef-organization-master-data/fivef-organization-master-data.component';
import {LicensesFrameComponent} from './components/frames/licenses-frame/licenses-frame.component';
import {LicenseAssignmentComponent} from './components/license-assignment/license-assignment.component';
import {SignatureModule} from '../signature/signature.module';
import {DavModule} from '../dav/dav.module';
import {
  DavContactImportFrameComponent
} from './components/frames/dav-contact-import-frame/dav-contact-import-frame.component';
import {
  DeleteStatusDialogComponent,
  EditStatusDialogComponent,
  TasksSettingsStandardFrameComponent
} from './components/frames/tasks-settings-standard-frame/tasks-settings-standard-frame.component';
import {ClientModule} from 'app/modules/client/client.module';
import {LabelsSettingsFrameComponent} from './components/frames/labels-settings-frame/labels-settings-frame.component';
import {LabelsListingComponent} from './components/organization-view/labels-listing/labels-listing.component';
import {LabelsModule} from 'app/lib/fivef-legacy/labels/labels.module';
import {
  BookmanCockpitSettingsFrameComponent
} from './components/frames/bookman-cockpit-settings-frame/bookman-cockpit-settings-frame.component';
import {OrganizationCardModule} from '../../lib/fivef-legacy/organization-card/organization-card.module';
import {AddressBookModule} from '../address-book/address-book.module';
import {InViewportModule} from 'ng-in-viewport';
import {SettingsFrameComponent} from './components/frames/settings-frame/settings-frame.component';
import {
  OrganizationSettingsComponent
} from './components/organization-view/organization-settings/organization-settings.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ClientsFrameComponent} from './components/frames/clients-frame/clients-frame.component';
import {
  MemberInvitationsFrameComponent
} from './components/frames/member-invitations-frame/member-invitations-frame.component';
import {
  CustomizationLogoFrameComponent
} from './components/frames/customization-logo-frame/customization-logo-frame.component';
import {
  CustomizationEmailFrameComponent
} from './components/frames/customization-email-frame/customization-email-frame.component';
import {
  LabelsGlobalSettingsFrameComponent
} from './components/frames/labels-global-settings-frame/labels-global-settings-frame.component';
import {
  TasksSettingsApprovalFrameComponent
} from './components/frames/tasks-settings-approval-frame/tasks-settings-approval-frame.component';
import {FiltersContainerModule} from 'app/lib/fivef-legacy/filters/filters-container.module';
import {EmployeeDirective} from './components/directives/employee.directive';
import {AddEmployeeDropdownComponent} from './components/add-employee-dropdown/add-employee-dropdown.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  UserWhitelistTwoFactorDialogComponent
} from './components/organization-view/user-whitelist-two-factor-dialog/user-whitelist-two-factor-dialog.component';
import {TwoFactorUserWhitelistService} from './services/two-factor-user-whitelist.service';
import {FivefColorPickerComponent} from '../../lib/fivef-ui/util/fivef-color-picker/fivef-color-picker.component';
import {FivefDialogComponent} from '../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefIconPickerComponent} from '../../lib/fivef-ui/util/fivef-icon-picker/fivef-icon-picker.component';
import {
  FivefCreateLabelDialogComponent
} from '../../lib/fivef-ui/label/fivef-create-label-dialog/fivef-create-label-dialog.component';
import {FivefLabelComponent} from '../../lib/fivef-ui/label/fivef-label/fivef-label.component';
import {FivefMenuItemComponent} from '../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';
import {
  FivefIconMessageBlockComponent
} from '../../lib/fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {
  FivefContextSidebarComponent
} from '../../lib/fivef-ui/common/fivef-context-sidebar/fivef-context-sidebar.component';
import {
  FivefContextSidebarContentDirective
} from '../../lib/fivef-ui/common/fivef-context-sidebar/fivef-context-sidebar-content.directive';
import {
  FivefLoadingIndicatorComponent
} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefCreateClientComponent} from '../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {FivefContactAutocompleteComponent} from '../../lib/fivef-ui/input/fivef-contact-autocomplete/fivef-contact-autocomplete.component';
import {FivefContactModule} from '../../lib/fivef-ui/contact/fivef-contact/fivef-contact.module';
import {FivefAlertComponent} from '../../lib/fivef-ui/util/fivef-alert/fivef-alert.component';
import {FivefDisableInputHintsWrapperDirective} from '../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';
import {FivefLegalFormNameByIdPipe} from '../../lib/fivef-ui/contact/fivef-legal-form-name-by-id.pipe';
import {FivefInfoBlockComponent} from '../../lib/fivef-ui/util/fivef-info-block/fivef-info-block.component';
import {FivefSettingsPanelComponent} from '../../lib/fivef-ui/navigation/fivef-settings-panel/fivef-settings-panel.component';

@NgModule({
  imports: [
    SharedModule,
    organizationRoutes,
    AddressBookModule,
    RouterModule,
    DashboardModule,
    HttpClientModule,
    CustomizationModule,
    SignatureModule,
    DavModule,
    FiltersContainerModule,
    ClientModule,
    LabelsModule,
    OrganizationCardModule,
    InViewportModule,
    MatCheckboxModule,
    OrganizationCardModule,
    FiltersContainerModule,
    FivefInfoBlockComponent,
    TranslateModule,
    FivefColorPickerComponent,
    FivefDialogComponent,
    FivefIconPickerComponent,
    FivefCreateLabelDialogComponent,
    FivefLabelComponent,
    FivefMenuItemComponent,
    FivefIconMessageBlockComponent,
    FivefContextSidebarComponent,
    FivefContextSidebarContentDirective,
    FivefLoadingIndicatorComponent,
    FivefCreateClientComponent,
    FivefContactAutocompleteComponent,
    FivefContactModule,
    FivefAlertComponent,
    FivefDisableInputHintsWrapperDirective,
    FivefLegalFormNameByIdPipe,
    FivefSettingsPanelComponent
  ],
  declarations: [
    OrganizationComponent,
    OfficeSettingsComponent,
    MemberFormComponent,
    OrganizationMasterDataComponent,
    OrganizationEmployeeComponent,
    MemberFormComponent,
    InvitationsViewComponent,
    EditRolesComponent,
    EditRoleToggleComponent,
    LicenseAssignmentComponent,
    OrganizationNavigatorComponent,
    AddressbookFrameComponent,
    ClientsFrameComponent,
    CustomizationFrameComponent,
    EmployeesFrameComponent,
    FivefOrganizationMasterDataComponent,
    LicensesFrameComponent,
    DavContactImportFrameComponent,
    TasksSettingsStandardFrameComponent,
    TasksSettingsApprovalFrameComponent,
    EditStatusDialogComponent,
    DeleteStatusDialogComponent,
    LabelsSettingsFrameComponent,
    LabelsGlobalSettingsFrameComponent,
    LabelsListingComponent,
    BookmanCockpitSettingsFrameComponent,
    SettingsFrameComponent,
    OrganizationSettingsComponent,
    MemberInvitationsFrameComponent,
    CustomizationLogoFrameComponent,
    CustomizationEmailFrameComponent,
    EmployeeDirective,
    AddEmployeeDropdownComponent,
    UserWhitelistTwoFactorDialogComponent
  ],
  providers: [
    MemberService,
    MemberAddressService,
    MemberEmailAddressService,
    MemberPhoneNumberService,
    OrganizationUploadService,
    TwoFactorUserWhitelistService
  ]
})
export class OrganizationModule {
}
