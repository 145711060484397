/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';
import { User } from '../../user/user';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const USER_HINT_TRIAL = 'USER_HINT_TRIAL';
export const RESET_AUTO_LOGOUT = 'RESET_AUTO_LOGOUT';

export class UserHintTrial implements Action {
  readonly type: string = USER_HINT_TRIAL;
  public payload = null;
  // userType is being misused for transmitting the authcode.
  constructor() {}
}

export class GetCurrentUser implements Action {
  readonly type: string = GET_CURRENT_USER;

  // Old store model expecting getter payload to be present:
  // Semantics: logoutOnFailure, default true.
  constructor(public payload = true) {
  }
}

export class GetCurrentUserSuccess implements Action {
  readonly type: string = GET_CURRENT_USER_SUCCESS;

  constructor(public payload = null) {
  }
}

export class SetCurrentUser implements Action {
  readonly type: string = SET_CURRENT_USER;

  constructor(public payload: User) {
  }
}

export class ResetCurrentUser implements Action {
  readonly type: string = RESET_CURRENT_USER;

  constructor(public payload = null) {
  }
}

export class ResetAutoLogout implements Action {
  readonly type: string = RESET_AUTO_LOGOUT;

  constructor(public payload = null) {
  }
}

export type Actions = GetCurrentUser
  | GetCurrentUserSuccess
  | SetCurrentUser
  | ResetCurrentUser
  | UserHintTrial
  | ResetAutoLogout;


