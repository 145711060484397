import {JsonApiInclude, JsonApiReference} from '../_legacy/models/json-api.model';
import {
  SimpleAddress,
  SimpleAddressResponse,
  SimpleEmailAddress,
  SimpleEmailAddressResponse,
  SimplePhoneNumber,
  SimplePhoneNumberResponse
} from '../contact/legacy/models/contact.interface';
import {AbstractPerson} from '../contact/legacy/person.model';
import {ContactAttribute, ContactAttributeGroup, ContactVisibility, SortContactAttributeGroup, visibilityTypes} from '../contact/contact';

export interface ContactPerson extends AbstractPerson {
  firstName: string,
  lastName: string,
  title: string,
  honorific: string,
  activatePartnerLinks?: boolean,
  contactVisibility: ContactVisibility,
  assignedOrganizations?: string[],
}

export function createNewContactPerson(): ContactPerson {
  return {
    id: '',
    firstName: '',
    lastName: '',
    title: '',
    honorific: '',
    activatePartnerLinks: false,
    attributeGroups: [],
    mainAddress: new SimpleAddress(),
    mainPhoneNumber: new SimplePhoneNumber(),
    mainEmailAddress: new SimpleEmailAddress(),
    contactVisibility: {visibleFor: '', type: visibilityTypes.visiblePublic},
    phoneNumbers: [],
    emailAddresses: [],
    addresses: []
  }
}

export function createContactPersonfromResponse(resp: ContactPersonResponse): ContactPerson {
  const ret = {
    id: resp.data.id,
    firstName: resp.data.attributes.first_name,
    lastName: resp.data.attributes.last_name,
    honorific: resp.data.attributes.honorific,
    title: resp.data.attributes.title_id,
    attributeGroups: [],
    contactVisibility: {visibleFor: '', type: visibilityTypes[resp.data.attributes.current_state]},
    mainAddress: undefined,
    mainEmailAddress: undefined,
    mainPhoneNumber: undefined,
    phoneNumbers: [],
    emailAddresses: [],
    addresses: []
  };

  ret.attributeGroups = resp.data.relationships.attribute_groups.data.map((x) => {
    const currResp = resp.included.find(y => y.id === x.id) as any as { id: string, attributes: { name: string }, relationships: { attributes: { data: JsonApiReference[] } } };
    const curr: ContactAttributeGroup = {
      id: currResp.id,
      name: currResp.attributes.name,
      items: currResp.relationships.attributes.data.map((z) => {
        const inc = resp.included.find(j => j.id === z.id) as any as { id: string, attributes: { attributekey: string, attributevalue: string } };
        const attr: ContactAttribute = {
          id: inc.id,
          value: inc.attributes.attributevalue,
          key: inc.attributes.attributekey
        };
        return attr
      })
    };
    return curr;
  }).sort(SortContactAttributeGroup);


  if (resp.data.relationships.main_address.data) {
    const foundAddress = resp.included.find(x => x.id === resp.data.relationships.main_address.data.id) as SimpleAddressResponse;
    const address = new SimpleAddress();
    address.city = foundAddress.attributes.city;
    address.countryName = foundAddress.attributes.country;
    address.locationOrType = foundAddress.attributes.address_type_id;
    address.street = foundAddress.attributes.street;
    address.streetNo = foundAddress.attributes.street_number;
    address.zip = foundAddress.attributes.post_code;
    address.id = foundAddress.id;
    ret.mainAddress = address;
  }

  if (resp.data.relationships.main_email_address.data) {
    const foundEmail = resp.included.find(x => x.id === resp.data.relationships.main_email_address.data.id) as SimpleEmailAddressResponse;
    const email = new SimpleEmailAddress();
    email.locationOrType = foundEmail.attributes.address_type_id;
    email.emailAddress = foundEmail.attributes.email_address;
    email.id = foundEmail.id;
    ret.mainEmailAddress = email;
  }

  if (resp.data.relationships.main_phone_number.data) {
    const foundPhone = resp.included.find(x => x.id === resp.data.relationships.main_phone_number.data.id) as SimplePhoneNumberResponse;
    const telephone = new SimplePhoneNumber();
    telephone.id = foundPhone.id;
    telephone.locationOrType = foundPhone.attributes.address_type_id;
    telephone.countryCode = foundPhone.attributes.country_code;
    telephone.phoneNumber = foundPhone.attributes.phone_number;
    ret.mainPhoneNumber = telephone
  }

  const furtherPhoneNumbers: SimplePhoneNumber[] = [];
  for (const phoneNumRel of resp.data.relationships.phone_numbers.data) {
    const phoneNumRelId = phoneNumRel.id;
    const phoneNum = new SimplePhoneNumber();

    const foundPhoneNum = resp.included.find(x => x.id === phoneNumRelId) as SimplePhoneNumberResponse;
    phoneNum.id = foundPhoneNum.id;
    phoneNum.countryCode = foundPhoneNum.attributes.country_code;
    phoneNum.locationOrType = foundPhoneNum.attributes.address_type_id;
    phoneNum.phoneNumber = foundPhoneNum.attributes.phone_number;

    furtherPhoneNumbers.push(phoneNum)
  }
  ret.phoneNumbers = furtherPhoneNumbers;

  const furtherEmailAddresses: SimpleEmailAddress[] = [];
  for (const emailRel of resp.data.relationships.email_addresses.data) {
    const emailRelId = emailRel.id;
    const emailAddr = new SimpleEmailAddress();

    const foundEmailAddr = resp.included.find(x => x.id === emailRelId) as SimpleEmailAddressResponse;
    emailAddr.id = foundEmailAddr.id;
    emailAddr.emailAddress = foundEmailAddr.attributes.email_address;
    emailAddr.locationOrType = foundEmailAddr.attributes.address_type_id;

    furtherEmailAddresses.push(emailAddr);
  }
  ret.emailAddresses = furtherEmailAddresses;

  const furtherAdresses: SimpleAddress[] = [];
  for (const addressRel of resp.data.relationships.addresses.data) {
    const addressId = addressRel.id;
    const foundAddress = resp.included.find(x => x.id === addressId) as SimpleAddressResponse;
    const address = new SimpleAddress();
    address.city = foundAddress.attributes.city;
    address.countryName = foundAddress.attributes.country;
    address.locationOrType = foundAddress.attributes.address_type_id;
    address.street = foundAddress.attributes.street;
    address.streetNo = foundAddress.attributes.street_number;
    address.zip = foundAddress.attributes.post_code;
    address.id = foundAddress.id;
    furtherAdresses.push(address);
  }
  ret.addresses = furtherAdresses;

  return ret
}

export interface ContactPersonResponse {
  data: {
    type: string,
    id: string,
    attributes: {
      created_at: Date,
      updated_at: Date,
      attribute_groups: any[],
      current_state: string,
      title_id: string,
      honorific: string,
      first_name: string,
      last_name: string
    },
    relationships: {
      addresses: {
        data: JsonApiReference[]
      },
      email_addresses: {
        data: JsonApiReference[]
      },
      main_address: {
        data: JsonApiReference
      },
      main_email_address: {
        data: JsonApiReference
      },
      main_phone_number: {
        data: JsonApiReference
      },
      phone_numbers: {
        data: JsonApiReference[]
      },
      attribute_groups: {
        data: JsonApiReference[]
      },
    }
  }
  included: JsonApiInclude[]
}
