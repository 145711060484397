import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {
  ArtifactPublicUploadAccessBuilder,
  ArtifactPublicUploadBuilder, DmsQueuedArtifactBuilder,
  ProcessArtifactBuilder
} from './process-artifact.builder';
import {
  ArtifactPublicUpload,
  ArtifactPublicUploadAccess,
  DmsProvider,
  DmsQueuedArtifact,
  ProcessArtifact
} from './process-artifact';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Download, FileApiResourceService} from './file-api-resource.service';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {AngularTokenService} from 'angular-token';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {OrganizationProjectsBuilder} from '../process-management/process-management.builder';
import {OrganizationProjects} from '../process-management/process-management';

export type ArtifactProperty = 'year' | 'month' | 'keywords' | 'description' | 'role' | 'document_type';

@Injectable()
export class ProcessArtifactService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';
  readonly ARTIFACT_BASE_PATH = 'api/v1/artifacts';
  readonly BASE_PATH_V3 = '/api/v3/workflow_engine/processes/';
  readonly BASE_PATH_V3_SUFFIX = '/file_inbox';
  readonly PUBLIC_DOWNLOAD_PATH = 'api/v1/file_inbox';

  constructor(private _http: FivefApiResourceService,
              private _fhttp: FileApiResourceService,
              private env: EnvService,
              private _httpClient: HttpClient,
              private _tokenSvc: AngularTokenService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }

  getAllPublicLinks(id: string): Observable<ArtifactPublicUploadAccess[]> {
    const builder = new ArtifactPublicUploadAccessBuilder();
    return <Observable<ArtifactPublicUploadAccess[]>>this._fhttp.get<ArtifactPublicUploadAccessBuilder, ArtifactPublicUploadAccess>(builder, `${this.BASE_PATH_V3}/${id}/${this.BASE_PATH_V3_SUFFIX}`);
  }

  getAllPublicDocuments(id: string): Observable<ArtifactPublicUpload[]> {
    const builder = new ArtifactPublicUploadBuilder();
    return <Observable<ArtifactPublicUpload[]>>this._fhttp.get<ArtifactPublicUploadBuilder, ArtifactPublicUpload>(builder, `${this.BASE_PATH_V3}/${id}/${this.BASE_PATH_V3_SUFFIX}/documents`);
  }

  createPublicLink(id: string, title: string, description: string = null): Observable<ArtifactPublicUploadAccess> {
    const builder = new ArtifactPublicUploadAccessBuilder();
    const payload = {
      data: {
        attributes: {
          title: title,
          description: description
        }
      }
    }
    return <Observable<ArtifactPublicUploadAccess>>this._fhttp.post<ArtifactPublicUploadAccessBuilder, ArtifactPublicUploadAccess>(builder, `${this.BASE_PATH_V3}/${id}/${this.BASE_PATH_V3_SUFFIX}`, payload);
  }

  deletePublicLink(id: string, linkId: string): Observable<ArtifactPublicUploadAccess> {
    const builder = new ArtifactPublicUploadAccessBuilder();
    return <Observable<ArtifactPublicUploadAccess>>this._fhttp.del<ArtifactPublicUploadAccessBuilder, ArtifactPublicUploadAccess>(builder, `${this.BASE_PATH_V3}/${id}/${this.BASE_PATH_V3_SUFFIX}/${linkId}`);
  }

  downloadProcessDocument(processId, documentId, filename): Observable<Download> {
    return this._fhttp.getBlob( `${this.env.tusServer()}${this.BASE_PATH_V3}${processId}/artifacts/${documentId}/download`, filename, this._tokenSvc.currentAuthData);
  }

  downloadProcessExport(processId, exportId, filename): Observable<Download> {
    return this._fhttp.getBlob( `${this.env.tusServer()}/${this.BASE_PATH}/${processId}/file_exports/${exportId}/download`, filename, this._tokenSvc.currentAuthData);
  }

  downloadPublicDocument(processId, documentId, filename): Observable<Download> {
    return this._fhttp.getBlob( `${this.env.tusServer()}${this.BASE_PATH_V3}${processId}/${this.BASE_PATH_V3_SUFFIX}/documents/${documentId}/download`, filename, this._tokenSvc.currentAuthData);
  }

  deletePublicDocument(processId: string, documentId: string): Observable<ArtifactPublicUpload> {
    const builder = new ArtifactPublicUploadBuilder();
    return <Observable<ArtifactPublicUpload>>this._fhttp.del<ArtifactPublicUploadBuilder, ArtifactPublicUpload>(builder, `${this.BASE_PATH_V3}/${processId}/${this.BASE_PATH_V3_SUFFIX}/documents/${documentId}`);
  }

  getAll(id: string, recursive = false): Observable<ProcessArtifact[]> {
    const queryParams = recursive ? '?include_children=true' : '';
    const builder = new ProcessArtifactBuilder(id, recursive);
    return <Observable<ProcessArtifact[]>>this._fhttp.get<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH}/${id}/artifacts${queryParams}`);
  }

  getVersions(processId: string, id: string): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(processId);
    return <Observable<ProcessArtifact>>this._fhttp.get<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH}/${processId}/artifacts/${id}/versions`);
  }

  /**
   * Fetch batch of artifacts by their IDs.
   *
   * artifacts/batch API to fetch dedicated documents by ID.
   * Currently only used at the UploadedDocumentsComponent at the main dashboard to provide the
   * documents for the document preview (not the menu, this is filled by udpate messages).
   *
   * ATTENTION: This call has no restriction on the size of the ids array and can possibly get expensive!
   *
   * @param ids Array of artifact/document IDs.
   */
  getAllByIds(ids: string[]): Observable<ProcessArtifact[]> {
    const builder = new ProcessArtifactBuilder(null, true);
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    };
    return <Observable<ProcessArtifact[]>>this._fhttp.postAll<ProcessArtifactBuilder, ProcessArtifact>(builder, 'api/v1/artifacts/batch', payload);
  }

  getOne(processId: string, id: string): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(processId);
    return <Observable<ProcessArtifact>>this._fhttp.get<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH}/${processId}/artifacts/${id}`);
  }

  getOneById(id: string): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(null);
    return <Observable<ProcessArtifact>>this._fhttp.get<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.ARTIFACT_BASE_PATH}/artifacts/${id}`);
  }

  remove(processId: string, id: string, deleteFile = false): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(processId);
    return <Observable<ProcessArtifact>>this._fhttp.del<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH}/${processId}/artifacts/${id}?delete_document=${deleteFile}`);
  }

  getAllExternal(token: string): Observable<ProcessArtifact[]> {
    const builder = new ProcessArtifactBuilder(token);
    return <Observable<ProcessArtifact[]>>this._fhttp.get<ProcessArtifactBuilder, ProcessArtifact>(builder, `api/v1/access/external/artifacts?access_token=${token}`);
  }

  pickFileFromDms(processId: string, referenceId: string, dmsDocumentId: string, accountType): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(processId);
    const payload = {
      data: {
        attributes: {
          dms_document_id: dmsDocumentId,
          reference_id: referenceId,
          account_type: accountType
        }
      }
    };
    return <Observable<ProcessArtifact>>this._http.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `api/v1/workflow_engine/quickstart/${processId}/attach_file_from_dms`, payload);
  }

  collectorPickFileFromDms(collectorId: string, nodeId: string, role: string, dmsDocumentId: string, accountType): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(collectorId);
    const payload = {
      data: {
        attributes: {
          dms_document_id: dmsDocumentId,
          reference_id: nodeId,
          account_type: accountType,
          role: role,
        }
      }
    };
    return <Observable<ProcessArtifact>>this._http.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `api/v1/clr/checklists/${collectorId}/nodes/${nodeId}/uploads/attach_file_from_dms`, payload);
  }

  getBlob(url: string, fileName: string, currentAuthData = null, callback: Function = () => {}) {
    if (currentAuthData) {
      const headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
      for (const key in currentAuthData) {
        if (currentAuthData.hasOwnProperty(key)) {
          headersConfig[key] = currentAuthData[key];
        }
      }
      headersConfig['access-token'] = currentAuthData['accessToken'];
      delete headersConfig['tokenType'];
      delete headersConfig['accessToken'];
      const headers = new HttpHeaders(headersConfig);

      this._httpClient.get(url, {
        responseType: 'blob',
        headers: headers,
      }).pipe(first())
        .subscribe((blob) => {
          callback();
          saveAs(blob, fileName);
        }, err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.DEFAULT')
        });
      return;
    }
    this._httpClient.get(url, {
      responseType: 'blob'
    }).pipe(first())
      .subscribe((blob) => {
        callback();
        saveAs(blob, fileName);
      }, err => {
        console.error(err);
        this._notifyService.error('HTTP_ERROR.DEFAULT')
      });
  }

  /**
   * Updates the artifact reference and links the artifact to another linked resource e.g. Collecto item.
   * Allows by batch mode (artifactsIds) to link multiple artifacts to one reference.
   *
   * @param processId
   * @param artifactsIds
   * @param referenceId
   */
  updateArtifactReference(processId: string, artifactsIds: string[], referenceId: string): Observable<ProcessArtifact[]> {
    const builder = new ProcessArtifactBuilder(processId);
    const payload = {
      data: {
        attributes: {
          reference_id: referenceId,
          artifacts_ids: artifactsIds
        }
      }
    };
    return <Observable<ProcessArtifact[]>>this._fhttp.postAll<ProcessArtifactBuilder, ProcessArtifact>(builder, `api/v1/workflow_engine/processes/${processId}/artifacts/move`, payload);
  }

  rename(processId: string, id: string, fileName: string): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(processId);
    const payload = {
      data: {
        attributes: {
          filename: fileName
        }
      }
    };
    return <Observable<ProcessArtifact>>this._fhttp.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH}/${processId}/artifacts/${id}/rename`, payload);
  }

  markSeen(id: string): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(null);
    const payload = {};
    return <Observable<ProcessArtifact>>this._fhttp.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.ARTIFACT_BASE_PATH}/artifacts/${id}/mark_seen`, payload);
  }

  /**
   * Returns processes as target for document moving.
   * The processes must be open, accessible and have uploading enabled.
   * Only processes of the current organization are considered.
   *
   * Uses the OrganizationProjects interface because it contains additional attributes
   * helping the user to distuingish the right process by
   * - included parent title
   * - included identifier
   * - included client
   *
   * @param processId
   * @param artifactId
   * @param query
   */
  getMoveArtifactTargets(processId: string, artifactId: string, query: string = null) {
    const queryParam = query ? `?q=${query}` : '';
    const builder = new OrganizationProjectsBuilder();
    return <Observable<OrganizationProjects[]>>this._http.getAll<OrganizationProjectsBuilder, OrganizationProjects>(builder, `${this.BASE_PATH_V3}/${processId}/artifacts/${artifactId}/move/candidates${queryParam}`)
  }

  /**
   * Moves an artifact to another process.
   * Target process candidate can be retrieved by getMoveArtifactTargets.
   *
   * @param processId
   * @param artifactId
   * @param targetProcessId
   */
  moveArtifactToTargetProcess(processId: string, artifactId: string, targetProcessId) {
    const payload = {
      data: {
        attributes: {
          process_id: targetProcessId
        }
      }
    }
    const builder = new ProcessArtifactBuilder(processId);
    return <Observable<ProcessArtifact>>this._http.post<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH_V3}/${processId}/artifacts/${artifactId}/move`, payload)
  }

  /**
   * Returns all artifacts being candidates for DMS synchronization.
   * The requirements and therefore the result set depend on the requested dmsProvider, e.g. DATEV, ELO, LOBO etc.
   *
   * @param processId
   * @param dmsProvider
   * @param referenceId
   */
  getArtifactsForDmsSyncronization(processId: string, dmsProvider: DmsProvider, referenceId: string = null): Observable<DmsQueuedArtifact[]> {
    const builder = new DmsQueuedArtifactBuilder(processId);
    let refQuery = '';
    if (referenceId) {
      refQuery = `&reference_id=${referenceId}`;
    }
    return <Observable<DmsQueuedArtifact[]>>this._http.get<DmsQueuedArtifactBuilder, DmsQueuedArtifact>(builder, `${this.BASE_PATH_V3}/${processId}/dms_sync_queues?all=true&dms_provider=${dmsProvider}${refQuery}`)
  }

  /**
   * Put artifacts give by artifactIds into the synchronization queue of the according dmsProvider.
   * @param processId
   * @param artifactIds
   * @param dmsProvider
   */
  enqueueArtifactsForDmsSyncronization(processId: string, artifactIds: string[], dmsProvider: DmsProvider): Observable<DmsQueuedArtifact[]> {
    const payload = {
      data: {
        attributes: {
          artifact_ids: artifactIds,
          dms_provider: dmsProvider
        }
      }
    }
    const builder = new DmsQueuedArtifactBuilder(processId);
    return <Observable<DmsQueuedArtifact[]>>this._http.postAll<DmsQueuedArtifactBuilder, DmsQueuedArtifact>(builder, `${this.BASE_PATH_V3}/${processId}/dms_sync_queues`, payload)
  }

  update(processId: string, artifactId: string, property: ArtifactProperty, value: any): Observable<ProcessArtifact> {
    const builder = new ProcessArtifactBuilder(null);
    const payload = { data: { attributes: { } } };
    payload['data']['attributes'][property] = value;
    return <Observable<ProcessArtifact>>this._http.put<ProcessArtifactBuilder, ProcessArtifact>(builder, `${this.BASE_PATH_V3}/${processId}/artifacts/${artifactId}/${property}`, payload);
  }
}
