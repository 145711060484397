import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as model from './system-health';
import * as build from './system-health.builder';

@Injectable()
export class OperatorSystemHealthService {
  readonly BASE_PATH = 'operator/api/v1/system';

  constructor(private _http: FivefApiResourceService) {
  }

  getStats(): Observable<model.Operator.SystemHealth> {
    const builder = new build.Operator.SystemHealthBuilder();
    return <Observable<model.Operator.SystemHealth>>this._http.get<build.Operator.SystemHealthBuilder, model.Operator.SystemHealth>(builder, `${this.BASE_PATH}/stats`);
  }
}
