import {Injectable, OnDestroy} from '@angular/core';
import {GLOBAL_LABELS, ILabelParams, LabelOrigin} from './label.interface';
import {Label} from './label';
import {LabelBuilder} from './label.builder';
import {KanbanApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/kanban-api-resource.service';
import {Observable} from 'rxjs/internal/Observable';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {LabelSelectors} from './index';
import {map, takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ItemLabels} from '../item-labels/item-labels';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';
import {getSelectableLabels} from './label.selectors';

@Injectable({providedIn: 'root'})
export class LabelService implements OnDestroy {
  private onDestroy = new Subject<void>();
  readonly BASE_PATH = 'api/v1/labels/labels';

  /**
   * Sorted labels of store without contextual labels.
   */
  public sortedLabels = new BehaviorSubject<Label[]>([]);

  /**
   * Sorting closure for labels.
   * @param a
   * @param b
   */
  private sortLabelsByTitle = (a, b) => (this.translateSvc.instant(a.title).trim().toLowerCase() < this.translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1);

  constructor(private store: Store<AppState>,
              private http: KanbanApiResourceService,
              private translateSvc: TranslateService) {
    this.initializeSortedLabels();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.sortedLabels.complete();
  }

  /**
   * Updates the sorted labels stream provided by member sortedLabels.
   * Implemented as subscription to not trigger sorting by sub item observers.
   * The sorting should only be invoked if the label set is altered or fetched.
   *
   * @private
   */
  private initializeSortedLabels() {
    this.store.select(LabelSelectors.getSelectableLabels)
      .pipe(
        takeUntil(this.onDestroy),
        map((labels: Label[]) => labels.sort(this.sortLabelsByTitle)))
      .subscribe(sortedLabels => this.sortedLabels.next(sortedLabels));
  }

  public getItemLabels(id: string) {

  }

  getAll(boardId = null): Observable<Label[]> {
    const builder = new LabelBuilder();
    let q = '';
    if (boardId) {
      q = `?configuration_id=${boardId}`;
    }
    return <Observable<Label[]>>this.http.get<LabelBuilder, Label>(builder, `${this.BASE_PATH}${q}`);
  }

  getOne(id: string): Observable<Label> {
    const builder = new LabelBuilder();
    return <Observable<Label>>this.http.get<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`);
  }

  create(params: ILabelParams): Observable<Label> {
    const builder = new LabelBuilder();
    const payload = builder.toRequest(params);
    return <Observable<Label>>this.http.post<LabelBuilder, Label>(builder, `${this.BASE_PATH}`, payload);
  }

  edit(id: string, params: ILabelParams): Observable<Label> {
    const builder = new LabelBuilder();
    const payload = builder.toRequest(params);
    return <Observable<Label>>this.http.put<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`, payload);
  }

  remove(id: string): Observable<Label> {
    const builder = new LabelBuilder();
    return <Observable<Label>>this.http.del<LabelBuilder, Label>(builder, `${this.BASE_PATH}/${id}`);
  }
}
