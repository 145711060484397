import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {InvoiceAddress} from 'app/+store/_legacy/api/models/invoice-address';
import {InvoiceAddressBuilder} from 'app/+store/_legacy/api/builders/invoice-address.builder';
import {ResourceBuilder} from 'app/+store/_legacy/api/builders/resource.builder';
import {ApiErrorBuilder} from 'app/+store/_legacy/api/builders/api-error.builder';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable()
export class InvoiceAddressService {
  readonly apiPath = 'sales/invoice_addresses';
  basePath;
  constructor(private _tokenService: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.apiPath}`;
   }


  getAll(): Observable<InvoiceAddress[]> {
    return this._tokenService.get(this.basePath).pipe(
      map((res: Response) => <InvoiceAddress[]>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleArrayError),);
  }

  show(id): Observable<InvoiceAddress> {
    return this._tokenService.get(`${this.basePath}/${id}`).pipe(
      map((res: Response) => <InvoiceAddress>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  create(invoiceAddress: InvoiceAddress): Observable<InvoiceAddress> {
    const request = InvoiceAddressBuilder.toRequest(invoiceAddress);
    return this._tokenService.post(this.basePath, request).pipe(
      map((res: Response) => <InvoiceAddress>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  update(id, invoiceAddress: InvoiceAddress): Observable<InvoiceAddress> {
    const request = InvoiceAddressBuilder.toRequest(invoiceAddress);
    return this._tokenService.put(`${this.basePath}/${id}`, request).pipe(
      map((res: Response) => <InvoiceAddress>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }

  destroy(id): Observable<InvoiceAddress> {
    return this._tokenService.delete(`${this.basePath}/${id}`).pipe(
      map((res: Response) => <InvoiceAddress>ResourceBuilder.fromResponse(res)),
      catchError(ApiErrorBuilder.handleError),);
  }
}
