import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FivefNotificationService} from '../../notification/fivef-notification/fivef-notification.service';
import {startWith} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {EmailValidator} from '../../../validator';
import {validPhoneNumber} from '../../../../modules/quickshare/modules/new-quickshare/components/recipient-selection/recipient-selection.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FivefSuffixButtonDirective} from '../../common/fivef-button/fivef-suffix-button.directive';

@Component({
  selector: 'fivef-input-ce',
  host: {'class': 'fivef-input-ce'},
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
    FivefSuffixButtonDirective
  ],
  templateUrl: './fivef-input-ce.component.html',
  styleUrls: ['./fivef-input-ce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefInputCeComponent implements OnInit, OnDestroy {
  private refreshSubject$: Subject<any> = new Subject<any>();

  @HostBinding('style.fontSize')
  fontSize: string = 'inherit';

  @ViewChild('inputRef', {static: true})
  public inputRef: ElementRef;

  @Input()
  public editable = false;

  @Input()
  public editOnClick = true;

  @Input()
  isDecimalType = false;

  @Input()
  isIntegerType = false;

  @Input()
  isEmailType = false;

  @Input()
  maxValue;

  @Input()
  isPhoneNumber = false;

  @Input()
  isNew = false;

  @Input()
  public suffix: string = null;

  @Input()
  public disabled = false;

  @Input()
  public showEditIcon = true;

  @Input()
  public showActionsOnHoverOnly = true;

  @Input()
  public showRemoveIcon = false;

  @Input()
  public enableMultiLines = false;

  @Input()
  public saveOnBlur = true;

  /**
   * Enables the form field alike background color.
   * Default: off.
   */
  @Input()
  transparentBackground = true;

  /**
   * Truncates the title instead of showing it in multiline setup.
   */
  @Input()
  public truncateContent = false;

  @Input()
  public placeholder: string = '';

  @Input()
  allowNull = false;

  public _value: string = '';

  @Output()
  onChange: EventEmitter<any> = new EventEmitter<any>(null);

  @Output()
  onEditing: EventEmitter<any> = new EventEmitter<any>(null);

  @Output()
  onCancelEditing: EventEmitter<any> = new EventEmitter<any>(null);

  @Input()
  private cursorAtStart = false;

  /**
   * Font side as CSS property.
   * Default 'inherit'.
   *
   * @param fontSize
   */
  @Input()
  set size(fontSize) {
    this.fontSize = fontSize;
  }

  @Input()
  set value(val: string) {
    if (val && typeof val === 'string' && this._value !== val) {
      this._value = val;
      this.inputRef.nativeElement.textContent = val;
    }
  }

  constructor(private cdr: ChangeDetectorRef,
              private notifyService: FivefNotificationService) {
    this.refreshSubject$.pipe(startWith(''))
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.refreshSubject$.complete();
  }

  public handleOnBlur($event, content) {
    $event.stopPropagation();

    if (!this.saveOnBlur) {
      return;
    }
    this.handleReturnKey($event, content);
  }

  public handleReturnKey($event, content) {
    if (this.enableMultiLines) {
      return true;
    }

    if ($event) $event.stopPropagation();
    this.save(content);
    return false;
  }

  private cancelChanges() {
    this.inputRef.nativeElement.textContent = this._value;
    if (!this.isNew && (this.showEditIcon || this.cursorAtStart)) {
      this.editable = false;
    }
    this.onCancelEditing.emit();
    this.cdr.detectChanges();
    return false;
  }

  public handleEscKey() {
    this.cancelChanges();
  }

  public remove() {
    this.onChange.emit('');
  }

  public enableEditing() {
    if (this.disabled) {
      return;
    }
    if (this.cursorAtStart && this.editable) {
      return;
    }
    this.onEditing.emit();
    this.editable = true;
    this.inputRef.nativeElement.focus();
    this.cdr.detectChanges();
    this.setEndOfContenteditable(this.inputRef.nativeElement);
  }

  private setEndOfContenteditable(contentEditableElement) {
    let range, selection;
    // Firefox, Chrome, Opera, Safari, IE 9+
    if (document.createRange) {
      // Create a range (a range is like the selection but invisible)
      range = document.createRange();
      // Select the entire contents of the element with the range
      range.selectNodeContents(contentEditableElement);
      // collapse the range to the end point. false means collapse to end rather than the start
      range.collapse(this.cursorAtStart);
      // get the selection object (allows you to change selection)
      selection = window.getSelection();
      // remove any selections already made
      selection.removeAllRanges();
      // make the range you have just created the visible selection
      selection.addRange(range);
    }
  }

  private save(inputRef) {
    let value = inputRef.textContent;

    if (value === this._value) {
      this.cancelChanges();
      return;
    }

    if (!this.allowNull
      && (!value
        || typeof value !== 'string')
      || value === 'undefined') {
      this.cancelChanges();
      return false;
    }

    if (this.isEmailType && !EmailValidator.emailValid(value)) {
      this.cancelChanges();
      this.notifyService.error('ORGANIZATION.CREATION.INVALID_EMAIL')
      return false;
    }

    if (this.isPhoneNumber && !validPhoneNumber(value)) {
      this.cancelChanges();
      this.notifyService.error('QUICKSHARE.INVALID_PHONE_NUMBER')
      return false;
    }

    if (this.cursorAtStart) {
      const parts = value.split('.');
      const onlyWhitespace = parts[0].replace(/\s/g, '');

      if (!onlyWhitespace) {
        this.cancelChanges();
        return false;
      }
    }

    if (this.isDecimalType) {
      const number = Number(value);
      if (typeof number !== 'number' || isNaN(number) || number > 9999999) {
        this.cancelChanges();
        return false;
      }
      value = number.toFixed(2);
    }

    if (this.isIntegerType) {
      const number = Number(value);
      if (typeof number !== 'number' || !Number.isInteger(number) || isNaN(number) || (this.maxValue && number > this.maxValue) || number > 9999999) {
        this.cancelChanges();
        return false;
      }
    }

    this.onChange.emit(value);
    this._value = value;
    this.refreshSubject$.next(this._value);

    if (this.isNew) {
      this._value = '';
      this.inputRef.nativeElement.textContent = this._value;
      return;
    }

    this._value = value
    this.inputRef.nativeElement.textContent = this._value;

    if (this.showEditIcon || this.cursorAtStart) {
      this.editable = false;
    }
    this.cdr.detectChanges();
  }

  public handleEnterKey() {
    if (this.isIntegerType || this.isDecimalType) {
      const number = Number(this.inputRef.nativeElement.textContent);
      const onlyNum = Math.floor(number)
      if (onlyNum > 999999) {
        return false;
      }
    }
  }

  public editContent($event) {
    // NOTE: mousedown and click event propagation prevention is necessary to
    // make content editable usable with CDK drag 'n drop component which consume these
    // event when blubbling up -->
    $event.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (this.cursorAtStart || this.editOnClick) {
      this.onEditing.emit();

      this.editable = true;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.inputRef.nativeElement.focus();
        this.cdr.detectChanges();
      }, 200);
    }
  }
}
