import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FivefControlValueAccessor} from 'app/lib/fivef-ui/input/fivef-control-value-accessor.directive';
import {ContactPerson} from 'app/+store/contact-person/contact-person';
import {SimpleAddress, SimpleEmailAddress, SimpleEmailAddressRequiredValidator, SimplePhoneNumber} from '../../../../+store/contact/legacy/models/contact.interface';
import {ContactPersonBaseForm, newContactBaseForm} from '../../../organization/models/person-contact-base-form';
import {ContactActions, NaturalPersonActions} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

/**
 * This component is used for creating new contacts as well as editing existing contacts.
 * The component name is misleading.
 */
@Component({
  selector: 'dvtx-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserProfileFormComponent),
      multi: true,
    }
  ]
})

export class UserProfileFormComponent extends FivefControlValueAccessor implements OnDestroy {
  private onDestroy = new Subject<void>();

  @Input()
  title: string = 'CONTACTS.NEW_TITLE';

  displayFurtherDataFields: boolean = false;
  myContactDetails: ContactPerson;

  public form: UntypedFormGroup;

  constructor(private store: Store<AppState>,
              fb: UntypedFormBuilder) {
    super();

    // Default for disabled for this component is true, override parent.
    this._disabled = true;

    this.form = fb.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      title: [undefined],
      honorific: [undefined],
      mainAddress: [new SimpleAddress()],
      mainPhoneNumber: [new SimplePhoneNumber()],
      mainEmailAddress: [new SimpleEmailAddress(), SimpleEmailAddressRequiredValidator()],
    });

    this.form.valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(((value: ContactPerson) => {
      const ret = newContactBaseForm();
      this.notifyOnChange({
        ...ret,
        contactPerson: {
          firstName: value.firstName,
          lastName: value.lastName,
          title: value.title,
          honorific: value.honorific,
          mainAddress: value.mainAddress,
          mainPhoneNumber: value.mainPhoneNumber,
          mainEmailAddress: value.mainEmailAddress,
          valid: this.form.valid
        },
        isValid: this.form.valid
      });

    }))

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  writeValue(obj: ContactPersonBaseForm): void {
    this.myContactDetails = obj ? obj.contactPerson : null;
    if (this.myContactDetails) {
      this.form.patchValue({
        firstName: obj.contactPerson.firstName,
        lastName: obj.contactPerson.lastName,
        title: obj.contactPerson.title,
        honorific: obj.contactPerson.honorific,
        mainAddress: obj.contactPerson.mainAddress || new SimpleAddress(),
        mainPhoneNumber: obj.contactPerson.mainPhoneNumber || new SimplePhoneNumber(),
        mainEmailAddress: obj.contactPerson.mainEmailAddress || new SimpleEmailAddress(),
        valid: this.form.valid
      });
    }
  }

  addPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new ContactActions.AddFurtherPhoneNumber({
      contactId: this.myContactDetails.id,
      phoneNumber
    }))
  }

  removePhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherPhoneNumber({
      contactId: this.myContactDetails.id,
      phoneNumberId
    }))
  }

  addEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new ContactActions.AddFurtherEmail({
      contactId: this.myContactDetails.id,
      emailAddress,
    }))
  }

  removeEmail(emailAddressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherEmail({
      contactId: this.myContactDetails.id,
      emailAddressId
    }))
  }

  addAddress(address: SimpleAddress) {
    this.store.dispatch(new ContactActions.AddFurtherAddress({
      contactId: this.myContactDetails.id,
      address,
    }))
  }

  removeAddress(addressId: string) {
    this.store.dispatch(new ContactActions.RemoveFurtherAddress({
      contactId: this.myContactDetails.id,
      addressId
    }))
  }

  addFurtherPhoneNumber(phoneNumber: SimplePhoneNumber) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherPhoneNumber({
      personId: this.myContactDetails.id,
      phoneNumber,
    }))
  }

  removeFurtherPhoneNumber(phoneNumberId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherPhoneNumber({
      personId: this.myContactDetails.id,
      phoneNumberId
    }))
  }

  addFurtherEmail(emailAddress: SimpleEmailAddress) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherEmail({
      personId: this.myContactDetails.id,
      emailAddress,
    }))
  }

  removeFurtherEmail(emailAddressId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherEmail({
      personId: this.myContactDetails.id,
      emailAddressId
    }))
  }

  addFurtherAddress(address: SimpleAddress) {
    this.store.dispatch(new NaturalPersonActions.AddFurtherAddress({
      personId: this.myContactDetails.id,
      address,
    }))
  }

  removeFurtherAddress(addressId: string) {
    this.store.dispatch(new NaturalPersonActions.RemoveFurtherAddress({
      personId: this.myContactDetails.id,
      addressId
    }))
  }
}
