import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {AuditTrail} from './audit-trail';

export class AuditTrailBuilder implements IApiResourceBuilder<AuditTrail> {
  fromResponse(data): AuditTrail {
    return new AuditTrail(
      data.id,
      data.attributes.filename,
      data.attributes.content,
      data.attributes.mime_type);
  }

  toRequest(_: AuditTrail) {
    return null;
  }
}
