<div *ngIf="foxdoxStatusData?.valid" class="dvtx-foxdox-status">
  <img class="mt-1 foxdox-logo" src="/assets/img/dvelop_documents_logo.png">

  <div class="card-content-hint card-content-bottom-margin mt-2">
    <mat-icon class="align-middle success fivef-color-status-success">check_circle</mat-icon>&nbsp;&nbsp;
    <span class="align-middle">{{ 'FOXDOX.LINK_ACCOUNT_SUCCESS' | translate }}</span>
  </div>
  <div class="mt-2">
    <div class="listing-box">
      <div class="box-body horizontal">
        <p>{{ 'FOXDOX.DOCUMENT_COUNT' | translate }} </p>
        <h1 class=""> &nbsp;&nbsp; {{foxdoxStatusData?.document_count}}</h1>
      </div>
    </div>

    <div class="listing-box">
      <div class="box-body horizontal">
        <p>{{ 'FOXDOX.FOLDER_COUNT' | translate }} </p>
        <h1 class=""> &nbsp;&nbsp; {{foxdoxStatusData?.folder_count}}</h1>
      </div>
    </div>
  </div>
  <div class="mt-1">
    <h3>{{ 'FOXDOX.DISK_SPACE' | translate }} </h3>
    <section class="bar-section">
      <mat-progress-bar mat-raised-button #tooltip="matTooltip"
                        [matTooltip]="foxdoxStatusData?.usedDiskSpacePercent"
                        matTooltipPosition="above"
                        class="progress-bar"
                        [value]="(foxdoxStatusData?.used/foxdoxStatusData?.disk_space)*100"
                        [bufferValue]="100">
      </mat-progress-bar>
      <label class="mobile">({{foxdoxStatusData?.diskSpaceInGB}} / {{foxdoxStatusData?.UsedDiskSpaceInGB}})</label>
      <label class="web">{{foxdoxStatusData?.diskSpaceInGB}}</label>
    </section>
    <label class="web">{{foxdoxStatusData?.UsedDiskSpaceInGB}}&nbsp;{{ 'FOXDOX.USED' | translate }} </label>
  </div>
</div>

<div *ngIf="foxdoxStatusData?.inValid">
  <div class="mt-2">
    <ng-container *ngIf="foxdoxStatusData?.errors">
      <div class="card-content-hint card-content-bottom-margin" *ngFor="let error of foxdoxStatusData?.errors">
        <mat-icon class="align-middle">warning</mat-icon>&nbsp;&nbsp;
        <span class="align-middle">{{ error.title | translate }}</span>
        <div *ngIf="error?.status?.toString() === '401'">
          <dvtx-foxdox-connect
            (onSubmit)="submitFoxdoxCredentials($event)" [result]="result">
          </dvtx-foxdox-connect>
        </div>
      </div>

    </ng-container>
  </div>
</div>
<div *ngIf="!foxdoxStatusData">
  <mat-spinner [diameter]="20"></mat-spinner>
</div>
