import {Injectable, ComponentFactoryResolver, Injector, OnDestroy} from '@angular/core';
import {FivefAvatarComponent} from './fivef-avatar/fivef-avatar.component';
import {IFivefAvatarProfile} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.interface';
import {Subject, Observable, of} from 'rxjs';
import {ContactSelectors, EmailToNameSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {takeUntil, startWith} from 'rxjs/operators';
import {AccountStatus} from 'app/+store/contact/contact.selectors';
import {TranslateService} from '@ngx-translate/core';
import {FivefAvatarShapeComponent} from './fivef-avatar-shape/fivef-avatar-shape.component';
import {UserAvatar} from 'app/+store/user-avatar/user-avatar';

@Injectable()
export class FivefAvatarService implements OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  organizationMembers$ = new Subject<any>();

  organizationMembersMap = {};

  emailToName$ = new Subject<any>();

  accountStatus = AccountStatus;

  systemAccount = false;

  lang = 'de';

  emailToNameMap = {};

  constructor(private resolver: ComponentFactoryResolver,
              private injector: Injector,
              private _i18nSvc: TranslateService,
              private store: Store<AppState>) {

    this.store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg())
    .pipe(takeUntil(this.onDestroy)).subscribe(members => {
      members.forEach(member => {
        if (!members || members.length == 0) {
          this.organizationMembersMap = {};
          this.organizationMembers$.next(null);
          return;
        }
        this.organizationMembersMap[member.email] = member;
      })
      this.organizationMembers$.next(this.organizationMembersMap);
    });

    this.store.select(EmailToNameSelectors.getAllEmailToName)
    .pipe(takeUntil(this.onDestroy)).subscribe((emailToName) => {
      if (!emailToName || emailToName.length == 0) {
        this.emailToNameMap = {};
        this.emailToName$.next(null);
        return;
      }
      emailToName.forEach(person => {
        this.emailToNameMap[person.email ? person.email : person.id] = person;
      })
      this.emailToName$.next(this.emailToNameMap)
      }
    );

    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang)
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Get an Avatar in native HTML content.
   *
   * @param {IFivefAvatarProfile} profile The user profile object.
   * @param {string} size of the avatar.
   * @param {Function} [options] callbackFn function for clicking on the avatar.
   * @param {boolean} [optional] showTitle show user name next to the avatar
   *
   *
   * @memberOf FivefAvatarService
   */
  get(profile: IFivefAvatarProfile, size: string, callbackFn?: any, showTitle?: boolean) {
    const factory = this.resolver.resolveComponentFactory(FivefAvatarComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.email = profile.email;
    componentRef.instance.size = size;
    componentRef.instance.callbackFn = callbackFn;
    componentRef.instance.showTitle = showTitle;
    componentRef.hostView.detectChanges();
    const {nativeElement} = componentRef.location;
    return componentRef.location.nativeElement;
  }

  getWithImage(profile: IFivefAvatarProfile, size: string, userAvatar: UserAvatar) {
    const factory = this.resolver.resolveComponentFactory(FivefAvatarShapeComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.profile = this.organizationMembersMap[profile.email] ? this.organizationMembersMap[profile.email] : this.emailToNameMap[profile.email];
    componentRef.instance.size = size;
    componentRef.instance.userAvatar = userAvatar;
    componentRef.hostView.detectChanges();
    const {nativeElement} = componentRef.location;
    return componentRef.location.nativeElement;
  }

  getProfile(account) {
    let email = '';
    if (account && typeof (account) === 'object') {
      email = account.honorific ? account.honorific : account.email;
    } else {
      email = account;
    }
    if (this._isSystemEmail(email)) {
      return {
        email: email,
        name: this.lang === 'de' ? 'Systemnachricht' : 'System Message',
        system: true
      }
    }
    const contact = this.organizationMembersMap[email];
    if (contact) {
      contact.name = contact.name ? contact.firstName + ' ' + contact.lastName : email;
      return contact;
    }


    const emailToName = this.emailToNameMap[email];
    if (emailToName) {
      if (emailToName.firstName && emailToName.lastName) {
        emailToName.name = emailToName.firstName + ' ' + emailToName.lastName
      }
      return emailToName;
    }

    return {
      email: email,
      name: email
    }
  }

  getProfileForAvatar$(account): Observable<any> {
    let email = '';
    let id = '';
    let name = '';
    if (account && typeof (account) === 'object') {
      email = account.honorific ? account.honorific : account.email;
      id = account.id;
      name = account.name;
    } else {
      email = account;
      id = account;
      name = account;
    }
    if (this._isSystemEmail(email)) {
      return of({
        email: email,
        name: this.lang === 'de' ? 'Systemnachricht' : 'System Message',
        system: true
      })
    }
    return of(this.initializeContact(this.organizationMembersMap, this.emailToNameMap, email, id , name));
  }

    public initializeContact(contacts, emailToNames, email, id , name) {
      if (contacts) {
        const contact = contacts[email];
        if (contact) {
          const _contact = Object.assign({}, contact);
          _contact.name = contact.name ? contact.firstName + ' ' + contact.lastName : email;
          return _contact;
        }
      }
      if (emailToNames) {
        const emailToName = emailToNames[email];
        if (emailToName) {
          emailToName.email = email;
          if (emailToName.firstName && emailToName.lastName) {
            emailToName.name =  emailToName.firstName + ' ' + emailToName.lastName;
          }
          return emailToName;
        }
      }
      return {
        id: id,
        email: email,
        name: name ? name : email
      }
    }

  /**
   * Adds firstName and lastName property on a given profile
   * by splitting the name attribute.
   *
   * @param name
   * @param accountProfile
   */
  updateAccountProfileFromName(name, accountProfile) {
    if (name && typeof(name) === 'string') {
      const profileName = name.split(' ');
      if (profileName.length > 1) {
        accountProfile['firstName'] = profileName[0];
        accountProfile['lastName'] = profileName.slice(1).join(' ').trim();
      }
    }
  }

  private _isSystemEmail(email: string) {
    if (!email) return false;

    switch (email) {
      case 'system@5fsoftware.de':
      case 'support@5fsoftware.de':
      case 'quickshare@5fsoftware.de':
      case 'third-party@5fsoftware.de':
      case 'bookman@5fsoftware.de':
      case 'signature@5fsoftware.de':
      case 'fastdocs@5fsoftware.de':
      case 'service@fastdocs.de':
        this.systemAccount = true;
        return true;
      default:
        return false;
    }
  }

}
