import {Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Task} from 'app/+store/task/task';
import {AngularTokenService} from 'angular-token';

@Component({
  selector: 'dvtx-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTaskDialogComponent {
  public task: Task = new Task(null, null);

  constructor(public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cdr: ChangeDetectorRef,
              tokenSvc: AngularTokenService) {
    if (!!data.task) {
      this.task = data.task;
    }

    if (data.processId) {
      this.task.process = {id: data.processId};
    }

    // Ensure a valid responsible.
    // For new tasks the creator is always the initial responsible.
    if (!this.task?.responsible?.email) {
      this.task.responsible = {email: tokenSvc.currentAuthData.uid}
    }
  }

  public updateTask(task: Task) {
    this.task = task;
    this.cdr.detectChanges();
  }

  public createTask() {
    this.data.onSaveChanges(this.task);
  }
}
