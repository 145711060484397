import {Inject, Injectable, InjectionToken} from '@angular/core';
import {environment} from 'environments/environment';
import {ApiErrorBuilder} from '../../+store/_legacy/api/builders/api-error.builder';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Observable} from 'rxjs/internal/Observable';
import {download, Download} from '../process-artifact/file-api-resource.service';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {throwError as observableThrowError} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';

export type Saver = (blob: Blob, filename?: string) => void

export class DownloadItem {
  constructor(public id: string, public title: string, public ownerName, public url, public uploadedAt: Date) {
  }
}

export const SAVER = new InjectionToken<Saver>('saver')

export function getSaver(): Saver {
  return saveAs;
}

@Injectable()
export class FileInboxService {
  readonly BASE_PATH = 'api/v1/file_inbox';
  basePath;

  constructor(private _http: HttpClient,
              private env: EnvService,
              private notifyService: FivefNotificationService,
              @Inject(SAVER) private save: Saver) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  getDocuments(token) {
    return this._http.get(`${this.basePath}/${token}/public_downloads`).pipe(
      map((res: any) => res.data),
      catchError(this.handleError),);
  }

  validateToken(token: String) {
    return this._http.get(`${this.basePath}/${token}`).pipe(
      map((res: any) => res.data.attributes),
      catchError(this.handleError));
  }

  _download(token, filename, itemId) {
    return this._http.get( `${this.basePath}/${token}/download/${itemId}`, {
      responseType: 'blob'
    }).pipe(first())
      .subscribe((blob) => {
        saveAs(blob, filename);
      }, err => {
        this.notifyService.error('HTTP_ERROR.TRY_AGAIN')
        console.error(err);
      });
  }

  public download(token, filename, itemId): Observable<Download> {
    return this.getBlob( `${this.env.tusServer()}/${this.BASE_PATH}/${token}/download/${itemId}`, filename);
  }

  private getBlob(url: string, filename: string): Observable<Download> {
    console.error(url);
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    const headers = new HttpHeaders(headersConfig)

    return this._http.get(url, {
      reportProgress: true,
      responseType: 'blob',
      observe: 'events',
      headers: headers,
    }).pipe(download(blob => this.save(blob, filename)))
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    console.error(error);
    return observableThrowError(errors);
  }
}
