import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {DmsFolderAclBuilder, RemovedParticipationBuilder} from './dms-folder-acl.builder';
import {DmsFolderAcl, RemovedParticipation} from './dms-folder-acl';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

@Injectable()
export class DmsFolderAclService {
  readonly BASE_PATH = 'api/v1/folders';

  constructor(private _http: FivefApiResourceService) {}

  getAll(id: string, accountType: DmsAccountType): Observable<DmsFolderAcl[]> {
    const builder = new DmsFolderAclBuilder(id, accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolderAcl[]>>this._http.get<DmsFolderAclBuilder, DmsFolderAcl>(builder, `${this.BASE_PATH}/${id}/acl?account_type=${param}`);
  }

  save(id: string, accountType: DmsAccountType, payload): Observable<DmsFolderAcl[]> {
    const builder = new DmsFolderAclBuilder(id, accountType);
    const param = accountType === DmsAccountType.Private ? 'private' : 'organization';
    return <Observable<DmsFolderAcl[]>>this._http.putAll<DmsFolderAclBuilder, DmsFolderAcl>(builder, `${this.BASE_PATH}/${id}/acl?account_type=${param}`, payload);
  }

  removeParticipationBySid(dmsFolderId: string, sid: string): Observable<RemovedParticipation> {
    const builder = new RemovedParticipationBuilder();
    const payload = {
      data: {
        attributes: {
          sid: sid
        }
      }
    };
    return <Observable<RemovedParticipation>>this._http.post<RemovedParticipationBuilder, RemovedParticipation>(builder, `${this.BASE_PATH}/${dmsFolderId}/close_by_dms_folder_and_sid`, payload);
  }
}
