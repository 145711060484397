import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {FivefControlValueAccessor} from '../../../input/fivef-control-value-accessor.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactVisibility, visibilityTypes} from '../../../../../+store/contact/contact';

@Component({
  selector: 'fivef-contact-visibility-select',
  templateUrl: './fivef-contact-visibility-select.component.html',
  styleUrls: ['./fivef-contact-visibility-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefContactVisibilitySelectComponent),
      multi: true,
    }
  ]
})
export class FivefContactVisibilitySelectComponent extends FivefControlValueAccessor implements OnInit {

  visibilities = [
    {
      label: 'CONTACTS.PRIVATE',
      value: visibilityTypes.visiblePrivate,
      icon: 'lock',
      tooltip_active: 'CONTACTS.TYPE_PRIVATE_TOOLTIP_ACTIVE',
      tooltip_inactive: 'CONTACTS.TYPE_PRIVATE_TOOLTIP_INACTIVE'
    },
    {
      label: 'CONTACTS.PUBLIC',
      value: visibilityTypes.visiblePublic,
      icon: 'visibility',
      tooltip_active: 'CONTACTS.TYPE_PUBLIC_TOOLTIP_ACTIVE',
      tooltip_inactive: 'CONTACTS.TYPE_PUBLIC_TOOLTIP_INACTIVE'
    },
    // { label: 'Global', value:  'published', icon: 'business', tooltip: 'Der Kontakt ist für Ihre Geschäftspartner sichtbar.' }
  ];

  contactVisibility = visibilityTypes.visiblePublic;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(obj: ContactVisibility): void {
    if (obj) {
      this.contactVisibility = obj.type;
    }
  }

  notify() {
    const notObj: ContactVisibility = {
      type: this.contactVisibility,
      visibleFor: ''
    };

    this.notifyOnChange(notObj);
    this.notifyOnTouch();
  }

}
