import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {stateKey} from "./cache-froala.selectors";
import {reducer} from "./cache-froala.reducer";


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer)
  ]
})
export class CacheFroalaModule {
}
