import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FivefAvatarModule} from '../../profile/fivef-avatar/fivef-avatar.module';
import {FivefItemSelectorRepository} from './fivef-item-selector.repository';
import {Observable} from 'rxjs/internal/Observable';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';
import {FivefSearchComponent} from '../fivef-search/fivef-search.component';
import {IFivefItemSelectorItem, IFivefSelectorSelectionKey} from './fivef-item-selector.interface';
import {takeUntil} from 'rxjs/operators';
import {FivefIconComponent} from '../../common/fivef-icon/fivef-icon.component';
import {FivefAvatarGroupComponent} from '../../profile/fivef-avatar-group/fivef-avatar-group.component';

@Component({
  selector: 'fivef-item-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    CdkOverlayOrigin,
    TranslateModule,
    CdkConnectedOverlay,
    MatInputModule,
    CdkFixedSizeVirtualScroll,
    FivefAvatarModule,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    ScrollingModule,
    FivefSearchComponent,
    FivefIconComponent,
    FivefAvatarGroupComponent
  ],
  providers: [
    FivefItemSelectorRepository
  ],
  host: {class: 'fivef-item-selector'},
  templateUrl: './fivef-item-selector.component.html',
  styleUrls: ['./fivef-item-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefItemSelectorComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @ViewChild('dropdown')
  public dropdown: CdkOverlayOrigin;

  public searchValue: string;

  public filteredItems$: Observable<IFivefItemSelectorItem[]>;
  /**
   * Selected current items. Kept as property and not as stream because of CDR issues
   * on the host element.
   */
  public selectedItems: any[] = [];

  /**
   * Reference property between selected objects and the candidate collection.
   * Default: ID.
   *
   * Example: Client contacts are referenced by client-contact relation.
   * In this context the selected client-contact objects are referencing by contactId (contact)
   * or clientId (client entity).
   *
   * In workflow draft contexts the relation could be the `email` if it is used as primary key.
   *
   * Hint: Prefer id reference as easy understandable default model.
   */
  public _resourceSelectorKey: IFivefSelectorSelectionKey = 'id';

  /**
   * Toggle to keep the dropdown open or closed.
   */
  public showDropdown = false;

  /**
   * Size of avatars of list items.
   */
  @Input()
  public avatarSize = 'xs';

  /**
   * Alignment of the button and selected items.
   */
  @Input()
  alignment: 'flex-start' | 'center' | 'flex-end' = 'flex-start';

  /**
   * Direction of button and selected item.
   * On tables use reverse order.
   */
  @Input()
  direction: 'row' | 'row-reverse' = 'row';

  @Input()
  listItemTemplate: 'contact' | 'client' = 'contact';

  /**
   * Button styling: invertedColors converts regular buttons into rounded
   * buttons by inverting the colors on a circle background.
   * Preferred style for table usage e.g. at tasks.
   *
   * Note: Material already contains a few circle items: e.g. add_circle or
   * remove_circle_outline being appropriate without this setting.
   *
   * The according styling class shrinks the icon to be able to place it
   * inside a circle (see _button.scss mixin at 5F library).
   */
  @Input()
  buttonStyle: 'default' | 'invertedColors' = 'default';

  /**
   * Maximum of elements to be shown beside opener.
   */
  @Input()
  public maxToShow = 1;

  @Input()
  public disabled = false;

  @Input()
  public tooltip: string = null;

  @Input()
  public icon = 'add_circle';

  @Input()
  appearance: 'avatar' | 'default' | 'buttonOnly' = 'default';

  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onDeselect: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onAction: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectionTitle: string;

  @Input()
  collectionTitle: string;

  /**
   * Disables the removal capabilities to ensure an item.
   */
  @Input()
  disableRemoveAction = false;

  /**
   * Actions to be added at the top of the listing.
   * See IFivefItemSelectorItem for reference.
   *
   * Example: Group responsible removal.
   *   ...
   *   [actions]="[{id: 'TASK.REMOVE_RESPONSIBLE', type: 'action', icon: 'remove_circle_outline'}]"
   *   (onAction)="updateResponsible(category, null)"
   *   ...
   * @param actions
   */
  @Input()
  set actions(actions: IFivefItemSelectorItem[]) {
    this.repo.actions = actions;
  }

  @Input()
  set collection(resources: any[]) {
    if (resources && resources.length) {
      this.repo.data = resources;
    }
  };

  @Input()
  set resourceSelectorKey(rk: IFivefSelectorSelectionKey) {
    this.repo.resourceSelectorKey = rk;
    this._resourceSelectorKey = rk;
  }

  @Input()
  set selected(selected) {
    // console.error(selected)
    if (Array.isArray(selected)) {
      const items = selected?.filter(item => !!item);
      this.repo.selected = items;
    } else {
      this.repo.selected = !!selected ? [selected] : [];
    }
  }

  constructor(private repo: FivefItemSelectorRepository,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.filteredItems$ = this.repo.filteredData;
    this.repo.selectedData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(items => {
        this.selectedItems = items;
        this.cdr.detectChanges();
      });

    if (this.collectionTitle) {
      this.repo.collectionTitle = this.collectionTitle;
    }

    if (this.selectionTitle) {
      this.repo.selectionTitle = this.selectionTitle;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public selectItem(item, $event: any = null) {
    if (this.disabled || !item || !item.id) {
      return;
    }

    if ($event) {
      $event.stopPropagation();
    }

    this.onSelect.emit(item);
  }

  public deselect(item, $event: any = null) {
    if (this.disabled || !item || !item.id) {
      return;
    }

    if ($event) {
      $event.stopPropagation();
    }

    this.onDeselect.emit(item);
  }

  public search(term) {
    this.repo.search(term);
  }

  public openSelector() {
    if (this.disabled) {
      return;
    }

    this.search('');
    this.showDropdown = true;
    this.cdr.detectChanges();
  }
}
