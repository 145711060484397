
import {first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ProcessArtifact} from './process-artifact';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {AngularTokenService} from 'angular-token';

@Injectable()
export class ProcessArtifactDownloadManagerService {
  readonly RESOURCE_PATH = 'workflow_engine/processes';
  uploadApiPath;
  basePath;
  currentUser$;

  constructor(private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService,
              private _tokenSvc: AngularTokenService) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
    this.uploadApiPath = `${this.basePath}/${this.RESOURCE_PATH}/`;
  }

  download(document: ProcessArtifact, path: string = null) {
    if (path || (document && document.externalUrl)) {
      const _path = path ? path : document.externalUrl;
      const _uid = this._tokenSvc.currentAuthData.uid;
      const uid = encodeURIComponent(_uid);
      const accessToken = this._tokenSvc.currentAuthData.accessToken;
      const client = this._tokenSvc.currentAuthData.client;
      const accessParams = `?access_token=${accessToken}&client=${client}&uid=${uid}`;

      if (accessParams) {
        const myRequest = new Request(`${_path}${accessParams}`);
        fetch(myRequest).then( (response) => {
          if (response.status === 200) {
            window.location.assign(`${response.url}`);
          } else {
            this._notifyService.error('HTTP_ERROR.DEFAULT');
          }
        });
      }
    }
  }
}
