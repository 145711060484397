import {throwError as observableThrowError} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, Input} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  bindFormAndAttributes,
  BindFormAndAttributesAsArray
} from '../../../../../../../../../third-party-confirmation/containers/third-party-request-confirmation/third-party-request-confirmation.component';
import {AuditContactImportsService} from 'app/+store/audit-order/audit-contact-imports.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Subscription} from 'rxjs';
import {AuditContactVerification, AuditContactVerificationRow} from 'app/+store/_legacy/api/models/audit-contact-verification';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {first} from 'rxjs/operators';

@Component({
  selector: 'dvtx-subarea-verifier-request-confirm',
  templateUrl: './subarea-verifier-request-confirm.component.html',
  styleUrls: ['./subarea-verifier-request-confirm.component.scss']
})
export class SubareaVerifierRequestConfirmComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @ViewChild('contactDialogTpl', {static: true})
  private contactDialogTpl: TemplateRef<any>;
  private contactDialogRef: MatDialogRef<any>;

  @ViewChild('removalDialogTpl', {static: true})
  private removalDialogTpl: TemplateRef<any>;
  removalDialogRef: MatDialogRef<any>;

  // New entry form and current values to be used for new entries
  newComponentAuditors = [];
  currentOrganization: string;
  currentIdentifier: string;
  currentAbbreviation: string;
  currentCountryCode: string;
  currentNotificationLanguage: string;
  globalComment: string;

  public data: AuditContactVerificationVM;
  private unsubscribeBinding: Subscription;
  public auditorForm: BindFormAndAttributesAsArray;
  public form: UntypedFormGroup;
  public creationForm: UntypedFormGroup;
  public showThankYou = false;
  submitOngoing = false;

  @Input() readOnly = false;

  isCommunication = false;
  isAuditing = false;

  // Removal form IDx for the removal confirmation dialog.
  removeEntryIdx = null;

  showLoginLink = false;
  showDashboardLink = false;

  constructor(private router: Router,
              private _fb: UntypedFormBuilder,
              private _store: Store<AppState>,
              private auditContactImportsService: AuditContactImportsService,
              private activatedRoute: ActivatedRoute,
              private _dialog: MatDialog) {
  }

  ngOnInit() {
    this.auditContactImportsService.getVerification(this.activatedRoute.snapshot.params['token']).pipe(
      takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data.profile) {
          switch (data.profile) {
            case ProcessProfile.StandardProfile:
              this.isCommunication = true;
              break;
            case ProcessProfile.AuditingProfile:
              this.isAuditing = true;
              break;
          }
        }

        this.currentOrganization = data.rows[0].organizationName;
        this.currentIdentifier = data.rows[0].identifier;
        this.currentAbbreviation = data.rows[0].abbreviation;
        this.currentCountryCode = data.rows[0].countryCode;
        this.currentNotificationLanguage = data.rows[0].notificationLanguage || 'en';
        this.globalComment = data.rows[0].global_comment;

        this.data = {
          ...data,
          rows: data.rows.map((entry) => {
            return {...entry, reviewer_confirmed: true}
          }),
          toForm: () => undefined,
          global_comment: data.rows[0].global_comment,
          reviewer_first_name: data.rows[0].reviewer_first_name,
          reviewer_last_name: data.rows[0].reviewer_last_name,
          reviewer_job_title: data.rows[0].reviewer_job_title,
          reviewer_organization_name: data.rows[0].reviewer_organization_name,
          reviewer_city: data.rows[0].reviewer_city,
          reviewer_country: data.rows[0].reviewer_country,
          reviewed_at: data.rows[0].reviewed_at
        };

        [this.form, this.unsubscribeBinding] = bindFormAndAttributes(
          this._fb,
          [
            'reviewer_comment',
            'reviewer_first_name',
            'reviewer_last_name',
            'reviewer_job_title',
            'reviewer_organization_name',
            'reviewer_city',
            'reviewer_country',
            'reviewed_at',
          ],
          this.data,
          {
            'reviewer_first_name': Validators.required,
            'reviewer_last_name': Validators.required
          }
        );

        const reviewedAt = this.form.get('reviewed_at');
        if (!reviewedAt.value) {
          reviewedAt.setValue(new Date());
        }

        reviewedAt.disable();

        this.auditorForm = new BindFormAndAttributesAsArray(
          this._fb,
          'rows',
          'auditors',
          [
            'firstName',
            'lastName',
            'role',
            'email',
            'mobile',
            'phone',
            'comment',
            'reviewer_confirmed'
          ],
          this.data as any
        );
      }, (error) => {
        if (error.status && (error.status === 401 || error.status === 404)) {
          console.error('ERROR', 'Unauthorized or not found');
          this.router.navigate(['access/page_not_found']);
        } else {
          return observableThrowError(error);
        }
      });
  }

  public ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.unsubscribeBinding) {
      this.unsubscribeBinding.unsubscribe();
    }

    if (this.auditorForm) {
      this.auditorForm.unsubscribe();
    }
  }

  openCreationDialog() {
    this.creationForm = this._initCreationForm(this.data);
    this.contactDialogRef = this._dialog.open(this.contactDialogTpl);
  }

  saveEntry() {
    this.newComponentAuditors.push(this.creationForm.value);
    this.contactDialogRef.close();
  }

  removeEntry(idx) {
    if (idx > -1 && idx < this.newComponentAuditors.length) {
      this.newComponentAuditors.splice(idx, 1);
    }
  }

  closeDialog() {
    this.contactDialogRef.close();
  }

  public verifyData() {
    const reviewedNow = new Date();
    for (const row of this.data.rows) {
      row.global_comment = this.data.global_comment;
      row.reviewer_first_name = this.data.reviewer_first_name;
      row.reviewer_last_name = this.data.reviewer_last_name;
      row.reviewer_job_title = this.data.reviewer_job_title;
      row.reviewer_organization_name = this.data.reviewer_organization_name;
      row.reviewer_city = this.data.reviewer_city;
      row.reviewer_country = this.data.reviewer_country;
      row.reviewed_at = this.data.reviewed_at;
    }
    this.submitOngoing = true;
    return this.auditContactImportsService.postVerification(
      this.activatedRoute.snapshot.params['token'],
      this.data,
      this.newComponentAuditors
    ).pipe(
      first())
      .subscribe(res => {
        this.showThankYou = true;

        this._store.select('currentUser')
          .pipe(first())
          .subscribe(user => {
            if (!user) {
              if (res.redirectUrl && res.redirectUrl.type === 'registration' && res.redirectUrl.url) {
                window.location.href = res.redirectUrl.url;
              } else if (res.redirectUrl && res.redirectUrl.type === 'sign_in' && res.redirectUrl.url) {
                this.showLoginLink = true;
              }
            } else {
              this.showDashboardLink = true;
            }
          })
      }, err => {
        console.error(err);
      });
  }

  private _initCreationForm(data) {
    return this._fb.group({
      id: null,
      identifier: [this.currentIdentifier],
      organization_name: [this.currentOrganization],
      abbreviation: [this.currentAbbreviation],
      country_code: [this.currentCountryCode],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      role: ['staff'],
      email: ['', Validators.required],
      country_phone_code: [''],
      phone: [''],
      country_mobile_code: [''],
      mobile: [''],
      verified_at: [false],
      comment: [''],
      notification_language: 'en' // [this.currentNotificationLanguage],
    })
  }

  closeRemovalDialog() {
    this.removalDialogRef.close();
  }

  public navigateToDashboard() {
    this.router.navigate(['dashboard']);
  }

  public navigateToLogin() {
    this.router.navigate(['session', 'sign-in']);
  }

  public confirmRemoval() {
    if (!this.auditorForm) return;

    this.auditorForm.deleteRow(this.removeEntryIdx);
    this.removeEntryIdx = null;
    this.closeRemovalDialog();
  }

  openRemovalDialog(formIdx) {
    this.removeEntryIdx = formIdx;
    this.removalDialogRef = this._dialog.open(this.removalDialogTpl);
  }
}

interface AuditContactVerificationVM extends AuditContactVerification {
  rows: AuditContactVerificationRowVM[];
}

interface AuditContactVerificationRowVM extends AuditContactVerificationRow {
  reviewer_confirmed: boolean;
}
