import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Project} from './project';
import {ProjectBuilder} from './project.builder';
import {IProjectParams} from './project.interface';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {IProjectTemplateParams} from '../workflow-template/workflow-template';
import {
  WorkflowNode,
  WorkflowNodeBuilder
} from 'app/modules/templates-management/modules/workflow-templates/components/template-workflows-structure/workflow-node';

@Injectable()
export class ProjectService {
  readonly BASE_PATH = 'api/v1/projects';

  constructor(private _http: FivefApiResourceService) {
  }

  create(params: IProjectParams): Observable<Project> {
    const builder = new ProjectBuilder();
    const payload = {data: {attributes: {title: params.title}}};

    if (params.profile) {
      payload['data']['attributes']['profile'] = params.profile
    }

    let queryParam = '';
    if (params.parentId) {
      queryParam = `?parent=${params.parentId}`;
    }
    return <Observable<Project>>this._http.post<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes${queryParam}`, payload);
  }

  createUsingTemplate(id: string, params: IProjectTemplateParams): Observable<Project> {
    const builder = new ProjectBuilder();
    const payload = {
      data: {
        attributes: {
          template_data: {
            title: params.title,
            description: params.description,
            color: params.color,
            dms_folder_id: params.dmsFolderId,
            dms_account_Type: params.dmsAccountType,
            due_date: params.dueDate,
            client_id: params.clientId,
            permit_upload: params.permitUpload,
            permit_comments: params.permitComments,
            children: WorkflowNode.toApiJson(params.children),
          }
        }
      }
    };

    return <Observable<Project>>this._http.post<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes/${id}/json_imports`, payload);
  }

  /**
   * Returns the project structure as tree.
   *
   * If `includeSettings` is set to true, then the process settings are included inside the response as
   * additional properties `permit_comments`, `permit_upload` and `column_setup` (Collecto).
   *
   * @param id
   * @param includeSettings
   */
  getProjectStructure(id: string, includeSettings: boolean = false): Observable<WorkflowNode> {
    const builder = new WorkflowNodeBuilder();
    return <Observable<WorkflowNode>>this._http.get<WorkflowNodeBuilder, WorkflowNode>(builder, `${this.BASE_PATH}/processes/${id}/project_structure?include_settings=${includeSettings}`);
  }

  publish(id: string): Observable<Project> {
    const builder = new ProjectBuilder();
    return <Observable<Project>>this._http.post<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes/${id}/publish`, {});
  }

  canInheritFolder(id: string): Observable<Project> {
    const builder = new ProjectBuilder();
    return <Observable<Project>>this._http.get<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes/${id}/can_inherit_folder`);
  }

  saveDraft(id: string, params: IProjectParams): Observable<Project> {
    const builder = new ProjectBuilder();
    const payload = {
      data: {
        attributes: {
          title: params.title,
          identifier: params.identifier,
          reference_id: params.referenceId,
          description: params.description,
          due_date: params.dueDate,
          dms_folder_id: params.dmsFolderId,
          client_uuid: params.clientId,
          dms_account_type: params.dmsAccountType === DmsAccountType.Organization ? 'organization' : (params.dmsAccountType === DmsAccountType.Private ? 'private' : null),
          color: params.color
        }
      }
    };
    return <Observable<Project>>this._http.put<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/drafts/${id}`, payload);
  }

  getAll(): Observable<Project[]> {
    const builder = new ProjectBuilder();
    return <Observable<Project[]>>this._http.get<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes`);
  }

  getOne(id: string): Observable<Project> {
    const builder = new ProjectBuilder();
    return <Observable<Project>>this._http.get<ProjectBuilder, Project>(builder, `${this.BASE_PATH}/processes/${id}`);
  }
}
