import {IFavorite} from './favorite.interface';
import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {Process} from '../process/process';

export class Favorite implements IFavorite {
  readonly type = 'favorites';
  color: string = '#fff';

  constructor(public id,
              public title: string,
              public resourceId: string,
              public resourceType: string,
              public confirmedAt: Date = null) {}
}


/**
 * v3 minimal favorite API model.
 *
 * === Example
 *
 * {
 * 			"id": "2c124927-00db-4ef6-a46f-f061ff7ebeff",
 * 			"type": "favorites",
 * 			"attributes": {
 * 				"type": "project",
 * 				"title": "Trashable project",
 * 				"color": "#00c875",
 * 				"status": "closed",
 * 				"client_id": "10002",
 * 				"client_name": "Mayer AG",
 * 				"updated_at": "2023-08-14T16:18:10.571+02:00",
 * 				"created_at": "2023-08-14T16:18:10.571+02:00"
 * 			}
 * 		},
 */
export class FavoriteV2 implements IResource {
  public readonly type = 'favorites';
  public color: string = '#fff';
  public workflow: string;
  public stateIcon: string;
  public stateMessage: string;

  constructor(public id,
              public title: string,
              color: string,
              public processType: string,
              public status: string,
              public clientNo: string,
              public clientName: string,
              public updatedAt: Date,
              public createdAt: Date) {
    if (color) {
      this.color = color;
    }
    this.workflow = Process.humanProcessTypeFromString(processType);
    this.stateIcon = Process.iconOfState(status);
    this.stateMessage = Process.humanProcessState(status);
  }
}
