import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {
  TwoFactorVerificationModule
} from '../../modules/two-factor-auth/modules/two-factor-verification/two-factor-verification.module';
import {FivefPageSidebarModule} from '../../lib/fivef-ui/page/fivef-page-sidebar/fivef-page-sidebar.module';
import {MainAppComponent} from './containers/main-app/main-app.component';
import {MainExternalComponent} from './containers/main-external/main-external.component';
import {MainSessionComponent} from './containers/main-session/main-session.component';
import {QuickstartModule} from '../quickstart/quickstart.module';
import {
  FivefSidebarToggleComponent
} from '../../lib/fivef-ui/navigation/fivef-sidebar-toggle/fivef-sidebar-toggle.component';
import {
  FivefLoadingIndicatorComponent
} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefPageHeaderModule} from '../../lib/fivef-ui/page/fivef-page-header/fivef-page-header.module';
import {FivefIconMessageBlockComponent} from '../../lib/fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefActioncableComponent} from '../../lib/fivef-ui/activity/fivef-actioncable/fivef-actioncable.component';
import {FivefInfoBlockComponent} from '../../lib/fivef-ui/util/fivef-info-block/fivef-info-block.component';


@NgModule({
  declarations: [
    MainAppComponent,
    MainExternalComponent,
    MainSessionComponent
  ],
  imports: [
    SharedModule,
    FivefPageHeaderModule,
    FivefPageSidebarModule,
    TwoFactorVerificationModule,
    QuickstartModule,
    FivefInfoBlockComponent,
    FivefSidebarToggleComponent,
    FivefLoadingIndicatorComponent,
    FivefPageHeaderModule,
    FivefIconMessageBlockComponent,
    FivefActioncableComponent
  ],
  exports: [
    MainAppComponent,
    MainExternalComponent,
    MainSessionComponent
  ]
})
export class MainAppModule {
}
