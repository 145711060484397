import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmitSuccessComponent} from './components/submit-success/submit-success.component';
import {FivefAccessTokenNotFoundComponent} from '../../lib/fivef-ui/page/fivef-access-token-not-found/fivef-access-token-not-found.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ExternalAccessService} from 'app/+store/_legacy/api/services/external-access.service';
import {QuickshareComponent} from './components/quickshare/quickshare.component';
import {QuickshareContainerComponent} from './containers/quickshare-container/quickshare-container.component';
import {QuickshareService} from './services/quickshare.service';
import {PasswordDialogComponent} from './containers/password-dialog/password-dialog.component';
import {FivefUploadComponent} from '../../lib/fivef-ui/upload/fivef-upload/fivef-upload.component';
import {MatButtonModule} from '@angular/material/button';
import {FivefLoadingIndicatorComponent} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {FivefIconMessageBlockComponent} from '../../lib/fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefDialogComponent} from '../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefFileButtonComponent} from '../../lib/fivef-ui/file/fivef-file-button/fivef-file-button.component';
import {FivefRichTextComponent} from '../../lib/fivef-ui/input/fivef-rich-text/fivef-rich-text.component';
import {FivefInfoBlockComponent} from '../../lib/fivef-ui/util/fivef-info-block/fivef-info-block.component';
import {BaseFormElementsModule} from '../../lib/fivef-legacy/base-form-elements/base-form-elements.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FivefUploadComponent,
    FivefAccessTokenNotFoundComponent,
    MatButtonModule,
    FivefLoadingIndicatorComponent,
    TranslateModule,
    MatInputModule,
    MatIconModule,
    FivefIconMessageBlockComponent,
    FivefInfoBlockComponent,
    FivefDialogComponent,
    MatDialogModule,
    MatTooltipModule,
    FivefFileButtonComponent,
    FivefRichTextComponent,
    BaseFormElementsModule
  ],
  declarations: [
    QuickshareComponent,
    QuickshareContainerComponent,
    SubmitSuccessComponent,
    PasswordDialogComponent
  ],
  providers: [
    ExternalAccessService,
    QuickshareService
  ]
})
export class ExternalAccessModule {
}
