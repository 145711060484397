import {Action} from '@ngrx/store';
import {CollectorItem} from './collector-item';
import {CollectorCategory} from '../collector-category/collector-category';
import {ItemStatus} from 'app/+store/collector/collector.interface';
import {IBomDataNodeParams} from '../bom/bom-data-node.interface';

export enum CollectorItemActionTypes {
  Create = '[CollectorItem] Create',
  CreateSuccess = '[CollectorItem] Create Success',
  CreateFail = '[CollectorItem] Create Fail',
  Remove = '[CollectorItem] Remove',
  RemoveSuccess = '[CollectorItem] Remove Success',
  RemoveFail = '[CollectorItem] Remove Fail',
  Save = '[CollectorItem] Save',
  SaveSuccess = '[CollectorItem] Save Success',
  SaveFail = '[CollectorItem] Save Fail',
  Status = '[CollectorItem] Status',
  StatusSuccess = '[CollectorItem] Status Success',
  StatusFail = '[CollectorItem] Status Fail',
  LoadAllSuccess = '[CollectorItem] Load All Success',
  UpsertAllSuccess = '[CollectorItem] Upsert All Success',
  LoadOne = '[CollectorItem] Load One',
  LoadOneSuccess = '[CollectorItem] Load One Success',
  LoadOneFail = '[CollectorItem] Load One Fail',
  Select = '[CollectorItem] Select',
  Copy = '[CollectorItem] Copy',
  CopySuccess = '[CollectorItem] Copy Success',
  CopyFail = '[CollectorItem] Copy Fail',
  Reset = '[CollectorItem] Reset',
  UpdateByCategory = '[CollectorItem] Update by category',
  UpdateAllSuccess = '[CollectorItem] Update all items',
}

/**
 * Updates status or due date of items by category.
 */
export class UpdateByCategory implements Action {
  readonly type = CollectorItemActionTypes.UpdateByCategory;

  constructor(public collectorId: string, public category: CollectorCategory, public items: CollectorItem[], public attribute: 'due_date' | 'status' | 'archived_at') {
  }
}

export class Create implements Action {
  readonly type = CollectorItemActionTypes.Create;

  constructor(public collectorId: string, public categoryId: string, public params: IBomDataNodeParams) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CollectorItemActionTypes.CreateSuccess;

  constructor(public item: CollectorItem) {
  }
}

export class CreateFail implements Action {
  readonly type = CollectorItemActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = CollectorItemActionTypes.Remove;

  constructor(public collectorId: string, public categoryId: string, public id: string) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = CollectorItemActionTypes.RemoveSuccess;

  constructor(public item: CollectorItem) {
  }
}

export class RemoveFail implements Action {
  readonly type = CollectorItemActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Save implements Action {
  readonly type = CollectorItemActionTypes.Save;

  constructor(public collectorId: string, public categoryId: string, public id: string, public params: IBomDataNodeParams) {
  }
}

export class SaveSuccess implements Action {
  readonly type = CollectorItemActionTypes.SaveSuccess;

  constructor(public item: CollectorItem) {
  }
}

export class SaveFail implements Action {
  readonly type = CollectorItemActionTypes.SaveFail;

  constructor(public payload: any) {
  }
}

export class Status implements Action {
  readonly type = CollectorItemActionTypes.Status;

  constructor(public collectorId: string, public id: string, public status: ItemStatus) {
  }
}

export class StatusSuccess implements Action {
  readonly type = CollectorItemActionTypes.StatusSuccess;

  constructor(public item: CollectorItem) {
  }
}

export class StatusFail implements Action {
  readonly type = CollectorItemActionTypes.StatusFail;

  constructor(public payload: any) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CollectorItemActionTypes.LoadAllSuccess;

  constructor(public items: CollectorItem[]) {
  }
}

export class UpsertAllSuccess implements Action {
  readonly type = CollectorItemActionTypes.UpsertAllSuccess;

  constructor(public items: CollectorItem[]) {
  }
}

export class LoadOne implements Action {
  readonly type = CollectorItemActionTypes.LoadOne;

  constructor(public collectorId: string, public itemId) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CollectorItemActionTypes.LoadOneSuccess;

  constructor(public item: CollectorItem) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CollectorItemActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Select implements Action {
  readonly type = CollectorItemActionTypes.Select;

  constructor(public id: string) {
  }
}

export class Copy implements Action {
  readonly type = CollectorItemActionTypes.Copy;

  constructor(public processId: string, public categoryId: string, public id: string) {
  }
}

export class CopySuccess implements Action {
  readonly type = CollectorItemActionTypes.CopySuccess;

  constructor(public item: CollectorItem) {
  }
}

export class CopyFail implements Action {
  readonly type = CollectorItemActionTypes.CopyFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = CollectorItemActionTypes.Reset;

  constructor() {
  }
}

export class UpdateAllSuccess implements Action {
  readonly type = CollectorItemActionTypes.UpdateAllSuccess;

  constructor(public items: CollectorItem[]) {
  }
}

export type CollectorItemActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Save
  | SaveSuccess
  | SaveFail
  | Status
  | StatusSuccess
  | StatusFail
  | LoadAllSuccess
  | UpsertAllSuccess
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Select
  | Copy
  | CopySuccess
  | CopyFail
  | Reset
  | UpdateAllSuccess
  | UpdateByCategory;
