import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnDestroy, OnInit, Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {AngularTokenService} from 'angular-token';
import {CommentEvent, InstantMessageEvent, ProcessEvent} from 'app/+store/process-event/process-event';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {
  CollectorItemLookupSelectors,
  CommentActions,
  ProcessEventActions,
  ProcessEventSelectors,
  ProcessParticipantSelectors
} from 'app/+store';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {first, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {ProcessEventType} from 'app/+store/process-event/process-event.interface';
import {IFivefAvatarProfile} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.interface';
import {FivefAvatarService} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.service';
import {CommentService} from 'app/+store/comment/comment.service';
import {Comment} from 'app/+store/comment/comment';
import {ProcessEventService} from 'app/+store/process-event/process-event.service';
import {SendInstantMessageSuccess} from 'app/+store/process-event/process-event.actions';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {of} from 'rxjs/internal/observable/of';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {CollectorLookupItem} from 'app/+store/collector-item-lookup/collector-item-lookup';
import {Process} from 'app/+store/process/process';
import {TranslateService} from '@ngx-translate/core';
import {PreviewViewType} from '../../../artifact/fivef-artifact-preview-browser/fivef-artifact-preview-dialog/fivef-artifact-preview-dialog.component';

/**
 * Message panel used by the Project's or Project Room's activity log.
 */
@Component({
  selector: 'dvtx-message-panel',
  templateUrl: './fivef-message-panel.component.html',
  styleUrls: ['./fivef-message-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefMessagePanelComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();
  public ProcessEventType = ProcessEventType;
  public PreviewViewType = PreviewViewType;

  /**
   * Collector item reference if comment was made in context of a Collecto element.
   * Input message is carrying the itemId property in this context.
   */
  public item$: Observable<CollectorLookupItem>;
  private itemId$ = new BehaviorSubject<string>(null);

  private uid: string; // Email aka uid of current user.

  @ViewChildren('viewComments')
  private viewComments: QueryList<ElementRef>;

  private createdAt;
  public _message;

  public seenBy$: Observable<IFivefAvatarProfile[]>;
  public reactions = [];

  public recipients$: IFivefAvatarProfile[] = [];
  private allParticipants$: Observable<ProcessParticipant[]>;

  public avatar$;

  /**
   * Ng Model for the reply and the emoji-input component.
   */
  public comment = '';
  public form: UntypedFormGroup;

  /**
   * shows the reply container.
   */
  public showInputComment = false;

  public editMode = false;

  @Input()
  public dateFormat = 'HH:mm';

  @Input()
  public process;
  @Input()
  public readonly = false;

  @Input()
  private status;

  @Output()
  private onOpenPreview = new EventEmitter<{ process: Process; artifactId: string; tabId: number }>();

  public lang = 'de';

  @Input()
  private set message(message: InstantMessageEvent) {
    this._message = message;

    this.avatar$ = this.avatarService.getProfile(message.performer);

    let timestamp = null;
    try {
      timestamp = Date.parse(message.createdAt);
    } catch (error) {
      console.error('MessagePanelComponent#message', error);
    }
    if (!timestamp || isNaN(timestamp)) {
      this.createdAt = new Date(message.createdAt);
    } else {
      this.createdAt = new Date();
    }

    if (message && message.recipients) {
      message.recipients.map(person => {
        this.recipients$.push(this.avatarService.getProfile(person));
      });

      if (!message.recipients.includes(message.performer)) {
        this.recipients$.push(this.avatarService.getProfile(message.performer));
      }
    }

    // if (message.reactionList && message.reactionList.length > 0) {
    //   this.reactions = message.reactionList;
    // }

    if (message && message.seenBy) {
      // this.seenBy$ = this.store.select(ProcessEventSelectors.getSeenBys(message.id))
      //   .pipe(map(seenBys =>
      //    seenBys.map(person => this.avatarService.getProfile(person))));

      // fill seenby variable only when there are data
      // not all comments are filled with there seenbys when updating reaction or retrieving the events
      this.store.select(ProcessEventSelectors.getSeenBys(message.id))
        .subscribe((seenBys => {
          if (seenBys && seenBys.length > 0) {
            this.seenBy$ = of(seenBys.map(person => this.avatarService.getProfile(person)));
          }
        }));
    }

    // Comment reference, e.g. Collecto Item.
    if (message.itemId) {
      this.itemId$.next(message.itemId);
    }
  }

  constructor(private token: AngularTokenService,
              private store: Store<AppState>,
              private fb: UntypedFormBuilder,
              private commentSvc: CommentService,
              private avatarService: FivefAvatarService,
              private eventSvc: ProcessEventService,
              private i18nSvc: TranslateService,
              private notifyService: FivefNotificationService,
              private cdr: ChangeDetectorRef) {
    this.uid = token.currentUserData.uid;
    this.allParticipants$ = this.store.select(ProcessParticipantSelectors.getProcessParticipantsOfSelectedProcess);
    this.form = this.fb.group({content: ''})
  }

  ngOnInit() {
    // Collecto reference. Used +store item is a minimal Collecto item object containing Group/Element info.
    this.item$ = this.itemId$.pipe(switchMap(itemId => this.store.select(CollectorItemLookupSelectors.getItem(itemId))));

    this.i18nSvc.onLangChange
      .pipe(
        startWith({lang: this.i18nSvc.currentLang}),
        takeUntil(this.onDestroy))
      .subscribe(lang => {
        this.lang = lang.lang;
        this.cdr.detectChanges();
      });

    // load commentseenbys after initializing readonly parameter, to avoid incase of readononly is true
    if (this._message && (this._message.type === ProcessEventType.Comment || this._message.type === ProcessEventType.ArtifactComment)) {
      this._loadCommentSeenBys(this._message);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.itemId$.complete();
  }

  public isFromCurrentUser() {
    return this.uid === this._message.performer;
  }

  public toggleComment() {
    this.showInputComment = !this.showInputComment;
  }

  public sendMessage() {
    const payload = {
      message: this.comment,
      recipients: [],
      publicComment:
      this._message.publicComment,
      referenceId: this._message.referenceId,
      parentId: this._message.parentId
    };
    this.eventSvc.sendInstantMessage(this._message.processId, payload, this._message.id)
      .pipe(first())
      .subscribe((ev: ProcessEvent) => {
        this.store.dispatch(new SendInstantMessageSuccess(ev));
        this.comment = '';
        this.showInputComment = false;
        this.cdr.detectChanges();
      }, err => {
        console.error(err);
        this.notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this.cdr.detectChanges();
      });
  }

  /**
   * Comments are a parallel model on Collecto and CAC nodes and maintain their own seen by functionality.
   * Load it and push it to the process event for same UX.
   * @private
   */
  private _loadCommentSeenBys(message) {
    if (!message || this.readonly) {
      return;
    }

    const comment = message as CommentEvent;
    let call = null;
    if (message.type === ProcessEventType.Comment) {
      call = this.commentSvc.getOneProcessComment(comment.processId, comment.commentId);
    }

    if (message.type === ProcessEventType.ArtifactComment) {
      call = this.commentSvc.getOneArtifactComment(this._message.artifactId, comment.commentId)
    }

    if (call) {
      call.pipe(first()).subscribe((_comment: Comment) => this.setSeenByAndReactions(message, _comment), err => console.error(err));
    }
  }

  /**
   * Resets the comment data from markRead directive.
   * @param message
   * @param comment
   */
  public updateComment(message, comment): void {
    if (message.type === ProcessEventType.ArtifactComment || message.type === ProcessEventType.Comment) {
      this.setSeenByAndReactions(message, comment);
    }
  }

  /**
   * Sets the comment seen bys and reactions out of the comment response data.
   * @param message
   * @param comment
   * @private
   */
  private setSeenByAndReactions(message: CommentEvent, comment: Comment): void {
    message.seenBy = comment.seenBy;
    this.reactions = comment.reactionList;

    try {
      this.seenBy$ = of(comment.seenBy.map(person => this.avatarService.getProfile(person)));
      this.cdr.detectChanges();
    } catch (err) {
      console.error(err);
    }
  }

  public enableEditMode() {
    this.form.patchValue({content: this._message.details});
    this.form.updateValueAndValidity();
    this.editMode = true;
    this.cdr.detectChanges();
  }

  public editComment() {
    const content = this.form.value.content;
    this.commentSvc.editComment(this._message.processId, this._message.id, content)
      .pipe(first())
      .subscribe((ev: ProcessEvent) => {
        this._message.details = content;
        this._message.updatedAt = new Date();
        this.form.patchValue({content: ''});
        this.form.updateValueAndValidity();
        this.editMode = false;
        this.comment = '';
        this.showInputComment = false;
        this.cdr.detectChanges();
        this.notifyService.success('GENERAL.UPDATE_ACTION_DONE');
      }, err => {
        console.error(err);
        this.notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this.cdr.detectChanges();
      });
  }

  public deleteComment() {
    this.commentSvc.deleteComment(this._message.processId, this._message.id)
      .pipe(first())
      .subscribe((ev: ProcessEvent) => {
        this.editMode = false;
        this.comment = '';
        this.showInputComment = false;
        this.cdr.detectChanges();
        this.notifyService.success('PROJECT_ROOM.COMMENT_DELETED')
        this.store.dispatch(new ProcessEventActions.DeleteSuccess(this._message.id))
      }, err => {
        console.error(err);
        this.notifyService.error('INBOX.GENERAL_SEND_ERROR')
        this.cdr.detectChanges();
      });
  }

  public cancelEdit() {
    this.form.patchValue({content: ''});
    this.form.updateValueAndValidity();
    this.editMode = false;
    this.cdr.detectChanges();
  }

  public react($event) {
    if (this._message.type === ProcessEventType.InstantMessage) {
      this.store.dispatch(new ProcessEventActions.React(this._message.processId, this._message.id, $event));
      return
    }
    const comment = this._message as CommentEvent;
    if (comment.type === ProcessEventType.Comment) {
      this.commentSvc.doReact(this._message.processId, comment.commentId, $event)
        .pipe(first())
        .subscribe((_comment) => {
          this.reactions = _comment.reactionList;
          this.store.dispatch(new CommentActions.SaveCommentSuccess(_comment));
          this.cdr.detectChanges();
        }, err => {
          console.error(err);
          this.notifyService.error('INBOX.GENERAL_SEND_ERROR')
          this.cdr.detectChanges();
        });
    }

    if (comment.type === ProcessEventType.ArtifactComment) {
      this.commentSvc.doReactOnArtifactComment(this._message.artifactId, comment.commentId, $event)
        .pipe(first())
        .subscribe((_comment) => {
          this.reactions = _comment.reactionList;
          this.store.dispatch(new CommentActions.SaveCommentSuccess(_comment));
          this.cdr.detectChanges();
        }, err => {
          console.error(err);
          this.notifyService.error('INBOX.GENERAL_SEND_ERROR')
          this.cdr.detectChanges();
        });
    }
  }

  /**
   * Opens the preview dialog by event to outer component.
   */
  public openPreviewDialog(): void {
    if (!this.process || !this._message.artifactId) {
      return;
    }

    const event = {
      process: this.process,
      artifactId: this._message.artifactId,
      tabId: 1
    }
    this.onOpenPreview.emit(event)
  }
}
