import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {WorkflowTemplateCategory} from "./workflow-template-category";

export class WorkflowTemplateCategoryBuilder implements IApiResourceBuilder<WorkflowTemplateCategory> {
  fromResponse(data): WorkflowTemplateCategory {
    return new WorkflowTemplateCategory(
      data.id,
      data.attributes.title,
      data.attributes.color,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(_: WorkflowTemplateCategory) {
    return null;
  }
}

