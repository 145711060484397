import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {ProcessEventBuilder} from './process-event.builder';
import {ProcessEvent} from './process-event';
import {IInstantMessage} from '../message/message';
import {ProcessArtifactActions} from '../process-artifact';
import {ProcessParticipantActions} from '../process-participant';
import {ProcessEventActions} from './index';
import {WebsocketService} from '../../services/websocket.service';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import Channel = ActionCable.Channel;
import {environment} from 'environments/environment';
import {AngularTokenService} from 'angular-token';
import {HttpClient} from '@angular/common/http';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {map} from 'rxjs/operators';
import {CommentReactionType} from '../comment/comment.interface';
import {CommentActions} from '../comment';
import {ProcessTreeService} from 'app/+store/process-tree/process-tree.service';

export interface WebsocketSubscriptionOptions {
  reloadCommentStatistics?: boolean;
}

@Injectable()
export class ProcessEventService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';
  counter = 0;
  apiUrl: string;

  constructor(private _http: FivefApiResourceService,
              private _httpClient: HttpClient,
              private _ws: WebsocketService,
              private _store: Store<AppState>,
              private _tokenSvc: AngularTokenService,
              private env: EnvService,
              private _ptSvc: ProcessTreeService) {
    this.apiUrl = env.apiBase();
  }

  getAll(id: string, page: number = null, perPage: number = 15, selectedEvents = null): Observable<ProcessEvent[]> {
    // Debugging:
    // this.counter += 1;
    // console.error('Event service invoke count: ALL: ', this.counter, page);
    const _queryParams = [];

    if (page) {
      _queryParams.push(`page=${page}&per_page=${perPage}`);
    }

    if (selectedEvents) {
      _queryParams.push(`conversation=${selectedEvents['conversation']}`);
      _queryParams.push(`documents=${selectedEvents['documents']}`);
      _queryParams.push(`received=${selectedEvents['received']}`);
      _queryParams.push(`updates=${selectedEvents['updates']}`);
    }

    const queryParams = _queryParams.length > 0 ? `?${_queryParams.join('&')}` : '';
    const builder = new ProcessEventBuilder(id);
    return <Observable<ProcessEvent[]>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${id}/process_events${queryParams}`);
  }

  getAllComments(id: string): Observable<ProcessEvent[]> {
    const builder = new ProcessEventBuilder(id);
    return <Observable<ProcessEvent[]>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${id}/process_events?type=comment&all=true`);
  }

  getOne(processId: string, eventId: string, parentId = null): Observable<ProcessEvent> {
    const builder = new ProcessEventBuilder(processId);
    return <Observable<ProcessEvent>>this._http.get<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${processId}/process_events/${eventId}?parent_id=${parentId}`);
  }

  read(processId: string, eventId: string): Observable<ProcessEvent> {
    const builder = new ProcessEventBuilder(processId);
    return <Observable<ProcessEvent>>this._http.post<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${processId}/process_events/${eventId}/read`, {});
  }

  doReaction(processId: string, eventId: string, reactionType: CommentReactionType): Observable<ProcessEvent> {
    const builder = new ProcessEventBuilder(processId);
    const payload = {data: {attributes: {reaction_type: reactionType}}}
    return <Observable<ProcessEvent>>this._http.post<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${processId}/process_events/${eventId}/reaction`, payload);
  }

  sendInstantMessage(id: string, message: IInstantMessage, replyTo: string = null): Observable<ProcessEvent> {
    const params = replyTo ? `?reply_to=${replyTo}` : '';
    const builder = new ProcessEventBuilder(id);
    const payload = {data: {attributes: {message: message.message, recipients: message.recipients, public: message.publicComment, backtrack_id: message.referenceId, parent_id: message.parentId}}};
    return <Observable<ProcessEvent>>this._http.post<ProcessEventBuilder, ProcessEvent>(builder, `${this.BASE_PATH}/${id}/messages${params}`, payload);
  }

  downloadComments(processId, ids: string[]) {
    const payload = {
      data: {
        attributes: {
          ids: ids
        }
      }
    }
    return this._httpClient.post(`${this.apiUrl}/${this.BASE_PATH}/${processId}/comments/download`, payload)
      .pipe(map(res => {
        return {
          content: res['data']['attributes']['content']
        };
      }))
  }

  subscribeWsToProcess(id, user, options: WebsocketSubscriptionOptions = {}): Channel {
    // const performerEmail = user.email;
    try {
      return this._ws.subscribe(user,
        'WorkflowEngine::TimelinesChannel', 'follow', {'process_id': id}, (data) => {
          // const performer = performerEmail;
          const opt: WebsocketSubscriptionOptions = options;
          const processId = id;
          if (!environment.production) {
            console.error('[Websocket | Project Room] Data received:', data.type, data);
          }
          try {
            switch (data.type) {
              case 'workflow_engine.process.timeline.panel':
                // Load generic data
                break;
              case 'workflow_engine.process.artifact.new':
                if (data?.payload?.artifact_id) {
                  this._store.dispatch(new ProcessArtifactActions.LoadOneById(data.payload.artifact_id));
                }
                break;
              case 'workflow_engine.process.artifact.renamed':
                if (!environment.production) {
                  console.error(data)
                }
                if (this._tokenSvc.currentAuthData.uid !== data.performer && data.payload && data.payload.process_id) {
                  this._store.dispatch(new ProcessEventActions.RefreshUploads(data.payload.process_id, data.payload.upload_id));
                } else {
                  if (!environment.production) {
                    console.error('[Websocket | Project Room] Renamed event but It\'s me.');
                  }
                }
                return; // ATTENTION: Return, no further actions needed. Events are replaced in-place.
              case 'workflow_engine.process.participant.new':
              case 'workflow_engine.process.participant.remove':
              case 'workflow_engine.process.external_participant.new':
              case 'workflow_engine.process.external_participant.remove':
                if (data.performer === user.uid) {
                  if (!environment.production) {
                    console.error('[Websocket | Project Room] It\'s me. Exit... Data received:', data.type, data);
                  }
                  return;
                }
                this._store.dispatch(new ProcessParticipantActions.LoadAllRefresh(id));
                break;
              case 'workflow_engine.process.label.changed':
                this._store.dispatch(new ProcessEventActions.LoadOne(data.payload.process_id, data.payload.event_id, id));
                break;
              case 'workflow_engine.artifact.comment.new':
                this._store.dispatch(new ProcessEventActions.LoadOne(data.payload.process_id, data.payload.event_id, id));
                this._store.dispatch(new ProcessArtifactActions.LoadOneById(data.payload.artifact_id));
                return;
              case 'workflow_engine.artifact.comment.edited':
                this._store.dispatch(new ProcessEventActions.LoadOne(data.payload.process_id, data.payload.event_id, id));
                this._store.dispatch(new ProcessArtifactActions.LoadOneById(data.payload.artifact_id));
                return;
              case 'workflow_engine.process.comment.new':
                if (data && data.payload && data.payload.event_id) {
                  this._store.dispatch(new ProcessEventActions.LoadOne(data.payload.process_id, data.payload.event_id, id));
                  this._store.dispatch(new CommentActions.LoadOne(data.payload.process_id, data.payload.comment_id));
                  if (opt.reloadCommentStatistics) {
                    this._store.dispatch(new CommentActions.LoadProcessStatistics(data.payload.process_id));
                  }
                }
                break;
              case 'workflow_engine.process.comment.deleted':
                if (data && data.payload && data.payload.comment_id) {
                  this._store.dispatch(new ProcessEventActions.DeleteSuccess(data.payload.event_id));
                  this._store.dispatch(new CommentActions.DeleteCommentSuccess(data.payload.comment_id));
                  if (opt.reloadCommentStatistics) {
                    this._store.dispatch(new CommentActions.LoadProcessStatistics(processId));
                  }
                }
                return;
              case 'workflow_engine.process.comment.edited':
                if (data && data.payload && data.payload.event_id && data.payload.process_id) {
                  this._store.dispatch(new ProcessEventActions.LoadOne(data.payload.process_id, data.payload.event_id, id));
                  this._store.dispatch(new CommentActions.LoadOne(data.payload.process_id, data.payload.event_id));
                  // if (data.payload.performer && performer !== data.payload.performer) {
                  //  TODO: Do Message on comment update.
                  // }
                }
                return;
            }
            this._store.dispatch(new ProcessEventActions.LoadAll(id, 1));
            setTimeout(_ => this._ptSvc.refreshProjectStructure(id), 0);
          } catch (error) {
            console.error('[Websocket | Project Room] Error: ', error);
            console.error('WS[Websocket | Project Room] Error DATA: ', data);
          }
        });
    } catch (error) {
      console.error('_connectWS', error);
      return null;
    }
  }
}
