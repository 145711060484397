import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CacEntity} from './cac-entity';

export class CacEntityBuilder implements IApiResourceBuilder<CacEntity> {
  constructor(public cacId: string) {}

  fromResponse(data): CacEntity {
    return null;
  }

  toRequest(recipient: CacEntity) {
    return null;
  }
}
