import {Injectable} from '@angular/core';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CppApiService} from '../../services/cpp-api.service';
import {Store} from '@ngrx/store';
import {
  ContactOrganizationActionTypes,
  EditContact,
  EditContactSuccess,
  LoadOne,
  LoadOneSuccess,
  RemoveParentOrganization,
  RemoveParentOrganizationSuccess,
  RemovePrimaryContact,
  RemovePrimaryContactSuccess,
  SetParentOrganization,
  SetParentOrganizationSuccess,
  SetPrimaryContact,
  SetPrimaryContactSuccess
} from './contact-organization.actions';
import {
  ContactOrganizationResponse,
  createContactOrganizationFromResponse
} from './contact-organization';
import * as OrganizationSelectors from '../organization/organization.selectors';
import {Organization} from '../organization/organization';
import {LoadAllSuccess} from '../contact/contact.actions';
import * as NotificationActions from '../notification/notification.actions';
import {ContactPersonOrganizationAssociationReponse} from '../contact-person-organization-association/contact-person-organization-association';

@Injectable()
export class ContactOrganizationEffects {
  loadOne = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.LoadOne),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, orga]: [LoadOne, Organization]) => {
        return this.cppApiService.get<ContactOrganizationResponse>('organization/' + orga.id + '/addressbook/' + orga.addressbookId + '/organizationcontacts/' + action.contactId).pipe(
          map((res: ContactOrganizationResponse) => {
            const loadedContact = createContactOrganizationFromResponse(res);
            return new LoadOneSuccess(loadedContact);
          }),
          catchError(err => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  ));

  setPrimaryContact = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.SetPrimaryContact),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [SetPrimaryContact, Organization]) => {
      return this.cppApiService.post<ContactPersonOrganizationAssociationReponse>(`organization/${org.id}/addressbook/${org.addressbookId}/organizationcontacts/${action.payload.orgContactId}/primarymember`, {Id: action.payload.primaryContactId}).pipe(
        map((resp: ContactPersonOrganizationAssociationReponse) => {
          return new SetPrimaryContactSuccess({
            orgContactId: action.payload.orgContactId,
            primaryContactId: resp.data.id
          })
        }),
        catchError(err => of(new NotificationActions.ShowHttpError(err)))
      )
      }
    )
  ));

  removePrimaryContact = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.RemovePrimaryContact),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [RemovePrimaryContact, Organization]) => {
        return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/organizationcontacts/${action.payload.orgContactId}/primarymember`).pipe(
          map((resp: any) => {
            return new RemovePrimaryContactSuccess(action.payload.orgContactId)
          }),
          catchError(err => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  ));

  setParentOrg = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.SetParentOrganization),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [SetParentOrganization, Organization]) => {
        return this.cppApiService.post<any>(`organization/${org.id}/addressbook/${org.addressbookId}/organizationcontacts/${action.payload.orgContactId}/parentorganization`, {id: action.payload.parentOrgId}).pipe(
          map((resp: any) => {
            return new SetParentOrganizationSuccess(action.payload)
          }),
          catchError(err => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  ));

  removeParentOrg = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.RemoveParentOrganization),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, org]: [RemoveParentOrganization, Organization]) => {
        return this.cppApiService.delete<any>(`organization/${org.id}/addressbook/${org.addressbookId}/organizationcontacts/${action.payload.orgContactId}/parentorganization`).pipe(
          map((resp: any) => {
            return new RemoveParentOrganizationSuccess(action.payload.orgContactId)
          }),
          catchError(err => of(new NotificationActions.ShowHttpError(err)))
        )
      }
    )
  ));

  editContact = createEffect(() => this.actions.pipe(
    ofType(ContactOrganizationActionTypes.EditContact),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected)),
    switchMap(([action, orga]: [EditContact, Organization]) => {
      const body = {
        name: action.contactToEdit.name,
        email: action.contactToEdit.mainEmailAddress.toCppRequest(),
        address: action.contactToEdit.mainAddress.toCppRequest(),
        phone: action.contactToEdit.mainPhoneNumber.toCppRequest(),
        legal_form_id: String(action.contactToEdit.legalFormId),
        contact_visibility: action.contactToEdit.contactVisibility.type,
        visible_for_id: action.contactToEdit.contactVisibility.visibleFor,
        organization_type_id: String(action.contactToEdit.organization_type_id),
        };

        return this.cppApiService.put<ContactOrganizationResponse>('organization/' + orga.id + '/addressbook/' + orga.addressbookId + '/organizationcontacts/' + action.contactToEdit.id, body).pipe(
          switchMap((res: ContactOrganizationResponse) => {
            const loadedContact = createContactOrganizationFromResponse(res);
            return [
              new EditContactSuccess(loadedContact),
              new LoadAllSuccess([{
                email: loadedContact.mainEmailAddress.emailAddress,
                telephone: loadedContact.mainPhoneNumber ? loadedContact.mainPhoneNumber.countryCode + loadedContact.mainPhoneNumber.phoneNumber : undefined,
                name: loadedContact.name,
                legalForm: loadedContact.legalFormId
              }
              ])
            ]
              ;
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          })
        )
      }
    )
  ));

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private store: Store<any>
  ) {
  }
}
