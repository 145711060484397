<fivef-dialog [title]="'CONTACTS.IMPORT_CONTACTS'">
  <dvtx-csv-import [settings]="csvSettings" [(ngModel)]="data">
    <!-- <mat-card-content class="after-drop-zone col-12 col-sm-12">
      <dvtx-csv-import-drop-zone class="drag-n-drop" [fileTypes]="['VCF']" (newFile)="newVcf($event)"></dvtx-csv-import-drop-zone>
    </mat-card-content> -->
  </dvtx-csv-import>

  <div fivef-dialog-footer>
    <button type="reset" mat-button mat-dialog-close>{{'GENERAL.CANCEL_ACTION' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!data" (click)="createContacts()">{{ 'GENERAL.SAVE_ACTION' | translate }}</button>
  </div>
</fivef-dialog>
