<button
  mat-icon-button
  [matMenuTriggerFor]="emojiMenu"
  #emojiMenuOption="matMenuTrigger"
  [disableRipple]='true'>
  <mat-icon color="{{emojiMenuOption?.menuOpen ?  'primary' : '' }}">sentiment_very_satisfied</mat-icon>
</button>
<mat-menu #emojiMenu="matMenu" class="fivef-emoji-menu--menu-container">
  <emoji-mart
    (click)="$event.stopPropagation();"
    (emojiClick)="insertEmoji($event);emojiMenuOption?.closeMenu()"
    [showPreview]="false"
    [emojiTooltip]="false"
    [autoFocus]="true"
    isNative="true"
    color="#333"
    title="Pick your emoji…"
    emoji="point_up">
  </emoji-mart>
</mat-menu>

