import {ContactPersonActions, ContactPersonActionTypes} from './contact-person.actions';
import {adapter, initialState, State} from './contact-person.state';
import {ContactActions, ContactActionTypes} from '../contact/contact.actions'
import {SortContactAttributeGroup} from '../contact/contact';

export function reducer(state = initialState, action: ContactPersonActions | ContactActions): State {
  switch (action.type) {
    case ContactPersonActionTypes.LoadOne:
      return {
        ...state,
        loading: true
      };
    case ContactPersonActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });
    case ContactPersonActionTypes.LoadOneFail:
      return {
        ...state,
        loading: false
      }
    case ContactPersonActionTypes.EditContactSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
      });
    case ContactActionTypes.AddAttributeGroupSuccess:
      const aaggsId = action.contactId;
      const aaggsEntity = state.entities[aaggsId];
      if (aaggsEntity) {
        const attributeGroups = [...aaggsEntity.attributeGroups, action.payload].sort(SortContactAttributeGroup);
        return adapter.updateOne({id: aaggsId, changes: {attributeGroups: attributeGroups}}, state)
      }
      return state;
    case ContactActionTypes.AddAttributeSuccess:
      const aasId = action.contactId;
      const aasEntity = state.entities[aasId];
      if (aasEntity) {
        const foundIdx = aasEntity.attributeGroups.findIndex(x => x.id === action.attributeGroupId);
        if (foundIdx >= 0) {
          const attrGroup = aasEntity.attributeGroups.splice(foundIdx, 1)[0];
          attrGroup.items = [...attrGroup.items, action.payload];
          const attrGroups = [...state.entities[aasId].attributeGroups, attrGroup].sort(SortContactAttributeGroup);
          return adapter.updateOne({id: aasId, changes: {attributeGroups: attrGroups}}, state);
        }
      }
      return state;
    case ContactActionTypes.DeleteAttributeGroupSuccess:
      const dagsId = action.contactId;
      const dagsEntity = state.entities[dagsId];
      if (dagsEntity) {
        const foundIdx = dagsEntity.attributeGroups.findIndex(x => x.id === action.attributeGroupId);
        if (foundIdx >= 0) {
          dagsEntity.attributeGroups.splice(foundIdx, 1);
          const attributeGroups = [...dagsEntity.attributeGroups].sort(SortContactAttributeGroup);
          return adapter.updateOne({id: dagsId, changes: {attributeGroups: attributeGroups}}, state)
        }
      }
      return state;
    case ContactActionTypes.DeleteAttributeSuccess:
      const dasId = action.contactId;
      const dasEntity = state.entities[dasId];
      if (dasEntity) {
        const foundIdx = dasEntity.attributeGroups.findIndex(x => x.id === action.attributeGroupId);
        if (foundIdx >= 0) {
          const attrGroup = dasEntity.attributeGroups.splice(foundIdx, 1)[0];
          const itemIndex = attrGroup.items.findIndex(x => x.id === action.attributeId);
          attrGroup.items.splice(itemIndex, 1);
          const attrGroups = [...state.entities[dasId].attributeGroups, attrGroup];
          attrGroups.sort(SortContactAttributeGroup);
          return adapter.updateOne({id: dasId, changes: {attributeGroups: attrGroups}}, state);
        }
      }
      return state;
    case   ContactPersonActionTypes.EditContactFail   :
      return {
        ...state,
        loading: false
      }
    case ContactActionTypes.AddFurtherAddressSuccess: {
      const id = action.payload.contactId
      const entity = state.entities[id]
      if (entity) {
        const addresses = [...entity.addresses, action.payload.address]
        return adapter.updateOne({id, changes: {addresses}}, state)
      }
      return state
    }
    case ContactActionTypes.RemoveFurtherAddressSuccess: {
      const contactId = action.contactId;
      const entity = state.entities[contactId]
      if (entity) {

        const addresses = entity.addresses.filter((address) => address.id !== action.addressId);
        return adapter.updateOne({id: contactId, changes: {addresses}}, state);
      }
      return state
    }
    case ContactActionTypes.AddFurtherPhoneNumberSuccess: {
      const id = action.payload.contactId
      const entity = state.entities[id]
      if (entity) {
        const phoneNumbers = [...entity.phoneNumbers, action.payload.phoneNumber]
        return adapter.updateOne({id, changes: {phoneNumbers}}, state)
      }
      return state
    }
    case ContactActionTypes.RemoveFurtherPhoneNumberSuccess: {
      const contactId = action.contactId;
      const entity = state.entities[contactId]
      if (entity) {
        const phoneNumbers = entity.phoneNumbers.filter((phoneNumber) => phoneNumber.id !== action.phoneNumberId);
        return adapter.updateOne({id: contactId, changes: {phoneNumbers}}, state);
      }
      return state
    }
    case ContactActionTypes.AddFurtherEMailSuccess: {
      const id = action.payload.contactId;
      const entity = state.entities[id]
      if (entity) {
        const emailAddresses = [...entity.emailAddresses, action.payload.emailAddress];
        return adapter.updateOne({id, changes: {emailAddresses}}, state);
      }
      return state
    }
    case ContactActionTypes.RemoveFurtherEMailSuccess: {
      const contactId = action.contactId;
      const entity = state.entities[contactId]
      if (entity) {
        const emailAddresses = entity.emailAddresses.filter((emailAddress) => emailAddress.id !== action.emailAddressId);
        return adapter.updateOne({id: contactId, changes: {emailAddresses}}, state);
      }
      return state
    }
    case ContactPersonActionTypes.LoadAssignedOrganizationsSuccess: {
      return adapter.updateOne({id: action.payload.id, changes: {assignedOrganizations: action.payload.assigns}}, state);
    }
  }
  return state
}
