import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-add-employee-dropdown',
  templateUrl: './add-employee-dropdown.component.html',
  styleUrls: ['./add-employee-dropdown.component.scss']
})
export class AddEmployeeDropdownComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  @Input() disabled = false;

  constructor(private _store: Store<AppState>) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
