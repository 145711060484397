import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {LicenceControlService} from 'app/services/licence-control.service';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatBadgeModule} from '@angular/material/badge';
import {RouterModule} from '@angular/router';

/**
 * Navigation link for sidebars.
 * Supports a route or a callback.
 * The visual appearance can be switched from horizontal (default) to vertical.
 */
@Component({
  selector: 'fivef-nav-link',
  host: {'class': 'fivef-nav-link-container'},
  templateUrl: './fivef-nav-link.component.html',
  styleUrls: ['./fivef-nav-link.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule, MatBadgeModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefNavLinkComponent implements OnInit {
  /**
   * Link icon.
   */
  @Input()
  icon;

  /**
   * Callback to be called on click.
   */
  @Input()
  callback;

  /**
   * Count for the badge.
   */
  @Input()
  count;

  /**
   * Color scheme of the link.
   */
  @Input()
  infoLevel;

  /**
   * Title as translation key.
   */
  @Input()
  title;

  /**
   * Routing path.
   */
  @Input()
  path;

  /**
   * Input orientation switches the icon text placement: horizontal (default) or vertical with smaller
   * text.
   */
  @Input()
  orientation: 'vertical' | 'horizontal' = 'horizontal';

  /**
   * Removes the text from the link.
   */
  @Input()
  showOnlyIcons = false;


  constructor(private licenceControl: LicenceControlService) {
  }

  ngOnInit() {
  }

  public checkLicense() {
    if (this.infoLevel === 'customizationSettingsRoute') {
      this.licenceControl.hasRequiredLicence('Professional', true)
    }
  }
}
