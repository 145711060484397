import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {startWith, takeUntil} from 'rxjs/operators';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'fivef-access-token-not-found',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './fivef-access-token-not-found.component.html',
  styleUrls: ['./fivef-access-token-not-found.component.scss']
})
export class FivefAccessTokenNotFoundComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();
  public lang = 'de';

  constructor(private i18nSvc: TranslateService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.i18nSvc.onLangChange.pipe(startWith({lang: this.i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => {
      this.lang = lang.lang;
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
