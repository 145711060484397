import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {TwoFactorSessionActions, TwoFactorSessionSelectors} from 'app/+store/two-factor-session';
import {takeUntil} from 'rxjs/operators';
import {TwoFactorSessionService} from 'app/+store/two-factor-session/two-factor-session.service';
import {TwoFactorSession} from 'app/+store/two-factor-session/two-factor-session';
import {Location} from '@angular/common';

@Component({
  selector: 'dvtx-two-factor-verification-dialog',
  templateUrl: './two-factor-verification-dialog.component.html',
  styleUrls: ['./two-factor-verification-dialog.component.scss']
})
export class TwoFactorVerificationDialogComponent implements AfterViewInit, OnDestroy {
  onDestroy = new Subject<void>();

  @ViewChild('twoFaAuthDialogTpl', {static: true})
  private twoFaAuthDialogTpl: TemplateRef<any>;
  private _dialogRef: MatDialogRef<any>;

  form: UntypedFormGroup;
  submitOngoing = false;
  error$ = new BehaviorSubject<string>(null);

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog,
              private _twoFactorSessionService: TwoFactorSessionService,
              private _location: Location,
              private _fb: UntypedFormBuilder) {
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this._store.select(TwoFactorSessionSelectors.getDialogRequest)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(open => {
          if (open) {
            this.openDialog();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.error$.complete();
  }

  public openDialog() {
    this._store.dispatch(new TwoFactorSessionActions.ResetDialogOpenState());

    if (this._dialogRef) {
      return;
    }

    this._initForm();

    this._dialogRef = this._dialog.open(this.twoFaAuthDialogTpl);
    this._dialogRef.afterClosed().subscribe(result => {
      this._dialogRef = null;
    });
  }

  public close() {
    if (this._dialogRef) {
      try {
        this._dialog.closeAll();
      } catch (e) {
        console.error(e);
      } finally {
        this._dialogRef = null;
      }
    }
  }

  public cancel() {
    this.close();
    this._location.back();
    this._store.dispatch(new TwoFactorSessionActions.Reset);
  }

  private _initForm() {
    this.form = this._fb.group({});
  }

  confirm() {
    if (this.form.invalid) return;

    this.submitOngoing = true;
    const sessionRequest = new TwoFactorSession(null, null);
    const otp_code = this.form.value.firstInput
      + this.form.value.secondInput
      + this.form.value.thirdInput
      + this.form.value.fourthInput
      + this.form.value.fifthInput
      + this.form.value.sixthInput;
    sessionRequest.code = otp_code;
    this._twoFactorSessionService.create(sessionRequest).subscribe(session => {
      this._store.dispatch(new TwoFactorSessionActions.LoadSuccess(session));
      this.submitOngoing = false;
      this.close();
    }, err => {
      this.submitOngoing = false;
      this.form.reset();
    });
  }
}
