import {IExternalAccessConfig, IQuickshare} from './quickshare.interface';
import {Process} from '../process/process';
import { Client } from 'app/+store/client/client';
import {IResource} from "../../lib/fivef-net/fivef-api-resource/models/resource.interface";

export class Quickshare implements IQuickshare {
  readonly type = 'workflow_engine_quickshares';
  public passphrase: string;
  client: Client;
  public parentId: string;

  constructor(public id: string,
              public title: string,
              public description: string,
              public state: string,
              public creatorName: string,
              public creatorEmail: string,
              public ownerName: string,
              public ownerEmail: string,
              public dmsFolderId: string,
              public notifyBySms: boolean,
              public passwordEnabled: boolean,
              public passwordExpiresAtEnabled: boolean,
              public passwordExpiresAt: Date,
              public dueDateEnabled: boolean,
              public dueDate: Date,
              public createdAt: Date,
              public updatedAt: Date,
              public color: string = null) {
  }

  clone() {
    return Quickshare.clone(this);
  }

  static asProcess(quickshare): Process {
    const p = new Process(
      quickshare.id,
      quickshare.title,
      null,
      quickshare.description,
      quickshare.dueDate,
      quickshare.createdAt,
      'workflow_engine_quickshares',
      quickshare.state,
      quickshare.updatedAt);
      p.color = quickshare.color;
      if (quickshare.client) {
        p.client = quickshare.client;
      }
      return p;
  }

  static clone(quickshare) {
    return new Quickshare(
      quickshare.id,
      quickshare.title, quickshare.description,
      quickshare.state,
      quickshare.ownerName, quickshare.ownerEmail,
      quickshare.creatorName, quickshare.creatorEmail,
      quickshare.dmsFolderId,
      quickshare.notifyBySms, quickshare.passwordEnabled,
      quickshare.passwordExpiresAtEnabled, quickshare.passwordExpiresAt,
      quickshare.dueDateEnabled, quickshare.dueDate,
      quickshare.createdAt, quickshare.updatedAt, quickshare.color
    );
  }

  static validPassphrase(passphrase): boolean {
    return !!passphrase && passphrase.trim().length > 5;
  }
}

export class QuickshareSettings implements IResource {
  readonly type = 'quickshare_settings';
  constructor(public id, public authorized: boolean, public tokens: IExternalAccessConfig[], public passphrase, public passwordExpiresAt) {
  }
}
