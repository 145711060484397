import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {DmsDocument} from './dms-document';



export class DmsDocumentBuilder implements IApiResourceBuilder<DmsDocument> {
  fromResponse(data): DmsDocument {
    const doc = new DmsDocument(
      data.id,
      data.attributes.name,
      data.attributes.filename,
      data.attributes.revision,
      data.attributes.folder_id,
      data.attributes.readable,
      data.attributes.writable,
      data.attributes.shared,
      data.attributes.protected_document,
      data.attributes.publicly_available,
      data.attributes.mime_type,
      data.attributes.size,
      data.attributes.pages,
      data.attributes.locked_until,
      data.attributes.preview_url,
      data.attributes.created_at,
      data.attributes.updated_at);

    doc.owner = data.attributes.owner_name;
    doc.auditProof = data.attributes.audit_proof;
    doc.uploadedBy = data.attributes.uploaded_by;
    return doc;
  }

  toRequest(_: DmsDocument) {
    return null;
  }
}
