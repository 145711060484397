<div
  [matTooltip]="(disabled ? 'SHOPPING_CART.NO_AVAILABLE_USER_LICENCES' : 'CONTACTS.ADD_OR_INVITE_EMPLOYEE') | translate">
  <button color="primary"
          [matMenuTriggerFor]="addEmployeeMenu"
          [disabled]="disabled"
          mat-raised-button type="button">
    <mat-icon aria-label="back" matPrefix>person_add</mat-icon>
    {{ 'CONTACTS.ADD_EMPLOYEE' | translate }}
  </button>
</div>
<mat-menu #addEmployeeMenu="matMenu" yPosition="below" xPosition="before"
          class="fivef-menu">
  <fivef-menu-item [icon]="'person_add'"
                   [title]="'CONTACTS.CREATE_EMPLOYEE'"
                   [subtitle]="'CONTACTS.CREATE_EMPLOYEE_INFO'"
                   dvtxEmployee
                   [selectedIndexTab]="'0'"></fivef-menu-item>

  <fivef-menu-item [icon]="'email'"
                   [title]="'INVITATIONS.INVITE_EMPLOYEE'"
                   [subtitle]="'CONTACTS.INVITE_EMPLOYEE_INFO'"
                   dvtxEmployee [selectedIndexTab]="'1'"></fivef-menu-item>
</mat-menu>
