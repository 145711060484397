import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FivefApiResourceService } from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import { ProjectStatistics } from './project-statistics';
import { ProjectStatisticsBuilder } from './project-statistics.builder';

@Injectable()
export class ProjectStatisticsService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: FivefApiResourceService) { }

  getAll(processId: string = null): Observable<ProjectStatistics> {
    const builder = new ProjectStatisticsBuilder();
    if (processId) {
      return <Observable<ProjectStatistics>>this._http.get<ProjectStatisticsBuilder, ProjectStatistics>(builder, `${this.BASE_PATH}/${processId}/statistics/dashboard`);
    } else {
      return <Observable<ProjectStatistics>>this._http.get<ProjectStatisticsBuilder, ProjectStatistics>(builder, `api/v1/workflow_engine/statistics/dashboard`);
    }
  }

}
