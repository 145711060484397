import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {OrganizationSelectors} from '../../../../+store';
import {AppState} from '../../../../app.state';
import {Store} from '@ngrx/store';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {IFivefFolderNode} from './fivef-folder-tree.interface';

/**
 * Data layer of the folder tree.
 *
 * Transforms and translates between the DmsFolder API model and internal tree
 * structure IFivefFolderNode.
 */
@Injectable()
export class FivefFolderTreeRepository implements OnDestroy {
  private onDestroy = new Subject<void>();

  private folders$ = new BehaviorSubject<DmsFolder[]>([]);
  public folderCache: {[id: string]: DmsFolder} = {};

  public folderNodes: Observable<IFivefFolderNode[]>;

  private organizationId$: Observable<string>;

  constructor(private store: Store<AppState>) {
    this.organizationId$ = this.store.select(OrganizationSelectors.getSelectedId);
    this.folderNodes = this.folders$.pipe(map(folders => this.buildTreeFrom(folders)));
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.folders$.complete();
  }

  /**
   * Initializes the repository and fetches all data.
   */
  public init() {
    this.folders$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(folders => {
        const folderCache: {[id: string]: DmsFolder} = {};
        folders.forEach(folder => folderCache[folder.id] = folder);
        this.folderCache = folderCache;
      });
  }

  public set folders(folders: DmsFolder[]) {
    this.folders$.next(folders && folders.length ? folders : []);
  }

  /**
   * Finds the original input folder by ID.
   *
   * @param id
   */
  public find(id: string): DmsFolder {
    return this.folderCache[id];
  }

  /**
   * Builds a tree of IFivefFolderNode entities from the DmsFolder API
   * model.
   * Children nodes available in the children property.
   *
   * @param folders
   * @private
   */
  private buildTreeFrom(folders: DmsFolder[]): IFivefFolderNode[] {
    const folderNodes: IFivefFolderNode[] = [];
    const parentMap: {[id: string]: IFivefFolderNode[]} = {};
    const folderMap: {[id: string]: IFivefFolderNode} = {};

    folders.forEach(folder => {
      const node = transformDmsFolder(folder);
      folderMap[node.id] = node;

      if (node.parentId) {
        if (!parentMap[node.parentId]) {
          parentMap[node.parentId] = [];
        }
        parentMap[node.parentId].push(node);

      } else {
        folderNodes.push(node);
      }
    });

    // Folders are ended, so no infinite run on circular folder structure.
    folders.forEach(f => {
      const current = folderMap[f.id];
      current.children = parentMap[f.id] ? parentMap[f.id] : [];
    });
    return folderNodes;
  }
}

/**
 * Transforms the DmsFolder model into the tree model IFivefFolderNode.
 * The original entitiy is available as data property.
 *
 * @param folder
 */
function transformDmsFolder(folder: DmsFolder): IFivefFolderNode {
  return {
    id: folder.id,
    icon: 'folder',
    title: folder.name,
    path: folder.path,
    sourceId: folder.sourceId,
    dmsAccountType: folder.dmsAccountType,
    parentId: folder.parentId,
    folderCount: folder.folderCount,
    processCount: folder.processCount,
    documentCount: folder.documentCount,
    shared: folder.shared,
    auditProof: folder.auditProof,
    createdAt: folder.createdAt,
    updatedAt: folder.updatedAt,
    children: [],
    userProcessMap: folder.userProcessMap,
    deletable: folder.deletable,
    renamable: folder.renamable,
    bookmanCockpitEnabled: folder.bookmanCockpitEnabled,
    auditProofUntil: folder.auditProofUntil,
    data: folder
  }
}
