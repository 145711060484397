import {IOrganizationLogo} from './organization-logo.interface';
import * as cmsModel from '../cms-slideshow/cms-slideshow';

export class OrganizationLogo implements IOrganizationLogo {
  readonly type = 'artifact_organization_logos';
  temporary = false;

  constructor(public id,
              public url: string,
              public current = false,
              public title: string,
              public organizationId: string,
              public inviterLogo = false) {
  }
}
