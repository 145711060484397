import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefStatusListComponent} from './fivef-status-list.component';
import {FivefStatusListItemComponent} from './fivef-status-list-item.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    FivefStatusListComponent,
    FivefStatusListItemComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule
  ],
  exports: [
    FivefStatusListComponent,
    FivefStatusListItemComponent
  ]
})
export class FivefStatusListModule {
}
