import {IApiRequest, IApiResourceBuilder, IApiResponse} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {IResource, IResourceCatalog} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export namespace ApiResourceBuilder {
  export function fromResponse<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, response: IApiResponse): M | M[] {
    const catalog: IResourceCatalog = {};
    // if (response.included) {
    //   // console.log('%c Includes: ', 'color: #ff8800', response.relationships);
    //   for (const resource of response.included) {
    //     if (!catalog[resource.type]) {
    //       catalog[resource.type] = [];
    //     }
    //     const model = ApiResourceBuilder._build(builder, resource);
    //     if (model) {
    //       catalog[resource.type].push(model);
    //     }
    //   }
    // }
    if (builder.addMetaSection) {
      if (response) {
        try {
          builder.addMetaSection(response.meta)
        } catch (err) {
          console.error('ERROR: ApiResourceBuilder: Failed to parse meta section: ', builder, response, err)
        }
      } else {
        console.error('ERROR: ApiResourceBuilder addMetaSection: Empty response', builder)
      }
    }

    if (builder.addIncludedSection && response) {
      if (response) {
        try {
          builder.addIncludedSection(response.included)
        } catch (err) {
          console.error('ERROR: ApiResourceBuilder: Failed to parse included section: ', builder, response, err)
        }
      } else {
        console.error('ERROR: ApiResourceBuilder addIncludedSection: Empty response', builder)
      }
    }

    try {
      if (response.data instanceof Array) {
        return <M[]>response.data.map((resource) => builder.fromResponse(resource, catalog));
      } else {
        return <M>builder.fromResponse(response.data, catalog);
      }
    } catch (err) {
      console.error('ERROR: ApiResourceBuilder: ', builder, response, err)
    }
  }

  export function toRequest<B extends IApiResourceBuilder<M>, M extends IResource>(builder: B, resource: M): IApiRequest {
    return builder.toRequest(resource);
  }
}
