import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from "./cache-froalo.state";

export const stateKey = 'cache-froala';
const getCacheFroalaState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getCacheFroalaEntities,
  selectAll: getAllCacheFroala,
} = adapter.getSelectors(getCacheFroalaState);


export const getOne = (id: string) => createSelector(
  getCacheFroalaEntities,
  (entities) => entities[id]
);

export const getAll = createSelector(
  getAllCacheFroala,
  (entities) => entities
);
