<div class="d-flex w-100 align-items-center justify-content-between" style="max-width: 500px;">
  <p class="h2">{{ 'PASSWORD.CHANGE_PASSWORD' | translate }}</p>
  <button *ngIf="editDisabled" mat-raised-button color="primary"
          (click)="editEnableDiable()">{{ 'GENERAL.EDIT_ACTION' | translate }}
  </button>
</div>
<div class="fivef-reset-password mt-2" *ngIf="!editDisabled">
  <form class="fivef-reset-password--form" [formGroup]="form">

    <!--    actual password field-->
    <label class="fivef-reset-password--label">{{ 'PASSWORD.ACTUAL' | translate }}</label>
    <div style="position: relative;">
      <input class="fivef-reset-password--password" [type]="currentPasswordVisbile"
             [formControlName]="'currentPassword'"
             (focus)="passwordConfirmatioShow(false);passwordShow(false);" required #currentPassword
             autocomplete="new-password">
      <mat-icon class="fivef-reset-password--password-icon" *ngIf="currentPasswordVisbile === fieldType.Password"
                (click)="currentPasswordShow(true)" [matTooltip]="'PASSWORD.VISIBLE' | translate">visibility
      </mat-icon>

      <mat-icon class="fivef-reset-password--password-icon" *ngIf="currentPasswordVisbile === fieldType.Text"
                (click)="currentPasswordShow(false)"
                [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate">visibility_off
      </mat-icon>
      <mat-error *ngIf="wrongPasswordError">{{ wrongPasswordError }}</mat-error>
    </div>

    <!--new password field-->
    <label class="fivef-reset-password--label">{{ 'PASSWORD.NEW' | translate }}</label>
    <div style="position: relative;">
      <input class="fivef-reset-password--password" [type]="passwordVisbile"
             [formControlName]="'newPassword'"
             (focus)="passwordConfirmatioShow(false);currentPasswordShow(false);" required
             (keyup)="verifyPassword()"
             autocomplete="new-password">
      <mat-icon class="fivef-reset-password--password-icon" (click)="passwordShow(true)"
                *ngIf="passwordVisbile === fieldType.Password"
                [matTooltip]="'PASSWORD.VISIBLE' | translate">visibility
      </mat-icon>

      <mat-icon class="fivef-reset-password--password-icon" (click)="passwordShow(false)"
                *ngIf="passwordVisbile === fieldType.Text"
                [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate">visibility_off
      </mat-icon>

      <mat-icon matTooltip="{{ 'PASSWORD.PASSWORD_POLICY' | translate }}"
                class="fivef-reset-password--password-icon-info">info
      </mat-icon>

    </div>
    <mat-error class="text-danger"
               *ngIf="form.get('newPassword').errors?.required && form.get('newPassword').touched">
      {{ 'PASSWORD.ENTER_PASSWORD' | translate }}
    </mat-error>
    <div class="fivef-reset-password--verify-password mb-1">
          <span *ngIf="verifyPasswordResponse && verifyPasswordResponse.level"
                [class]="verifyPasswordResponse.level">
          {{ verifyPasswordResponse.message | translate }}
      </span>
    </div>

    <!--new confirm password field-->
    <label class="fivef-reset-password--label">{{ 'PASSWORD.NEW_CONFIRMATION' | translate }}</label>
    <div style="position: relative;">
      <input class="fivef-reset-password--password" [type]="passwordConfirmationVisbile"
             [formControlName]="'newPasswordConfirmation'"
             (focus)="passwordShow(false);currentPasswordShow(false);" required
             autocomplete="new-password">
      <mat-icon *ngIf="!form.errors?.passwordsNoMatch && form.get('newPasswordConfirmation').touched"
                class="fivef-reset-password--password-icon-check">
        check
      </mat-icon>
      <mat-icon class="fivef-reset-password--password-icon" (click)="passwordConfirmatioShow(true)"
                *ngIf="passwordConfirmationVisbile === fieldType.Password"
                [matTooltip]="'PASSWORD.VISIBLE' | translate">visibility
      </mat-icon>

      <mat-icon class="fivef-reset-password--password-icon" *ngIf="passwordConfirmationVisbile === fieldType.Text"
                (click)="passwordConfirmatioShow(false)"
                [matTooltip]="'PASSWORD.VISIBILITY_OFF' | translate">visibility_off
      </mat-icon>


    </div>
    <mat-error class="text-danger" *ngIf="form.errors?.passwordsNoMatch">
      {{ 'PASSWORD.PASSWORDS_DONT_MATCH' | translate }}
    </mat-error>

    <!--    two factor authentication section -->
    <dvtx-two-factor-verification
      *ngIf="twoFactorAuthEnabled | async"
      [form]="form"
      [error]="wrongCodeError"
      class="mt-1">
    </dvtx-two-factor-verification>

    <div class="d-flex w-100 justify-content-end mt-2 align-items-center">
      <div>
        <button type="reset" mat-button color="warn" (click)="editEnableDiable()"
                class="wide">{{ 'GENERAL.CANCEL_ACTION' | translate }}
        </button>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="submit()"
                [disabled]="form.invalid">{{ 'PASSWORD.CHANGE_PASSWORD' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<!--password filed only for display-->
<div *ngIf="editDisabled" class="fivef-reset-password--display-fields fivef-reset-password--form mt-2">
  <label class="fivef-reset-password--label">{{ 'PASSWORD.ACTUAL' | translate }}</label>
  <input class="fivef-reset-password--password" matInput [placeholder]="'PASSWORD.ACTUAL' | translate" type="password"
         disabled value="1345645"
         type="password">
  <label class="fivef-reset-password--label">{{ 'PASSWORD.NEW' | translate }}</label>
  <input class="fivef-reset-password--password" matInput [placeholder]="'PASSWORD.NEW' | translate" value="1345645"
         type="password" disabled>
  <label class="fivef-reset-password--label">{{ 'PASSWORD.NEW_CONFIRMATION' | translate }}</label>
  <input class="fivef-reset-password--password" matInput [placeholder]="'PASSWORD.NEW_CONFIRMATION' | translate"
         value="1345645" type="password" disabled>
</div>

