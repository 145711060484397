import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CppApiService} from '../../services/cpp-api.service';
import {Store} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import * as OrganizationSelectors from '../organization/organization.selectors';
import {Organization} from '../organization/organization';
import {ContactOrganizationResponse,} from '../contact-organization/contact-organization';
import {of} from 'rxjs';
import {
  AssignContactPerson,
  AssignContactPersonSuccess,
  ContactPersonOrganizationAssociationActionTypes,
  ContactPersonOrganizationAssociationFail,
  LoadContactPersonOrganizationAssociations,
  LoadContactPersonOrganizationAssociationsSuccess,
  UnassignContactPerson,
  UnassignContactPersonSuccess
} from './contact-person-organization-association.actions';
import {
  ContactPersonOrganizationAssociationListReponse,
  ContactPersonOrganizationAssociationReponse,
  newCoPeOrAsfromResponse,
  newCoPeOrAsfromResponseList
} from './contact-person-organization-association';


@Injectable()
export class ContactPersonOrganizationAssociationEffects {
  loadContactAssociations = createEffect(() => this.actions.pipe(
    ofType(ContactPersonOrganizationAssociationActionTypes.LoadContactPersonOrganizationAssocitions),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    mergeMap(([action, orga]: [LoadContactPersonOrganizationAssociations, Organization]) => {
        return this.cppApiService
          .get<ContactPersonOrganizationAssociationListReponse>(
            'organization/' + orga.id +
            '/addressbook/' + orga.addressbookId +
            '/organizationcontacts/' + action.orgaContactId +
            '/member')
          .pipe(
            map((res: ContactPersonOrganizationAssociationListReponse) => {
              const loadedContact = newCoPeOrAsfromResponseList(res);
              return new LoadContactPersonOrganizationAssociationsSuccess(loadedContact);
            }),
            catchError((err) => {
              console.log(err);
              return of(new ContactPersonOrganizationAssociationFail(err))
            })
          )
      }
    )
  ));

  assignContactPerson = createEffect(() => this.actions.pipe(
    ofType(ContactPersonOrganizationAssociationActionTypes.AssignContactPerson),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, orga]: [AssignContactPerson, Organization]) => {
        return this.cppApiService
          .post<ContactPersonOrganizationAssociationReponse>(
            'organization/' + orga.id +
            '/addressbook/' + orga.addressbookId +
            '/organizationcontacts/' + action.contactOrgId +
            '/member',
            {
              id: action.contactPersonId
            }
          )
          .pipe(
            map((res: ContactPersonOrganizationAssociationReponse) => {
              const loadedContact = newCoPeOrAsfromResponse(res);
              return new AssignContactPersonSuccess(loadedContact);
            }),
            catchError(err => of(new ContactPersonOrganizationAssociationFail(err)))
          )
      }
    )
  ));

  unassignContactPerson = createEffect(() => this.actions.pipe(
    ofType(ContactPersonOrganizationAssociationActionTypes.UnassignContactPerson),
    withLatestFrom(this.store.select(OrganizationSelectors.getSelected).pipe(map(x => x))),
    switchMap(([action, orga]: [UnassignContactPerson, Organization]) => {
        return this.cppApiService
          .delete<ContactOrganizationResponse>(
            'organization/' + orga.id +
            '/addressbook/' + orga.addressbookId +
            '/organizationcontacts/' + action.payload.organizationContactId +
            '/member/' + action.payload.personContactId
          )
          .pipe(
            map((res) => {
              return new UnassignContactPersonSuccess(action.payload.id);
            }),
            catchError(err => of(new ContactPersonOrganizationAssociationFail(err)))
          )
      }
    )
  ));

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private store: Store<any>
  ) {
  }
}
