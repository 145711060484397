import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './user';

export namespace Tenant {
  export class UserStatsBuilder implements IApiResourceBuilder<model.Tenant.UserStats> {
    fromResponse(data): model.Tenant.UserStats {
      return new model.Tenant.UserStats(
        data.attributes.all,
        data.attributes.member_only,
        data.attributes.bp_only,
        data.attributes.cav_only
      );
    }

    toRequest(_: model.Tenant.UserStats) {
      return null;
    }
  }

  export class UserBuilder implements IApiResourceBuilder<model.Tenant.User> {
    total = 1;
    perPage = 1;
    records = 1;

    fromResponse(data): model.Tenant.User {
      const user = new model.Tenant.User(
        data.id,
        data.attributes.email,
        data.attributes.first_name,
        data.attributes.last_name,
        data.attributes.license,
        data.attributes.created_at,
        data.attributes.confirmed_at,
        data.attributes.locked_at,
      );
      user.lastSignInAt = data.attributes.last_sign_in_at;
      user.memberships = data.attributes.memberships;

      user.creatorEmail = data.attributes.creator_email;
      user.creatorName = data.attributes.creator_name;
      user.authProvider = data.attributes.auth_provider;

      user.total = this.total;
      user.perPage = this.perPage;
      user.records = this.records;

      user.hasOtp = data.attributes.otp_enabled;

      return user;
    }

    toRequest(user: model.Tenant.User) {
      return {
        data: {
          id: null,
          type: user.type,
          attributes: {
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            licence: !!user.license
          }
        }
      };
    }

    addMetaSection(meta: any) {
      if (!meta) return;
      this.total = meta.total;
      this.perPage = meta.per_page;
      this.records = meta.records;
    }
  }

  export class TenantAdminBuilder implements IApiResourceBuilder<model.Tenant.TenantAdmin> {
    fromResponse(data): model.Tenant.TenantAdmin {
      return new model.Tenant.TenantAdmin(
        data.id,
        data.attributes.email,
        data.attributes.user_id,
        data.attributes.first_name,
        data.attributes.last_name,
        data.attributes.created_at);
    }

    toRequest(org: model.Tenant.TenantAdmin) {
      return null
    }
  }
}
