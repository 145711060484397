import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export class MobileActivation implements IResource {
  readonly type = 'mobile_activations';

  enabled: boolean;
  id: string;

  constructor(
    id: string,
    enabled: boolean,
  ) {
    this.id = id;
    this.enabled = enabled;
  }
}
