<fivef-reaction class="fivef-reactions--button"
                (onReaction)="onReaction.emit($event)"
                [icon]="'thumb_up'"
                *ngIf="!reactions?.length"></fivef-reaction>

<div *ngFor="let r of reactions" class="d-flex">
  <ng-container [ngSwitch]="r.reaction">
    <fivef-reaction class="fivef-reactions--button fivef-reactions--blue"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'thumb_up'"
                    *ngSwitchCase="'like'"></fivef-reaction>
    <fivef-reaction class="fivef-reactions--button fivef-reactions--smiley"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'mood_bad'"
                    *ngSwitchCase="'angry'"></fivef-reaction>
    <fivef-reaction class="fivef-reactions--button fivef-reactions--smiley"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'sentiment_dissatisfied'"
                    *ngSwitchCase="'sad'"></fivef-reaction>
    <fivef-reaction class="fivef-reactions--button fivef-reactions--smiley"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'insert_emoticon'"
                    *ngSwitchCase="'laugh'"></fivef-reaction>
    <fivef-reaction class="fivef-reactions--button fivef-reactions--red"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'favorite'"
                    *ngSwitchCase="'heart'"></fivef-reaction>
    <fivef-reaction class="fivef-reactions--button fivef-reactions--smiley"
                    (onReaction)="onReaction.emit($event)"
                    [reaction]="r" [icon]="'sentiment_neutral'"
                    *ngSwitchCase="'surprised'"></fivef-reaction>
  </ng-container>
</div>
