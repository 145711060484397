import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CmsArticleActionTypes,
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess,
} from './cms-article.actions';
import {CmsArticle} from './cms-article';
import {CmsArticleService} from './cms-article.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class CmsArticleEffects {
  create$ = createEffect(() => this.actions.pipe(ofType(CmsArticleActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.article).pipe(
        first(),
        concatMap((article: CmsArticle) => {
          return [new CreateSuccess(article)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(CmsArticleActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.article).pipe(
        first(),
        switchMap((article: CmsArticle) => {
          return [new SaveSuccess(article)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(CmsArticleActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((article: CmsArticle) => {
          return [new LoadOneSuccess(article)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(CmsArticleActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: CmsArticle[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(CmsArticleActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.id).pipe(
        first(),
        concatMap((item: CmsArticle) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: CmsArticleService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
