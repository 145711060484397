import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {MilestoneType} from './milestone.interface';
import {Milestone} from './milestone';

export class MilestoneBuilder implements IApiResourceBuilder<Milestone> {
  constructor(private _processId: string = null) {}

  fromResponse(data): Milestone {
    const attrs = data.attributes;
    const milestone = new Milestone(
      data.id,
      attrs.parent_id,
      this._processId,
      attrs.title,
      attrs.pre_due_date,
      attrs.due_date,
      attrs.started_at,
      attrs.completed_at,
      attrs.created_at,
      attrs.updated_at
    );
    milestone.todayDiff = attrs.today_diff;
    milestone.formatedDueDate = attrs.date_pointed;
    milestone.dueAt = attrs.date_dashed;
    switch (data.type) {
      case 'milestone_tasks':
        milestone.type = MilestoneType.Task;
        milestone.icon = 'tasks';
        return milestone;
      case 'milestone_processes':
        milestone.type = MilestoneType.Process;
        milestone.icon = attrs.project ? 'process' : 'projectroom';
        return milestone;
      case 'milestone_appendix_nodes':
        milestone.type = MilestoneType.Appendix;
        milestone.icon = 'attach_file';
        return milestone;
      case 'document_milestone':
        milestone.type = MilestoneType.Document;
        milestone.icon = 'insert_drive_file';
        return milestone;
      case 'milestone_collector_nodes':
        milestone.type = MilestoneType.CollectorNode;
        milestone.icon = 'playlist_add_check';
        return milestone;
      default:
        console.error('Unknown milestone type: ', data);
        throw new Error('Unknown milestone type');
    }
  }

  toRequest(_: Milestone) {
    return null;
  }
}
