<fivef-dialog [isCallToAction]="true" [title]="'DMS.CREATE_FOLDER_ACTION'" [isUtilityDialog]="true">
  <div class="d-flex">
    <mat-form-field>
      <input matInput [(ngModel)]="folderName"
             [value]="'DMS.CREATE_FOLDER_ACTION' | translate"
             #creationField
             autofocus/>
    </mat-form-field>
  </div>

  <div fivef-dialog-footer>
    <button mat-button
            (click)="closeDialog()">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="!(creationField?.value?.length >= 1)"
            (click)="createFolder()">
      {{ 'GENERAL.CREATE_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
