import {Component, OnInit} from '@angular/core';
import {VersionCheckService} from 'app/services/version-check.service';
import {CommonModule} from '@angular/common';

/**
 * Helper component to show the current app's version.
 *
 * Located at HOST/#/version (see app routes).
 */
@Component({
  selector: 'fivef-app-version',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fivef-app-version.component.html',
  styleUrls: ['./fivef-app-version.component.scss']
})
export class FivefAppVersionComponent implements OnInit {
  versionHash: string;
  version: string;

  constructor(private _versionCheckSvc: VersionCheckService) {
    this.versionHash = this._versionCheckSvc.currentHash;
    this.version = this._versionCheckSvc.version;
  }

  ngOnInit() {
  }
}
