import {Component, Inject, OnInit} from '@angular/core';
import {ContactListDto} from 'app/+store/contact/contact';
import {ContactActions} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from '../../../../../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {first} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'dvtx-contact-deletion-dialog',
  templateUrl: './contact-deletion-dialog.component.html',
  styleUrls: ['./contact-deletion-dialog.component.scss']
})
export class ContactDeletionDialogComponent implements OnInit {
  submitOnGoing: boolean;
  forceContactDeletionByAdmin: boolean;
  lockContactForDeletion: boolean;
  deletionOngoing: boolean;
  isAdmin: boolean;
  contactToDelete: ContactListDto

  constructor(
    public dialogRef: MatDialogRef<ContactDeletionDialogComponent>,
    protected store: Store<AppState>,
    private _orgProxySvc: OrganizationProxyService,
    private _translateSvc: TranslateService,
    private _notifyService: FivefNotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.forceContactDeletionByAdmin = data.forceContactDeletionByAdmin;
    this.lockContactForDeletion = data.lockContactForDeletion;
    this.isAdmin = data.isAdmin;
    this.contactToDelete = data.contact;
  }

  ngOnInit() {
  }

  deleteContact() {
    if (!this.contactToDelete) {
      return;
    }
    if (this.lockContactForDeletion && !this.forceContactDeletionByAdmin) {
      return;
    }
    this.submitOnGoing = true;
    // Currently unsued: Remove from process is disapproved by company head
    if (this.forceContactDeletionByAdmin) {
      this.deletionOngoing = true;
      this._orgProxySvc.pruneContact(this.contactToDelete.email)
        .pipe(first())
        .subscribe(_org => {
          if (this.contactToDelete.key !== 'noContact') {
            this.store.dispatch(new ContactActions.DeleteContact(this.contactToDelete.id));
          } else {
            this.data.saveChanges();
          }
          this.dialogRef.close();
          this.submitOnGoing = false;
          this.deletionOngoing = false;
          this._notifyService.success('ADDRESSBOOK.CONTACT_DELETED')
        }, err => {
          console.error(err);
          this.submitOnGoing = false;
          this.deletionOngoing = false;
          this._notifyService.info('HTTP_ERROR.TRY_AGAIN')
        });
    } else {
      this.store.dispatch(new ContactActions.DeleteContact(this.contactToDelete.id));
      this.dialogRef.close();
      this.submitOnGoing = false;
    }
  }
}
