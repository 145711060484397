<ng-template [ngIf]="inProgress" [ngIfElse]="iconTpl">
  <mat-spinner class="fivef-button-spinner fivef-menu-item--icon"
               color="primary"
               diameter="22"></mat-spinner>
</ng-template>
<ng-template #iconTpl>
  <fivef-icon class="fivef-menu-item--icon"
              [icon]="icon"></fivef-icon>
</ng-template>

<div class="fivef-menu-item--content">
  <span class="fivef-menu-item--title"
        [matTooltip]="title | translate">{{title | translate}}</span>
  <span class="fivef-menu-item--subtitle"
        [matTooltip]="subtitle | translate">{{subtitle | translate}}</span>

  <span class="fivef-menu-item--subtitle">
    <ng-content select="[fivef-menu-item-footer]"></ng-content>
  </span>
</div>

<div *ngIf="hasSubItems">
  <button mat-icon-button [disableRipple]="true">
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
