import {first, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Store} from '@ngrx/store';
import {FivefNotificationService} from '../../../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {HttpClient} from '@angular/common/http';
import {CppApiService} from '../../../../services/cpp-api.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(
    private store: Store<any>,
    private _translateSvc: TranslateService,
    private notiSer: FivefNotificationService,
    private httpClient: HttpClient,
    private cppApiService: CppApiService
  ) { }

  public inviteBusinessPartner(email: string, disableTrial = false): Observable<any> {
    return this.httpClient.post(environment.token_service_config.apiBase + '/callbacks/registrations/invite', {
      data: {
        attributes: {
          invitation_type: 'business_partner',
          disable_trial: disableTrial,
          email
        }
      }
    }, {headers: this.cppApiService.setHeaders()}).pipe(
      take(1),
      tap(() => {
        // this.notiSer.horizontalPosition = 'center';
        // this.notiSer.verticalPosition = 'top';
        this.notiSer.success('INVITATIONS.INVITATION_SENT_SUCCESSFULLY')
      })
    );
  }
}
