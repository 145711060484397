import {throwError as observableThrowError, of as observableOf, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {IListingElement, ListingBuilder} from '../builders/listing.builder';
import {ApiErrorBuilder} from '../builders/api-error.builder';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from 'environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Listing} from 'app/lib/fivef-legacy/base-form-elements/components/1_control-value-accessor-components/api-select-cva/api-select-cva.component';

/**
 * Listing service using the API listing endpoints. Also provides static dropdown selects
 * used over the place.
 */
@Injectable()
export class ListingService {
  basePath;
  readonly BASE_PATH = 'listings/';

  constructor(private tokenService: HttpClient,
              private translateService: TranslateService) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/${this.BASE_PATH}`;
  }

  fetchListing(path: string): Observable<any> {
    switch (path) {
      case 'user_role':
        return observableOf(this.roleType());
      case 'gender':
        return observableOf(this.getGenderTitle());
      case 'priority':
        return observableOf(this.getPriorities());
      case 'email-type':
        return observableOf(this.emailType());
      case 'location-or-phone-type':
        return observableOf(this.locationOrPhoneType());
      case 'location-or-type':
        return observableOf(this.locationOrType());
      case 'typed_email-type':
        return observableOf(this.typed_emailType());
      case 'typed_location-or-phone-type':
        return observableOf(this.typed_locationOrPhoneType());
      case 'typed_location-or-type':
        return observableOf(this.typed_locationOrType());
      case 'currency':
        return observableOf(this.currencies());
      default:
        return this.fetchListingsData(path);
    }
  }

  roleType() {
    return ['Bearbeiter', 'Beobachter'];
  }

  getGenderTitle() {
    return [{
      name: 'CONTACTS.MR',
      value: 0
    }, {
      name: 'CONTACTS.MS',
      value: 1
    }];
  }

  getPriorities() {
    return [{
      name: 'undefiniert',
      value: 'undefined',
      color: 'grey'
    }, {
      name: 'sehr niedrig',
      value: 'lowest',
      color: ''
    }, {
      name: 'niedrig',
      value: 'low',
      color: '#ffcb00'
    }, {
      name: 'mittel',
      value: 'medium',
      color: '#ff8b62'
    }, {
      name: 'hoch',
      value: 'high',
      color: '#e2445c'
    }, {
      name: 'sehr hoch',
      value: 'highest',
      color: '#ec082b'
    }];
  }

  getStatus() {
    return [
      {
        status: 'initiated',
        readableStatus: 'Erstellt',
        icon: 'play_arrow',
        infoLevel: 'default'
      },
      {
        status: 'in_progress',
        readableStatus: 'In Bearbeitung',
        icon: 'timelapse',
        infoLevel: 'default'
      },
      {
        status: 'completed',
        readableStatus: 'Abgeschlossen',
        icon: 'check',
        infoLevel: 'success'
      },
      {
        status: 'delegated',
        readableStatus: 'Delegiert',
        icon: 'send',
        infoLevel: 'info'
      },
      {
        status: 'overdued',
        readableStatus: 'Überfällig',
        icon: 'access_time',
        infoLevel: 'error'
      },
      {
        status: 'rejected',
        readableStatus: 'Abgelehnt',
        icon: 'cancel',
        infoLevel: 'error'
      },
      {
        status: 'canceled',
        readableStatus: 'Abgebrochen',
        icon: 'cancel',
        infoLevel: 'error'
      }]
  }

  order_by() {
    // TODO: Change name to translate string
    return [
      {name: 'Created At', value: 'created_at'},
      {name: 'updated_at', value: 'updated_at'},
      {name: 'due_date', value: 'due_date'},
      {name: 'priority', value: 'priority'},
      {name: 'status', value: 'status'}
    ];
  }

  currencies() {
    return ['USD', 'EUR']
  }

  emailType() {
    if (this.translateService.currentLang === 'de') {
      return ['Geschäftlich', 'Privat', 'Benutzerdefiniert'];
    } else {
      return ['Business', 'Private', 'Custom'];
    }
  }

  locationOrPhoneType() {
    if (this.translateService.currentLang === 'de') {
      return ['Mobil', 'Privat', 'Fax'];
    } else {
      return ['Mobile', 'Private', 'Fax'];
    }
  }

  locationOrType() {
    if (this.translateService.currentLang === 'de') {
      return ['Geschäftlich', 'Privat', 'Benutzerdefiniert'];
    } else {
      return ['Business', 'Private', 'Custom'];
    }
  }

  typed_emailType(): Listing[] {
    if (this.translateService.currentLang === 'de') {
      return [
        {name: 'Geschäftlich', value: '0'},
        {name: 'Privat', value: '1'},
        {name: 'Benutzerdefiniert', value: '999'},
      ];
    } else {
      return [
        {name: 'Business', value: '0'},
        {name: 'Private', value: '1'},
        {name: 'Custom', value: '999'},
      ];
    }
  }

  typed_locationOrPhoneType(): Listing[] {
    if (this.translateService.currentLang === 'de') {
      return [
        {name: 'Mobil', value: '0'},
        {name: 'Privat', value: '1'},
        {name: 'Fax', value: '2'}
      ];
    } else {
      return [
        {name: 'Mobile', value: '0'},
        {name: 'Private', value: '1'},
        {name: 'Fax', value: '2'}
      ];
    }
  }

  typed_locationOrType(): Listing[] {
    if (this.translateService.currentLang === 'de') {
      return [
        {name: 'Geschäftlich', value: '0'},
        {name: 'Privat', value: '1'},
        {name: 'Benutzerdefiniert', value: '999'},
      ];
    } else {
      return [
        {name: 'Business', value: '0'},
        {name: 'Private', value: '1'},
        {name: 'Custom', value: '999'},
      ];
    }
  }

  fetchListingsData(path: string): Observable<IListingElement[]> {
    return this.tokenService.get(`${this.basePath}${path}`).pipe(
      map((res: any) => res.data.map(item => ListingBuilder.createListingElementFrom(item))),
      catchError(this.handleError),);
  }

  getLastUpdated(path: string): Observable<any> {
    return this.tokenService.get(`${this.basePath}${path}/last_updated`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError),);
  }

  getListingDataByUserInputs(path: string, userInputs: string) {
    if (userInputs && typeof userInputs === 'string') {
      return this.tokenService.get(`${this.basePath}${path}?search=${userInputs}`).pipe(
        map((res: any) => res.data.map(item => ListingBuilder.createListingElementFrom(item))),
        catchError(this.handleError),);
    } else {
      return observableOf([]);
    }
  }

  private handleError(error: HttpErrorResponse) {
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }

  getMainColors() {
    return [
      {
        color: 'success',
        class: 'success',
        value: '#00d200'
      },
      {
        color: 'warning',
        class: 'warning',
        value: '#ff8800'
      },
      {
        color: 'danger',
        class: 'danger',
        value: 'var(--fivef-color-warn)'
      },
      {
        color: 'action',
        class: 'action',
        value: '#0062cc'
      },
      {
        color: 'five-blue',
        class: 'five-blue',
        value: '#7b8490'
      }
    ];
  }
}
