import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum DocumentPreviewPreviewState {
  Initiated = 'initiated',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failure = 'failure',
  Error = 'error',
  Deleted = 'deleted',
  Expired = 'expired'
}

export interface IDocumentPreviewPreview extends IResource {
  page: number;
  state: DocumentPreviewPreviewState;
  content?: string;
  mimeType?: string;
  url?: string;
  documentId?: string;
  width?: number;
  height?: number;
}
