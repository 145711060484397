import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';

@Component({
  selector: 'dvtx-signature-box',
  templateUrl: './signature-box.component.html',
  styleUrls: ['./signature-box.component.scss']
})
export class SignatureBoxComponent implements OnInit {

  @Input() boxWidth = 640;
  @Input() boxHeight = 120;
  @Input() lineJoin = 'round';
  @Input() strokeStyle = '#000000';
  @Input() lineWidth = 3;
  @Output() onConfirmSignature = new EventEmitter<string>();
  @ViewChild('canvasRef', { static: true }) canvasRef: ElementRef;
  canvasElement: HTMLCanvasElement;
  canvasContext;
  painting = false;
  clickX: number[] = [];
  clickY: number[] = [];
  clickDrag: boolean[] = [];

  constructor(private _store: Store<AppState>) {
  }

  ngOnInit() {
    this.canvasElement = this.canvasRef.nativeElement;
    this.canvasContext = this.canvasElement.getContext('2d');
  }

  touchStart($event) {
    const canvasPositions = this.canvasElement.getBoundingClientRect();
    const mouseX = ($event.changedTouches ? $event.changedTouches[0].pageX : $event.pageX) - canvasPositions.left;
    const mouseY = ($event.changedTouches ? $event.changedTouches[0].pageY : $event.pageY) - canvasPositions.top;

    this.painting = true;
    this.addClick(mouseX, mouseY);
    this.redraw();
    $event.preventDefault()
  }

  mouseDown($event: MouseEvent) {
    const canvasPositions = this.canvasElement.getBoundingClientRect();
    const mouseX = $event.pageX - canvasPositions.left;
    const mouseY = $event.pageY - canvasPositions.top;

    this.painting = true;
    this.addClick(mouseX, mouseY);
    this.redraw();
  }

  mouseMove($event: MouseEvent) {
    const canvasPositions = this.canvasElement.getBoundingClientRect();
    if (this.painting) {
      const mouseX = $event.pageX - canvasPositions.left;
      const mouseY = $event.pageY - canvasPositions.top;

      this.addClick(mouseX, mouseY, true);
      this.redraw();
    }
  }

  touchMove($event) {
    const canvasPositions = this.canvasElement.getBoundingClientRect();
    if (this.painting) {
      const mouseX = ($event.changedTouches ? $event.changedTouches[0].pageX : $event.pageX) - canvasPositions.left;
      const mouseY = ($event.changedTouches ? $event.changedTouches[0].pageY : $event.pageY) - canvasPositions.top;

      this.addClick(mouseX, mouseY, true);
      this.redraw();
    }
  }

  stopPainting() {
    this.painting = false;
  }

  redraw() {
    this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height); // Clears the canvas
    this.canvasContext.strokeStyle = this.strokeStyle;
    this.canvasContext.lineJoin = this.lineJoin;
    this.canvasContext.lineWidth = this.lineWidth;

    for (let i = 0; i < this.clickX.length; i++) {
      this.canvasContext.beginPath();
      if (this.clickDrag[i] && i) {
        this.canvasContext.moveTo(this.clickX[i - 1], this.clickY[i - 1]);
      } else {
        this.canvasContext.moveTo(this.clickX[i] - 1, this.clickY[i]);
      }
      this.canvasContext.lineTo(this.clickX[i], this.clickY[i]);
      this.canvasContext.closePath();
      this.canvasContext.stroke();
    }
  }

  private addClick(x: number, y: number, dragging = false) {
    this.clickX.push(x);
    this.clickY.push(y);
    this.clickDrag.push(dragging);
  }

  public clearCanvas() {
    this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height); // Clears the canvas
    this.clickDrag = [];
    this.clickX = [];
    this.clickY = [];
  }

  public confirmSignature(): string {
    const base64Image = this.canvasElement.toDataURL('type/png');
    this.onConfirmSignature.emit(base64Image);
    return base64Image;
  }

  changeWidth($event: number) {
    this.lineWidth = $event;
    this.redraw();
  }
}
