import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MobileActivation} from './mobile-activation';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {MobileActivationBuilder} from './mobile-activation.builder';

@Injectable({
  providedIn: 'root'
})
export class MobileActivationService {
  readonly BASE_PATH = 'api/v3/organizations';

  constructor(private http: FivefApiResourceService) {
  }

  public status(email: string): Observable<MobileActivation> {
    const builder = new MobileActivationBuilder();
    const encodedEmail = encodeURIComponent(email);
    return <Observable<MobileActivation>>this.http.get<MobileActivationBuilder, MobileActivation>(builder, `${this.BASE_PATH}/mobile_activations/status?email=${encodedEmail}`);
  }

  public activate(email: string): Observable<MobileActivation> {
    const builder = new MobileActivationBuilder();
    const encodedEmail = encodeURIComponent(email);
    return <Observable<MobileActivation>>this.http.post<MobileActivationBuilder, MobileActivation>(builder, `${this.BASE_PATH}/mobile_activations/enable?email=${encodedEmail}`, {});
  }
}
