import {IProject} from './project.interface';
import {Process, ProcessStatus} from '../process/process';
import {Client} from '../client/client';
import {ProcessProfile} from '../process/process.interface';

export class Project implements IProject {
  readonly type = 'workflow_engine_projects';
  client: Client;
  profile: ProcessProfile;
  organizationId: string;
  dmsAccountType?: 'organization' | 'private';

  constructor(public id: string,
              public parentId: string,
              public title: string,
              public identifier: string,
              public referenceId: string,
              public description: string,
              public state: string,
              public creatorName: string,
              public creatorEmail: string,
              public ownerName: string,
              public ownerEmail: string,
              public dmsFolderId: string,
              public dueDate: Date,
              public createdAt: Date,
              public updatedAt: Date,
              public color: string = null) {
  }

  clone() {
    return Project.clone(this);
  }

  static asProcess(project): Process {
    const p =  new Process(
      project.id,
      project.title,
      null,
      project.description,
      project.dueDate,
      project.createdAt,
      'workflow_engine_projects',
      project.state,
      project.updatedAt);
    if (project.client) {
      p.client = project.client;
    }
    if (project.state) {
      p.status = new ProcessStatus(project.state)
    }
    if (project.parentId) {
      p.parentId = project.parentId;
    }
    p.profile = project.profile;
    return p;
  }

  static clone(project) {
    return new Project(
      project.id,
      project.parentId,
      project.title,
      project.identifier,
      project.referenceId,
      project.description,
      project.state,
      project.ownerName, project.ownerEmail,
      project.creatorName, project.creatorEmail,
      project.dmsFolderId,
      project.dueDate,
      project.createdAt, project.updatedAt
    );
  }
}
