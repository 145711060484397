<form class="fivef-contact-autocomplete--form">
  <mat-form-field>
    <input type="text"
           [placeholder]="placeholder | translate"
           matInput
           [disabled]="disabled"
           [formControl]="myControl"
           (keyup.enter)="onKeyEnter()"
           [matAutocomplete]="auto"
           (blur)="onBlurEventHandler()"
           (click)="triggerFocus($event)"
           (focus)="triggerFocus($event)">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="selectContact($event)">
      <cdk-virtual-scroll-viewport *ngIf="(filteredContacts$ | async)?.length > 0"
                                   style="height: 240px;"
                                   [itemSize]="30">
        <mat-option style="width: 100%;" *cdkVirtualFor="let option of filteredContacts$ | async" [value]="option">
          <fivef-avatar [size]="'xs'"
                        [email]="option"
                        [isVerified]="option?.hasAccount"
                        [showTitle]="true"
                        [showEmail]="showEmail"></fivef-avatar>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</form>
