import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CollectorExport} from './collector-export';
import {CollectorExportResponse} from './collector-export.interface';

export class CollectorExportBuilder implements IApiResourceBuilder<CollectorExport> {
  fromResponse(data: CollectorExportResponse): CollectorExport {
    return new CollectorExport(
      data.id,
      data.attributes
    )
  }

  toRequest(data: CollectorExport) {
    return {
      data: {
        type: data.type,
        attributes: data.attributes
      }
    };
  }
}
