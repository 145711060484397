import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import {FivefPageHeaderComponent} from './fivef-page-header.component';
import {FivefLocaleSwitcherComponent} from './fivef-locale-switcher/fivef-locale-switcher.component';
import {OrganizationBannerElementComponent} from 'app/modules/organization/banner-element/banner-element.component';
import {FivefNotificationBellComponent} from './fivef-notification-bell/fivef-notification-bell.component';
import {FivefPageHeaderLogoComponent} from './fivef-page-header-logo/fivef-page-header-logo.component';
import {FivefPageHeaderSearchResultsComponent} from './fivef-page-header-search-results/fivef-page-header-search-results.component';
import {InViewportModule} from 'ng-in-viewport';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {FivefAvatarModule} from '../../profile/fivef-avatar/fivef-avatar.module';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {FivefProcessIconComponent} from '../../process/fivef-process-icon/fivef-process-icon.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    InViewportModule,
    InViewportModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FivefAvatarModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    FivefProcessIconComponent,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    FivefIconMessageBlockComponent,
    FivefLoadingIndicatorComponent,
    FivefSearchComponent
  ],
  declarations: [
    FivefPageHeaderComponent,
    FivefLocaleSwitcherComponent,
    OrganizationBannerElementComponent,
    FivefNotificationBellComponent,
    FivefPageHeaderLogoComponent,
    FivefPageHeaderSearchResultsComponent
  ],
  exports: [
    FivefPageHeaderComponent,
    FivefLocaleSwitcherComponent,
    FivefPageHeaderLogoComponent
  ]
})
export class FivefPageHeaderModule {
}
