<div class="fivef-filter-listing--header">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</div>

<div class="fivef-filter-listing--container">
  <cdk-virtual-scroll-viewport *ngIf="(filterdOptions$ | async)?.length"
                               style="height: 280px;" [itemSize]="20" autosize>
    <ng-container *cdkVirtualFor="let option of filterdOptions$ | async">
      <ng-container *ngIf="option.id && (option.name || option.firstName || option.email)">
        <button type="button"
                class="fivef-filter-listing--item w-100"
                [matTooltip]="option.name || (option.firstName + ' ' + option.lastName) || option.email"
                [class.active]="isSelected(option)"
                (click)="changeSelection(option)" [disabled]="loading">
          <span>{{ option.name || (option.firstName + ' ' + option.lastName) || option.email }}</span>
        </button>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
