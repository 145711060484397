import {ProcessActionActions, ProcessActionActionTypes} from './process-action.actions';
import {adapter, initialState, State} from './process-action.state';

export function reducer(state = initialState, action: ProcessActionActions): State {
  switch (action.type) {
    case ProcessActionActionTypes.Run:
      return {
        ...state,
        loading: true
      };
    case ProcessActionActionTypes.RunSuccess:
      return {
        ...state,
        loading: false,
      };
    case ProcessActionActionTypes.RunFail:
      return {
        ...state,
        loading: false
      };
  }
  return state;
}

