import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './user.interface';
import * as model from './user';
import * as build from './user.builder';
import * as userModel from './user';
import * as userBuilder from './user.builder';

@Injectable()
export class TenantUserService {
  readonly BASE_PATH = 'api/v3/tenants/entities';
  readonly TENANT_BASE_PATH = 'api/v3/tenants';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(tenantId: string, params: modelInterface.Tenant.IUserGetAllParams): Observable<model.Tenant.User[]> {
    const page = params.page;
    const builder = new build.Tenant.UserBuilder();
    let query = `?all=${!!params.all}`;
    if (page && page > 0) {
      query = `${query}&page=${params.page}`
    }

    if (params.lockedOnly) {
      query = `${query}&locked_only=${!!params.lockedOnly}`
    }

    if (params.query && params.query.length > 0) {
      query = `${query}&q=${params.query}`
    }
    return <Observable<model.Tenant.User[]>>this._http.get<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users${query}`);
  }

  getOne(tenantId: string, id: string): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    return <Observable<model.Tenant.User>>this._http.get<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${id}`);
  }

  createUserAccount(tenantId: string, user: model.Tenant.User): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = builder.toRequest(user);
    return <Observable<model.Tenant.User>>this._http.post<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users`, payload);
  }

  createMembership(tenantId: string, organizationId: string, userId: string): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = {
      data: {
        attributes: {
          organization_id: organizationId
        }
      }
    };
    return <Observable<model.Tenant.User>>this._http.post<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users/${userId}/memberships`, payload);
  }

  revokeMembership(tenantId: string, userId: string, membershipId: string): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    return <Observable<model.Tenant.User>>this._http.del<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users/${userId}/memberships/${membershipId}`);
  }

  toggleMembershipRole(tenantId: string, organizationId: string, membershipId: string, role: string, enable: boolean): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = {
      data: {
        attributes: {
          enable: enable
        }
      }
    }
    return <Observable<model.Tenant.User>>this._http.put<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}/memberships/${membershipId}/${role}`, payload);
  }

  updateWorkflowCreationPermissions(tenantId: string, organizationId: string, membershipId: string, options: { 'enable_workflow': boolean; 'enable_sub_workflow': boolean }): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = {
      data: {
        attributes: {
          enable_workflow: options.enable_workflow,
          enable_sub_workflow: options.enable_sub_workflow
        }
      }
    }
    return <Observable<model.Tenant.User>>this._http.put<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}/memberships/${membershipId}/process_creation_support`, payload);
  }

  getUserWithDetailedMembership(tenantId: string, organizationId: string, membershipId: string): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    return <Observable<model.Tenant.User>>this._http.get<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}/memberships/${membershipId}`);
  }

  update(user: model.Tenant.User): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = builder.toRequest(user);
    return <Observable<model.Tenant.User>>this._http.put<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${user.id}`, payload);
  }

  destroy(userId: string): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    return <Observable<model.Tenant.User>>this._http.del<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${userId}`);
  }

  getStats(startAt: Date = null) {
    const builder = new build.Tenant.UserStatsBuilder();
    const payload = { data: { attributes: { start_at: startAt } } };
    return <Observable<model.Tenant.UserStats>>this._http.post<build.Tenant.UserStatsBuilder, model.Tenant.UserStats>(builder, 'operator/api/v1/user/stats', payload);
  }

  toggleLicense(tenantId: string, userId: string, enable: boolean): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = {
      data: {
        attributes: {
          enable_licence: enable
        }
      }
    };
    return <Observable<model.Tenant.User>>this._http.put<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users/${userId}/license`, payload);
  }

  toggleUserAccountLock(tenantId: string, userId: string, enable: boolean): Observable<model.Tenant.User> {
    const builder = new build.Tenant.UserBuilder();
    const payload = {
      data: {
        attributes: {
          lock_account: enable
        }
      }
    };
    return <Observable<model.Tenant.User>>this._http.put<build.Tenant.UserBuilder, model.Tenant.User>(builder, `${this.BASE_PATH}/${tenantId}/users/${userId}/lock`, payload);
  }

  getAllTenantAdmins(tenantId): Observable<model.Tenant.TenantAdmin[]> {
    const builder = new build.Tenant.TenantAdminBuilder();
    return <Observable<model.Tenant.TenantAdmin[]>>this._http.get<build.Tenant.TenantAdminBuilder, model.Tenant.TenantAdmin>(builder, `${this.BASE_PATH}/${tenantId}/tenant_admins`);
  }

  createTenantAdmin(tenantId, userId: string): Observable<model.Tenant.TenantAdmin> {
    const builder = new build.Tenant.TenantAdminBuilder();
    const payload = {
      data: {
        attributes: {
          user_id: userId
        }
      }
    }
    return <Observable<model.Tenant.TenantAdmin>>this._http.post<build.Tenant.TenantAdminBuilder, model.Tenant.TenantAdmin>(builder, `${this.BASE_PATH}/${tenantId}/tenant_admins`, payload);
  }

  deleteTenantAdmin(tenantId, adminId: string): Observable<model.Tenant.TenantAdmin> {
    const builder = new build.Tenant.TenantAdminBuilder();
    return <Observable<model.Tenant.TenantAdmin>>this._http.del<build.Tenant.TenantAdminBuilder, model.Tenant.TenantAdmin>(builder, `${this.BASE_PATH}/${tenantId}/tenant_admins/${adminId}`);
  }

  getTenantContentAdmins(): Observable<userModel.Tenant.TenantAdmin[]> {
    const builder = new userBuilder.Tenant.TenantAdminBuilder();
    return <Observable<userModel.Tenant.TenantAdmin[]>>this._http.get<userBuilder.Tenant.TenantAdminBuilder, userModel.Tenant.TenantAdmin>(builder, `${this.TENANT_BASE_PATH}/tenant_content_admins`);
  }

  getTenantContentAdminCandidates(): Observable<userModel.Tenant.User[]> {
    const builder = new userBuilder.Tenant.UserBuilder();
    return <Observable<userModel.Tenant.User[]>>this._http.get<userBuilder.Tenant.UserBuilder, userModel.Tenant.User>(builder, `${this.TENANT_BASE_PATH}/tenant_content_admins/candidates`);
  }

  grantTenantContentAdmin(email: string): Observable<userModel.Tenant.TenantAdmin> {
    const builder = new userBuilder.Tenant.TenantAdminBuilder();
    const payload = {
      data: {
        attributes: {
          email: email
        }
      }
    }
    return <Observable<userModel.Tenant.TenantAdmin>>this._http.post<userBuilder.Tenant.TenantAdminBuilder, userModel.Tenant.TenantAdmin>(builder, `${this.TENANT_BASE_PATH}/tenant_content_admins`, payload);
  }

  revokeTenantContentAdmin(roleId: string): Observable<userModel.Tenant.TenantAdmin> {
    const builder = new userBuilder.Tenant.TenantAdminBuilder();
    return <Observable<userModel.Tenant.TenantAdmin>>this._http.del<userBuilder.Tenant.TenantAdminBuilder, userModel.Tenant.TenantAdmin>(builder, `${this.TENANT_BASE_PATH}/tenant_content_admins/${roleId}`);
  }
}
