import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'fivef-status-list',
  host: {class: 'fivef-status-list'},
  templateUrl: './fivef-status-list.component.html',
  styleUrls: ['./fivef-status-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefStatusListComponent {

}
