<div class="fivef-mat-table">
  <mat-table [dataSource]="filteredLabels">
    <ng-container matColumnDef="labelCol">
      <mat-header-cell *matHeaderCellDef>{{ 'LABELS.LABEL' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <fivef-label [label]="element" [readonly]="true"></fivef-label>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="descriptionCol">
      <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate" [innerHTML]="element.description | translate"></div>
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="canEdit || canDelete">
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button *ngIf="canEdit" [matTooltip]="'GENERAL.EDIT_ACTION' | translate"
                  (click)="editLabel(element)">
            <mat-icon>create</mat-icon>
          </button>
          <button mat-icon-button *ngIf="canDelete" [matTooltip]="'GENERAL.DELETE_ACTION' | translate"
                  (click)="deleteLabel(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="canEdit || canDelete">
      <mat-header-row *matHeaderRowDef="['labelCol', 'descriptionCol', 'actions']; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['labelCol', 'descriptionCol', 'actions']"></mat-row>
    </ng-container>

    <ng-container *ngIf="!canEdit && !canDelete">
      <mat-header-row *matHeaderRowDef="['labelCol', 'descriptionCol']; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['labelCol', 'descriptionCol']"></mat-row>
    </ng-container>

  </mat-table>
</div>
