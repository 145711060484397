import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum PartnerLinkVisibility {
  'Incoming' = 'incoming',
  'Visible' = 'visible',
  'Hidden' = 'hidden'
}

export enum PartnerLinkConnectionStatus {
  New = 'New',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending'
}

export interface IPartnerLink extends IResource {
  organizationId: string;
  title: string;
  description: string;
  url: string;
  fgColor: string;
  bgColor: string;
  iconLink: string;
  visibility: PartnerLinkVisibility;
  publishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPartnerLinkRecipient extends IResource {
  email: string;
  firstName?: string;
  lastName?: string;
  name: string;
  linkId?: string;
  status: PartnerLinkConnectionStatus
}
