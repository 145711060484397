import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'fivef-notification-bell',
  host: {class: 'fivef-notification-bell'},
  templateUrl: './fivef-notification-bell.component.html',
  styleUrls: ['./fivef-notification-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefNotificationBellComponent {
  @Input() unreadCount = null;
}

