import {Observable, of as observableOf} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ExternalAccessService} from '../../../+store/_legacy/api/services/external-access.service';
import * as processContextActions from '../actions/process-context.actions';
import {ProcessContext} from '../../../+store/_legacy/api/models/process-context';
import {WorkflowEngineService} from '../../../+store/_legacy/api/services/workflow-engine.service';


@Injectable()

export class ProcessContextEffects {

  /**
   * Validate the access token and retrieve the current process context for
   * further actions.
   *
   * @type {Observable<ValidateAccessSuccess>}
   */
  validateExternalAccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.VALIDATE_ACCESS),
      switchMap((action: processContextActions.ValidateAccess) => {
        return this._externalAccessSvc.validate(action.payload).pipe(
          map((context: ProcessContext) => {
            // Run commands in validation hooks and return clone of context as new state
            // without executed commands.
            const validationQueue = context.runCommands('after_validation');
            const newContext = context.clone();
            newContext.setQueue('after_validation', validationQueue);
            return new processContextActions.ValidateAccessSuccess(newContext);
          }),
          catchError(error => {
            this._router.navigate(['/access/external/form/not_found']);
            return observableOf(error);
          }),);
      })));

  /**
   * Get the current process state/context in the context of an external user.
   * @type {Observable<GetExternalProcessContextSuccess>}
   */
  getExternalProcessContext$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.GET_EXTERNAL_PROCESS_CONTEXT),
      switchMap((action: processContextActions.GetExternalProcessContext) => {
        return this._externalAccessSvc.get(action.payload);
      }),
      map((context: ProcessContext) => {
        return new processContextActions.GetExternalProcessContextSuccess(context);
      })));

  /**
   * Update the process state based on the ExternalAccessService.
   *
   * @type {Observable<UpdateExternalProcessContextSuccess>}
   */
  updateExternalProcessContext$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.UPDATE_EXTERNAL_PROCESS_CONTEXT),
      switchMap((action: processContextActions.UpdateExternalProcessContext) => {
        return this._externalAccessSvc.update(action.payload.token, action.payload.context);
      }),
      map((context: ProcessContext) => {
        // Run commands in after_submit hook and return clone of context as new state
        // without executed commands.
        const commandQueue = context.runCommands('after_submit');
        const newContext = context.clone();
        newContext.setQueue('on_init', commandQueue);
        return new processContextActions.UpdateExternalProcessContextSuccess(context);
      })));

  /**
   * Update the process state based on the ExternalAccessService.
   *
   * @type {Observable<UpdateExternalProcessContextSuccess>}
   */
  updateProcessContext$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.UPDATE_PROCESS_CONTEXT),
      switchMap((action: processContextActions.UpdateProcessContext) => {
        return this._workflowEngineSvc.update(action.payload.id, action.payload.context);
      }),
      map((context: ProcessContext) => {
        // Run commands in after_submit hook and return clone of context as new state
        // without executed commands.
        const commandQueue = context.runCommands('after_submit');
        const newContext = context.clone();
        newContext.setQueue('on_init', commandQueue);
        return new processContextActions.UpdateProcessContextSuccess(context);
      })));

  /**
   * Get the current process state/context in the context of an authenticated user.
   * @type {Observable<GetExternalProcessContextSuccess>}
   */
  getProcessContext$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.GET_PROCESS_CONTEXT),
      switchMap((action: processContextActions.GetProcessContext) => {
        return this._workflowEngineSvc.getProcess(action.payload);
      }),
      map((context: ProcessContext) => {
        return new processContextActions.GetProcessContextSuccess(context);
      })));

  /**
   * Get the current process state/context in the context of an authenticated user.
   * @type {Observable<GetProcessContextDraftsOnlySuccess>}
   */
  getProcessContextsDraftsOnly$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.GET_PROCESS_CONTEXTS_DRAFTS_ONLY),
      switchMap((action: processContextActions.GetProcessContextDraftsOnly) => {
        return this._workflowEngineSvc.getAll(action.payload);
      }),
      map((contexts: any) => {
        return new processContextActions.GetProcessContextDraftsOnlySuccess(contexts.processes);
      })));

  /**
   * Get the current process state/context in the context of an authenticated user.
   * @type {Observable<GetExternalProcessContextsSuccess>}
   */
  getProcessContexts$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.GET_PROCESS_CONTEXTS),
      switchMap((action: processContextActions.GetProcessContexts) => {
        return this._workflowEngineSvc.getAll(action.payload);
      }),
      map((context: any) => {
        return new processContextActions.GetProcessContextsSuccess(context.processes);
      })));

  /**
   * Run the commands of the given event queue hook.
   *
   * Example:
   *
   * new processContextActions.RunCommand({
   *   context: context,
   *   hook: 'after-validation'
   * })
   *
   * @type {Observable<RunCommandSuccess>}
   */
  runCommand$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(processContextActions.RUN_COMMAND),
      switchMap((action: processContextActions.RunCommand) => {
        return observableOf({
          queue: action.payload.context.runCommands(action.payload.hook),
          hook: action.payload.hook
        });
      }),
      map(commands => {
        return new processContextActions.RunCommandSuccess(commands);
      })));

  constructor(private _actions$: Actions,
              private _workflowEngineSvc: WorkflowEngineService,
              private _externalAccessSvc: ExternalAccessService,
              private _router: Router) {
  }
}
