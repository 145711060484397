import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {first, takeUntil} from 'rxjs/operators';
import {ProcessOrganizationSelectorService} from 'app/+store/process/process-organization-selector.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ProcessSelectors} from 'app/+store';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {PreviewBrowserViewmode} from '../containers/preview-browser/fivef-artifact-preview-browser.component';

export enum PreviewViewType {
  Icon = 'Icon',
  IconButton = 'IconButton',
  ListItem = 'ListItem',
  Custom = 'Custom'
}

@Component({
  selector: 'fivef-artifact-preview-dialog',
  templateUrl: './fivef-artifact-preview-dialog.component.html',
  styleUrls: ['./fivef-artifact-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefArtifactPreviewDialogComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public readonly PreviewViewType = PreviewViewType;

  @Input()
  appearance: PreviewViewType = PreviewViewType.IconButton;

  @Input()
  processId: string;

  @Input()
  viewMode = PreviewBrowserViewmode.Document;

  @Input()
  disabled = true;

  isMember$: Observable<boolean>;

  @Input()
  documentIds: string[] = [];

  @Input()
  documentId: string = null;

  @Input()
  lockedProcess = true;

  @Input()
  enableTaskCreation = true;

  @Input()
  selectedDocumentPreviewTab = 0;

  @Output()
  onCloseDialogue = new EventEmitter();

  @Output()
  onCreateTask = new EventEmitter();

  @ViewChild('previewBrowserTpl', {static: true})
  private previewBrowserTpl: TemplateRef<any>;
  private dialogRef: MatDialogRef<TemplateRef<any>>;

  private isExtraSmall: Observable<BreakpointState>;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _processOrganizationService: ProcessOrganizationSelectorService,
              private _breakpointObserver: BreakpointObserver) {
    this.isExtraSmall = this._breakpointObserver.observe(
      Breakpoints.XSmall
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  adjustFullScreen(value) {
    if (value) {
      this.dialogRef.updateSize('100%', '100%');
      this.dialogRef.addPanelClass('fivef-dialogue-fullScreen');
    } else {
      this.dialogRef.updateSize('80vw', '');
      this.dialogRef.removePanelClass('fivef-dialogue-fullScreen');
    }
  }

  openDialog() {
    // console.log(this.disabled)
    if (this.disabled) {
      console.error('The preview dialog is disabled. Exit...')
      return;
    }

    this._store.select(ProcessSelectors.getProcessById(this.processId))
      .pipe(first()).subscribe(process => {
      if (process) {
        this._processOrganizationService.select(process);
      }
    });
    this.isMember$ = this._processOrganizationService.isMember$();
    this.dialogRef = this._dialog.open(this.previewBrowserTpl, {
      panelClass: ['five-f-transparent-background-w100-max-width', 'five-f-dialog-border-color'],
      width: '80vw',
      maxWidth: 'unset'
    });

    const smallDialogSubscription = this.isExtraSmall.pipe(takeUntil(this.onDestroy)).subscribe(size => {
      if (size.matches) {
        this.dialogRef.updateSize('100%', '100%');
      } else {
        this.dialogRef.updateSize('80vw', '');
      }
    });
    this.dialogRef.afterClosed().subscribe(() => {
      if (smallDialogSubscription) smallDialogSubscription.unsubscribe();
    });
    this.dialogRef.beforeClosed().subscribe(() => {
      if (this.onCloseDialogue && this.onCloseDialogue.observers.length > 0) {
        this.onCloseDialogue.emit();
      }
    });
  }

  closeDialog(_$event = null) {
    try {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    } catch (err) {
      console.error('Cannot close dialog', err);
    }
  }
}
