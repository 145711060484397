import {Injectable} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {Observable} from 'rxjs/internal/Observable';
import {MatDialog} from '@angular/material/dialog';

/**
 * Global material dialog service to open dialogs inside non-angular components like the
 * FivefConfirm decorator or to open dialogs with a certain configuration (context sidebar).
 */
@Injectable({
  providedIn: 'root'
})
export class FivefDialogService {
  /**
   * Singleton instance reference of this service.
   *
   * @private
   */
  private static instance: FivefDialogService | null = null;

  constructor(private dialog: MatDialog) {
    FivefDialogService.instance = this;
  }

  /**
   * Get an instance of this service inside non-angular components.
   */
  public static getInstance() {
    return FivefDialogService.instance;
  }

  /**
   * Opens a confirmation dialog with given component.
   *
   * @param data
   * @param component
   */
  public openConfirmDialog<T>(data: any, component: ComponentType<T>): Observable<boolean> {
    return this.dialog.open(component, {
      data: data,
      autoFocus: false
    }).afterClosed();
  }

  /**
   * Opens a context sidebar dialog with the given component.
   *
   * @param data
   * @param component
   */
  public openContextSidebar<T>(data: any, component: ComponentType<T>): Observable<boolean> {
    return this.dialog.open(component, {
      data: data,
      autoFocus: false,
      panelClass: 'fivef-context-sidebar-overlay',
    }).afterClosed();
  }
}
