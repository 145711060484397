<fivef-dialog class="fivef-folder-tree-dialog--container"
              [title]="dialogTitle"
              [info]="dialogInfo"
              [isCallToAction]="true">
  <fivef-folder-tree [folders]="folders$ | async"
                     [enableFolderActionToolbar]="true"
                     [loading]="loading$ | async"
                     [select]="selectedFolder$ | async"
                     (onRefresh)="refresh()"
                     (onSelect)="selectNode($event)"
                     [expandRoot]="false"></fivef-folder-tree>

  <div fivef-dialog-footer>
    <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button mat-raised-button
            color="primary"
            (click)="selectNodeAndClose()">{{ confirmButtonTitle | translate }}
    </button>
  </div>
</fivef-dialog>
