<span (click)="disableLabel ? $event.stopPropagation() : click(label);"
      [style.background-color]="label.color"
      [style.color]="colors.LightOrDark(label.color) === 'light' ? '#3c3c3c' : '#ffffff'"
      [class.fivef-label--disabled]="disableLabel"
      class="fivef-label--container">
  <mat-icon class="fivef-label--icon"
            *ngIf="label.icon && !label.isSVGIcon">{{ label.icon }}</mat-icon>
  <mat-icon class="fivef-label--icon"
            *ngIf="label.icon && label.isSVGIcon" [svgIcon]="label.icon"></mat-icon>
  <span [matTooltip]="(label.description | translate)">
    {{ ((label?.title | translate)?.length > 22) ? (label?.title | translate | slice:0:22) : (label?.title | translate) }}
    {{ ((label?.title | translate)?.length > 22) ? '...' : '' }}
  </span>

  <span *ngIf="!readonly && label.origin === LabelOrigin.NO_ORIGIN"
        class="fivef-label--remove-action">
    <mat-icon (click)="remove($event)"
              [matTooltip]="'GENERAL.REMOVE_ACTION' | translate"
              class="fivef-label--remove-action--icon">clear</mat-icon>
  </span>
</span>
