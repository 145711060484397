import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Observable, Subject} from 'rxjs';
import {Organization} from 'app/+store/organization/organization';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ITabNavRoute} from 'app/lib/fivef-legacy/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'dvtx-customization-email-frame',
  templateUrl: './customization-email-frame.component.html',
  styleUrls: ['./customization-email-frame.component.scss']
})
export class CustomizationEmailFrameComponent implements OnInit {
  public organization$: Observable<Organization>;
  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  constructor(private store: Store<AppState>, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
    this.orgId = this._route.snapshot.params.id;

    this.routes = [{
      title: 'ORGANIZATION.LOGO',
      route: `/organization/${this.orgId}/customization/logo`
    }, {
      title: 'FOOTER.TITLE',
      route: `/organization/${this.orgId}/customization/email`,
    }];
    this.activeLink = this.routes[1].title;
  }
}
