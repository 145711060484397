import {Action} from '@ngrx/store';
import {Comment, CommentProcessStatistics} from './comment';
import {CommentReactionType} from './comment.interface';

export enum CommentActionTypes {
  DeleteComment = '[Comment] Delete Comment',
  DeleteCommentSuccess = '[Comment] Delete Comment Success',
  DeleteCommentFail = '[Comment] Delete Comment Fail',
  DeleteArtifactComment = '[Comment] Delete Artifact Comment',
  SelectComment = '[Comment] Select Comment',
  SendArtifactComment = '[Comment] Send Artifact Comment',
  SendProcessComment = '[Comment] Send Process Comment',
  SendCommentSuccess = '[Comment] Send Process Comment Success',
  SendCommentFail = '[Comment] Send Process Comment Fail',
  SaveComment = '[Comment] Save Comment',
  SaveArtifactComment = '[Comment] Save Artifact Comment',
  SaveCommentSuccess = '[Comment] Save Comment Success',
  SaveCommentFail = '[Comment] Save Comment Fail',
  LoadAll = '[Comment] Load All',
  LoadAllSuccess = '[Comment] Load All Success',
  LoadAllFail = '[Comment] Load All Fail',
  LoadOne = '[Comment] Load One',
  LoadArtifactComment = '[Comment] Load Artifact Comment',
  LoadOneSuccess = '[Comment] Load One Success',
  LoadOneFail = '[Comment] Load One Fail',
  Read = '[Comment] Read Event',
  ReadSuccess = '[Comment] Read Event Success',
  ReadFail = '[Comment] Read Event Fail',
  React = '[Comment] React Event',
  ReactOnArtifactComment = '[Comment] React On Artifact Comment Event',
  ReactSuccess = '[Comment] React Event Success',
  ReactFail = '[Comment] React Event Fail',
  LoadProcessStatistics = '[Comment] LoadStatistics',
  LoadProcessStatisticsSuccess = '[Comment] LoadStatistics Success',
  LoadProcessStatisticsFail = '[Comment] LoadStatistics Fail',
  LoadAllTaskComments = '[Comment] Load All Task Comments]',
  DeleteTaskComment = '[Comment] Delete Task Comment',
  SendTaskComment = '[Comment] Send Task Comment',
  UpdateTaskComment = '[Comment] Update Task Comment',
  ReactOnTaskComment = '[Comment] React On Task Comment',
  ReadTaskComment = '[Comment] Read Task Comment Event'
}

export class LoadAll implements Action {
  readonly type = CommentActionTypes.LoadAll;

  constructor(public processId: string, public recursive = false, public resourceId = null, public isArtifact = false) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = CommentActionTypes.LoadAllSuccess;

  constructor(public comments: Comment[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = CommentActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = CommentActionTypes.LoadOne;

  constructor(public processId: string, public commentId) {
  }
}

export class LoadArtifactComment implements Action {
  readonly type = CommentActionTypes.LoadArtifactComment;

  constructor(public artifactId: string, public commentId) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = CommentActionTypes.LoadOneSuccess;

  constructor(public comment: Comment) {
  }
}

export class LoadOneFail implements Action {
  readonly type = CommentActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class DeleteComment implements Action {
  readonly type = CommentActionTypes.DeleteComment;

  constructor(public processId: string, public commentId: string) {
  }
}

export class DeleteArtifactComment implements Action {
  readonly type = CommentActionTypes.DeleteArtifactComment;

  constructor(public artifactId: string, public commentId: string) {
  }
}

export class DeleteCommentSuccess implements Action {
  readonly type = CommentActionTypes.DeleteCommentSuccess;

  constructor(public commentId: string) {
  }
}

export class DeleteCommentFail implements Action {
  readonly type = CommentActionTypes.DeleteCommentFail;

  constructor(public payload: any) {
  }
}

export class SaveComment implements Action {
  readonly type = CommentActionTypes.SaveComment;

  constructor(public processId: string, public commentId: string, public content: string) {
  }
}

export class SaveArtifactComment implements Action {
  readonly type = CommentActionTypes.SaveArtifactComment;

  constructor(public artifactId: string, public commentId: string, public content: string) {
  }
}

export class SaveCommentSuccess implements Action {
  readonly type = CommentActionTypes.SaveCommentSuccess;

  constructor(public comment: Comment) {
  }
}

export class SaveCommentFail implements Action {
  readonly type = CommentActionTypes.SaveCommentFail;

  constructor(public payload: any) {
  }
}

export class SendProcessComment implements Action {
  readonly type = CommentActionTypes.SendProcessComment;

  constructor(public processId, public comment: Comment) {
  }
}

export class SendCommentSuccess implements Action {
  readonly type = CommentActionTypes.SendCommentSuccess;

  constructor(public comment: Comment) {
  }
}

export class SendCommentFail implements Action {
  readonly type = CommentActionTypes.SendCommentFail;

  constructor(public payload: any) {
  }
}

export class SendArtifactComment implements Action {
  readonly type = CommentActionTypes.SendArtifactComment;

  constructor(public artifactId, public comment: Comment) {
  }
}

export class SelectComment implements Action {
  readonly type = CommentActionTypes.SelectComment;

  constructor(public comment: Comment) {
  }
}

export class Read implements Action {
  readonly type = CommentActionTypes.Read;

  constructor(public comment: Comment) {
  }
}

export class ReadSuccess implements Action {
  readonly type = CommentActionTypes.ReadSuccess;

  constructor(public comment: Comment) {
  }
}

export class ReadFail implements Action {
  readonly type = CommentActionTypes.ReadFail;

  constructor(public payload: any) {
  }
}

export class React implements Action {
  readonly type = CommentActionTypes.React;

  constructor(public processId: string, public commentId: string, public reactionType: CommentReactionType) {
  }
}

export class ReactOnArtifactComment implements Action {
  readonly type = CommentActionTypes.ReactOnArtifactComment;

  constructor(public artifactId: string, public commentId: string, public reactionType: CommentReactionType) {
  }
}

export class ReactSuccess implements Action {
  readonly type = CommentActionTypes.ReactSuccess;

  constructor(public comment: Comment) {
  }
}

export class ReactFail implements Action {
  readonly type = CommentActionTypes.ReactFail;

  constructor(public payload: any) {
  }
}

export class LoadProcessStatistics implements Action {
  readonly type = CommentActionTypes.LoadProcessStatistics;

  constructor(public processId: string, public recursive = false) {
  }
}

export class LoadProcessStatisticsSuccess implements Action {
  readonly type = CommentActionTypes.LoadProcessStatisticsSuccess;

  constructor(public stats: CommentProcessStatistics) {
  }
}

export class LoadProcessStatisticsFail implements Action {
  readonly type = CommentActionTypes.LoadProcessStatisticsFail;

  constructor(public payload: any) {
  }
}

export class LoadAllTaskComments implements Action {
  readonly type = CommentActionTypes.LoadAllTaskComments;

  constructor(public taskId: string) {
  }
}

export class DeleteTaskComment implements Action {
  readonly type = CommentActionTypes.DeleteTaskComment;

  constructor(public taskId, public commentId: string) {
  }
}

export class SendTaskComment implements Action {
  readonly type = CommentActionTypes.SendTaskComment;

  constructor(public taskId: string, public comment: Comment) {
  }
}

export class UpdateTaskComment implements Action {
  readonly type = CommentActionTypes.UpdateTaskComment;

  constructor(public taskId: string, public commentId: string, public message: string) {
  }
}

export class ReactOnTaskComment implements Action {
  readonly type = CommentActionTypes.ReactOnTaskComment;

  constructor(public taskId: string, public commentId: string, public reactionType: CommentReactionType) {
  }
}

export class ReadTaskComment implements Action {
  readonly type = CommentActionTypes.ReadTaskComment;

  constructor(public taskId: string, public commentId: string) {
  }
}

export type CommentActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadArtifactComment
  | LoadOneSuccess
  | LoadOneFail
  | DeleteComment
  | DeleteArtifactComment
  | DeleteCommentSuccess
  | DeleteCommentFail
  | SaveComment
  | SaveArtifactComment
  | SaveCommentSuccess
  | SaveCommentFail
  | SendProcessComment
  | SendCommentSuccess
  | SendCommentFail
  | SendArtifactComment
  | SelectComment
  | Read
  | ReadSuccess
  | ReadFail
  | React
  | ReactOnArtifactComment
  | ReactSuccess
  | ReactFail
  | LoadProcessStatistics
  | LoadProcessStatisticsSuccess
  | LoadProcessStatisticsFail
  | LoadAllTaskComments
  | DeleteTaskComment
  | SendTaskComment
  | UpdateTaskComment
  | ReactOnTaskComment
  | ReadTaskComment;
