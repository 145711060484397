import {Component, Input, ViewChild, EventEmitter, Output, ElementRef} from '@angular/core';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'dvtx-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss']
})
export class DropdownContentComponent {
  @ViewChild('buttonToggler') buttonToggler: MatButton;

  @ViewChild('menuContainer', {static: true})
  menuContainer: ElementRef;

  public showDropdownContent = false;

  @Input()
  icon;

  @Input()
  content;

  @Input()
  tooltip;

  @Input()
  borderColor;

  @Input()
  classesList;

  @Input()
  backdropClassesList;

  @Input()
  maxWidth;

  @Input()
  buttonType = 'iconButton';

  @Input()
  buttonColor = 'primary';

  @Input()
  iconButtonColor = 'basic';

  @Input()
  xPosition = 'before';

  @Input()
  yPosition = 'below';

  @Output() onCloseDropdown: EventEmitter<any> = new EventEmitter();

  public openDropdownMenu() {
    if (this.buttonToggler._elementRef.nativeElement) {
      this.buttonToggler._elementRef.nativeElement.click()
    }
  }

  afterClose() {
    this.showDropdownContent = false;
    this.onCloseDropdown.emit();
  }

  public toggleDropdown() {
    this.openDropdownMenu();
  }

  menuOpened(event, old = null) {
    // Code smell and bad sideeffect: the dropdown component should be independent and not implement special cases for the outside world!
    // TODO: Special handling regarding the filters menu should only belong to this dialog.
    const element: any = document.querySelectorAll('.fivef-filters-dropdown-menu + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane')[0];
    if (element) {
      const topValue = Number(element.style.top.split('px')[0]) + 50 + 10; // 50px os the parent margin from top, and the 10 is extra
      const diff = window.innerHeight - (element.clientHeight + topValue);

      // if diff is less than 0, this means the content are outside the screen.
      if (diff < 0) {
        element.style.top = `${Number(element.style.top.split('px')[0]) + diff}px`;
      }
      // we make sure to make the content taking max heoght of the availbale based on the top position.
      element.style.maxHeight = `calc(100vh - ${topValue}px)`;

      // if element top value is empty, or old top value is empty, or the current top value is not equal to the old, or the diff still less than 0
      if ((!element.style.top.split('px')[0] || !old || element.style.top.split('px')[0] != old) || diff < 0) {
        old = element.style.top.split('px')[0];
        // Waiting 100ms so the content is drawn.
        setTimeout(() => this.menuOpened(event, old), 100);
        return;
      }
    }
  }
}
