import {BehaviorSubject, combineLatest as observableCombineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Label} from 'app/+store/label/label';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {UntypedFormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Colors} from 'app/lib/color/hsl';
import {FivefCreateLabelDialogComponent} from 'app/lib/fivef-ui/label/fivef-create-label-dialog/fivef-create-label-dialog.component';
import {LabelActions} from 'app/+store';
import {LabelScope} from 'app/+store/label/label.interface';
import {TranslateService} from '@ngx-translate/core';
import {FivefConfirm} from '../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';

@Component({
  selector: 'dvtx-labels-listing',
  templateUrl: './labels-listing.component.html',
  styleUrls: ['./labels-listing.component.scss']
})
export class LabelsListingComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  public colors = Colors;

  filteredLabels: Label[] = [];

  searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  _labels: BehaviorSubject<Label[]> = new BehaviorSubject(undefined);

  @Input() scope: LabelScope;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() canCreate: boolean;

  @Input() set onSearch(term) {
    this.search(term);
  }

  @Input() set labels(labels: Label[]) {
    this._labels.next(labels);
  }

  constructor(private _store: Store<AppState>,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private _translateSvc: TranslateService) {
  }

  ngOnInit() {
    observableCombineLatest(
      this._labels.pipe(
        filter(labels => !!labels),
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      ),
      this.searchTerm$,
    ).pipe(takeUntil(this.onDestroy)).subscribe(([labels, value]) => {
      this.filteredLabels = labels;
      this.filteredLabels = this.filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
      const q = value;
      if (!q || q.length === 0) return;
      this.filteredLabels = this.filteredLabels.filter(item => item.title.toLowerCase().search(q.toLowerCase()) > -1 || this._translateSvc.instant(item.title).toLowerCase().search(q.toLowerCase()) > -1);
      this.filteredLabels = this.filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
    })
    this._translateSvc.onLangChange.subscribe(() => {
      if (this.filteredLabels) {
        this.filteredLabels = this.filteredLabels.sort((a, b) => (this._translateSvc.instant(a.title).trim().toLowerCase() < this._translateSvc.instant(b.title).trim().toLowerCase() ? -1 : 1));
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  createLabel() {
    this.dialog.open(FivefCreateLabelDialogComponent, {
      panelClass: 'labels-creation-form-dialog',
      data: {
        scope: this.scope
      }
    });
  }

  editLabel(label) {
    this.dialog.open(FivefCreateLabelDialogComponent, {
      panelClass: 'labels-creation-form-dialog',
      data: {
        label: label,
        scope: this.scope,
        editingMode: true
      }
    });
  }

  @FivefConfirm({
    message: 'LABELS.DELETE_LABEL',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_ACTION'
  })
  deleteLabel(label) {
    this._store.dispatch(new LabelActions.Remove(label.id));
  }

  public search(term) {
    let searchTerm = '';
    if (term && typeof term === 'string') {
      searchTerm = term.toLocaleLowerCase();
    }
    this.searchTerm$.next(searchTerm);
  }
}
