import { IRole } from '../../../../+store/user/role.interface';
import { Role } from '../../../../+store/user/role';
import { IFile } from '../models/files.interface';
import { DMSFile } from '../models/files';
export namespace FilesBuilder {

  export function fromResponse(response): DMSFile {
    console.log('files' , response)
    return new DMSFile (
      response.id,
      response.type,
      response.attributes.folder_id,
      response.attributes.revision,
      response.attributes.name,
      response.attributes.filename,
      response.attributes.readable,
      response.attributes.writable,
      response.attributes.mime_type,
      response.attributes.created_at,
      response.attributes.updated_at,
      response.attributes.locked_at
      )
    }


  export function toRequest(file: IFile) {
    const requestFormat = {
      data: {
        attributes: {
          folder_id: file.folder_id,
          revision: file.revision,
          name: file.name,
          filename: file.filename,
          readable: file.readable,
          writable: file.writable,
          mime_type: file.mime_type,
          created_at: file.created_at,
          updated_at: file.updated_at,
          locked_at: file.locked_at,
        }
      }
    };
    return requestFormat;
  }
}

