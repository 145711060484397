import {Action} from '@ngrx/store';
import {OrganizationLogo} from 'app/+store/organization-logo/organization-logo';
import {Organization} from '../organization/organization';

export enum OrganizationLogoActionTypes {
  LoadAll = '[OrganizationLogo] Load All',
  LoadAllSuccess = '[OrganizationLogo] Load All Success',
  LoadAllFail = '[OrganizationLogo] Load All Fail',
  LoadCurrent = '[OrganizationLogo] Load Current',
  LoadCurrentSuccess = '[OrganizationLogo] Load Current Success',
  LoadCurrentFail = '[OrganizationLogo] Load Current Fail',
  Remove = '[OrganizationLogo] Remove Logo',
  RemoveSuccess = '[OrganizationLogo] Remove Logo Success',
  RemoveFail = '[OrganizationLogo] Remove Logo Fail',
  Publish = '[OrganizationLogo] Publish Logo',
  PublishSuccess = '[OrganizationLogo] Publish Logo Success',
  PublishFail = '[OrganizationLogo] Publish Logo Fail',
  Reset = '[OrganizationLogo] Reset Logo'
}

export class LoadAll implements Action {
  readonly type = OrganizationLogoActionTypes.LoadAll;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = OrganizationLogoActionTypes.LoadAllSuccess;

  constructor(public payload: OrganizationLogo[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = OrganizationLogoActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadCurrent implements Action {
  readonly type = OrganizationLogoActionTypes.LoadCurrent;

  constructor(public organization: Organization = null) {
  }
}

export class LoadCurrentSuccess implements Action {
  readonly type = OrganizationLogoActionTypes.LoadCurrentSuccess;

  constructor(public payload: OrganizationLogo) {
  }
}

export class LoadCurrentFail implements Action {
  readonly type = OrganizationLogoActionTypes.LoadCurrentFail;

  constructor(public payload: any) {
  }
}

export class Remove implements Action {
  readonly type = OrganizationLogoActionTypes.Remove;

  constructor(public logo: OrganizationLogo) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = OrganizationLogoActionTypes.RemoveSuccess;

  constructor(public payload: OrganizationLogo) {
  }
}

export class RemoveFail implements Action {
  readonly type = OrganizationLogoActionTypes.RemoveFail;

  constructor(public payload: any) {
  }
}

export class Publish implements Action {
  readonly type = OrganizationLogoActionTypes.Publish;

  constructor(public id: any, public checked: boolean) {
  }
}

export class PublishSuccess implements Action {
  readonly type = OrganizationLogoActionTypes.PublishSuccess;

  constructor(public payload: OrganizationLogo) {
  }
}

export class PublishFail implements Action {
  readonly type = OrganizationLogoActionTypes.PublishFail;

  constructor(public payload: any) {
  }
}

export class Reset implements Action {
  readonly type = OrganizationLogoActionTypes.Reset;

  constructor(public payload: any = null) {
  }
}

export type OrganizationLogoActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadCurrent
  | LoadCurrentSuccess
  | LoadCurrentFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | Publish
  | PublishSuccess
  | PublishFail
  | Reset;
