import { Injectable } from '@angular/core';
import { FivefApiResourceService } from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import { Observable } from 'rxjs';
import { BookmanServiceAccountBuilder } from './bookman-service-account.builder';
import { BookmanServiceAccount } from './bookman-service-account';

@Injectable()
export class BookmanServiceAccountService {
  readonly basePath = 'api/v1/fibu/service_accounts';

  constructor(private _http: FivefApiResourceService) {
  }

  signInBookmanServiceAccount(credentials) {
    const builder = new BookmanServiceAccountBuilder();
    const payload = {
      data: {
        attributes: {
          email: credentials.email,
          password: credentials.password
        }
      }
    };
    return <Observable<BookmanServiceAccount>>this._http.post<BookmanServiceAccountBuilder, BookmanServiceAccount>(builder, `${this.basePath}`, payload);
  }

  signOutBookmanServiceAccount() {
    const builder = new BookmanServiceAccountBuilder();
    return <Observable<BookmanServiceAccount>>this._http.del<BookmanServiceAccountBuilder, BookmanServiceAccount>(builder, `${this.basePath}`);
  }


  getBookmanServiceAccount() {
    const builder = new BookmanServiceAccountBuilder();
    return <Observable<BookmanServiceAccount>>this._http.get<BookmanServiceAccountBuilder, BookmanServiceAccount>(builder, `${this.basePath}`);
  }

}
