import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export class BasicContact implements IResource {
  readonly type = 'basic_contact';
  phone: string;
  mobile: string;
  verifiedAt: Date = null;
  verificationProcessId: number;
  hasAccount: false;
  hasRegisteredAccount: false;

  constructor(public id: string,
              public email: string,
              public gender: string,
              public title: string,
              public firstName,
              public lastName,
              public role: string = null,
              public notificationLanguage: string = null) {}
}
