import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './natural-person.state';
import * as MembershipSelectors from '../membership/membership.selectors'
import {OrganizationSelectors} from '../organization'
import {LicenceAssignmentSelectors} from '../licence-assignment'
import {ContactSelectors} from '../contact';
import {contactListDtoType} from '../contact/contact';
import { ProcessParticipantSelectors } from '../process-participant';
import {StringUtils} from '../../lib/string_utils/functions';
import { EmailToNameSelectors } from '../email-to-name';

export const stateKey = 'naturalPerson';
export const getNaturalPersonState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getNaturalPersonEntities,
  selectAll: getAllNaturalPersons,
} = adapter.getSelectors(getNaturalPersonState);

export const getNaturalPersonsLoading = createSelector(
  getNaturalPersonState,
  state => state.loading
);

/**
 * Returns the profile ID of current user.
 */
export const getMyNaturalPersonId = createSelector(
  getNaturalPersonState,
  state => state.my
);

/**
 * Returns the current user's profile by state accessor (see also reducer).
 */
export const getMyNaturalPersonObject = createSelector(
  getNaturalPersonState,
  state => state.myNaturalPerson
);

/**
 * Returns the natural person entity of the current user.
 * TODO: This accessor is a bit unclear because it seems to do the same
 *       as getMyNaturalPersonObject but by iterating all entities (expensive).
 *       Changed at 19.09.2024 to prefer getMyNaturalPersonObject / state.myNaturalPerson
 *       if present. Unclear if there is a scenario possible where myNaturalPerson is empty
 *       but state.my is present.
 */
export const getMyNaturalPersonEntity = createSelector(
  getAllNaturalPersons,
  getMyNaturalPersonId,
  getMyNaturalPersonObject,
  (entities, id, person) => {
    return !!person ? person : (!!id ? entities.find(entity => entity.id === id) : null);
  }
);

export const getNaturalPersonsOfSelectedOrganization = createSelector(
  MembershipSelectors.getMembershipsOfSelectedOrg,
  getNaturalPersonEntities,
  (memberships, entities) => memberships.map(m => {
    const member = entities[m.naturalProfileId];
    if (member) {
      member['membershipId'] = m.id;
    }
    return member;
  }).filter(x => x !== undefined)
)

export const getById = (id: string) => createSelector(
  getNaturalPersonEntities,
  entities => entities[id]
)

export const getNameByEmailMap = createSelector(
  getAllNaturalPersons,
  ContactSelectors.getAllContacts,
  EmailToNameSelectors.getAllEmailToName,
  (people, contacts, emailToNames) => {
    const nameByEmailMap = {}

    emailToNames.forEach(emailToName => {
      const email = StringUtils.normalizeString(emailToName.email);
      if (!nameByEmailMap[email] && emailToName.firstName && emailToName.lastName) {
        nameByEmailMap[email] = `${emailToName.firstName} ${emailToName.lastName}`;
      }
    })

    people.forEach(p => {
      const email = StringUtils.normalizeString(p.mainEmailAddress.emailAddress)
      if (!nameByEmailMap[email] && p.firstName && p.lastName) {
        nameByEmailMap[email] = `${p.firstName} ${p.lastName}`;
      }
    });

    contacts.forEach(c => {
      if (c.type === contactListDtoType.Membership || c.type === contactListDtoType.naturalPersonContact) {
        const email = StringUtils.normalizeString(c.email);
        if (!nameByEmailMap[email]) {
          const [lastName, firstName] = c.name.split(', ');
          if (lastName && firstName) {
            nameByEmailMap[email] = `${firstName} ${lastName}`;
          }
        }
      }
    });
    return nameByEmailMap;
  }
)

export const getNameByEmail = (email: string) => createSelector(
  getNameByEmailMap,
  (nameByEmailMap) => nameByEmailMap[email] || email
)

export const getPersonByEmail = (email: string) => createSelector(
  getAllNaturalPersons,
  ContactSelectors.getContactsOfSelectedOrg,
  (people, contacts) => {
    const contact: any = contacts.find(c => matchingEmails(c.email, email) && (c.type === contactListDtoType.Membership || c.type === contactListDtoType.naturalPersonContact));
    if (contact) {
      const person: any = people.find(p => p.mainEmailAddress && matchingEmails(p.mainEmailAddress.emailAddress, contact.email));
      if (person) {
        if (contact.hasAccount) {
          person.hasAccount = contact.hasAccount;
        }
        person.isMember = true;
        return person;
      } else {
        return {
          email: email
        };
      }
    } else {
      return {
        email: email
      }
    }
  }
);

const matchingEmails = (email, other): boolean => {
  return StringUtils.simpleCompare(email, other);
};
