<section class="fivef-context-sidebar--header-section">
  <header class="fivef-context-sidebar--header">
    <ng-template [ngIf]="title || subtitle" [ngIfElse]="ngContentRef">
      <h2 class="fivef-context-sidebar--header--title" *ngIf="title">
        <fivef-icon *ngIf="icon"
                    class="fivef-context-sidebar--header--icon"
                    [icon]="icon"></fivef-icon>
        {{ title | translate }}
      </h2>
      <h3 *ngIf="subtitle"
          class="fivef-context-sidebar--header--subtitle">{{ subtitle | translate }}</h3>
    </ng-template>

    <ng-template #ngContentRef>
      <ng-content select="[fivef-context-sidebar-header]"></ng-content>
    </ng-template>
  </header>

  <nav class="fivef-context-sidebar--nav"
       *ngIf="_collection?.length">
    <button mat-icon-button
            [style.visibility]="hasPrevious ? 'visible' : 'hidden'"
            (click)="previousNode()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button
            [style.visibility]="hasNext ? 'visible' : 'hidden'"
            (click)="nextNode()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </nav>
</section>

<hr>

<main class="fivef-context-sidebar--content-section">
  <ng-content></ng-content>
</main>

<footer class="fivef-context-sidebar--footer-section">
  <ng-content select="[fivef-context-sidebar-footer]"></ng-content>
</footer>
