import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CollectorItem} from '../collector-item/collector-item';
import {CollectorCategory} from '../collector-category/collector-category';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {Client} from 'app/+store/client/client';
import {ClientBuilder} from 'app/+store/client/client.builder';
import {ApiStatusToItemStatusMapping, ItemStatus} from 'app/+store/collector/collector.interface';
import {Collector} from './collector';

export class CollectorBuilder implements IApiResourceBuilder<Collector> {
  categories = [];
  items = [];
  itemsMap = {};
  clients: {
    [id: string]: Client
  } = {};

  constructor(private _store: Store<AppState> = null) {
  }

  addIncludedSection(includes) {
    if (includes && includes.length) {
      const cbuilder = new ClientBuilder();

      includes.forEach(include => {
        this.createIncludeModel(include.type, include);
        if (include.type === 'client_people' || include.type === 'client_organizations') {
          const _client = cbuilder.fromResponse(include);
          this.clients[include.id] = _client;
        }
      });
    }
  }

  createIncludeModel(type: string, include) {
    const attrs = include.attributes;
    switch (type) {
      case 'bom_tree_nodes':
        if (attrs.parent_id) {
          const item = new CollectorItem(
            include.id,
            attrs.parent_id,
            attrs.title,
            attrs.description,
            attrs.color,
            attrs.pre_due_date,
            attrs.due_date,
            attrs.started_at,
            attrs.completed_at,
            attrs.created_at,
            attrs.updated_at,
            false,
            attrs.priority,
            attrs.starts_at,
            attrs.ends_at,
            attrs.progress,
            attrs.effort,
            attrs.order,
            attrs.responsible_id,
            attrs.locked_at
          );
          item.status = ApiStatusToItemStatusMapping[attrs.status];
          item.priority = attrs.priority == 'undefined' ? 'medium' : attrs.priority;
          item.isOverdued = item.dueDate && item.status !== ItemStatus.Closed && this.isOverdued(item.dueDate);
          if (!this.itemsMap[item.categoryId]) this.itemsMap[item.categoryId] = [];
          this.itemsMap[item.categoryId].push(item);
          this.items.push(item);
        } else {
          const category = new CollectorCategory(
            include.id,
            null,
            attrs.title,
            attrs.description,
            attrs.color,
            attrs.pre_due_date,
            attrs.due_date,
            attrs.started_at,
            attrs.completed_at,
            attrs.created_at,
            attrs.updated_at,
            false,
            attrs.priority,
            attrs.starts_at,
            attrs.ends_at,
            attrs.order
          );
          category.status = ApiStatusToItemStatusMapping[attrs.status];
          category.priority = attrs.priority == 'undefined' ? 'medium' : attrs.priority;
          category.isOverdued = category.dueDate && this.isOverdued(category.dueDate);
          this.categories.push(category);
        }
        break;
    }
  }

  fromResponse(data): Collector {
    const collector = new Collector(
      data.id,
      data.attributes.parent_id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.state,
      data.attributes.creator_name,
      data.attributes.creator_email,
      data.attributes.owner_name,
      data.attributes.owner_email,
      data.attributes.recipients || [],
      data.attributes.workers || [],
      data.attributes.dms_folder_id,
      data.attributes.created_at,
      data.attributes.updated_at,
      [],
      data.attributes.can_create_task,
      data.attributes.starts_at,
      data.attributes.ends_at,
      data.attributes.due_date,
      data.attributes.started_at,
      data.attributes.identifier);

    let clientId = null;
    try {
      clientId = data.relationships.client && data.relationships.client.data.id ? data.relationships.client.data.id : null;
    } catch (e) { }
    if (clientId) {
      collector.client = this.clients[clientId];
    }

    collector.color = data.attributes.color;
    collector.profile = data.attributes.profile;
    collector.year = data.attributes.year;
    collector.month = data.attributes.month;

    if (data.relationships && data.relationships.nodes && data.relationships.nodes.data) {
      data.relationships.nodes.data.forEach(item => {
        const category = this.categories.find(_category => _category.id === item.id);
        if (category) {
          if (this.itemsMap[category.id]) {
            category.items = this.itemsMap[category.id].sort((l, r) => (l.order < r.order ? -1 : 1));
          }
          collector.categories.push(category);
        }
      });
    }

    return collector;
  }

  toRequest(_: Collector) {
    return null;
  }

  isOverdued(date) {
    const currentDate = new Date(new Date().setHours(24, 0, 0));
    const itemDueDate = new Date(new Date(date).setHours(24, 0, 0));
    return Math.round((itemDueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) < 0;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
