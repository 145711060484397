import {ChangeDetectionStrategy, Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {FivefControlValueAccessor} from '../../../../../../lib/fivef-ui/input/fivef-control-value-accessor.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ListingService} from 'app/+store/_legacy/api/services/listing.service';

@Component({
  selector: 'dvtx-api-select-cva',
  templateUrl: './api-select-cva.component.html',
  styleUrls: ['./api-select-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApiSelectCvaComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiSelectCvaComponent extends FivefControlValueAccessor implements OnInit {
  listing$: Observable<Listing[]>;

  @Input()
  listingType: string;

  @Input()
  listingPath = '';

  @Input()
  requiredField: boolean;

  _selectedValue: any;
  @Input() label: string;

  get selectedValue(): string {
    return this._selectedValue;
  }

  set selectedValue(value: string) {
    this._selectedValue = value;
    this.notifyOnChange(value);
    this.notifyOnTouch();
  }

  constructor(private _listingService: ListingService,
              protected injector: Injector) {
    super();
  }

  ngOnInit(): void {
    this.listing$ = this._listingService.fetchListing(this.listingType);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.listing$.pipe(
        first(),
      ).subscribe((list: Listing[]) => {
        const foundItem = list.find(x => String(x.value) === String(obj));

        this.selectedValue = foundItem ? foundItem.value : undefined;
      })
    }
  }

  getVisual(item) {
    return this.listingPath !== '' ? item[this.listingPath] : item;
  }
}

export interface Listing {
  name: string,
  value: any
}
