import {NgModule} from '@angular/core';
import {ClientOverviewComponent} from './containers/client-overview/client-overview.component';
import {FivefClientListingComponent} from '../../lib/fivef-ui/client/fivef-client-listing/fivef-client-listing.component';
import {FivefCreateClientComponent} from '../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {CommonModule} from '@angular/common';
import {FivefSearchComponent} from '../../lib/fivef-ui/input/fivef-search/fivef-search.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FivefIconMessageBlockComponent} from '../../lib/fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {InViewportDirective} from 'ng-in-viewport';

@NgModule({
  imports: [
    CommonModule,
    FivefClientListingComponent,
    FivefCreateClientComponent,
    FivefSearchComponent,
    TranslateModule,
    MatProgressBarModule,
    FivefIconMessageBlockComponent,
    InViewportDirective
  ],
  declarations: [
    ClientOverviewComponent
  ],
  exports: [
    ClientOverviewComponent,
  ]
})
export class ClientModule {
}
