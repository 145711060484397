import {IProcess} from '../process/process.interface';
import {IDmsFolder} from '../dms-folder/dms-folder.interface';
import {
  BomNodeDataColumn,
  DatevDmsProcessSettings,
  DatevDmsProfile,
  DmsCleanupStatus,
  GlobalProcessSettings,
  LocalProcessSettings,
  OrganizationProjects
} from './process-management';
import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';

export interface IPrivilegedProcess extends IProcess {
  folder?: IDmsFolder;
}

export class OrganizationProjectsBuilder implements IApiResourceBuilder<OrganizationProjects> {
  constructor() {
  }

  fromResponse(data): OrganizationProjects {
    const attr = data.attributes;
    return new OrganizationProjects(
      attr.client_id,
      attr.client_name,
      attr.client_uuid,
      attr.created_at,
      attr.parent_id,
      attr.parent_title,
      attr.process_type,
      attr.state,
      attr.title,
      attr.color,
      attr.identifier,
      data.id);
  }

  toRequest(_: OrganizationProjects) {
    return null;
  }
}

export class LocalProcessSettingsBuilder implements IApiResourceBuilder<LocalProcessSettings> {
  fromResponse(data): LocalProcessSettings {
    const attr = data.attributes;
    return new LocalProcessSettings(
      attr.id,
      attr.default_view,
      attr.permit_comments,
      attr.permit_upload,
      attr.column_setup);
  }

  toRequest(_: LocalProcessSettings) {
    return null;
  }
}

export class ProcessColumnBuilder implements IApiResourceBuilder<BomNodeDataColumn> {
  fromResponse(data): BomNodeDataColumn {
    const attr = data.attributes;
    return new BomNodeDataColumn(
      data.id,
      attr.visibility);
  }

  toRequest(_: BomNodeDataColumn) {
    return null;
  }
}

/**
 * Builds the process individual DATEV DMS settings items.
 * Each process can only have one settings object.
 */
export class DatevDmsProcessSettingsBuilder implements IApiResourceBuilder<DatevDmsProcessSettings> {
  fromResponse(data): DatevDmsProcessSettings {
    const attr = data.attributes;
    return new DatevDmsProcessSettings(
      attr.id,
      attr.datev_profile_id,
      attr.auto_sync_enabled,
      attr.domain_id,
      attr.folder_id,
      attr.register_id
    );
  }

  toRequest(_: DatevDmsProcessSettings) {
    return null;
  }
}

/**
 * Builds the DATEV DMS profiles.
 * The profiles can currently only be set by the 5F Desktop app.
 * Profiles are availale for all moderators of project rooms if member of the process organization.
 */
export class DatevDmsProfileBuilder implements IApiResourceBuilder<DatevDmsProfile> {
  fromResponse(data): DatevDmsProfile {
    const attr = data.attributes;
    return new DatevDmsProfile(
      data.id,
      attr.title,
      attr.description,
      attr.worker,
      attr.domains
    );
  }

  toRequest(_: DatevDmsProfile) {
    return null;
  }
}

export class GlobalProcessSettingsBuilder implements IApiResourceBuilder<GlobalProcessSettings> {
  fromResponse(data): GlobalProcessSettings {
    const attr = data.attributes;
    return new GlobalProcessSettings(
      attr.id,
      attr.permit_reopen,
      attr.permit_delete,
      attr.permit_export_marker_access,
      attr.permit_trash,
      attr.trash_retention_period);
  }

  toRequest(_: GlobalProcessSettings) {
    return null;
  }
}

export class DmsCleanupStatusBuilder implements IApiResourceBuilder<DmsCleanupStatus> {
  fromResponse(data): DmsCleanupStatus {
    const attr = data.attributes;
    return new DmsCleanupStatus(
      attr.id,
      attr.status,
      attr.created_at,
      attr.updated_at);
  }

  toRequest(_: DmsCleanupStatus) {
    return null;
  }
}
