import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {PersonsFilterModule} from 'app/lib/fivef-legacy/persons-filter/persons-filter.module';
import {InViewportModule} from 'ng-in-viewport';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';
import {ClientsFilterModule} from 'app/lib/fivef-legacy/clients-filter/clients-filter.module';
import {FastdocsContainerComponent} from './containers/fastdocs-container/fastdocs-container.component';
import {FastdocsListingModule} from './modules/fastdocs-listing/fastdocs-listing.module';
import {FastdocsListingFiltersComponent} from './component/fastdocs-listing-filters/fastdocs-listing-filters.component';
import {FastdocsStatsComponent} from './component/fastdocs-stats/fastdocs-stats.component';
import {NoItemsComponent} from './component/no-items/no-items.component';
import {FastdocsTreeViewComponent} from './component/fastdocs-tree-view/fastdocs-tree-view.component';
import {FastdocsAdminComponent} from './containers/fastdocs-admin/fastdocs-admin.component';
import {OrganizationCardModule} from '../../lib/fivef-legacy/organization-card/organization-card.module';
import {FiltersContainerModule} from 'app/lib/fivef-legacy/filters/filters-container.module';
import {PieChartModule} from '@swimlane/ngx-charts';
import {FivefSearchComponent} from '../../lib/fivef-ui/input/fivef-search/fivef-search.component';
import {FivefLoadingIndicatorComponent} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';

@NgModule({
  imports: [
    SharedModule,
    PersonsFilterModule,
    InViewportModule,
    FastdocsListingModule,
    TooltipModule,
    ClientsFilterModule,
    OrganizationCardModule,
    FiltersContainerModule,
    PieChartModule,
    FivefSearchComponent,
    FivefLoadingIndicatorComponent
  ],
  declarations: [
    FastdocsContainerComponent,
    FastdocsListingFiltersComponent,
    FastdocsStatsComponent,
    NoItemsComponent,
    FastdocsTreeViewComponent,
    FastdocsAdminComponent
  ],
  exports: [
    NoItemsComponent
  ]
})
export class FastdocsModule {
}
