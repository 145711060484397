import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Observable} from 'rxjs';
import {Organization} from 'app/+store/organization/organization';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {MembershipSelectors} from 'app/+store/membership';
import {Membership} from 'app/+store/membership/membership';
import {ITabNavRoute} from 'app/lib/fivef-legacy/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-organization-profile-frame',
  templateUrl: './settings-frame.component.html',
  styleUrls: ['./settings-frame.component.scss']
})
export class SettingsFrameComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public organization$: Observable<Organization>;
  public myMembership: Observable<Membership>;
  routes: ITabNavRoute[] = [];
  activeLink: string;

  constructor(private store: Store<AppState>,
              private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
    this.myMembership = this.store.select(MembershipSelectors.getMyMembership);
    const orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'ORGANIZATION.MASTER_DATA',
      route: `/organization/${orgId}/orgSettings/profile`
    }, {
      title: 'ORGANIZATION.GLOBAL_SETTINGS',
      route: `/organization/${orgId}/orgSettings/settings`
    }];
    this.activeLink = this.routes[1].title;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
