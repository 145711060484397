import {DmsFolderActions, DmsFolderActionTypes} from './dms-folder.actions';
import {adapter, initialState, State} from './dms-folder.state';

export function reducer(state = initialState, action: DmsFolderActions): State {
  const current = state.selected;
  const refresh = state.refresh;
  switch (action.type) {
    case DmsFolderActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case DmsFolderActionTypes.Refresh:
      return {
        ...state,
        selected: null,
        loading: true,
        refresh: true
      };
    case DmsFolderActionTypes.LoadAllSuccess:
      const clientFolderMap = {};
      action.payload.forEach(f => {
        if (f.authMode === 'global_client_folder') {
          clientFolderMap[f.id] = true;
        }
      });
      return adapter.upsertMany(action.payload, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
        clientFolderMap: clientFolderMap
      });
    case DmsFolderActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
    case DmsFolderActionTypes.LoadOne:
      return {
        ...state,
        selected: null
      };
    case DmsFolderActionTypes.Create:
    case DmsFolderActionTypes.Delete:
    case DmsFolderActionTypes.Rename:
      return {
        ...state
      };
    case DmsFolderActionTypes.LoadOneSuccess:
    case DmsFolderActionTypes.CreateSuccess:
    case DmsFolderActionTypes.RenameSuccess:
    case DmsFolderActionTypes.LockFolderSuccess:
      return adapter.upsertOne(action.folder, {
        ...state,
        selected: action.folder,
        loading: false,
      });
    case DmsFolderActionTypes.RefreshSuccess:
      if (refresh) {
        return adapter.setAll(action.payload, {
            ...state,
            loading: false,
            refresh: false
        });
      } else {
        return adapter.upsertMany(action.payload, {
          ...state,
          selected: current ? state.entities[current.id] : null,
          loading: false,
          refresh: false
        });
      }
    case DmsFolderActionTypes.DeleteSuccess:
      return adapter.removeOne(action.folder.id, {
        ...state,
        selected: action.folder,
        loading: false,
      });
    case DmsFolderActionTypes.CreateFail:
    case DmsFolderActionTypes.LoadOneFail:
    case DmsFolderActionTypes.DeleteFail:
    case DmsFolderActionTypes.RefreshFail:
    case DmsFolderActionTypes.RenameFail:
      return {
        ...state,
        selected: null,
        loading: false,
        refresh: false
      };
  }
  return state;
}

