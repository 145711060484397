import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {CacEntity} from './cac-entity';

export interface State extends EntityState<CacEntity> {
  loading: boolean;
}

export const adapter = createEntityAdapter<CacEntity>({
  sortComparer: (l, r) => CacEntity.sortByCountry(l, r)
});

export const initialState: State = adapter.getInitialState({
  loading: false
});
