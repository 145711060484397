import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {MatSidenav} from '@angular/material/sidenav';
import {FeatureSelectors, InvitationActions, NaturalPersonActions, OrganizationSelectors, PageSidebarActions,} from 'app/+store';
import {combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, takeUntil} from 'rxjs/operators';
import {SystemService} from 'app/services/system.service';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {AngularTokenService} from 'angular-token';
import * as storeCache from 'store';
import {FivefPageSidebarComponent} from 'app/lib/fivef-ui/page/fivef-page-sidebar/fivef-page-sidebar/fivef-page-sidebar.component';
import {Feature} from 'app/+store/feature/feature';
import {CmsBlog} from 'app/+store/cms-blog/cms-blog';
import {AppState} from 'app/app.state';
import {PageSidebar} from 'app/+store/page-sidebar/page-sidebar';
import {GetCurrentUser} from 'app/+store/_legacy/actions/current-user-actions';
import {CmsBlogSelectors} from 'app/+store/cms-blog';
import {User} from 'app/+store/user/user';
import * as foxdoxStatus from 'app/+store/_legacy/actions/foxdox-status.actions';
import {FivefNavLink} from '../../../../lib/fivef-ui/navigation/fivef-nav-link/fivef-nav-link.interface';
// import * as tenantModel from 'app/+store/tenant/tenant/tenant';

declare var $;

@Component({
  selector: 'dvtx-main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.scss']
})
export class MainAppComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  @ViewChild('sidebar', {static: true}) public sidebar: MatSidenav;
  @ViewChild('pageSideBarComponent') public pageSideBarComponent: FivefPageSidebarComponent;
  public error;
  public showQuickstarter = false;
  public loading: boolean = false;
  public featureSet$: Observable<Feature>;
  public sidebarMainRoutes: FivefNavLink[] = [];
  private blog$: Observable<CmsBlog>;
  public sidebarSettingsRoutes: FivefNavLink[] = [];
  public sidebarOtherRoutes: FivefNavLink[] = [];
  public currentOrganization$: Observable<any>;
  private componentBeforeNavigation: any = null;
  public sidebarCollapsed = true;
  private smallScreen: boolean;

  constructor(private _store: Store<AppState>,
              public _authTokenService: AngularTokenService,
              private _systemSvc: SystemService,
              private _router: Router,
              private route: ActivatedRoute,
              private _location: Location,
              @Inject(DOCUMENT) document,
              private _cdr: ChangeDetectorRef) {
    this._store.dispatch(new PageSidebarActions.Open(new PageSidebar()));
    const self = this;

    // resize sidebar feature
    $(document).ready(function () {
      const resizer = document.querySelector('.fivef-page-sidebar-resizer');
      const sidebar = document.querySelector('.dvtx-sidebar-nav-container');
      const appMainContainer = document.querySelector('.dvtx-app-main-container');
      const navigationWidth: any = document.querySelector('.fivef-page-sidebar--container');

      if (resizer) {
        resizer.addEventListener('mousedown', (event) => {
          document.addEventListener('mousemove', resize, false);
          document.addEventListener('mouseup', () => {
            if (appMainContainer) {
              appMainContainer.style['user-select'] = 'unset'
            }

            document.removeEventListener('mousemove', resize, false);
          }, false);
        });
      }

      function resize(e) {
        if (document.querySelector('.fivef-page-sidebar-resizer--hide')) {
          return;
        }
        const size = `${e.x}px`;
        if (appMainContainer) {
          appMainContainer.style['user-select'] = 'none'
        }
        if (e.x < 300 && sidebar && navigationWidth) {
          sidebar.style.flexBasis = '300px';
          sidebar.style['min-width'] = '300px';
          navigationWidth.style['width'] = `${300 - 70}px`
        } else if (e.x > 800 && sidebar && navigationWidth) {
          sidebar.style.flexBasis = '800px';
          sidebar.style['min-width'] = '800px';
          navigationWidth.style['width'] = `${800 - 70}px`
        } else if (sidebar && navigationWidth) {
          sidebar.style.flexBasis = size;
          sidebar.style['min-width'] = size;
          navigationWidth.style['width'] = `${e.x - 70}px`
          storeCache.set('sidebar-resize', e.x);
        }
      }
    });

    this._router.events.pipe(takeUntil(this.onDestroy)).subscribe(e => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    // Keep it for debugging: Print routes.
    // for (let i = 0; i < this._router.config.length; i++) {
    //   const path: string = this._router.config[i].path;
    //   console.log(`Route: ${path}`, this._router.config[i]);
    // }

    this.currentOrganization$ = this._store.select(OrganizationSelectors.getSelected).pipe(map(x => x));
    // TODO: why the getcurrentuser was sending param false ,, removed to fix return url of collecto page
    this._store.dispatch(new GetCurrentUser());
    // 2019-02-12: Userlance is currently disabled.
    // if (environment.production) {
    //   Userlane('init', 31843);
    // }
    this._store.select('currentUser').pipe(
      filter(u => {
        return !!u
      }),
      distinctUntilKeyChanged('uid'),
      takeUntil(this.onDestroy)
    ).subscribe((user: User) => {
      this._store.dispatch(new NaturalPersonActions.LoadMy());
      this._store.dispatch(new foxdoxStatus.GetStatus());
      this._store.dispatch(new InvitationActions.LoadMy());
    });

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.blog$ = this._store.select(CmsBlogSelectors.getCmsBlogOfSelectedOrg);
    this._activateQuickstarterToolbar();

    this.sidebar.open();

    // Installs the feature set independent main routes from the top left.
    this.setMainRoutes();

    // Installs all sub routes of the main viewport.
    observableCombineLatest(this.featureSet$, this.blog$, this.currentOrganization$)
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(([featureSet, blog, organization]) => {
        // Installs the feature set dependent main routes below the main routes.
        this.setMainSubRoutes(featureSet, organization, blog);
      });

    if (this.pageSideBarComponent) {
      this.pageSideBarComponent.configureSideNav();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event): void {
    const path = this._location.path().split('?')[0];
    // Prevent scrollup on tasks and Collectos
    //  if (/tasks/.test(path) || /collecto/.test(path)) {
    //    this.loading = false;
    //    return;
    //  }
    let loading = JSON.parse(JSON.stringify(this.loading));
    const previousLoadingState = this.loading;
    if (event instanceof NavigationStart) {
      // load spinner if navigation is to a new component
      if (!event.url.includes(path)) {
        loading = true;
      }
    }
    if (event instanceof NavigationEnd) {
      loading = false
      // scroll up if navigation is to a new component
      let currentRoute = this.route;
      while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
      if (this.componentBeforeNavigation !== currentRoute.component) {
        this.scrollUp();
      }
      this.componentBeforeNavigation = currentRoute.component;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      loading = false
    }
    if (event instanceof NavigationError) {
      loading = false
    }
    this.loading = loading;

    if (previousLoadingState !== loading) {
      this._cdr.detectChanges();
    }
  }

  private scrollUp() {
    setTimeout(() => {
      const documentSideNavContent = document.querySelector('mat-sidenav-content');
      if (documentSideNavContent) {
        documentSideNavContent.scrollTo(0, 0);
      }
    }, 100);
  }

  public setSidebarCollapsedState($event) {
    this.sidebarCollapsed = !!$event;
    this._cdr.detectChanges();
  }

  /**
   * Shows the quickstart buttons only if the route equals the dashboard.
   * Implementation uses the location service and router events to listen on the route because
   * - The app component is always present.
   * - The quickstart buttons are currently embedded inside the main layout.
   */
  private _activateQuickstarterToolbar() {
    this.checkQuickstarterToolbar();
    this._router.events
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => {
        this.checkQuickstarterToolbar();

      });
  }

  checkQuickstarterToolbar() {
    const path = this._location.path();
    this.showQuickstarter = /^\/dashboard/.test(path) && !(/\/overdue/.test(path));
  }

  openSidebar($event) {
    if (!this.sidebar) return;
    if ($event) {
      this.sidebar.open();
    } else {
      this.sidebar.close();
    }
  }

  checkSidebarToggleIcon() {
    this.smallScreen = window.innerWidth < 800 ? true : false
    if (this.smallScreen) {
      if (this.sidebar.opened) {
        return 'navigate_before';
      } else {
        return 'navigate_next'
      }
    } else {
      if (this.sidebarCollapsed) {
        return 'navigate_next'
      } else {
        return 'navigate_before';
      }
    }
  }

  /**
   * Installs the main app routes in default design at top left.
   * @private
   */
  private setMainRoutes() {
    const sidebarMainRoutes = [];
    sidebarMainRoutes.push(new FivefNavLink('BANNER.LINK.DASHBOARD_TITLE', 'home', `/dashboard`));

    // New Dashboard route.
    // sidebarMainRoutes.push(new FivefNavLink('BETA', 'dashboard', `/dashboard/beta`));

    sidebarMainRoutes.push(new FivefNavLink('BANNER.LINK.PROJECT_ROOMS_TITLE', 'projectroom', `/workflows/my-workflows`));
    sidebarMainRoutes.push(new FivefNavLink('TASK.TITLE', 'tasks', `/tasks/my`));
    sidebarMainRoutes.forEach(r => r.orientation = 'vertical');
    this.sidebarMainRoutes = sidebarMainRoutes;
    this._cdr.detectChanges();
  }

  /**
   * Installs all sub routes in main design below main routes.
   * Also installs the FAQ route at the bottom part of the left side.
   *
   * @param featureSet
   * @param organization
   * @param blog
   * @private
   */
  private setMainSubRoutes(featureSet, organization, blog) {
    const sidebarSettingsRoutes = [];
    const faqRoute = new FivefNavLink('BANNER.LINK.FAQ_TITLE', 'help', `/faq`);
    faqRoute.orientation = 'vertical';
    sidebarSettingsRoutes.push(faqRoute);

    const sidebarOtherRoutes = [];
    if (featureSet && featureSet.hasFastdocs && organization) {
      sidebarOtherRoutes.push(new FivefNavLink('FASTDOCS.DASHBOARD_STARTER_TITLE', 'lohn', `/fastdocs/container`));
    }

    if (featureSet && organization && blog && featureSet.hasCms && blog.publishedAt) {
      sidebarOtherRoutes.push(new FivefNavLink(blog.linkTitle, 'article', `/cms/content/${organization?.id}/articles`));
    }

    if (featureSet && featureSet.hasLabels) {
      sidebarOtherRoutes.push(new FivefNavLink('LABELS.LABELS_OVERVIEW', 'labels', `/label-items`));
    }

    // TODO: Grant Thornton purplehub link
    //       - Authorize by tenant membership and GT domain.
    // if (tenantModel.Tenant.Tenant.isGrantThornton()) {
    //   this.sidebarOtherRoutes.push(new SidebarRoute('Purplehub', 'gt', `/`));
    // }

    sidebarOtherRoutes.forEach(r => r.orientation = 'vertical');
    this.sidebarSettingsRoutes = sidebarSettingsRoutes;
    this.sidebarOtherRoutes = sidebarOtherRoutes;
    this._cdr.detectChanges();
  }
}
