<ng-container [ngSwitch]="_appearance" *ngIf="!disabled">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="ClientCreateActionAppearance.CreateButton">
    <button mat-icon-button
            class="ff-icon-sm"
            type="button" [disabled]="readonly" (click)="$event.preventDefault(); openDialog()"
            [disableRipple]="true">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientCreateActionAppearance.CreateRaisedButton">
    <button mat-raised-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon matPrefix>add</mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientCreateActionAppearance.CreateBasicButton">
    <button mat-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon matPrefix>add</mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientCreateActionAppearance.EditButton">
    <button mat-icon-button type="button" (click)="$event.preventDefault(); openDialog()"
            [disabled]="readonly"
            [disableRipple]="true">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientCreateActionAppearance.DeleteButton">
    <button mat-icon-button
            (click)="$event.preventDefault(); deleteClient(client?.id)"
            [disabled]="readonly || !client?.canDeleteClient">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </ng-container>

  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="ClientCreateActionAppearance.Form">
    <div class="d-flex flex-column" style="width: 100%">
      <ng-container [ngTemplateOutlet]="clientCreationForm">
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #clientCreationForm>
  <form [formGroup]="form" novalidate>
    <input type="hidden" formControlName="contactId"/>
    <input type="hidden" formControlName="legalForm"/>

    <div class="ff-icon-content ff-icon-content--floating-labels">
      <mat-icon svgIcon="clients"></mat-icon>
      <div class="ff-icon-content--content">
        <mat-form-field>
          <mat-label>{{'CLIENT.NAME' | translate}}</mat-label>
          <input matInput formControlName="name" [readonly]="readonly"/>
        </mat-form-field>
      </div>
    </div>

    <div class="ff-icon-content ff-icon-content--floating-labels">
      <mat-icon svgIcon="datev"></mat-icon>
      <div class="ff-icon-content--content">
        <mat-form-field>
          <mat-label>{{'CLIENT.CLIENT_ID' | translate}}</mat-label>
          <input matInput formControlName="clientId" [readonly]="readonly"/>

          <button matSuffix mat-icon-button [matTooltip]="'CLIENT.CLIENT_ID_INFO' | translate">
            <mat-icon>info</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #clientCreationDialogTpl>
  <fivef-dialog [title]="dialogTitle" [isCallToAction]="true" *ngIf="showForm">
    <ng-container [ngTemplateOutlet]="clientCreationForm"></ng-container>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>

      <button mat-raised-button
              color="primary"
              [disabled]="form.pristine || form.invalid || submitOngoing"
              (click)="submit()">
        <mat-icon matPrefix>save</mat-icon>
        {{ saveButtonTitle | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
