import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FivefApiResourceService } from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import { BookmanClient } from './bookman-client';
import { BookmanClientBuilder } from './bookman-client.builder';

@Injectable()
export class BookmanClientService {
  readonly BASE_PATH = 'api/v1/fibu/clients';

  constructor(private _http: FivefApiResourceService) { }

  getAll(): Observable<BookmanClient[]> {
    const builder = new BookmanClientBuilder();
    return <Observable<BookmanClient[]>>this._http.get<BookmanClientBuilder, BookmanClient>(builder, this.BASE_PATH);
  }

  refresh(): Observable<BookmanClient[]> {
    const builder = new BookmanClientBuilder();
    return <Observable<BookmanClient[]>>this._http.get<BookmanClientBuilder, BookmanClient>(builder, `${this.BASE_PATH}/refresh`);
  }

}
