<fivef-dialog [color]="color" [title]="'ADDRESSBOOK.CREATE_CONTACT'">
  <div>
    <span class="mb-1 d-block">{{ 'CONTACTS.SET_VISIBILITY_FOR_INFORMATION' | translate }}</span>
    <fivef-contact-visibility-select [(ngModel)]="contactVisibility"></fivef-contact-visibility-select>

    <fivef-contact-person
      class="mt-2"
      [newContactMode]="true"
      [(ngModel)]="createContactForm"
    ></fivef-contact-person>
  </div>

  <div fivef-dialog-footer>
    <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>

    <button mat-raised-button color="primary"
            (click)="createContact()"
            [disabled]="contactType === contactTypes.naturalPerson && !createContactForm.isValid">
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>

    <button *ngIf="contactType === contactTypes.naturalPerson && !hideInviteButton"
            mat-raised-button
            color="primary"
            (click)="createContact(true)"
            [disabled]="contactType === contactTypes.naturalPerson && !createContactForm.isValid">
      {{ 'ADDRESSBOOK.SAVE_AND_INVITE_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
