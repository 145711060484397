import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Motd} from './motd';

export class MotdBuilder implements IApiResourceBuilder<Motd> {
  fromResponse(data): Motd {
    return new Motd(
      data.id,
      data.attributes.title,
      data.attributes.subtitle,
      data.attributes.content,
      data.attributes.author_email,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(motd: Motd) {
    return {
      data: {
        id: motd.id,
        type: motd.type,
        attributes: {
          title: motd.title,
          subtitle: motd.subtitle,
          content: motd.content,
          published: !!motd.publishedAt
        }
      }
    };
  }
}
