import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {TaskResourceBuilder} from './task-resource.builder';
import {TaskResource} from './task-resource';
import {TaskBuilder} from '../task/task.builder';
import {Task} from '../task/task';
import {TaskResourceType} from './task-resource.interface';

@Injectable()
export class TaskResourceService {
  readonly base_path = 'api/v1/tasks';

  builder: TaskResourceBuilder;

  constructor(private _http: FivefApiResourceService) {
  }

  remove(taskId, resourceId: string): Observable<TaskResource> {
    return <Observable<TaskResource>>this._http.del<TaskResourceBuilder, TaskResource>(this.builder, `${this.base_path}/tasks/${taskId}/resources/${resourceId}`);
  }

  create(taskId, resource: TaskResource, referenceId: string = null, referenceType: TaskResourceType = null): Observable<Task> {
    const queryParam = referenceId && referenceType ? `?reference_id=${referenceId}&reference_type=${referenceType}` : '';
    const requestBuilder = new TaskResourceBuilder();
    const payload = requestBuilder.toRequest(resource);
    const builder = new TaskBuilder();
    return <Observable<Task>>this._http.post<TaskBuilder, Task>(builder, `${this.base_path}/tasks/${taskId}/resources${queryParam}`, payload);
  }

  update(taskId, resource: TaskResource): Observable<TaskResource> {
    const builder = new TaskResourceBuilder();
    const payload = builder.toRequest(resource);
    return <Observable<TaskResource>>this._http.put<TaskResourceBuilder, TaskResource>(this.builder, `${this.base_path}/tasks/${taskId}/${resource.resourceType}/${resource.id}`, payload);
  }
}
