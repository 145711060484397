import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map, takeUntil} from 'rxjs/operators';
import {
  CANDIDATES_CLIENT_TITLE_ROW,
  CANDIDATES_CONTACT_TITLE_ROW,
  CANDIDATES_TITLE_ROW,
  IFivefItemSelectorItem,
  IFivefSelectorItemType,
  IFivefSelectorSelectionKey,
  SELECTED_CLIENT_TITLE_ROW,
  SELECTED_CONTACT_TITLE_ROW,
  SELECTED_TITLE_ROW
} from './fivef-item-selector.interface';

/**
 * Data repository for item selector.
 * The main data type is IFivefItemSelectorItem.
 *
 * Provides all selected items as `selectedData` and the current
 * filtered data as `filteredData`.
 *
 * All items can be searched by `search(term)`.
 *
 * The listing itself is splitted: All selected entries are shown on top
 * with a leading messsage.
 */
@Injectable()
export class FivefItemSelectorRepository implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  private resources$ = new BehaviorSubject<IFivefItemSelectorItem[]>([]);
  private selectedResources$ = new BehaviorSubject<any[]>([]);
  private resourceSelectorKey$ = new BehaviorSubject<IFivefSelectorSelectionKey>('id');

  private searchTerm$ = new BehaviorSubject<string>(null);

  public filteredData: Observable<any[]>;
  public selectedData: Observable<any[]>;

  public selectionTitle: string;
  public collectionTitle: string;

  public actions: IFivefItemSelectorItem[] = [];

  /**
   * Creates a map of selected item IDs by the references selection key of the
   * selection object.
   *
   * The selection key must map the primary key of the candidates collection.
   *
   * @param selectedItems
   * @param key
   * @private
   */
  private getSelectedResourceIds(selectedItems: any[], key: IFivefSelectorSelectionKey) {
    const selectedItemIds = {};
    const selected = selectedItems.filter(i => !!i && !!i[key]);
    if (selected?.length && key) {
      selected.forEach(item => selectedItemIds[item[key]] = true);
    }
    return selectedItemIds;
  }

  constructor() {
    this.initFilteredData();
  }

  private initFilteredData() {
    // Prepare the list items and provide all current selected items as is.
    const filteredData$ = combineLatest(this.resources$, this.selectedResources$, this.resourceSelectorKey$)
      .pipe(
        takeUntil(this.onDestroy),
        map(([data, selected, key]: [any, any, IFivefSelectorSelectionKey]) => {
          const selectedItemIds = this.getSelectedResourceIds(selected, key);
          const resourceCandidates = [];
          const selectedResources = [];
          const currentSelectedResources = [];

          const duplicateCheckMap = {};
          data.forEach(item => {
            if (!item?.id) {
              return;
            }

            if (selectedItemIds[item.id]) {
              if (duplicateCheckMap[item.id]) {
                return;
              }
              duplicateCheckMap[item.id] = true;

              const elem = this.transform('selected', item);
              selectedResources.push(elem);
              currentSelectedResources.push(item);
              resourceCandidates.push(elem);
            } else {
              resourceCandidates.push(this.transform('candidate', item));
            }
          });
          return {resourceCandidates, selectedResources, currentSelectedResources}
        }));

    this.selectedData = filteredData$.pipe(map(data => data.currentSelectedResources));
    this.filteredData = combineLatest(filteredData$, this.searchTerm$, this.resourceSelectorKey$)
      .pipe(
        takeUntil(this.onDestroy),
        map(([data, searchTerm, resourceSelectorKey]: [any, string, IFivefSelectorSelectionKey]) => {
          let resources: IFivefItemSelectorItem[] = [this.getCandidatesTitle(resourceSelectorKey), ...data.resourceCandidates];
          if (data.selectedResources.length && resourceSelectorKey) {
            resources = [
              this.getLeadingSelectedTitle(resourceSelectorKey),
              ...data.selectedResources,
              ...resources
            ];
          }

          if (this.actions.length) {
            resources = [{id: 'PROJECT_ROOM.ACTIONS', type: 'message'}, ...this.actions, ...resources];
          }

          if (!!searchTerm && typeof searchTerm === 'string' && searchTerm.length) {
            const filteredResources = this.findByTerm(resources, searchTerm);
            if (filteredResources && filteredResources.filter(a => a.type !== 'message').length > 0) {
              return filteredResources;
            } else {
              return [];
            }
          }

          return resources;
        }));
  }

  findByTerm(resources: IFivefItemSelectorItem[], searchTerm: string) {
    const term = searchTerm.trim().toLowerCase();
    return resources.filter(lineItem => {
      if (lineItem.type === 'message') {
        return true;
      }

      if (lineItem.type === 'action') {
        return true;
      }

      const item = lineItem.data;
      let match = !!item && !!item.name && item.name.toLowerCase().indexOf(term) > -1;
      match = match || !!item.email && item.email.toLowerCase().indexOf(term) > -1;
      match = match || !!item.firstName && item.firstName.toLowerCase().indexOf(term) > -1;
      match = match || !!item.lastName && item.lastName.toLowerCase().indexOf(term) > -1;
      return match;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.resources$.complete();
    this.selectedResources$.complete();
    this.resourceSelectorKey$.complete();
    this.searchTerm$.complete();
  }

  public search(term) {
    this.searchTerm$.next(term);
  }

  set data(resources: any[]) {
    this.resources$.next(resources);
  }

  set selected(selectedItems: any[]) {
    this.selectedResources$.next(selectedItems);
  }

  set resourceSelectorKey(rk: IFivefSelectorSelectionKey) {
    this.resourceSelectorKey$.next(rk);
  }

  private transform(type: IFivefSelectorItemType, dataItem: any): IFivefItemSelectorItem {
    return {
      id: dataItem.id,
      type: type,
      data: dataItem
    }
  }

  /**
   * Default heading of selected items.
   * For the client and contact model we maintain defaults.
   *
   * @param resourceSelectorKey
   * @private
   */
  private getLeadingSelectedTitle(resourceSelectorKey: IFivefSelectorSelectionKey) {
    if (this.selectionTitle) {
      return {id: this.selectionTitle, type: 'message'};
    }

    if (resourceSelectorKey === 'contactId') {
      return SELECTED_CONTACT_TITLE_ROW;
    } else if (resourceSelectorKey === 'clientId') {
      return SELECTED_CLIENT_TITLE_ROW;
    } else {
      return SELECTED_TITLE_ROW;
    }
  }

  /**
   * Default heading of candidates collection.
   * For the client and contact model we maintain defaults.
   *
   * @param resourceSelectorKey
   * @private
   */
  private getCandidatesTitle(resourceSelectorKey: IFivefSelectorSelectionKey) {
    if (this.collectionTitle) {
      return {id: this.collectionTitle, type: 'message'};
    }

    if (resourceSelectorKey === 'contactId') {
      return CANDIDATES_CONTACT_TITLE_ROW;
    } else if (resourceSelectorKey === 'clientId') {
      return CANDIDATES_CLIENT_TITLE_ROW;
    } else {
      return CANDIDATES_TITLE_ROW;
    }
  }
}
