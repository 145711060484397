import {Router} from '@angular/router';
import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ProcessBuilder} from '../process/process.builder';
import {BasicContact, CacAppendix, CacProcess, FileAttachment, CacEntity} from './cac';
import {ProcessStatus} from '../process/process';
import {AppendixStatusEnum, ApplicableState} from './cac.interface';
import {ProcessProfile} from '../process/process.interface';
import {CacStatistics} from './cac-appendix';
import {DateUtil} from 'app/lib/date/date-util';

export class FileAttachmentBuilder implements IApiResourceBuilder<FileAttachment> {
  fromResponse(data): FileAttachment {
    const attrs = data.attributes;
    const attachment = new FileAttachment(data.id, attrs.display_name, attrs.filename, attrs.description,
      attrs.size, attrs.mime_type, attrs.uploaded_by, attrs.fingerprint,
      attrs.reference_id, attrs.created_at, attrs.updated_at);
    attachment.content = attrs.content;
    return attachment;
  }

  toRequest(model: FileAttachment) {
    return null;
  }
}

export class CacStatisticsBuilder implements IApiResourceBuilder<CacStatistics> {
  fromResponse(data): CacStatistics {
    return new CacStatistics(
      data.id,
      data.attributes.societies,
      data.attributes.sent,
      data.attributes.overdue,
      data.attributes.not_applicable,
      data.attributes.in_progress,
      data.attributes.closed);
  }

  toRequest(model: CacStatistics) {
    return null;
  }
}

export class BasicContactBuilder implements IApiResourceBuilder<BasicContact> {
  fromResponse(data): BasicContact {
    return null;
  }

  toRequest(model: BasicContact) {
    return {
      data: {
        id: model.id,
        type: 'basic_contacts',
        attributes: {
          email: model.email,
          gender: model.gender,
          title: model.title,
          verified: !!model.verifiedAt,
          first_name: model.firstName,
          last_name: model.lastName,
          role: model.role,
          phone: model.phone,
          mobile: model.mobile,
          notification_language: model.notificationLanguage
        }
      }
    }
  }
}

export class CacAppendixBuilder implements IApiResourceBuilder<CacAppendix> {
  constructor(private _processId: string) {
  }

  fromResponse(data): CacAppendix {
    const attr = data.attributes;
    const app = new CacAppendix(data.id, attr.title, attr.due_date, attr.description, attr.checked, attr.references, attr.created_at, attr.updated_at);
    app.processId = this._processId;
    return app;
  }

  toRequest(model: CacAppendix) {
    return null;
  }
}

export class CacBuilder implements IApiResourceBuilder<CacProcess> {
  total = 1;
  perPage = 1;
  records = 1;

  now = new Date();
  processBuilder: ProcessBuilder;

  attachments: {
    [id: string]: FileAttachment
  } = {};

  defaultAppendices: {
    [id: string]: CacAppendix
  } = {};

  defaultAppendixAttachmentCount: {
    [id: string]: number
  } = {};

  attachmentReferences: {
    [id: string]: FileAttachment[]
  } = {};

  constructor(private _router: Router = null) {
    this.processBuilder = new ProcessBuilder(_router);
  }

  fromResponse(data): CacProcess {
    if (!data) {
      return null
    }
    const process = this.processBuilder.fromResponse(data);
    const cac = CacProcess.fromProcess(process);
    const attr = data.attributes;
    cac.status = new ProcessStatus(attr.status.code, attr.status.message, attr.status.info_level, attr.status.icon);
    const isClosed = cac.status.isClosed();
    cac.parentId = attr.parent_id;
    cac.startedAt = attr.started_at;
    cac.color = attr.color;
    cac.profile = attr.profile;
    cac.contactImportId = attr.reference_uuid;
    cac.organizationId = attr.organization_uuid;

    cac.instructionNodeId = attr.instruction_node_id;
    cac.attachments = this.attachmentReferences[cac.instructionNodeId] || [];
    const instructionAttachmentCount = cac.attachments.length;

    this.defaultAppendices = {};
    this.defaultAppendixAttachmentCount = {};
    if (attr.default_appendices && attr.default_appendices.length > 0) {
      cac.appendices = attr.default_appendices.map(appendix => {
        const a = new CacAppendix(
          appendix.id,
          appendix.title,
          appendix.due_date,
          appendix.description,
          appendix.checked,
          appendix.references,
          appendix.created_at,
          appendix.updated_at
        );
        this._setAppendixAttributes(cac, a, isClosed, appendix.applicable, appendix.status);
        a.startedAt = appendix.started_at;
        a.deliveredAt = appendix.delivered_at;
        this.defaultAppendices[a.id] = a;
        this.defaultAppendixAttachmentCount[a.id] = (a.attachments && a.attachments.length) ? a.attachments.length : 0;
        return a;
      });
    }
    if (attr.societies && attr.societies.length > 0) {
      cac.societies = attr.societies.map(society => {
        const _society = new CacEntity(society.id, society.name, society.country_code, society.abbreviation,
          society.component_materiality, society.tolerance_materiality, society.tolerance_limit,
          society.currency, society.comment);
        _society.checked = society.checked;
        _society.processId = cac.id;
        _society.partnerNo = society.partner_no;
        if (society.contacts && society.contacts.length > 0) {
          _society.contacts = society.contacts.map(contact => {
            const c = new BasicContact(contact.id, contact.email, contact.gender, contact.title,
              contact.first_name, contact.last_name, contact.role);
            c.phone = contact.phone;
            c.mobile = contact.mobile;
            c.notificationLanguage = contact.notification_language;
            c.verifiedAt = contact.verified_at;
            c.hasAccount = contact.has_account;
            c.hasRegisteredAccount = contact.has_registered_account;
            c.verificationProcessId = contact.process_reference_id;
            return c;
          });
        }
        if (society.appendices && society.appendices.length > 0) {
          _society.appendices = society.appendices.map(appendix => {
            const a = new CacAppendix(
              appendix.id,
              appendix.title,
              appendix.due_date,
              appendix.description,
              appendix.checked,
              appendix.references,
              appendix.created_at,
              appendix.updated_at
            );
            if (a.reference) {
              _society.defaultAppendixReferenceMap[a.reference] = a;
            }
            a.startedAt = appendix.started_at;
            a.deliveredAt = appendix.delivered_at;
            a.attachmentCount = a.reference && this.defaultAppendixAttachmentCount[a.reference] ? this.defaultAppendixAttachmentCount[a.reference] : 0;

            this._setAppendixAttributes(cac, a, isClosed, appendix.applicable, appendix.status, society);
            return a;
          });
          _society.attachmentCount = _society.appendices.reduce((acc, app) => {
            if (!app.checked) return acc;
            const specificAttachments = (app.attachments && app.attachments.length) ? app.attachments.length : 0;
            const contributedAttachments = (app.contributedDocuments && app.contributedDocuments.length) ? app.contributedDocuments.length : 0;
            const generalAttachmentsCount = app.reference && this.defaultAppendixAttachmentCount[app.reference] ? this.defaultAppendixAttachmentCount[app.reference] : 0;
            return acc + specificAttachments + contributedAttachments + generalAttachmentsCount;
          }, instructionAttachmentCount);
          _society.instructionAttachmentCount = instructionAttachmentCount;
        }
        _society.color = society.color;
        return _society;
      });
    }

    switch (cac.profile) {
      case ProcessProfile.StandardProfile:
        cac.isCommunication = true;
        break;
      case ProcessProfile.AuditingProfile:
        cac.isAuditing = true;
        break;
    }

    try {
      // data.relationships.attachments.data.map( attachment => {
      //   cac.attachments.push(this.attachmentReferences[attachment.id]);
      // });

    } catch (err) {
      console.error('ERROR: Add included section failed', err);
    }

    cac.total = this.total;
    cac.perPage = this.perPage;
    cac.records = this.records;

    return cac;
  }

  toRequest(_: CacProcess) {
    return null;
  }

  addIncludedSection(includes) {
    if (!includes || includes.length === 0) {
      return;
    }
    const builder: FileAttachmentBuilder = new FileAttachmentBuilder();

    includes.map((include) => {
      if (include.type === 'bom_tree_attachments') {
        const _attachment = builder.fromResponse(include);
        this.attachments[include.id] = _attachment;
        if (!this.attachmentReferences[_attachment.referenceId]) {
          this.attachmentReferences[_attachment.referenceId] = [];
        }
        this.attachmentReferences[_attachment.referenceId].push(_attachment);
      }
    });
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.total = meta.total;
    this.perPage = meta.per_page;
    this.records = meta.records;
  }

  private _setAppendixAttributes(cac: CacProcess, appendix: CacAppendix, isClosed: boolean, isApplicable: boolean, status: string, society: CacEntity = null) {
    appendix.processId = cac.id;
    if (society) {
      appendix.societyId = society.id;
    }

    appendix.closed = isClosed || status === 'closed';
    appendix.applicable = isApplicable ? ApplicableState.Applicable : (isApplicable === false ? ApplicableState.NotApplicable : ApplicableState.None);
    appendix.attachments = this.attachmentReferences[appendix.id];

    appendix.status = AppendixStatusEnum.sent;
    appendix.overdued = !!appendix.dueDate && DateUtil.inFutureOrToday(new Date(appendix.dueDate), this.now)
    appendix.inTime = !!appendix.dueDate && !!appendix.deliveredAt && DateUtil.inFutureOrToday(new Date(appendix.deliveredAt), new Date(appendix.dueDate));

    appendix.overdued = appendix.overdued && !appendix.inTime;

    if (isClosed || appendix.closed) {
      appendix.status = AppendixStatusEnum.closed;
      // appendix.overdued = false;

    } else if (appendix.applicable === ApplicableState.NotApplicable) {
      appendix.status = AppendixStatusEnum.not_applicable;

    } else if (status === 'completed' || status === 'in_progress') {
        appendix.status = AppendixStatusEnum.in_progress;

    } else {
      appendix.status = AppendixStatusEnum.sent;
    }
  }
}
