import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {DocumentTemplateBuilder} from './document-template.builder';
import {DocumentTemplate} from './document-template';

@Injectable()
export class DocumentTemplateService {
  readonly BASE_PATH = 'api/v1/templates/documents';
  builder: DocumentTemplateBuilder;

  constructor(private _http: FivefApiResourceService) {
    this.builder = new DocumentTemplateBuilder();
  }

  getAll(): Observable<DocumentTemplate[]> {
    return this._http.getAll<DocumentTemplateBuilder, DocumentTemplate>(this.builder, this.BASE_PATH);
  }

  download(id: string) {
    return <Observable<DocumentTemplate>>this._http.get<DocumentTemplateBuilder, DocumentTemplate>(this.builder, `${this.BASE_PATH}/${id}/download`);
  }

  runTest(id: string) {
    return <Observable<DocumentTemplate>>this._http.get<DocumentTemplateBuilder, DocumentTemplate>(this.builder, `${this.BASE_PATH}/${id}/test`);
  }

  downloadSample() {
    return <Observable<DocumentTemplate>>this._http.get<DocumentTemplateBuilder, DocumentTemplate>(this.builder, `${this.BASE_PATH}/default`);
  }

  update(id, publish): Observable<DocumentTemplate> {
    const payload = {
      data: {
        attributes: {
          publish: publish
        }
      }
    };
    return this._http.put<DocumentTemplateBuilder, DocumentTemplate>(this.builder, `${this.BASE_PATH}/${id}`, payload);
  }

  remove(resource: DocumentTemplate): Observable<DocumentTemplate> {
    return this._http.delete<DocumentTemplateBuilder, DocumentTemplate>(this.builder, this.BASE_PATH, resource);
  }
}
