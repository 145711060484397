<mat-form-field class="fivef-toolbar--search"
                fivefDisableInputHints
                (keydown.enter)="$event.preventDefault()">
  <input type="text"
         [placeholder]="'GENERAL.SEARCH' | translate"
         [disabled]="disabled"
         [value]="searchTerm"
         [(ngModel)]="searchTerm"
         (ngModelChange)="search(searchFieldRef.value)"
         matInput
         #searchFieldRef>
  <mat-icon matPrefix>search</mat-icon>

  <button *ngIf="searchFieldRef?.value"
          matSuffix
          mat-icon-button
          [disableRipple]="true"
          aria-label="Clear"
          (click)="searchFieldRef.value = ''; search(''); $event.stopPropagation()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
