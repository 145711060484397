import {ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {FivefControlValueAccessor} from 'app/lib/fivef-ui/input/fivef-control-value-accessor.directive';
import {environment} from 'environments/environment';
import Tribute from 'tributejs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {FroalaRepository} from '../../../../../+store/froala/froala.repository';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

declare let $: any; // The Froala instance will be attached to the $ variable

@Component({
  selector: 'dvtx-emoji-input',
  templateUrl: './fivef-emoji-input.component.html',
  styleUrls: ['./fivef-emoji-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefEmojiInputComponent),
      multi: true,
    }
  ]
})
export class FivefEmojiInputComponent extends FivefControlValueAccessor implements OnDestroy, OnInit {
  private onDestroy = new Subject();

  private _message: string = '';
  private _froalaInstance: any;
  // private tribute: Tribute<any> = new Tribute({values: []});
  private processId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _froalaInstance$: BehaviorSubject<any> = new BehaviorSubject(null);
  // private tribute$: Observable<any>;
  private returnedCachedValue$: Observable<string>;
  private repository: FroalaRepository;

  @Input()
  height = 100;

  @Input()
  heightMax = 500;

  DEFAULT_OPTIONS;
  _options;
  dasError: boolean = false;
  fileError: boolean = false;

  caretPos: number = 0;

  @ViewChild('editor', {static: true})
  editor: ElementRef;

  @Input()
  debug = false;

  @Input()
  placeholder = 'MESSAGING.PLACEHOLDER';

  @Output()
  onSend = new EventEmitter();

  @Output()
  onSave = new EventEmitter();

  @Output()
  onCancel = new EventEmitter();

  @Input()
  editMode = false;

  @Input()
  disableEditButton = false;

  @Input()
  enableCancelButton = false;

  @Input() set message(msg: string) {
    this._message = msg;
    this.notifyOnChange(msg);
    this.notifyOnTouch();
  }

  @Input() set processId(id: string) {
    this.processId$.next(id);
  }

  @Input() set value(value) {
    this.message = value
  }

  @Input() private id: string;

  get message(): string {
    return this._message;
  }

  get options() {
    return Object.assign({}, this.DEFAULT_OPTIONS, {
      placeholderText: this.editor.nativeElement.placeholder,
      heightMax: this.heightMax
    }, this._options);
  }

  constructor(protected injector: Injector,
              private _cdr: ChangeDetectorRef,
              private _store: Store<AppState>) {
    super();

    this.initOptions();
    this.repository = new FroalaRepository(this._store)
    // this.tribute$ = this.repository.tribute;
    this.returnedCachedValue$ = this.repository.returnedCachedValue;
  }

  ngOnInit(): void {
    this.processId$.pipe(distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe(id => {
      this.repository.processId = id;
      this.repository.froalaId = this.id;
      this.repository.initCachedText();

      // if (!id) {
      //   this.tribute = new Tribute({values: []});
      //   if (this._froalaInstance) {
      //     this.tribute.detach(this._froalaInstance.el);
      //   }
      // }
    });

    // Raises an error for non-initialized Observable
    // this._initTribute();

    this._initCachedFroalaText();
  }

  private _initCachedFroalaText() {
    this.returnedCachedValue$.pipe(takeUntil(this.onDestroy)).subscribe(cachedValue => {
      if (cachedValue) {
        this.message = cachedValue;
        this._cdr.detectChanges();
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete;
    this._froalaInstance$.complete();

    // if (this._froalaInstance) {
    //   this.tribute.detach(this._froalaInstance.el);
    // }
  }

  writeValue(value): void {
    if (value !== undefined) {
      this.message = value;
      this.notifyOnChange(this.message);
    }
  }

  getCaretPos($event) {
    if ($event.selectionStart || String($event.selectionStart) === '0') {
      this.caretPos = $event.selectionStart;
    }
  }

  insertEmoji($event) {
    // const styles = this._emojiSvc.emojiSpriteStyles($event.emoji.sheet);
    // const el = document.createElement('span');
    // Object.assign(el.style, styles);
    this.insertAtMarker($event.emoji.native);
  }

  initializeLink(controls) {
    controls.initialize();
    this._froalaInstance = controls.getEditor();
    this._froalaInstance$.next(controls.getEditor());
  }

  insertAtMarker(value: string) {
    const html: string = this._froalaInstance.html.get(true);
    const index = html.search(/<span class=.fr-marker.*?>.*?<\/span>/)
    const newHtml = index === -1 ? `${html}${value}` : [html.slice(0, index), value, html.slice(index)].join('');
    this._froalaInstance.html.set(newHtml);
    this.message = newHtml;
  }

  // private _initTribute() {
  //   combineLatest(this.tribute$, this._froalaInstance$).pipe(takeUntil(this.onDestroy), distinctUntilChanged()).subscribe(
  //     ([tribute, froalaInstance]) => {
  //       this.tribute = tribute;
  //       if (tribute && froalaInstance) {
  //         this.tribute.detach(froalaInstance.el)
  //         this.tribute.attach(froalaInstance.el)
  //       } else {
  //         this.tribute = new Tribute({values: []});
  //         if (froalaInstance) {
  //           this.tribute.detach(froalaInstance.el);
  //         }
  //       }
  //     })
  // }

  initOptions() {
    const self = this;
    this.DEFAULT_OPTIONS = {
      key: environment.froalaKey,
      attribution: false,
      toolbarButtons: [['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'clearFormatting', 'insertTable', 'html']], // 'insertVideo',
      // 'imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'
      toolbarButtonsXS: [['undo', 'redo', '-', 'bold', 'italic', 'underline']],
      // videoResponsive: true,
      // videoInsertButtons: ['videoByURL', 'videoEmbed'],
      charCounterCount: false,
      quickInsertTags: null,
      fontSizeDefaultSelection: '14',
      heightMin: this.height,
      heightMax: this.heightMax,
      zIndex: 9999,
      enter: $.FE?.ENTER_BR,
      imageMaxSize: 200 * 1024,
      fileAllowedTypes: ['*'],
      fileMaxSize: 20 * 1024 * 1024,
      fileUpload: true,
      draggable: true,
      events: {
        initialized: function () {
          // const editor = this;
          // if (self.tribute) {
          //   editor.events.on('keydown', function (e) {
          //     if (e.which == 13 && self.tribute.isActive) {
          //       return false;
          //     }
          //   }, true);
          // }
        },
        'image.beforeUpload': function (files) {
          const editor = this;
          self.fileError = false;
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            let dasFiles = files[0];
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 100000) {  // around 100KB
              self.dasError = true;
              editor.image.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
        },
        'file.beforeUpload': function (files) {
          const editor = this;
          self.fileError = true;
          self.dasError = false;
          editor.popups.hideAll();
          self._cdr.detectChanges();
          return false;
          let dasFiles = files[0];
          if (files.length) {
            const reader = new FileReader();
            reader.onload = function (event: any) {
              const result = event.target.result;
              editor.file.insert(result, dasFiles.name, {result: result});
            };
            // if image is copy pasted convert blob to file
            if (dasFiles && dasFiles.type && !dasFiles.name) {
              // A Blob() is almost a File() - it's just missing the two properties below which we will add
              // Cast to a File() type
              dasFiles = new File([dasFiles], 'name', {type: dasFiles.type.replace(';base64', '')});
            }
            const imageSize = dasFiles.size;
            if (imageSize > 2000000) {  // around 20MB
              self.fileError = true;
              editor.file.remove();
            } else {
              reader.readAsDataURL(dasFiles);
              self.dasError = false;
            }
          }
          editor.popups.hideAll();
          return false;
        },
        'froalaEditor.initialized': (_e, editor) => {
          this._froalaInstance = editor;
        },
        'blur': () => {
          this._froalaInstance.selection.save();
          this.repository.cacheFroalaText(this.editor.nativeElement.value);
        },
        'focus': () => {
          // This is done to remove any invisible space when focusing in an empty editor
          if (this._froalaInstance.html.get(true) === '') {
            this._froalaInstance.html.set('');
          }
        }
      }
    };
  }

  send($event) {
    this.onSend.emit($event);
    this.repository.RemoveCachedFroala()
  }
}
