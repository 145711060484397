import {Directive, HostBinding} from '@angular/core';

@Directive({
  selector: '[fivefContextSidebarContent]',
  standalone: true
})
export class FivefContextSidebarContentDirective {
  @HostBinding('class')
  contentClass = 'fivef-context-sidebar-content';
}
