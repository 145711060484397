import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {BomDataNodeBuilder} from './bom-data-node.builder';
import {BomDataNode} from './bom-data-node';
import {ItemStatus, ItemStatusToApiStatusMapping} from '../collector/collector.interface';
import {IBomDataNodeParams} from './bom-data-node.interface';

/**
 * API service for the generic Data Node API v3.
 *
 * Replaces the redundant Collecto, Quickshare and CAC APIs and introduces Collecto features
 * to all of BOM driven workflows.
 *
 * The response type is BomDataNode and must be casted to the workflow's node type.
 * See also CollectorIten and CollectorCategory.
 */
@Injectable({providedIn: 'root'})
export class BomDataNodeService {
  readonly BASE_PATH = 'api/v3/workflow_engine/processes';
  readonly BASE_PATH_SUFFIX = 'data_nodes';

  constructor(private http: FivefApiResourceService) {}

  /**
   * Get all data nodes.
   *
   * @param processId
   * @param parentNodeId If given only sub nodes of parent given by parentNodeId are fetched.
   */
  getAll(processId: string, parentNodeId: string = null): Observable<BomDataNode[]> {
    const builder = new BomDataNodeBuilder(processId);
    const query = parentNodeId ? `?parent_id=${parentNodeId}` : '';
    return <Observable<BomDataNode[]>>this.http.get<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}${query}`);
  }

  /**
   * Get single node with id nodeId
   *
   * ATTENTION: Response properties are whitelisted and authorized by the API.
   *
   * @param processId
   * @param nodeId
   */
  getOne(processId: string, nodeId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.get<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}`);
  }

  /**
   * Creates a new BOM node item.
   * If parentId is given, then the value is created as child.
   *
   * ATTENTION: Response properties are whitelisted and authorized by the API.
   *
   * @param processId
   * @param params
   * @param parentId
   */
  create(processId: string, params: IBomDataNodeParams, parentId: string = null): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const query = parentId ? `?parent_id=${parentId}` : '';
    const payload = { data: { attributes: { title: params.title, color: params.color } } };
    if (params.description) payload.data.attributes['description'] = params.description;
    if (params.priority) payload.data.attributes['priority'] = params.priority.toLowerCase();
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}${query}`, payload);
  }

  /**
   * Updates a BOM node item.
   *
   * See also updateAttributes below if only set values must be considered.
   * ATTENTION: Attributes are evaluated and authorized as well at the API.
   *
   * @param processId
   * @param nodeId
   * @param params
   */
  update(processId: string, nodeId: string, params: IBomDataNodeParams): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      data: {
        attributes: {
          title: params.title,
          description: params.description,
          due_date: params.dueDate,
          pre_due_date: params.preDueDate,
          starts_at: params.startsAt,
          started_at: params.startedAt,
          completed_at: params.completedAt,
          ends_at: params.endsAt,
          priority: params.priority,
          year: params.year,
          month: params.month,
          document_type: params.documentType,
          category_id: params.categoryId,
          important: params.important,
          low_hanging_fruit: params.low_hanging_fruit,
          applicable: params.applicable,
          sum: params.sum,
          effort: params.effort,
          annotation: params.annotation,
          progress: params.progress,
          choice: params.choice,
          multi_choice: params.multiChoice,
          archived_at: params.archived_at
        }
      }
    };

    return <Observable<BomDataNode>>this.http.put<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}`, payload);
  }

  /**
   * Updates a certain set of attributes if these are set at params.
   *
   * @param processId
   * @param nodeId
   * @param params
   */
  updateAttributes(processId: string, nodeId: string, params: IBomDataNodeParams): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      data: {
        attributes: {
          title: params.title,
        }
      }
    };

    if (params.description) payload.data.attributes['description'] = params.description;
    if (params.dueDate) payload.data.attributes['due_date'] = params.dueDate;
    if (params.preDueDate) payload.data.attributes['pre_due_date'] = params.preDueDate;
    if (params.startsAt) payload.data.attributes['starts_at'] = params.startsAt;
    if (params.startedAt) payload.data.attributes['started_at'] = params.startedAt;
    if (params.completedAt) payload.data.attributes['completed_at'] = params.completedAt;
    if (params.endsAt) payload.data.attributes['ends_at'] = params.endsAt;
    if (params.color) payload.data.attributes['color'] = params.color;
    if (params.priority) payload.data.attributes['priority'] = params.priority;
    if (params.order) payload.data.attributes['order'] = params.order;

    return <Observable<BomDataNode>>this.http.put<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}`, payload);
  }

  /**
   * Removes a BOM node item from process.
   *
   * @param processId
   * @param nodeId
   */
  remove(processId: string, nodeId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.del<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}`);
  }

  /**
   * Sets the locked_at date at an BOM node item.
   * It is expected that the upload is locked/prevented.
   *
   * @param processId
   * @param nodeId
   */
  lockDataNode(processId: string, nodeId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/lock`, {});
  }

  /**
   * Unsets the locked_at date at the BOM node item.
   *
   * @param processId
   * @param nodeId
   */
  unlockDataNode(processId: string, nodeId: string) {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/unlock`, {});
  }

  /**
   * Adds a reposnible at a BOM node item.
   * The responsible ID must be a participant of the workflow.
   *
   * @param processId
   * @param nodeId
   * @param participantId
   */
  addResponsible(processId: string, nodeId: string, participantId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      'data': {
        'attributes': {
          'participant_id': participantId
        }
      }
    };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/responsible`, payload);
  }

  /**
   * Resets the responsible for a BOM node item.
   *
   * @param processId
   * @param nodeId
   */
  removeResponsible(processId: string, nodeId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.del<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/responsible`);
  }

  /**
   * Updates the status of a BOM node item.
   *
   * @param processId
   * @param nodeId
   * @param status
   */
  updateStatus(processId: string, nodeId: string, status: ItemStatus): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      data: {
        attributes: {
          status: ItemStatusToApiStatusMapping[status],
        }
      }
    };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/status`, payload);
  }

  /**
   * Updates the due date of a BOM node item.
   *
   * @param processId
   * @param nodeId
   * @param params
   */
  updateDueDate(processId: string, nodeId: string, params: IBomDataNodeParams): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      data: {
        attributes: {
          due_date: params.dueDate
        }
      }
    };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/due_date`, payload);
  }

  /**
   * Copies a BOM node item.
   * If the item has children elements then these items are copied as well.
   *
   * @param processId
   * @param nodeId
   */
  copy(processId: string, nodeId: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/copy`, {});
  }

  /**
   * Sets the archived_at date of a BOM node item to allow filtering.
   *
   * @param processId
   * @param nodeId
   * @param archive
   */
  archive(processId: string, nodeId: string, archive: boolean): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { archive } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/archive`, params);
  }

  /**
   * Toggles the direct response field on BOM node items.
   *
   * @param processId
   * @param nodeId
   * @param enable
   */
  enableResponse(processId: string, nodeId: string, enable: boolean): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { enable_response: enable } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/enable_response`, params);
  }

  /**
   * Toggles the direct response field on BOM node items.
   *
   * @param processId
   * @param nodeId
   * @param enable
   */
  enableChoice(processId: string, nodeId: string, enable: boolean): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { enable_choice: enable } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/enable_choice`, params);
  }

  /**
   * Toggles the direct response field on BOM node items.
   *
   * @param processId
   * @param nodeId
   * @param enable
   */
  enableMultiChoice(processId: string, nodeId: string, enable: boolean): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { enable_multi_choice: enable } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/enable_multi_choice`, params);
  }

  /**
   * Allows to set the available options for each node row.
   *
   * @param processId
   * @param nodeId
   * @param options
   */
  updateChoiceOptions(processId: string, nodeId: string, options: string[]): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { choice_options: options } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/choice_options`, params);
  }

  /**
   * Allows to set the available multiple choiceoptions for each node row.
   *
   * @param processId
   * @param nodeId
   * @param options
   */
  updateMultipleChoiceOptions(processId: string, nodeId: string, options: string[]): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { multi_choice_options: options } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/multi_choice_options`, params);
  }

  /**
   * Allows to set a direct response of a BOM node item.
   * The field is expcted to be HTML.
   * It is expected that the response field was activated before.
   *
   * @param processId
   * @param nodeId
   * @param response
   */
  sendResponse(processId: string, nodeId: string, response: string): Observable<BomDataNode> {
    const builder = new BomDataNodeBuilder(processId);
    const params = { data: { attributes: { response } } };
    return <Observable<BomDataNode>>this.http.post<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/${nodeId}/respond`, params);
  }

  /**
   * Applies a global due date to all BOM items.
   * overrideExisting allows to define if existing due dates should be overridden.
   *
   * @param processId
   * @param overrideExisting
   */
  applyGlobalDueDate(processId: string, overrideExisting = false): Observable<BomDataNode[]> {
    const builder = new BomDataNodeBuilder(processId);
    const payload = {
      data: {
        attributes: {
          override_existing: overrideExisting
        }
      }
    };
    return <Observable<BomDataNode[]>>this.http.postAll<BomDataNodeBuilder, BomDataNode>(builder, `${this.BASE_PATH}/${processId}/${this.BASE_PATH_SUFFIX}/apply_global_due_date`, payload);
  }
}
