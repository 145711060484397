import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MessageResourceStat} from './message';
import {MessageService} from './message.service';
import {catchError, first, map} from 'rxjs/operators';
import * as MessageActions from './message.actions';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';

@Injectable()
export class MessageResourceStatsService {
  public unreadStats = new BehaviorSubject<{ [id: string]: number }>({});

  constructor(private _store: Store<AppState>,
              private _msgSvc: MessageService) {}

  public refresh(processId: string, grouped_by = 'resource_id', recursive = false) {
    this._getCurrentUnreadStats(grouped_by, processId, recursive).subscribe(stats => {
      this.unreadStats.next(stats);
    });
  }

  public markNotificationsRead(resourceId, findResourceBy = 'resource_id'): void {
    this._store.dispatch(new MessageActions.MarkReadForResource(resourceId, findResourceBy));
  }

  public markNotificationsReadSync(processId: string, resourceId, findResourceBy = 'resource_id', refreshRecursive = false): void {
    this._msgSvc.readMessagesForResource(resourceId, findResourceBy).pipe(first()).subscribe(res => {
      this.refresh(processId, findResourceBy, refreshRecursive);
    }, err => {
      this.refresh(processId, findResourceBy, refreshRecursive);
      console.error(err)
    });
  }

  private _getCurrentUnreadStats(grouped_by, processId: string, recursive = false): Observable<{ [id: string]: number }> {
    return this._msgSvc.getMessageResourceStats(processId, grouped_by, recursive)
      .pipe(
        first(),
        map((stats: MessageResourceStat[]) => {
          const unreadMessagesMap: { [id: string]: number } = {};
          stats.forEach((stat: MessageResourceStat) => {
            unreadMessagesMap[stat.id] = stat.unread;
          });
          return unreadMessagesMap;
        }),
        catchError(err => {
          console.error(err);
          return of({})
        }));
  }
}
