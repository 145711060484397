import {CacActions, CacActionTypes} from './cac.actions';
import {adapter, initialState, State} from './cac.state';

export function reducer(state = initialState, action: CacActions): State {
  const current = state.selected;
  switch (action.type) {
    case CacActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case CacActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.cac, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case CacActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };
  }
  return state;
}
