import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  FibuActionTypes,
  LoadByClient,
  LoadByClientFail,
  LoadByClientSuccess,
  Update,
  UpdateFail,
  UpdateSuccess,
} from './fibu.actions';
import {Fibu} from './fibu';
import {FibuService} from './fibu.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class FibuEffects {
  loadByClient$ = createEffect(() => this.actions.pipe(
    ofType(FibuActionTypes.LoadByClient),
    switchMap((action: LoadByClient) => {
      return this._svc.loadByClientId(action.clientId).pipe(
        first(),
        concatMap((fibu: Fibu) => {
          return [new LoadByClientSuccess(fibu)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadByClientFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(FibuActionTypes.Update),
    switchMap((action: Update) => {
      return this._svc.update(action.processId, action.params).pipe(
        first(),
        concatMap((fibu: Fibu) => {
          return [new UpdateSuccess(fibu)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateFail(err));
        }));
    })
  ));


  constructor(private actions: Actions,
              private _svc: FibuService,
              private _router: Router,
    private _translateSvc: TranslateService,
    private _notifyService: FivefNotificationService) {
  }
}
