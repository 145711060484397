<div class="button-row mb-2">
  <a mat-button routerLink="../../navigation">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div>

<mat-card appearance="outlined" class="p-0 user-profile">
  <mat-card-title>
    <span class="small-title">{{ 'ORGANIZATION.CARD_TITLE' | translate }}</span>
    <h3>
      {{ (organization$ | async)?.name }}
      <ng-container *ngIf="(organization$ | async)?.legalFormId | fivefLegalFormNameById | async as legalFormName">
        <ng-container *ngIf="legalFormName !== 'Sonstige' ">
          {{ legalFormName.indexOf('(') > -1 ? legalFormName : '(' + legalFormName + ')' }}
        </ng-container>
      </ng-container>
    </h3>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group class="tab-group">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label">
            <div>{{ 'DAV.IMPORT_CONTACTS_TITLE' | translate }}</div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <dvtx-dav-contact-import></dvtx-dav-contact-import>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
