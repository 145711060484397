import {CommentReaction, CommentReactionType, CommentType, IComment} from './comment.interface';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

/**
 * Public comment statistic object.
 * Contains a mapping form a node ID to a count.
 * A node ID can be a Collecto Element, a CAC Appendix or a Process.
 * The API stats object returns for each item the process ID.
 * Therefore we count all node ID counts plus null counts with the corresponding
 * process ID to get all comments of a certain process.
 *
 * Node IDs and Process IDs are UUIDs. We can put all together in one unified for
 * a simplified retrieval.
 *
 * A CommentProcessStatistics object is scoped inside the reducer by the given process ID
 * to have multiple (even recursive) stats side by side.
 *
 * See also the Builder for further info on the API response.
 *
 * ATTENTION: Restricted Project Room comments are not included.
 */
export class CommentProcessStatistics implements IResource {
  readonly type = 'comment_process_statistics';
  commentCounts = {};

  constructor(public id: string) {
  }

  addNodeCommentCount(processId, id, count) {
    // Pure project room comments do not have a backtrack/node id. Skip them.
    // (in contrast to Collecto element or CAC Appendix comments).
    // This scenario is only present in recursive/include_children call.
    if (id) {
      this.commentCounts[id] = count;
    }
    if (!this.commentCounts[processId]) {
      this.commentCounts[processId] = 0;
    }
    this.commentCounts[processId] += count;
  }
}

export class Comment implements IComment {
  processId: string;
  backtrackId: string;
  replyTo: string;
  replyToId: string;
  replyToAuthor: string;
  replyToDate: Date;

  reactionMap = {}
  reactionList = [];

  /**
   * Helper to signalize a task entity update to prevent rerendering at *ngFor.
   */
  trackBy: string;

  constructor(public id: string,
              public type: CommentType,
              public commentableId: string,
              public performer: string,
              public message: string,
              public createdAt: Date = null,
              public updatedAt: Date = null,
              public seenBy: string[] = [],
              public reactions: CommentReaction[] = []) {
    // We additionally use the reactions and seenBy count because these are relations and
    // not reflected by updatedAt.
    this.trackBy = `${id}|${updatedAt}|${seenBy?.length}|${reactions?.length}`;
  }
}
