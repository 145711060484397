<ng-template #callToActionRef>
  <div class="d-flex justify-content-end align-items-center">
    <button mat-raised-button color="primary" (click)="openLogoDialog()">
      <mat-icon matPrefix aria-label="Upload logo">file_upload</mat-icon>
      Upload Logo
    </button>

    <button mat-icon-button
            [matTooltip]="'CUSTOMIZATION.UPLOADED_LOGO_RECOMMENDATION_2' | translate">
      <mat-icon>info</mat-icon>
    </button>
  </div>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [callToActionPortal]="callToActionPortal"
                      [toOverview]="true"
                      [title]="'ORGANIZATION.LOGO'"></dvtx-settings-header>

<dvtx-logo-upload-container #logoUploadContainerRef></dvtx-logo-upload-container>
