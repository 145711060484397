import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './process-participant.state';
import {ProcessParticipantType} from './process-participant.interface';
import {ProcessSelectors} from '../process';
import {ContactSelectors} from '../contact';
import {Net} from 'app/lib/fivef-net/uuid';

export const stateKey = 'process-participant';
const getProcessParticipantState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessParticipantEntities,
  selectAll: getAllProcessParticipants,
} = adapter.getSelectors(getProcessParticipantState);

export const getLoadingState = createSelector(
  getProcessParticipantState,
  state => state.loading
);

/**
 * Local participant map process ID => ProcessParticipant[];
 */
const participantProcessMap = createSelector(
  getAllProcessParticipants,
  ContactSelectors.getContactsOfSelectedOrg,
  (participants: any, contacts) => {
    const participantMap = {};
    const contactMap = {};
    contacts.forEach(c => {
      contactMap[c.email] = c;
    });

    participants.forEach(p => {
      p.hasAccount = !!contactMap[p.email];
      // Old hack kept for backwards compatibility: Convenience method for Collecto: TODO: Remove if possible.
     // p.isMember = !!(p.type === 'MEMBER');
      p.isMember = !!p.processOrganizationMember;
      const processId = p.processId;
      if (!participantMap[processId]) {
        participantMap[processId] = [];
      }
      participantMap[processId].push(p);
    });
    return participantMap;
  }
);

export const getParticipantsByProcessId = (id: string) => createSelector(
  participantProcessMap,
  (participantMap) => id ? (participantMap[id] && participantMap[id].length ? participantMap[id] : []) : []);

export const getParticipantsOfSelectedProcess = createSelector(
  ProcessSelectors.getSelectedProcess,
  participantProcessMap,
  (process, participantMap) => process && process.id ? (participantMap[process.id] && participantMap[process.id].length ? participantMap[process.id] : []) : []);

export const participantByEmail = (id: string, email: string) => createSelector(
  getProcessParticipantsOfProcess(id),
  (participants) => participants.find(c => email && c.email === email)
);

export const getExternalParticipantsOfSelectedProcess = createSelector(
  getParticipantsOfSelectedProcess,
  (participants) => participants.filter(p => p.type === ProcessParticipantType.External));

export const getProcessParticipantsOfSelectedProcess = createSelector(
  getParticipantsOfSelectedProcess,
  (participants) => participants.filter(p => p.type === ProcessParticipantType.Member));

export const getProcessParticipantsOfProcess = (id: string) => createSelector(
  getParticipantsByProcessId(id),
  (participants) => participants.filter(p => p.type === ProcessParticipantType.Member));
