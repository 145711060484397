import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Label} from './label';
import {GLOBAL_LABELS, ILabelParams, LabelScope} from './label.interface';

export class LabelBuilder implements IApiResourceBuilder<Label> {

  fromResponse(data): Label {
    const attrs = data.attributes;
    const label = new Label(
      data.id,
      attrs.icon,
      attrs.color,
      attrs.title,
      attrs.description,
      attrs.is_global,
      attrs.scope,
      attrs.origin,
      attrs.usage_count,
      false,
      attrs.creator_id
    );

    label.referenceId = attrs.reference_id;

    if (label.scope === LabelScope.PERSONAL) {
      label.icon = 'person';
    }

    if (label.scope === LabelScope.GLOBAL) {
      const globalLabel = GLOBAL_LABELS.find(_label => _label.title === label.title);
      label.isSVGIcon = globalLabel && globalLabel.isSVGIcon;
    }

    return  label;
  }

  toRequest(params: ILabelParams) {
    return {
      data: {
        type: 'label',
        attributes: {
          icon: params.icon,
          color: params.color,
          title: params.title,
          description: params.description,
          scope: params.scope,
          origin: params.origin,
          is_global: params.isGlobal,
          reference_id: params.referenceId
        }
      }
    };
  }
}
