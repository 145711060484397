import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {Feature} from './feature';
import {StringUtils} from 'app/lib/string_utils';

export class FeatureBuilder implements IApiResourceBuilder<Feature> {
  fromResponse(data): Feature {
    const feature = new Feature(data.id);
    const attrs = data.attributes;

    // Only consider properties beginning with can_ or has_, e.g. can_create_signature.
    const canDos = Object.keys(attrs).filter(key => ~key.indexOf('can_') || ~key.indexOf('has_'));
    canDos.forEach(can => {
      if (can[can.length - 1] === '?') {
        const prop = can.substring(0, can.length - 1);
        feature[StringUtils.camalize(prop)] = attrs[can];
      }
    });
    return feature;
  }

  toRequest(feature: Feature) {
    return null;
  }
}
