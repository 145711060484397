import {IItemLabels, ItemType} from './item-labels.interface';
import {Label} from 'app/+store/label/label';

export class ItemLabels implements IItemLabels {
  readonly type = 'item';
  public labels: Label[] = [];

  constructor(public id: string,
              public itemId: string,
              public labelIds: string[],
              public itemType: ItemType,
              public processId: string) {
  }
}
