import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import * as model from 'app/+store/cms-slideshow/cms-slideshow';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {TranslateModule} from '@ngx-translate/core';

declare var $;

/**
 * 5F slideshow carousel using the bootstrap carousel from
 * internal MDB library.
 */
@Component({
  selector: 'fivef-carousel',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    FroalaViewModule,
    TranslateModule
  ],
  templateUrl: './fivef-carousel.component.html',
  host: {'class': 'fivef-carousel'},
  styleUrls: ['./fivef-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCarouselComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @ViewChild('nextArrowRef', {static: true})
  nextArrowRef: ElementRef;

  @Input()
  public highlighContentArea = false;

  public _slides: model.Cms.Slide[] = [];
  @Input()
  public set slides(s: model.Cms.Slide[]) {
    this._slides = s;
  }

  ngOnInit() {
    $(document).ready(function() { $('.carousel').carousel({ interval: 4000 }) });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
