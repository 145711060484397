import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './contact.state';
import {OrganizationSelectors} from '../organization';
import {ContactListDto, contactListDtoType, PersonContactListDto} from './contact';
import {ContactPersonSelectors} from '../contact-person'
import {ContactOrganizationSelectors} from '../contact-organization'
import {VerifiedUserSelectors} from 'app/+store/verified-user';
import {StringUtils} from '../../lib/string_utils';
import {AddressBookTableHelper} from '../../modules/address-book/modules/address-book-table/containers/address-book-table/address-book-table.helper';
import {transformContactObject} from '../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.interface';

export const stateKey = 'contactsNew';
const getContactState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getContactEntities,
  selectAll: getAllContacts,
} = adapter.getSelectors(getContactState);

export const getContactsLoading = createSelector(
  getContactState,
  state => state.loading
);

export const registeredUsersMap = createSelector(
  VerifiedUserSelectors.getAllVerifiedUsers,
  (knownUsers) => {
    const registeredUserMap = {};
    knownUsers.forEach(u => {
      registeredUserMap[StringUtils.normalizeString(u.id)] = u.hasRegisteredAccount;
    });
    return registeredUserMap;
  });

/**
 * Creates an e-mail list with normalized known (verified) e-mails.
 * NOTE: Using this together with find creates an additional complexity *n.
 * Use map below if possible.
 */
export const verifiedEmailListing = createSelector(
  VerifiedUserSelectors.getAllVerifiedUsers,
  (knownUsers) => knownUsers.map(u => StringUtils.normalizeString(u.id)));

/**
 * Creates a map from normalized e-mail to boolean for quick O(1) lookup.
 */
export const verifiedEmailListingMap = createSelector(
  verifiedEmailListing,
  (knownUsers) => {
    const knownUserMap = {};
    knownUsers.forEach(email => knownUserMap[email] = true);
    return knownUserMap;
  });


export const getSortedContacts = createSelector(
  getAllContacts,
  (contacts) => {
    return contacts.sort(AddressBookTableHelper.sortByString)
      .map((contact) => {
        contact.normalizedEmail = StringUtils.normalizeString(contact.email);

        if (contact.type === contactListDtoType.Membership) {
          contact.hasAccount = true;
        }

        if (contact.telephone !== 'undefined' && contact.telephone !== undefined && contact.telephone !== '') {
          contact.telephone = contact.telephone.split('+')[1] ? '+' + contact.telephone.split('+')[1] : contact.telephone.split('+')[0];
        }
        return contact;
      })
  }
);

/**
 * Sorted Address Books: Precalculated sorted contact map.
 *
 * Returns a dictionary from organization ID => sorted contacts of organitation with ID.
 * It also adds the normalizedEmail property to allow later fast comparisons.
 */
export const getSortedAddressBooks = createSelector(
  getSortedContacts,
  verifiedEmailListingMap,
  (contacts, knownEmailsMap) => {
    const addressBookMap = {};
    contacts.forEach(contact => {
      if (!addressBookMap[contact.belongsToOrganizationId]) {
        addressBookMap[contact.belongsToOrganizationId] = [];
      }
      contact.hasAccount = contact.hasAccount || !!knownEmailsMap[contact.normalizedEmail];
      addressBookMap[contact.belongsToOrganizationId].push(contact);
    });
    return addressBookMap;
  }
);

/**
 * Returns all contact entries of the current organization.
 */
export const getContactsOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getSortedAddressBooks,
  (orgaId, addressBooks) => addressBooks[orgaId] || []);

/**
 * Returns all contact entries of the current organization filtered by filterType and contact IDs.
 */
export const getFilteredContactsOfSelectedOrg = (filterType: contactListDtoType | undefined, excludedIds: string[], skipIfHasAccount = true) => createSelector(
  OrganizationSelectors.getSelectedId,
  getSortedAddressBooks,
  (orgaId, addressBookMap) => {
    const contacts = addressBookMap[orgaId] || [];
    return contacts.filter(entity => {
      const excludeIds = excludedIds && excludedIds.length && excludedIds.length > 0;
      return (filterType === undefined || entity.type === filterType) && (!excludeIds || excludedIds.indexOf(entity.id) < 0)
    })
  }
);

export const getFilteredPersonContactsOfSelectedOrg = (filterType: contactListDtoType | undefined, excludedIds: string[], skipIfHasAccount = true) => createSelector(
  OrganizationSelectors.getSelectedId,
  getSortedAddressBooks,
  (orgaId, addressBookMap) => {
    const contacts = addressBookMap[orgaId] || [];
    return contacts.filter(entity => {
      const excludeIds = excludedIds && excludedIds.length && excludedIds.length > 0;
      return (filterType === undefined || entity.type === filterType) && (!excludeIds || excludedIds.indexOf(entity.id) < 0)
    })
  }
);

/**
 * Returns contact if found.
 *
 * TODO: Rework: Very inefficient.
 * @param email
 */
export const contactExists = (email: string) => createSelector(
  OrganizationSelectors.getSelectedId,
  getAllContacts,
  (orgaId, contacts) => {
    if (!email) {
      return false;
    }
    const _email = email.toLowerCase();
    let found = false;
    try {
      found = !!contacts.find(c => _email && c.email && c.email.toLowerCase() === email && (c.type === contactListDtoType.naturalPersonContact || c.type === contactListDtoType.Membership));
    } catch (e) {
      console.error(e)
    }
    return found;
  });

export const isVerified = (email: string) => createSelector(
  verifiedEmailListingMap,
  (verfiedEmailsMap) => !!verfiedEmailsMap[email]
);

export const getContactPersonsOfSelectedOrg = (filterType: contactListDtoType | undefined, excludedIds: string[]) => createSelector(
  OrganizationSelectors.getSelectedId,
  getSortedAddressBooks,
  verifiedEmailListingMap,
  (orgaId, addressBookMap, knownEmails) => {
    const contacts = addressBookMap[orgaId] || [];
    return contacts.filter((entity: ContactListDto) => {
      if (!(entity.type === contactListDtoType.Membership || entity.type === contactListDtoType.naturalPersonContact)) {
        return false;
      }

      if (!entity.hasAccount) {
        // Removed in favor of O(1) lookup below
        // entity.hasAccount = !!knownEmails.find(known => matchingEmails(known, entity.email));
        entity.hasAccount = !!knownEmails[entity.normalizedEmail];
      }

      return (filterType === undefined || entity.type === filterType) && excludedIds && excludedIds.indexOf(entity.id) < 0
    })
  }
);

export enum AccountStatus {
  Member = 'Member',
  VerifiedUser = 'VerifiedUser',
  None = 'None'
}

/**
 * Creates an account status map for the avatar for all users.
 */
export const getAccountStatusOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getAllContacts,
  verifiedEmailListingMap,
  (orgaId, entities, knownEmails) => {
    const statusMap = {}
    entities.forEach(contact => {
      const email = contact.normalizedEmail;
      if (contact.type === contactListDtoType.Membership && contact.belongsToOrganizationId === orgaId) {
        statusMap[email] = AccountStatus.Member;
      }

      if (!statusMap[email] && !!knownEmails[email] && contact.type !== contactListDtoType.organizationContact && contact.type !== contactListDtoType.Membership) {
        statusMap[email] = AccountStatus.VerifiedUser;
      }

      if (!statusMap[email]) {
        statusMap[email] = AccountStatus.None;
      }
    });
    return statusMap;
  }
);

/**
 * Returns the account status for the avatar for user with email.
 */
export const getAccountStatusOfSelectedOrgByEmail = (email: string) => createSelector(
  getAccountStatusOfSelectedOrg,
  (statusMap) => statusMap[StringUtils.normalizeString(email)] || AccountStatus.None
);


const getPrecalculatedMembersAndContactPersonsOfSelectedOrg = createSelector(
  OrganizationSelectors.getSelectedId,
  getSortedAddressBooks,
  ContactPersonSelectors.getPersonContactEntities,
  verifiedEmailListingMap,
  registeredUsersMap,
  (orgaId, addressBookMap, people, knownEmailsMap, registeredUserMap) => {
    const contactOrMembers = [];
    const contacts = addressBookMap[orgaId] || [];

    const memberMap = {};
    contacts.forEach(c => {
      if (c.type === contactListDtoType.Membership) {
        memberMap[c.normalizedEmail] = true;
      }
    });

    contacts
      .forEach((contact: ContactListDto) => {
        if (!(contact.type === contactListDtoType.Membership || contact.type === contactListDtoType.naturalPersonContact)) {
          return;
        }

        // If member: match.
        if (contact.type === contactListDtoType.Membership) {
          contact.hasAccount = true;
          contact.isMember = true;
        }

        // Filter contacts that are also members
        if (contact.type === contactListDtoType.naturalPersonContact && memberMap[contact.normalizedEmail]) {
          return false;
        }

        if (!contact.hasAccount) {
          contact.hasAccount = !!knownEmailsMap[contact.email];
          contact.isVerified = contact.hasAccount;
        }

        const firstName = people[contact.id] && people[contact.id].firstName;
        const lastName = people[contact.id] && people[contact.id].lastName;
        const personContact: PersonContactListDto = Object.assign({firstName: firstName, lastName: lastName}, contact);
        personContact.isRegisteredUser = !!registeredUserMap[contact.email];
        contactOrMembers.push(personContact);
      })
    return contactOrMembers;
  }
);

/**
 * Very important selector. Almost used everywhere in workflows.
 * @param excludedIds
 */
export const getMembersAndContactPersonsOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  getPrecalculatedMembersAndContactPersonsOfSelectedOrg,
  (contacts) => {
    return contacts.filter((entity: ContactListDto) => {
      return excludedIds && excludedIds.indexOf(entity.id) < 0
    })
  }
);

/**
 * Very important selector. Almost used everywhere in workflows.
 * @param excludedIds
 */
export const getEmailNameMapOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  getMembersAndContactPersonsOfSelectedOrg(excludedIds),
  (contacts) => {
    if (!contacts || contacts.length === 0) {
      return {};
    }
    return contacts.reduce((acc, c) => {
      if (!c || !c.email) return acc;
      acc[c.email.downcase] = {firstName: c.firstName, lastName: c.lastName, name: `${c.firstName} ${c.lastName}`};
      return acc;
    }, {});
  }
);

export const getMembersAndVerifiedContactPersonsOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  getMembersAndContactPersonsOfSelectedOrg(excludedIds),
  (contacts: any[]) => {
    if (!contacts) {
      return [];
    }
    return contacts.filter(c => !!c && c.hasAccount);
  });

/**
 * Very important selector. Almost used everywhere in workflows.
 * @param excludedIds
 */
export const getMembersOfSelectedOrgMap = createSelector(
  getPrecalculatedMembersAndContactPersonsOfSelectedOrg,
  (membersContacts) => {
    if (!membersContacts || membersContacts.length === 0) {
      return {};
    }
    return membersContacts.reduce((acc, c) => {
      if (!c || !c.email) return acc;
      acc[c.email] = c;
      return acc;
    }, {});
  }
);

/**
 * Specialized selector for transformed contacts for the 5F item selector component.
 * Memoizes all transformed contacts to prevent recalculation on multiple task fields.
 */
export const getSelectableContacts = createSelector(
  getMembersAndVerifiedContactPersonsOfSelectedOrg(),
  (contacts: any[]) => {
    if (!contacts) {
      return [];
    }
    return contacts.map(transformContactObject)
  });

/**
 * Dangerous. Expensive (parameter + find + matching).
 * @param email
 */
export const getAvatarProfileByEmail = (email: string) => createSelector(
  getMembersAndContactPersonsOfSelectedOrg(),
  (contacts: any[]) => {
    console.error('getAvatarProfileByEmail');
    const contact = contacts.find(c => matchingEmails(c.email, email));
    if (contact) {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: email,
        isMember: contact.type === contactListDtoType.Membership,
        isVerified: contact.type === contactListDtoType.naturalPersonContact && contact.hasAccount,
      }
    } else {
      return {
        email: email
      }
    }
  });

// Entities can return items twice if contact and membership exists.
// Membership overrides normal contact.
export const getVerfiedMembersAndContactPersonsOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  OrganizationSelectors.getSelectedId,
  getAllContacts,
  verifiedEmailListingMap,
  (orgaId, contacts, knownEmailsMap) => {
    const members = contacts.filter(c => c.type === contactListDtoType.Membership);
    return contacts.filter((entity: ContactListDto) => {
      if (!(entity.type === contactListDtoType.Membership || entity.type === contactListDtoType.naturalPersonContact)) {
        return false;
      }

      if (entity.type === contactListDtoType.Membership) {
        entity.hasAccount = true;
      }

      // Filter contacts that are also members
      if (entity.type === contactListDtoType.naturalPersonContact) {
        if (members.find(m => matchingEmails(entity.email, m.email))) {
          return false;
        }
      }

      if (!entity.hasAccount) {
        entity.hasAccount = !!knownEmailsMap[entity.email];
      }

      return entity.belongsToOrganizationId === orgaId && entity.hasAccount && excludedIds && excludedIds.indexOf(entity.id) < 0
    })
  }
);

export const getVerfiedContactPersonsOnlyOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  OrganizationSelectors.getSelectedId,
  getAllContacts,
  verifiedEmailListingMap,
  (orgaId, contacts, knownEmailsMap) => {
    const membersEmails = contacts
      .filter(contact => contact.type === contactListDtoType.Membership)
      .map(contact => StringUtils.normalizeString(contact.email));
    return contacts.filter((entity: ContactListDto) => {
      entity.hasAccount = !!knownEmailsMap[entity.normalizedEmail];
      if (!entity.hasAccount) {
        return false;
      }
      const notMember = !membersEmails.find(email => matchingEmails(email, entity.email))
      return entity.belongsToOrganizationId === orgaId
        && entity.type === contactListDtoType.naturalPersonContact
        && entity.hasAccount
        && notMember
        && excludedIds && excludedIds.indexOf(entity.id) < 0;
    });
  });

export const getMembersOfSelectedOrg = (excludedIds: string[] = []) => createSelector(
  OrganizationSelectors.getSelectedId,
  getAllContacts,
  (orgaId, entities) => {
    const resultSet = entities.filter((entity: ContactListDto) => {
      return entity.belongsToOrganizationId === orgaId &&
        (
          entity.type === contactListDtoType.Membership
        )
        && excludedIds && excludedIds.indexOf(entity.id) < 0
    });
    return resultSet.map(member => {
      member.hasAccount = true;
      return member;
    });
  }
);

export const getById = (contactId: string) => createSelector(
  getContactEntities,
  entities => entities[contactId]
);

export const getByEmail = (email: string) => createSelector(
  getAllContacts,
  contacts => contacts.find(contact => matchingEmails(contact.email, email))
);

export const getOrgOrPersonContactById = (contactId: string) => createSelector(
  ContactOrganizationSelectors.getOrganizationContactById(contactId),
  ContactPersonSelectors.getPersonContactById(contactId),
  (orgContact, personContact) => orgContact || personContact
);

export const getOrgOrPersonContactByEMail = (email: string) => createSelector(
  getAllContacts,
  allContacts => allContacts.find(c => matchingEmails(c.email, email))
);

const matchingEmails = (email, other): boolean => {
  return StringUtils.simpleCompare(email, other);
};
