<h3 class="mb-2"> {{ 'INVITATIONS.INVITE_EMPLOYEE' | translate }}</h3>
<div class="ff-icon-content">
  <mat-icon>email</mat-icon>
  <div class="ff-icon-content--content">
    <dvtx-contact-select [fetchContactsFromStore]="true"
                         [style.width]="'340px'"
                         [class]="'mw-100'"
                         elementName="E-Mail"
                         [(ngModel)]="inviteeEmail" [filterType]="contactListDtoType.naturalPersonContact"
                         [excludedIds]="[]" [valueIsEmail]="true">
    </dvtx-contact-select>
  </div>
</div>
