import {IProcessArtifact} from 'app/+store/process-artifact/process-artifact.interface';
import {environment} from 'environments/environment';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

/**
 * Supported DMS providers.
 * Currently DATEV is the mostly used default.
 */
export enum DmsProvider {
  FiveF = 'five_f',
  Disk = 'disk',
  Sharepoint = 'sharepoint',
  Datev = 'datev',
  Lobo = 'lobo',
  Epona = 'epona',
  Enaio = 'enaio',
  GrantThornton = 'grant_thornton'
}

/**
 * DMS synchronization status for visual frontend evaluation and filters.
 */
export enum DmsSyncStatus {
  Undefined = 'undefined',
  Open = 'open',
  InProgress = 'in_progress',
  Completed = 'completed',
  Error = 'error',
  Failure = 'failure',
  Duplicate = 'duplication'
}

/**
 * Artifact DTO for DMS synchronization candidates.
 */
export class DmsQueuedArtifact implements IResource {
  readonly type = 'dms_queued_artifacts';
  public trackKey: string;

  /**
   *
   * @param id
   * @param title
   * @param size
   * @param processId
   * @param processTitle
   * @param clientName
   * @param clientNo Client ID, e.g. DATEV ID for DMS mappings.
   * @param syncStatus Current synchronization state with DMS.
   * @param dmsObjectIdent Additional DMS ID. E.g. a document at DATEV DMS has a sequential upcounting document number.
   * @param syncedAt
   * @param dmsProvider
   * @param updatedAt
   * @param createdAt
   */
  constructor(public id: string,
              public title: string,
              public size: number,
              public processId: string,
              public processTitle: string,
              public clientName: string,
              public clientNo: string,
              public syncStatus: DmsSyncStatus,
              public dmsObjectIdent: string,
              public syncedAt: Date,
              public dmsProvider: DmsProvider,
              public updatedAt: Date,
              public createdAt: Date) {
  }
}

export class ProcessArtifact implements IProcessArtifact {
  readonly type = 'artifact_records';
  uploaderName: string;
  uploaderEmail = null;
  exports = [];
  selected?: boolean;
  fileName: string;
  url: string;
  commentCount: number;
  unreadCommentCount: number;
  newUpload: boolean;
  color: string;

  // NOTE: the dmsDocumentId attribute is kept for backwards compability and refers now to
  //       5F Artifact UUID. If the external Foxdox ID is needed use remoteDmsDocumentId.
  dmsDocumentId: string;

  // Additional DMS ID. E.g. a document at DATEV DMS has a sequential upcounting document number.
  dmsObjectIdent: string;

  // Translatable code for DMS error messages.
  dmsErrorCode: string;

  /**
   * Allows to track document changes by *ngFor or mat-table trackby functionality.
   * This variable is set at the builder and must include all mutable fields like
   * the new upload state, dynamic embedded comments or export states
   * (export markers and Fibu export info).
   */
  public trackKey: string;

  public year: string;
  public month: string;
  public keywords: string[];
  public documentType: string;

  constructor(public id: string,
              public processId: string,
              public organizationId: string,
              public title: string,
              public description: string,
              public revision: string,
              public remoteDmsDocumentId: string,
              public referenceId: string,
              public role: string,
              public size: number,
              public ownerName: string,
              public comment: string,
              public publicAvailable: boolean,
              public createdAt: Date,
              public updatedAt: Date,
              public externalUrl: string = null,
              public processIds: string[] = [],
              public bookmanExportedAt: string = null,
              public datevExportedAt: string = null,
              public datevDocumentId: string = null,
              public datevErrorUri: string = null,
              public datevRequestId: string = null,
              public datevErrorCode: string = null,
              public datevErrorDescription: string = null) {
    this.uploaderName = ownerName;
    this.fileName = title;
    this.url = externalUrl;

    // Change: The remote DMS document ID is now only needed for some external requests.
    //         For all 5F platform requests we are using our new internal Artifact UUID.
    //         For compability of the dmsRoute (a direct link to the persisted document inside
    //         the external DMS) the dmsDocumentId is provided as remoteDmsDocumentId.
    this.dmsDocumentId = id;
  }

  // Route to the document inside the external Foxdox DMS web app.
  get dmsRoute(): string {
    return `${environment.dmsPath}/view/${this.remoteDmsDocumentId}`;
  }

  get name() {
    return this.title;
  }
}

export enum DocumentType {
  Document = 'document',
  Invoice = 'invoice',
  IngoingInvoice = 'ingoing_invoice',
  OutgoingInvoice = 'outgoing_invoice',
  Receipt = 'receipt',
  OtherReceipt = 'other_receipts',
}

export enum DocumentTypeTranslations {
  Document = 'DOCUMENTS.TYPES.DOCUMENT',
  Invoice = 'DOCUMENTS.TYPES.INVOICE',
  IngoingInvoice = 'DOCUMENTS.TYPES.INGOING_INVOICE',
  OutgoingInvoice = 'DOCUMENTS.TYPES.OUTGOING_INVOICE',
  Receipt = 'DOCUMENTS.TYPES.RECEIPT',
  OtherReceipt = 'DOCUMENTS.TYPES.OTHER_RECEIPT',
}

/**
 * {
 *   "data": {
 *     "id": "02c61935-5183-4128-b874-42aa0a38a76a",
 *     "type": "public_upload",
 *     "attributes": {
 *       "title": "API.json",
 *       "size": 1479999,
 *       "token": "oVw8WZb4CDafDLc1LD2dopXL",
 *       "created_at": "2021-01-14T07:09:59.945+01:00"
 *     }
 *   }
 * }
 */
export class ArtifactPublicUpload implements IResource {
  readonly type = 'public_upload';

  constructor(public id: string,
              public title: string,
              public token: string,
              public size: number,
              public createdAt: Date) {
  }
}

/**
 * {
 *   "data": {
 *     "id": "oVw8WZb4CDafDLc1LD2dopXL",
 *     "type": "public_upload_access",
 *     "attributes": {
 *       "title": "My Link",
 *       "description": "My Text",
 *       "creator_email": "audit-proof@localhost.local",
 *       "creator_name": "Alfons Krämer",
 *       "created_at": "2021-01-14T05:58:04.242+01:00",
 *       "updated_at": "2021-01-14T05:58:04.242+01:00"
 *     }
 *   }
 * }
 */
export class ArtifactPublicUploadAccess implements IResource {
  readonly type = 'public_upload_access';

  constructor(public id: string,
              public title: string,
              public description: string,
              public creatorEmail: string,
              public creatorName: string,
              public createdAt: Date) {
  }
}
