<dvtx-int-telephone-input
  name="phone"
  class="fivef-telephone-input--phone"
  [(ngModel)]="innerValue"
  [enablePlaceholder]="true"
  [enableAutoCountrySelect]="true"
  [disabled]="disabled"
  [preferredCountries]="['de']"
  [options]="options"
  (autoCompleteFun)="autoCompleteFunction($event)"
  [phoneType]="innerValue.locationOrType">
</dvtx-int-telephone-input>

<ng-container *ngIf="isType">
  <dvtx-api-select-cva
    class="fivef-telephone-input--type"
    [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
    listingPath="name"
    listingType="typed_location-or-phone-type"
    [(ngModel)]="innerValue.locationOrType"
    [disabled]="disabled">
  </dvtx-api-select-cva>
</ng-container>
