import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum CommentType {
  InstantMessage = 'InstantMessage',
  Comment = 'Comment'
}

export enum CommentReactionType {
  Like = 'like',
  Angry = 'angry',
  Sad = 'sad',
  Laugh = 'laugh',
  Heart = 'heart',
  Surprised = 'surprised'
}

export interface CommentReaction {
  email: string;
  reactionType: CommentReactionType
}

/**
 * Comment resource type. Signalizes if the comment was created in task, process,
 * artifact or CAC appendix/Collecto item context (node).
 */
export type CommentResourceType = 'process' | 'node' | 'task' | 'artifact';

export interface IComment extends IResource {
  type: CommentType;
  commentableId: string;
  performer: string;
  message: string;
  processId?: string;
  backtrackId?: string;
  seenBy?: string[];
}
