import {catchError, first, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  DocumentTemplateActionTypes,
  DownloadFail,
  DownloadSample,
  DownloadSampleFail,
  DownloadSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  Publish,
  PublishFail,
  Remove,
  RemoveFail,
  RemoveSuccess,
  SaveDownload,
  TestTemplate,
  TestTemplateFail,
  TestTemplateSuccess
} from './document-template.actions';
import {DocumentTemplateService} from './document-template.service';
import {DocumentTemplate} from './document-template';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {FileUtils} from 'app/lib/file_utils/functions';

@Injectable()
export class DocumentTemplateEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        map((res: DocumentTemplate[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  ));

  publish$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.Publish),
    switchMap((action: Publish) => {
      return this._svc.update(action.id, action.published).pipe(first()).pipe(
        switchMap((_res: DocumentTemplate) => {
          this._notifyService.success('CUSTOMIZATION.UPDATE_TEMPLATE_SUCCESS')
          return [new LoadAll()];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.UPDATE_TEMPLATE_FAILURE')
          return of(new PublishFail(err));
        }));
    })
  ));

  download$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.Download),
    switchMap((action: Publish) => {
      return this._svc.download(action.id).pipe(first()).pipe(
        switchMap((template: DocumentTemplate) => {
          return [new DownloadSuccess(template), new SaveDownload(template)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE')
          return of(new DownloadFail(err));
        }));
    })
  ));

  downloadSample$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.DownloadSample),
    switchMap((_action: DownloadSample) => {
      return this._svc.downloadSample().pipe(first()).pipe(
        switchMap((template: DocumentTemplate) => {
          return [new DownloadSuccess(template), new SaveDownload(template)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE')
          return of(new DownloadSampleFail(err))
        }));
    })
  ));

  testTemplate$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.TestTemplate),
    switchMap((action: TestTemplate) => {
      return this._svc.runTest(action.template.id).pipe(first()).pipe(
        switchMap((template: DocumentTemplate) => {
          return [new TestTemplateSuccess(template)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.DOWNLOAD_TEMPLATE_FAILURE')
          return of(new TestTemplateFail(err))
        }));
    })
  ));

  saveTemplate$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.SaveDownload),
    switchMap((action: SaveDownload) => {
      const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      FileUtils.saveAsFile(action.template.fileName, action.template.content, mimeType);
      return of(null);
    })
  ), {dispatch: false});

  remove$ = createEffect(() => this.actions.pipe(
    ofType(DocumentTemplateActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.template).pipe(first()).pipe(
        switchMap((res: DocumentTemplate) => {
          this._notifyService.success('CUSTOMIZATION.REMOVE_TEMPLATE_SUCCESS')
          return [new RemoveSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.REMOVE_TEMPLATE_FAILURE')
          return of(new RemoveFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: DocumentTemplateService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




