<div class="fivef-address-input-form--container">
  <div class="ff-row">
    <mat-form-field class="fivef-address-input-form--street" fivefDisableInputHints>
      <input matInput [disabled]="disabled" [(ngModel)]="address.street"
        placeholder="{{ 'CONTACTS.STREET' | translate }}">
    </mat-form-field>

    <mat-form-field class="fivef-address-input-form--street-no" fivefDisableInputHints>
      <input matInput [disabled]="disabled" [(ngModel)]="address.streetNo"
        placeholder="{{ 'CONTACTS.STREET_NO' | translate }}">
    </mat-form-field>
  </div>

  <div class="ff-row">
    <mat-form-field class="fivef-address-input-form--city" fivefDisableInputHints>
      <input matInput [disabled]="disabled" [(ngModel)]="address.city" placeholder="{{ 'CONTACTS.CITY' | translate }}">
    </mat-form-field>

    <mat-form-field class="fivef-address-input-form--zip" fivefDisableInputHints>
      <input matInput [disabled]="disabled" [(ngModel)]="address.zip" placeholder="{{ 'CONTACTS.ZIP' | translate }}">
    </mat-form-field>
  </div>
</div>
