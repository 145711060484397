import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CollectorItem} from './collector-item';
import {ApiStatusToItemStatusMapping, ItemStatus} from 'app/+store/collector/collector.interface';
import {ApplicableState} from '../cac/cac.interface';

export class CollectorItemBuilder implements IApiResourceBuilder<CollectorItem> {
  fromResponse(data): CollectorItem {
    const item = new CollectorItem(
      data.id,
      data.attributes.parent_id,
      data.attributes.title,
      data.attributes.description,
      data.attributes.color,
      data.attributes.pre_due_date,
      data.attributes.due_date,
      data.attributes.started_at,
      data.attributes.completed_at,
      data.attributes.created_at,
      data.attributes.updated_at,
      false,
      null,
      data.attributes.starts_at,
      data.attributes.ends_at,
      data.attributes.progress,
      data.attributes.effort,
      data.attributes.order,
      data.attributes.responsible_id,
      data.attributes.locked_at,
      data.attributes.comment_count,
      data.attributes.unread_comment_count,
    );

    // Assessment period
    item.year = data.attributes.year;
    item.month = data.attributes.month;

    item.important = data.attributes.important;
    item.low_hanging_fruit = data.attributes.low_hanging_fruit;
    item.progress = data.attributes.progress;
    item.sum = data.attributes.sum;
    item.newRecordMessage = data.attributes.new_record_message_id;

    if (data.attributes.applicable === false) {
      item.applicable = ApplicableState.NotApplicable;
    } else {
      item.applicable = ApplicableState.Applicable;
    }

    item.responseEnabled = data.attributes.response_enabled;
    item.response = data.attributes.response;

    item.choiceEnabled = data.attributes.choice_enabled;
    item.choice = data.attributes.choice;
    item.choiceOptions = data.attributes.choice_options;
    item.multiChoiceEnabled = data.attributes.multi_choice_enabled;
    item.multiChoice = data.attributes.multi_choice;
    item.multiChoiceOptions = data.attributes.multi_choice_options;

    item.annotation = data.attributes.annotation;
    item.archivedAt = data.attributes.archived_at;
    item.status = ApiStatusToItemStatusMapping[data.attributes.status];
    item.priority = data.attributes.priority == 'undefined' ? 'medium' : data.attributes.priority;

    item.isOverdued = item.dueDate && item.status !== ItemStatus.Closed && CollectorItemBuilder.isOverdued(item.dueDate, item.completedAt);

    item.documentType = data.attributes.document_type;
    return item;
  }

  toRequest(_: CollectorItem) {
    return null;
  }

  static isOverdued(dueDate, completedAt) {
    try {
      let currentDate;
      if (completedAt) {
        currentDate = new Date(new Date(completedAt).setHours(24, 0, 0));
      } else {
        currentDate = new Date(new Date().setHours(24, 0, 0));
      }

      const itemDueDate = new Date(new Date(dueDate).setHours(24, 0, 0));
      return Math.round((itemDueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) < 0;

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
