<div [ngSwitch]="appearance"
     class="fivef-color-picker--handle">
  <!-- Colored icon button button opener type for setting menus -->
  <ng-container *ngSwitchCase="'iconButton'">
    <button [disabled]="disabled"
            mat-icon-button
            [matTooltip]="tooltip | translate"
            class="fivef-color-picker--handle--icon-button-style ff-icon-sm"
            [matMenuTriggerFor]="menu"
            (click)="showColorPicker = !showColorPicker"
            #buttonToggler>
      <mat-icon [style.color]="selected">
        invert_colors
      </mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <button class="fivef-color-picker--handle--icon-style"
            matRipple
            [matMenuTriggerFor]="menu"
            [matTooltip]="'GENERAL.CHOOSE_COLOR' | translate"
            (click)="showColorsDropdown()"
            [style.background]="selected">
      <mat-icon [style.color]="colorsLib.LightOrDark(selected) === 'light' ? '#3c3c3c' : '#ffffff'">
        invert_colors
      </mat-icon>
    </button>
  </ng-container>

  <!-- Appearance 'button' is default -->
  <ng-container *ngSwitchDefault>
    <div class="fivef-color-picker--handle--button-style"
         [matTooltip]="tooltip | translate"
         [class.five-f-menu-opened]="showColorPicker">
      <button [disabled]="disabled"
              type="button" mat-stroked-button
              [disableRipple]="true"
              [matMenuTriggerFor]="menu"
              (click)="showColorPicker = !showColorPicker"
              #buttonToggler
              [style.background]="selected">
        <mat-icon [style.color]="colorsLib.LightOrDark(selected) === 'light' ? '#3c3c3c' : '#ffffff'">
          invert_colors
        </mat-icon>
      </button>
    </div>
  </ng-container>
</div>

<!-- Color menu with static colors and color picker -->
<mat-menu #menu="matMenu"
          class="five-f-card five-f-color-picker-dropdown--menu"
          [xPosition]="xPosition"
          [yPosition]="yPosition"
          (click)="$event.stopPropagation();"
          (closed)="hideColorsDropdown()">

  <ul class="mb-0 five-f-color-picker-dropdown--inner-content">
    <ng-container *ngFor="let color of colors">
      <li class="list-inline-item">
        <span [style.background]="color" (click)="selectColor(color)"></span>
      </li>
    </ng-container>

    <li class="list-inline-item" *ngIf="showCustomColor"
        (click)="$event.stopPropagation();">
      <button mat-icon-button
              [matTooltip]="'GENERAL.SHOW_MORE' | translate"
              [disableRipple]="true"
              (click)="moreColorsPicker.click()">
        <mat-icon>palette</mat-icon>
      </button>

      <input type="color"
             class="fivef-color-picker--handle--native-dialog"
             (change)="selectCustomColor($event)"
             #moreColorsPicker/>
    </li>
  </ul>
</mat-menu>
