<fivef-dialog [title]="data.title" [infoColor]="color">
  <div>
    <h3 class="mb-2">{{ data.message | translate }}</h3>

    <fivef-avatar [size]="'xs'"
                  [email]="email"
                  [showTitle]="true"
                  [showEmail]="true"></fivef-avatar>


    <!-- Admin / moderator option -->
    <div *ngIf="data.adminOption"
         class="mt-2 d-flex align-items-center">
      <mat-checkbox #adminCheckbox (change)="adminChanged($event)" [checked]="false">
        <span [innerHTML]="'ROLE.PR_ADMIN' | translate"></span>
      </mat-checkbox>

      <div class="align-self-center">
        <button mat-icon-button
                [matTooltip]="'PROJECT_ROOM.PROJECT_ROOM_ADMIN_INFO' | translate">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>

    <!-- Delegation option -->
    <div *ngIf="data.canDelegateOption"
         class="d-flex align-items-center">
      <mat-checkbox #adminCheckbox (change)="manageParticipationChange($event)" [checked]="false">
        <span [innerHTML]="'ROLE.MANAGE_PARTICIPATIONS' | translate"></span>
      </mat-checkbox>

      <div class="align-self-center">
        <button mat-icon-button
                matTooltip="{{ 'PROJECT_ROOM.MANAGE_PARTICIPATIONS_INFO' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>

    <!-- Recursive option -->
    <div *ngIf="data.recursiveOption"
         class="mb-2 d-flex align-items-center">
      <mat-checkbox #recursiveCheckbox (change)="recursiveChanged($event)" [checked]="true">
        <span [innerHTML]="'WORKFLOW_ENGINE.APPLY_TO_SUBWORKFLOWS' | translate"></span>
      </mat-checkbox>

      <div class="align-self-center">
        <button mat-icon-button
                matTooltip="{{ 'PROJECT_ROOM.APPLY_TO_SUBWORKFLOWS' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>

    <!-- Expiry date configuration -->
    <div *ngIf="data.expiresAtOption">
      <h4>{{ 'ROLE.PARTICIPANT_EXPIRY_INFO' | translate }}</h4>

      <div class="d-flex">
        <fivef-datepicker [(ngModel)]="expiresAt" [minDate]="tomorrow"></fivef-datepicker>

        <div class="ml-2">
          <button mat-button color="primary" (click)="addOneWeek()">
            1 {{ 'DATE.WEEK' | translate }}
          </button>
        </div>
        <div class="d-flex">
          <button mat-button color="primary" (click)="addThreeMonth()">
            3 {{ 'DATE.MONTHS' | translate }}
          </button>
        </div>
        <div class="d-flex">
          <button mat-button color="primary" (click)="addOneYear()">
            1 {{ 'DATE.YEARS' | translate }}
          </button>
        </div>
        <div class="d-flex">
          <button mat-button color="primary" (click)="resetDate()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>

  <div fivef-dialog-footer>
    <ng-container *ngIf="remove">
      <button mat-button (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
      <button mat-raised-button color="warn" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
    </ng-container>

    <ng-container *ngIf="!remove">
      <button mat-button (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
      <button mat-raised-button color="primary" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
    </ng-container>
  </div>
</fivef-dialog>
