<ngx-extended-pdf-viewer
  #pdfViewer
  [showOpenFileButton]="false"
  [showPrintButton]="showEditingMode"
  [showDownloadButton]="showEditingMode"
  [language]="lang"
  [showStampEditor]="showEditingMode"
  [showTextEditor]="showEditingMode"
  [showDrawEditor]="showEditingMode"
  [showPresentationModeButton]="true"
  [showBookModeButton]="false"
  [showHandToolButton]="true"
  [showPagingButtons]="'always-visible'"
  [showRotateButton]="'always-visible'"
  [showFindHighlightAll]="showEditingMode"
  (pdfLoaded)="onPdfLoad()"
  [showUnverifiedSignatures]="true" [src]="src"></ngx-extended-pdf-viewer>
<!--<ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>-->
