<table class="address-book-table" cellpadding="0" cellspacing="0" border="0">
  <thead>
  <tr>
    <th *ngIf="false" class="address-book-table__head-cell select-cell">
      <mat-checkbox (change)="masterToggle()"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>

    <th *ngIf="columnsToDisplay.includes('avatar')" class="address-book-table__head-cell avatar-cell"></th>

    <th *ngIf="columnsToDisplay.includes('visibility')"
        class="address-book-table__head-cell visibility-cell d-none d-lg-flex"></th>

    <th *ngIf="columnsToDisplay.includes('name')"
        class="address-book-table__head-cell name-cell">{{ 'CONTACTS.NAME' | translate }} *
    </th>

    <th *ngIf="columnsToDisplay.includes('contactClients')"
        class="address-book-table__head-cell associatedClients-cell d-none d-lg-flex">{{ 'CONTACTS.ASSOCIATED_CLIENTS' | translate }}
    </th>

    <th *ngIf="columnsToDisplay.includes('email')"
        class="address-book-table__head-cell email-cell d-none d-md-flex">{{ 'CONTACTS.EMAIL-TABLE-HEADER' | translate }}
      *
    </th>

    <th *ngIf="columnsToDisplay.includes('phoneNumber')"
        class="address-book-table__head-cell phoneNumber-cell d-none d-xl-flex">{{ 'CONTACTS.PHONE_TITLE' | translate }}
    </th>

    <th *ngIf="columnsToDisplay.includes('partnerLink')"
        class="address-book-table__head-cell partnerLink-cell d-none d-xxl-flex">
      <dvtx-table-text-and-info [title]="partnerLinkColumnTitle"
                                [tooltip]="'PARTNER_LINK.ACCESSIBLE_INFO'"></dvtx-table-text-and-info>
    </th>

    <th *ngIf="columnsToDisplay.includes('partnerLinksAlternative')"
        class="address-book-table__head-cell partnerLinksAlternative-cell d-none d-xxl-flex">
      <dvtx-table-text-and-info [title]="partnerLinkColumnTitle"
                                [tooltip]="'PARTNER_LINK.ACCESSIBLE_INFO'"></dvtx-table-text-and-info>
    </th>

    <th *ngIf="columnsToDisplay.includes('contact')" class="address-book-table__head-cell contact-cell">
      <dvtx-table-text-and-info [title]="'CONTACTS.CONTACT_ENTRY'"
                                [tooltip]="'CONTACTS.CONTACT_ENTRY_INFO'"></dvtx-table-text-and-info>
    </th>

    <th *ngIf="columnsToDisplay.includes('member')"
        class="address-book-table__head-cell member-cell">{{ 'CONTACTS.MEMBER' | translate }}
    </th>

    <th *ngIf="columnsToDisplay.includes('projectRooms')"
        class="address-book-table__head-cell projectRooms-cell">{{ 'PROJECT_ROOM.PROJECT_ROOMS' | translate }}
    </th>

    <th *ngIf="columnsToDisplay.includes('two-fa')"
        class="address-book-table__head-cell two-fa-cell d-none d-xxl-flex">
      <dvtx-table-text-and-info [title]="'PROCESS_MANAGEMENT.TWO_FA_ENABLED'"
                                [tooltip]="'PROCESS_MANAGEMENT.TWO_FA_ENABLED_TOOLTIP'"></dvtx-table-text-and-info>
    </th>

    <th *ngIf="columnsToDisplay.includes('actions')" class="address-book-table__head-cell th-actions-cell"></th>

    <th *ngIf="columnsToDisplay.includes('participationActions')"
        class="address-book-table__head-cell participationActions-cell"></th>

  </tr>
  </thead>
  <tbody>
  </tbody>
</table>
<cdk-virtual-scroll-viewport *ngIf="(contactDataSource.data | async)?.length" [itemSize]="54" autosize>
  <table class="address-book-table" cellpadding="0" cellspacing="0" border="0">
    <thead>
    </thead>
    <tbody>
    <tr
      *cdkVirtualFor="let contact of contactDataSource.data | async; index as i; templateCacheSize: 0; trackBy: identifyByKey"
      class="address-book-table__row">

      <td *ngIf="false"
          class="address-book-table__cell select-cell">
        <mat-checkbox (change)="toggle($event, contact)"
                      (click)="$event.stopPropagation()"
                      [checked]="selection.isSelected(contact)">
        </mat-checkbox>
      </td>

      <td *ngIf="columnsToDisplay.includes('avatar')"
          class="address-book-table__cell avatar-cell">
        <ng-template [ngIf]="contact.type === 'naturalPersonContact'" [ngIfElse]="memberTemplate">
          <span>
            <!-- <mat-icon>person_outline</mat-icon> -->
            <!--<i *ngIf="contact.hasAccount" class="fa fa-dvtx fa-check account--indicator"></i>-->
            <!-- <mat-icon *ngIf="contact.hasAccount" class="account--indicator">check_circle_outline</mat-icon> -->
            <fivef-avatar [email]="contact?.email" [size]="'sm'"></fivef-avatar>
          </span>
        </ng-template>
        <ng-template #memberTemplate>
          <span>
             <fivef-avatar [email]="contact?.email" [size]="'sm'"></fivef-avatar>
          </span>
        </ng-template>
      </td>

      <td *ngIf="columnsToDisplay.includes('visibility')"
          class="address-book-table__cell visibility-cell d-none d-lg-flex">
        <ng-container [ngSwitch]="contact.contactVisibility">
          <mat-icon *ngSwitchCase="'visiblePublic'" [matTooltip]="'Public'">visibility</mat-icon>
          <mat-icon *ngSwitchCase="'visiblePrivate'" [matTooltip]="'Private'">lock</mat-icon>
        </ng-container>
      </td>

      <td *ngIf="columnsToDisplay.includes('name')"
          class="address-book-table__cell name-cell" [matTooltip]="contact?.name">
        <span *ngIf="contact.type === 'Membership' || contact?.key === 'noContact'">{{ contact?.name }}</span>
        <dvtx-title-editable
          [classNames]="'m-0'"
          [placeholder]="'CONTACTS.ADD_LAST_NAME' | translate"
          *ngIf="contact.type !== 'Membership' && contact?.key !== 'noContact'"
          [renameDoc]="true"
          (onChange)="editContactLastName(contact, $event)"
          [fontSize]="'null'"
          [editIcon]="false"
          [showOnHover]="false"
          [value]="contact?.lastName">
        </dvtx-title-editable>
        <ng-container *ngIf="contact.type !== 'Membership' && contact?.key !== 'noContact'">,&nbsp;</ng-container>
        <dvtx-title-editable
          [classNames]="'m-0'"
          [renameDoc]="true"
          [placeholder]="'CONTACTS.ADD_FIRST_NAME' | translate"
          (onChange)="editContactFirstName(contact, $event)"
          [fontSize]="'null'"
          [editIcon]="true"
          [showOnHover]="true"
          *ngIf="contact.type !== 'Membership' && contact?.key !== 'noContact'"
          [value]="contact?.firstName">
        </dvtx-title-editable>
        <span [matTooltip]="contact.name" class="cell-text-truncate">
          <!-- {{contact.name}} -->
          <ng-container *ngIf="contact.email === (ownId$ | async)?.uid">
            ({{ "GENERAL.YOU" | translate }})
          </ng-container>
        </span>
      </td>

      <td *ngIf="columnsToDisplay.includes('contactClients')"
          class="address-book-table__cell associatedClients-cell d-none d-lg-flex">
        <ng-template [ngIf]="clientMappingLoading" [ngIfElse]="clientDropdown">
          <div class="d-flex">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </ng-template>

        <ng-template #clientDropdown>
          <fivef-item-selector *ngIf="contact?.key !== 'noContact'"
                               [disabled]="false"
                               [collection]='clients$ | async'
                               [selected]="contact?.clients"
                               [resourceSelectorKey]="'clientId'"
                               [listItemTemplate]="'client'"
                               (onSelect)="createContactClient(contact, $event)"
                               (onDeselect)="removeContactClient(contact, $event)"></fivef-item-selector>
        </ng-template>
      </td>

      <td *ngIf="columnsToDisplay.includes('email')"
          class="address-book-table__cell email-cell d-none d-md-flex">
        <span [matTooltip]="contact.email" class="cell-text-truncate">
          <span
            *ngIf="disableEmailEdit || contact?.type === 'Membership' || contact?.key === 'noContact'">{{ contact?.email }}</span>
          <dvtx-title-editable
            *ngIf="!disableEmailEdit && contact?.type !== 'Membership' && contact?.key !== 'noContact'"
            [classNames]="'m-0'"
            [placeholder]="'CONTACTS.ADD_EMAIL' | translate"
            [renameDoc]="true"
            (onChange)="editContactEmail(contact, $event)"
            [fontSize]="'null'"
            [editIcon]="true"
            [showOnHover]="true"
            [isEmail]="true"
            [value]="contact?.email">
        </dvtx-title-editable>
        </span>
      </td>

      <td *ngIf="columnsToDisplay.includes('phoneNumber')"
          class="address-book-table__cell phoneNumber-cell d-none d-xl-flex">
        <span *ngIf="contact.type === 'Membership'">{{ contact?.telephone }}</span>
        <dvtx-title-editable
          *ngIf="contact.type !== 'Membership'"
          [classNames]="'m-0'"
          [placeholder]="'CONTACTS.ADD_PHONE_NUMBER' | translate"
          [renameDoc]="true"
          (onChange)="editContactPhoneNumber(contact, $event)"
          [removeIcon]="true"
          [fontSize]="'null'"
          [isPhoneNumber]="true"
          [editIcon]="true"
          [showOnHover]="true"
          [value]="contact?.telephone === '49' ? '' : contact?.telephone ">
        </dvtx-title-editable>
      </td>

      <td *ngIf="columnsToDisplay.includes('partnerLink')"
          class="address-book-table__cell partnerLink-cell">
        <div [matTooltip]="'ADDRESSBOOK.ACTIVATE_PARTNER_LINKS' | translate"
             class="w-100 d-flex justify-content-center d-none d-xxl-flex">
          <mat-checkbox (change)="authorizePartner($event, contact)" (click)="$event.stopPropagation()"
                        [checked]="!!contact.partnerLinkId"></mat-checkbox>
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('partnerLinksAlternative')"
          class="address-book-table__cell partnerLinksAlternative-cell d-none d-xxl-flex">
        <div [matTooltip]="'ADDRESSBOOK.ACTIVATE_PARTNER_LINKS' | translate"
             class="w-100 d-flex justify-content-center">
          <mat-checkbox (change)="authorizePartner($event, contact)" (click)="$event.stopPropagation()"
                        [checked]="!!contact.partnerLinkId"></mat-checkbox>
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('contact')"
          class="address-book-table__cell contact-cell">
        <div *ngIf="contact.type === 'naturalPersonContact'"
             class="w-100 d-flex justify-content-center">
          <mat-icon class="fivef-color-status-success">check_circle</mat-icon>
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('member')"
          class="address-book-table__cell member-cell">
        <div *ngIf="contact.type === 'Membership' || contact?.isMember"
             class="w-100 d-flex justify-content-center">
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-500">check_circle</mat-icon>
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('projectRooms')"
          class="address-book-table__cell projectRooms-cell">
        <div class="w-100 d-flex justify-content-center">
          {{ contact?.processCount }}
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('two-fa')"
          class="address-book-table__cell two-fa-cell two-fa-cell--value d-none d-xxl-flex">
        <ng-container *ngIf="hasTwofactorEnabled(contact?.email) | async">
          <strong>{{ 'GENERAL.YES' | translate }}</strong>
        </ng-container>
      </td>


      <td *ngIf="columnsToDisplay.includes('actions')"
          class="address-book-table__cell actions-cell">
        <div class="w-100 d-flex justify-content-end">
          <div>
            <button (click)="openContactDeletionDialog($event, contact)"
                    *ngIf="enableDeleteAction && contact.type !== 'Membership'"
                    [disabled]="processParticipationLoading || contact.createdBy !== (ownId$ | async)?.email && (administrationRights$ | async)?.hasAdministrationRights === false"
                    [matTooltip]="'ADDRESSBOOK.DELETE_CONTACT_ACTION' | translate"
                    mat-icon-button>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button
                    *ngIf="contact.type !== 'Membership'"
                    (click)="openSettings(contact)"
                    [matTooltip]="'GENERAL.SHOW_MORE' | translate">
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </td>

      <td *ngIf="columnsToDisplay.includes('participationActions')"
          class="address-book-table__cell participationActions-cell">
        <!-- Entry is member: Provide Member deletion dialog. The memberEmailMap is a workaround that contact person entries can also be members of the organization -->
        <ng-template [ngIfElse]="contactDeleteButton" [ngIf]="contact?.isMember">
          <button (click)="openMemberRemovalDialog(contact)"
                  [disabled]="processParticipationLoading || contact?.email === currentUserUID"
                  [matTooltip]="'ORGANIZATION.DELETE_MEMBER' | translate"
                  mat-icon-button>
            <mat-icon>delete_outline</mat-icon>
          </button>
        </ng-template>

        <!-- Entry is normal contact entry: Provide contact deletion dialog in admin forced mode -->
        <ng-template #contactDeleteButton>
          <button (click)="openContactDeletionDialog($event, contact)"
                  *ngIf="contact.type !== 'Membership'"
                  [disabled]="processParticipationLoading || contact.createdBy !== (ownId$ | async)?.email && (administrationRights$ | async)?.hasAdministrationRights === false"
                  [matTooltip]="'ADDRESSBOOK.DELETE_CONTACT_ACTION' | translate"
                  mat-icon-button>
            <mat-icon>delete_outline</mat-icon>
          </button>
        </ng-template>

        <!-- Navigation to the Project Room Mgmt aka Project Room Listing where the selected contact is participant -->
        <div class="w-100 d-flex justify-content-end pr-2">
          <button (click)="navigateToProjectRooms($event, contact)"
                  [matTooltip]="'PROJECT_ROOM.TO_PROJECT_ROOMS' | translate"
                  mat-icon-button>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </td>

    </tr>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
<div class="loader" *ngIf="!(contactDataSource.data | async) || (contactDataSource.data | async)?.length === 0">
  <span>
    {{ 'GENERAL.NO_ENTRIES_FOUND' | translate }}
  </span>
</div>
