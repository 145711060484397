import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {endsWith} from 'lodash';
import {FivefNotificationService} from '../../notification/fivef-notification/fivef-notification.service';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'fivef-csv-import-dropzone',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  host: { class: 'fivef-csv-import-dropzone' },
  templateUrl: './fivef-csv-import-dropzone.component.html',
  styleUrls: ['./fivef-csv-import-dropzone.component.scss']
})
export class FivefCsvImportDropzoneComponent {
  @ViewChild('fileChooser', { static: true }) public fileChooser: {nativeElement: HTMLInputElement};
  @Input() public fileTypes: string[] = ['CSV'];
  @Input() sideNote: string;
  @Output() newFile = new EventEmitter<File>();

  constructor(private notificationService: FivefNotificationService) {
  }

  public openFileChooser() {
    this.fileChooser.nativeElement.click();
  }

  public onFileChange() {
    this.onNewFiles(this.fileChooser.nativeElement.files);
  }

  @HostListener('dragenter', ['$event'])
  @HostListener('dragleave', ['$event'])
  @HostListener('dragover', ['$event'])
  public onFileDragging(event) { // : DragEvent) { // Issue: safari ReferenceError: Can't find variable: DragEvent
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onFileDropped(event) { // : DragEvent) { // Issue: safari ReferenceError: Can't find variable: DragEvent
    this.onNewFiles(event.dataTransfer.files);
    event.preventDefault();
    event.stopPropagation();
  }

  public onNewFiles(files: FileList) {
    if (!files[0]) {
      return;
    }

    const fileMatchesFileTypes = this.fileTypes.some((fileType) => {
      return endsWith(files[0].name.toLowerCase(), `.${fileType}`.toLowerCase())
    });

    if (!fileMatchesFileTypes) {
      this.notificationService.error(`Bitte wählen Sie eine ${this.fileTypes.join(' / ')} Datei aus`);
      return;
    }

    this.newFile.next(files[0]);
  }
}
