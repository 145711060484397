<div class="dvtx-client-nav-bar-light five-f-mat-tab" [class.five-f-card-profile-user]="profile === FiveFCardProfile.User" *ngIf="routes">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a mat-tab-link *ngFor="let link of routes"
       [routerLink]="link.route"
       [active]="activeLink === link.title"> {{link.title | translate}} <ng-container *ngIf="link.count">({{link.count}})</ng-container>
      <span *ngIf="link.newFeature" class="five-f-beta-feature-button-tag ff-pointer"
            style="right: 3px; bottom: 2px; height: 15px; width: 21px; line-height: 14px; }">{{ 'GENERAL.NEW' | translate }}</span>
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</div>
