import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommentReactionType} from '../../../../../+store/comment/comment.interface';

@Component({
  selector: 'fivef-reactions',
  host: {'class': 'fivef-reactions'},
  templateUrl: './fivef-reactions.component.html',
  styleUrls: ['./fivef-reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefReactionsComponent {
  @Input()
  public reactions = [];

  @Output()
  public onReaction = new EventEmitter<CommentReactionType>();
}
