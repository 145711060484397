import {Component, Input, ChangeDetectionStrategy, ViewChild, EventEmitter, Output} from '@angular/core';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';

/**
 * Icon picker.
 * Currently used at label creation.
 *
 * Refactored from organization module, extended with new icons and simplified.
 */
@Component({
  selector: 'fivef-icon-picker',
  templateUrl: './fivef-icon-picker.component.html',
  standalone: true,
  host: {'class': 'fivef-icon-picker'},
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefIconPickerComponent {
  @ViewChild('buttonToggler') buttonToggler: MatButton;
  public showIconPicker = false;

  public readonly icons = [
    'accessibility',
    'assessment',
    'autorenew',
    'beenhere',
    'alarm',
    'call_merge',
    'check_circle',
    'close_circle',
    'date_range',
    'domain_verification',
    'donut_small',
    'event',
    'event_note',
    'favorite',
    'feedback',
    'forum',
    'help',
    'history',
    'hourglass_empty',
    'info',
    'language',
    'lock',
    'lock_open',
    'notifications_off',
    'not_interested',
    'mail',
    'phone_forwarded',
    'public',
    'rate_review',
    'schedule',
    'search',
    'security',
    'star',
    'visibility',
    'warning',
    'work'];

  @Output()
  onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  @Input()
  disabled = false;

  @Input()
  selected = null;

  @Input()
  xPosition = 'after';

  @Input()
  yPosition = 'below';

  @Input()
  tooltipPosition = 'top right';

  selectIcon($event) {
    this.onSelection.emit($event);
    this.showIconPicker = false;
  }

  public openIconPicker() {
    this.buttonToggler._elementRef.nativeElement.click()
  }
}
