<fivef-context-sidebar *ngIf="contact"
                       [nodeId]="contact?.id"
                       [title]="contact?.name"
                       [subtitle]="contact?.email">
  <mat-tab-group mat-stretch-tabs
                 fivefContextSidebarContent
                 disablePagination
                 [selectedIndex]="selectedTab"
                 (selectedIndexChange)="selectedTabChanged($event)">
    <mat-tab [label]="'SETTINGS.SETTINGS' | translate">
      <ng-template matTabContent>
        <ng-container *ngIf="!!personContact">
          <div *ngIf="!!invitationStatusPresent"
               style="font-size: 12px;">
            <div>
              <span class="greyTextColor">  {{ 'ADDRESSBOOK.INVITED_AT' | translate }}: </span>
              {{ invitationDate | date:'medium' }} &nbsp;•&nbsp;
              <span class="greyTextColor">  {{ 'GENERAL.STATUS' | translate }}: </span>
              <ng-container [ngSwitch]="invitationStatus">
                <span *ngSwitchCase="InvitationStatus.Accepted">{{ 'INVITATIONS.ACCEPTED' | translate }}</span>
                <span *ngSwitchCase="InvitationStatus.Declined">{{ 'INVITATIONS.DECLINED' | translate }}</span>
                <span *ngSwitchCase="InvitationStatus.Pending">{{ 'INVITATIONS.PENDING' | translate }}</span>
              </ng-container>
            </div>
          </div>

          <hr>

          <div
            *ngIf="(myMembership$ | async)?.hasAdministrationRights === false && currentUserUid !== contact?.createdBy">
            <fivef-icon-message-block [icon]="'info'" [message]="'ADDRESSBOOK.NEED_ADMIN_PRIVILEGES'" [alignment]="'horizontal'">

            </fivef-icon-message-block>
            <hr>
          </div>

          {{ 'CONTACTS.SET_VISIBILITY_FOR_INFORMATION' | translate }}

          <fivef-contact-visibility-select [(ngModel)]="personContact.contactVisibility"
                                           [disabled]="!editmode"></fivef-contact-visibility-select>

          <hr>

          <fivef-contact-person class="mt-2 mb-3"
                                [disabled]="!editmode"
                                [(ngModel)]="form"
                                [displayFurtherDataFields]="true"
                                title=""></fivef-contact-person>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ 'CONTACTS.ASSOCIATED_CLIENTS' | translate }}</span>
      </ng-template>
      <ng-template matTabContent>
        <fivef-client-contact-listing [selectedContact]="contact"
                                      [isContact]="true"
                                      (updateMapping)="updateMapping($event)"></fivef-client-contact-listing>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="(featureSet$ | async)?.hasMobileSupport"
             [label]="'ADDRESSBOOK.MOBILE_ACTIVATION.TITLE' | translate">
      <ng-template matTabContent>
        <fivef-contact-mobile-settings [userEmail]="contact?.email"></fivef-contact-mobile-settings>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div fivef-context-sidebar-footer style="display: flex">
    <div *ngIf="(myMembership$ | async)?.hasAdministrationRights && (featureSet$ | async)?.hasPartnerLinks"
         class="mr-4">
      <mat-checkbox [checked]="!!partnerLinkId"
                    [labelPosition]="'before'"
                    (change)="authorizePartner($event)">
        {{ 'PARTNER_LINK.ACTIVATE_PARTNERLINKS' | translate }}
      </mat-checkbox>
    </div>

    <button
      *ngIf="!editmode && ((myMembership$ | async).hasAdministrationRights || currentUserUid === contact?.createdBy)"
      (click)="editmode = true;"
      mat-raised-button>
      <mat-icon matPrefix>edit</mat-icon>
      {{ 'GENERAL.EDIT_ACTION' | translate }}
    </button>

    <button *ngIf="editmode" [disabled]="!form.isValid" mat-raised-button
            (click)="saveData()">
      <mat-icon matPrefix>save</mat-icon>
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>

    <button
      *ngIf="invitationStatus === null"
      mat-raised-button
      (click)="sendMemberInvitation()"
      [disabled]="invitationSent">
      <mat-icon matPrefix>send</mat-icon>
      {{ 'INVITATIONS.INVITE' | translate }}
    </button>

    <button
      *ngIf="invitationStatus === InvitationStatus.Pending"
      mat-raised-button
      (click)="sendMemberInvitation()">
      <mat-icon matPrefix>send</mat-icon>
      {{ 'INVITATIONS.RESEND_INVITATION' | translate }}
    </button>

    <button mat-dialog-close
            mat-raised-button color="primary">
      {{ 'GENERAL.CLOSE_ACTION' | translate }}
    </button>
  </div>
</fivef-context-sidebar>
