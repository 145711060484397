import {NgModule} from '@angular/core';
import {ClientsFilterComponent} from './components/clients-filter/clients-filter.component';
import {FivefTruncatePipe} from '../../fivef-ui/util/fivefTruncate.pipe';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    FivefTruncatePipe,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatSelectModule
  ],
  declarations: [
    ClientsFilterComponent
  ],
  exports: [
    ClientsFilterComponent
  ]
})
export class ClientsFilterModule {
}
