<div class="d-flex p-3 justify-content-end">
  <div>
    <button mat-raised-button color="primary" (click)="importContacts()" [disabled]="selection.selected.length === 0">
      {{ 'DAV.IMPORT_CONTACTS' | translate }}
    </button>
  </div>
</div>

<table mat-table [dataSource]="dataSource" class="p-1 w-100" *ngIf="dataSource">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef style="width: 40px;">
      <div class="d-flex justify-content-center">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </div>
    </th>
    <td mat-cell *matCellDef="let row"
        style="width: 40px;"
        (click)="selection.toggle(row)">
      <div class="d-flex justify-content-center">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="exists">
    <th mat-header-cell *matHeaderCellDef style="width: 40px;">
      <div class="d-flex justify-content-center"><div>{{ 'DAV.IMPORTED' | translate }}</div></div></th>
    <td mat-cell *matCellDef="let element" style="width: 40px;">
      <div class="d-flex justify-content-center">
        <div *ngIf="element?.imported">
          <mat-icon>check_circle</mat-icon>
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>{{ 'CONTACTS.FIRST_NAME' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>{{ 'CONTACTS.LAST_NAME' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'CONTACTS.NAME' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>{{ 'CONTACTS.EMAIL' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef> {{ 'CONTACTS.PHONE' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{element?.phone}} </td>
  </ng-container>

  <ng-container matColumnDef="contactType">
    <th mat-header-cell *matHeaderCellDef style="width: 60px;">{{ 'DAV.CONTACT_TYPE' | translate }}</th>
    <td mat-cell *matCellDef="let element" style="width: 60px;">
      <mat-form-field>
        <mat-select placeholder="{{ 'DAV.CONTACT_TYPE' | translate }}"
                    [(ngModel)]="element.contactType">
          <mat-select-trigger>
            <div *ngIf="element.contactType === DavContactType.Organization">
              <span class="dvtx-inline-valign"><mat-icon matPrefix>business</mat-icon></span> Organization
            </div>
            <div *ngIf="element.contactType === DavContactType.Person">
              <span class="dvtx-inline-valign"><mat-icon matPrefix>person</mat-icon></span> Person
            </div>
          </mat-select-trigger>
          <mat-option [value]="DavContactType.Person">
            <mat-icon matPrefix>person</mat-icon>
            Person
          </mat-option>
          <mat-option [value]="DavContactType.Organization">
            <mat-icon matPrefix>business</mat-icon> Organization
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="visibility">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex justify-content-center">
        <div>
          {{ 'DAV.PRIVATE_CONTACT_TYPE' | translate }}
        </div>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center">
        <div>
          <mat-checkbox [(ngModel)]="element.private">
          </mat-checkbox>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="invite">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex justify-content-center"><div>{{ 'DAV.INVITE' | translate }}</div></div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center">
        <div *ngIf="element.contactType === DavContactType.Person">
          <mat-checkbox [(ngModel)]="element.invite">
          </mat-checkbox>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </tr>
</table>

<!--<pre>-->
  <!--{{ contacts | json }}-->
<!--</pre>-->

<!--<pre>-->
  <!--{{ addressBookContacts$ | async | json }}-->
<!--</pre>-->
