import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {DocumentPreviewPreview} from './document-preview-preview';

export class DocumentPreviewPreviewBuilder implements IApiResourceBuilder<DocumentPreviewPreview> {
  fromResponse(data): DocumentPreviewPreview {
    return new DocumentPreviewPreview(
      data.id,
      data.attributes.page,
      data.attributes.status,
      data.attributes.content,
      null,
      data.attributes.mime_type,
      data.attributes.width,
      data.attributes.height
    );
  }

  toRequest(preview: DocumentPreviewPreview) {
    return null;
  }
}
