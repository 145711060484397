import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProcessAction} from './process-action';

export enum ProcessActionActionTypes {
  Run = '[ProcessAction] Run Action',
  RunSuccess = '[ProcessAction] Run Action Success',
  RunFail = '[ProcessAction] Run Action Fail',
}

export class Run implements Action {
  readonly type = ProcessActionActionTypes.Run;

  constructor(public command: any) {
  }
}

export class RunSuccess implements Action {
  readonly type = ProcessActionActionTypes.RunSuccess;

  constructor(public payload: any) {
  }
}

export class RunFail implements Action {
  readonly type = ProcessActionActionTypes.RunFail;

  constructor(public payload: any) {
  }
}

export type ProcessActionActions =
  | Run
  | RunSuccess
  | RunFail;
