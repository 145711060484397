import {Injectable} from '@angular/core';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import FavoriteProcessBuilder from "./favorite-process.builder";
import FavoriteProcess from "./favorite-process";
import {Observable} from "rxjs";


@Injectable()
export class FavoriteProcessService {
  readonly BASE_PATH = 'api/v3/favorites/favorites';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(page: number = 1, sortBy: string, sortDirection: string, timestamp: number): Observable<any> {
    const builder = new FavoriteProcessBuilder();
    let queryParams = `?page=${page}&sort=${sortBy}&direction=${sortDirection}`;
    if (timestamp !== null) {
      queryParams = queryParams + `&timestamp=${timestamp}`
    }
    return <Observable<FavoriteProcess[]>>this._http.get<FavoriteProcessBuilder, FavoriteProcess>(builder, `${this.BASE_PATH}${queryParams}`);
  }
}

