import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {OrganizationProxyService} from '../../../../+store/organization/organization-proxy.service';
import {DmsFolderActions, DmsFolderSelectors, OrganizationSelectors} from '../../../../+store';
import {DmsAccountType} from '../../../../+store/dms-folder/dms-folder.interface';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {User} from '../../../../+store/user/user';
import {DmsFolder} from '../../../../+store/dms-folder/dms-folder';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {OrganizationProxy} from '../../../../+store/organization/organization-proxy';
import {Organization} from '../../../../+store/organization/organization';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class FivefFolderTreeDialogRepository implements OnDestroy {
  private onDestroy = new Subject<void>();

  public folders: Observable<DmsFolder[]>;
  private permitPrivateFolders$ = new BehaviorSubject<boolean>(false);
  public loading: Observable<boolean>;

  /**
   * If set to true, the root folder will be renamed to the organization.
   */
  public renameRootToOrganization = false;

  constructor(private store: Store<AppState>,
              private orgSettingsSvc: OrganizationProxyService,
              private ngZone: NgZone) {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.permitPrivateFolders$.complete();
  }

  public refresh() {
    this.ngZone.runOutsideAngular(_ => {
      this.store.dispatch(new DmsFolderActions.LoadAll(DmsAccountType.Private));
      this.store.dispatch(new DmsFolderActions.LoadAll(DmsAccountType.Organization));
    });
  }

  /**
   * Initializes all streams.
   */
  public init() {
    this.loading = this.store.select(DmsFolderSelectors.loadingState);

    // The folder tree is containing optional private folders if permitted by organizational setup
    // and / or the public folders.
    const user$: Observable<User> = this.store.select('currentUser')
      .pipe(filter(u => !!u), distinctUntilKeyChanged('email'), takeUntil(this.onDestroy));
    this.permitPrivateFolders$
      .pipe(filter(p => !!p), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe((permitPrivateFolders: boolean) => {
        if (permitPrivateFolders) {
          this.store.dispatch(new DmsFolderActions.LoadAll(DmsAccountType.Private));
        }
      });

    const privateFolders$ =
      combineLatest(this.permitPrivateFolders$, user$, this.store.select(DmsFolderSelectors.getPrivateFolders))
        .pipe(map(([permitPrivateFolders, user, folders]: [boolean, User, DmsFolder[]]) => {
          if (permitPrivateFolders) {
            return this.renameRootToUsername(user, folders)
          }
          return [];
        }));

    const org$ = this.store.select(OrganizationSelectors.getSelected)
      .pipe(
        filter(org => !!org),
        distinctUntilKeyChanged('id'),
        takeUntil(this.onDestroy)
      );
    org$.subscribe(oid => this.fetchOrganizationFolders());

    org$.subscribe(org => {
      this.permitPrivateFolders$.next(false);
      this.orgSettingsSvc
        .getOne(org.id)
        .pipe(first())
        .subscribe((settings: OrganizationProxy) => {
          this.permitPrivateFolders$.next(!settings.disable_private_dms);
        });
    });

    const organizationFolders$: Observable<DmsFolder[]> =
      org$.pipe(
        switchMap(org => {
          return this.store.select(DmsFolderSelectors.getOrganizationFolders(org.id))
            .pipe(map((folders) => this.renameRootToOrganizationName(org, folders)))
        }));

    this.folders = combineLatest(privateFolders$, organizationFolders$)
      .pipe(map(([privateFolders, organizationFolders]) => privateFolders.concat(organizationFolders)));
  }

  private fetchOrganizationFolders() {
    this.store.dispatch(new DmsFolderActions.LoadAll(DmsAccountType.Organization));
  }

  private renameRootToUsername(user: User, folders: DmsFolder[]): DmsFolder[] {
    return folders.map(folder => {
      if (folder.parentId) {
        return folder;
      }
      folder.name = user.name;
      return folder;
    })
  }

  /**
   * Renames main folder (root) to organization name.
   * @param organization
   * @param folders
   * @private
   */
  private renameRootToOrganizationName(organization: Organization, folders: DmsFolder[]): DmsFolder[] {
    return folders.map(folder => {
      if (folder.parentId) {
        return folder;
      }
      if (folder.name === 'Root') {
        folder.name = organization.name;
      }
      return folder;
    })
  }
}
