import {Injectable} from '@angular/core';
import {catchError, map, mapTo, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CppApiService} from 'app/services/cpp-api.service';
import {
  AddFurtherAddress,
  AddFurtherAddressSuccess,
  AddFurtherEmail,
  AddFurtherEmailSuccess,
  AddFurtherPhoneNumber,
  AddFurtherPhoneNumberSuccess,
  LoadMyFail,
  LoadMySuccess,
  NaturalPersonActionTypes,
  RemoveFurtherAddress,
  RemoveFurtherAddressSuccess,
  RemoveFurtherEmail,
  RemoveFurtherEmailSuccess,
  RemoveFurtherPhoneNumber,
  RemoveFurtherPhoneNumberSuccess,
  UpdateMy,
  UpdateMySuccess
} from './natural-person.actions';
import {naturalPersonfromJsonAPI, NaturalPersonResponse} from './natural-person';
import {SimpleAddress, SimpleEmailAddress, SimplePhoneNumber, SimpleSingleAddressResponse, SimpleSingleEmailAddressResponse} from '../contact/legacy/models/contact.interface';
import {NotificationActions} from '../notification';
import {HttpErrorResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as JsonAPI from '../_legacy/models/json-api.model'
import {SyncApiProfileService} from 'app/+store/_legacy/api/services/sync-api-profile.service';
import {FivefNotificationService} from '../../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class NaturalPersonEffects {
  loadMyNP = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.LoadMy),
    switchMap(() => {
      return this.cppApiService.get<JsonAPI.Root<NaturalPersonResponse>>('person').pipe(
        map(res => new LoadMySuccess(naturalPersonfromJsonAPI(res.data, res.included))),
        catchError(err => of(new LoadMyFail(err)))
      )
    })
  ));

  UpdateMy = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.UpdateMy),
    switchMap((action: UpdateMy) => {
      const mainEmailAddress = new SimpleEmailAddress();
      const mainAddress = new SimpleAddress();
      const mainPhoneNumber = new SimplePhoneNumber();
      if (action.payload.mainAddress) {
        mainAddress.id = action.payload.mainAddress.id;
        mainAddress.city = action.payload.mainAddress.city;
        mainAddress.countryName = action.payload.mainAddress.countryName;
        mainAddress.locationOrType = action.payload.mainAddress.locationOrType;
        mainAddress.street = action.payload.mainAddress.street;
        mainAddress.streetNo = action.payload.mainAddress.streetNo;
        mainAddress.zip = action.payload.mainAddress.zip;
      }
      if (action.payload.mainEmailAddress) {
        mainEmailAddress.id = action.payload.mainEmailAddress.id;
        mainEmailAddress.emailAddress = action.payload.mainEmailAddress.emailAddress;
        mainEmailAddress.locationOrType = action.payload.mainEmailAddress.locationOrType;
      }
      if (action.payload.mainPhoneNumber) {
        mainPhoneNumber.id = action.payload.mainPhoneNumber.id;
        mainPhoneNumber.locationOrType = action.payload.mainPhoneNumber.locationOrType;
        mainPhoneNumber.countryCode = action.payload.mainPhoneNumber.countryCode;
        mainPhoneNumber.phoneNumber = action.payload.mainPhoneNumber.phoneNumber;
      }

      const body = {
        title_id: String(action.payload.title),
        name: action.payload.lastName + ', ' + action.payload.firstName,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        main_email_address: mainEmailAddress ? mainEmailAddress.toCppRequest() : '',
        main_email_id: action.payload.mainEmailAddress.id,
        main_address: mainAddress ? mainAddress.toCppRequest() : '',
        main_address_id: action.payload.mainAddress ? action.payload.mainAddress.id : '',
        main_phone: mainPhoneNumber ? mainPhoneNumber.toCppRequest() : '',
        main_phone_id: action.payload.mainPhoneNumber ? action.payload.mainPhoneNumber.id : '',
        honorific: action.payload.honorific,
      }
      return this.cppApiService.put<JsonAPI.Root<NaturalPersonResponse>>(`person/${action.payload.id}/profile`, body).pipe(
        map(my => {
          this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
          return new UpdateMySuccess(naturalPersonfromJsonAPI(my.data, my.included))
        })
      )
    })
  ));

  // Synchronizes the CPP user profile with the proxy object of the API.
  SyncApiProfile = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.UpdateMy),
    switchMap((action: UpdateMy) => {
      return this.syncApiProfileSvc.update({
        title_id: action.payload.title,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        honorific: action.payload.honorific
      })
    })
  ), {dispatch: false});

  addFurtherEmail = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherEMail),
    switchMap((action: AddFurtherEmail) => {
      return this.cppApiService.post<SimpleSingleEmailAddressResponse>(`person/${action.payload.personId}/email`, action.payload.emailAddress.toCppRequest()).pipe(
        map((res: SimpleSingleEmailAddressResponse) => {
          this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
          const emailAddress = new SimpleEmailAddress();
          emailAddress.id = res.data.id;
          emailAddress.emailAddress = res.data.attributes.email_address;
          emailAddress.locationOrType = res.data.attributes.address_type_id;
          return new AddFurtherEmailSuccess({
            personId: action.payload.personId,
            emailAddress
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  removeFurtherEmail = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherEMail),
    switchMap((action: RemoveFurtherEmail) => {
      this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/email/${action.payload.emailAddressId}`).pipe(
        map(() => new RemoveFurtherEmailSuccess(action.payload.personId, action.payload.emailAddressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  addFurtherAddress = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherAddress),
    switchMap((action: AddFurtherAddress) => {
      return this.cppApiService.post<SimpleSingleAddressResponse>(`person/${action.payload.personId}/address`, action.payload.address.toCppRequest()).pipe(
        map((res: SimpleSingleAddressResponse) => {
          this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
          const address = new SimpleAddress()
          address.id = res.data.id;
          address.city = res.data.attributes.city;
          address.countryName = res.data.attributes.country;
          address.locationOrType = res.data.attributes.address_type_id;
          address.street = res.data.attributes.street;
          address.streetNo = res.data.attributes.street_number;
          address.zip = res.data.attributes.post_code;

          return new AddFurtherAddressSuccess({
            personId: action.payload.personId,
            address
          });
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  removeFurtherAddress = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherAddress),
    switchMap((action: RemoveFurtherAddress) => {
      this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/address/${action.payload.addressId}`).pipe(
        map(() => new RemoveFurtherAddressSuccess(action.payload.personId, action.payload.addressId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  addFurtherPhoneNumber = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.AddFurtherPhoneNumber),
    switchMap((action: AddFurtherPhoneNumber) => {
      return this.cppApiService.post<any>(`person/${action.payload.personId}/phone`, action.payload.phoneNumber.toCppRequest()).pipe(
        map(res => {
          this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
          const phoneNumber = new SimplePhoneNumber();
          phoneNumber.id = res.data.id;
          phoneNumber.countryCode = res.data.attributes.country_code;
          phoneNumber.phoneNumber = res.data.attributes.phone_number;
          phoneNumber.locationOrType = res.data.attributes.address_type_id;

          return new AddFurtherPhoneNumberSuccess({
            personId: action.payload.personId,
            phoneNumber
          })
        }),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  removeFurtherPhoneNumber = createEffect(() => this.actions.pipe(
    ofType(NaturalPersonActionTypes.RemoveFurtherPhoneNumber),
    switchMap((action: RemoveFurtherPhoneNumber) => {
      this._notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
      return this.cppApiService.delete<any>(`person/${action.payload.personId}/phone/${action.payload.phoneNumberId}`).pipe(
        mapTo(new RemoveFurtherPhoneNumberSuccess(action.payload.personId, action.payload.phoneNumberId)),
        catchError((err: HttpErrorResponse) => of(new NotificationActions.ShowHttpError(err)))
      )
    })
  ));

  constructor(private actions: Actions,
              private store: Store<any>,
              private cppApiService: CppApiService,
              private _notifyService: FivefNotificationService,
              private syncApiProfileSvc: SyncApiProfileService
  ) {
  }
}
