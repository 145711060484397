import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {StringUtils} from 'app/lib/string_utils';
import * as api from 'app/+store/iam/process-policy.interface';

export class ProcessPolicyBuilder implements IApiResourceBuilder<api.Iam.IProcessPolicy> {
  fromResponse(data): api.Iam.IProcessPolicy {
    const policy = api.Iam.DEFAULT_PROCESS_POLICY;
    const attrs = data.attributes;

    // Only consider properties beginning with can_ or has_, e.g. can_create_signature.
    const canDos = Object.keys(attrs).filter(key => ~key.indexOf('can_') || ~key.indexOf('has_'));
    canDos.forEach(can => {
      if (can[can.length - 1] === '?') {
        const prop = can.substring(0, can.length - 1);
        policy[StringUtils.camalize(prop)] = attrs[can];
      }
    });
    return policy;
  }

  toRequest(feature: api.Iam.IProcessPolicy) {
    return null;
  }
}
