import {IPartnerLink} from './partner-link.interface';

export const DefaultIconLink = '../../../assets/img/5f-logo.png';

export class PartnerLink implements IPartnerLink {
  readonly type = 'cms_partner_links';

  assignees: any[] = [];

  constructor(public id,
              public organizationId,
              public title: string,
              public description,
              public url,
              public fgColor: string,
              public bgColor: string,
              public iconLink: string,
              public visibility,
              public publishedAt = null,
              public createdAt = null,
              public updatedAt = null) {
  }
}
