import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {QuickshareAttachment} from './quickshare-attachment';

export class QuickshareAttachmentBuilder implements IApiResourceBuilder<QuickshareAttachment> {
  fromResponse(data): QuickshareAttachment {
    return new QuickshareAttachment(
      data.id,
      data.attributes.process_id,
      data.attributes.display_name,
      data.attributes.filename,
      data.attributes.description,
      data.attributes.uploaded_by,
      data.attributes.revision,
      data.attributes.fingerprint,
      data.attributes.size,
      data.attributes.mime_type,
      data.attributes.created_at,
      data.attributes.updated_at,
      data.attributes.content);
  }

  toRequest(_: QuickshareAttachment) {
    return null;
  }
}
