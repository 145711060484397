import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fivefEnumToArray',
  standalone: true
})
export class FivefEnumToArrayPipe implements PipeTransform {
  transform(data: Object) {
    const array = [];
    Object.keys(data).forEach( (key) => {
      array[key] = data[key];
    });
    return array;
  }
}

@Pipe({
  name: 'fivefEnumKeys',
  standalone: true
})
export class FivefEnumKeysPipe implements PipeTransform {
  transform(data: Object) {
    if (!data) return [];
    return Object.keys(data);
  }
}

@Pipe({
  name: 'fivefEnumValues',
  standalone: true
})
export class FivefEnumValuesPipe implements PipeTransform {
  transform(data: Object) {
    const values = [];
    Object.keys(data).forEach( (key) => {
      values.push(data[key]);
    });
    return values;
  }
}
