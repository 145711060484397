import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {DropdownContentComponent} from './component/dropdown-content.component';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TooltipModule
  ],
  declarations: [
    DropdownContentComponent
  ],
  exports: [
    DropdownContentComponent
  ]
})
export class DropdownContentModule {
}
