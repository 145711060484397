<fivef-dialog [title]="'CONTACTS.DELETE_CONTACT_TITLE'"
              [isUtilityDialog]="true"
              [infoColor]="(forceContactDeletionByAdmin || !lockContactForDeletion ) ? 'warn' : 'primary'">

  <div class="mb-2 d-flex flex-column" style="max-width: 700px;">
    <!-- content in forced mode, e.g. project room administration (not address book!) -->
    <ng-template [ngIf]="forceContactDeletionByAdmin" [ngIfElse]="addressBookContent">
      <ng-template [ngIf]="deletionOngoing" [ngIfElse]="dialogContent">
        <!-- Loader on request -->
        <div class="w-100 my-3 d-flex justify-content-center">
          <fivef-loading-indicator [message]="'ADDRESSBOOK.DELETING_CONTACT'"></fivef-loading-indicator>
        </div>
      </ng-template>

      <ng-template #dialogContent>
        <div class="mb-2 d-flex align-items-center h3">
          {{ 'CONTACTS.DELETE_CONTACT_TEXT' | translate }}
        </div>

        <div class="d-flex align-items-center h4">
          <div class="dvtx-inline-valign fivef-color-status-warn mr-2">
            <mat-icon>warning</mat-icon>
          </div>
          <div>
            {{ 'CONTACTS.DELETE_CONTACT_PROJECT_ROOM_REMOVAL_INFO' | translate }}
          </div>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #addressBookContent>
      <!--  Show only deletion info if no attached processes to contact otherwise redirect task to admin -->
      <ng-template [ngIf]="lockContactForDeletion" [ngIfElse]="deletionMessage">
        <div class="d-flex align-items-center h3">
          <div class="dvtx-inline-valign fivef-color-status-warn mr-2">
            <mat-icon>warning</mat-icon>
          </div>
          <div>
            {{ 'ADDRESSBOOK.CONTACT_PROJECT_ROOM_PARTICIPANT' | translate }}
          </div>
        </div>

        <!-- Admin info to delete contact by Project Room Mgmt -->
        <ng-template [ngIf]="isAdmin" [ngIfElse]="normalUser">
          <div class="h4 mt-2">
            {{ 'ADDRESSBOOK.CONTACT_DELETION_PROJECT_ROOM_ADMIN_INFO' | translate }}
          </div>
        </ng-template>

        <!-- Normal user info that contact must be deleted by admin -->
        <ng-template #normalUser>
          <div class="h4 mt-2" [innerHTML]="'ADDRESSBOOK.CONTACT_DELETION_PROJECT_ROOM_USER_INFO' | translate"></div>
        </ng-template>
      </ng-template>

      <!-- Contact has no attached Project Rooms and can be deleted -->
      <ng-template #deletionMessage>
        <ng-template [ngIf]="deletionOngoing" [ngIfElse]="dialogContent">
          <div class="w-100 my-3 d-flex justify-content-center">
            <fivef-loading-indicator [message]="'ADDRESSBOOK.DELETING_CONTACT'"></fivef-loading-indicator>
          </div>
        </ng-template>

        <ng-template #dialogContent>
          <div>
            {{ 'CONTACTS.DELETE_CONTACT_TEXT' | translate }}
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>

  <div fivef-dialog-footer>
    <!-- Show cancel/deletion buttons only on forced deletion, e.g. member area or if not locked (aka no attached processes) -->
    <!-- Show close button otherwise -->
    <ng-container *ngIf="forceContactDeletionByAdmin || !lockContactForDeletion">
      <button mat-button mat-dialog-close>
        {{ 'GENERAL.CANCEL_ACTION' | translate }}
      </button>

      <button mat-raised-button color="warn" (click)="deleteContact()"
              [disabled]="submitOnGoing">
        <mat-icon matPrefix>delete</mat-icon>
        {{ 'ADDRESSBOOK.DELETE_CONTACT_ACTION' | translate }}
      </button>
    </ng-container>

    <!-- Close button on existing processes and disabled forced mode -->
    <ng-container *ngIf="lockContactForDeletion && !forceContactDeletionByAdmin">
      <button mat-raised-button
              color="primary"
              mat-dialog-close>
        {{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </ng-container>
  </div>
</fivef-dialog>
