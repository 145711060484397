import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {ProcessParticipantAclBuilder} from './process-participant-acl.builder';
import {ProcessParticipantAcl} from './process-participant-acl';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

/**
 * Actions for the process permission view/table of the project room.
 * @deprecated This view will be replaced, so this service and API is deprecated.
 */
@Injectable()
export class ProcessParticipantAclService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';

  constructor(private _http: FivefApiResourceService) {
  }

  /**
   * Get all users with permissions.
   * @deprecated Do not use this API anymore.
   */
  getAll(id: string): Observable<ProcessParticipantAcl[]> {
    const builder = new ProcessParticipantAclBuilder(id);
    return <Observable<ProcessParticipantAcl[]>>this._http.get<ProcessParticipantAclBuilder, ProcessParticipantAcl>(builder, `${this.BASE_PATH}/${id}/process_participants`);
  }
}
