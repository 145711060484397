import {Process} from '../process/process';
import {DmsCleanupStatusBuilder, IPrivilegedProcess} from './process-management.builder';
import {DmsFolder} from '../dms-folder/dms-folder';
import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {IDatevDmsDomain} from './process-management.interface';

export class PrivilegedProcess extends Process implements IPrivilegedProcess {
  folder?: DmsFolder;
}

export enum SettingsAccessParameter {
  permit_delete_by_project_room_admin = 'permit_delete_by_project_room_admin',
  permit_delete_by_members = 'permit_delete_by_members',
  permit_comments_all = 'permit_comments_all',
  permit_comments_members = 'permit_comments_members',
  permit_comments_moderators = 'permit_comments_moderators',
  permit_comments_none = 'permit_comments_none',
  permit_upload_all = 'permit_upload_all',
  permit_upload_members = 'permit_upload_members',
  permit_upload_moderators = 'permit_upload_moderators',
  permit_upload_none = 'permit_upload_none',
  permit_export_marker_access_by_all = 'permit_export_marker_access_by_all',
  permit_export_marker_access_by_members = 'permit_export_marker_access_by_members',
  permit_export_marker_access_by_off = 'permit_export_marker_access_by_off',
  permit_trash_off = 'permit_trash_off',
  permit_trash_by_project_room_admin = 'permit_trash_by_project_room_admin',
  permit_trash_by_members = 'permit_trash_by_members'
}

/**
 * Default view settings for workflows.
 *
 * Use case: The new editor view of Collecto can be the better view for working with it.
 * The default view can be overridden by user view settings.
 */
export enum DefaultProcessView {
  /**
   * Regular processes will start with the overview e.g. the Collecto or 3rd party dashboard.
   */
  Overview = 'process_view_overview',

  /**
   * Editor view, e.g. for Collecto.
   */
  TextEditor = 'process_view_text_editor',

  /**
   * WIP: Placeholder for the insight view for testing.
   * Could change to 'Dashboard'.
   */
  Insights = 'process_view_insights'
}

export type IBomNodeColumns = 'important'
  | 'low_hanging_fruit'
  | 'title'
  | 'upload'
  | 'due_date'
  | 'responsible'
  | 'applicable'
  | 'status'
  | 'priority'
  | 'effort'
  | 'progress'
  | 'sum'
  | 'annotation'
  | 'choice';

export enum IBomNodeColumnVisibility {
  disabled = 'disabled',
  members = 'members',
  all = 'all',
}

export class BomNodeDataColumn implements IResource {
  readonly type = 'bom_tree_nodes_columns';
  constructor(public id: string, public visibility: IBomNodeColumnVisibility) {
  }
}

export interface IColumnSetup {
  column: IBomNodeColumns;
  visibility: IBomNodeColumnVisibility;
}

export class LocalProcessSettings {
  readonly type = 'local_process_settings';

  constructor(public id: string,
              public defaultView: DefaultProcessView,
              public permitCommenting: SettingsAccessParameter,
              public permitUploading: SettingsAccessParameter,
              public columnSetup: IColumnSetup[]) {
  }
}

/**
 * Only domains with IDs out of the following range are valid for DMS transfer.
 *
 * Reference:
 * - https://developer.datev.de/datev/platform/de/product/33980/api/33977#/documentmanagement_131/operation/%2Fdocuments/post
 */
export const VALID_DATEV_DMS_DOMAINS = [1, 33, 40, 51, 61, 62, 63, 64, 65, 66, 67, 68];

/**
 * DATEV DMS Profile. Defines the DATEVConnect configuration.
 * DATEV DMS profiles are created by the 5F Desktop Client.
 *
 * DATEV DMS process settings must contain a valid profile setup.
 */
export class DatevDmsProfile {
  readonly type = 'datev_dms_profiles';
  public domains: IDatevDmsDomain[] = [];

  constructor(public id: string,
              public title: string,
              public description: string,
              public worker: string,
              _domains: IDatevDmsDomain[]) {
    if (_domains && _domains.length) {
      this.domains = _domains.filter(d => VALID_DATEV_DMS_DOMAINS.includes(+d.id));
    }
  }
}

/**
 * DATEV DMS settings.
 * References the profile, the domain setup as target document dropzone inside the
 * DATEV DMS.
 * If auto sync is enabled then the client will automatically fetch all files.
 * NOTE: The DATEV domain setup is a taxonomy of 3 levels. Not each domain has all
 * levels. The levels are called domain (Bereich z.B. Mandanten), Folder (Ordner)
 * and register. The first level is a requirement for a successful document transfer.
 *
 * See the DMS process settings for a visual reference.
 */
export class DatevDmsProcessSettings {
  readonly type = 'local_datev_process_settings';

  /**
   * Settings representation of a process DATEV DMS configuration.
   * @param id Configuration ID. Mostly not used.
   * @param profileId Profile reference.
   * @param autoSync boolean. Must be set to true if files should automatically transferred into the DMS.
   * @param domainId First level of the folder structure. Required.
   * @param folderId Second level of the DATEV taxonomy. Optional.
   * @param registerId Third level of the DATEV taxonomy. Optional.
   */
  constructor(public id: string,
              public profileId: string,
              public autoSync: boolean,
              public domainId: string,
              public folderId: string,
              public registerId: string) {
  }
}

export class GlobalProcessSettings {
  readonly type = 'global_process_settings';

  constructor(public id: string,
              public permitProcessReopening: SettingsAccessParameter,
              public permitProcessDeletion: SettingsAccessParameter,
              public permitExportMarkerAccess: SettingsAccessParameter,
              public permitTrash: SettingsAccessParameter,
              public trashRetentionPeriod: number) {
  }
}

export class DmsCleanupStatus {
  readonly type = 'dms_cleanup_status';

  constructor(public id: string,
              public status: string,
              public createdAt: Date,
              public updatedAt: Date) {
  }
}

export class OrganizationProjects implements IResource {
  readonly type = 'organization_projects';

  constructor(public clientId: string,
              public clientName: string,
              public clientUuid: string,
              public createdAt: string,
              public parentId: string,
              public parentTitle: string,
              public processType: string,
              public state: string,
              public title: string,
              public color: string,
              public identifier: string,
              public id: string,
              ) {}
}
