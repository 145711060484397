import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IFivefWaitingDialogData} from './fivef-waiting-dialog.interface';
import {CommonModule} from '@angular/common';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefLoadingIndicatorComponent} from '../fivef-loading-indicator/fivef-loading-indicator.component';

@Component({
  selector: 'fivef-waiting-dialog',
  standalone: true,
  imports: [CommonModule, FivefDialogComponent, FivefLoadingIndicatorComponent],
  templateUrl: './fivef-waiting-dialog.component.html',
  styleUrls: ['./fivef-waiting-dialog.component.scss']
})
export class FivefWaitingDialogComponent {
  @Input()
  public color = 'var(--fivef-color-action)';

  @Input()
  title;

  @Input()
  headline;

  @Input()
  disableClose = false;

  constructor(public dialogRef: MatDialogRef<FivefWaitingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IFivefWaitingDialogData) {
    [
      this.color,
      this.title,
      this.headline
    ] = [
      data.color || this.color,
      data.title,
      data.headline
    ];
  }
}
