import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from 'app/app.state';
import {OrganizationSelectors} from 'app/+store';
import {environment} from 'environments/environment';
import * as store from 'store';
import {I18n} from 'app/lib/i18n/constants';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {EnvService} from '../lib/fivef-net/fivef-api-resource/services/env.service';

@Injectable()
export class XOrganizationInterceptor implements HttpInterceptor {
  oid: string = '';
  private msgApiBase: string = '';
  private signApiBase: string = '';
  private milestoneApiBase: string = '';
  private fileApiBase: string = '';
  private customerApiBase: string = '';

  constructor(private _store: Store<AppState>, private _envSvc: EnvService) {
    this._store.select(OrganizationSelectors.getSelectedId).pipe(filter(o => !!o), distinctUntilChanged()).subscribe(id => {
      if (id) {
        this.oid = id;
      } else {
        this.oid = null;
      }
    });
    this.msgApiBase = this._envSvc.msgApiBase();
    this.signApiBase = this._envSvc.signApiBase();
    this.milestoneApiBase = this._envSvc.milestoneApiBase();
    this.fileApiBase = this._envSvc.resumableUploadApiBase();
    this.customerApiBase = this._envSvc.customerApiBase();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiCall = req.url.startsWith(environment.token_service_config.apiBase)
      || req.url.startsWith(environment.pvmsApiUrl)
      || req.url.startsWith(environment.upmsApiUrl)
      || req.url.startsWith(environment.wtsApiUrl)
      || req.url.startsWith(environment.kanbanApiUrl)
      || req.url.startsWith(this.msgApiBase)
      || req.url.startsWith(this.signApiBase)
      || req.url.startsWith(this.milestoneApiBase)
      || req.url.startsWith(this.customerApiBase)
      || req.url.startsWith(this.fileApiBase);

    let lang = I18n.DEFAULT_LANG;
    try {
      const _lang = store.get(I18n.LANG_KEY);
      if (_lang) {
        lang = _lang;
      }
    } catch (err) {
      console.error('Cannot fetch current language from store');
    }
    const i18nReq = req.clone({ setHeaders: { 'Accept-Language': lang } });

    if (this.oid && this.oid.length > 0 && isApiCall) {
      const authReq = i18nReq.clone({ setHeaders: { 'X-ORGANIZATION': this.oid, 'Content-Type': 'application/json' } });
      return next.handle(authReq);
    }
    const jsonReq = i18nReq.clone({ setHeaders: { 'Content-Type': 'application/json' } });
    return next.handle(jsonReq);
  }
}
