/**
 * Simple route interface with title and route (path).
 */
export interface ISimpleRoute {
  title: string;
  route: string;
}

/**
 * Interface for routes used at sidebar and tab panes e.g. inside
 * <fivef-nav-link>.
 */
export interface IFivefNavLink {
  title: string,
  icon: string;
  path: string;
  count?: number;
  infoLevel?;
  callback?;
  orientation?: 'vertical' | 'horizontal';
}

/**
 * Dto model for routes used at sidebar and tab panes e.g. inside
 * <fivef-nav-link>.
 */
export class FivefNavLink implements IFivefNavLink {
  public orientation: 'vertical' | 'horizontal' = 'horizontal';

  constructor(public title: string,
              public icon: string,
              public path: string,
              public count: number = null,
              public infoLevel = '',
              public callback = null) {
  }
}
