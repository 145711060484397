<!-- Row template for contact entries -->
<ng-template let-item="item" #contactItemTpl>
  <fivef-avatar [size]="avatarSize"
                [email]="item?.data?.email"
                [showTitle]="true"
                [showEmail]="true"></fivef-avatar>
</ng-template>

<!-- Row template for client entries -->
<ng-template let-item="item" #clientItemTpl>
  <div class="fivef-item-selector--menu--list-item--client">
    <fivef-icon [icon]="'client'"></fivef-icon>
    <div class="fivef-item-selector--menu--list-item--client--title">
      <div class="fivef-item-selector--menu--list-item--client--name">{{ item?.data?.name }}</div>
      <div class="fivef-item-selector--menu--list-item--client--number">{{ item?.data?.clientId }}</div>
    </div>
  </div>
</ng-template>

<ng-container>
  <!-- Opener button with selected items -->
  <div class="fivef-item-selector--button-wrapper"
       [style.justify-content]="alignment"
       [style.flex-direction]="direction">
    <button *ngIf="!disabled"
            mat-icon-button
            [class.fivef-item-selector--button-inverted]="buttonStyle === 'invertedColors'"
            [disableRipple]="'disabled'"
            cdk-overlay-origin
            (click)="openSelector()"
            [matTooltip]="tooltip | translate"
            #dropdown="cdkOverlayOrigin">
      <fivef-icon [icon]="icon"></fivef-icon>
    </button>

    <ng-container *ngIf="selectedItems?.length">
      <ng-container [ngSwitch]="appearance">
        <!-- Empty placeholder case to ensure default -->
        <ng-container *ngSwitchCase="'buttonOnly'"></ng-container>

        <fivef-avatar-group *ngSwitchCase="'avatar'"
                            [class.ff-pointer]="!disabled"
                            [maxToShow]="maxToShow"
                            [profiles]="selectedItems"></fivef-avatar-group>

        <a *ngSwitchDefault
           href="#" class="fivef-item-selector--button-title"
           (click)="$event.preventDefault(); openSelector()">
        <span
          *ngIf="selectedItems['length'] <= maxToShow">{{ (selectedItems[0]?.name | slice:0:28) + (selectedItems[0]?.name?.length > 28 ? '...' : '') }}</span>
          <span
            *ngIf="selectedItems['length'] > maxToShow">
          {{ (selectedItems[0]?.name | slice:0:20) + (selectedItems[0]?.name?.length > 20 ? '...' : '') }}
            + {{ selectedItems['length'] - 1 }} {{ 'GENERAL.OTHERS' | translate }}
        </span>
        </a>
      </ng-container>
    </ng-container>
  </div>

  <ng-template
    cdk-connected-overlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="dropdown"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="showDropdown"
    (backdropClick)="showDropdown = false">
    <div class="fivef-item-selector--menu">
      <!-- Search -->
      <fivef-search class="fivef-item-selector--menu--search"
                    (onChange)="search($event)"></fivef-search>

      <!-- Item listing with leading selected items -->
      <!-- Container height - search height -->
      <cdk-virtual-scroll-viewport [itemSize]="40"
                                   autosize
                                   style="height: calc(100% - 32px)"
                                   [appendOnly]="false"
                                   [maxBufferPx]="50"
                                   [minBufferPx]="0">
        <ul class="fivef-item-selector--menu--listing">
          <li tabindex="-1"
              class="fivef-item-selector--menu--list-item"
              *cdkVirtualFor="let item of (filteredItems$ | async)">
            <ng-container [ngSwitch]="item.type">
              <div *ngSwitchCase="'message'"
                   class="fivef-item-selector--menu--list-item--message">
                {{ item?.id | translate }}
              </div>
              <div *ngSwitchCase="'action'"
                   class="fivef-item-selector--menu--list-item--action">
                <button mat-button
                        color="primary"
                        (click)="onAction.emit(item)">
                  <mat-icon matPrefix *ngIf="item?.icon">{{ item?.icon }}</mat-icon>
                  {{ item?.id | translate }}
                </button>
              </div>
              <div *ngSwitchCase="'selected'"
                   class="fivef-item-selector--menu--list-item--removable">
                <ng-container [ngSwitch]="listItemTemplate">
                  <ng-container *ngSwitchCase="'client'" [ngTemplateOutlet]="clientItemTpl"
                                [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchDefault [ngTemplateOutlet]="contactItemTpl"
                                [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <!-- Show check icon on selected element if disableRemoveAction is set -->
                <ng-template [ngIf]="disableRemoveAction" [ngIfElse]="removeButtonTpl">
                  <button mat-icon-button
                          [disableRipple]="'disabled'">
                    <mat-icon>check_circle</mat-icon>
                  </button>
                </ng-template>

                <ng-template #removeButtonTpl>
                  <button mat-icon-button
                          [disableRipple]="'disabled'"
                          (click)="deselect(item, $event)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </ng-template>
              </div>
              <div *ngSwitchCase="'candidate'"
                   class="fivef-item-selector--menu--list-item--selectable">
                <ng-container [ngSwitch]="listItemTemplate">
                  <ng-container *ngSwitchCase="'client'" [ngTemplateOutlet]="clientItemTpl"
                                [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchDefault [ngTemplateOutlet]="contactItemTpl"
                                [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>
                <button mat-icon-button
                        [disableRipple]="'disabled'"
                        (click)="selectItem(item.data, $event)">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </ng-container>
          </li>
        </ul>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-template>
</ng-container>
