<ul>
  <li class="fivef-session-registration-complete--checklist-item">
    <mat-icon
      class="fivef-session-registration-complete--checklist-icon fivef-session-registration-complete--checklist-icon-success">
      check_circle
    </mat-icon>
    <div class="fivef-session-registration-complete--center">
      <div class="fivef-session-registration-complete--text">
        {{ 'REGISTRATION.SET_PASSWORD' | translate }}
      </div>
    </div>
  </li>

  <li class="fivef-session-registration-complete--checklist-item">
    <ng-container *ngIf="dmsAccountPresent; else creatingDmsAccount">
      <mat-icon
        class="fivef-session-registration-complete--checklist-icon fivef-session-registration-complete--checklist-icon-success">
        check_circle
      </mat-icon>
      <div class="fivef-session-registration-complete--center">
        <div class="dvtx-loading-indicator  fivef-session-registration-complete--text">
          {{ 'REGISTRATION.STORAGE_CREATED' | translate }}
        </div>
      </div>
    </ng-container>

    <ng-template #creatingDmsAccount>
      <mat-icon
        class="fivef-session-registration-complete--checklist-icon fivef-session-registration-complete--in-progress">
        check_circle
      </mat-icon>
      <div class="fivef-session-registration-complete--center">
        <div class="fivef-loading-indicator fivef-session-registration-complete--text">
          {{ 'REGISTRATION.CREATING_STORAGE' | translate }}<span>.</span><span>.</span><span>.</span>
        </div>
      </div>
    </ng-template>
  </li>

  <li class="fivef-session-registration-complete--checklist-item">
    <ng-container *ngIf="accountCompleted; else completingAccount">
      <mat-icon
        class="fivef-session-registration-complete--checklist-icon fivef-session-registration-complete--checklist-icon-success">
        check_circle
      </mat-icon>
      <div class="fivef-session-registration-complete--center">
        <div class=" fivef-session-registration-complete--text">
          {{ 'REGISTRATION.ACCOUNT_CREATED' | translate }}
        </div>
      </div>
    </ng-container>
    <ng-template #completingAccount>
      <mat-icon
        class="fivef-session-registration-complete--checklist-icon fivef-session-registration-complete--in-progress">
        check_circle
      </mat-icon>
      <div class="fivef-session-registration-complete--center">
        <div class="fivef-loading-indicator fivef-session-registration-complete--text">
          {{ 'REGISTRATION.CREATING_ACCOUNT' | translate }}<span>.</span><span>.</span><span>.</span>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div class="d-flex justify-content-end mt-3">
  <div>
    <button mat-raised-button color="primary" (click)="toDashboard()"
            class="fivef-session-registration-complete--button" type="button"
            [disabled]="!accountCompleted || !dmsAccountPresent">{{ 'REGISTRATION.LETS_GO' | translate }}
    </button>
  </div>
</div>
