<div class="w-100 dvtx-client-overview">
  <form *ngIf="enableToolbar" class="fivef-toolbar fivef-toolbar--end">
    <fivef-search (onChange)="applySearch($event)"></fivef-search>

    <fivef-create-client [appearance]="ClientCreateActionAppearance.CreateRaisedButton"
                         [readonly]="false"
                         (onCreateSuccess)="createSuccess($event)"></fivef-create-client>
  </form>

  <fivef-client-listing [clients]="data$ | async"
                        [featureSet]="featureSet$ | async"
                        [organization]="organization"
                        (onDelete)="removeClient($event)"
                        (onEditSuccess)="editSuccess($event)"></fivef-client-listing>

  <ng-container *ngIf="loading$ | async; else more">
    <div class="w-100">
      <div class="dvtx-fg-color dvtx-fg-fivef-blue-300 dvtx-loading-indicator">
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </ng-container>

  <ng-template #more>
    <div class="w-100 d-flex justify-content-end">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone"
             inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0" class="w-100 dvtx-no-items">
  <fivef-icon-message-block [icon]="'clients'"
                            [message]="'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH' | translate"></fivef-icon-message-block>
</div>
