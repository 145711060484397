import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {FivefNotificationService} from '../lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';

/**
 * Error 413 payload too large intercepter.
 */
@Injectable()
export class PayloadTooLargeInterceptor implements HttpInterceptor {
  constructor(private notifySvc: FivefNotificationService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && (error as HttpErrorResponse).status === 413) {
            this.notifySvc.error('HTTP_ERROR.PAYLOAD_TOO_LARGE', null, 15000);
          }
          return throwError(error);
        })
      );
  }
}
