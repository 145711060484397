<fivef-dialog [disablePadding]="true" *ngIf="isOpen">
  <div class="fivef-preview-browser--toolbar py-1 px-3">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <ng-container *ngIf="viewMode === 'document'">
        <div class="d-flex">
          <button mat-icon-button aria-label="Back" (click)="openPreviousDocument()"
                  [disabled]="!_documentIds?.length || _documentIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.PREVIOUS_PREVIEW' | translate">
            <mat-icon>arrow_back</mat-icon>
          </button>

          <button mat-icon-button aria-label="Next" (click)="openNextDocument()"
                  [disabled]="!_documentIds?.length || _documentIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.NEXT_PREVIEW' | translate">
            <mat-icon>arrow_forward</mat-icon>
          </button>

          <button mat-icon-button aria-label="Browse" (click)="openImagelist()"
                  [disabled]="!_documentIds?.length || _documentIds?.length === 1"
                  [matTooltip]="'PREVIEW_BROWSER.PREVIEWS_OVERVIEW' | translate">
            <mat-icon>view_comfy</mat-icon>
          </button>

          <div class="d-flex align-items-center">
            <button mat-icon-button class="ml-4" (click)="switchToImagePreview()"
                    [color]="documentViewMode === DocumentViewMode.Image ? 'primary' : 'gray'"
                    [matTooltip]="'PREVIEW_BROWSER.IMAGE_PREVIEW' | translate">
              <mat-icon>photo</mat-icon>
            </button>

            <mat-slide-toggle color="primary" (change)="switchDocumentViewMode($event)"
                              [checked]="documentViewMode === DocumentViewMode.PDF"></mat-slide-toggle>

            <button mat-icon-button (click)="this.switchToPdfPreview()"
                    [color]="documentViewMode === DocumentViewMode.PDF ? 'primary' : 'gray'"
                    [matTooltip]="'PREVIEW_BROWSER.PDF_PREVIEW' | translate">
              <mat-icon>picture_as_pdf</mat-icon>
            </button>

            <div class="ml-4">
              <button mat-icon-button (click)="download()"
                      [matTooltip]="'GENERAL.DOWNLOAD_ACTION' | translate">
                <mat-icon>file_download</mat-icon>
              </button>
            </div>

            <div>
              <button mat-icon-button (click)="loadPDF()"
                      [matTooltip]="'PREVIEW_BROWSER.LOAD_PDF' | translate">
                <mat-icon>picture_as_pdf</mat-icon>
              </button>
            </div>

            <ng-container *ngIf="currentProcessArtifact as artifact">
              <ng-template
                [ngIf]="(featureSet$ | async)?.canCreateSignature && signatureSupported(artifact) && isMember">
                <fivef-signature-selection [action]="DocumentSignatureSelectionMenuViewType.Button"
                                           [processId]="_processId" [document]="artifact">
                </fivef-signature-selection>
              </ng-template>
            </ng-container>
            <div>
              <button *ngIf="!fullScreen" mat-icon-button (click)="fullScreenDialogue(true)"
                      [matTooltip]="'Full Screen'">
                <mat-icon>fullscreen</mat-icon>
              </button>
              <button *ngIf="fullScreen" mat-icon-button (click)="fullScreenDialogue(false)"
                      [matTooltip]="'Normal Screen'">
                <mat-icon>fullscreen_exit</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="h3 px-3 d-flex align-items-center" [matTooltip]="currentProcessArtifact?.title">
          <h3 class="fivef-preview-browser--toolbar--filename text-truncate w-100 p-0 m-0">
            {{ currentProcessArtifact?.title }}
          </h3>
        </div>
      </ng-container>

      <ng-container *ngIf="viewMode === 'imagelist'">
        <div class="d-flex">
          <button mat-icon-button aria-label="Back" [matTooltip]="'GENERAL.BACK_ACTION' | translate"
                  (click)="closeImagelist()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="h3 px-3 d-flex align-items-center text-truncate">
          <h3 class="text-truncate w-100 p-0 m-0">
            {{ 'PREVIEW_BROWSER.PREVIEWS_OVERVIEW' | translate }}
          </h3>
        </div>
      </ng-container>

      <button mat-icon-button aria-label="Close" (click)="onClose.emit()"
              [matTooltip]="'GENERAL.CLOSE_ACTION' | translate">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="d-flex fivef-preview-browser--main">
    <div class="fivef-preview-browser--previews-wrapper">

      <div mat-dialog-content>
        <dvtx-preview-browser-imagelist *ngIf="viewMode === 'imagelist'" [documents]="listDocuments$ | async"
                                        (onDocumentClicked)="onImagelistItemClicked($event)"></dvtx-preview-browser-imagelist>
      </div>

      <ng-container *ngIf="viewMode === 'document' && viewDocument$ | async as document">
        <ng-container *ngIf="document.id && !isNotFoundDocument(document)">

          <div class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto"
               mat-dialog-content
               *ngIf="document.pages === 0">
            <mat-card appearance="outlined" style="padding-top: 0; padding-left: 0; padding-right: 0;">
              <!--                  <mat-card-title style="background-color: #233246; color: #FFFFFF; padding: 1rem;">-->
              <div class="p-5 w-100 d-flex justify-content-center">
                <fivef-icon-message-block [icon]="'hide_image'"
                                          [message]="'PREVIEW_BROWSER.PREVIEW_NOT_AVAILABLE_FOR_TYPE'"></fivef-icon-message-block>
              </div>
            </mat-card>
          </div>

          <div class="five-f-document-pdf-preview">
            <div mat-dialog-content *ngIf="documentViewMode === DocumentViewMode.PDF">
              <ng-template [ngIf]="(pdf$ | async)?.content" [ngIfElse]="pdfNotAvailable">
                <div class="d-flex w-100 justify-content-center">
                  <fivef-pdf-viewer [content]="(pdf$ | async)?.content" [showEditingMode]="false"
                                    class="w-100"
                                    [ngStyle]="fullScreen ? {'min-height': '86vh'} : {'min-height': '65vh'}"></fivef-pdf-viewer>
                </div>
              </ng-template>

              <ng-template #pdfNotAvailable>
                <div class="p-5 w-100 d-flex justify-content-center" *ngIf="!pdfLoading && !pdfIsAvailable">
                  <fivef-icon-message-block [icon]="'hide_image'"
                                            [message]="'SIGNATURE.WIZARD.PDF_NOT_AVAILABLE_FOR_DOCUMENT'"></fivef-icon-message-block>
                </div>

                <div class="p-5 w-100 d-flex justify-content-center" *ngIf="pdfLoading">
                  <p class="h3">
                    <span class="dvtx-loading-indicator">
                      {{ 'SIGNATURE.WIZARD.LOAD_PDF' | translate }}<span>.</span><span>.</span><span>.</span>
                    </span>
                  </p>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="fivef-preview-browser--document-image-preview">
            <div mat-dialog-content *ngIf="documentViewMode === DocumentViewMode.Image">
              <div *ngFor="let prevObj of document.previews"
                   class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto"
                   style="width: 90%; height: 0px; position: relative;"
                   [style.paddingTop.%]="calculateImageHeightPercent(prevObj)">
                <div class="d-block m-1 w-100 h-100 p-0 m-0" style="position: absolute; top: 0; left: 0;">
                  <mat-card appearance="outlined" class="w-100 h-100 p-0 m-0 mat-elevation-z3">
                    <img class="p-0 m-0 w-100 dvtx-document-preview-image" inViewport
                         [inViewportOptions]="{ threshold: 0.0001 }" [dvtxLazyPreview]="prevObj"
                         #preview="dvtxLazyPreview" src=""/>
                    <dvtx-lazy-preview-loading-skeleton *ngIf="preview.loading"></dvtx-lazy-preview-loading-skeleton>
                    <ng-container *ngIf="preview.loaded && !preview.isComplete">
                      <div class="p-5 w-100 d-flex justify-content-center">
                        <fivef-icon-message-block [icon]="'hide_image'"
                                                  [message]="'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE'"></fivef-icon-message-block>
                      </div>
                    </ng-container>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="fivef-preview-browser--preview-container pl-0 pr-0 pb-0 mb-1 mx-auto" mat-dialog-content
             *ngIf="isNotFoundDocument(document)">
          <div class="p-5 w-100 d-flex justify-content-center">
            <fivef-icon-message-block [icon]="'hide_image'"
                                      [message]="'PREVIEW_BROWSER.PREVIEW_NOT_AVAILBLE'"></fivef-icon-message-block>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="viewMode === 'document'"
         class="pb-1 fivef-preview-browser--metadata-container">
      <ng-container>
        <mat-button-toggle-group class="five-f-mat-button-toggle-group m-0"
                                 style="border: none;padding: 0px;"
                                 [(ngModel)]="selectedDocumentPreviewTab">
          <mat-button-toggle style="border-left: none !important;" [value]="0" [disableRipple]="true">
            {{ 'PREVIEW_BROWSER.DETAILS' | translate }}
          </mat-button-toggle>
          <mat-button-toggle style="border-left: none !important;" [value]="1" [disableRipple]="true">
            {{ 'PROJECT_ROOM.COMMENTS' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <ng-container [ngSwitch]="selectedDocumentPreviewTab">
          <ng-template [ngSwitchCase]="0">
            <dvtx-artifact-details [artifact]="currentProcessArtifact"></dvtx-artifact-details>
          </ng-template>

          <ng-template [ngSwitchCase]="1">
            <div>
              <fivef-comments [resourceType]="'artifact'"
                              [canCreateComment]="(policy$ | async)?.canCommentProcess"
                              [processId]="_processId"
                              [currentProcessArtifact]="currentProcessArtifact"
                              [resourceId]="currentProcessArtifact?.id"></fivef-comments>
              <!--              &lt;!&ndash; <dvtx-info-icon-message-block *ngIf="request?.state === 'closed'"-->
              <!--                                            [horizontal]="true"-->
              <!--                                            [icon]="'lock'"-->
              <!--                                            [message]="'PROJECT_ROOM.WORKFLOW_CLOSED'"></dvtx-info-icon-message-block>-->
              <!--   &ndash;&gt;-->
              <!--              <div>-->
              <!--                <fivef-message-editor (onSave)="createComment($event)"-->
              <!--                                      [message]="comment"-->
              <!--                                      [height]="100"-->
              <!--                                      [heightMax]="400"-->
              <!--                                      class="d-inline-block w-100"-->
              <!--                                      [optimizeToolbar]="true">-->
              <!--                </fivef-message-editor>-->
              <!--              </div>-->

              <!--              <div class="five-f-comments-tab__download-comments d-flex justify-content-end" *ngIf="isMember">-->
              <!--                <button mat-button (click)="openCommentDownloadDialog()" >-->
              <!--                  {{ 'PROJECT_ROOM.DOWNLOAD_COMMENTS' | translate }}-->
              <!--                  <mat-icon matSuffix>file_download</mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
              <!--              <dvtx-export-comments [currentProcessArtifact]="currentProcessArtifact" [id]="currentProcessArtifact?.id"-->
              <!--                                    #exportCommentsComponentRef></dvtx-export-comments>-->
              <!--              <ng-template [ngIf]="commentsLoading$ | async" [ngIfElse]="noComments">-->
              <!--                <div style="padding: 14px; height: 100%;" class="dvtx-loading-indicator">-->
              <!--                  <div class="d-flex align-items-center justify-content-center">-->
              <!--                    <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>-->
              <!--                    {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </ng-template>-->

              <!--              <ng-template #noComments>-->
              <!--                <dvtx-info-icon-message-block *ngIf="(comments$ | async)?.length === 0"-->
              <!--                                              [icon]="'chat_bubble_outline'"-->
              <!--                                              [message]="(currentProcessArtifact?.state === 'closed') ? 'PROJECT_ROOM.NO_COMMENTS' : 'PROJECT_ROOM.NO_COMMENTS_2'"></dvtx-info-icon-message-block>-->
              <!--              </ng-template>-->

              <!--              <div>-->
              <!--                <ul class="fivef-preview-browser--comments-tab-panel pr-3">-->
              <!--                  <li class="five-f-comments-tab__panel__item" *ngFor="let message of comments$ | async">-->
              <!--                    <dvtx-sidebar-comment-panel [message]="message"-->
              <!--                                                [commentType]="'artifact_comment'"-->
              <!--                                                [process]="currentProcessArtifact"-->
              <!--                                                [resourceId]="currentProcessArtifact?.id"-->
              <!--                                                [backtrackId]="currentProcessArtifact?.id"-->
              <!--                                                [isClosed]="currentProcessArtifact?.state === 'closed'"-->
              <!--                                                [enableReply]="false"-->
              <!--                                                (onMarkedRead)="refreshArtifact($event)"-->
              <!--                                                (onNewComment)="refreshArtifact($event)"-->
              <!--                                                [isArtifact]="true"></dvtx-sidebar-comment-panel>-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              </div>-->

              <!--              &lt;!&ndash;  <ng-template [ngIf]="loading">&ndash;&gt;-->
              <!--              &lt;!&ndash;    <div style="padding: 14px; height: 100%;" class="dvtx-loading-indicator">&ndash;&gt;-->
              <!--              &lt;!&ndash;      <div class="d-flex align-items-center justify-content-center">&ndash;&gt;-->
              <!--              &lt;!&ndash;        <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>&ndash;&gt;-->
              <!--              &lt;!&ndash;        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>&ndash;&gt;-->
              <!--              &lt;!&ndash;      </div>&ndash;&gt;-->
              <!--              &lt;!&ndash;    </div>&ndash;&gt;-->
              <!--              &lt;!&ndash;  </ng-template>&ndash;&gt;-->
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>
</fivef-dialog>
