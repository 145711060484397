import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum TaskActivityType {
  Approved = 'approved',
  Declined = 'declined',
  Created = 'created',
  Updated = 'attributes_updated',
  Deleted = 'deleted',
  Completed = 'completed',
  Archived = 'archived',
  Canceled = 'canceled',
  DueDateChanged = 'due_date_changed',
  PriorityChanged = 'priority_changed',
  StatusChanged = 'status_changed',
  CreateAssignee = 'create_assignee',
  DestroyAssignee = 'destroy_assignee',
  AcceptAssignment = 'accept_assignment',
  RejectAssignment = 'reject_assignment',
  Commented = 'commented',
  DocumentAdded = 'document_added',
  DocumentRemoved = 'document_removed',
  LabelAdded = 'label_added',
  LabelRemoved = 'label_removed',
}

export interface ITaskEvent extends IResource {
  taskId: string;
  activity: TaskActivityType;
  performer: string;
  performer_email: string;
  changeset: any;
  resource: any;
  createdAt: Date;
}
