import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './member';

export namespace Tenant {
  export class MemberBuilder implements IApiResourceBuilder<model.Tenant.Member> {
    constructor(public organizationId: string) {
    }

    fromResponse(data): model.Tenant.Member {
      const member = new model.Tenant.Member(
        data.id,
        this.organizationId,
        data.attributes.email,
        data.attributes.first_name,
        data.attributes.last_name,
        data.attributes.created_at,
        data.attributes.updated_at);

      member.fastdocsEnabledAt = data.attributes.fastdocs_enabled_at;
      member.digitalbarEnabledAt = data.attributes.digitalbar_enabled_at;
      member.fivefAgentEnabledAt = data.attributes.fivef_agent_enabled_at;
      member.datevEnabledAt = data.attributes.datev_enabled_at;
      member.bigFilesEnabledAt = data.attributes.big_files_enabled_at;
      member.membershipId = data.attributes.membership_id;
      member.owner = data.attributes.owner;
      member.admin = data.attributes.admin;
      member.projectRoomAdmin = data.attributes.project_room_admin;

      return member;
    }

    toRequest(member: model.Tenant.Member) {
      return {
        data: {
          id: member.id,
          type: member.type,
          attributes: {
            email: member.email,
            first_name: member.firstName,
            last_name: member.lastName,
          }
        }
      };
    }
  }
  export class MemberPermissionsBuilder implements IApiResourceBuilder<model.Tenant.MemberPermissions> {
    constructor(public organizationId: string) {
    }

    fromResponse(data): model.Tenant.MemberPermissions {
      const memberPermission = new model.Tenant.MemberPermissions(
        data.id,
        data.attributes.workflow_creation_enabled,
        data.attributes.sub_workflow_creation_enabled,
        data.attributes.fastdocs_enabled,
        data.attributes.big_files_enabled,
        data.attributes.project_room_admin_role,
        data.attributes.digitalbar_enabled,
        data.attributes.fivef_agent_enabled,
        data.attributes.datev_enabled)
      return memberPermission;
    }

    toRequest(member: model.Tenant.MemberPermissions) {
      return {
        data: {
          id: member.id,
          type: member.type,
          attributes: {
          }
        }
      };
    }
  }
}
