// Removed in favor of next one.
// export type FivefReactionType = 'like' | 'laugh' | 'surprised' | 'sad' | 'angry' | 'heart';
import {CommentReactionType} from '../../../../../+store/comment/comment.interface';

export const FIVEF_ICON_TYPE_MAPPING: {[id: string]: CommentReactionType} = {
  'thumb_up': CommentReactionType.Like,
  'mood_bad': CommentReactionType.Angry,
  'sentiment_dissatisfied': CommentReactionType.Sad,
  'insert_emoticon': CommentReactionType.Laugh,
  'favorite': CommentReactionType.Heart,
  'sentiment_neutral': CommentReactionType.Surprised,
};

