/**
 * Visual component for the message text.
 *
 * Author: Andreas Baier <andreas.baier@paperbird.org>
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'fivef-snackbar-message-content',
  templateUrl: './fivef-snackbar-message-content.component.html',
  styleUrls: ['./fivef-snackbar-message-content.component.scss']
})
export class FivefSnackbarMessageContentComponent {
  @Input() message: string;

  constructor() {
  }
}
