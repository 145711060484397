import {createFeatureSelector, createSelector} from '@ngrx/store';
import {adapter, State} from './dms-folder.state';
import {DmsAccountType} from './dms-folder.interface';

export const stateKey = 'dms-folder';
const getDmsFolderState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getDmsFolderEntities,
  selectAll: getAllDmsFolders,
} = adapter.getSelectors(getDmsFolderState);

export const getSelected = createSelector(
  getDmsFolderState,
  (state) => state.selected
)

export const getOne = (id: string) => createSelector(
  getDmsFolderEntities,
  (entities) => entities[id]
);

export const getall = createSelector(
  getAllDmsFolders,
  (entities) => entities
);

export const getPrivateFolders = createSelector(
  getAllDmsFolders,
  (entities) => entities.filter(f => f.dmsAccountType === DmsAccountType.Private)
);

export const isClientFolder = (id) => createSelector(
  getDmsFolderState,
  (state) => !!state.clientFolderMap[id]
);

export const getOrganizationFolders = (oid: string) => createSelector(
  getAllDmsFolders,
  (entities) => entities.filter(f => f.dmsAccountType === DmsAccountType.Organization && f.sourceId === oid)
);

export const loadingState = createSelector(
  getDmsFolderState,
  (state) => state.loading
);
