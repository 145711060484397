/* tslint:disable:member-ordering */
import { Action } from '@ngrx/store';
import { User } from '../../user/user';

// action types
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const USER_LOGIN_PENDING = 'USER_LOGIN_PENDING';

// start login request action creator
export class LoginUserRequest implements Action {
  readonly type: string = LOGIN_USER_REQUEST;

  // userType is being misused for transmitting the authcode.

  constructor(public payload: { email: string, password: string }) {
  }
}

// login success action creator
export class LoginUserSuccess implements Action {
  readonly type: string = LOGIN_USER_SUCCESS;

  constructor(public payload: any) {}
}

// login error action creator
export class LoginUserError implements Action {
  readonly type: string = LOGIN_USER_ERROR;

  constructor(public payload: any) {}
}

// start register request action creator
export class UserRegisterRequest implements Action {
  readonly type: string = USER_REGISTER_REQUEST;

  constructor(public payload: { email: string, password: string, passwordConfirmation: string }) {}
}

// sign up success action creator
export class UserRegisterSuccess implements Action {
  readonly type: string = USER_REGISTER_SUCCESS;

  constructor(public payload: User) {}
}

// sign up error action creator
export class UserRegisterError implements Action {
  readonly type: string = USER_REGISTER_ERROR;

  constructor(public payload: any) {}
}

// reset password action creator
export class UserResetPassword implements Action {
  readonly type: string = USER_RESET_PASSWORD;

  constructor(public payload: string) {}
}

// log out request action creator
export class UserLogoutRequest implements Action {
  readonly type: string = USER_LOGOUT_REQUEST;

  constructor(public payload = null) {}
}

// log out success action creator
export class UserLogoutSuccess implements Action {
  readonly type: string = USER_LOGOUT_SUCCESS;

  constructor(public payload = null) {}
}

// log out error action creator
export class UserLogoutError implements Action {
  readonly type: string = USER_LOGOUT_ERROR;

  constructor(public payload: any) {}
}

// Used in TFA context. Doing nothing.
export class UserLoginPending implements Action {
  readonly type: string = USER_LOGIN_PENDING;

  constructor(public payload: any) {}
}

export type Actions = LoginUserRequest
  | LoginUserSuccess
  | LoginUserError
  | UserRegisterRequest
  | UserRegisterSuccess
  | UserRegisterError
  | UserResetPassword
  | UserLogoutRequest
  | UserLogoutSuccess
  | UserLogoutError
  | UserLoginPending;



