<ng-template #twoFaAuthDialogTpl>
  <fivef-dialog [title]="'AUTH.TWO_FACTOR_DIALOG_TITLE'">
    <dvtx-two-factor-verification [form]="form"
                                  [error]="error$ | async"></dvtx-two-factor-verification>

    <div fivef-dialog-footer>
      <button mat-button (click)="cancel()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="primary"
              (click)="confirm()" [disabled]="submitOngoing || form.invalid || form.pristine">
        {{ 'GENERAL.CONFIRM_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
