import {ChangeDetectorRef, OnDestroy, OnInit, Directive} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {I18n} from 'app/lib/i18n/constants';
import {ContactListDto, contactListDtoType} from 'app/+store/contact/contact';
import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {FivefConfirm} from '../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {combineLatest, catchError, first, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Subscription} from 'rxjs/internal/Subscription';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {concat} from 'rxjs/internal/observable/concat';
import {of} from 'rxjs/internal/observable/of';

export enum BatchActionViewType {
  Embedded = 'Embedded',
  Button = 'Button',
  Custom = 'Custom',
  MenuItem = 'MenuItem'
}

export enum BatchActionState {
  Initiated = 'initiated',
  InProgress = 'in_progress',
  Finished = 'finished',
  Canceled = 'canceled'
}

export enum IActionCallStatus {
  Success = 'success',
  Failure = 'failure',
  PreconditionFailed = 'precondition_failed'
}

export interface IActionCallResult<T> {
  id: string;
  item: T;
  status: IActionCallStatus;
  statusCode: number | string;
}

export interface IActionCall<T> {
  id: string;
  title: string;
  callback: Observable<IActionCallResult<T>>;
}

@Directive()
export abstract class BatchActionDialogBase implements OnDestroy, OnInit {
  protected onDestroy = new Subject<void>();
  BatchActionViewType = BatchActionViewType;
  BatchActionState = BatchActionState;
  contactListDtoType = contactListDtoType;

  // Dialog state
  state: BatchActionState = BatchActionState.Initiated;

  // Subscription to contact changes until snapshot for mass action candidates.
  protected contactSubscription: Subscription;
  contacts$: Observable<ContactListDto[]>;

  selection = [];
  processedContacts: ContactListDto[];
  submitOnGoing = false;

  // All candidates for the mass action
  contactCandidates$ = new BehaviorSubject<ContactListDto[]>([]);

  // Selection of IDs remembered for mass action.
  selection$: BehaviorSubject<IResource[]> = new BehaviorSubject([]);
  dialogRef: MatDialogRef<any>;

  // Current selected language for view switching on complex (dynamic) content.
  public lang: string = I18n.DEFAULT_LANG;

  // Cancel listener for canceling calls.
  cancel$ = new BehaviorSubject<boolean>(false);

  // Progress display
  progress = 0;
  progressSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  sum = 0;
  currentItem$ = new BehaviorSubject<string>('');

  actionCalls$: Observable<IActionCallResult<ContactListDto>>[] = [];
  failedItems = [];
  partialSucceededItems = [];
  public selectionContainsEmployee = false;

  protected _filteredTypes: contactListDtoType[] = [];

  protected constructor(protected _translateSvc: TranslateService,
                        protected _cdr: ChangeDetectorRef) {
    this._translateSvc.onLangChange.pipe(startWith({lang: this._translateSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang);
  }

  /**
   * Call implementation.
   * @param contact
   * @protected
   */
  protected abstract actionCallRequest(contact: ContactListDto): Observable<any>;

  /**
   * Handle the result.
   * @param res
   * @protected
   */
  protected abstract _handleResult(res: IActionCallResult<any>): void;

  ngOnInit() {
    this._initContacts();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this._stopContactSubscription();
  }

  /**
   * Initializes the contacts to be processed based on the given
   * contact type filter and the selection.
   * @protected
   */
  protected _initContacts() {
    this.contacts$ = this.contactCandidates$.pipe(combineLatest(this.selection$))
      .pipe(
        map(([contacts, selection]) => {
          const ids = selection.map(s => s.id);
          this.selectionContainsEmployee = !!contacts.find(c => ids.includes(c.id) && c.type === contactListDtoType.Membership);
          if (this._filteredTypes && this._filteredTypes.length) {
            const filteredType = this._filteredTypes;
            return contacts.filter(c => ids.includes(c.id) && !filteredType.includes(c.type));
          }
          return contacts.filter(c => ids.includes(c.id));
        })
      );
  }

  protected _prepareActionCalls() {
    this.partialSucceededItems = [];
    this.contactSubscription = this.contacts$.subscribe(contacts => {
      this.actionCalls$ = [];
      this.processedContacts = contacts;
      contacts.forEach(contact => {
        this.actionCalls$.push(this._createCallback(contact));
      });
    });
  }

  protected _stopContactSubscription() {
    if (this.contactSubscription) {
      try {
        this.contactSubscription.unsubscribe();
        this.contactSubscription = null;
      } catch (err) {
        console.error(err);
      }
    }
  }

  countUp() {
    if (this.progress === this.sum) {
      setTimeout(() => {
        this.state = BatchActionState.Finished;
        this._cdr.detectChanges();
      }, 1500)

    } else {
      const itemPercent = 100 / (this.sum); // how many percent each file represents.
      this.progressSubject$.next(itemPercent * this.progress);

      setTimeout(() => {
        ++this.progress;
        this.countUp();
      }, 3000);
    }
  }

  @FivefConfirm({
    message: 'ADDRESSBOOK.CONFIRM_DELETION',
    icon: 'warning',
    color: 'primary'
  })
  public startAction() {
    this._stopContactSubscription();
    this.progress = 0;
    this.sum = this.processedContacts.length;
    this.progressSubject$.next(0);
    this.state = BatchActionState.InProgress;
    this._cdr.detectChanges();
    this.startCalls();
  }

  startCalls() {
    concat(...this.actionCalls$)
      .subscribe((res: IActionCallResult<any>) => {
        this._handleResult(res);
      }, err => {
        console.error(err);
      }, () => {
        if (this.cancel$.value) {
          this.state = BatchActionState.Canceled;
        } else {
          setTimeout(() => {
            this.state = BatchActionState.Finished;
            this.cancel$.next(false);
            this._cdr.detectChanges();
          }, 1500);
        }
      })
  }

  public cancelAction() {
    this.cancel$.next(true);
  }

  protected _createCallback(contact: ContactListDto): Observable<IActionCallResult<ContactListDto>> {
    const _contact = contact;
    return this.cancel$
      .pipe(
        first(),
        switchMap(cancel => {
          if (cancel) {
            const res: IActionCallResult<ContactListDto> = {
              id: _contact.id,
              item: _contact,
              status: IActionCallStatus.Success,
              statusCode: null
            };
            return of(res);
          } else {
            // return of(_contact) // For testing, see delay below, to simulate a request.
            return this.actionCallRequest(_contact)
              .pipe(
                first(),
                tap(__contact => {
                  this.currentItem$.next(_contact.name);
                }),
                // delay(2000), // For testing, see delay below
                map((__contact: ContactListDto) => {
                  ++this.progress;
                  const filePercent = 100 / (this.sum); // how many percent each file represents.
                  this.progressSubject$.next(filePercent * this.progress);

                  return {
                    id: __contact.id,
                    item: __contact,
                    status: IActionCallStatus.Success,
                    statusCode: 200
                  };
                }),
                catchError(err => {
                  ++this.progress;
                  const filePercent = 100 / (this.sum); // how many percent each file represents.
                  this.progressSubject$.next(filePercent * this.progress);

                  let status = IActionCallStatus.Failure;
                  let statusCode = 400;
                  if (err && err.status === 412) {
                    statusCode = 412;
                    // this._store.dispatch(new ContactListDtoActions.DeleteSuccess(artifactId));
                    status = IActionCallStatus.PreconditionFailed;
                  }
                  const res: IActionCallResult<ContactListDto> = {
                    id: _contact.id,
                    item: _contact,
                    status: status,
                    statusCode: statusCode
                  };
                  return of(res);
                }));
          }
        })
      )
    // return of(_contact)
  }

  /**
   * Closes the dialog if present and removes open subscriptions.
   */
  public closeDialog() {
    try {
      if (this.dialogRef) {
        this._stopContactSubscription();
        this.dialogRef.close();
      }
    } catch (err) {
      console.error('Cannot close dialog', err);
    }
  }

  protected _reset() {
    this.cancel$.next(false);
    this.actionCalls$ = [];
    this.processedContacts = [];
    this.progress = 0;
    this.state = BatchActionState.Initiated;
  }
}
