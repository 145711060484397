<div class="button-row mb-2">
  <a mat-button routerLink="../navigation">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div>

<mat-card appearance="outlined" class="p-0 user-profile">
  <mat-card-title>
    <span class="small-title">{{ 'ORGANIZATION_NAVIGATON.CUSTOMIZATION' | translate }}</span>
    <h3>
      {{ (organization$ | async)?.name }}
      <ng-container *ngIf="(organization$ | async)?.legalFormId | fivefLegalFormNameById | async as legalFormName">
        <ng-container *ngIf="legalFormName !== 'Sonstige' ">
          {{ legalFormName.indexOf('(') > -1 ? legalFormName : '(' + legalFormName + ')' }}
        </ng-container>
      </ng-container>
    </h3>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group class="tab-group" [selectedIndex]="selectedTab">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label">
            <div>{{ 'ORGANIZATION.LOGO' | translate }}</div>
          </div>
        </ng-template>
        <div>
          <dvtx-logo-upload-container></dvtx-logo-upload-container>
        </div>
      </mat-tab>
      <!-- Document templates are loaded lazily to not create a websocket subscription  -->
      <mat-tab *dvtxFeatureToggle="false">
        <ng-template mat-tab-label>
          <div class="tab-label">
            <div>{{ 'ORGANIZATION.DOCUMENT_TEMPLATES' | translate }}</div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <dvtx-document-template-container></dvtx-document-template-container>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label ">
            <div>{{ 'FOOTER.TITLE' | translate }}</div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <dvtx-email-footer-container></dvtx-email-footer-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
