import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  OperatorTenantActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess
} from './tenant.actions';
import * as model from './tenant';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {OperatorTenantService} from './tenant.service';

@Injectable()
export class OperatorTenantEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((tenant: model.Operator.Tenant) => {
          return [new CreateSuccess(tenant)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.tenant).pipe(
        first(),
        switchMap((tenant: model.Operator.Tenant) => {
          return [new SaveSuccess(tenant)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((tenant: model.Operator.Tenant) => {
          return [new LoadOneSuccess(tenant)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: model.Operator.Tenant[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.id).pipe(
        first(),
        concatMap((item: model.Operator.Tenant) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  updateStatus$ = createEffect(() => this.actions.pipe(
    ofType(OperatorTenantActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.tenant).pipe(
        first(),
        concatMap((item: model.Operator.Tenant) => {
          return [new SaveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: OperatorTenantService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}
