import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CmsBlog} from './cms-blog';

export class CmsBlogBuilder implements IApiResourceBuilder<CmsBlog> {
  fromResponse(data): CmsBlog {
    return new CmsBlog(
      data.id,
      data.attributes.link_title,
      data.attributes.description,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);
  }

  toRequest(cmsBlog: CmsBlog) {
    return {
      data: {
        id: cmsBlog.id,
        type: cmsBlog.type,
        attributes: {
          link_title: cmsBlog.linkTitle,
          published: !!cmsBlog.publishedAt
        }
      }
    };
  }
}
