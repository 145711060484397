import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CacEffects} from './cac.effects';
import {CacService} from './cac.service';
import {reducer} from './cac.reducer';
import {stateKey} from './cac.selectors';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer),
    EffectsModule.forFeature([CacEffects])
  ],
  providers: [
    CacEffects,
    CacService
  ]
})
export class CacStoreModule {
}
