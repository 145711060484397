import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CollectorItemOrder} from './collector';

export class CollectorItemOrderBuilder implements IApiResourceBuilder<CollectorItemOrder> {
  fromResponse(data): CollectorItemOrder {
    return new CollectorItemOrder(
      data.attributes.nodes
    );

  }

  toRequest(_: CollectorItemOrder) {
    return null;
  }
}
