import {FileUtils} from 'app/lib/file_utils/functions';

export namespace UploadUtils {
  export function authHeaders(tokenSvcAuthData, organizationUUID = null) {
    const customHeaders = [];
    const keys = ['client', 'expiry', 'token-type', 'uid'];
    keys.forEach(key => {
      customHeaders.push({ name: key, value: tokenSvcAuthData[key] });
    });
    customHeaders.push({ name: 'access-token', value: tokenSvcAuthData['accessToken'] });

    if (organizationUUID) {
      customHeaders.push({ name: 'X-ORGANIZATION', value: organizationUUID });
    }
    return customHeaders;
  }

  export function authHeadersObj(tokenSvcAuthData) {
    return tokenSvcAuthData ? {
      'access-token': tokenSvcAuthData['accessToken'],
      'client': tokenSvcAuthData['client'],
      'uid': tokenSvcAuthData['uid'],
    } : {};
  }

  export function validate(fileItem, supportedTypes, maxSize) {
    const extension = FileUtils.getExtension(fileItem.file.name);

    if (supportedTypes.indexOf(extension) === -1) {
      return {
        name: fileItem.file.name,
        reason: 'Unsupported file format: ' + extension
      };
    }

    if (fileItem.file.size / 1024 > maxSize) {
      return {
        name: fileItem.file.name,
        reason: `File bigger than ${maxSize} KB`
      };
    }
    return null;
  }
}
