<!-- Global Websocket Service -->
<fivef-actioncable></fivef-actioncable>

<!-- Two Factor Dialog -->
<dvtx-two-factor-verification-dialog #dvtxTwoFactorVerification></dvtx-two-factor-verification-dialog>

<!-- Page header -->
<dvtx-page-header #mainHeader [error]="error"></dvtx-page-header>

<!-- Quick access workflows (blue buttons) -->
<div class="quickstart"
     *ngIf="!error && showQuickstarter && !loading && (currentOrganization$ | async) && (featureSet$ | async)?.canCreateWorkflows">
  <div class="quickstart-list">
    <dvtx-quickstart-bar class="quickstart-list-holder"></dvtx-quickstart-bar>
  </div>
</div>

<!-- Main content container -->
<mat-sidenav-container [hasBackdrop]="false" autosize class="dvtx-app" style="display: flex;">

  <!-- Main left sidebar -->
  <mat-sidenav (mouseleave)="pageSideBarComponent?.showIfCollapsed(false);" #sidebar
               class="dvtx-sidebar-nav-container dvtx-app-sidebar mat-elevation-z4" style="overflow: visible">
    <div id="fivef-main-app--navigation-wrapper"
         class="fivef-main-app--navigation-wrapper"
         (mouseenter)="pageSideBarComponent?.showIfCollapsed(true)">
      <div class="fivef-main-app--navigation">
        <div class="fivef-main-app--navigation--main-items">
          <dvtx-sidebar-navigation [showOnlyIcons]="!sidebarCollapsed"
                                   [routes]="sidebarMainRoutes?.concat(sidebarOtherRoutes)">
          </dvtx-sidebar-navigation>
        </div>
        <div class="fivef-main-app--navigation--secondary-items">
          <dvtx-sidebar-navigation [showOnlyIcons]="!sidebarCollapsed"
                                   [routes]="sidebarSettingsRoutes">
          </dvtx-sidebar-navigation>
        </div>
      </div>
    </div>

    <fivef-page-sidebar #pageSideBarComponent
                        class="fivef-main-app--context-navigation"
                        (openSidebar)="openSidebar($event)"
                        (onShowIconChange)="setSidebarCollapsedState($event)"></fivef-page-sidebar>

    <fivef-sidebar-toggle *ngIf="_authTokenService?.userSignedIn()"
                          [icon]="checkSidebarToggleIcon()"
                          (onToggle)="pageSideBarComponent?.collapseSidebarApp($event, sidebar)"
                          class="fivef-page-sidebar-toggle fivef-page-sidebar-toggle--show">
    </fivef-sidebar-toggle>
  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content style="margin-left: 0; flex:auto"
                       class="fivef-app-content"
                       id="fivef-app-content">

    <fivef-loading-indicator *ngIf="loading" [appearance]="'spinnerOnly'"></fivef-loading-indicator>

    <div [hidden]="loading" class="fivef-app-content__router-outlet">
      <!-- Resize handle border of sidebar -->
      <div class="fivef-page-sidebar-resizer--handle fivef-page-sidebar-resizer"></div>

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
