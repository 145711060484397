<div [formGroup]="form"
     class="ff-content-container-width fivef-user-profile--form">
  <!--  title and name section-->
  <div class="ff-icon-input">
    <mat-icon>account_box</mat-icon>
    <div class="ff-icon-input--content fivef-user-profile--name"
         style="">
      <dvtx-text-input-cva
        class="fivef-user-profile--title"
        [label]="'CONTACTS.TITLE'"
        formControlName="honorific">
      </dvtx-text-input-cva>

      <dvtx-text-input-cva
        [label]="'CONTACTS.FIRST_NAME'"
        formControlName="firstName"
        [requiredField]="true">
      </dvtx-text-input-cva>

      <dvtx-text-input-cva
        [label]="'CONTACTS.LAST_NAME'"
        formControlName="lastName"
        [requiredField]="true">
      </dvtx-text-input-cva>
    </div>
  </div>

  <hr>

  <!--  email section-->
  <div class="ff-icon-input ff-mw500">
    <mat-icon>email</mat-icon>
    <dvtx-email-address-input class="ff-icon-input--content"
                              [showEmailType]="false"
                              formControlName="mainEmailAddress"
                              [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]">
    </dvtx-email-address-input>
  </div>

  <div class="ff-icon-input--indent-paragraph">
    <dvtx-email-address-group-cva
      [emailAddresses]="myContactDetails?.emailAddresses"
      (addEmail)="addFurtherEmail($event)"
      [showAddEmail]="false"
      (removeEmail)="removeFurtherEmail($event)"
      [disabled]="disabled">
    </dvtx-email-address-group-cva>
  </div>

  <hr>

  <!--  phone number section-->
  <div class="ff-icon-input ff-mw500">
    <mat-icon>phone</mat-icon>
    <dvtx-telephone-input class="ff-icon-input--content"
                          [formControlName]="'mainPhoneNumber'"
                          [isType]="false"></dvtx-telephone-input>

  </div>

  <!-- TODO: It is not saving after first creation??? -->
  <!-- <div class="ff-icon-input--indent-paragraph">
        <dvtx-telephone-group-cva
          [telephonenumbers]="myContactDetails?.phoneNumbers"
          (addPhone)="addFurtherPhoneNumber($event)"
          (removePhone)="removeFurtherPhoneNumber($event)"
          [disabled]="disabled">
        </dvtx-telephone-group-cva>
      </div>-->

  <hr>

  <!--  address section -->
  <div class="ff-icon-input">
    <mat-icon>place</mat-icon>
    <dvtx-address-input-cva class="ff-icon-input--content"
                            [label]="'CONTACTS.ADDRESS'"
                            formControlName="mainAddress"
                            [disabled]="disabled">
    </dvtx-address-input-cva>
  </div>

  <!-- TODO: It is not saving after first creation??? -->
  <!-- <div class="ff-icon-input--indent-paragraph">
      <dvtx-address-input-group-cva
        [enableLocationOrType]="true"
        [addresses]="myContactDetails?.addresses"
        (addAddress)="addFurtherAddress($event)"
        (removeAddress)="removeFurtherAddress($event)"
        [disabled]="disabled">
      </dvtx-address-input-group-cva>
    </div>-->
</div>
