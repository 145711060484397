import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CmsArticle} from './cms-article';

export class CmsArticleBuilder implements IApiResourceBuilder<CmsArticle> {
  fromResponse(data): CmsArticle {
    const article = new CmsArticle(
      data.id,
      data.attributes.cms_blog_id,
      data.attributes.title,
      data.attributes.subtitle,
      data.attributes.content,
      data.attributes.author_email,
      data.attributes.published_at,
      data.attributes.created_at,
      data.attributes.updated_at);
    article.sticky = data.attributes.sticky;
    return article;
  }

  toRequest(cmsArticle: CmsArticle) {
    return {
      data: {
        id: cmsArticle.id,
        type: cmsArticle.type,
        attributes: {
          title: cmsArticle.title,
          subtitle: cmsArticle.subtitle,
          content: cmsArticle.content,
          sticky: cmsArticle.sticky,
          published: !!cmsArticle.publishedAt
        }
      }
    };
  }
}
