import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefEditContactDialogComponent} from './fivef-edit-contact-dialog/fivef-edit-contact-dialog.component';
import {FivefContextSidebarComponent} from '../../common/fivef-context-sidebar/fivef-context-sidebar.component';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import {FivefClientContactListingComponent} from '../../client/fivef-edit-client-dialog/fivef-client-contact-listing/fivef-client-contact-listing.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {FivefContextSidebarContentDirective} from '../../common/fivef-context-sidebar/fivef-context-sidebar-content.directive';
import {FivefContactMobileSettingsComponent} from './fivef-contact-mobile-settings/fivef-contact-mobile-settings.component';
import {MatInputModule} from '@angular/material/input';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefContactPersonComponent} from './fivef-contact-person/fivef-contact-person.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FivefContactDirective} from './fivef-contact.directive';
import {FivefCreateContactDialogComponent} from './fivef-create-contact-dialog/fivef-create-contact-dialog.component';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefContactVisibilitySelectComponent} from './fivef-contact-visibility-select/fivef-contact-visibility-select.component';
import {FivefInputLabelComponent} from '../../input/fivef-input-label/fivef-input-label.component';
import {BaseFormElementsModule} from '../../../fivef-legacy/base-form-elements/base-form-elements.module';


@NgModule({
  declarations: [
    FivefContactMobileSettingsComponent,
    FivefEditContactDialogComponent,
    FivefCreateContactDialogComponent,
    FivefContactVisibilitySelectComponent,
    FivefContactPersonComponent,
    FivefContactDirective
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatTabsModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    MatCheckboxModule,
    FivefContextSidebarComponent,
    FivefContextSidebarContentDirective,
    FivefClientContactListingComponent,
    FivefClientContactListingComponent,
    FivefIconMessageBlockComponent,
    FivefDialogComponent,
    ReactiveFormsModule,
    BaseFormElementsModule,
    FivefInputLabelComponent,
  ],
  exports: [
    FivefEditContactDialogComponent,
    FivefContactDirective
  ]
})
export class FivefContactModule {
}
