import {Component, Input, OnInit} from '@angular/core';
import {Browser} from 'browser-support';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'fivef-browser-support-container',
  templateUrl: './fivef-browser-support-container.component.html',
  styleUrls: ['./fivef-browser-support-container.component.scss']
})
export class FivefBrowserSupportContainerComponent implements OnInit {
  /**
   * Test and styleguide setter.
   */
  @Input()
  unsupportedBrowserTest = false;


  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const token = this._activatedRoute.snapshot.params['token'];
    const validator = new Browser.SupportValidator();
    const redirectToSystemUnsupportedBrowser = this.unsupportedBrowserTest || validator.isUnsupportedBrowser;

    if (this.unsupportedBrowserTest) {
      return;
    }

    if (redirectToSystemUnsupportedBrowser) {
      this._router.navigate(['system', 'unsupported-browser']);
    } else {
      this._router.navigate(['workflows', 'audit', 'kap', '_verification', token], { queryParamsHandling: 'preserve' });
    }
  }
}
