<fivef-dialog [isCallToAction]="true"
              [isUtilityDialog]="true"
              [title]="'DMS.DELETE_FOLDER_ACTION'" [color]="'var(--fivef-color-icon-error)'">
  <div fivef-dialog-footer>
    <button mat-button
            (click)="closeDialog()">
      {{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>
    <button mat-raised-button
            color="warn"
            (click)="deleteFolder()">
      {{ 'DMS.DELETE_FOLDER_ACTION' | translate }}
    </button>
  </div>
</fivef-dialog>
