<dvtx-task-status-bar *ngIf="_task?.id"
                      [statuses]="_task?.statuses"
                      [activeStatus]="_task?.status"></dvtx-task-status-bar>

<div class="ff-content">
  <div class="ff-content-column ff-content-fill">
    <fivef-input-label>{{ 'GENERAL.TITLE' | translate }}</fivef-input-label>
    <mat-form-field>
      <input type="text"
             matInput
             #titleInputRef="ngModel"
             required
             [value]="_task?.title"
             [disabled]="_task?.is?.closed"
             name="titleInputRef"
             [(ngModel)]="title"
             (keydown.enter)="updateTitle(titleInputRef.value)"
             (focusout)="updateTitle(titleInputRef.value)"
             (blur)="updateTitle(titleInputRef.value)"/>
      <mat-error *ngIf="titleInputRef.errors?.['required']">{{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="!_task?.id"
       class="ff-content-column ff-content-s">
    <fivef-input-label>{{ 'TASK.TASK_TYPE' | translate }}</fivef-input-label>
    <mat-form-field>
      <mat-select [placeholder]="'TASK.TASK_TYPE' | translate"
                  [(ngModel)]="_task.taskType"
                  (ngModelChange)="updateTaskType($event)">
        <mat-option *ngFor="let type of taskTypes | fivefEnumKeys"
                    [value]="taskTypes[type]">
          {{ taskTypeTitle[type] | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="mb-1">
  <fivef-input-label>{{ 'GENERAL.DESCRIPTION' | translate }}</fivef-input-label>
  <fivef-rich-text [text]="_task?.description"
                   [resetOnSave]="false"
                   [saveIcon]="'save'"
                   [disabled]="_task?.is?.closed"
                   [showCancelAction]="false"
                   (onSave)="updateDescription($event)"></fivef-rich-text>
</div>

<div *ngIf="policy?.canUpdateLabels && _task?.id"
     class="ff-content ff-content-column">
  <fivef-input-label>{{ 'KANBAN.LABELS_TITLE' | translate }}</fivef-input-label>
  <fivef-labels-bar [item]="_task" [process]="_task?.process"></fivef-labels-bar>
</div>

<div class="ff-content ff-content-3">
  <div>
    <fivef-input-label>{{ 'GENERAL.RESPONSIBLE' | translate }}</fivef-input-label>
    <fivef-item-selector [appearance]="'avatar'"
                         [icon]="'edit'"
                         [buttonStyle]="'invertedColors'"
                         [selected]="responsible$ | async"
                         [tooltip]="'TASK.ADD_RESPONSIBLE'"
                         [disableRemoveAction]="true"
                         [disabled]="_task.permissions.canChangeResponsible === false || _task?.is?.closed"
                         (onSelect)="updateResponsible(_task, $event)"
                         [collection]="assigneeCandidates$ | async"></fivef-item-selector>
  </div>

  <div>
    <fivef-input-label>{{ 'TASK.ASSIGNEES' | translate }}</fivef-input-label>
    <fivef-item-selector [appearance]="'avatar'"
                         [icon]="'edit'"
                         [buttonStyle]="'invertedColors'"
                         [maxToShow]="3"
                         [tooltip]="'PROJECT_ROOM.ADD_TEAM_MEMBER'"
                         [selected]="selectedAssignees$ | async"
                         [selectionTitle]="'TASK.ASSIGNEES'"
                         [disabled]="_task.permissions.canAssignParticipant === false || _task.permissions.canRemoveParticipant === false  || _task?.is?.closed"
                         [collection]="assigneeCandidates$ | async"
                         (onSelect)="addAssignee(_task, $event)"
                         (onDeselect)="removeAssignee(_task, $event)"></fivef-item-selector>
  </div>
</div>

<div class="ff-content ff-content-3">
  <div>
    <fivef-input-label>{{ 'GENERAL.DUE_DATE' | translate }}</fivef-input-label>
    <fivef-datepicker [value]="_task?.dates?.dueDate"
                      [disabled]="_task?.is?.closed || (_task?.id && !_task?.permissions?.canEdit)"
                      (onChange)="updateDueDate($event)"></fivef-datepicker>
  </div>
</div>

<div class="ff-content ff-content-2">
  <div class="ff-content-row ff-content-align-center">
    <fivef-input-label>{{ 'GENERAL.PRIORITY' | translate }}:</fivef-input-label>
    <fivef-status-selector [theme]="'priority'"
                           [appearance]="'link'"
                           [buttonTitle]="'GENERAL.PRIORITY'"
                           (onSelection)="updatePriority($event)"
                           [selected]="priorityMap[_task.priority.id]"
                           [statuses]="priorities"
                           [disabled]="_task.is.closed || (_task?.id && !_task.permissions.canEdit)"></fivef-status-selector>
  </div>
  <div *ngIf="_task?.id"
       class="ff-content-row ff-content-align-center">
    <fivef-input-label>{{ 'GENERAL.STATUS' | translate }}:</fivef-input-label>
    <fivef-status-selector (onSelection)="updateStatus($event)"
                           [selected]="_task.status"
                           [statuses]="_task.statuses"
                           [disabled]="_task.is.closed || !_task.permissions.canEdit"
                           [appearance]="'link'"></fivef-status-selector>
  </div>
</div>
