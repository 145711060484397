import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import * as model from './tenant';

export namespace Operator {
  export class TenantBuilder implements IApiResourceBuilder<model.Operator.Tenant> {
    fromResponse(data): model.Operator.Tenant {
      if (data) {
        const tenant = new model.Operator.Tenant(
          data.id,
          data.attributes.name,
          data.attributes.sender_name,
          data.attributes.domain,
          data.attributes.email_domain,
          data.attributes.favicon_url,
          data.attributes.login_header,
          data.attributes.login_header_en,
          data.attributes.login_footer,
          data.attributes.login_footer_en,
          data.attributes.imprint_url,
          data.attributes.created_at,
          data.attributes.updated_at);

        tenant.color_theme = data.attributes.color_theme;
        tenant.available_login_themes = data.attributes.available_login_themes;

        return tenant;
      }
    }

    toRequest(tenant: model.Operator.Tenant) {
      return {
        data: {
          id: tenant.id,
          type: tenant.type,
          attributes: {
            name: tenant.name,
            sender_name: tenant.sender_name,
            domain: tenant.domain,
            email_domain: tenant.email_domain || tenant.domain,
            favicon_url: tenant.favicon_url,
            login_header: tenant.login_header,
            login_header_en: tenant.login_header_en,
            login_footer: tenant.login_footer,
            login_footer_en: tenant.login_footer_en,
            imprint_url: tenant.imprint_url,
            available_login_themes: tenant.available_login_themes,
            color_theme: tenant.color_theme,
            color_theme_config: tenant.color_theme_config
          }
        }
      };
    }
  }

  export class AzureSSOConfigBuilder implements IApiResourceBuilder<model.Operator.AzureSSOConfig> {
    fromResponse(data): model.Operator.AzureSSOConfig {
      if (data) {
        return new model.Operator.AzureSSOConfig(
          data.id,
          data.attributes.name,
          data.attributes.domain,
          data.attributes.tenant_id,
          data.attributes.client_id,
          data.attributes.client_secret,
          data.attributes.tenant_account_id,
          data.attributes.tenant_account_name,
          data.attributes.tenant_account_domain,
          data.attributes.default_organization_id,
          !!data.attributes.optional,
          data.attributes.ad_department_onboarding_activated,
          data.attributes.ad_department_onboarding_fallback_id,
          data.attributes.created_at,
          data.attributes.updated_at);
      }
    }

    toRequest(config: model.Operator.AzureSSOConfig) {
      return {
        data: {
          id: config.id,
          type: config.type,
          attributes: {
            name: config.name,
            domain: config.domain,
            client_id: config.clientId,
            client_secret: config.clientSecret,
            tenant_id: config.adTenantId,
            tenant_account_id: config.fiveFTenantId
          }
        }
      };
    }
  }

  export class AzureSSODomainMappingBuilder implements IApiResourceBuilder<model.Operator.AzureSSODomainMapping> {
    fromResponse(data): model.Operator.AzureSSODomainMapping {
      if (data) {
        return new model.Operator.AzureSSODomainMapping(
          data.id,
          data.attributes.source_domain,
          data.attributes.target_domain,
          data.attributes.tenant_account_id,
          data.attributes.tenant_account_name,
          data.attributes.tenant_account_domain,
          data.attributes.created_at,
          data.attributes.updated_at);
      }
    }

    toRequest(config: model.Operator.AzureSSODomainMapping) {
      return {
        data: {
          id: config.id,
          type: config.type,
          attributes: {
            source_domain: config.sourceDomain,
            target_domain: config.targetDomain,
            tenant_account_id: config.fiveFTenantId,
          }
        }
      };
    }
  }
}
