import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {QuickshareRecipient} from './quickshare-recipient';
import {QuickshareRecipientBuilder} from './quickshare-recipient.builder';

@Injectable()
export class QuickshareRecipientService {
  readonly BASE_PATH = 'api/v1/quickshare/processes';

  constructor(private _http: FivefApiResourceService) {}

  getAll(id: string): Observable<QuickshareRecipient[]> {
    const builder = new QuickshareRecipientBuilder(id);
    return <Observable<QuickshareRecipient[]>>this._http.get<QuickshareRecipientBuilder, QuickshareRecipient>(builder, `${this.BASE_PATH}/${id}/recipients`);
  }

  create(id: string, recipient: QuickshareRecipient): Observable<QuickshareRecipient> {
    const builder = new QuickshareRecipientBuilder(id);
    const payload = builder.toRequest(recipient);
    return <Observable<QuickshareRecipient>>this._http.post<QuickshareRecipientBuilder, QuickshareRecipient>(builder, `${this.BASE_PATH}/${id}/recipients`, payload);
  }

  update(id: string, recipient: QuickshareRecipient): Observable<QuickshareRecipient> {
    const builder = new QuickshareRecipientBuilder(id);
    const payload = builder.toRequest(recipient);
    return <Observable<QuickshareRecipient>>this._http.put<QuickshareRecipientBuilder, QuickshareRecipient>(builder, `${this.BASE_PATH}/${id}/recipients/${recipient.id}`, payload);
  }

  remove(id: string, recipientId: string): Observable<QuickshareRecipient> {
    const builder = new QuickshareRecipientBuilder(id);
    return <Observable<QuickshareRecipient>>this._http.del<QuickshareRecipientBuilder, QuickshareRecipient>(builder, `${this.BASE_PATH}/${id}/recipients/${recipientId}`);
  }
}
