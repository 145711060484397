import {NgModule} from '@angular/core';
import {FileInboxService, getSaver, SAVER} from './file-inbox.service';

@NgModule({
  imports: [
  ],
  providers: [
    FileInboxService,
    {provide: SAVER, useFactory: getSaver}
  ]
})
export class FileInboxStoreModule {
}
