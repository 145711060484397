import {IResource} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';

export namespace LicenceType {
  export const BASE_LICENSE = 'sales_licence_bases';
  export const TEAM_LICENSE = 'sales_licence_teams';
  export const PROFESSIONAL_LICENSE = 'sales_licence_professionals';
  export const ANNUAL_AUDITING_LICENSE = 'sales_licence_annual_auditings';
  export const GROUP_AUDITING_LICENSE = 'sales_licence_group_auditings';
}

export class LicenceAssignment implements IResource {
  readonly type = 'sales_licence_assignment';

  public hasKapLicence: boolean;
  public hasJapLicence: boolean;
  public hasTeamLicence: boolean;
  public hasProfLicence: boolean;
  public hasStandardLicence: boolean;
  public hasProfessionalLicence: boolean;
  public hasEnterpriseLicence: boolean;

  constructor(
    public id: number,
    public licenceType: string | undefined,
    public membershipId: string,
    public kapLicenseId: string | null,
    public japLicenseId: string | null,
    public teamLicenseId: string | null,
    public professionalLicenseId: string | null,
    public standardLicenceId?: string | null,
    public professionalLicenceId?: string | null,
    public enterpriseLicenceId?: string | null,
    public attributes?: any
  ) {
    this.hasKapLicence = kapLicenseId !== null;
    this.hasJapLicence = japLicenseId !== null;
    this.hasTeamLicence = teamLicenseId !== null;
    this.hasProfLicence = professionalLicenseId !== null;
    this.hasStandardLicence = standardLicenceId !== null;
    this.hasProfessionalLicence = professionalLicenceId !== null;
    this.hasEnterpriseLicence = enterpriseLicenceId !== null;
    switch (licenceType) {
      case 'sales_licence_standard':
        attributes.title = 'LICENCES.STANDARD';
        break;
      case 'sales_licence_professional':
        attributes.title = 'LICENCES.PROFESSIONAL';
        break;
      case 'sales_licence_enterprise':
        attributes.title = 'LICENCES.ENTERPRISE';
        break;
    }


  }



}

export class LicencePurchase {
  constructor(
    public productId: string,
    public invoiceAddressId: string,
    public amount: number
  ) {
  }
}

export class Licence implements IResource {
  constructor(
    public id: number,
    public type: string,
    public title: string,
    public subtitle: string,
    public description: string,
    public licenceType: string,
    public validUntil: Date
  ) {
  }
}


export class LicenceProduct {

  get twoDecimalsFix() {
    // return parseFloat().toFixed(2);
    const Num = +this.price;
    return Num.toFixed(2);
  }

  constructor(
    public id: string,
    public title: string,
    public price: number,
    public description: LicenceProductDescription,
  ) {
  }
}

export class LicenceProductDescription {
  constructor(
    public title: string,
    public mainText: string,
    public footerText: string,
    public bulletPoints: string[] = [],
    public displayOrder
  ) {
  }
}

// NEW LICENCE MODEL {

  export enum PaymentMethod {
    'monthly',
    'yearly'
  }

  export enum LicencePlanType {
    'standard',
    'professional',
    'enterprise'
  }

  export interface LicencePlanPrice {
    monthly: string,
    yearly: string
  }

  export interface LicencePlanAttribute {
    type: LicencePlanType | string,
    title: string,
    price: LicencePlanPrice,
    userLicencesCount: number,
    organizationsLicencesCount: number,
    additionalMemberCost: LicencePlanPrice
  }

  export interface NamedUserLicence {
    created_at: Date,
    validUntil: Date
  }

  export interface PurchasedLicencePlan {
    paymentMethod: PaymentMethod,
    licencePlanType: LicencePlanAttribute,
    validUntil: Date | undefined,
    namedUserLicences: NamedUserLicence[] | undefined
  }

// }
