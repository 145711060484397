import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'fivef-status-list-item',
  host: {class: 'fivef-status-list-item'},
  templateUrl: './fivef-status-list-item.component.html',
  styleUrls: ['./fivef-status-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefStatusListItemComponent {
  @Input()
  inProgress = false;

  @Input()
  progressTitle: string;

  @Input()
  successTitle: string;

  @Input()
  successPrefix: string;

  @Input()
  isErrorState = false;

  @Input()
  errorPrefix: string;

  @Input()
  errorTitle: string;
}
