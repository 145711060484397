<ng-container [ngSwitch]="appearance">
  <button mat-button
          *ngSwitchCase="'button'"
          [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
    {{ 'LABELS.ADD_LABEL' | translate }}
    <span class="fivef-labels-selector--indicator"
          style="top: -3px; right: -18px"
          *ngIf="hiddenCount > 0">
      {{ hiddenCount }}
    </span>
  </button>

  <button *ngSwitchDefault
          class="fivef-labels-selector--action-button"
          [matTooltip]="'LABELS.ADD_LABEL' | translate"
          [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="add_label"></mat-icon>
    <span class="fivef-labels-selector--indicator"
          *ngIf="hiddenCount > 0">{{ hiddenCount }}</span>
  </button>
</ng-container>

<mat-menu #menu="matMenu">
  <div class="fivef-labels-selector--menu"
       (click)="$event.stopPropagation();"
       (keydown.tab)="$event.stopPropagation()"
       (keydown.space)="$event.stopPropagation()">

    <h4 class="fivef-labels-selector--title">{{ 'LABELS.Select_Labels' | translate }}</h4>

    <fivef-search class="fivef-labels-selector--search"
                  (onChange)="applySearch($event)"></fivef-search>

    <ul class="fivef-labels-selector--listing">
      <li class="fivef-labels-selector--list-item" *ngFor="let label of filteredLabels$ | async">
        <mat-checkbox class="fivef-labels-selector--list-item--checkbox"
                      [disableRipple]="true"
                      [checked]="isSelected(label?.id)"
                      (change)="select(label, $event)">
          <fivef-label [label]="label" [preventClicking]="true"
                       [readonly]="true"></fivef-label>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</mat-menu>
