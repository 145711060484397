import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {
  FivefApiResourceService,
  SimpleMessage,
  SimpleMessageBuilder
} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import * as modelInterface from './tenant.interface';
import * as userModelInterface from '../user/user.interface';
import * as userModel from '../user/user';
import * as userBuilder from '../user/user.builder';
import * as model from './tenant';
import * as organizationModel from '../organization/organization';
import * as build from './tenant.builder';
import * as organizationBuild from '../organization/organization.builder';
import {catchError, map, tap} from 'rxjs/operators';
import * as orgBuild from '../organization/organization.builder';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Download, FileApiResourceService} from '../../process-artifact/file-api-resource.service';
import {EnvService} from '../../../lib/fivef-net/fivef-api-resource/services/env.service';
import {AngularTokenService} from 'angular-token';
import {throwError} from 'rxjs/internal/observable/throwError';
import {of} from 'rxjs/internal/observable/of';
import {HttpClient} from '@angular/common/http';
import {IApiResponse} from '../../../lib/fivef-net/fivef-api-resource/models/api.interface';

@Injectable()
export class TenantTenantService {
  readonly BASE_PATH = 'api/v3/tenants';

  private _tenant$ = new BehaviorSubject<model.Tenant.Tenant>(null);
  public tenant$ = this._tenant$.asObservable();

  constructor(private _http: FivefApiResourceService,
              private _httpClient: HttpClient,
              private env: EnvService,
              private _fhttp: FileApiResourceService,
              private _tokenSvc: AngularTokenService) {
  }

  /**
   * Returns the tenant and its ID by organization.
   *
   * @param organizationId
   */
  getTenantFor(organizationId: string): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant>>this._http.get<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/lookup/${organizationId}`)
      .pipe(tap(tenant => {
        this._tenant$.next(<model.Tenant.Tenant>tenant);
      }));
  }

  getStats(startAt: Date = null) {
    const builder = new build.Tenant.TenantLicenceStatsBuilder();
    const payload = { data: { attributes: { start_at: startAt } } };
    return <Observable<model.Tenant.LicenceStats>>this._http.post<build.Tenant.TenantLicenceStatsBuilder, model.Tenant.LicenceStats>(builder, `${this.BASE_PATH}/entities/stats`, payload);
  }

  getAuthorizedDomains(id: string): Observable<model.Tenant.TenantAuthorizedDomain[]> {
    const builder = new build.Tenant.TenantAuthorizedDomainsBuilder();
    return <Observable<model.Tenant.TenantAuthorizedDomain[]>>this._http.get<build.Tenant.TenantAuthorizedDomainsBuilder, model.Tenant.TenantAuthorizedDomain>(builder, `${this.BASE_PATH}/entities/authorized_domains`);
  }

  getOne(id: string): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant>>this._http.get<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${id}`);
  }

  getTenantOrganizations(tenantId: string): Observable<organizationModel.Tenant.Organization[]> {
    const builder = new orgBuild.Tenant.OrganizationBuilder();
    return <Observable<organizationModel.Tenant.Organization[]>>this._http.get<orgBuild.Tenant.OrganizationBuilder, organizationModel.Tenant.Organization>(builder, `${this.BASE_PATH}/entities/${tenantId}/organizations`);
  }

  getTenantUsers(tenantId: string, params: userModelInterface.Tenant.IUserGetAllParams): Observable<model.Tenant.Tenant> {
    const page = params.page;
    let query = `?all=${!!params.all}`;
    if (page && page > 0) {
      query = `${query}&page=${params.page}`
    }

    if (params.lockedOnly) {
      query = `${query}&locked_only=${!!params.lockedOnly}`
    }

    if (params.query && params.query.length > 0) {
      query = `${query}&q=${params.query}`
    }

    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant>>this._http.get<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenantId}/users${query}`);
  }




  getAll(): Observable<model.Tenant.Tenant[]> {
    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant[]>>this._http.get<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}`);
  }

  getAllOrganizations(tenantId): Observable<organizationModel.Tenant.Organization[]> {
    const builder = new organizationBuild.Tenant.OrganizationBuilder();
    return <Observable<organizationModel.Tenant.Organization[]>>this._http.get<organizationBuild.Tenant.OrganizationBuilder, organizationModel.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations`);
  }

  removeOrganization(tenantId, organizationId): Observable<organizationModel.Tenant.Organization> {
    const builder = new organizationBuild.Tenant.OrganizationBuilder();
    return <Observable<organizationModel.Tenant.Organization>>this._http.del<organizationBuild.Tenant.OrganizationBuilder, organizationModel.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations/${organizationId}`);
  }

  assignOrganization(tenantId, organizationId): Observable<organizationModel.Tenant.Organization> {
    const builder = new organizationBuild.Tenant.OrganizationBuilder();
    const params = {
      data: {
        attributes: {
          organization_id: organizationId
        }
      }
    }
    return <Observable<organizationModel.Tenant.Organization>>this._http.post<organizationBuild.Tenant.OrganizationBuilder, organizationModel.Tenant.Organization>(builder, `${this.BASE_PATH}/${tenantId}/organizations`, params);
  }

  create(params: modelInterface.Tenant.ITenantParams): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: params
      }
    };
    return <Observable<model.Tenant.Tenant>>this._http.post<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, this.BASE_PATH, payload);
  }

  updateDetails(tenant: model.Tenant.Tenant): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          sender_name: tenant.sender_name,
          imprint_url: tenant.imprint_url
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenant.id}`, payload);
  }

  /**
   * Updates the tenant.
   * NOTE: Function update is not intended for the current interface (more attributes updates than revised).
   * @param tenant
   */
  update(tenant: model.Tenant.Tenant): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = builder.toRequest(tenant);
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/${tenant.id}`, payload);
  }

  destroy(tenantId: string): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant>>this._http.del<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/${tenantId}`);
  }

  getTenantAdminLogItems(tenantId: string, page: number): Observable<model.Tenant.AdminLogItem[]> {
    const builder = new build.Tenant.AdminLogItemBuilder();
    const query = `?page=${page}`;
    return <Observable<model.Tenant.AdminLogItem[]>>this._http.get<build.Tenant.AdminLogItemBuilder, model.Tenant.AdminLogItem>(builder, `${this.BASE_PATH}/entities/${tenantId}/tenant_logs${query}`);
  }

  downloadLog(tenantId): Observable<Download> {
    const filename = 'tenant_log.txt';
    return this._fhttp.getBlob( `${this.env.tusServer()}/${this.BASE_PATH}/entities/${tenantId}/tenant_logs/download`, filename, this._tokenSvc.currentAuthData);
  }

  updateAdAutoProvisioning(tenantId: string, enable: boolean): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          ad_department_onboarding: enable
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenantId}/ad_department_onboarding`, payload);
  }

  enableAutoLicensing(tenantId: string, enable: boolean): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          auto_license_upgrade: enable
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenantId}/auto_license_upgrade`, payload);
  }

  updateAdAutoOffboarding(tenantId: string, enable: boolean): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          ad_offboarding_enabled: enable
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenantId}/ad_offboarding`, payload);
  }

  updateAdAutoOffboardingAdmin(tenantId: string, adminId: string): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          ad_offboarding_admin_id: adminId
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/entities/${tenantId}/ad_offboarding_performer`, payload);
  }

  adSelftest(tenantId: string): Observable<SimpleMessage> {
    const builder = new SimpleMessageBuilder();
    return <Observable<SimpleMessage>>this._http.get<SimpleMessageBuilder, SimpleMessage>(builder, `${this.BASE_PATH}/entities/${tenantId}/ad_department_selftest`);
  }

  offboardingAdminTest(tenantId: string): Observable<SimpleMessage> {
    const builder = new SimpleMessageBuilder();
    return <Observable<SimpleMessage>>this._http.get<SimpleMessageBuilder, SimpleMessage>(builder, `${this.BASE_PATH}/entities/${tenantId}/offboarding_admin_test`)
      .pipe(catchError(err => {
        console.error(err);
        if (err.error && err.error.data && err.status === 422) {
          return of(builder.fromResponse(err.error.data))
        } else {
          return throwError(err)
        }
      }))
  }

  getDepartment(tenantId: string, id: string): Observable<model.Tenant.TenantDepartment> {
    const builder = new build.Tenant.TenantDepartmentBuilder();
    return <Observable<model.Tenant.TenantDepartment>>this._http.get<build.Tenant.TenantDepartmentBuilder, model.Tenant.TenantDepartment>(builder, `${this.BASE_PATH}/entities/${tenantId}/departments/${id}`);
  }

  getDepartments(tenantId: string): Observable<model.Tenant.TenantDepartment[]> {
    const builder = new build.Tenant.TenantDepartmentBuilder();
    return <Observable<model.Tenant.TenantDepartment[]>>this._http.get<build.Tenant.TenantDepartmentBuilder, model.Tenant.TenantDepartment>(builder, `${this.BASE_PATH}/entities/${tenantId}/departments`);
  }

  createDepartment(tenantId: string, department: model.Tenant.TenantDepartment): Observable<model.Tenant.TenantDepartment> {
    const builder = new build.Tenant.TenantDepartmentBuilder();
    const payload = builder.toRequest(department)
    return <Observable<model.Tenant.TenantDepartment>>this._http.post<build.Tenant.TenantDepartmentBuilder, model.Tenant.TenantDepartment>(builder, `${this.BASE_PATH}/entities/${tenantId}/departments`, payload);
  }

  updateDepartment(tenantId: string, department: model.Tenant.TenantDepartment): Observable<model.Tenant.TenantDepartment> {
    const builder = new build.Tenant.TenantDepartmentBuilder();
    const payload = builder.toRequest(department)
    return <Observable<model.Tenant.TenantDepartment>>this._http.put<build.Tenant.TenantDepartmentBuilder, model.Tenant.TenantDepartment>(builder, `${this.BASE_PATH}/entities/${tenantId}/departments/${department.id}`, payload);
  }

  deleteDepartment(tenantId: string, id: string): Observable<model.Tenant.TenantDepartment> {
    const builder = new build.Tenant.TenantDepartmentBuilder();
    return <Observable<model.Tenant.TenantDepartment>>this._http.del<build.Tenant.TenantDepartmentBuilder, model.Tenant.TenantDepartment>(builder, `${this.BASE_PATH}/entities/${tenantId}/departments/${id}`);
  }

  offboardingDryRun(tenantId: string): Observable<model.Tenant.OffbordingDryRun> {
    const builder = new build.Tenant.OffbordingDryRunBuilder(tenantId);
    return <Observable<model.Tenant.OffbordingDryRun>>this._http.get<build.Tenant.OffbordingDryRunBuilder, model.Tenant.OffbordingDryRun>(builder, `${this.BASE_PATH}/entities/${tenantId}/offboarding_dry_run`);
  }

  offboardingDryRunExport(tenantId, filename): Observable<Download> {
    return this._fhttp.getBlob( `${this.env.tusServer()}/${this.BASE_PATH}/entities/${tenantId}/offboarding_dry_run_export`, filename, this._tokenSvc.currentAuthData);
  }

  getLoginConfigs(): Observable<model.Tenant.LoginConfig[]> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig[]>>this._http.get<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs`);
  }

  createLoginConfig(config: model.Tenant.LoginConfig): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    const payload = builder.toRequest(config);
    return <Observable<model.Tenant.LoginConfig>>this._http.post<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs`, payload);
  }

  getLoginConfig(id: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig>>this._http.get<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}`);
  }

  updateLoginConfig(config: model.Tenant.LoginConfig): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    const payload = builder.toRequest(config);
    return <Observable<model.Tenant.LoginConfig>>this._http.put<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${config.id}`, payload);
  }

  deleteLoginConfig(id: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig>>this._http.del<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}`);
  }

  publishConfig(id: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig>>this._http.post<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}/publish`, {});
  }

  unPublishConfig(id: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig>>this._http.post<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}/unpublish`, {});
  }

  setSlideshowAtConfig(id: string, slideshowId: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    const payload = {
      data: {
        attributes: {
          slideshow_id: slideshowId
        }
      }
    }
    return <Observable<model.Tenant.LoginConfig>>this._http.post<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}/select_slideshow`, payload);
  }

  resetSlideshowAtConfig(id: string): Observable<model.Tenant.LoginConfig> {
    const builder = new build.Tenant.LoginConfigBuilder();
    return <Observable<model.Tenant.LoginConfig>>this._http.post<build.Tenant.LoginConfigBuilder, model.Tenant.LoginConfig>(builder, `${this.BASE_PATH}/login_configs/${id}/reset_slideshow`, {});
  }

  getTenantProfile(): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    return <Observable<model.Tenant.Tenant>>this._http.get<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/profiles`);
  }

  updateTenantProfile(tenant: model.Tenant.Tenant): Observable<model.Tenant.Tenant> {
    const builder = new build.Tenant.TenantBuilder();
    const payload = {
      data: {
        attributes: {
          sender_name: tenant.sender_name,
          imprint_url: tenant.imprint_url,
          login_header: tenant.login_header,
          login_header_en: tenant.login_header_en,
          login_footer: tenant.login_footer,
          login_footer_en: tenant.login_footer_en,
        }
      }
    }
    return <Observable<model.Tenant.Tenant>>this._http.put<build.Tenant.TenantBuilder, model.Tenant.Tenant>(builder, `${this.BASE_PATH}/profiles`, payload);
  }

  /**
   * {
   *   "data": {
   *     "attributes": {
   *       "available_login_themes": [
   *         "fivef_pure"
   *       ]
   *     }
   *   }
   * }
   */
  getAvailableLoginThemes(): Observable<string[]> {
    return this._httpClient.get( `${this.env.apiBase()}/${this.BASE_PATH}/login_configs/available_login_themes`)
      .pipe(map((res: any) => res?.data.attributes?.available_login_themes))
  }
}
