<ng-container *ngIf="_id && nodes$ && (nodes$ | async)?.length && (nodes$ | async)?.length > 0">
  <div class="ff-space-between ff-w100 ff-align-center">
    <h3>{{ 'PROJECT.PROJECT_STRUCTURE' | translate }}</h3>

    <!-- Display options menu -->
    <button mat-icon-button
            [matTooltip]="'GENERAL.DISPLAY_OPTIONS' | translate"
            [matMenuTriggerFor]="displayMenu"
            class="ff-icon-sm">
      <mat-icon>tune</mat-icon>
    </button>
    <mat-menu #displayMenu="matMenu" class="ff-menu">
      <div class="px-3 py-1">
        <!-- Sort options -->
        <a class="h4">{{ 'SORT.SORTING' | translate }}</a>

        <div style="margin-top: 5px;" *ngIf="settings">
          <mat-radio-group style="display: inline-flex; flex-direction: column;"
                           (change)="changeSortingBy($event)" [value]="settings.id">
            <div class="mb-1">{{ 'SORT.ALPHABETICAL' | translate }}</div>
            <mat-radio-button value="title_asc" style="margin-bottom: 5px;">
              {{ 'SORT.ASCENDING' | translate }}
            </mat-radio-button>
            <mat-radio-button value="title_desc" style="margin-bottom: 10px;">
              {{ 'SORT.DESCENDING' | translate }}
            </mat-radio-button>
            <div class="mb-1">{{ 'SORT.BY_CREATION_DATE' | translate }}</div>
            <mat-radio-button value="created_at_asc" style="margin-bottom: 5px;">
              {{ 'SORT.ASCENDING' | translate }}
            </mat-radio-button>
            <mat-radio-button value="created_at_desc" style="margin-bottom: 10px;">
              {{ 'SORT.DESCENDING' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Closed filter -->
        <a class="mb-1 h4">{{ 'FILTER.FILTER_OPTIONS' | translate }}</a>
        <div>
          <mat-checkbox (click)="$event.stopPropagation()" [checked]="settings?.hideClosed"
                        matTooltip="{{ 'FILTER.HIDE_CLOSED_WORKFLOWS' | translate }}"
                        (change)="toggleClosed($event)">
            {{ 'FILTER.HIDE_CLOSED_WORKFLOWS' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </mat-menu>
  </div>

  <!-- Search -->
  <div class="fivef-toolbar fivef-toolbar--start fivef-toolbar--content-spacing fivef-toolbar--content-spacing--end">
    <fivef-search [disabled]="!(nodes$ | async)?.length" (onChange)="applySearch($event)"></fivef-search>
  </div>

  <!-- Tree -->
  <fivef-tree [nodes]="nodes$ | async"
              [search]="searchTerm"
              [expandRoot]="false"
              [showNoResult]="true"
              [noElementsIcon]="'category'"
              [noElementsText]="'FASTDOCS.NO_ITEMS_FOUND'"
              [select]="{ id: _id }"
              (onSelect)="selectNode($event)">
    <ng-container *fivefTreeNodeDef="let node">
      <fivef-process-tree-node [node]="node"></fivef-process-tree-node>
    </ng-container>
  </fivef-tree>
</ng-container>
