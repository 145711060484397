import * as auditOrderActions from '../actions/audit-order.actions';
import { IAuditOrder } from '../../../+store/_legacy/api/models/audit-order.interface';

export type AuditOrderState = IAuditOrder[];
export const initialState: AuditOrderState = [];

export function auditOrderReducer(state: AuditOrderState = initialState, action: auditOrderActions.Actions): AuditOrderState {
  switch (action.type) {
    case auditOrderActions.ADD_AUDIT_ORDER:
      const contained = state.some((task) => action.payload.id === task.id);
      return contained ? state : [action.payload, ...state];

    case auditOrderActions.GET_AUDIT_ORDER_SUCCESS:
      return state.some((task) => action.payload.id === task.id) ? state : [action.payload, ...state];

    case auditOrderActions.GET_AUDIT_ORDERS_SUCCESS:
      return action.payload;

    case auditOrderActions.RESET_AUDIT_ORDERS:
      return initialState;

    default: {
      return state;
    }
  }
};

