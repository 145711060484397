import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export interface IEmailFooter extends IResource {
  title: string,
  content: string,
  creatorEmail: string,
  publishedAt: string,
  published: boolean,
  updatedAt: string,
  createdAt: string,
}

export enum FooterType {
  User = 'user-signature',
  Orgnizational = 'orgnization-signature'
}

