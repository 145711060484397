import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuditContracteeActionTypes, LoadAll, LoadAllFail, LoadAllSuccess} from './audit-contractee.actions';
import {AuditContracteeService} from './audit-contractee.service';
import {AuditContractee} from './audit-contractee';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class AuditContracteeEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(AuditContracteeActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(first()).pipe(
        concatMap((res: AuditContractee[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: AuditContracteeService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




