import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Label} from 'app/+store/label/label';
import {ItemLabels} from 'app/+store/item-labels/item-labels';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {ItemLabelsActions, ItemLabelsSelectors, LabelActions} from 'app/+store';
import {first, switchMap} from 'rxjs/operators';
import {LabelService} from 'app/+store/label/label.service';
import {ItemType} from 'app/+store/item-labels/item-labels.interface';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {FivefLabelsComponent} from '../fivef-labels/fivef-labels.component';

@Component({
  selector: 'fivef-labels-bar',
  standalone: true,
  host: {class: 'fivef-labels-bar'},
  imports: [
    CommonModule,
    FivefLabelsComponent
  ],
  templateUrl: './fivef-labels-bar.component.html',
  styleUrls: ['./fivef-labels-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefLabelsBarComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public labels$: Observable<Label[]>;
  public itemLabels$: Observable<ItemLabels>;

  private itemId: string;

  private selectedItem$ = new BehaviorSubject<ItemLabels>(null);

  @Input()
  private process: {id: string};

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter();

  @Input()
  set item(item) {
    if (item && item.id) {
      this.itemId = item.id;
      this.selectedItem$.next(item);
    }
  }

  constructor(private store: Store<AppState>,
              private labelsSvc: LabelService) {
  }

  ngOnInit() {
    this.initSelectedItemLabels$();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.selectedItem$.complete();
  }

  private initSelectedItemLabels$() {
    this.labels$ = this.labelsSvc.sortedLabels;
    this.itemLabels$ = this.selectedItem$.pipe(switchMap(item => this.store.select(ItemLabelsSelectors.getItemLabels(item?.id))));
  }

  public addLabel(label) {
    if (!label.label.id) {
      this.labelsSvc.create(label.label).pipe(
        first()
      ).subscribe((_label: Label) => {
        this.store.dispatch(new LabelActions.CreateSuccess(_label));
        this.store.dispatch(new ItemLabelsActions.Create(this.itemId, _label.id, ItemType.DOCUMENT_ITEM, this.process.id));
      }, err => {
        console.error(err);
      });
      return;

    } else if (label.event.checked && label.label.id) {
      this.store.dispatch(new ItemLabelsActions.Create(this.itemId, label.label.id, ItemType.DOCUMENT_ITEM, this.process.id));
      return;
    } else {
      this.removeLabel(label.label);
    }
  }

  public removeLabel(label) {
    this.store.dispatch(new ItemLabelsActions.Remove(this.itemId, label.id));
  }
}
