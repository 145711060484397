import {IFoxdoxStatus} from '../models/foxdoxStatus.interface';
import {FoxdoxStatus} from '../models/foxdoxStatus';

export namespace FoxdoxStatusBuilder {

  export function fromResponse(response): FoxdoxStatus {
    return new FoxdoxStatus(
      response.id,
      response.attributes.disk_space,
      response.attributes.free,
      response.attributes.used,
      response.attributes.document_count,
      response.attributes.folder_count,
      response.attributes.used_in_human_size
    )
  }

  export function toRequest(foxdox_status: IFoxdoxStatus) {
    const requestFormat = {
      data: {
        attributes: {
          disk_space: foxdox_status.disk_space,
          free: foxdox_status.free,
          used: foxdox_status.used,
          document_count: foxdox_status.document_count,
          folder_count: foxdox_status.folder_count,
        }
      }
    };
    return requestFormat;
  }
}
