import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {CollectorBuilder} from './collector.builder';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {CollectorItemOrderBuilder} from './collector-items-order.builder';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';
import {HttpClient} from '@angular/common/http';
import {Collector} from './collector';
import {ICollectorParams} from './collector.interface';
@Injectable()
export class CollectorService {
  readonly BASE_PATH = 'api/v1/clr';

  constructor(private _http: FivefApiResourceService, private _store: Store<AppState> = null, private _plainHttp:  HttpClient) {
  }

  create(params: ICollectorParams): Observable<Collector> {
    const builder = new CollectorBuilder();
    const payload = {data: {attributes: { title: params.title}}};
    if (params.profile) {
      payload['data']['attributes']['profile'] = params.profile
    }
    const query = params.parentId ? `?parent=${params.parentId}` : '';
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists${query}`, payload);
  }

  publish(id: string): Observable<Collector> {
    const builder = new CollectorBuilder();
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/publish`, {});
  }


  duplicate(id: string, includeTemplates = false, includeDueDates = false): Observable<Collector> {
    const builder = new CollectorBuilder();
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/duplicate?include_templates=${includeTemplates}&include_due_dates=${includeDueDates}`, {});
  }

  prefetch_attachments(id: string): Observable<any> {
    const builder = new CollectorBuilder();
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/prefetch_templates`, {});
  }

  validateBookmanFibu(id: string): Observable<Collector> {
    const builder = new CollectorBuilder();
    return <Observable<Collector>>this._http.get<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/fibu/validate`);
  }

  validateDatevDuo(id: string): Observable<Collector> {
    const builder = new CollectorBuilder();
    return <Observable<Collector>>this._http.get<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/datev_duo/validate`);
  }

  saveDraft(id: string, params: ICollectorParams): Observable<Collector> {
    const builder = new CollectorBuilder();
    const payload = { data: { attributes: params } };
    if (params.dmsAccountType) {
      payload['data']['attributes']['dms_account_type'] =  params.dmsAccountType === DmsAccountType.Organization ? 'organization' : 'private'
    }
    return <Observable<Collector>>this._http.put<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/drafts/${id}`, payload);
  }

  getAll(): Observable<Collector[]> {
    const builder = new CollectorBuilder(this._store);
    return <Observable<Collector[]>>this._http.get<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists`);
  }

  getOne(id: string): Observable<Collector> {
    const builder = new CollectorBuilder(this._store);
    return <Observable<Collector>>this._http.get<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}`);
  }

  updateRecipients(id: string, emails: string[]): Observable<Collector> {
    const builder = new CollectorBuilder();
    const payload = {
      'data': {
        'attributes': {
          'recipients': emails
        }
      }
    };
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/recipients`, payload);
  }

  updateWorkers(id: string, emails: string[]): Observable<Collector> {
    const builder = new CollectorBuilder();
    const payload = {
      'data': {
        'attributes': {
          'workers': emails
        }
      }
    };
    return <Observable<Collector>>this._http.post<CollectorBuilder, Collector>(builder, `${this.BASE_PATH}/checklists/${id}/workers`, payload);
  }

  reorder(id: string, items: any[]) {
    const orderedItems = items.map(item => {
      return {
        id: item.id,
        order: item.order,
        parent_id: item.categoryId
      };
    });

    const builder = new CollectorItemOrderBuilder();
    const payload = {
      data: {
        attributes: {
          nodes: orderedItems
        }
      }
    };
    return <Observable<any>>this._http.post<CollectorItemOrderBuilder, any>(builder, `${this.BASE_PATH}/checklists/${id}/reorders`, payload);
  }
}
