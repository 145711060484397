import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ContactPersonOrganizationAssociation} from './contact-person-organization-association';

export enum ContactPersonOrganizationAssociationActionTypes {
  LoadContactPersonOrganizationAssociationsSuccess = '[contact-person-organization-association] Load SUCCESS assigned contacts of a given contactorganization',
  LoadContactPersonOrganizationAssocitions = '[contact-person-organization-association] Load assigned contacts of a given contactorganization',
  AssignContactPerson = '[contact-person-organization-association] Assign a contact person to this contactorganization',
  AssignContactPersonSuccess = '[contact-person-organization-association] Assign SUCCESS a contact person to this contactorganization',
  UnassignContactPerson = '[contact-person-organization-association] Unassign a contact person from this contactorganization',
  UnassignContactPersonSuccess = '[contact-person-organization-association] Unassign SUCCESS a contact person from this contactorganization',
  ContactPersonOrganizationAssociationFail = '[contact-person-organization-association] HttpError',
}

export class LoadContactPersonOrganizationAssociations implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.LoadContactPersonOrganizationAssocitions;

  constructor(public orgaContactId: string) {
  }
}

export class LoadContactPersonOrganizationAssociationsSuccess implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.LoadContactPersonOrganizationAssociationsSuccess;

  constructor(public payload: ContactPersonOrganizationAssociation[]) {
  }
}

export class AssignContactPerson implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.AssignContactPerson;

  constructor(public contactOrgId: string, public contactPersonId: string) {
  }
}

export class AssignContactPersonSuccess implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.AssignContactPersonSuccess;

  constructor(public payload: ContactPersonOrganizationAssociation) {
  }
}

export class UnassignContactPerson implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.UnassignContactPerson;

  constructor(public payload: ContactPersonOrganizationAssociation) {
  }
}

export class UnassignContactPersonSuccess implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.UnassignContactPersonSuccess;

  constructor(public relId: string) {
  }
}

export class ContactPersonOrganizationAssociationFail implements Action {
  readonly type = ContactPersonOrganizationAssociationActionTypes.ContactPersonOrganizationAssociationFail;

  constructor(public contactToEdit: HttpErrorResponse) {
  }
}

export type ContactPersonOrganizationAssociationActions =
  | LoadContactPersonOrganizationAssociationsSuccess
  | LoadContactPersonOrganizationAssociations
  | AssignContactPerson
  | AssignContactPersonSuccess
  | UnassignContactPerson
  | UnassignContactPersonSuccess
  | ContactPersonOrganizationAssociationFail
