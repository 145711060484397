import {
  SimpleAddress,
  SimpleAddressResponse,
  SimpleEmailAddress,
  SimpleEmailAddressResponse,
  SimplePhoneNumber,
  SimplePhoneNumberResponse
} from '../contact/legacy/models/contact.interface';

export class Organization {
  public address: SimpleAddress;
  public telephone: SimplePhoneNumber;
  public email: SimpleEmailAddress;
  constructor(public id: string,
              public name: string,
              public addressbookId?: string,
              public legalFormId?: string,
  ) {
  }

  public static fromResponse(resp: OrganizationDetailResponse): Organization {
    const foundAddress = resp.included.find(x => x.id === resp.data.relationships.main_address.data.id) as SimpleAddressResponse;

    const orga = new Organization(resp.data.id, resp.data.attributes.name);
    orga.addressbookId = resp.data.relationships.address_book.data.id;
    orga.legalFormId = resp.data.attributes.legal_form_id;

    orga.address = new SimpleAddress();
    orga.address.city = foundAddress.attributes.city;
    orga.address.countryName = foundAddress.attributes.country;
    orga.address.locationOrType = foundAddress.attributes.address_type_id;
    orga.address.street = foundAddress.attributes.street;
    orga.address.streetNo = foundAddress.attributes.street_number;
    orga.address.zip = foundAddress.attributes.post_code;
    orga.address.id = foundAddress.id;

    const foundEmail = resp.included.find(x => x.id === resp.data.relationships.main_email_address.data.id) as SimpleEmailAddressResponse;
    orga.email = new SimpleEmailAddress();
    orga.email.locationOrType = foundEmail.attributes.address_type_id;
    orga.email.emailAddress = foundEmail.attributes.email_address;
    orga.email.id = foundEmail.id;

    const foundPhone = resp.included.find(x => x.id === resp.data.relationships.main_phone_number.data.id) as SimplePhoneNumberResponse;
    orga.telephone = new SimplePhoneNumber();
    orga.telephone.id = foundPhone.id;
    orga.telephone.locationOrType = foundPhone.attributes.address_type_id;
    orga.telephone.countryCode = foundPhone.attributes.country_code;
    orga.telephone.phoneNumber = foundPhone.attributes.phone_number;
    return orga;
  }
}


export interface OrganizationListResponse {
  data: {
    id: string,
    attributes: {
      name: string,
      address_book_id: string,
      legal_form_id: string,
    }
  }[]
}

export interface OrganizationDetailResponse {
  data: {
    id: string,
    attributes: {
      name: string,
      legal_form_id: string,
    },
    relationships: {
      main_address: {
        data: {
          type: string,
          id: string,
        }
      },
      main_email_address: {
        data: {
          type: string,
          id: string,
        }
      },
      main_phone_number: {
        data: {
          type: string,
          id: string,
        }
      },
      address_book: {
        data: {
          type: string,
          id: string,
        }
      }
    },
  },
  included: {
    type: string,
    id: string,
    attributes: any
  }[],
}
