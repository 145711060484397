import {Injectable} from '@angular/core';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {CmsFaqCategory, CmsFaqItem, CmsFaqSection} from './cms-faq';
import {CmsFaqCategoryBuilder, CmsFaqItemBuilder, CmsFaqSectionBuilder} from './cms-faq.builder';
import {Observable} from 'rxjs';

@Injectable()
export class CmsFaqService {
  readonly BASE_PATH = 'api/v1/cms/faq';

  constructor(private _http: FivefApiResourceService) {
  }

  getCategories(): Observable<CmsFaqCategory[]> {
    const builder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory[]>>this._http.get<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/`);
  }

  getCategory(categoryId): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.get<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/${categoryId}`);
  }

  createCategory(category: CmsFaqCategory): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    const payload = builder.toRequest(category);
    return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}`, payload);
  }

  updateCategory(cmsFaqCategory: CmsFaqCategory): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    const payload = builder.toRequest(cmsFaqCategory);
    return <Observable<CmsFaqCategory>>this._http.put<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/${cmsFaqCategory.id}`, payload);
  }

  deleteCategory(cmsFaqCategoryId: string): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/${cmsFaqCategoryId}`);
  }

  createSection(categoryId: CmsFaqCategory, section: CmsFaqSection): Observable<CmsFaqCategory> {
    const builder = new CmsFaqSectionBuilder(categoryId);
    const payload = builder.toRequest(section);
    const responseBuilder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections`, payload);
  }

  updateSection(categoryId: string, section: CmsFaqSection): Observable<CmsFaqCategory> {
    const builder = new CmsFaqSectionBuilder(categoryId);
    const payload = builder.toRequest(section);
    const responseBuilder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.put<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${section.id}`, payload);
  }

  deleteSection(cmsFaqCategoryId: string, sectionId: string): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/${cmsFaqCategoryId}/faq_sections/${sectionId}`);
  }

  createItem(categoryId: string, sectionId: string, item: CmsFaqItem): Observable<CmsFaqCategory> {
    const builder = new CmsFaqItemBuilder(categoryId, sectionId);
    const payload = builder.toRequest(item);
    const responseBuilder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/faq_items`, payload);
  }

  updateItem(categoryId: string, sectionId: string, item: CmsFaqItem): Observable<CmsFaqCategory> {
    const builder = new CmsFaqItemBuilder(categoryId, sectionId);
    const payload = builder.toRequest(item);
    const responseBuilder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.put<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/faq_items/${item.id}`, payload);
  }

  deleteItem(categoryId: string, sectionId: string, itemId: string): Observable<CmsFaqCategory> {
    const builder = new CmsFaqCategoryBuilder();
    return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(builder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/faq_items/${itemId}`);
  }

  publishCategory(categoryId: string, publish: boolean): Observable<CmsFaqCategory> {
    const responseBuilder = new CmsFaqCategoryBuilder();
    if (publish) {
      return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/publish`, {});
    } else {
      return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/publish`);
    }
  }

  publishSection(categoryId: string, sectionId: string, publish: boolean): Observable<CmsFaqCategory> {
    const responseBuilder = new CmsFaqCategoryBuilder();
    if (publish) {
      return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/publish`, {});
    } else {
      return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/publish`);
    }
  }

  publishItem(categoryId: string, sectionId: string, itemId: string, publish: boolean): Observable<CmsFaqCategory> {
    const responseBuilder = new CmsFaqCategoryBuilder();
    if (publish) {
      return <Observable<CmsFaqCategory>>this._http.post<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/faq_items/${itemId}/publish`, {});
    } else {
      return <Observable<CmsFaqCategory>>this._http.del<CmsFaqCategoryBuilder, CmsFaqCategory>(responseBuilder, `${this.BASE_PATH}/${categoryId}/faq_sections/${sectionId}/faq_items/${itemId}/publish`);
    }
  }
}
