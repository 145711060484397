import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {CommentReaction, CommentType} from './comment.interface';
import {Comment, CommentProcessStatistics} from './comment';

/**
 * Builder for comment statistics API.
 * This API returns counts of public comments for certain nodes.
 * A node is e.g. a CAC appendix or a Collecto item.
 *
 * Statistics are saved in the Comment +store as additions stats property.
 * stats is an object with { PROCESS_ID => { [NODE_ID] => count: number }.
 * So each call is scoped by the invocation by a process.
 * Because node IDs and Process IDs are UUID we can also persist the process comment sum
 * as an own node. This helps especially in recursive cases like the CAC matrix
 * to get from the overview process to it's subprocesses.
 *
 * The response of the statistic is as follows:
 *
 * {
 *   "data": {
 *     "id": "6bdd315c-9859-4256-ab91-0a565601d9e7",
 *     "type": "comment_process_statistics",
 *     "attributes": {
 *       "comment_counts": [
 *         {
 *           "node_id": "06ad2d54-b218-403b-9904-83bf1a108b31",
 *           "process_id": "6824009c-26c5-45b3-9290-6e7ea3ef925a",
 *           "comment_count": 16
 *         },
 *         {
 *           "node_id": null,
 *           "process_id": "6824009c-26c5-45b3-9290-6e7ea3ef925a",
 *           "comment_count": 1
 *         },
 *         {
 *           "node_id": "0896bb49-a80b-4022-8345-bcad84a4f5f9",
 *           "process_id": "b470560a-dc64-4ae7-98ac-bc9fcc9d8273",
 *           "comment_count": 1
 *         },
 *         {
 *           "node_id": "6cf79360-5eb0-47b0-aada-97626e1e33c3",
 *           "process_id": "b470560a-dc64-4ae7-98ac-bc9fcc9d8273",
 *           "comment_count": 7
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 * If the node ID is null, then the count can be considered as a public project room comment without
 * CAC Appendix/Collecto element reference.
 */
export class CommentStatisticsBuilder implements IApiResourceBuilder<CommentProcessStatistics> {
  /**
   * Creates the builder with given process ID.
   * @param id
   */
  constructor(public id: string) {
  }

  fromResponse(data): CommentProcessStatistics {
    const attrs = data.attributes;
    const stats = new CommentProcessStatistics(this.id);
    attrs.comment_counts.forEach(stat => {
      stats.addNodeCommentCount(stat.process_id, stat.node_id, stat.comment_count);
    });
    return stats;
  }

  toRequest(_stats: CommentProcessStatistics) {
    return null;
  }
}

export class CommentBuilder implements IApiResourceBuilder<Comment> {
  eventSeenByMap = {};
  reactionsMap = {};

  addIncludedSection(includes) {
    if (includes && includes.length) {
      includes.forEach(include => {
        if (include.type === 'comment_reactions') {
          this.reactionsMap[include.id] = {
            email: include.attributes.user_uid,
            reactionType: include.attributes.reaction_type
          };
        } else if (include.type === 'comment_seen_bys') {
          this.eventSeenByMap[include.id] = include.attributes.user_uid;
        }
      });
    }
  }

  constructor(private _type: CommentType = CommentType.InstantMessage) {
  }

  fromResponse(data): Comment {
    const attrs = data.attributes;
    const comment = new Comment(
      data.id,
      this._type,
      attrs.commentable_id || attrs.process_id,
      attrs.author || attrs.author_email,
      attrs.message || attrs.content,
      attrs.created_at,
      attrs.updated_at);
    comment.processId = attrs.process_id;
    comment.backtrackId = attrs.backtrack_id;

    comment.replyTo = attrs.reply_to;
    comment.replyToId = attrs.reply_to_id;
    comment.replyToDate = attrs.reply_to_date;
    comment.replyToAuthor = attrs.reply_to_author;

    if (data.relationships && data.relationships.seen_bys && data.relationships.seen_bys.data) {
      data.relationships.seen_bys.data.forEach(seenBy => {
        comment.seenBy.push(this.eventSeenByMap[seenBy.id]);
      });
    }

    if (data.relationships && data.relationships.reactions && data.relationships.reactions.data) {
      data.relationships.reactions.data.forEach(reaction => {
        const r: CommentReaction = this.reactionsMap[reaction.id]
        if (!comment.reactionMap[r.reactionType]) {
          comment.reactionMap[r.reactionType] = [];
        }
        comment.reactionMap[r.reactionType].push(r.email);
        comment.reactions.push(r);
        comment.reactionList = Object.keys(comment.reactionMap).map((idx) => {
          return {reaction: idx, performers: comment.reactionMap[idx]};
        });
      });
    }

    return comment;
  }

  toRequest(comment: Comment) {
    return {
      data: {
        id: comment.id,
        type: CommentType.Comment,
        attributes: {
          content: comment.message,
          backtrack_id: comment.backtrackId,
          reply_to: comment.replyToId
        }
      }
    };
  }
}
