<form #f="ngForm" class="fivef-mat-table fivef-csv-import-display">
  <table #table>
    <cdk-virtual-scroll-viewport style="height: 500px;" [itemSize]="25" autosize>
      <thead>
      <tr>
        <th class="dvtx-import-column-header-info">Info</th>
        <ng-container *ngFor="let column of _columns;">
          <th class="dvtx-import-column-header dvtx-import-column-header-{{column.key}}">{{ column.title }}</th>
        </ng-container>
      </tr>
      </thead>
      <tbody>
      <tr *cdkVirtualFor="let row of dataSource.data; index as j;templateCacheSize: 0;">
        <td class="text-truncate dvtx-import-column-header-info" [class.has-error]="row.errors?.length"
            (click)="dump()">
          <ng-container *ngFor="let error of row.errors" [ngSwitch]="error">
            <dvtx-hint *ngSwitchCase="csvErrors.QUOTE_MISMATCH"
                       hint="Diese Zeile enthält keine schließenden Anführungszeichen" position="after"></dvtx-hint>
            <dvtx-hint *ngSwitchCase="csvErrors.TOO_FEW_ITEMS" hint="Zu wenige Einträge in dieser Zeile"
                       position="after"></dvtx-hint>
            <dvtx-hint *ngSwitchCase="csvErrors.TOO_MANY_ITEMS" hint="Zu viele Einträge in dieser Zeile"
                       position="after"></dvtx-hint>
            <dvtx-hint *ngSwitchCase="csvErrors.ONE_OR_MORE_REQUIRED_CELLS_MISSING"
                       hint="Benötigte Einträge in dieser Zeile fehlen" position="after"></dvtx-hint>
            <dvtx-hint *ngSwitchCase="csvErrors.ONE_OR_MORE_EMAILS_INVALID" hint="E-Mail Adresse ist nicht gültig"
                       position="after"></dvtx-hint>
            <dvtx-hint *ngSwitchDefault [hint]="error" position="after"></dvtx-hint>
          </ng-container>
          <dvtx-hint *ngIf="!!f.controls[j]?.invalid" hint="Ungültige Werte in dieser Zeile"
                     position="after"></dvtx-hint>
        </td>
        <ng-container *ngFor="let column of _columns; let i = index">
          <td class="name-td text-truncate dvtx-import-column dvtx-import-column-{{column.key}}"
              [class.required-but-missing]="column.isRequired && !column.editType && !((row.data[i] || '').trim())">
            <ng-container>
              <ng-container [ngSwitch]="column.editType" [ngModelGroup]="j + ''">
                <ng-container *ngSwitchCase="'email'">
                  <mat-form-field floatLabel="never" fivefDisableInputHints>
                    <input type="email"
                           [name]="i + ''"
                           matInput
                           (click)="click()"
                           [placeholder]="placeholder"
                           [ngModel]="row.data[i]"
                           (ngModelChange)="valueChange($event, i, row)"
                           [required]="column.isRequired"
                           [email]="true"
                           [matTooltip]="row.data[i]"
                           [errorStateMatcher]="matcher">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'string'">
                  <mat-form-field floatLabel="never" fivefDisableInputHints>
                    <input type="text"
                           [name]="i + ''"
                           matInput
                           (click)="click()"
                           [placeholder]="placeholder"
                           [ngModel]="row.data[i]"
                           (ngModelChange)="valueChange($event, i, row)"
                           [ngModelOptions]="{updateOn: 'blur'}"
                           [required]="column.isRequired"
                           [matTooltip]="row.data[i]"
                           [pattern]="column.isNumber ? '-?((\\d{1,3}(?:\\.\\d{3})*(?:,\\d+)?)|(\\d{1,3}(?:,\\d{3})*(?:\\.\\d+)?))' : '.*'"
                           [errorStateMatcher]="matcher">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                  <mat-form-field floatLabel="never" fivefDisableInputHints>
                    <input matInput
                           [name]="i + ''"
                           (click)="click()"
                           [matDatepicker]="datepicker"
                           [placeholder]="placeholder"
                           [ngModel]="row.data[i]"
                           (ngModelChange)="valueChange($event, i, row)"
                           [ngModelOptions]="{updateOn: 'blur'}"
                           [required]="column.isRequired"
                           [errorStateMatcher]="matcher">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row.data[i] }}
                </ng-container>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </cdk-virtual-scroll-viewport>
  </table>
</form>
