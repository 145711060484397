import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BookmanClientActionTypes, LoadAll, LoadAllFail, LoadAllSuccess,} from './bookman-client.actions';
import {BookmanClient} from './bookman-client';
import {BookmanClientService} from './bookman-client.service';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class BookmanClientEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(BookmanClientActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: BookmanClient[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  refresh$ = createEffect(() => this.actions.pipe(
    ofType(BookmanClientActionTypes.Refresh),
    switchMap((action: LoadAll) => {
      return this._svc.refresh().pipe(
        first(),
        concatMap((res: BookmanClient[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
    private _svc: BookmanClientService,
    private _router: Router,
    private _translateSvc: TranslateService,
    private _notifyService: FivefNotificationService) {
  }
}
