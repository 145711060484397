import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FivefIconComponent} from '../../common/fivef-icon/fivef-icon.component';

@Component({
  selector: 'fivef-icon-message-block',
  standalone: true,
  host: {'class': 'fivef-icon-message-block'},
  imports: [CommonModule, MatIconModule, TranslateModule, FivefIconComponent],
  templateUrl: './fivef-icon-message-block.component.html',
  styleUrls: ['./fivef-icon-message-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefIconMessageBlockComponent {
  @Input()
  icon;

  @Input()
  message = '';

  @Input()
  alignment: 'vertical' | 'horizontal' = 'vertical';
}
