import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {FivefAvatarModule} from '../../../profile/fivef-avatar/fivef-avatar.module';

@Component({
  selector: 'fivef-seenby',
  host: {'class': 'fivef-seenby'},
  templateUrl: './fivef-seenby.component.html',
  styleUrls: ['./fivef-seenby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefSeenbyComponent {
  public showDropdown = false;

  @Input()
  showAvatarTitle;

  @Input()
  showTitle = false;

  @Input()
  title;

  @Input()
  icon;

  @Input()
  persons = [];
}
