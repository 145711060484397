import {Injectable} from '@angular/core';
import {AngularTokenService} from 'angular-token';
import {EnvService} from 'app/lib/fivef-net/fivef-api-resource/services/env.service';
import {catchError, map} from 'rxjs/operators';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {TwoFactorMemberStatusBuilder, TwoFactorSessionBuilder} from './two-factor-session.builder';
import {TwoFactorMemberStatus, TwoFactorSession} from './two-factor-session';
import {Observable, of} from 'rxjs';
import {TwoFactorStatus} from './two-factor-session.interface';

@Injectable()
export class TwoFactorSessionService {
  readonly BASE_PATH = '/api/v1/auth/two_factor_sessions';
  readonly TWO_FACTOR_MEMBER_STATUS_BASE_PATH = '/api/v1/organizations/two_factor_member_status';
  apiUrl: string;

  constructor(private _tokenSvc: AngularTokenService,
              private _http: FivefApiResourceService,
              private env: EnvService) {
    this.apiUrl = env.apiBase();
  }

  memberStatus() {
    const builder = new TwoFactorMemberStatusBuilder();
    return <Observable<TwoFactorMemberStatus[]>>this._http.get<TwoFactorMemberStatusBuilder, TwoFactorMemberStatus>(builder, `${this.TWO_FACTOR_MEMBER_STATUS_BASE_PATH}`)
  }

  verify() {
    const builder = new TwoFactorSessionBuilder();
    return <Observable<TwoFactorSession>>this._http.get<TwoFactorSessionBuilder, TwoFactorSession>(builder, `${this.BASE_PATH}`)
      .pipe(
        catchError(_err => {
          const session = new TwoFactorSession(this._tokenSvc.currentAuthData.uid, null);
          session.status = TwoFactorStatus.Denied;
          return of(session);
        })
      );
  }

  create(session) {
    const builder = new TwoFactorSessionBuilder();
    const payload = builder.toRequest(session);
    return <Observable<TwoFactorSession>>this._http.post<TwoFactorSessionBuilder, TwoFactorSession>(builder, `${this.BASE_PATH}`, payload);
  }
}
