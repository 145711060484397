import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess} from './verified-user.actions';
import {ContactActionTypes} from 'app/+store/contact/contact.actions';
import {VerifiedUserService} from './verified-user.service';
import {VerifiedUser} from './verified-user';
import {TranslateService} from '@ngx-translate/core';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';

@Injectable()
export class VerifiedUserEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ContactActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        map((res: VerifiedUser[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  ));

  constructor(private actions: Actions,
              private _svc: VerifiedUserService,
              private _translateSvc: TranslateService,
              private _notifyService: FivefNotificationService) {
  }
}




