import {startWith, filter, map, takeUntil, distinctUntilKeyChanged} from 'rxjs/operators';
import {Component, forwardRef, Injector, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FivefControlValueAccessor} from '../../../../../../lib/fivef-ui/input/fivef-control-value-accessor.directive';
import {Observable, combineLatest, Subject} from 'rxjs';
import {ContactListDto, contactListDtoType} from '../../../../../../+store/contact/contact';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../app.state';
import {ContactSelectors} from '../../../../../../+store/contact';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {LoadAll} from '../../../../../../+store/contact/contact.actions';
import {OrganizationSelectors} from '../../../../../../+store/organization';

@Component({
  selector: 'dvtx-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactSelectComponent),
      multi: true,
    }
  ]
})
export class ContactSelectComponent extends FivefControlValueAccessor implements OnInit, OnChanges, OnDestroy {
  private onDestroy = new Subject();

  myControl = new UntypedFormControl();
  filteredOptions: Observable<ContactListDto[]>;

  @Input()
  filterType?: contactListDtoType;

  @Input()
  valueIsEmail: boolean = false;

  @Input()
  excludedIds: string[] = [];

  @Input()
  skipIfHasAccount = true;

  @Output()
  onReset = new EventEmitter();

  @Input()
  fetchContactsFromStore = false;

  constructor(protected injector: Injector,
              private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(OrganizationSelectors.getSelected).pipe(filter(o => !!o), takeUntil(this.onDestroy)).subscribe((organization) => {
      if (!this.fetchContactsFromStore)
        this.store.dispatch(new LoadAll(organization))
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes) {
    if (changes.excludedIds) {
      this.excludedIds = changes.excludedIds.currentValue;
      this.filteredOptions =
        combineLatest(
          this.myControl.valueChanges.pipe(startWith('')),
          this.store.select(ContactSelectors.getFilteredContactsOfSelectedOrg(this.filterType, this.excludedIds, this.skipIfHasAccount)),
        ).pipe(
          map(([value, contacts]: [string, ContactListDto[]]) => {
            const filteredContacts = this._filter(value, contacts);
            return filteredContacts
          })
        );
    }
  }

  private _filter(value: string, contacts: ContactListDto[]): ContactListDto[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return contacts.filter((entry: ContactListDto) => {
        return entry.name.toLowerCase().indexOf(filterValue) >= 0
          || entry.email.toLowerCase().indexOf(filterValue) >= 0
      });
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
  }

  reset() {
    this.writeValue(null);
    this.notifyOnChange(null);
    this.onReset.emit();
  }

  contactSelected($event: MatAutocompleteSelectedEvent) {
    if (this.valueIsEmail) {
      this.notifyOnChange($event.option.value.email)
    } else {
      this.notifyOnChange($event.option.value)
    }
  }

  // Used to display the text in the input field.
  displayFn(contact: ContactListDto): string {
    return contact ? contact.name : '';
  }

  onBlurEventHandler() {
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.notifyOnChange(this.myControl.value);
    } else if (this.valueIsEmail) {
      this.notifyOnChange((this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value);
    }
  }

}
