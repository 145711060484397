import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {CollectorAttachment} from './collector-attachment';
import {CollectorAttachmentBuilder} from './collector-attachment.builder';

@Injectable()
export class CollectorAttachmentService {
  readonly BASE_PATH = 'api/v1/clr/checklists';

  constructor(private _http: FivefApiResourceService) {}

  getAll(collectorId: string): Observable<CollectorAttachment[]> {
    const builder = new CollectorAttachmentBuilder();
    return <Observable<CollectorAttachment[]>>this._http.get<CollectorAttachmentBuilder, CollectorAttachment>(builder, `${this.BASE_PATH}/${collectorId}/attachments`);
  }

  remove(collectorId: string, attachmentId: string): Observable<CollectorAttachment> {
    const builder = new CollectorAttachmentBuilder();
    return <Observable<CollectorAttachment>>this._http.del<CollectorAttachmentBuilder, CollectorAttachment>(builder, `${this.BASE_PATH}/${collectorId}/attachments/${attachmentId}`);
  }

  download(collectorId: string, attachmentId: string) {
    const builder = new CollectorAttachmentBuilder();
    return <Observable<CollectorAttachment>>this._http.get<CollectorAttachmentBuilder, CollectorAttachment>(builder, `${this.BASE_PATH}/${collectorId}/attachments/${attachmentId}`);
  }

  pickFileFromDms(processId: string, nodeId: string, dmsDocumentId: string, accountType): Observable<CollectorAttachment> {
    const builder = new CollectorAttachmentBuilder();
    const payload = {
      data: {
        attributes: {
          dms_document_id: dmsDocumentId,
          account_type: accountType
        }
      }
    };
    return <Observable<CollectorAttachment>>this._http.post<CollectorAttachmentBuilder, CollectorAttachment>(builder, `api/v1/clr/checklists/${processId}/nodes/${nodeId}/attach_file_from_dms`, payload);
  }
}
