import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum TaskAssignmentStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Completed = 'COMPLETED'
}

export enum TaskAssignmentStatusTitle {
  PENDING = 'TASK.STATUS.PENDING',
  ACCEPTED = 'TASK.STATUS.ACCEPTED',
  REJECTED = 'TASK.STATUS.REJECTED',
  APPROVED = 'TASK.STATUS.APPROVED',
  DENIED = 'TASK.STATUS.DENIED',
  COMPLETED = 'TASK.STATUS.COMPLETED'
}

export enum TaskAssignmentStatusDateTitle {
  PENDING = 'TASK.ASSIGNEE_STATUS_DATE.PENDING',
  ACCEPTED = 'TASK.ASSIGNEE_STATUS_DATE.ACCEPTED',
  REJECTED = 'TASK.ASSIGNEE_STATUS_DATE.REJECTED',
  APPROVED = 'TASK.ASSIGNEE_STATUS_DATE.APPROVED',
  DENIED = 'TASK.ASSIGNEE_STATUS_DATE.DENIED',
  COMPLETED = 'TASK.ASSIGNEE_STATUS_DATE.COMPLETED'
}

export interface ICreateTaskAssigneeParams {
  taskId: string;
  email: string;
}

export interface ITaskAssignee extends IResource {
  email: string;
  firstName: string;
  lastName: string;
  status: TaskAssignmentStatus;
  taskId: string;
  createdAt: Date;
  updatedAt: Date;
}
