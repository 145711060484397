import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum TaskResourceType {
  Document = 'document',
  CollectorNode = 'collector_node',
  Appendix = 'appendix'
}

export interface ITaskResource extends IResource {
  title: string;
  description: string;
  creatorEmail: string;
  resourceId: string;
  resourceType: TaskResourceType;
  createdAt: Date;
  updatedAt: Date;
}
