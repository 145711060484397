import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DEFAULT_SORTING_OPTIONS, IProcessTreeSettings, ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {ProcessActions} from 'app/+store/process';
import {Net} from 'app/lib/fivef-net/uuid';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';
import {distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {ProcessTreeService} from 'app/+store/process-tree/process-tree.service';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioChange, MatRadioModule} from '@angular/material/radio';
import {AngularTokenService} from 'angular-token';
import {Observable} from 'rxjs/internal/Observable';
import {IFivefResourceTreeNode} from '../../tree/fivef-tree/fivef-tree.interface';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {FivefTreeComponent} from '../../tree/fivef-tree/fivef-tree.component';
import {FivefProcessTreeNodeComponent} from '../fivef-process-tree-node/fivef-process-tree-node.component';
import {FivefTreeNodeDevDirective} from '../../tree/fivef-tree/fivef-tree-node-dev.directive';

@Component({
  selector: 'fivef-process-tree-sidebar-widget',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, TranslateModule, MatMenuModule, MatIconModule, MatRadioModule, MatCheckboxModule, FivefSearchComponent, FivefTreeComponent, FivefProcessTreeNodeComponent, FivefTreeNodeDevDirective],
  templateUrl: './fivef-process-tree-sidebar-widget.component.html',
  styleUrls: ['./fivef-process-tree-sidebar-widget.component.scss']
})
export class FivefProcessTreeSidebarWidgetComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  protected _id: string;

  private processesNodes$: Observable<ProcessTreeNode[]>;
  public nodes$ = new BehaviorSubject<IFivefResourceTreeNode[]>([]);

  private refresh$ = new BehaviorSubject<string>(null);

  public settings: IProcessTreeSettings;
  public processSortingBy = DEFAULT_SORTING_OPTIONS;
  selectedProcessSortingBy = this.processSortingBy[0];

  public searchTerm: string = null;

  @Input() set processId(id: string) {
    this._id = id;
    if (Net.validUUID(id)) {
      this.refresh$.next(id);
    }
  }

  @Input() set refresh(refresh: any) {
    if (refresh) {
      this.updateTree();
    }
  }

  constructor(private _store: Store<AppState>,
              private _svc: ProcessTreeService,
              private _cdr: ChangeDetectorRef,
              private _tokenSvc: AngularTokenService,
              private _ngZone: NgZone) {
    this.refresh$
      .pipe(filter(id => !!id), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe((id) => {
        if (Net.validUUID(id)) {
          this._fetchNodes(id)
        }
      });
  }

  ngOnInit() {
    this.processesNodes$ = this._svc.processesNodes;
    this._svc.init(this._id);

    this._svc.currentSettings$.pipe(takeUntil(this.onDestroy)).subscribe(settings => {
      this.settings = settings;
      this._cdr.detectChanges();
    });

    const userUid = this._tokenSvc.currentAuthData.uid;
    this._svc.refresh(userUid);

    this.processesNodes$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(pNodes => {
        this.nodes$.next(this.buildTreeFrom(pNodes));
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.refresh$.complete();
    this.nodes$.complete();
    this._svc.detach();
  }

  private _fetchNodes(id) {
    this._svc.refreshProjectStructure(id);
  }

  public updateTree(): void {
    if (this._id) {
      this._fetchNodes(this._id);
    }
  }

  public toggleClosed(event: MatCheckboxChange) {
    const s = Object.assign({}, this.settings, {hideClosed: event.checked})
    this._svc.updateSettings(s)
      .pipe(first())
      .subscribe(_settings => {
        this._fetchNodes(this._id);
      });
  }

  public changeSortingBy($event: MatRadioChange) {
    const value = DEFAULT_SORTING_OPTIONS.find(opt => opt.id === $event.value);
    const s = Object.assign({}, this.settings, {sortBy: value.sortBy, sortDirection: value.sortDirection})
    this._svc.updateSettings(s)
      .pipe(first())
      .subscribe(_settings => {
        this._fetchNodes(this._id);
      });
  }

  /**
   * Creates a fivef-tree model.
   *
   * @param processNodes
   * @private
   */
  private buildTreeFrom(processNodes: ProcessTreeNode[]): IFivefResourceTreeNode[] {
    return ProcessTreeNode.buildTreeFrom(processNodes);
  }

  /**
   * Sets the searchTerm to be used as input of the search.
   *
   * @param $event
   */
  public applySearch($event: string) {
    this.searchTerm = $event;
    this._cdr.detectChanges();
  }

  public selectNode(node) {
    console.error(node);
    if (!node || node && node.id === this._id) return;
    this._store.dispatch(new ProcessActions.RunCommand(node.id, 'index', 'on_click'));
  }
}
