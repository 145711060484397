import {IFivefConfirmData} from './fivef-confirm.interface';
import {FivefDialogService} from '../../common/fivef-dialog/fivef-dialog.service';
import {FivefConfirmComponent} from './fivef-confirm.component';

/**
 * Confirm decorator for methods.
 *
 * Example usage:
 *
 * @FivefConfirm({
 *   message: 'LABELS.DELETE_LABEL',
 *   icon: 'warning',
 *   color: 'warn',
 *   confirmAction: 'GENERAL.DELETE_ACTION'
 * })
 * deleteLabel(label) {
 *   this._store.dispatch(new LabelActions.Remove(label.id));
 * }
 *
 * @param confirmData
 * @constructor
 */
export function FivefConfirm(confirmData: IFivefConfirmData) {
  return function (_target: Object, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any) {
      FivefDialogService
        .getInstance()?.openConfirmDialog(confirmData, FivefConfirmComponent)
        .subscribe((result) => {
          if (result) {
            originalMethod.apply(this, args);
          }
        });
    };
    return descriptor;
  };
}
