import {IResource} from '../../../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {ContactImportItem} from './contact-import-item';


export class AuditContactVerificationProcess implements IResource {
  readonly type = 'audit_contact_verification_process';

  constructor(
    public id: string,
    public status: string,
    public dueDate: Date,
    public receivedAt: string,
    public items: ContactImportItem[]
  ) {

  }

  toForm() {
    return undefined;
  }
}

