import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ExternalAccess} from './external-access';

export class ExternalAccessBuilder implements IApiResourceBuilder<ExternalAccess> {
  constructor(private _processId) {
  }

  fromResponse(data): ExternalAccess {
    const ea = new ExternalAccess(
      data.id,
      this._processId,
      data.attributes.comment,
      data.attributes.version,
      data.attributes.url,
      data.attributes.upload_enabled,
      data.attributes.comment_enabled,
      data.attributes.created_at,
      data.attributes.updated_at);

    ea.senderName = data.attributes.sender_name;
    ea.senderEmail = data.attributes.sender_email;
    ea.recipientEmail = data.attributes.recipient_email;
    ea.recipientFirstName = data.attributes.recipient_first_name;
    ea.recipientLastName = data.attributes.recipient_last_name;
    ea.documentIds = data.attributes.documents;
    ea.passphrase = data.attributes.passphrase;
    ea.expiresAt = data.attributes.expires_at;
    return ea;
  }

  toRequest(externalAccess: ExternalAccess) {
    return {
      data: {
        id: externalAccess.id,
        type: externalAccess.type,
        attributes: {
          comment: externalAccess.comment,
          upload_enabled: externalAccess.uploadEnabled,
          comment_enabled: externalAccess.commentEnabled,
          document_ids: externalAccess.documentIds,
          email: externalAccess.recipientEmail,
          first_name: externalAccess.recipientFirstName,
          last_name: externalAccess.recipientLastName,
        }
      }
    };
  }
}
