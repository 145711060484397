import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorItemLookupActionTypes,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  FetchOne,
  SkipFetching
} from './collector-item-lookup.actions';
import {CollectorItemLookupService} from './collector-item-lookup.service';
import {CollectorLookupItem} from './collector-item-lookup';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {CollectorItemLookupSelectors} from 'app/+store';

/**
 * Collecto item lookup effects for Collecto comment references inside Project/Project Room.
 *
 * Concept: First event dispatches if an item needs to be fetched (FetchOne) or is already present
 * (SkipFetching).
 * Second event really fetches based on former decision.
 */
@Injectable()
export class CollectorItemLookupEffects {
  /**
   * Dispatcher to distinguish already fetched items or items in fetching state and
   * new requests.
   * If the fetch status of an item is already truthy skip the API request.
   */
  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemLookupActionTypes.LoadOne),
    withLatestFrom(this.store.select(CollectorItemLookupSelectors.getFetchStatus)),
    switchMap(([action, fetchStatus]: [LoadOne, {[itemId: string]: boolean}]) => {
      // If item is already in fetching state, skip API request.
      if (fetchStatus[action.itemId]) {
        return of(new SkipFetching());

      } else {
        return of(new FetchOne(action.processId, action.itemId));
      }
    })
  ));

  /**
   * Fetching effect if item is not already present in cache or if
   * item is not in fetching state.
   */
  fetchOne$ = createEffect(() => this.actions.pipe(
    ofType(CollectorItemLookupActionTypes.FetchOne),
    concatMap((action: FetchOne) => {
      return this.svc.lookup(action.processId, action.itemId).pipe(
        first(),
        concatMap((item: CollectorLookupItem) => {
          return [new LoadOneSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private store: Store<AppState>,
              private svc: CollectorItemLookupService) {
  }
}
