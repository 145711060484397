import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {startWith, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefInfoBlockComponent} from '../../util/fivef-info-block/fivef-info-block.component';

@Component({
  selector: 'fivef-platform-error',
  standalone: true,
  host: {'class': 'fivef-platform-error'},
  templateUrl: './fivef-platform-error.component.html',
  imports: [
    CommonModule,
    FivefInfoBlockComponent,
    FivefIconMessageBlockComponent
  ],
  styleUrls: ['./fivef-platform-error.component.scss']
})
export class FivefPlatformErrorComponent implements OnInit, OnDestroy {
  @Input()
  public error: any;

  public lang = 'de';
  private onDestroy = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef,
              private i18nSvc: TranslateService) {
  }

  ngOnInit() {
    this.i18nSvc.onLangChange
      .pipe(
        startWith({lang: this.i18nSvc.currentLang}),
        takeUntil(this.onDestroy))
      .subscribe(lang => {
        this.lang = lang.lang;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
