import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';
import * as store from 'store';
import {I18n} from 'app/lib/i18n/constants';
import {EnvService} from '../lib/fivef-net/fivef-api-resource/services/env.service';

@Injectable()
export class XTenantInterceptor implements HttpInterceptor {
  tenantDomain: string;
  private fileApiBase: string = '';
  private customerApiBase: string = '';

  constructor(private envSvc: EnvService) {
    this.tenantDomain = window.location.hostname;
    this.fileApiBase = this.envSvc.resumableUploadApiBase();
    this.customerApiBase = this.envSvc.customerApiBase();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiCall = req.url.startsWith(environment.token_service_config.apiBase)
      || req.url.startsWith(this.customerApiBase)
      || req.url.startsWith(this.fileApiBase);

    let lang = I18n.DEFAULT_LANG;
    try {
      const _lang = store.get(I18n.LANG_KEY);
      if (_lang) {
        lang = _lang;
      }
    } catch (err) {
      console.error('Cannot fetch current language from store');
    }
    const i18nReq = req.clone({ setHeaders: { 'Accept-Language': lang } });

    if (isApiCall) {
      const authReq = i18nReq.clone({ setHeaders: { 'X-TENANT-DOMAIN': this.tenantDomain, 'Content-Type': 'application/json' } });
      return next.handle(authReq);
    }
    const jsonReq = i18nReq.clone({ setHeaders: { 'Content-Type': 'application/json' } });
    return next.handle(jsonReq);
  }
}
