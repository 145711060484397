import * as processContextActions from '../actions/process-context.actions';
import { ProcessContext } from '../../../+store/_legacy/api/models/process-context';

export type ProcessContextState = ProcessContext;
export const initialState: ProcessContextState = null;

/**
 * Reducer for the process context.
 * The process context contains information how to proceed in a process in which way (by command
 * objects), who has access as well as other meta information around process management.
 *
 * @param {ProcessContextState} state
 * @param {Actions} action
 * @returns {ProcessContextState}
 * @constructor
 */
export function processContextReducer(state: ProcessContextState = initialState,
                                      action: processContextActions.Actions): ProcessContextState {

  switch (action.type) {
    case processContextActions.RUN_COMMAND_SUCCESS:
      if (state.getQueue(action.payload.hook).length === 0) {
        return state;
      }
      const newState = state.clone();
      newState.setQueue(action.payload.hook, action.payload.queue);
      return newState;

    case processContextActions.CREATE_PROCESS_CONTEXT_SUCCESS:
      return action.payload;

    case processContextActions.GET_PROCESS_CONTEXT_SUCCESS:
      return action.payload;

    case processContextActions.GET_EXTERNAL_PROCESS_CONTEXT_SUCCESS:
      return action.payload;

    case processContextActions.NEW_PROCESS_CONTEXT:
      return new ProcessContext(action.payload.workflow, action.payload.revision);

    case processContextActions.SET_PROCESS_CONTEXT_SUCCESS:
      return action.payload;

    case processContextActions.UPDATE_EXTERNAL_PROCESS_CONTEXT_SUCCESS:
      return action.payload;

    case processContextActions.VALIDATE_ACCESS_SUCCESS:
      return action.payload;

    case processContextActions.RESET_PROCESS_CONTEXT:
      return initialState;

    default: {
      return state;
    }
  }
};
