import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {DavContactBuilder} from './dav-contact.builder';
import {DavContact} from './dav-contact';

@Injectable()
export class DavContactService {
  readonly BASE_PATH = 'api/v1/dav/contacts';

  constructor(private _http: FivefApiResourceService) {}

  getAll(): Observable<DavContact[]> {
    const builder = new DavContactBuilder();
    return <Observable<DavContact[]>>this._http.getAll<DavContactBuilder, DavContact>(builder, `${this.BASE_PATH}`);
  }
}
