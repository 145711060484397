import {Directive, HostBinding} from '@angular/core';

/**
 * Directive to disable the hint wrapper area in form fields.
 */
@Directive({
  selector: '[fivefDisableInputHints]',
  standalone: true
})
export class FivefDisableInputHintsWrapperDirective {
  @HostBinding('class')
  disableHintWrapperClass = 'fivef-disable-input-hints-wrapper';
}
