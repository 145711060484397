<button cdk-overlay-origin
        (click)="showDropdown = !showDropdown"
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false"
        #dropdown="cdkOverlayOrigin"
        [matTooltip]="!title ? title : ''"
        [matTooltipPosition]="'above'"
        class="fivef-seenby--button"
        [class.fivef-seenby--button--active]="persons?.length > 0">
  <mat-icon *ngIf="icon && icon === 'supervised_user_circle'"
            svgIcon="supervised_user_circle"></mat-icon>
  <mat-icon *ngIf="icon && icon !== 'supervised_user_circle'">{{ icon }}</mat-icon>
  <ng-container *ngIf="title && showTitle">
    <span class="fivef-seenby--button--title">
      {{ title }}
    </span>
  </ng-container>
</button>

<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-auto-size-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown"
             (backdropClick)="showDropdown = false">
  <div class="fivef-seenby--menu" *ngIf="persons?.length">
    <ng-container *ngFor="let person of persons">
      <fivef-avatar class="fivef-seenby--menu--listing-item"
                    [size]="'xs'"
                    [email]="person"
                    [showTitle]='showAvatarTitle'></fivef-avatar>
    </ng-container>
  </div>
</ng-template>
