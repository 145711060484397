<div class="fivef-email-address-group-cva">
  <div *ngFor="let emailAddress of emailAddresses" class="fivef-email-address-group-cva--email">
    <dvtx-email-address-input
      [innerValue]="emailAddress"
      [disabled]="disabled"
    ></dvtx-email-address-input>

    <div class="d-flex align-items-end" *ngIf="!disabled">
      <button mat-button [disabled]="disabled" (click)="saveEmail(emailAddress)" *ngIf="!emailAddress.id">
        <mat-icon>done</mat-icon>
        {{'GENERAL.SAVE_ACTION' | translate}}
      </button>
      <button mat-button [disabled]="disabled" (click)="deleteEmail(emailAddress)">
        <mat-icon>remove_circle_outline</mat-icon>
        {{'GENERAL.REMOVE_ACTION' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="!disabled && showAddEmail">
    <button mat-button [disabled]="disabled" type="button" (click)="addNewEmailField()"
            [disableRipple]="true">
      <mat-icon>add</mat-icon>
      {{ 'CONTACTS.ADD_EMAIL' | translate }}
    </button>
  </div>
</div>
