import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {FibuProcess} from './fibu-process';
import {FibuProcessBuilder} from './fibu-process.builder';

@Injectable()
export class FibuProcessService {
  readonly BASE_PATH = 'api/v1/fibu/fibu_folders';

  constructor(private _http: FivefApiResourceService) {
  }

  getAll(): Observable<FibuProcess[]> {
    const builder = new FibuProcessBuilder();
    return <Observable<FibuProcess[]>>this._http.get<FibuProcessBuilder, FibuProcess>(builder, `${this.BASE_PATH}`);
  }

  loadAll(ids: string[]): Observable<FibuProcess[]> {
    const builder = new FibuProcessBuilder();
    const payload = {
      data: {
        attributes: {
          ids
        }
      }
    }
    return <Observable<FibuProcess[]>><unknown>this._http.post<FibuProcessBuilder, FibuProcess>(builder, `${this.BASE_PATH}/lookup`, payload);
  }

}
