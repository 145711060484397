import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {ContactPersonBaseForm, newContactBaseForm} from '../../../../../modules/organization/models/person-contact-base-form';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppState} from '../../../../../app.state';
import {Store} from '@ngrx/store';
import {ContactVisibility, visibilityTypes} from '../../../../../+store/contact/contact';
import {NewContact} from '../../../../../+store/contact/contact.actions';
import {first} from 'rxjs/operators';
import {Organization} from '../../../../../+store/organization/organization';
import {getSelected} from '../../../../../+store/organization/organization.selectors';
import {InviteService} from '../../../../../modules/organization/components/organization-view/invite.service';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {PartnerLinkParticipationActions} from 'app/+store/partner-link-participation';

@Component({
  selector: 'fivef-create-contact-dialog',
  templateUrl: './fivef-create-contact-dialog.component.html',
  styleUrls: ['./fivef-create-contact-dialog.component.scss']
})
export class FivefCreateContactDialogComponent implements OnInit {
  types = [
    {label: 'Person', value: contactTypes.naturalPerson, icon: 'person', tooltip: 'Kontaktperson anlegen.'}
  ];
  public color = 'var(--fivef-color-action)';
  contactType: contactTypes = contactTypes.organization;
  contactTypes = contactTypes;
  contactVisibility: ContactVisibility = {visibleFor: '', type: visibilityTypes.visiblePublic};
  lockContactType = false;

  orgaTypeId: string = '';

  createContactForm: ContactPersonBaseForm = newContactBaseForm();

  // TODO: Reset the form after a contact is created.
  furtherContact: boolean = false;

  public hideInviteButton = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private mdr: MatDialogRef<FivefCreateContactDialogComponent>,
              private store: Store<AppState>,
              private inviteService: InviteService,
              private _ngZone: NgZone) {
    if (data && data.options && data.options.contactType === 'contact_people') {
      this.contactType = contactTypes.naturalPerson;
    }

    if (data && data.options && data.options.contactType === 'contact_people' && data.contactPerson) {
      this.createContactForm.contactPerson = data.contactPerson;
    }

    if (data && data.options && data.options.hideInviteButton) {
      this.hideInviteButton = data.options.hideInviteButton;
    }

    if (data && data.options && data.options.lockContactType) {
      this.lockContactType = data.options.lockContactType;
    }
    if (data && data.color) {
      this.color = data.color;
    }
  }

  ngOnInit() {
  }

  createContact(shouldInvite = false) {
    this.store.select(getSelected).pipe(
      first()
    ).subscribe((orga: Organization) => {
      // Person Contact creation
      const email = this.createContactForm.contactPerson.mainEmailAddress.emailAddress;
      this.store.dispatch(new NewContact(orga, this.createContactForm, this.contactVisibility));

      const createPartnerLinkAccess = this.createContactForm.contactPerson.activatePartnerLinks && email;
      const sanitizedEmail = email.toLowerCase();

      if (shouldInvite) {
        this.inviteService.inviteBusinessPartner(email).subscribe(() => {
          setTimeout(_ => {
            this._ngZone.runOutsideAngular(_ => {
              if (createPartnerLinkAccess) {
                this.store.dispatch(new PartnerLinkParticipationActions.Create(sanitizedEmail));
              }
              this.store.dispatch(new LoadOfOrganization(orga.id));
            });
          });
        }, err => {
          console.error(err);
          this.store.dispatch(new LoadOfOrganization(orga.id));
        });
      } else if (createPartnerLinkAccess) {
        this.store.dispatch(new PartnerLinkParticipationActions.Create(sanitizedEmail));
        this.store.dispatch(new LoadOfOrganization(orga.id));
      }

      if (!this.furtherContact) {
        this.closeDialog();
      }
    });
  }

  closeDialog(result?) {
    this.mdr.close(result);
  }

}


enum contactTypes {
  naturalPerson,
  organization
}
