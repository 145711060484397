import {Injectable} from '@angular/core';
import {AuditAuditorCommunicationsBuilder} from 'app/+store/_legacy/api/builders/auditAuditorCommunications.builder';
import {AuditAuditorCommunications} from 'app/+store/_legacy/api/models/auditAuditorCommunications';
import {environment} from 'environments/environment';
import {
  BasicContactBuilder,
  CacAppendixBuilder,
  CacBuilder,
  CacStatisticsBuilder,
  FileAttachmentBuilder
} from './cac.builder';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Router} from '@angular/router';
import {BasicContact, CacAppendix, CacProcess, FileAttachment} from './cac';
import {ProcessProfile} from '../process/process.interface';
import {first} from 'rxjs/operators';
import {FileUtils} from 'app/lib/file_utils/functions';
import {HttpClient} from '@angular/common/http';
import {ICacEntityParapms} from '../cac-entity/cac-entity.interface';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {AppendixStatusEnum} from './cac.interface';
import {CacStatistics} from './cac-appendix';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class CacService {
  readonly BASE_PATH = 'api/v1/audit/cac/processes';
  readonly BASE_PATH_V3 = 'api/v3/audit/cac/processes';
  basePath: string;

  constructor(private _http: FivefApiResourceService,
              private _httpClient: HttpClient,
              private _notifyService: FivefNotificationService,
              private _router: Router) {
    this.basePath = `${environment.token_service_config.apiBase}/${this.BASE_PATH}`;
  }

  create(parentId, cac: AuditAuditorCommunications, clientId, profile: ProcessProfile, contactImportId: string): Observable<CacProcess> {
    const builder = new CacBuilder(this._router);
    const payload = AuditAuditorCommunicationsBuilder.toRequest(cac, clientId);
    payload['data']['attributes']['profile'] = profile;
    payload['data']['attributes']['contact_import_id'] = contactImportId;
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}?parent=${parentId}` , payload);
  }

  getOne(_parentId: string, id: string): Observable<CacProcess> {
    const builder = new CacBuilder(this._router);
    return <Observable<CacProcess>>this._http.get<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}`);
  }

  getStatistics(id: string) {
    const builder = new CacStatisticsBuilder();
    return <Observable<CacStatistics>>this._http.get<CacStatisticsBuilder, CacStatistics>(builder, `${this.BASE_PATH}/${id}/statistics`);
  }

  createAppendix(id: string, title: string, societyId = null) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: {
          title: title
        }
      }
    };
    const queryParam = societyId ? `?society=${societyId}` : '';
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/appendices${queryParam}` , payload);
  }

  sendRequests(id): Observable<CacProcess> {
    const builder = new CacBuilder(this._router);
    const payload = {};
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/send`, payload);
  }

  getAllRequests(id, page = null, perPage= 15): Observable<CacProcess[]> {
    const builder = new CacBuilder(this._router);
    const payload = {};
    const query = page ? `?page=${page}&per_page=${perPage}` : '';
    return <Observable<CacProcess[]>>this._http.get<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/requests${query}`);
  }

  /**
   * Returns one published request by ID e.g. to update its appendices.
   *
   * @param id
   * @param requestId
   */
  getOneRequest(id, requestId): Observable<CacProcess> {
    const builder = new CacBuilder(this._router);
    return <Observable<CacProcess>>this._http.get<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/requests/${requestId}`);
  }

  /**
   * Activates a default appendix for a given society/request process.
   * This method is only useful in published state.
   *
   * @param cacId
   * @param societyId
   * @param defaultAppendixId
   */
  activateAppendix(cacId: string, societyId: string, defaultAppendixId: string) {
    const builder = new CacAppendixBuilder(societyId);
    return this._http.post<CacAppendixBuilder, CacAppendix>(builder, `${this.BASE_PATH_V3}/${cacId}/societies/${societyId}/appendices/${defaultAppendixId}/activate`, {});
  }

  /**
   * Adds a new society to a published CAC.
   *
   * @param cacId
   * @param societyId
   * @param defaultAppendixId
   */
  addSociety(cacId: string, values) {
    const payload = {
      data: {
        attributes: {
          color: values.color,
          name: values.name,
          country_code: values.country_code,
          partner_no: values.partner_no,
          comment: values.comment,
        }
      }
    }
    const builder = new CacBuilder(this._router);
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH_V3}/${cacId}/societies`, payload);
  }

  updateAppendix(parentId: string, appendix: CacAppendix) {
    const builder = new CacBuilder(this._router);
    const applicable = CacAppendix.toApiApplicability(appendix.applicable);
    const preStatus = CacAppendix.toApiStatus(appendix.status);
    const status = applicable ? preStatus : 'received';
    const payload = {
      data: {
        attributes: {
          title: appendix.title,
          due_date: appendix.dueDate,
          description: appendix.description,
          applicable: applicable,
          status: status,
          checked: appendix.checked
        }
      }
    };
    return this._http.put<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${parentId}/appendices/${appendix.id}`, payload);
  }

  updateAppendixOnly(parentId: string, appendix: CacAppendix) {
    const builder = new CacAppendixBuilder(parentId);
    const applicable = CacAppendix.toApiApplicability(appendix.applicable);
    const preStatus = CacAppendix.toApiStatus(appendix.status);
    const status = applicable ? preStatus : 'received';
    const payload = {
      data: {
        attributes: {
          title: appendix.title,
          due_date: appendix.dueDate,
          description: appendix.description,
          applicable: applicable,
          status: status,
          checked: appendix.checked
        }
      }
    };
    return this._http.put<CacAppendixBuilder, CacAppendix>(builder, `${this.BASE_PATH}/${parentId}/appendices/${appendix.id}?render_node_only=true`, payload);
  }

  markAppendixReceived(id: string, appendix: CacAppendix) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: {
          status: 'received'
        }
      }
    };
    return this._http.put<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/appendices/${appendix.id}`, payload);
  }

  setApplicable(id: string, appendix: CacAppendix, applicable: boolean, received = false) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: {
          applicable: applicable
        }
      }
    };
    if (applicable === false && appendix.status !== AppendixStatusEnum.completed && appendix.status !== AppendixStatusEnum.closed) {
      payload['data']['attributes']['status'] = 'in_progress';
    }
    return this._http.put<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${id}/appendices/${appendix.id}`, payload);
  }

  createSociety(cacId: string, params: ICacEntityParapms) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: params
      }
    };
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies`, payload);
  }

  updateSociety(cacId: string, id: string, params) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: params
      }
    };
    return this._http.put<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${id}`, payload);
  }

  deleteSociety(cacId: string, id: string) {
    const builder = new CacBuilder(this._router);
    return this._http.del<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${id}`);
  }

  markSocietyChecked(cacId: string, id: string, checked: boolean) {
    const builder = new CacBuilder(this._router);
    const payload = {
      data: {
        attributes: {
          checked: checked
        }
      }
    };
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${id}/checked`, payload);
  }

  exportAsZip(id: string) {
    const payload = {
      'data': {
        'attributes': {
          'dms_document_ids': null,
          'export_type': 'cac',
          'include_index_html': true,
          'include_xml': true,
          'exclude_collector_templates': true,
          'mark_file_exported': true
        }
      }
    };
    return this._httpClient.post(`${environment.token_service_config.apiBase}/api/v1/workflow_engine/processes/${id}/file_exports`, payload)
      .pipe(first())
      .subscribe(res => {
          this._notifyService.info('DMS.EXPORT_CREATION_ONGOING_INFO');
      });
  }

  removeAppendix(parentId: string, id: string) {
    const builder = new CacBuilder(this._router);
    return this._http.del<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${parentId}/appendices/${id}`);
  }

  removeDocument(cacId: string, id: string) {
    const builder = new CacBuilder(this._router);
    return this._http.del<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/attachments/${id}`);
  }

  download(cacId: string, id: string) {
    const builder: FileAttachmentBuilder = new FileAttachmentBuilder();
    return this._http.get<FileAttachmentBuilder, FileAttachment>(builder, `${this.BASE_PATH}/${cacId}/attachments/${id}`);
  }

  createContact(cacId: string, societyId: string, contact: BasicContact) {
    const builder = new CacBuilder(this._router);
    const payload = new BasicContactBuilder().toRequest(contact);
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${societyId}/contacts`, payload);
  }

  updateContact(cacId: string, societyId: string, contact: BasicContact) {
    const builder = new CacBuilder(this._router);
    const payload = new BasicContactBuilder().toRequest(contact);
    return this._http.put<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${societyId}/contacts/${contact.id}`, payload);
  }

  verify(cacId: string, societyId: string, contact: BasicContact) {
    const builder = new CacBuilder(this._router);
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${societyId}/contacts/${contact.id}/verify`, {});
  }

  invite(cacId: string, societyId: string, contact: BasicContact) {
    const builder = new CacBuilder(this._router);
    return this._http.post<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${societyId}/contacts/${contact.id}/invite`, {});
  }

  deleteContact(cacId: string, societyId: string, contactId: string) {
    const builder = new CacBuilder(this._router);
    return this._http.del<CacBuilder, CacProcess>(builder, `${this.BASE_PATH}/${cacId}/societies/${societyId}/contacts/${contactId}`);
  }

  exportAsXls(id) {
    return this._httpClient.get(`${environment.token_service_config.apiBase}/${this.BASE_PATH}/${id}/export`)
      .pipe(first())
      .subscribe(res => {
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        FileUtils.saveAsFile(res['data'].attributes.filename, res['data'].attributes.content, mimeType);
      });
  }
}
