import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ContactPerson} from './contact-person';
import {Update} from '@ngrx/entity';
import {ContactListDto} from '../contact/contact';

export enum ContactPersonActionTypes {
  LoadOne = '[contact-person] Load one',
  LoadOneSuccess = '[contact-person] Load one Success',
  LoadOneFail = '[contact-person] Load one Fail',
  EditContact = '[contact-person] Edit Contact',
  EditContactSuccess = '[contact-person] Edit Contact Success',
  EditContactFail = '[contact-person] Edit Contact Fail',
  LoadAssignedOrganizations = '[contact-person] Load assigned organizations',
  LoadAssignedOrganizationsSuccess = '[contact-person] Load assigned organizations success',
  EditFirstName = '[contact-person] Edit First Name',
  EditLastName = '[contact-person] Edit Last Name',
  EditEmail = '[contact-person] Edit Email',
  EditPhoneNumber = '[contact-person] Edit Phone Number'
}

export class LoadOne implements Action {
  readonly type = ContactPersonActionTypes.LoadOne;

  constructor(public contactId: string) {

  }
}

export class LoadOneSuccess implements Action {
  readonly type = ContactPersonActionTypes.LoadOneSuccess;

  constructor(public payload: ContactPerson) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ContactPersonActionTypes.LoadOneFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class EditContact implements Action {
  readonly type = ContactPersonActionTypes.EditContact;

  constructor(public contactToEdit: ContactPerson) {

  }
}

export class EditContactSuccess implements Action {
  readonly type = ContactPersonActionTypes.EditContactSuccess;

  constructor(public payload: ContactPerson) {
  }
}

export class EditContactFail implements Action {
  readonly type = ContactPersonActionTypes.EditContactFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class LoadAssignedOrganizations implements Action {
  readonly type = ContactPersonActionTypes.LoadAssignedOrganizations;

  constructor(public contactId: string) {

  }
}

export class LoadAssignedOrganizationsSuccess implements Action {
  readonly type = ContactPersonActionTypes.LoadAssignedOrganizationsSuccess;

  constructor(public payload: {id: string, assigns: string[]}) {

  }
}
export class EditFirstName implements Action {
  readonly type = ContactPersonActionTypes.EditFirstName

  constructor(public contactId, public firstName) {
  }
}
export class EditLastName implements Action {
  readonly type = ContactPersonActionTypes.EditLastName

  constructor(public contactId, public lastName) {
  }
}
export class EditEmail implements Action {
  readonly type = ContactPersonActionTypes.EditEmail

  constructor(public contactId, public email) {
  }
}
export class EditPhoneNumber implements Action {
  readonly type = ContactPersonActionTypes.EditPhoneNumber

  constructor(public contactId, public phoneNumber) {
  }
}
export type ContactPersonActions =
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | EditContact
  | EditContactSuccess
  | EditContactFail
  | LoadAssignedOrganizations
  | LoadAssignedOrganizationsSuccess
  | EditFirstName
  | EditLastName
  | EditEmail
  | EditPhoneNumber
