import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';

export enum ProjectRoomType {
  Default = 'PROJECT_ROOM.DEFAULT'
}

export interface IProjectRoom extends IResource {
  type: ProjectRoomType;
}

