import {IProcessTreeNode, ProcessTreeNodeType} from './process-tree.interface';
import { ProcessStatus } from 'app/+store/process/process';
import {IFivefResourceTreeNode} from '../../lib/fivef-ui/tree/fivef-tree/fivef-tree.interface';

export type ProcessTreeSortAttribute = 'title' | 'created_at' | 'updated_at';
export type ProcessTreeSortDirection = 'desc' | 'asc';

/**
 * Project structure settings for the Project Room and other implementing
 * views. Defines sorting and some filters.
 * Both are persisted as user settings. See
 * ProcessTreeSettingsBuilder, and ProcessTreeService for further details.
 *
 * Current options:
 * - Sort project structure by different attributes.
 * - Define sort direction.
 * - Hide closed workflows.
 */
export interface IProcessTreeSettings {
  id: string;
  type: string;
  title?: string;
  icon?: string;
  sortBy: ProcessTreeSortAttribute,
  sortDirection: ProcessTreeSortDirection,
  hideClosed?: boolean;
}

export class ProcessTreeSettings implements IProcessTreeSettings {
  public id: string;
  readonly type = 'workflow_engine_process_tree_settings';
  constructor(public sortBy, public sortDirection, public hideClosed) {
    this.id = `${sortBy}_${sortDirection}`;
  }
}

export const DEFAULT_SORTING_OPTIONS: IProcessTreeSettings[] = [
  {
    id: 'title_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_TITLE_ASC',
    sortBy: 'title',
    sortDirection: 'asc',
    icon: 'sort_by_alpha',
  },
  {
    id: 'title_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_TITLE_DESC',
    sortBy: 'title',
    sortDirection: 'desc',
    icon: 'sort_by_alpha',
  },
  {
    id: 'created_at_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_CREATION_DATE_DESC',
    sortBy: 'created_at',
    icon: 'history',
    sortDirection: 'desc',
  },
  {
    id: 'created_at_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_CREATION_DATE_ASC',
    sortBy: 'created_at',
    icon: 'update',
    sortDirection: 'asc',
  },
  {
    id: 'updated_at_desc',
    type: 'project_structure_settings',
    title: 'SORT.BY_LAST_UPDATED_AT_DESC',
    sortBy: 'updated_at',
    icon: 'history',
    sortDirection: 'desc',
  },
  {
    id: 'updated_at_asc',
    type: 'project_structure_settings',
    title: 'SORT.BY_LAST_UPDATED_AT_ASC',
    sortBy: 'updated_at',
    icon: 'update',
    sortDirection: 'asc',
  }
]

export class ProcessTree {
}

export class ProcessTreeNode implements IProcessTreeNode {
  createdAt: Date;
  active = false;
  closed = false;

  icon: string = null;
  isSvgIcon = false;
  isProject = false;
  isProjectRoom = true;
  isCollecto = false;
  isCac = false;
  count?: number;

  constructor(public id: string,
              public type: ProcessTreeNodeType,
              public parentId: string,
              public title: string,
              public updatedAt: Date,
              public status?: ProcessStatus,
              public canCreateTask: boolean = false) {}

  /**
   * Builds a fivef-tree compatible data model.
   * @param processNodes
   */
  static buildTreeFrom(processNodes: ProcessTreeNode[]): IFivefResourceTreeNode[] {
    const nodes: IFivefResourceTreeNode[] = [];
    const parentMap = {};
    const nodeMap = {};
    processNodes.forEach(n => {
      const node: IFivefResourceTreeNode = {
        id: n.id,
        title: n.title,
        parentId: n.parentId,
        type: 'Process',
        icon: n.closed ? 'lock' : n.icon,
        children: [],
        data: n
      }
      nodeMap[n.id] = node;
      if (n.parentId) {
        if (!parentMap[n.parentId]) {
          parentMap[n.parentId] = [];
        }
        parentMap[n.parentId].push(node);
      }
    });
    processNodes.forEach(n => {
      const node = nodeMap[n.id];
      // Root node:
      // Node without parent or node where parent is not in processNodes.
      if (!n.parentId || !nodeMap[n.parentId]) {
        nodes.push(node);
      } else {
        const innernode = nodeMap[n.parentId]
        innernode.children.push(node);
      }
    });
    return nodes;
  }

  static buildGroupedDocumentTreeFrom(nodes, rootName): IFivefResourceTreeNode[] {
    const rootNode: IFivefResourceTreeNode = {
      id: '00000000-0000-0000-0000-000000000000',
      title: rootName,
      parentId: null,
      type: 'Document',
      icon: 'insert_drive_file',
      children: [],
      data: null
    }

    const root = new ProcessTreeNode(
      '00000000-0000-0000-0000-000000000000',
      ProcessTreeNodeType.Root,
      null,
      'PROJECT_ROOM.ALL_DOCUMENTS',
      new Date()
    );
    root.icon = 'insert_drive_file';
    nodes.forEach(n => {
      const node: IFivefResourceTreeNode = {
        id: n.id,
        title: n.title,
        parentId: n.parentId,
        type: 'Process',
        icon: n.closed ? 'lock' : n.type,
        children: [],
        data: n
      }
      rootNode.children.push(node);
      if (n.parentType === 'quickcollector') {
        n.items.forEach(item => {
          node.children.push({
            id: item.id,
            title: item.subTitle,
            parentId: n.id,
            type: 'Process',
            icon: 'playlist_add_check',
            children: [],
            data: item
          })
        });
      }
    });

    return [rootNode];
  }
}
