<!-- Template variants for the inner action button -->
<!-- Templates for menu and buttons -->
<ng-template #buttonContentRef>
  <ng-template [ngIf]="theme === 'status'">
    <div class="fivef-status-selector--content"
         [style.background]="'#' + _selected?.color">
        <span class="fivef-status-selector--content-icon">
          <mat-icon *ngIf="_selected?.icon"
                    [class.fivef-status-selector--icon-only]="iconOnly">{{_selected?.icon}}</mat-icon>
        </span>
      <span class="fivef-status-selector--content-title"
            *ngIf="!iconOnly">{{_selected?.title | translate}}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]="theme === 'status_inverse'">
    <div class="fivef-status-selector--content fivef-status-selector--theme-status-inverse"
         [style.color]="'#' + _selected?.color">
        <span class="fivef-status-selector--content-icon">
          <mat-icon *ngIf="_selected?.icon"
                    [class.fivef-status-selector--icon-only]="iconOnly">{{_selected?.icon}}</mat-icon>
        </span>
      <span class="fivef-status-selector--content-title"
            *ngIf="!iconOnly">{{_selected?.title | translate}}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]="theme === 'priority'">
    <div class="fivef-status-selector--content">
      <span [style.border-color]="_selected.color"
            [style.color]="_selected.color">
        <mat-icon *ngIf="_selected?.icon">{{_selected.icon}}</mat-icon>
        {{_selected.title | translate}}
      </span>
    </div>
  </ng-template>
</ng-template>

<!-- Template variants for the link style action integration, e.g. at Collecto sidebar -->
<ng-template #linkContentRef>
  <ng-template [ngIf]="theme === 'status'">
    {{_selected?.title | translate}}
  </ng-template>

  <ng-template [ngIf]="theme === 'priority'">
    <span class="d-flex align-items-center"
          [style.border-color]="_selected.color"
          [style.color]="_selected.color">
      <mat-icon *ngIf="_selected?.icon">{{_selected.icon}}</mat-icon>
      {{_selected.title | translate}}
    </span>
  </ng-template>
</ng-template>

<ng-template #dropdownMenuRef>
  <!-- status menu style with status blocks -->
  <ng-template [ngIf]="theme === 'status' || theme === 'status_inverse'">
    <ul class="fivef-status-selector--menu--theme-status">
      <li class="fivef-status-selector--menu-item"
          *ngFor="let status of _statuses"
          (click)="selectStatus(status)">
          <span [style.background]="'#' + status?.color"
                [class.fivef-status-selector--menu-item--active]="selectedStatus[status?.id]"
                [matTooltip]="status?.title | translate">
            <mat-icon style="color: white !important;" *ngIf="_selected?.icon" class="mr-1">{{status?.icon}}</mat-icon>
            {{(status?.title | translate).length > 22 ? (((status?.title | translate) | slice:0:22) + '..') : (status?.title | translate)}}
          </span>
      </li>
    </ul>
  </ng-template>

  <!-- priority listing style with left border -->
  <ng-template [ngIf]="theme === 'priority'">
    <ul class="fivef-status-selector--menu--theme-priority">
      <li class="fivef-status-selector--menu-item"
          *ngFor="let priority of _statuses"
          (click)="selectStatus(priority)">
      <span [style.border-color]="priority.color"
            [style.color]="priority.color"
            [class.fivef-status-selector--menu-item--active]="priority.id === _selected?.id">
        <mat-icon>{{priority.icon}}</mat-icon>
        {{priority.title | translate}}
      </span>
      </li>
    </ul>
  </ng-template>
</ng-template>
<!-- End templates for menu and buttons -->

<!-- Button integration: Modes: 'button' (e.g. inside tables, 'link' (e.g. Collecto sidebar) -->
<ng-container *ngIf="appearance == 'button'; else link">
  <button cdk-overlay-origin
          class="fivef-status-selector--button fivef-status-selector--theme-{{theme}}"
          (click)="openMenu()"
          #dropdown="cdkOverlayOrigin"
          [disabled]="disabled">
    <div class="fivef-status-selector--content-wrapper">
      <ng-template [ngIf]="_selected" [ngIfElse]="statusContentRef">
        <template [ngTemplateOutlet]="buttonContentRef"></template>
      </ng-template>

      <ng-template #statusContentRef>
        <div class="fivef-status-selector--content"
             [style.color]="'black'">{{buttonTitle | translate}}</div>
      </ng-template>
    </div>
  </button>

  <!-- Overlay with menu template depending on theme -->
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 fivef-status-selector--menu">
      <template [ngTemplateOutlet]="dropdownMenuRef"></template>
    </div>
  </ng-template>
</ng-container>

<!-- Link style: buttton less container/link embedment type -->
<ng-template #link>
  <span class="fivef-status-selector--link fivef-status-selector--theme-{{theme}}"
        cdk-overlay-origin
        (click)="openMenu()"
        #dropdown="cdkOverlayOrigin"
        [style.background]="'#' + _selected?.color"
        [style.border-color]="'#' + _selected?.color">
    <div class="fivef-status-selector--content-wrapper">
      <div class="fivef-status-selector--content">
        <ng-template [ngIf]="_selected" [ngIfElse]="defaultMessageRef">
          <template [ngTemplateOutlet]="linkContentRef"></template>
        </ng-template>

        <ng-template #defaultMessageRef>
          <span [style.color]="'black'">{{buttonTitle | translate}}</span>
        </ng-template>
      </div>
    </div>
  </span>

  <!-- Overlay with menu template depending on theme -->
  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown && !disabled"
               (backdropClick)="showDropdown = false">
    <div class="mat-elevation-z8 fivef-status-selector--menu">
      <template [ngTemplateOutlet]="dropdownMenuRef"></template>
    </div>
  </ng-template>
</ng-template>
