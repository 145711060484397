import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {Quickshare, QuickshareSettings} from './quickshare';
import {QuickshareBuilder, QuickshareSettingsBuilder} from './quickshare.builder';
import {IQuickshareParams} from './quickshare.interface';
import {DmsAccountType} from '../dms-folder/dms-folder.interface';

@Injectable()
export class QuickshareService {
  readonly BASE_PATH = 'api/v1/quickshare';

  constructor(private _http: FivefApiResourceService) {}

  create(params: IQuickshareParams): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    const payload = { data: { attributes: { title: params.title } } };
    const query = params.parentId ? `?parent=${params.parentId}` : '';
    return <Observable<Quickshare>>this._http.post<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes${query}`, payload);
  }

  publish(id: string): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    return <Observable<Quickshare>>this._http.post<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes/${id}/publish`, {});
  }

  saveDraft(id: string, params: IQuickshareParams): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    const payload = {
      data: {
        attributes: {
          title: params.title,
          description: params.description,
          due_date_enabled: params.dueDateEnabled,
          due_date: params.dueDate,
          dms_folder_id: params.dmsFolderId,
          dms_account_type: params.dmsAccountType === DmsAccountType.Organization ? 'organization' : (params.dmsAccountType === DmsAccountType.Private ? 'private' : null),
          password_enabled: params.passwordEnabled,
          passphrase: params.passphrase,
          password_expires_at_enabled: params.passwordExpiresAtEnabled,
          password_expires_at: params.passwordExpiresAt,
          notify_by_sms: params.notifyBySms,
          client_uuid: params.clientId,
          color: params.color
        }
      }
    };
    return <Observable<Quickshare>>this._http.put<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/drafts/${id}`, payload);
  }

  getAll(): Observable<Quickshare[]> {
    const builder = new QuickshareBuilder();
    return <Observable<Quickshare[]>>this._http.get<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes`);
  }

  getOne(id: string): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    return <Observable<Quickshare>>this._http.get<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes/${id}`);
  }

  updateRecipients(id: string, emails: string[]): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    const payload = {
      'data': {
        'attributes': {
          'recipients': emails
        }
      }
    };
    return <Observable<Quickshare>>this._http.post<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes/${id}/recipients`, payload);
  }

  updateWorkers(id: string, emails: string[]): Observable<Quickshare> {
    const builder = new QuickshareBuilder();
    const payload = {
      'data': {
        'attributes': {
          'workers': emails
        }
      }
    };
    return <Observable<Quickshare>>this._http.post<QuickshareBuilder, Quickshare>(builder, `${this.BASE_PATH}/processes/${id}/workers`, payload);
  }

  getSettings(id: string): Observable<QuickshareSettings> {
    const builder = new QuickshareSettingsBuilder();
    return <Observable<QuickshareSettings>>this._http.get<QuickshareSettingsBuilder, QuickshareSettings>(builder, `${this.BASE_PATH}/processes/${id}/settings`);
  }

  updateSettings(quickshareSettings: QuickshareSettings): Observable<QuickshareSettings> {
    const builder = new QuickshareSettingsBuilder();
    const payload = builder.toRequest(quickshareSettings);
    return <Observable<QuickshareSettings>>this._http.post<QuickshareSettingsBuilder, QuickshareSettings>(builder, `${this.BASE_PATH}/processes/${quickshareSettings.id}/settings`, payload);
  }
}
