import {GroupContextType, IKanbanGroup} from './kanban-group.interface';
import {KanbanItem} from 'app/+store/kanban-store/kanban-item/kanban-item';
import {LabelScope} from 'app/+store/label/label.interface';

export class KanbanGroup implements IKanbanGroup {
  readonly type = 'group';
  public items: KanbanItem[] = [];

  constructor(
    public id: string,
    public order: number,
    public labelId: string,
    public boardId: string,
    public title: string,
    public color: string,
    public labelScope: LabelScope,
    public itemsOrder: string[],
    public isExpanded: boolean = true,
    public isContextual: boolean = false,
    public contextType: GroupContextType = GroupContextType.NO_CONTEXT,
    public referenceId?: string,
  ) {
  }
}
