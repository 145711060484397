import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Task} from 'app/+store/task/task';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {CommonModule} from '@angular/common';
import {IFivefFlatNode, IFivefResourceTreeNode} from '../../tree/fivef-tree/fivef-tree.interface';
import {TranslateModule} from '@ngx-translate/core';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {FivefTreeComponent} from '../../tree/fivef-tree/fivef-tree.component';
import {FivefProcessTreeNodeComponent} from '../../process/fivef-process-tree-node/fivef-process-tree-node.component';
import {FivefTreeNodeDevDirective} from '../../tree/fivef-tree/fivef-tree-node-dev.directive';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fivef-task-tree',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FivefSearchComponent,
    FivefTreeComponent,
    FivefProcessTreeNodeComponent,
    FivefTreeNodeDevDirective,
    MatIconModule
  ],
  templateUrl: './process-tasks-navigation.component.html',
  styleUrls: ['./process-tasks-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefTaskTreeComponent implements OnDestroy {
  public nodes$ = new BehaviorSubject<IFivefResourceTreeNode[]>([]);

  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<IFivefResourceTreeNode>();

  /**
   * Query string to serch and filter the tree.
   */
  @Input()
  public searchTerm: string = null;

  @Input()
  set processTaskMap(data: { [processId: string]: Task[] }) {
    this.nodes$.next(this.buildTreeFrom(!!data ? data : {}));
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.nodes$.complete();
  }

  /**
   * Sets the searchTerm to be used as input of the search.
   *
   * @param $event
   */
  public applySearch($event: string) {
    this.searchTerm = $event;
    this.cdr.detectChanges();
  }

  /**
   * Builds a recursive tree with processes as first level, tasks as second level.
   *
   * @param tasks
   * @private
   */
  private buildTreeFrom(tasks: { [processId: string]: Task[] }): IFivefResourceTreeNode[] {
    const nodes: IFivefResourceTreeNode[] = [];
    Object.keys(tasks).forEach(processId => {
      const firstTask = tasks[processId][0];
      const processNode: IFivefResourceTreeNode = {
        id: processId,
        type: 'Process',
        title: firstTask.process.title,
        icon: firstTask.process.icon,
        count: tasks[processId].length,
        children: [],
        data: null
      };
      nodes.push(processNode);

      tasks[processId].forEach(task => {
        const taskNode: IFivefResourceTreeNode = {
          id: task.id,
          type: 'Task',
          title: task.title,
          parentId: processId,
          icon: 'tasks',
          data: task,
          children: []
        };
        processNode.children.push(taskNode);
      });
    });
    return nodes;
  }

  public selectNode(node: IFivefFlatNode) {
    this.onSelect.emit(node?.data);
  }
}
