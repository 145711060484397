<div *ngIf="editableFeature">
  <div *ngIf="!editable"
       (click)="changeEditable($event)"
       [froalaView]="body || (placeholder | translate)"
       class="description five-f-content-editable-container five-f-content-editable-container--editor"></div>

  <div *ngIf="editable">
    <dvtx-wysiwyg-editor-error [dasError]="dasError"
                               [fileError]="fileError"></dvtx-wysiwyg-editor-error>

    <textarea *ngIf="!_disabled"
              (froalaInit)="initializeLink($event)"
              [froalaEditor]="options"
              [(froalaModel)]="body"
              [placeholder]="placeholder | translate"></textarea>
    <div *ngIf="_disabled" [froalaView]="body"></div>
  </div>
</div>

<div *ngIf="!editableFeature">
    <dvtx-wysiwyg-editor-error [dasError]="dasError" [fileError]="fileError"></dvtx-wysiwyg-editor-error>
    <textarea *ngIf="!_disabled"
              [froalaEditor]="options"
              [(froalaModel)]="body"
              [placeholder]="placeholder | translate"></textarea>
    <div *ngIf="_disabled" [froalaView]="body"></div>
</div>
