import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {FivefApiResourceService} from 'app/lib/fivef-net/fivef-api-resource/services/fivef-api-resource.service';
import {ArtifactKeyword} from './artifact-keyword';
import {ArtifactKeywordBuilder} from './artifact-keyword.builder';

@Injectable()
export class ArtifactKeywordService {
  readonly KEYWORD_API_V3 = '/api/v3/organizations';
  readonly KEYWORD_API_V3_SUFFIX = 'artifact_keywords';

  constructor(private _http: FivefApiResourceService) {}

  getOne(organizationId: string, id: string): Observable<ArtifactKeyword> {
    const builder = new ArtifactKeywordBuilder(organizationId);
    return <Observable<ArtifactKeyword>>this._http.get<ArtifactKeywordBuilder, ArtifactKeyword>(builder, `${this.KEYWORD_API_V3}/${organizationId}/${this.KEYWORD_API_V3_SUFFIX}/${id}`);
  }

  getAll(organizationId: string): Observable<ArtifactKeyword[]> {
    const builder = new ArtifactKeywordBuilder(organizationId);
    return <Observable<ArtifactKeyword[]>>this._http.get<ArtifactKeywordBuilder, ArtifactKeyword>(builder, `${this.KEYWORD_API_V3}/${organizationId}/${this.KEYWORD_API_V3_SUFFIX}`);
  }

  create(organizationId: string, keyword: ArtifactKeyword): Observable<ArtifactKeyword> {
    const builder = new ArtifactKeywordBuilder(organizationId);
    const payload = builder.toRequest(keyword)
    return <Observable<ArtifactKeyword>>this._http.post<ArtifactKeywordBuilder, ArtifactKeyword>(builder, `${this.KEYWORD_API_V3}/${organizationId}/${this.KEYWORD_API_V3_SUFFIX}`, payload);
  }

  update(organizationId: string, keyword: ArtifactKeyword): Observable<ArtifactKeyword> {
    const builder = new ArtifactKeywordBuilder(organizationId);
    const payload = builder.toRequest(keyword)
    return <Observable<ArtifactKeyword>>this._http.put<ArtifactKeywordBuilder, ArtifactKeyword>(builder, `${this.KEYWORD_API_V3}/${organizationId}/${this.KEYWORD_API_V3_SUFFIX}/${keyword.id}`, payload);
  }

  delete(organizationId: string, id: string): Observable<ArtifactKeyword> {
    const builder = new ArtifactKeywordBuilder(organizationId);
    return <Observable<ArtifactKeyword>>this._http.del<ArtifactKeywordBuilder, ArtifactKeyword>(builder, `${this.KEYWORD_API_V3}/${organizationId}/${this.KEYWORD_API_V3_SUFFIX}/${id}`);
  }
}
