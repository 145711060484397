import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer} from './cac-entity.reducer';
import {stateKey} from './cac-entity.selectors';
import {CacEntityService} from './cac-entity.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(stateKey, reducer)
  ],
  providers: [
    CacEntityService
  ]
})
export class CacEntityStoreModule {
}
