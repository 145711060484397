import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {CreateWorkflowComponent} from './containers/create-workflow/create-workflow.component';
import {QuickstartModule} from 'app/modules/quickstart/quickstart.module';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';
import {FivefDialogComponent} from '../../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefMenuItemComponent} from '../../../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefDisableInputHintsWrapperDirective} from '../../../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';

@NgModule({
  imports: [
    SharedModule,
    QuickstartModule,
    ScrollingModule,
    FivefDialogComponent,
    FivefMenuItemComponent,
    FivefDisableInputHintsWrapperDirective
  ],
  declarations: [
    CreateWorkflowComponent
  ],
  exports: [
    CreateWorkflowComponent
  ]
})
export class ProcessDialogModule {
}
