<div class="fivef-page-header-search-results--container">
  <mat-table class="fivef-page-header-search-results--table"
             [dataSource]="dataSource"
             multiTemplateDataRows>
    <ng-container matColumnDef="project">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{ 'GENERAL.RECENTLY_VIEWED_ISSUES' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let element">
        <div class="fivef-page-header-search-results--fivef-color-bar"
             [style.background-color]="element?.color ? element?.color : 'transparent'"></div>
        <div class="w-100 d-flex" [matTooltip]="element.title">
          <fivef-process-icon [workflowType]="element?.processType"></fivef-process-icon>
          &nbsp;
          <div class="w-100 text-truncate">
            <div class="d-flex flex-column">
              <div class="ml-1 mb-0 d-flex align-items-center">
                {{ element.title ? element.title : ('GENERAL.NO_TITLE' | translate) }}
              </div>
              <div class="ml-1 d-flex">
                <div style="font-weight: 300;" class="text-truncate">{{ element?.clientName }}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{ 'GENERAL.IDENT' | translate }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="w-100 text-truncate">
          {{ element?.identifier }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="view">
      <mat-header-cell mat-header-cell *matHeaderCellDef>
        <div>
          {{ 'GENERAL.STATUS' | translate }}
        </div>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <div>
          {{ element.status?.title | translate }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             class="ff-pointer p-0"
             (click)="navigateToProcess(row)">
    </mat-row>
  </mat-table>

  <div *ngIf="loading$ | async; else more">
    <fivef-loading-indicator [appearance]="'progressbar'"></fivef-loading-indicator>
  </div>
  <ng-template #more>
    <div class="w-100 d-flex justify-content-center">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone" inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;
        </div>
      </ng-container>
    </div>
  </ng-template>

  <div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0">
    <fivef-icon-message-block [icon]="'process'"
                              [message]="'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH'"></fivef-icon-message-block>
  </div>
</div>

<hr>

<div class="fivef-page-header-search-results--footer">
  <mat-checkbox (change)="_loadPage(1)" [(ngModel)]="excludeClosedStatus">
    <span>{{ 'STATUS.EXCLUDE_CLOSED_WORKFLOWS' | translate }}</span></mat-checkbox>
  <button mat-button
          (click)="navigateToSearchPage()">
    <!--<mat-icon aria-label="search">search</mat-icon>-->
    {{ 'GENERAL.ADVANCED_SEARCH' | translate }}
    <mat-icon aria-label="search">arrow_forward</mat-icon>
  </button>
</div>
