import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnInit} from '@angular/core';
import {IFivefIconColorStatus, PRIORITIES, PRIORITIES_MAP} from './fivef-status-selector.interface';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatTooltipModule} from '@angular/material/tooltip';

/**
 * Status selector with readonly mode for process state and Collecto items.
 * Supports title, color and icon.
 */
@Component({
  selector: 'fivef-status-selector',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, OverlayModule, MatTooltipModule],
  host: {'class': 'fivef-status-selector'},
  templateUrl: './fivef-status-selector.component.html',
  styleUrls: ['./fivef-status-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefStatusSelectorComponent implements OnInit {
  public showDropdown = false;

  public _statuses: IFivefIconColorStatus[];
  public _selected: IFivefIconColorStatus;
  public selectedStatus = {};

  @Input()
  public buttonTitle = 'GENERAL.STATUS';

  /**
   * Status styles. The default `status` is corresponding to a Collecto status.
   * Style `priority` only shows text and all options as listing with a highlighting border.
   * Style `status_inverse` inverts the colors and is used at the document listing.
   */
  @Input()
  public theme: 'status' | 'status_inverse' | 'priority' = 'status';

  /**
   * Show only icon of status. Skip status title.
   */
  @Input()
  public iconOnly = false;

  /**
   * Disabled action menu.
   * Readonly status.
   */
  @Input()
  public disabled = false;

  /**
   * Use a button or a normal container for the (semantical) status appearance.
   */
  @Input()
  public appearance: 'button' | 'link' = 'button';

  /**
   * Emits a new status on selection by dropdown menu.
   * @private
   */
  @Output()
  private onSelection: EventEmitter<any> = new EventEmitter<any>(null);

  /**
   * Sets all provided status for status selection.
   *
   * @param statuses
   * @private
   */
  @Input()
  private set statuses(statuses: any[]) {
    if (statuses) {
      this._statuses = statuses;
    }
  }

  /**
   * Sets the current status.
   * Permitted is a single status and status array.
   *
   * @param selected
   * @private
   */
  @Input()
  private set selected(selected: string | IFivefIconColorStatus) {
    if (selected) {
      if (typeof selected === 'string') {
        this._selected = PRIORITIES_MAP[selected];
      } else {
        this._selected = selected;
      }

      this.setSelectedStatus(selected);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
  }

  /**
   * Emits the selected status and closes the dropdown menu.
   * @param $event
   */
  public selectStatus($event) {
    this.onSelection.emit($event);
    this.showDropdown = false;
    this.cdr.detectChanges();
  }

  /**
   * Creates a dictionary for fast current status lookup.
   * @param status
   * @private
   */
  private setSelectedStatus(status) {
    const selectedStatus = {};

    if (!status || !status.id) {
      return false;
    }

    if (status?.id) {
      selectedStatus[status.id] = true;
    }

    this.selectedStatus = selectedStatus;
    // CDR not needed. This method is only invoked by @Input.
  }

  /**
   * Opens the dropdown menu.
   */
  public openMenu() {
    if (this.disabled) return;
    this.showDropdown = !this.showDropdown;
    this.cdr.detectChanges();
  }
}
